import React from 'react';
import { UnitedStates } from '../../../domain/country/UnitedStates';
import { Option, Select } from '../Select/Select';

export interface StatePickerProps {
  onChange?: (state: UnitedStates.StateCode) => void;
  defaultStateCode?: UnitedStates.StateCode;
  options: Option[];
  placeholder: string;
  id?: string;
}

const handleChange = (value: string, onChange: StatePickerProps['onChange']) => {
  if (!UnitedStates.isKnownState(value)) {
    return;
  }

  if (onChange) onChange(value);
};

const StatePicker: React.FC<StatePickerProps> = ({ onChange, defaultStateCode, options, placeholder, id }) => {
  const defaultStateValue = defaultStateCode && {
    value: defaultStateCode,
    label: UnitedStates.unitedStatesMap[defaultStateCode],
  };

  return (
    <Select
      id={id || 'state-picker-select-box'}
      options={options}
      defaultValue={defaultStateValue}
      onChange={(value) => handleChange(value.value, onChange)}
      placeholder={placeholder}
      dropdownTheme="lessonup-default"
    />
  );
};

export default StatePicker;
