import React from 'react';
import FolderSvg from '../../../svgIcons/FolderSvg';
import './ExplorerFolderIcon.less';

interface Props {
  className: string;
  showOpen?: boolean;
  isInPublicationFolder?: boolean;
}
export const ExplorerFolderIcon: React.FC<Props> = ({
  className = '',
  showOpen = false,
  isInPublicationFolder = false,
}) => (
  <div className={className}>
    <FolderSvg color={isInPublicationFolder ? 'red' : 'yellow'} showOpen={showOpen} />
  </div>
);
