import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { searchRoute } from '../../../../shared-core/services/app/searchRoutes';
import ActionButton from '../../../../shared-core/ui/components/Button';
import RocketFlightSvg from '../../../../shared-core/ui/components/svgIcons/RocketFlightSvg';
import './ErrorPage.less';

const GenericErrorPage: React.FC = (props) => {
  const { t } = useTranslation('errors');
  return (
    <div className="error-page-container">
      <div className="error-page error-page-500">
        <RocketFlightSvg />
        <p className="error-description">{t('default')}</p>
        <Link to={searchRoute()}>
          <ActionButton className="error-page-back">{t('error-page-back')}</ActionButton>
        </Link>
      </div>
    </div>
  );
};

export default GenericErrorPage;
