import { Lesson } from '@lessonup/teaching-core';
import { AppError } from '@lessonup/utils';
import { History } from 'history';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { ChannelTitle } from '../../../../shared-core/domain';
import {
  FolderId,
  FolderWithNavigationData,
  getFolderFromExplorer,
  getMetaForFolder,
  PublishContentExplorer,
} from '../../../../shared-core/domain/newExplorers';
import { channelRoute } from '../../../../shared-core/services/app/searchRoutes';
import { Text } from '../../../../shared-core/ui/components/translations/Text/Text';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import { navFromSearch } from '../../../redux/selectors';
import { isChannelRouteOfType, isLessonSearchRoute, isplanRoute } from '../../../utils/routeUtils/routeUtils';
import LessonPage from '../../lesson/LessonPage';
import { ChannelSubPageProps } from '../ChannelPageView';

const ChannelLessonPage: React.FC<ChannelSubPageProps> = ({ activeTab, changeTab, details }) => {
  const { selectionId, subSelectionId, channelPage } = activeTab;
  const { publicFolder } = details;
  const services = useAppServices();
  const navigationCheck = useSelector(navFromSearch());
  const history = useHistory();

  if (!selectionId) {
    return null;
  }

  const backNavigation = (lesson: Lesson) => {
    const folder = publicFolder && tryGetFolderFromPublishContentExplorer(publicFolder, lesson.location?.folder);
    if (navigationCheck === 'search' || !details.channel.showSeries) {
      return <BackButton url={navBackUrl} history={history} />;
    }
    if (!folder || !folder.parents) {
      if (lesson.plan) {
        const planUrl = channelRoute({
          channelSlug: details.channel.name,
          channelPage: 'plan',
          selectionId: lesson.plan,
        });
        return <BackButton url={planUrl} history={history} forPlan={true} />;
      }
      return <BackButton url={navBackUrl} history={history} />;
    }

    return (
      <Breadcrumbs
        breadcrumbs={[...folder.parents, getMetaForFolder(folder)]}
        rootFolderName={ChannelTitle.localizeTitle(details.channel.title)}
        getUrl={(folderId) =>
          channelRoute({
            channelSlug: details.channel.name,
            channelPage: 'series',
            selectionId: folderId || undefined,
          })
        }
        currentFolderName={lesson.name}
      />
    );
  };

  const handlePinSelected = (pin: string) => {
    changeTab(
      {
        channelPage,
        selectionId,
        subSelectionId: pin,
      },
      true
    );
  };

  const navBackUrl = channelRoute({
    channelSlug: details.channel.name,
    channelPage: 'search',
  });

  const backUrl = channelRoute({
    channelSlug: details.channel.name,
  });

  const handleTeach = (newTab?: boolean) => {
    services.lesson.teach({ lessonId: selectionId, pinId: subSelectionId, newTab, source: 'channel-lesson' });
  };

  /**
   * This function attempts to find the folder with navigation data, which can be used for rendering the breadcrumbs.
   * It attempts to find the folder within the specified publish content explorer.
   * However, there are situations in which the folder does not reside within the publish content explorer.
   * For example, when the organization also wants to publish public lessons from its users.
   * In that case, the folder resides within the personal context explorer.
   * We shouldn't attempt to render the breadcrumb in this situation.
   *
   * @param explorer The PublishContextExplorer in which you want to search for the folder.
   * @param folderId The ID of the folder which you want to search for.
   * @returns The FolderWithNavigationData object if the folder exists within the explorer, otherwise undefined.
   */
  const tryGetFolderFromPublishContentExplorer = (
    explorer: PublishContentExplorer,
    folderId?: FolderId
  ): FolderWithNavigationData | undefined => {
    try {
      return getFolderFromExplorer(explorer, folderId);
    } catch (error) {
      if (AppError.isError(error, 'not-found')) {
        return;
      }

      throw error;
    }
  };

  return (
    <LessonPage
      lessonId={selectionId}
      preselectedPin={subSelectionId}
      handlePinSelected={handlePinSelected}
      backNavigation={backNavigation}
      backUrl={backUrl}
      teachOverride={handleTeach}
      withoutHasMore={true}
    />
  );
};

export default ChannelLessonPage;

const BackButton = ({ url, history, forPlan = false }: { url: string; history: History; forPlan?: boolean }) => {
  const location = useLastLocation();
  // if last location would be search, use browser back as the experience is better
  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (location) {
      const search = !forPlan && isLessonSearchRoute(location.pathname);
      const channelSearch = !forPlan && isChannelRouteOfType(location.pathname, 'search');
      const plan = forPlan && isplanRoute(location.pathname);
      const channelPlan = forPlan && isChannelRouteOfType(location.pathname, 'plan');
      const any = search || channelSearch || plan || channelPlan;
      if (any) {
        event.preventDefault();
        history.goBack();
      }
    }
  };

  return (
    <div className="lesson-page__back">
      <span className="lesson-page__back-arrow">&lsaquo;</span>
      <Link to={url} className="link-prominent" onClick={onClick}>
        {forPlan ? (
          <Text translateKey="returnToPlan" options={{ ns: 'lessonPlan' }} />
        ) : (
          <Text translateKey="returnToSearch" options={{ ns: 'search' }} />
        )}
      </Link>
    </div>
  );
};
