import { LessonThumbnailPixelRatio } from '../../../../domain/ui/constants/lessonThumbnail';
import transformServingImage, { TransformOptions } from '../../../../utils/transformServingImage';

// The image size ranges between 240px (minimum) to ~380px depending on the screen size.
// 330px should be enough to cover that range.
export const IMAGE_WIDTH = 330;
const PIXEL_RATIOS: LessonThumbnailPixelRatio[] = [1, 2, 3];

export function resizeLessonThumbnail(imageUrl: string, pixelRatio: number, transformOptions?: TransformOptions) {
  return transformServingImage(imageUrl, { ...transformOptions, size: IMAGE_WIDTH * pixelRatio });
}

export function generateLessonThumbnailSrcSet(imageUrl: string, transformOptions?: TransformOptions) {
  return PIXEL_RATIOS.slice()
    .sort()
    .map((pixelRatio) => `${resizeLessonThumbnail(imageUrl, pixelRatio, transformOptions)} ${pixelRatio}x`)
    .join(', ');
}
