import { MongoGroup } from '@lessonup/teaching-core';
import _ from 'lodash';
import { MongoUser } from '../user';

export interface StudentGroup {
  _id: string;
  name: string;
  studentDisplayName?: string;
  teacherName: string | undefined;
}

export class StudentGroup {
  public static fromGroups(groups: MongoGroup[], teachers: Partial<MongoUser>[]) {
    const nameDictionary = _.fromPairs(teachers.map((t) => [t._id, t.profile && t.profile.name]));

    return groups.map((group) => {
      return {
        _id: group._id,
        name: group.name,
        studentDisplayName: group.studentDisplayName,
        teacherName: nameDictionary[group.user],
      };
    });
  }
}
