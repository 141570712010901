import { Country } from '../country/Country';

type OrganizationID = string;

export const GB_SCHOOL_PICKER_FEATURE_FLAG = 'showSchoolPickerGB';
export const allowedCountriesForSchoolPicker: Country.CountryCode[] = ['nl', 'gb'];

export function showSchoolPickerForCountry(
  country: Country.CountryCode | undefined,
  checkFeatureFlags: (flag: string) => boolean
): boolean {
  if (!country) return false;

  if (country === 'gb') {
    return checkFeatureFlags(GB_SCHOOL_PICKER_FEATURE_FLAG);
  }

  return allowedCountriesForSchoolPicker.includes(country);
}

export type School = {
  _id: string; // document id
  globalSchoolId: string; // {countryCode}|{localIdentifier}
  schoolCode: string;
  country: string; // required
  countrySubdivision?: string; // used in GB schools
  creationDate: Date;
  modifiedDate?: Date; // last date when this object was altered, handy for indexing
  schoolType?: string; // (eg HE, FE, PO, etc )
  internalSchoolType?: string;

  // see the phase2 version why this is an object
  origin: {
    type: 'external-source';
    externalId: string; // something to indicate what kind of origin this is (like nl-schools)
    importLabel: string; // friendly name so we later know what the import source was.

    // identifier to the batch so we can target specific batches of imports.
    // Just hardcode this for now since we assume only having 1 import
    importBatch: string;
    importDate: Date; // original import date
  };

  isArchived: boolean;
  hasAlias: boolean;

  name: string;
  city: string;
  address?: string;
  zipcode?: string;
  state?: string;

  organizationId?: OrganizationID;
  isSuper?: boolean;
};
