import React from 'react';

const EyeBlockedIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" {...props}>
      <path d="M14.78.22a.75.75 0 0 0-1.061 0L10.56 3.379a8.815 8.815 0 0 0-2.561-.378c-3.489 0-6.514 2.032-8 5a9.176 9.176 0 0 0 2.703 3.236L.218 13.721a.75.75 0 0 0 1.06 1.061l13.5-13.5a.75.75 0 0 0 0-1.061zM6.5 5a1.5 1.5 0 0 1 1.421 1.019L6.019 7.921A1.5 1.5 0 0 1 6.5 5zM1.721 8a7.594 7.594 0 0 1 2.52-2.462A3.981 3.981 0 0 0 4 6.907c0 .858.27 1.652.73 2.303l-.952.952A7.625 7.625 0 0 1 1.721 8zM12 6.906c0-.424-.066-.833-.189-1.217l-5.028 5.028A4 4 0 0 0 12 6.906z" />
      <path d="m12.969 4.531-1.084 1.084.059.037c.94.6 1.737 1.403 2.335 2.348a7.594 7.594 0 0 1-2.335 2.348 7.326 7.326 0 0 1-5.725.933l-1.201 1.201A8.808 8.808 0 0 0 8 13c3.489 0 6.514-2.032 8-5a9.142 9.142 0 0 0-3.031-3.469z" />
    </svg>
  );
};

export default EyeBlockedIcon;
