import { logger } from '@lessonup/client-integration';
import { LoaderSpinner } from '@lessonup/ui-components';
import { AppError, triggerDownloadFromFetchResponse } from '@lessonup/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showDefaultErrorNotification } from '../../../../shared-core/client/utils/modals';
import { Api } from '../../../../shared-core/domain/api/LessonupApi';
import { LessonPlan } from '../../../../shared-core/domain/lessonPlan';
import { useAppServices } from '../../appServices/AppServicesContext';

interface Props {
  planId: string;
  child: LessonPlan.Children.Attachment;
}

export const DownloadPlanAttachment: React.FC<Props> = ({ planId, child }) => {
  const { lessonPlan } = useAppServices();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('lessonPlanAttachment');
  const name: string = child.name ?? t('defaultName');

  async function handleClick() {
    try {
      setLoading(true);
      if (!child.uploadId) throw new AppError('not-found', 'Plan child without uploadId found');
      const response = await lessonPlan.lessonPlanDownloadAttachment({ planId, childId: child.id });
      await downloadFile(response);
    } catch (error) {
      showDefaultErrorNotification(error);
      logger.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function downloadFile({ url, contentDispositionHeader }: Api.PlanDownloadAttachmentResponse) {
    const uploadFile = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Disposition': contentDispositionHeader,
      },
    });

    return triggerDownloadFromFetchResponse(uploadFile, name);
  }

  return (
    <button onClick={handleClick} className="plan-button full">
      {loading ? <LoaderSpinner /> : <span className="icon"></span>}
      <span>{name}</span>
    </button>
  );
};
