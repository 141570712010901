import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-core/ui/components/Button';
import DefaultForm from '../../../shared-core/ui/components/inputElements/DefaultForm';

interface ForgotPasswordEmailFormProps {
  headerText: string;
  description: string;
  onSubmit: (email: string) => void;
  className?: string;
}

function ForgotPasswordEmailForm(props: ForgotPasswordEmailFormProps) {
  const { t } = useTranslation('login');
  const [email, setEmail] = useState<string | undefined>(undefined);

  function handleSubmit() {
    if (email) props.onSubmit(email);
  }

  function emailChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  return (
    <div className={props.className}>
      <h3 className="password-forgotten-title">{props.headerText}</h3>
      <p>{props.description}</p>
      <DefaultForm className="password-forgotten-form" handleSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Email"
          autoComplete="email"
          value={email}
          onChange={emailChangeHandler}
          required
        />
        <Button theme="light" type="submit">
          {t('submit')}
        </Button>
      </DefaultForm>
    </div>
  );
}

export default ForgotPasswordEmailForm;
