import { tracker } from '@lessonup/analytics';
import { ID } from '@lessonup/teaching-core';
import { BodyText, Button, Headline, IconWarning, SpaceBetween, spacing } from '@lessonup/ui-components';
import { AppError } from '@lessonup/utils';
import { compact, intersection, map } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { logger } from '@lessonup/client-integration';
import { MongoUser, Organization } from '../../../../../shared-core/domain';
import { Api } from '../../../../../shared-core/domain/api/LessonupApi';
import { schoolSettingsRoute } from '../../../../../shared-core/services/app/teacherRoutes';
import { useFormInput } from '../../../../../shared-core/ui/components/inputElements/useFormInput';
import { RadioButton } from '../../../../../shared-core/ui/components/RadioButton';
import { useBEM } from '../../../../../shared-core/ui/utils/hooks';
import { useAppServices } from '../../../../components/appServices/AppServicesContext';
import { loggedInUser } from '../../../../redux/selectors';
import { GrayPageSection } from '../../../GrayPageLayout/GrayPageLayout';
import { RegistrationFormProps } from '../../types';
import { useRegistrationContext } from '../../utils/RegistrationContext';
import './RegistrationPickChildPage.less';

const FORM_ID = 'childPickerForm';
const TRANSLATION_NS = 'registrationPickChildPage';

export type ResponseStatus = 'error' | 'success';
type ErrorState = 'generic' | 'notInWhitelist' | 'emailRequired';

interface Props extends RegistrationFormProps {
  organizationId: ID | undefined;
  source?: 'schools-page';
}

type ChildForPicker = Organization.OrganizationDataForChildPicker;
export const RegistrationPickChildPage: React.FC<Props> = ({ organizationId, source, next }) => {
  const [childOrganizations, setChildOrganizations] = useState<ChildForPicker[] | undefined>();
  const [parentName, setParentName] = useState<string | undefined>();
  const [showWhitelistedEmailInput, setShowWhitelistedEmailInput] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<ErrorState | undefined>();
  const user = useSelector(loggedInUser());
  const { t } = useTranslation(TRANSLATION_NS);
  const bemClasses = useBEM('registrationPickChildPage');
  const services = useAppServices();
  const { pickChildSource } = useRegistrationContext();

  const [newEmail, newEmailInput] = useFormInput({
    type: 'email',
    label: t('whitelistedEmailInput'),
    onChange: () => setError(undefined),
  });

  useEffect(() => {
    async function fetchData() {
      try {
        if (!organizationId) return;
        const results = await services.organizations.childOrganizationsForParent(organizationId);
        setChildOrganizations(results.childOrganizations);
        setParentName(results.parent?.name);
      } catch (error) {
        logger.error(error);
        setError('generic');
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function checkEmailWhitelist(organizationId: string) {
    setLoading(true);
    try {
      const emails = user ? MongoUser.allEmailsForUser(user) : [];
      const emailInWhitelist = await services.domainRules.containsEmailsWhitelistedForOrganization({
        emails,
        organizationId,
      });
      if (!emailInWhitelist) {
        setShowWhitelistedEmailInput(true);
        return;
      }
      setShowWhitelistedEmailInput(false);
    } catch (error) {
      logger.error(error);
      setError('generic');
    } finally {
      setLoading(false);
    }
  }

  const alreadyJoinedOrganization = useCallback((): string[] => {
    const userOrganizations = map(user?.organizations, 'id');
    const childOrganizationIDs = map(childOrganizations, '_id');
    return intersection(userOrganizations, childOrganizationIDs);
  }, [childOrganizations, user?.organizations]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<Promise<void> | undefined> => {
    event.preventDefault();
    setError(undefined);

    if (showWhitelistedEmailInput && !newEmail) {
      setError('emailRequired');
      return;
    }

    const form = document.forms[FORM_ID];
    if (!form) return;
    const selectedChild: string = form['child'].value;

    if (newEmail) {
      try {
        setLoading(true);

        const emailInWhitelist = await services.domainRules.containsEmailsWhitelistedForOrganization({
          emails: [newEmail],
          organizationId: selectedChild,
        });

        if (emailInWhitelist) {
          const resp = await services.user.addEmail({
            email: newEmail,
          });
          if (resp.success === false) {
            setError('generic');
            return;
          }
        } else {
          setError('notInWhitelist');
          return;
        }
      } catch (error) {
        logger.error(error);
        setError('generic');
        return;
      } finally {
        setLoading(false);
      }
    }

    return requestToJoin(selectedChild);
  };

  async function requestToJoin(organizationId: ID) {
    if (alreadyJoinedOrganization()?.length) {
      goToNext();
      return;
    }

    try {
      const { success, reason } = await services.user.sendOrganizationJoinRequest(organizationId);

      if (!success) {
        handleUnsuccessfulJoinRequest(setError, reason);
        return;
      }

      const requestToJoinMethod = source ? 'school_picker' : pickChildSource;
      if (requestToJoinMethod) {
        tracker.events.teacherRequestToJoinOrganizationSent({
          organizationId,
          requestToJoinMethod,
        });
      } else {
        logger.error(new AppError('unexpected-data', 'No source set for child picker'));
      }

      goToNext();
    } catch (error) {
      logger.error(error);
      setError('generic');
    }
  }

  function addressLine({ address, city }: Organization.OrganizationDataForChildPicker) {
    return compact([address, city]).join(', ');
  }

  function goToNext() {
    if (source === 'schools-page') {
      return window.open(schoolSettingsRoute(), '_self');
    }

    next();
  }

  const ctaButtonText = childOrganizations?.length && source !== 'schools-page' ? t('cta') : t('ctaContinue');

  return (
    <SpaceBetween direction="y" spacing={spacing.size16}>
      <GrayPageSection className={bemClasses()} position="top">
        <Headline size="medium">{t('heading')}</Headline>
        {childOrganizations?.length ? <BodyText>{t('paragraph', { parentSchoolName: parentName })}</BodyText> : null}
      </GrayPageSection>
      <GrayPageSection position="bottom">
        <div className={bemClasses({ element: 'child-picker' })}>
          <form name={FORM_ID} id={FORM_ID} onSubmit={handleSubmit}>
            <SpaceBetween
              direction="y"
              spacing={spacing.size8}
              className={bemClasses({ element: 'child-picker-container' })}
            >
              {!childOrganizations?.length ? (
                <PickChildEmptyState />
              ) : (
                childOrganizations.map((childOrganization) => (
                  <RadioButton
                    disabled={loading || !!alreadyJoinedOrganization().length}
                    initialValue={alreadyJoinedOrganization()[0] === childOrganization._id}
                    name={'child'}
                    value={childOrganization._id}
                    key={childOrganization._id}
                    onChange={(event) => {
                      if (event.target.checked) {
                        checkEmailWhitelist(childOrganization._id);
                      }
                    }}
                    required
                  >
                    <Headline size="small">{childOrganization.name}</Headline>
                    <BodyText>{addressLine(childOrganization)}</BodyText>
                  </RadioButton>
                ))
              )}
              {showWhitelistedEmailInput && (
                <SpaceBetween direction="y" spacing={spacing.size16}>
                  <SpaceBetween direction="x" spacing={spacing.size16}>
                    <IconWarning />
                    <BodyText weight="bold">{t('onlySchoolDomainsAccepted')}</BodyText>
                  </SpaceBetween>
                  {newEmailInput}
                </SpaceBetween>
              )}
            </SpaceBetween>
            <SpaceBetween direction="y" spacing={spacing.size16}>
              {error && <BodyText className={bemClasses({ element: 'error' })}>{t(`error.${error}`)}</BodyText>}
              {alreadyJoinedOrganization().length ? (
                <BodyText className={bemClasses({ element: 'notification' })}>{t('alreadyJoined')}</BodyText>
              ) : null}
              <Button type="submit" loading={loading}>
                {ctaButtonText}
              </Button>
            </SpaceBetween>
          </form>
        </div>
      </GrayPageSection>
    </SpaceBetween>
  );
};

function PickChildEmptyState() {
  const { t } = useTranslation(TRANSLATION_NS);

  return <BodyText>{t('emptyState')}</BodyText>;
}

function handleUnsuccessfulJoinRequest(
  setError: (error: ErrorState) => void,
  reason?: Api.SendJoinRequestResponse['reason']
) {
  if (reason === 'emailAddressInvalid') {
    setError('notInWhitelist');
    return;
  }

  setError('generic');
}
