import { DisplayGiftMessage } from '@lessonup/teacher-modern';
import qs from 'qs';
import React from 'react';
import { teacherHome } from '../../../../shared-core/services/app/teacherRoutes';
import { ClientOnlyModernWrapper } from '../../../utils/bridge/ClientOnlyModernWrapper';
import { withQueryString } from '../../../utils/routeUtils/routeUtils';

export const DisplayGiftPageView = () => {
  const onNext = () => {
    window.open(withQueryString(teacherHome(), qs.stringify({ 'welcome-tour': true })), '_self');
  };

  return (
    <ClientOnlyModernWrapper>
      <DisplayGiftMessage onNext={onNext} />
    </ClientOnlyModernWrapper>
  );
};
