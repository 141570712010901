import classNames from 'classnames';
import React from 'react';
import { ImageUtils } from '../../../../utils/ImageUtils';
import RocketSvg from '../../logos/RocketSvg';
import './RedeemVoucherConfirmationBox.less';

interface Props {
  onlyUseSmall?: boolean;
  icon?: string;
  children: React.ReactNode;
}

const RedeemVoucherConfirmationBox: React.FC<Props> = ({ onlyUseSmall, icon, children }) => {
  const backgroundImage: React.StyleHTMLAttributes<HTMLDivElement> | undefined = icon
    ? { style: { backgroundImage: ImageUtils.urlString(icon) } }
    : undefined;

  return (
    <div className={classNames('voucher-confirmation', { small: onlyUseSmall })}>
      <div className={classNames('voucher-confirmation-image', { 'custom-image': !!icon })} {...backgroundImage}>
        {!icon && <RocketSvg />}
      </div>
      <div className="voucher-confirmation-box">{children}</div>
    </div>
  );
};

export default RedeemVoucherConfirmationBox;
