import { WithOptionalId } from '@lessonup/utils';
import { DomainRule, MongoUser } from '../domain';

type User = WithOptionalId<MongoUser>;

export const getDomainFromEmail = (email: string): string => {
  const emailBits = email.split('@');
  return emailBits[emailBits.length - 1];
};

export const getUserNameFromEmail = (email: string): string => {
  const emailBits = email.split('@');
  return emailBits[0];
};

export const filterEmailsThatMatchDomains = (userEmails: string[], domains: string[]): string[] => {
  return userEmails.filter((email) => {
    const domainFromEmail = getDomainFromEmail(email);
    if (domains.includes(domainFromEmail)) {
      return email;
    }
  });
};

export const getUserEmailDomains = (user: User): string[] => {
  return MongoUser.allEmailsForUser(user).map(getDomainFromEmail);
};

export const isUserEmailWhitelistedForDomainRules = (user: MongoUser, domainRules: DomainRule[]): boolean => {
  const emailAddresses = MongoUser.allEmailsForUser(user);
  return containsEmailsWhitelistedForDomainRules(emailAddresses, domainRules);
};

export const containsEmailsWhitelistedForDomainRules = (
  emailAddresses: string[],
  domainRules: DomainRule[]
): boolean => {
  const whitelistingDomainRules = domainRules.filter(({ whitelist }) => !!whitelist);
  if (!whitelistingDomainRules.length) return true;
  const whitelistedDomains = whitelistingDomainRules.map(({ domains }) => domains).flat();
  const anyEmailWhitelisted = filterEmailsThatMatchDomains(emailAddresses, whitelistedDomains).length > 0;
  return anyEmailWhitelisted;
};
