interface CollapseInnerHits {
  sorted: {
    hits: {
      total: {
        value: number;
        relation: string;
      };
      hits: any[];
    };
  };
}

export interface Hit<T = any> {
  _id: string;
  _index: string;
  _score: number;
  _source: T;
  _explanation?: Explanation;
  inner_hits?: CollapseInnerHits;
}

export namespace Hit {
  export function toDocument<T>(hit: Hit<T>): T {
    return {
      _id: hit._id,
      ...hit._source,
    };
  }

  export function collapseCount(hit: Hit): number | undefined {
    if (!hit.inner_hits) return undefined;
    return hit.inner_hits.sorted.hits.total.value;
  }
}

export interface Explanation {
  value: number;
  description: string;
  details: Explanation[];
}
