import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../shared-core/ui/components/Button';
import DefaultForm from '../../../../shared-core/ui/components/inputElements/DefaultForm';
import { Modal } from '../../../../shared-core/ui/components/modals/Modal';
import ModalButtonBar from '../../../../shared-core/ui/components/modals/ModalButtonBar';
import { ModalProps } from '../../../../shared-core/ui/utils/ModalManagerService';
import { useAppServices } from '../../appServices/AppServicesContext';

const TRANSLATION_NAMESPACE = 'forgotPasswordModal';

function ForgotPasswordModal(props: ModalProps<undefined>) {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const services = useAppServices();
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  function handleClose() {
    return props.closeModal(undefined);
  }

  function modalContent() {
    return formSubmitted ? <ConfirmMessage text={t('thank-you')} closeModal={handleClose} /> : <ForgotPasswordForm />;
  }

  return (
    <Modal title={t('title')} isOpen onRequestClose={handleClose} width="medium">
      {modalContent()}
    </Modal>
  );
}

function ForgotPasswordForm(props: {}) {
  const { t } = useTranslation();

  return (
    <div className="forgot-password-content">
      <div className="login__page login__page-options">
        <div className="login__page-option">
          <DefaultForm>
            <h3>Stel een nieuw wachtwoord in</h3>
            <input type="text" placeholder="Email" />
            <ActionButton theme="light">{t('Verstuur')}</ActionButton>
          </DefaultForm>
        </div>
        <div className="login__page-divider" />
        <div className="login__page-option">
          <DefaultForm>
            <h3>Stuur mij een magische link</h3>
            <input type="text" placeholder="Email" />
            <ActionButton theme="light">{t('Verstuur')}</ActionButton>
          </DefaultForm>
        </div>
      </div>
    </div>
  );
}

const ConfirmMessage: React.FunctionComponent<{ text: string; closeModal: (res: undefined) => void }> = (props) => {
  const { t } = useTranslation();

  function clickHandler(e: React.MouseEvent | React.TouchEvent) {
    props.closeModal(undefined);
  }

  return (
    <div>
      <div className="flagmodal__header">
        <div className="flagmodal_headertext">{props.text}</div>
      </div>
      <ModalButtonBar layout="center">
        <ActionButton theme="light" onClick={clickHandler}>
          {t('ok')}
        </ActionButton>
      </ModalButtonBar>
    </div>
  );
};

export default ForgotPasswordModal;
