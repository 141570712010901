import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  direction?: 'up' | 'down';
}

const ChevronSvg: React.FC<Props> = (props) => {
  const { direction, ...svgProps } = props;
  return (
    <svg transform={direction === 'up' ? 'scale(1, -1)' : ''} width={13} height={7} viewBox="0 0 13 7" {...svgProps}>
      <path d="M0 0h13L6.5 7z" fill="#5A4D4C" fillRule="evenodd" />
    </svg>
  );
};

export default ChevronSvg;
