import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectedFacet } from '../../../../../shared-core/domain';
import { Notification } from '../../../../../shared-core/ui/components/Notification';
import './CountryNotification.less';

interface Props {
  country: string | undefined;
  selectedFacets: SelectedFacet[];
  onClick: () => void;
}

export const CountryNotification: React.FunctionComponent<Props> = ({ country, selectedFacets, onClick }) => {
  const { t } = useTranslation('searchResultsCountryNotification');

  const button = {
    text: t('button'),
    onClick,
  };

  return (
    <>
      {country && !userIsFromSelectedCountry(selectedFacets, country) && (
        <Notification button={button} className="country-notification" image="globe">
          {t('message')}
        </Notification>
      )}
    </>
  );
};

function userIsFromSelectedCountry(selectedFacets: SelectedFacet[], country?: string) {
  return selectedFacets.find(({ key, value }) => key === 'country' && value === country);
}
