import { TrackerAction, TrackerParams, TrackingUserIds } from '@lessonup/utils';
import { Channel, Language, LanguageKey, LeadTrigger, MongoUserProfile, UserContext } from '../../../domain';
import { Api } from '../../../domain/api/LessonupApi';
import { DomainRuleSuggestion } from '../../../domain/authorization/DomainRules';
import { ExplorerUserContent } from '../../../domain/newExplorers/Explorer';
import { IndexedSchoolSuggestion } from '../../../domain/schoolSuggestions/IndexedSchoolSuggestion';
import { Facets, SearchParams } from '../../../domain/search';
import { HttpApiService } from './HttpApiService';

export class LessonUpApiService extends HttpApiService {
  protected debugging = false;
  protected delayMS = 0;
  private prefix = '/search/api';

  public user(): Promise<UserContext | undefined> {
    return this.get(`${this.prefix}/user`);
  }

  public loginWithEmailPassword(params: Api.AuthMeteorLoginParams): Promise<Api.AuthMeteorLoginResponse | undefined> {
    return this.post(`${this.prefix}/auth/loginWithEmailPassword`, params);
  }

  public verifyEmailByToken(params: Api.VerifyEmailByTokenParams): Promise<Api.VerifyEmailByTokenResponse> {
    return this.post(`${this.prefix}/auth/verifyEmailByToken`, params);
  }

  public sendEmailVerificationEmail(
    params: Api.SendEmailVerificationEmailParams
  ): Promise<Api.SendEmailVerificationEmailResponse> {
    return this.post(`${this.prefix}/auth/send-email-verification`, params);
  }

  public registerWithEmailVerificationTokenPassword(
    params: Api.AuthMeteorEmailVerifiedRegistrationParams
  ): Promise<Api.AuthMeteorEmailVerifiedRegistrationResponse | undefined> {
    return this.post<Api.AuthMeteorEmailVerifiedRegistrationParams, Api.AuthMeteorEmailVerifiedRegistrationResponse>(
      `${this.prefix}/auth/registerWithEmailVerificationTokenPassword`,
      params
    );
  }

  public addEmail(params: Api.AddEmailParams): Promise<Api.AddEmailResponse> {
    return this.post<Api.AddEmailParams, Api.AddEmailResponse>(`${this.prefix}/user/add-email`, params);
  }

  public logoutServerSide(): Promise<boolean> {
    return this.post(`${this.prefix}/auth/logout`);
  }

  public requestMagicLink(
    email: string,
    language: string,
    emailVariant: Api.magicLinkVariant,
    singleUse?: boolean
  ): Promise<Api.RequestMagicLinkResponse> {
    return this.post(`${this.prefix}/requestMagicLink?language=${encodeURIComponent(language)}`, {
      email,
      singleUse,
      emailVariant,
    });
  }

  public requestEmailValidation(email: string): Promise<Api.RequestEmailValidationResponse> {
    return this.post(`${this.prefix}/requestEmailValidation`, { email });
  }

  public requestPasswordReset(email: string): Promise<Api.RequestMagicLinkResponse> {
    return this.post(`${this.prefix}/auth/requestPasswordReset`, { email });
  }

  public resetPassword(params: Api.ResetPasswordParams): Promise<Api.ResetPasswordResponse> {
    return this.post(`${this.prefix}/auth/resetPassword`, params);
  }

  public searchLessons(params: SearchParams, subType?: Facets.FacetSubTypes[]): Promise<Api.LessonSearchResponse> {
    return this.get(`${this.prefix}/search/lessons/${subType?.join('+') || ''}`, SearchParams.toDictionary(params));
  }

  public searchPins(params: SearchParams): Promise<Api.PinSearchResponse> {
    return this.get(`${this.prefix}/search/pins`, SearchParams.toDictionary(params));
  }

  public searchPlans(params: SearchParams): Promise<Api.PlanSearchResponse> {
    return this.get(`${this.prefix}/search/plans`, SearchParams.toDictionary(params));
  }

  public lessonCluster(lessonId: string): Promise<Api.LessonClusterResponse> {
    return this.get(`${this.prefix}/search/clusterdata/${lessonId}`);
  }

  public lessonDetails(lessonId: string, params: { omitHasMore?: boolean }): Promise<Api.LessonByIdResponse> {
    return this.get(`${this.prefix}/lessons/${lessonId}`, params);
  }

  public lessonPlanDetails(planId: string): Promise<Api.PlanByIdResponse> {
    return this.get(`${this.prefix}/plans/${planId}`);
  }

  public lessonPlanDownloadAttachment(
    params: Api.PlanDownloadAttachmentParams
  ): Promise<Api.PlanDownloadAttachmentResponse> {
    return this.post(`${this.prefix}/plans/${params.planId}/attachments/${params.childId}/download`, params);
  }

  public saveLesson(lessonId: string, params?: Api.LessonSaveParams): Promise<Api.LessonSaveResponse> {
    return this.post(`${this.prefix}/lessons/${lessonId}/save`, params);
  }

  public setRating(lessonId: string, params: Api.RateLessonParams): Promise<Api.RateLessonResponse> {
    return this.post(`${this.prefix}/lessons/${lessonId}/rate`, params);
  }

  public addPinToLesson(lessonId: string, params: Api.LessonAddPinParams): Promise<Api.LessonAddPinResponse> {
    return this.post(`${this.prefix}/lessons/${lessonId}/addPins`, params);
  }

  public explorer(): Promise<Api.ExplorerResponse> {
    return this.get(`${this.prefix}/explorer`);
  }

  public explorerWithTokens(access_token, refresh_token): Promise<Api.ExplorerResponse> {
    return this.get(`${this.prefix}/explorer?access_token=${access_token}&refresh_token=${refresh_token}`);
  }

  public createFolder(params: Api.CreateFolderParams): Promise<Api.CreateFolderResponse> {
    return this.post(`${this.prefix}/explorer/create`, params);
  }

  public folderContent(params: Api.FolderContentParams): Promise<ExplorerUserContent[]> {
    return this.get(`${this.prefix}/explorer/content`, params);
  }

  public folderContentWithToken(params: Api.FolderContentParamsWithToken): Promise<ExplorerUserContent[]> {
    return this.get(`${this.prefix}/explorer/content`, params);
  }

  public flagLesson(lessonId: string, comment: string): Promise<Api.LessonFlagResponse> {
    return this.post(`${this.prefix}/lessons/${lessonId}/flag`, { comment });
  }

  public followChannelWithUserId(
    channelSlug: string,
    trigger?: LeadTrigger
  ): Promise<Api.FollowChannelWithUserIdResponse> {
    return this.post(`${this.prefix}/channels/${channelSlug}/follow`, { trigger });
  }

  public registerChannelLead(params: Api.RegisterChannelLeadParams): Promise<Api.RegisterChannelLeadResponse> {
    return this.post(`${this.prefix}/channels/${params.channel}/registerLead`, params);
  }

  public unfollowChannel(channelSlug: string) {
    return this.post(`${this.prefix}/channels/${channelSlug}/unfollow`);
  }

  public handleUserAcceptTerms(): Promise<Api.HandleUserAcceptTermsResponse> {
    return this.post(`${this.prefix}/user/accept-terms`, { origin: 'search' });
  }

  public setUserData(data: string, value: string | boolean | {}): Promise<Api.UserSetDataResponse> {
    return this.post(`${this.prefix}/user/set-data`, { data, value });
  }

  public channelDetailsByName(
    name: string,
    language: Language.Key,
    initialPage?: Channel.Page,
    selectionId?: string
  ): Promise<Api.ChannelPageDetailsBySlug> {
    return this.get(`${this.prefix}/channels/${name}/channelData/${language}/${initialPage}/${selectionId}`);
  }

  public channelContentPage(
    name: string,
    page: Channel.ContentPageKey,
    language: Language.Key
  ): Promise<Api.ChannelContentPage> {
    return this.get(`${this.prefix}/channels/${name}/contentPage/${page}/${language}`);
  }

  public channelFolderPage(name: string, selectionId: string): Promise<Api.ChannelFolderPage> {
    return this.get(`${this.prefix}/channels/${name}/folderPage/${selectionId}`);
  }

  public channelCurriculaPage(name: string, selectionId: string): Promise<Api.ChannelCurriculaPage> {
    return this.get(`${this.prefix}/channels/${name}/curriculaPage/${selectionId}`);
  }

  public embedIframePage(name: string, selectionId: string): Promise<Api.EmbedIframePage> {
    return this.get(`/embed/${selectionId}`);
  }

  public validateVoucher(voucherId: string): Promise<Api.ValidateVoucherResponse> {
    return this.post(`${this.prefix}/vouchers/${voucherId}/validate`);
  }

  public redeemVoucher(voucherId: string): Promise<Api.RedeemVoucherResponse> {
    return this.post(`${this.prefix}/vouchers/${voucherId}/redeem`);
  }

  public saveLessonPlan({ planId, location }: Api.PlanSaveParams): Promise<Api.PlanSaveResponse> {
    return this.post(`${this.prefix}/plans/${planId}/save`, { location });
  }

  public trackAction<K extends TrackerAction>(
    action: K,
    params?: TrackerParams<K>,
    userIds?: Partial<TrackingUserIds>
  ): Promise<void> {
    return this.post(`${this.prefix}/actions/track-action`, { action, params, userIds });
  }

  public publicChannels(language: Language.Key): Promise<Api.ChannelOverviewResponse> {
    return this.get(`${this.prefix}/channels/published`, { language });
  }

  public sendOrganizationJoinRequest(organizationId: string): Promise<Api.SendJoinRequestResponse> {
    return this.post(`${this.prefix}/user/send-join-request`, { organizationId });
  }

  public setSchoolType(schoolTypes: MongoUserProfile['schoolTypes']): Promise<void> {
    return this.post(`${this.prefix}/user/set-schooltypes`, { schoolTypes });
  }

  public getSchoolSuggestions(params: Api.SchoolPickerRequest): Promise<IndexedSchoolSuggestion[]> {
    return this.get(`${this.prefix}/school-picker/get`, params);
  }

  public setName(params: { givenName: string; familyName: string }): Promise<void> {
    return this.post(`${this.prefix}/user/set-name`, params);
  }

  public saveOrganizationRegistration(
    params: Api.SaveOrganizationRegistrationParams
  ): Promise<Api.SaveOrganizationRegistrationResponse> {
    return this.post(`${this.prefix}/user/save-organization-registration`, params);
  }

  public setReasonOnRegistration(params: Api.SaveReasonParams): Promise<void> {
    return this.post(`${this.prefix}/user/save-reason-registration`, params);
  }

  public domainRuleSuggestions(): Promise<DomainRuleSuggestion[]> {
    return this.get(`${this.prefix}/domain-rule/get`);
  }

  public async containsEmailsWhitelistedForOrganization(
    params: Api.ContainsEmailsWhitelistedForOrganizationParams
  ): Promise<boolean> {
    const response = await this.get<
      Api.ContainsEmailsWhitelistedForOrganizationParams,
      Api.ContainsEmailsWhitelistedForOrganizationResponse
    >(`${this.prefix}/domain-rule/emails-whitelisted-for-organization`, params);
    return response.containsWhitelistedEmails;
  }

  public demoLesson(language: LanguageKey): Promise<Api.GetDemoLessonResponse> {
    return this.get(`${this.prefix}/lessons/demo-lesson/get`, { language });
  }

  public lessonDownloadAttachment(
    params: Api.LessonDownloadAttachmentParams
  ): Promise<Api.LessonDownloadAttachmentResponse> {
    return this.post(`${this.prefix}/lessons/${params.lessonId}/attachments/${params.uploadId}/download`);
  }

  public getChildrenFromParent(parentOrganizationID: string): Promise<Api.GetChildrenFromParentResponse> {
    return this.get(`${this.prefix}/organizations/get-children`, {
      parentOrganizationID,
    });
  }

  public referralByReferralToken({
    token,
  }: Api.ReferralByReferralTokenRouteParams): Promise<Api.ReferralByReferralTokenResponse> {
    return this.get(`${this.prefix}/referral/by-token/${token}`) as Promise<Api.ReferralByReferralTokenResponse>;
  }
}
