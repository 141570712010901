import classNames from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LanguageSingleton } from '../../../shared-core/domain/language/Language';
import CheckboxGroup from '../pickers/checkboxGroup/CheckboxGroup';
import './RegistrationCheckboxes';

interface Props {
  termsAccepted: boolean;
  emailAccepted: boolean;
  setTermsAccepted: (val: boolean) => void;
  setEmailAccepted: (val: boolean) => void;
  className?: string;
}

export const RegistrationCheckboxes: React.FC<Props> = (props) => {
  const { t } = useTranslation('registrationCheckboxes');
  const language = LanguageSingleton.get();

  return (
    <div className={classNames('registration-checkboxes', props.className)}>
      <CheckboxGroup
        id="terms"
        onChangeSelect={(_, state) => props.setTermsAccepted(state)}
        label={
          <Trans
            t={t}
            i18nKey="acceptTerms"
            components={[
              <a
                href={language === 'nl' ? '/nl/voorwaarden' : '/en/terms'}
                target="_blank"
                onClick={(event) => event.stopPropagation()}
                rel="noreferrer noopener"
              >
                content
              </a>,
            ]}
          />
        }
        theme="accented-border"
      />
      <CheckboxGroup
        id="email"
        onChangeSelect={(_, state) => props.setEmailAccepted(state)}
        label={t('acceptEmail')}
        theme="accented-border"
      />
    </div>
  );
};
