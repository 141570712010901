import { trackAction } from '@lessonup/client-integration';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { useOnMountEffect } from '../../../shared-core/ui/utils/hooks/useOnMountEffect';
import { RedirectService } from '../../services/RedirectService';
import pageTitle from '../../utils/pageTitle';
import GrayPageLayout from '../GrayPageLayout/GrayPageLayout';
import VoucherPageView from './VoucherPageView';

const TRANSLATION_NS = 'redeemVoucher';
interface Props {
  voucherToken?: string;
}

const VoucherPage: React.FC<Props & RouteComponentProps> = (props) => {
  useOnMountEffect(() => {
    trackAction('redeem-voucher-start');
    RedirectService.setRedirectUrlFromQueryString(props.location.search);
  });

  return (
    <GrayPageLayout className="voucher-page">
      <VoucherPageMetaTags />
      <VoucherPageView />
    </GrayPageLayout>
  );
};

function VoucherPageMetaTags() {
  const { t } = useTranslation(TRANSLATION_NS);

  return (
    <Helmet>
      <title>{pageTitle(t('pageTitle'))}</title>
    </Helmet>
  );
}

export default VoucherPage;
