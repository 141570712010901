import { Colors } from '@lessonup/teaching-core';
import React from 'react';
import { RocketPath } from '../logos/RocketSvg';

const RocketFlightSvg = (props) => {
  return (
    <svg width={462} height={174} viewBox="0 0 462 174" {...props}>
      <g fill="none" fillRule="evenodd">
        <RocketPath transform="translate(369, 10) scale(0.8)" fill={Colors.textToHexMap['red']} />
        <path
          d="M372 79c-54.333 47-95.667 60.5-124 40.5-42.5-30-40-45.5-86 0S49 153 49 106.5s71-58.5 71 2.5-69.5 76.5-123 52"
          stroke={Colors.textToHexMap['red']}
          strokeWidth={2}
          strokeDasharray={5}
        />
      </g>
    </svg>
  );
};
export default RocketFlightSvg;
