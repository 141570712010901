import classNames from 'classnames';
import React from 'react';
import './Cell.less';

export interface CellProps
  extends React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement> {
  className?: string;
  colSpan?: number;
}

export const Cell = React.forwardRef<HTMLTableCellElement, CellProps>(({ children, className, ...props }, ref) => {
  return (
    <td {...props} className={classNames('cell', className)} ref={ref}>
      {children}
    </td>
  );
});
