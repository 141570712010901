import React, { useState } from 'react';
import { Input, InputType } from '../Input/Input';

export interface UseFormInputProps {
  autoComplete?: React.InputHTMLAttributes<HTMLInputElement>['autoComplete'];
  type: InputType;
  requiredMsg?: string;
  initial?: string;
  placeholder?: string;
  label?: string;
  validator?: (value: string | undefined) => boolean;
  id?: string;
  autoFocus?: boolean;
  className?: string;
  onChange?: (value: string | undefined) => void;
}

export const useFormInput = (props: UseFormInputProps): [string | undefined, JSX.Element, () => boolean] => {
  const {
    autoComplete: autocomplete,
    type,
    requiredMsg,
    initial,
    placeholder,
    label,
    validator,
    autoFocus,
    onChange,
    className,
  } = props;

  const [value, setValue] = useState<string | undefined>(initial);
  const [showError, setShowError] = useState<boolean>(false);

  const updateInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStr = event.target.value;
    setValue(newStr);
    onChange && onChange(newStr);
    if (newStr) {
      setShowError(false);
    }
  };

  const validate = (): boolean => {
    if (!requiredMsg) return true;

    const valid = validator ? validator(value) : !!value;
    setShowError(!valid);

    return valid;
  };

  const element = (
    <Input
      onBlur={() => value && validate()}
      onChange={(e) => updateInputValue(e)}
      id={props.id}
      error={showError ? requiredMsg : ''}
      labelText={label}
      autoComplete={autocomplete}
      type={type}
      placeholderText={placeholder}
      onFocus={() => setShowError(false)}
      initial={initial}
      autoFocus={autoFocus}
      className={className}
    />
  );

  return [value, element, validate];
};
