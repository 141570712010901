import classNames from 'classnames';
import React from 'react';
import './AspectRatioBox.less';

interface Props {
  className?: string;
  aspectRatio: number;
  children?: React.ReactNode;
}

const AspectRatioBox: React.FC<Props> = ({ className, aspectRatio, children }) => {
  const paddingTop = aspectRatio * 100 + '%';
  return (
    <div className={classNames('aspect-ratio-box', className)} style={{ paddingTop }}>
      <div className="aspect-ratio-box-content">{children}</div>
    </div>
  );
};

export default AspectRatioBox;
