import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Explorer, ExplorerContentType, Location, SharedExplorer } from '../../../../shared-core/domain/newExplorers';
import { LocationPickerModal } from '../../../../shared-core/ui/components/modals';
import RocketSpinner from '../../../../shared-core/ui/components/spinners/RocketSpinner';
import { ModalProps } from '../../../../shared-core/ui/utils/ModalManagerService';
import { loggedInUser } from '../../../redux/selectors';
import { appServices } from '../../../services/AppServices';

export type LocationPickerModalProviderResponse = Location | undefined;
export interface LocationPickerModalProviderParams {
  translationKey: string;
  createFolder?: () => void;
  enabledTypes?: ExplorerContentType[];
}
type Props = ModalProps<LocationPickerModalProviderResponse> & LocationPickerModalProviderParams;

export const LocationPickerModalProvider: React.FC<Props> = ({ closeModal, translationKey, enabledTypes }) => {
  const [explorer, setExplorer] = useState<Explorer | undefined>();
  const [sharedExplorers, setSharedExplorers] = useState<SharedExplorer[]>([]);

  const getExplorer = async () => {
    const fetched = await appServices.explorer.get();
    setExplorer(fetched.explorer);
    setSharedExplorers(fetched.sharedExplorers);
  };

  useEffect(() => {
    getExplorer();
  }, []);

  const user = useSelector(loggedInUser());

  const fetchLessonsAndPlansForLocation = (location: Location) => appServices.explorer.fetchContent({ location });

  const handleCreateFolder = async (location: Location) => {
    await appServices.explorer.createFolder(location);
    getExplorer();
  };

  return user && explorer ? (
    <LocationPickerModal
      closeModal={closeModal}
      fetchLessonsAndPlansForLocation={fetchLessonsAndPlansForLocation}
      handleCreateFolder={handleCreateFolder}
      rootExplorer={explorer}
      initialExplorer={explorer}
      sharedExplorers={sharedExplorers}
      translationKey={translationKey}
      enabledTypes={enabledTypes}
      user={user}
    />
  ) : (
    <RocketSpinner />
  );
};
