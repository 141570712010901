import React, { useCallback, useState } from 'react';
import { Item } from '../../../../domain/newExplorers';
import { useContextGenerator } from '../../../utils/react';
import { useRowSelection } from '../../Table';

export interface SelectionContext {
  selectedRows: Item[];
  toggleRow: (row: Item) => void;
  setSelectedRows: React.Dispatch<React.SetStateAction<Item[]>>;
  isSelected: (id: string) => boolean;
  activatedRow: Item | null;
  setActivatedRow: (row: Item | null) => void;
  overRideIfNotPartOfSelection: (row: Item) => void;
}

type Context = SelectionContext | undefined;

export const SelectionContext = React.createContext<Context>(undefined);

export const useExplorerSelectionContext = useContextGenerator(SelectionContext, 'SelectionContext');

export interface SelectionContextProviderProps {
  children?: React.ReactNode;
}

export const SelectionContextProvider: React.FunctionComponent<SelectionContextProviderProps> = ({ children }) => {
  const { selectedRows, toggleRow, setSelectedRows, overRideIfNotPartOfSelection } = useRowSelection<Item>();

  const [activatedRow, setActivatedRow] = useState<Item | null>(null);

  const isSelected = useCallback((id: string) => selectedRows.some((item) => item.id === id), [selectedRows]);

  return (
    <SelectionContext.Provider
      value={{
        selectedRows,
        toggleRow,
        isSelected,
        setSelectedRows,
        overRideIfNotPartOfSelection,
        activatedRow,
        setActivatedRow,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};
