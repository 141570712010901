import React from 'react';
import { useBEM } from '../../utils/hooks';
import './StyledTable.less';
import { Table, TableProps } from './Table';

export interface StyledTableProps extends TableProps {
  wrapContent?: boolean;
  zebraStripedRows?: boolean;
  children: React.ReactNode;
}

export const StyledTable: React.FC<StyledTableProps> = ({
  children,
  className,
  wrapContent = true,
  zebraStripedRows = true,
  ...props
}) => {
  const bemClasses = useBEM('StyledTable');

  return (
    <Table
      className={bemClasses({
        modifiers: [...(wrapContent ? [] : ['wrap-content']), ...(zebraStripedRows ? ['zebra'] : [])],
        className: `table table-padding-3 th-left ${className}`,
      })}
      {...props}
    >
      {children}
    </Table>
  );
};
