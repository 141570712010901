import { exhaustiveStringTuple } from '@lessonup/utils';
import { RegistrationStep } from '../../../../shared-core/services/app/searchRoutes';

const registrationSteps = exhaustiveStringTuple<RegistrationStep>()(
  'select',
  'credentials',
  'password',
  'verification-sent',
  'terms',
  'organization',
  'suggestions',
  'demo-lesson',
  'pick-child',
  'pick-child-school-page',
  'referral',
  'registration-gift',
  'verification-expired',
  'verify-email'
);
export const isRegistrationStep = (step: string): step is RegistrationStep => {
  return registrationSteps.includes(step as RegistrationStep);
};
