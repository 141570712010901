import classNames from 'classnames';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ChannelTitle, Facets, IndexedChannelInfo } from '../../../../shared-core/domain';
import { useSearchContext } from '../../../pages/search/sidebar/SearchContext';
import pageTitle from '../../../utils/pageTitle';
import generateMetaDescription from './searchPageMetaDescription';

interface SearchTitleProps {
  count?: number;
  type: 'lessons' | 'plans';
  channelInfo?: IndexedChannelInfo;
}

function SearchPageMeta(props: SearchTitleProps) {
  const { t } = useTranslation('searchPage');
  const { selectedFacets } = useSearchContext();
  let title: string;
  const { count } = props;
  const translationKey = props.type === 'lessons' ? 'onlineLessons' : 'onlinePlans';

  title = count ? t(`${translationKey}Count`, { count, formattedCount: count.toLocaleString() }) : t(translationKey);

  const { schoolType, yearAndLevel, subject } = Facets.obtainMetaDataInfoForSelectedFacets(selectedFacets);

  if (subject) title += ` ${subject}`;

  title = title + ' ' + classNames(schoolType && schoolType.toLowerCase(), yearAndLevel);

  const channelTitle = props.channelInfo?.title && ChannelTitle.localizeTitle(props.channelInfo.title);
  const channelTitleSuffix = channelTitle ? ' ' + t('byChannel', { channelTitle }) : '';

  return (
    <Helmet>
      <title>{pageTitle(title + channelTitleSuffix)}</title>
      {count !== undefined && <meta name="robots" content={count ? 'index' : 'noindex'} />}
      <meta name="description" content={generateMetaDescription(selectedFacets, count, props.type, t)} />
    </Helmet>
  );
}

export default SearchPageMeta;
