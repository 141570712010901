import _ from 'lodash';
import { useEffect, useRef } from 'react';

interface Params<T> {
  initial: T;
  params: T;
  callback: (params: T, previous: T | undefined) => void;
  isEqual?: (value: T, other: T) => boolean;
  debug?: boolean;
  changeOnUserChange?: boolean;
  isUserLoggedIn?: boolean;
}
/**
 * Call callback if params changed
 */
const useCallbackIfParamsChanged = <T>({
  initial,
  params,
  callback,
  debug,
  isEqual,
  changeOnUserChange,
  isUserLoggedIn,
}: Params<T>) => {
  const lastParams = useRef<T>(initial);
  const compare = isEqual || _.isEqual;
  const isLoggedIn = useRef<boolean | undefined>(isUserLoggedIn);

  useEffect(() => {
    if (compare(params, lastParams.current)) {
      return;
    }
    if (debug) {
      console.log('debug useCallbackIfParamsChanged is fetching for param change');
    }
    callback(params, lastParams.current);
    lastParams.current = params;
  }, [callback, compare, lastParams, params]);

  useEffect(() => {
    if (!changeOnUserChange || isLoggedIn.current === isUserLoggedIn) return;

    if (debug) {
      console.log('debug useCallbackIfParamsChanged is fetching for user change');
    }
    callback(params, lastParams.current);
    isLoggedIn.current = isUserLoggedIn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);

  if (debug) {
    console.log('debug useCallbackIfParamsChanged', {
      initial,
      params,
      equal: compare(params, lastParams.current),
    });
  }
};

export default useCallbackIfParamsChanged;
