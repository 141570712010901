import { Component } from '@lessonup/teaching-core';

export namespace MathUtils {
  export type Point = { x: number; y: number };
  export type AbsolutePoint = { x: number; y: number; type: 'absolute' }; // for px
  export type RelativePoint = { x: number; y: number; type: 'relative' }; // for %
  export interface Container {
    width: number;
    height: number;
  }
  type Actions = {
    top: boolean;
    right: boolean;
    bottom: boolean;
    left: boolean;
    move: boolean;
    rotate: boolean;
  };

  function absolutePoint(point: Point): AbsolutePoint {
    return {
      x: point.x,
      y: point.y,
      type: 'absolute',
    };
  }
  export function relativePoint(point: Point): RelativePoint {
    return {
      x: point.x,
      y: point.y,
      type: 'relative',
    };
  }

  export function degreesToRadians(deg: number): number {
    const rad = (deg * Math.PI) / 180;
    return rad;
  }

  export function radiansToDegrees(rad: number): number {
    const deg = (rad * 180) / Math.PI;
    return deg;
  }

  export function getCenterPointOfComponent(box: Component.Box, container: Container): AbsolutePoint {
    const boxSize = getRelativeBoxSize(box);
    const relativePosition: RelativePoint = calculateRelativeCenterPosition(box, boxSize);
    return calculateAbsolutePosition(relativePosition, container);
  }

  export function calculateRelativeCenterPosition(box: Component.Box, boxSize: Container): RelativePoint {
    const point: Point = {
      x: box.left + boxSize.width / 2,
      y: box.top + boxSize.height / 2,
    };
    return relativePoint(point);
  }

  export function calculateAbsolutePosition(position: RelativePoint | Point, container: Container): AbsolutePoint {
    const point: Point = {
      x: (position.x / 100) * container.width,
      y: (position.y / 100) * container.height,
    };
    return absolutePoint(point);
  }

  export function calculateRelativePosition(position: AbsolutePoint | Point, container: Container): RelativePoint {
    const point: Point = {
      x: (position.x / container.width) * 100,
      y: (position.y / container.height) * 100,
    };
    return relativePoint(point);
  }

  export function simpleRound(number: number, decimals: number) {
    const multiplier = Math.pow(10, decimals);
    return Math.round(number * multiplier) / multiplier;
  }

  export function getRelativeBoxSize(box: Component.Box, inContainer?: Container): Container {
    const containerWidth = inContainer?.width || 100;
    const containerHeigth = inContainer?.height || 100;
    const boxSize: Container = {
      width: containerWidth - box.right - box.left,
      height: containerHeigth - box.bottom - box.top,
    };
    return boxSize;
  }

  export function distanceFromCenter(center: Point, point: Point) {
    return Math.sqrt(Math.pow(Math.abs(center.x - point.x), 2) + Math.pow(Math.abs(center.y - point.y), 2));
  }

  export function getAngleOnCircle(center: Point, p1: Point) {
    const p0 = {
      x: center.x,
      y:
        center.y -
        Math.sqrt(
          Math.abs(p1.x - center.x) * Math.abs(p1.x - center.x) + Math.abs(p1.y - center.y) * Math.abs(p1.y - center.y)
        ),
    };
    return (2 * Math.atan2(p1.y - p0.y, p1.x - p0.x) * 180) / Math.PI;
  }

  export function polarCoordinateToPoint(center: Point, radius: number, angleInDegrees: number) {
    const angleInRadians = degreesToRadians(angleInDegrees - 90);

    return {
      x: center.x + radius * Math.cos(angleInRadians),
      y: center.y + radius * Math.sin(angleInRadians),
    };
  }
}
