// 1 till LESSON_THUMBNAIL_PIXEL_RATIO
export type LessonThumbnailPixelRatio = 1 | 2 | 3 | 4;

export const LESSON_THUMBNAIL_WIDTH = 231;
export const LESSON_THUMBNAIL_HEIGHT = 169;
export const LESSON_THUMBNAIL_RATIO = LESSON_THUMBNAIL_HEIGHT / LESSON_THUMBNAIL_WIDTH;

export const LESSON_THUMBNAIL_PIXEL_RATIO = 4;

export const LESSON_THUMBNAILER_VERSION = 3;
