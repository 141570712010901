import { UserRatings } from '@lessonup/firebase-database';
import React from 'react';
import { IndexedLesson, SearchResultItem } from '../../../../shared-core/domain/search';
import { FlexBoxDummies } from '../../../../shared-core/ui/utils/FlexBoxDummies';
import LessonSummaryCard from './LessonSummaryCard';

interface Props {
  items: SearchResultItem<IndexedLesson>[];
  userRatings: UserRatings;
  inset?: boolean;
  cardClassName?: string;
  lessonUrlCreator?: (lessonId: string) => string;
}

const LessonSummaryCards: React.FunctionComponent<Props> = ({
  userRatings,
  items,
  inset,
  cardClassName,
  lessonUrlCreator,
}) => {
  const Lessons = items
    .map((item) => {
      return (
        <LessonSummaryCard
          className={cardClassName}
          lesson={item.doc.lesson}
          channel={item.doc.channel}
          metaData={item.doc.metaData}
          key={item._id}
          userRating={userRatings[item._id]}
          inset={inset}
          explanation={item.explanation}
          linkTo={lessonUrlCreator}
        />
      );
    })
    // Dummy cards to ensure equal widths of all cards
    .concat(FlexBoxDummies('lesson-summary-card-dummy', 10));

  return <div className="lesson-summary-cards">{Lessons}</div>;
};

export default LessonSummaryCards;
