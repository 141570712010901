import { logger } from '@lessonup/client-integration';
import { DefaultFirestoreDatabase, Session } from '@lessonup/firebase-database';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of } from 'rxjs';
import { map, share, startWith, switchMap, tap } from 'rxjs/operators';
import { distinctUntilChangedIsEqual } from '../../../utils';
import { FirebaseAuthService } from './FirebaseAuthService';

export type SessionClientServiceStatus = 'pending' | 'valid' | 'invalid';
export interface DataProvider {
  startSession: () => Promise<Session.Token>;
  disableSessionLock?: () => boolean;
}

export class SessionClientService {
  private readonly tokenFromStorageTrigger = new BehaviorSubject<number>(Date.now());
  private readonly hadValidSession = new BehaviorSubject(false);
  private readonly fetching = new BehaviorSubject(false);
  private readonly statusSubject = new BehaviorSubject<SessionClientServiceStatus>('pending');
  private serviceStarted = false;

  public constructor(
    private readonly firebaseAuth: FirebaseAuthService,
    private readonly firestoreDatabase: DefaultFirestoreDatabase,
    private readonly data: DataProvider
  ) {}

  public statusObs(): Observable<SessionClientServiceStatus> {
    return this.statusSubject.asObservable();
  }

  public status(): SessionClientServiceStatus {
    return this.statusSubject.getValue();
  }

  public resumeSession(): Promise<void> {
    return this.startNewSession();
  }

  public startStatusSubscription(): void {
    if (this.serviceStarted) return;
    this.serviceStarted = true;

    const isDisabled = this.isDisabled();

    combineLatest([
      this.userObs(),
      this.latestTokenObs,
      this.firebaseSessionObs(),
      this.fetchingObs(),
      this.hadValidSessionObs(),
    ])
      .pipe(
        map(([user, localToken, session, fetching, hadValidSession]): SessionClientServiceInternalStatus => {
          return internalSessionState({
            userId: user?.uid,
            isAnonymous: user?.isAnonymous,
            localToken,
            fetching,
            hadValidSession,
            session,
            isDisabled,
          });
        }),
        distinctUntilChangedIsEqual(),
        tap((state) => {
          if (state === 'shouldFetchToken') {
            this.startNewSession();
          }
          if (state === 'validSession') {
            this.hadValidSession.next(true);
          }
        }),
        map((value) => {
          return externalSessionState(value);
        }),
        distinctUntilChangedIsEqual()
      )
      .subscribe(this.statusSubject);
  }

  public endSession(userId: string): void {
    localStorage.removeItem(this.localStorageKeyForUser(userId));
  }

  private userObs() {
    return this.firebaseAuth.validUserStream();
  }

  private isDisabled() {
    if (!this.data.disableSessionLock) return false;
    const disableSessionLock = this.data.disableSessionLock();
    return disableSessionLock;
  }

  private firebaseSessionObs(): Observable<Session | undefined | 'notActive'> {
    return combineLatest([this.fetchingObs(), this.latestTokenObs]).pipe(
      switchMap(([fetching, localToken]) => {
        if (fetching || !localToken) return of<'notActive'>('notActive');
        return this.firestoreDatabase.sessions.sessionObs(localToken.token);
      })
    );
  }

  /**
   * Whenever one of these sources change, trigger refetch
   */
  private latestTokenObs: Observable<Session.Token | undefined> = combineLatest([
    this.userObs(),
    fromEvent<StorageEvent>(window, 'storage').pipe(startWith(null)),
    this.tokenFromStorageTrigger.asObservable(),
  ]).pipe(
    map(([user]) => {
      if (!user) return undefined;
      return localStorage.getItem(this.localStorageKeyForUser(user.uid));
    }),
    map((token) => {
      if (token) return JSON.parse(token) as Session.Token;
    }),
    distinctUntilChangedIsEqual(),
    share()
  );

  private hadValidSessionObs() {
    return this.hadValidSession.asObservable().pipe(distinctUntilChangedIsEqual());
  }

  private fetchingObs() {
    return this.fetching.asObservable().pipe(distinctUntilChangedIsEqual());
  }

  private async startNewSession() {
    if (this.fetching.getValue()) return;
    try {
      this.fetching.next(true);
      const token = await this.data.startSession();
      if (token) {
        localStorage.setItem(this.localStorageKeyForUser(token.userId), JSON.stringify(token));
        this.triggerTokenLocalStorageUpdate();
      }
      this.fetching.next(false);
    } catch (error) {
      // ignore, we want to stay on pending, we can improve this later
      logger.error(error);
    }
  }

  /**
   * watching localStorage only works for other tabs, so manual update
   */
  private triggerTokenLocalStorageUpdate() {
    this.tokenFromStorageTrigger.next(Date.now());
  }

  private localStorageKeyForUser(userId: string) {
    return `Session-Token-${userId}`;
  }
}

export type SessionClientServiceInternalStatus =
  | 'init'
  | 'disabled'
  | 'shouldFetchToken'
  | 'pending'
  | 'validSession'
  | 'invalidSession';

interface StateReducerParams {
  userId: string | undefined;
  isAnonymous: boolean | undefined;
  localToken: Session.Token | undefined;
  fetching: boolean;
  hadValidSession: boolean;
  session: Session | undefined | 'notActive';
  isDisabled: boolean;
}

export function internalSessionState({
  userId,
  isAnonymous,
  localToken,
  fetching,
  hadValidSession,
  session,
  isDisabled,
}: StateReducerParams): SessionClientServiceInternalStatus {
  if (isDisabled || isAnonymous || !userId) return 'disabled';
  if (fetching) return 'pending';
  if (!localToken) return 'shouldFetchToken';
  if (session === 'notActive') return 'pending';

  // If we have a token and had a valid session, we should just assume fresh login or refresh
  const initialBoot = !session && !hadValidSession;
  if (initialBoot) {
    return 'shouldFetchToken';
  }
  return session ? 'validSession' : 'invalidSession';
}
/**
 * Should be enough for the rest off the app.
 */
export function externalSessionState(state: SessionClientServiceInternalStatus): SessionClientServiceStatus {
  if (state === 'validSession' || state === 'disabled') return 'valid';
  if (state === 'invalidSession') return 'invalid';
  return 'pending';
}
