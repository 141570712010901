import { AssignmentPin, QuizQuestion } from '@lessonup/teaching-core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { ContentTypeDefaults } from '../../../../utils/ContentTypeDefaults';
import TextNewLineToBr from '../../../components/text/TextNewLineToBr';
import ImageComponent, { ImageComponentPropsForPin } from '../../images/ImageComponent';
import PinContainer from '../../pin/PinContainer';
import { MapPinProps } from '../../pin/PinView';
import ViewComponents from '../../viewComponents/ViewComponents';
import QuizAnswer from './QuizAnswer';

interface Props extends MapPinProps {
  pin: AssignmentPin.Quiz;
  options?: DataOptionState[];
  order?: QuizQuestion.AnswerValue[];
}

type Style = 'correct' | 'incorrect' | 'opinion' | 'default' | 'iscorrect';

export interface DataOptionState {
  key: QuizQuestion.AnswerValue;
  isLoading?: boolean;
  style?: Style;
  order?: number | undefined;
}

interface SelectedAnswer {
  value: QuizQuestion.AnswerValue;
  style: Style;
}

const QuizView: React.FC<Props> = (props) => {
  const [selectedAnswer, setSelectedAnswer] = useState<SelectedAnswer | undefined>(undefined);
  const { pin, isThumb, order, options } = props;
  const { item } = pin;

  useEffect(() => {
    setSelectedAnswer(undefined); // Prevent state reuse in case of subsequent QuizViews.
  }, [pin._id]);

  function onClickHandler(key: any): void {
    let style: Style = 'default';

    const { correctAnswers } = props;

    if (!correctAnswers || !correctAnswers.length) {
      style = 'opinion';
    } else {
      style = correctAnswers.includes(key) ? 'correct' : 'incorrect';
    }
    setSelectedAnswer({ value: key, style });
  }

  function renderAnswers() {
    const answers = _.sortBy(pin.item.custom.answers, (answer, index) => {
      const shuffledIndex = _.indexOf(order, answer.value);
      return shuffledIndex !== -1 ? shuffledIndex : index;
    });

    const views = answers.map((answer) => {
      const option = _.find(options, (o) => o.key === answer.value);
      const style = selectedAnswer && selectedAnswer.value === answer.value ? selectedAnswer.style : 'default';
      const isLoading = (option && option.isLoading) || false;

      return (
        <QuizAnswer
          key={answer.value}
          answer={answer}
          isLoading={isLoading}
          style={style}
          onClickHandler={!isThumb ? onClickHandler : undefined}
          fontSize={item.custom.fontSize2}
        />
      );
    });

    return views;
  }

  const questionClasses = () => {
    const pinContent = item.custom;
    const textAlignClass = `text-align-${pinContent.align}`;
    const fontSize = pinContent.fontSize1 ? pinContent.fontSize1 : ContentTypeDefaults.getDefaultFontSize(1, item.type);
    const fontSizeClass = `font-size-${fontSize}`;

    return ['question', textAlignClass, fontSizeClass].join(' ');
  };

  const imageProps = ImageComponentPropsForPin(pin, 'image', isThumb);

  return (
    <PinContainer pin={pin}>
      {item.image && <ImageComponent {...imageProps} />}
      <div id="title" className={questionClasses()}>
        <TextNewLineToBr string={item.custom.question} />
      </div>
      <div className="component-area">
        <div className="scale-up">
          <ViewComponents
            components={item.components}
            isThumb={isThumb}
            expandOnlyOne={item.settings?.closeOtherHotspots}
          />
        </div>
      </div>

      <div id="answers" className="show-in-fullscreen">
        {renderAnswers()}
      </div>
    </PinContainer>
  );
};

export default QuizView;
