import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Colors, Poll } from '@lessonup/teaching-core';
import { PollScaleInput } from './PollScaleInput';
import './RealtimePollScale.less';

const activeColor = Colors.textToHexMap.yellow;
const inactiveColor = Colors.textToHexMap['air-blue'];
const white = Colors.textToHexMap.white;

interface Props {
  pinContent: Poll.Scale;
  pollAnswer: number | undefined;
  handleSubmitAnswer?: (answer: number) => Promise<void>;
  isThumb?: boolean;
  fontSize?: string;
  markerHeight?: string;
  hideScore?: boolean;
  loading?: boolean;
}

declare const i18n;

const RealtimePollScale: React.FC<Props> = (props) => {
  const { pinContent, handleSubmitAnswer, pollAnswer } = props;
  const min = pinContent.range.min;
  const max = pinContent.range.max;
  const initialMarkerPosition = calcMarkerPosition(props.pollAnswer, min, max).toString() + '%';
  const initialValue = useRef(props.pollAnswer ?? Math.round((max - min) / 2 + min));
  const [scaleValue, setScaleValue] = useState<number>(Math.round(props.pollAnswer ?? (max - min) / 2 + min));
  const [markerStyle, setMarkerStyle] = useState<{ left: string }>({
    left: initialMarkerPosition,
  });

  const setRangeChange = useCallback(
    (rangeValue: number, min: number, max: number) => {
      if (rangeValue === scaleValue) {
        return;
      }
      const newValue = rangeValue;
      setScaleValue(newValue);
      setMarkerStyle({ left: calcMarkerPosition(newValue, min, max).toString() + '%' });
    },
    [scaleValue]
  );

  useEffect(() => {
    if (!props.handleSubmitAnswer || typeof pollAnswer !== 'number') return;

    if (initialValue && initialValue.current !== pollAnswer) {
      setRangeChange(pollAnswer, min, max);
      initialValue.current = pollAnswer;
    }
  }, [max, min, pollAnswer, props.handleSubmitAnswer, setRangeChange]);

  useEffect(() => {
    if (typeof pollAnswer !== 'undefined') {
      return;
    }

    const newScaleValue = Math.round((max - min) / 2 + min);
    setScaleValue(newScaleValue);
    setMarkerStyle({ left: calcMarkerPosition(newScaleValue, min, max).toString() + '%' });
  }, [min, max, pollAnswer]);

  useEffect(() => {
    function cleanup() {
      if (typeof pollAnswer !== undefined || !props.handleSubmitAnswer) return;

      props.handleSubmitAnswer(scaleValue);
    }
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRangeChange = (rangeValue: number) => {
    if (!handleSubmitAnswer) return;
    setRangeChange(rangeValue, min, max);
  };

  const updateStudentAnswer = (event) => {
    event?.preventDefault();
    return handleSubmitAnswer && handleSubmitAnswer(scaleValue);
  };

  const showScore = !props.hideScore && (typeof props.pollAnswer !== 'undefined' || handleSubmitAnswer);

  return (
    <div className="scale-wrapper" data-pin={props.pollAnswer}>
      <div
        className={classNames(
          'scale-input-container',
          props.isThumb && 'is-thumb',
          props.handleSubmitAnswer && 'scale-fontsize-fix'
        )}
      >
        <div
          className={classNames(
            props.markerHeight ? props.markerHeight : 'scale-marker-homework',
            'scale-marker-container'
          )}
        >
          {showScore && (
            <span style={markerStyle} className="scale-marker">
              {scaleValue}
            </span>
          )}
        </div>
        <form onSubmit={(event) => updateStudentAnswer(event)}>
          <PollScaleInput
            answerData={{ range: pinContent.range, answer: scaleValue }}
            className={props.fontSize}
            onChange={props.handleSubmitAnswer && handleRangeChange}
            style={gradientStyle(inactiveColor, markerStyle.left)}
          />
          <div className="scale-label-wrapper">
            <span className="scale-label scale-min">{min}</span>
            <span className="scale-label scale-max">{max}</span>
          </div>
          {handleSubmitAnswer && (
            <button
              type="submit"
              data-role="submit-poll-scale-answer"
              disabled={props.pollAnswer === scaleValue}
              className={classNames('button scale-button cw fw content-item-button', props.loading && 'event-loading')}
            >
              {typeof pollAnswer === 'undefined'
                ? i18n.__('Bewaren')
                : props.loading
                ? i18n.__('Aan het bewaren')
                : pollAnswer !== scaleValue
                ? i18n.__('Bewaren')
                : i18n.__('Bewaard')}
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default RealtimePollScale;

const calcMarkerPosition = (value: number | undefined, min: number, max: number) => {
  const rangeSize = max - min;
  if (value === undefined) return (Math.round(rangeSize / 2) / rangeSize) * 100;

  return value === 0 ? min : Math.round(((value - min) / rangeSize) * 100);
};

const gradientStyle = (color: string, percentage: string) => {
  return {
    background: 'linear-gradient(to right, ' + color + ' ' + percentage + ' ,' + white + ' ' + percentage + ')',
  };
};
