import _, { isString } from 'lodash';
import qs from 'qs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Auth } from '../../../../shared-core/domain/authorization/Auth';
import { forgotPasswordRoute } from '../../../../shared-core/services/app/searchRoutes';
import { teacherMyLessonsUrl } from '../../../../shared-core/services/app/teacherRoutes';
import ActionButton from '../../../../shared-core/ui/components/Button';
import TextButton from '../../../../shared-core/ui/components/buttons/TextButton';
import '../../../../shared-core/ui/components/inputElements/FormStyles.less';
import { useFormInput } from '../../../../shared-core/ui/components/inputElements/useFormInput';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import { ServiceComponentProps } from '../../../components/appServices/WithServices';
import { LoginModalConfig } from '../../../components/login/LoginModal';
import './ResetPasswordPage.less';

type Props = ServiceComponentProps & RouteComponentProps;

interface ResetPasswordFormProps {
  submitResetPassword: (password: string) => void;
}

const ResetPasswordPage: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation('resetPassword');
  const { modals, user } = useAppServices();
  const [status, setStatus] = useState<'form' | 'success' | 'failed'>('form');

  async function submitResetPassword(password: string): Promise<void> {
    const { token, userId } = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });

    if (!token || !userId) {
      setStatus('failed');
      return;
    }

    const parsedUserId = isString(userId) ? userId : JSON.stringify(userId);
    const parsedToken = isString(token) ? token : JSON.stringify(token);

    const response = await user.resetPassword(parsedUserId, parsedToken, password);
    setStatus(response ? 'success' : 'failed');
  }

  async function openLogin(initialPage?: LoginModalConfig['initialPage']) {
    await modals.open('login', { userService: user, modals, initialPage });
  }

  function content() {
    if (status === 'form') {
      return (
        <React.Fragment>
          <h1 className="reset-password-title">{t('resetPasswordTitle')}</h1>
          <p className="reset-password-description">{t('resetPasswordDescription')}</p>
          <ResetPasswordForm submitResetPassword={submitResetPassword} />
          <TextButton onClick={() => openLogin()}>{t('rememberPassword')}</TextButton>
        </React.Fragment>
      );
    } else if (status === 'success') {
      return (
        <React.Fragment>
          <h1 className="reset-password-title">{t('resetPasswordSuccessTitle')}</h1>
          <p className="reset-password-description">{t('resetPasswordSuccessDescription')}</p>
          <a href={teacherMyLessonsUrl()}>
            <ActionButton theme="secondary">{t('backToSearch')}</ActionButton>
          </a>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h1 className="reset-password-title">{t('resetPasswordFailedTitle')}</h1>
          <p className="reset-password-description">{t('resetPasswordFailedDescription')}</p>
          <Link to={forgotPasswordRoute()}>
            <ActionButton theme="secondary">{t('requestNewToken')}</ActionButton>
          </Link>
        </React.Fragment>
      );
    }
  }

  return (
    <div className="reset-password-page">
      <div className="reset-password-inner">{content()}</div>
    </div>
  );
};

const ResetPasswordForm: React.FunctionComponent<ResetPasswordFormProps> = (props) => {
  const { t } = useTranslation('resetPassword');

  const [password, firstPasswordInput, validateFirstPasswordInput] = useFormInput({
    type: 'password',
    placeholder: t('password'),
    requiredMsg: t('firstPasswordError'),
    validator: Auth.validateRegistrationPasswordRequirements,
  });

  const [confirmPassword, confirmPasswordInput, validateconfirmPasswordInput] = useFormInput({
    type: 'password',
    placeholder: t('confirmPassword'),
    requiredMsg: t('confirmPasswordError'),
    validator: (confirmPassword) => _.isEqual(password, confirmPassword),
  });

  function submitHandler(e: React.MouseEvent | React.TouchEvent) {
    e.preventDefault();
    const validateAllInputs = [validateFirstPasswordInput(), validateconfirmPasswordInput()].every((val) => val);
    if (!validateAllInputs || !confirmPassword || !password) {
      return;
    }

    props.submitResetPassword(password);
  }

  return (
    <form className="reset-password-form default-form">
      {firstPasswordInput}
      {confirmPasswordInput}
      <div className="button-bar">
        <ActionButton onClick={submitHandler} theme="primary">
          {t('resetPasswordSubmit')}
        </ActionButton>
      </div>
    </form>
  );
};

export default ResetPasswordPage;
