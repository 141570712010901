import React from 'react';
import { useBEM } from '../../utils/hooks';
import './Switch.less';

export interface SwitchProps {
  checked: boolean;
  onChange?: (state: boolean) => void;
  label?: string;
  className?: string;
}

export const Switch: React.FC<SwitchProps> = (props) => {
  const bemClasses = useBEM('Switch');

  function handleChange() {
    props.onChange && props.onChange(!props.checked);
  }

  return (
    <div className={bemClasses({ element: 'container', className: props.className })}>
      {props.label && (
        <label
          htmlFor="switch"
          className={bemClasses({ element: 'label' })}
          onMouseUp={() => handleChange()}
          onKeyUp={(event) => event.key === ' ' && handleChange()}
          tabIndex={0}
        >
          {props.label}
        </label>
      )}
      <label
        className={bemClasses()}
        onMouseUp={() => handleChange()}
        onKeyUp={(event) => event.key === ' ' && handleChange()}
      >
        <input
          type="checkbox"
          role="switch"
          id="switch"
          className={bemClasses({ element: 'input' })}
          checked={props.checked}
          readOnly
        />
        <span className={bemClasses({ element: 'slider' })}></span>
      </label>
    </div>
  );
};
