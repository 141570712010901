import classNames from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Channel, ChannelTab, isDefaultChannelTab } from '../../../shared-core/domain/channels/Channel';
import { channelRoute } from '../../../shared-core/services/app/searchRoutes';
import CloseCrossSvg from '../../../shared-core/ui/components/svgIcons/CloseCrossSvg';
import useOnClickOutside from '../../../shared-core/ui/utils/useOnClickOutside';
import useToggle from '../../../shared-core/ui/utils/useToggle';
import capitalize from '../../../shared-core/utils/capitalize';
import { htmlDecode } from '../../utils/htmlDecode';
import './ChannelTabs.less';

interface ChannelTabsProps {
  channel: Channel;
  children?: React.ReactNode;
}

interface ChannelTabProps {
  tab: ChannelTab;
  active: boolean;
  className?: string;
  channelName: string;
}

const ChannelTabsList: React.FC<ChannelTabsProps> = (props) => {
  const { t } = useTranslation(['channel', 'common']);
  const [expandedMenu, toggleExpandedMenu] = useToggle(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    if (expandedMenu) toggleExpandedMenu(false);
  });

  return (
    <div className={classNames('channel-tabs-list', { expanded: expandedMenu })} ref={ref}>
      <div className="d-sm-none small-screen-menu-toggle" onClick={() => toggleExpandedMenu()}>
        {expandedMenu ? <CloseCrossSvg /> : t('common:menu')}
      </div>
      <div className="channel-tabs">{props.children}</div>
    </div>
  );
};

export const ChannelTabItem: React.FC<ChannelTabProps & React.HTMLAttributes<HTMLDivElement>> = ({
  tab,
  active,
  className,
  channelName,
}) => {
  const route = isDefaultChannelTab(tab)
    ? channelRoute({ channelSlug: channelName, channelPage: tab.id })
    : channelRoute({ channelSlug: channelName, channelPage: 'series', selectionId: tab.id });

  return (
    <Link to={route}>
      <div className={classNames('channel-tab', className, { active })}>{capitalize(htmlDecode(tab.name) || '')}</div>
    </Link>
  );
};

export default ChannelTabsList;
