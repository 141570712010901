import { LessonPin } from '@lessonup/teaching-core';
import classNames from 'classnames';
import React from 'react';

import Checkbox from '../../../../../shared-core/ui/components/Checkbox';
import PinThumbBottomBar from '../../../../../shared-core/ui/components/pin/PinThumbBottomBar/PinThumbBottomBar';
import PinView from '../../../../../shared-core/ui/components/pin/PinView';
import InfoSvg from '../../../../../shared-core/ui/components/svgIcons/InfoSvg';

interface PinResultProps {
  pin: LessonPin;
  selected: boolean;
  toggleSelectPin: (pin: string) => void;
  openPinInfo?: (pinId: string) => void;
  pinSize?: 250 | 350 | 400;
  highlighted?: boolean;
  onClick?: (pinId: string) => void;
}

export const PinResult: React.FunctionComponent<PinResultProps> = ({
  pin,
  selected,
  toggleSelectPin,
  openPinInfo,
  pinSize,
  highlighted,
  onClick,
}: PinResultProps) => {
  const handlePinInfoClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!openPinInfo) return;
    event.stopPropagation();
    openPinInfo(pin._id);
  };
  const handlePinClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!onClick) return;
    event.stopPropagation();
    onClick(pin._id);
  };

  return (
    <div className={classNames('pin-result', { highlighted: !!highlighted })} key={pin._id} onClick={handlePinClick}>
      <div className={`pin-size-${pinSize || 400}`}>
        <PinView pin={pin} isThumb={true} />

        <PinThumbBottomBar>
          <Checkbox
            id={`pin-result-checkbox-${pin._id}`}
            checked={selected}
            onChange={() => toggleSelectPin(pin._id)}
            theme="secondary"
            height="large"
            stopPropagation={true}
          />
          {openPinInfo && (
            <div className="pin-result-info" onClick={handlePinInfoClick}>
              <InfoSvg />
            </div>
          )}
        </PinThumbBottomBar>
      </div>
    </div>
  );
};
