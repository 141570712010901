import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { featureFlagsForUser } from '../../redux/selectors';

const useFeatureFlags = () => {
  const featureFlags = useSelector(featureFlagsForUser());

  const isFeatureFlagEnabled = useCallback((flag: string) => featureFlags.includes(flag), [featureFlags]);

  return { isFeatureFlagEnabled };
};

export default useFeatureFlags;
