import { find } from 'lodash';
import { Explorer, ExplorerPermission } from '../newExplorers/Explorer';
import { OrganizationId } from './Organization';

export namespace OrganizationSharedExplorer {
  export interface Settings {
    id: OrganizationId;
    permission?: ExplorerPermission;
  }

  export const getDefaultPermission = (organizationId: string, explorer: Explorer): ExplorerPermission | undefined => {
    const explorerSettings = settingsForOrganization(organizationId, explorer);

    return explorerSettings?.permission;
  };

  export function organizationIdsForExplorer(explorer: Explorer | undefined): string[] {
    if (!explorer?.organizations) return [];
    return explorer.organizations.map((org) => org.id);
  }

  export const hasSettings = (organizationId: OrganizationId, explorer: Explorer): boolean =>
    !!explorer.organizations?.some(({ id }) => organizationId === id);

  const settingsForOrganization = (organizationId: string, explorer: Explorer): Settings | undefined =>
    find(explorer.organizations, { id: organizationId });
}
