import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssignmentPin } from '@lessonup/teaching-core';
import { ImageUtils } from '../../../../utils/ImageUtils';
import { PinViewUtils } from '../../../../utils/PinViewUtils';
import { MapPinProps } from '../../pin/PinView';
import './LinkView.less';

interface Props extends MapPinProps<AssignmentPin.Link> {
  pin: AssignmentPin.Link;
}

interface State {
  elementWidth: number | undefined;
}

interface WebPageProps {
  image?: string;
  url?: string;
  openButtonText?: string;
  refCallback: (e) => void;
  elementWidth: number | undefined;
  onClickHandler?: (e: React.MouseEvent) => void;
}

const LinkView: React.FC<Props> = (props) => {
  const { t } = useTranslation('linkView');
  const [elementWidth, setElementWidth] = useState<number | undefined>(undefined);

  function refCallback(element) {
    if (element) {
      setSize(element.getBoundingClientRect().width);
    }
  }

  function setSize(width: number) {
    setElementWidth(width);
  }

  function onClickHandler() {
    window.open(props.pin.item.url, '_blank');
  }

  const { url, image } = props.pin.item;

  if (props.isThumb) {
    return (
      <WebPageLink
        refCallback={refCallback}
        elementWidth={elementWidth}
        image={image}
        url={(props.pin.item.custom as any).domain || ''}
      />
    );
  } else {
    return (
      <WebPageLink
        refCallback={refCallback}
        elementWidth={elementWidth}
        url={url}
        image={image}
        openButtonText={t('openLink')}
        onClickHandler={onClickHandler}
      />
    );
  }
};

const WebPageLink = (props: WebPageProps) => {
  return (
    <div
      ref={props.refCallback}
      className="weblink-viewer"
      style={{ fontSize: PinViewUtils.calcFontSize(props.elementWidth) }}
    >
      <div
        className="weblink-bg"
        style={{ backgroundImage: ImageUtils.urlString(ImageUtils.checkImageUrl(props.image)) }}
      ></div>

      <div className="browser" onClick={props.onClickHandler}>
        <div className="browserbar">
          <div className="browser-buttons">
            <div className="browser-button c1"></div>
            <div className="browser-button c2"></div>
            <div className="browser-button c3"></div>
          </div>
          <div className="url">{props.url || ''}</div>
        </div>

        <div
          className="webpage"
          style={{ backgroundImage: ImageUtils.urlString(ImageUtils.checkImageUrl(props.image)) }}
        >
          <div className="glass"></div>
          {props.openButtonText && <div className="open-button">{props.openButtonText}</div>}
        </div>
      </div>
    </div>
  );
};

export default LinkView;
