import { TrackerAction, TrackerParams } from '@lessonup/utils';
import { isNil, toNumber } from 'lodash';

type GaCategory = 'users' | 'lessons' | 'channels';

interface UAAction {
  eventName: string;
  event_category: GaCategory;
  event_label?: string;
  value?: number;
}

export const channelGAActions = [
  'save-lesson',
  'share-lesson',
  'start-presenting',
  'finish-presenting',
  'follow-channel',
  'unfollow-channel',
  'view-channel',
  'view-lesson',
];

export function channelGAActionFromAction<K extends TrackerAction, P extends TrackerParams<K>>(
  action: K,
  params: P
): UAAction | undefined {
  switch (action) {
    case 'save-lesson':
      return { eventName: action, event_category: 'lessons', ...getUALabelOrValue(params?.['label']) };
    case 'share-lesson':
      return { eventName: action, event_category: 'lessons', ...getUALabelOrValue('teacher') };
    case 'start-presenting':
      return { eventName: action, event_category: 'lessons' };
    case 'finish-presenting':
      return { eventName: action, event_category: 'lessons', ...getUALabelOrValue(params?.['secondsPresented']) };
    case 'follow-channel':
      return { eventName: 'follow', event_category: 'channels' };
    case 'unfollow-channel':
      return { eventName: 'unfollow', event_category: 'channels' };
    case 'view-channel':
      return { eventName: action, event_category: 'channels' };
    case 'view-lesson':
      return { eventName: action, event_category: 'lessons' };
    default:
      return undefined;
  }
}

function getUALabelOrValue(value: string | number | undefined): { event_label?: string; event_value?: number } | {} {
  if (isNil(value)) {
    return {};
  }

  return isFinite(Number(value)) ? { value: toNumber(value) } : { event_label: value };
}
