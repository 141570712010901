import { isNil, isString } from 'lodash';
import qs from 'qs';

export abstract class RedirectService {
  private static redirectUrl: string | undefined = undefined;

  public static setRedirectUrlFromQueryString(queryString: string) {
    const query = qs.parse(queryString, { ignoreQueryPrefix: true });
    const redirectURLFromQuery = query['redirect-url'];

    if (isNil(redirectURLFromQuery)) return;

    const redirectUrlFromQueryString = isString(redirectURLFromQuery)
      ? redirectURLFromQuery
      : redirectURLFromQuery && JSON.stringify(redirectURLFromQuery);

    if (!this.isUrlValidPath(redirectUrlFromQueryString)) return;
    this.redirectUrl = redirectUrlFromQueryString;
  }

  public static setRedirectUrl(redirectUrl: string) {
    if (!this.isUrlValidPath(redirectUrl)) return;
    this.redirectUrl = redirectUrl;
  }

  public static getRedirectUrl() {
    return this.redirectUrl;
  }

  public static clearRedirectUrl() {
    this.redirectUrl = undefined;
  }

  private static isUrlValidPath(redirectUrl: string) {
    if (!redirectUrl.startsWith('/')) {
      return false;
    }

    try {
      // This makes sure the URL does not start with //.
      new URL(redirectUrl);
    } catch (e) {
      return true;
    }
    return false;
  }
}
