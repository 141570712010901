import { AssignmentType } from '@lessonup/teaching-core';
import { LoadingRocket, spacing, styled } from '@lessonup/ui-components';
import React, { useEffect, useState } from 'react';
import { Explorer, Location, SharedExplorer } from '../../../shared-core/domain/newExplorers';
import { appServices } from '../../services/AppServices';
import { CanvasLessonPicker } from './CanvasLessonPicker/CanvasLessonPicker';

const getLtik = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const ltik = searchParams.get('ltik');
  if (!ltik) throw new Error('Missing lti key.');
  return ltik;
};

export default function LtiDeeplinkPage() {
  const [JWT, setJWT] = useState();
  const [explorer, setExplorer] = useState<Explorer | undefined>();
  const [sharedExplorers, setSharedExplorers] = useState<SharedExplorer[]>([]);

  useEffect(() => {
    getExplorer();
  }, []);

  const [endpoint, setEndpoint] = useState();

  useEffect(() => {
    if (!JWT || !endpoint) return;

    if (document.forms['ltijs']) {
      document.forms['ltijs'].submit();
    }
  }, [JWT, endpoint]);

  if (typeof window === 'undefined') return null;

  const searchParams = new URLSearchParams(window.location.search);
  const access_token = searchParams.get('accessToken');
  const refresh_token = searchParams.get('refreshToken');
  const key = searchParams.get('key');

  const getExplorer = async () => {
    const fetched = await appServices.explorer.getWithToken(access_token, refresh_token);
    setExplorer(fetched.explorer);
    setSharedExplorers(fetched.sharedExplorers);
  };

  const submitForm = async ({ assignmentType, lessonId }: { assignmentType: AssignmentType; lessonId: string }) => {
    const request = await fetch('/search/api/lti/deeplink', {
      body: JSON.stringify({ deeplinkInfo: true, lessonId, assignmentType, key }),
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${getLtik()}`,
        contentType: 'application/json',
      },
    });

    const { jwt, returnUrl }: any = await request.json();

    setJWT(jwt);
    setEndpoint(returnUrl);
  };

  const fetchLessonsAndPlansForLocation = (location: Location) =>
    appServices.explorer.fetchContentWithToken({ location, access_token, refresh_token });

  if (!JWT || !endpoint) {
    return explorer ? (
      <CanvasLessonPicker
        initialExplorer={explorer}
        rootExplorer={explorer}
        sharedExplorers={sharedExplorers}
        submitForm={submitForm}
        fetchLessonsAndPlansForLocation={fetchLessonsAndPlansForLocation}
      />
    ) : (
      <LoadingContainer>
        <LoadingRocket />
      </LoadingContainer>
    );
  }

  return <SubmitToCanvas JWT={JWT} endpoint={endpoint} />;
}

const SubmitToCanvas = ({ JWT, endpoint }: { JWT: string; endpoint: string }) => {
  return (
    <form id="ltijs" action={endpoint} name="ltijs" method="POST">
      <input type="hidden" name="JWT" value={JWT} />
    </form>
  );
};

const LoadingContainer = styled.div`
  margin-top: ${spacing.size32};
`;
