import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { logger } from '@lessonup/client-integration';
import { Lesson } from '@lessonup/teaching-core';
import { AppError } from '@lessonup/utils';
import { showDefaultErrorNotification } from '../../../shared-core/client/utils/modals';
import { ChannelTitle, IndexedChannelInfo, LanguageSingleton, LessonPlan } from '../../../shared-core/domain';
import { channelRoute, lessonPlanRoute } from '../../../shared-core/services/app/searchRoutes';
import Button from '../../../shared-core/ui/components/Button';
import { useToasts } from '../../../shared-core/ui/components/toast/ToastProvider';
import useCallbackIfParamsChanged from '../../../shared-core/ui/utils/hooks/useCallbackIfParamsChanged';
import { sanitizeContent } from '../../../shared-core/utils';
import { useAppServices } from '../../components/appServices/AppServicesContext';
import FollowChannelButton from '../../components/buttons/FollowChannelButton/FollowChannelButton';
import LessonPlanChild from '../../components/lessonPlan/LessonPlanChild';
import { fetchedPlanAction, fetchingPlanAction, fetchPlanErrorAction } from '../../redux/actions/actions';
import { lessonPlanDetails } from '../../redux/selectors';
import { htmlDecode } from '../../utils/htmlDecode';
import { useSpinnerContext } from '../../utils/loaders/SpinnerContext';
import ErrorPageView from '../error';
import './LessonPlanPage.less';

type Props = {
  planId: string;
  backNavigation?: (lessonPlan: LessonPlan) => JSX.Element | undefined;
  inChannelContext?: boolean;
};

const LessonPlanPage: React.FC<Props> = ({ backNavigation, planId, inChannelContext }) => {
  const { planDetails, error, planId: planReduxId, isLoading } = useSelector(lessonPlanDetails());
  const { t } = useTranslation('lessonPlans');
  const dispatch = useDispatch();
  const services = useAppServices();
  const { lessonPlan: planService } = services;
  const language = LanguageSingleton.get();
  const spinner = useSpinnerContext();

  useCallbackIfParamsChanged({
    initial: planReduxId,
    params: planId,
    async callback(planToFetch) {
      if (!planToFetch) return;
      spinner.open();
      dispatch(fetchingPlanAction(planToFetch));
      try {
        const result = await planService.planById(planToFetch);
        dispatch(fetchedPlanAction(result));
      } catch (error) {
        if (AppError.isError(error, 'not-found')) {
          dispatch(fetchPlanErrorAction(404, planToFetch));
        } else if (AppError.isError(error, 'not-allowed')) {
          dispatch(fetchPlanErrorAction(403, planToFetch));
        } else {
          dispatch(fetchPlanErrorAction(500, planToFetch));
        }
        throw error;
      } finally {
        spinner.close();
      }
    },
  });

  if (error) {
    return <ErrorPageView type="lessonPlan" code={error} />;
  }

  if (!planDetails || planId !== planReduxId) return null;

  const { plan, lessons, channelInfo } = planDetails;
  const sections = LessonPlan.sections(plan);
  const channelNameLink = channelInfo?.name && channelRoute({ channelSlug: channelInfo.name, language });
  const channelName = channelInfo && channelInfo.title && ChannelTitle.localizeTitle(channelInfo.title, language);
  const sanitizedDescription = plan.description ? sanitizeContent(plan.description).trim() : '';

  return (
    <div className="plan-wrapper">
      {plan && backNavigation ? backNavigation(plan) : null}
      <div className="plan-styles">
        <div className="plan-section intro">
          <div className="plan-intro">
            <span className="plan-label">{t('lessonPlan')}</span>
            <h1 className="title">{htmlDecode(plan.name)}</h1>
            {channelInfo && channelNameLink ? (
              <p className="channel-origin">
                {t('isChannelLessonPlan')} <Link to={channelNameLink}>{channelName}</Link>
              </p>
            ) : null}
            {planDetails.accessTypes.copySearch ? (
              <LessonPlanActionsBanner planId={planId} channelInfo={channelInfo} />
            ) : null}
            {plan.description ? (
              <div className="description" dangerouslySetInnerHTML={{ __html: sanitizedDescription }}></div>
            ) : null}
          </div>
        </div>
        {sections.map((section, i) => {
          const lastSection = i === sections.length - 1;
          const onlySection = sections.length == 1;
          return (
            <LessonPlanSection
              key={`section-${i}`}
              childKey={`section-${i}`}
              section={section}
              lastSection={lastSection}
              onlySection={onlySection}
              lessons={lessons}
              channelInfo={channelInfo}
              inChannelContext={inChannelContext}
              planId={planId}
            />
          );
        })}
      </div>
    </div>
  );
};

interface SectionProps {
  planId: string;
  section: LessonPlan.Children.AnyChild[];
  childKey: string;
  lastSection: boolean;
  onlySection: boolean;
  lessons: Lesson[];
  channelInfo?: IndexedChannelInfo;
  inChannelContext?: boolean;
}

const LessonPlanSection: React.FC<SectionProps> = ({
  planId,
  section,
  lastSection,
  onlySection,
  lessons,
  channelInfo,
  inChannelContext,
}) => {
  const [countColumns, hitBreak] = useColumnCounter();
  if (!section[0]) return null;

  const linkToChannelLesson =
    inChannelContext && channelInfo
      ? (id: string) =>
          channelRoute({
            channelSlug: channelInfo.name,
            channelPage: 'lesson',
            selectionId: id,
          })
      : undefined;

  return !onlySection && section[0] ? (
    <div className="plan-section">
      <div className="plan-children">
        <div className="plan-bullet"></div>
        {lastSection ? null : (
          <div>
            <div className="plan-border-line"></div>
          </div>
        )}
        {section.map((child, i) => {
          countColumns(child.cols || LessonPlan.childCols(child.type));

          return (
            <div key={child.id}>
              <LessonPlanChild
                planId={planId}
                child={child}
                lessons={lessons}
                channelInfo={channelInfo}
                linkToChannel={linkToChannelLesson}
              />
              {(hitBreak() || i === section.length - 1) && <div className="clearfix"></div>}
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div className="plan-section">
      <div className="plan-children">
        {section.map((child, i) => {
          countColumns(child.cols || LessonPlan.childCols(child.type));
          return (
            <div key={child.id}>
              <LessonPlanChild
                planId={planId}
                child={child}
                lessons={lessons}
                channelInfo={channelInfo}
                linkToChannel={linkToChannelLesson}
              />
              {(hitBreak() || i === section.length - 1) && <div className="clearfix"></div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const LessonPlanActionsBanner: React.FC<{
  planId: string;
  channelInfo?: IndexedChannelInfo;
}> = ({ planId, channelInfo }) => {
  const { t } = useTranslation('saveLessonPlan');
  const services = useAppServices();
  const toasts = useToasts();

  async function saveLessonPlan() {
    if (!services.user.currentUser()) {
      toasts.add({
        msg: t('needToLoginNotification'),
        delayMs: 1000000000,
        actionButton: {
          action: () => services.modals.open('login', { userService: services.user }),
          label: t('loginPrompt', {}),
        },
      });
      return;
    }

    try {
      const location = await services.explorer.pickLocationToSave();
      if (!location) return;
      const res = await services.lessonPlan.saveLessonPlan(planId, location);

      if (res.savedToAccount && res.planId) {
        services.modals.open('link', {
          title: t('lessonPlanSavedTitle') || '',
          description: t('lessonPlanSavedDescription'),
          link: lessonPlanRoute(res.planId),
          linkText: t('openPlanRoute'),
        });
      }
    } catch (error) {
      showDefaultErrorNotification(error);

      logger.error(error);
    }
  }

  return (
    <div className="plan-actions">
      <p>{t('description')}</p>
      <Button onClick={saveLessonPlan} height="small" theme="primary">
        {t('saveLessonPlanCTA')}
      </Button>
      {channelInfo && (
        <FollowChannelButton
          channelTitle={channelInfo.title && htmlDecode(ChannelTitle.localizeTitle(channelInfo.title))}
          channelSlug={channelInfo.name}
          requireFollow={channelInfo.requireFollow}
          followContext={{ trigger: 'plan-page', planId }}
        />
      )}
    </div>
  );
};
export default LessonPlanPage;

type ColumnCounter = [(n: number) => void, () => boolean];

function useColumnCounter(): ColumnCounter {
  const maxCount = LessonPlan.COLUMN_WIDTH;
  let count = 0;

  function add(n: number) {
    count += n;
  }
  function hitMax() {
    return count % maxCount === 0;
  }
  return [add, hitMax];
}
