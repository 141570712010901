import { sanitizeHtml } from '@lessonup/utils';
import { Sanitizer } from './Sanitizer';

const lessStrictOptions: Sanitizer.SanitizeFunctionOptions = {
  allowedTags: [
    'div',
    'span',
    'ul',
    'li',
    'b',
    'p',
    'br',
    'font',
    'strong',
    'em',
    'i',
    'u',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'ol',
    'a',
    'img',
    'blockquote',
    'pre',
    'code',
    'table',
    'thead',
    'tbody',
    'tr',
    'th',
    'td',
    'sup',
    'sub',
  ],
  allowedAttributes: {
    '*': ['style', 'class', 'color', 'face', 'href', 'src', 'alt', 'title', 'width', 'height', 'align'],
  },
  parser: {
    decodeEntities: false,
  },
  transformTags: {
    '*': (tagName, attribs) => {
      if (attribs.style) {
        // eslint-disable-next-line no-param-reassign
        attribs.style = attribs.style
          .replace(/\s*:\s*/g, ':')
          .replace(/\s*;\s*/g, ';')
          .trim();
      }
      return { tagName, attribs };
    },
  },
};

export const sanitizeContent = (content) => sanitizeHtml(content, lessStrictOptions);
