import React from 'react';
import { Language, LanguageSingleton } from '../../../domain';

interface Props {
  label: string;
  urls: Partial<Language.Dict<string>>;
  language?: string;
  target?: string;
}

const MultiLocaleLink: React.FC<Props> = ({ label, urls, target, language = LanguageSingleton.get() }) => {
  if (!urls[language]) return null;
  return (
    <a href={urls[language]} target={target}>
      {label}
    </a>
  );
};

export default MultiLocaleLink;
