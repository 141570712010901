import { Component } from '@lessonup/teaching-core';
import { compactMap } from '@lessonup/utils';
import _ from 'lodash';
import React, { useState } from 'react';
import AudioComponentView from './audio/AudioComponentView';
import DropzoneComponentView from './dropzone/DropzoneComponentView';
import FormulaComponentView from './formula/FormulaComponentView';
import HotspotComponentView from './hotspot/HotspotComponentView';
import ImageComponentView from './image/ImageComponentView';
import SpinnerComponentView from './spinner/SpinnerComponentView';
import SymbolComponentView from './symbol/SymbolComponentView';
import TableComponentView from './table/TableComponentView';
import TextComponentView from './text/TextComponentView';
import TimerComponentView from './timer/TimerComponentView';
import TrafficLightComponentView from './trafficlight/TrafficLightComponentView';
import VideoComponentView from './video/VideoComponentView';
import './viewComponents.less';

interface Props {
  components: Component.All[] | undefined;
  isThumb?: boolean;
  editSlideColor?: (color: number) => void; // specific way for the trafficlight component to change slide color.
  expandOnlyOne?: boolean;
}

const ViewComponents: React.FunctionComponent<Props> = (props) => {
  const { isThumb, editSlideColor, components, expandOnlyOne } = props;
  const [expanded, setExpanded] = useState<string[]>(expandedComponentsOnStart(components || []));
  const [mediaTriggerImages, setMediaTriggerImages] = useState<{ [key: string]: boolean }>(
    initMediaTriggers(components || [], expanded)
  );

  function toggleHotspotExpanded(componentId: string, toggleMediaTrigger?: string) {
    const componentIsExpanded = expanded.includes(componentId);

    if (expandOnlyOne && !componentIsExpanded) return expandComponentIfOnlyOne(componentId, toggleMediaTrigger);

    if (toggleMediaTrigger) toggleMediaTriggerImage(toggleMediaTrigger, !componentIsExpanded);

    if (componentIsExpanded) return collapseComponent(componentId);

    setExpanded(expanded.concat(componentId));
  }

  function expandComponentIfOnlyOne(componentId: string, mediaTrigger?: string) {
    mediaTrigger && setMediaTriggerImages(_.mapValues(mediaTriggerImages, (_, key) => key === mediaTrigger));
    setExpanded([componentId]);
  }

  function collapseComponent(componentId: string) {
    setExpanded(expanded.filter((expanded) => expanded !== componentId));
  }

  function toggleMediaTriggerImage(mediaTrigger: string, val?: boolean) {
    setMediaTriggerImages({
      ...mediaTriggerImages,
      [mediaTrigger]: val || !mediaTriggerImages[mediaTrigger],
    });
  }

  const renderComponent = (component: Component.All) => {
    if (mediaTriggerImages.hasOwnProperty(component._id) && !mediaTriggerImages[component._id]) {
      return undefined;
    }

    switch (component.type) {
      case 'audio':
        return <AudioComponentView component={component} key={component._id} isThumb={isThumb} />;
      case 'text':
        return <TextComponentView component={component} key={component._id} isThumb={isThumb} />;
      case 'image':
        return <ImageComponentView component={component} key={component._id} isThumb={isThumb} />;
      case 'hotspot':
        return (
          <HotspotComponentView
            component={component}
            key={component._id}
            isThumb={isThumb}
            expanded={expanded.includes(component._id)}
            toggle={toggleHotspotExpanded}
          />
        );
      case 'formula':
        return <FormulaComponentView component={component} key={component._id} isThumb={isThumb} />;
      case 'trafficlight':
        return (
          <TrafficLightComponentView
            component={component}
            key={component._id}
            isThumb={isThumb}
            editSlideColor={editSlideColor}
          />
        );
      case 'spinner':
        return <SpinnerComponentView component={component} key={component._id} isThumb={isThumb} />;
      case 'table':
        return <TableComponentView component={component} key={component._id} isThumb={isThumb} />;
      case 'timer':
        return <TimerComponentView component={component} key={component._id} isThumb={isThumb} />;
      case 'video':
        return (
          <VideoComponentView
            component={component}
            key={component._id}
            isThumb={isThumb}
            useThumbnailForComponentType={component.useThumbnailForComponentType}
          />
        );
      case 'dropzone':
        return <DropzoneComponentView component={component} key={component._id} isThumb={isThumb} />;
      case 'symbol':
        return <SymbolComponentView component={component} key={component._id} isThumb={isThumb} />;
    }
  };

  return <div className={`view-components`}>{components && compactMap(components, renderComponent)}</div>;
};

export default ViewComponents;

const initMediaTriggers = (components: Component.All[], expanded: string[]) => {
  return components.reduce<Record<string, boolean>>((acc, component) => {
    if (component.type !== 'hotspot') return acc; // Only hotspots can toggle images
    const mediaTrigger = Component.getSetting(component, 'mediaTrigger');

    return mediaTrigger ? acc : { ...acc, [mediaTrigger]: !!expanded.find((c) => c === component._id) };
  }, {});
};

function expandedComponentsOnStart(components: Component.All[]): string[] {
  return components.filter((component) => !!Component.getSetting(component, 'showTextOnStart')).map((c) => c._id);
}
