import React from 'react';
import { FolderRenderParams } from '../../../shared-core/domain/newExplorers';
import { ImageUtils } from '../../../shared-core/utils/ImageUtils';
import FolderCounts from './FolderCount';

interface Props {
  folder: FolderRenderParams;
}
const FolderVisual: React.FC<Props> = ({ folder }) => {
  const divStyle = folder.image
    ? {
        backgroundImage: 'url(' + ImageUtils.checkImageUrl(folder.image) + ')',
      }
    : {};

  return (
    <div className="visual" style={divStyle}>
      <div className="hover-el"></div>
      <FolderCounts count={folder.counts} />
    </div>
  );
};
export default FolderVisual;
