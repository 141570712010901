import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LanguageSingleton } from '../../../../../../../shared-core/domain';
import { Country } from '../../../../../../../shared-core/domain/country/Country';
import { UnitedStates } from '../../../../../../../shared-core/domain/country/UnitedStates';
import { InputLabel } from '../../../../../../../shared-core/ui/components/Input/InputLabel/InputLabel';
import CountryPicker from '../../../../../../../shared-core/ui/components/pickers/CountryPicker';
import StatePicker from '../../../../../../../shared-core/ui/components/pickers/StatePicker';
import { useBEM } from '../../../../../../../shared-core/ui/utils/hooks';
import { geoLocation, loggedInUser } from '../../../../../../redux/selectors';
import './CountryStateForm.less';

interface Props {
  onChange: (
    action:
      | { type: 'setCountry'; countryCode: Country.CountryCode }
      | { type: 'setState'; usState: UnitedStates.StateCode }
  ) => void;
  country?: Country.CountryCode;
  usState?: UnitedStates.StateCode;
}

const entriesToOptions = <T extends UnitedStates.UnitedStatesTuple | Country.CountryTuple>(
  entries: T[]
): { value: T[0]; label: T[1] }[] => entries.map(([value, label]) => ({ value, label }));

export const CountryStateForm: React.FC<Props> = (props) => {
  const { t } = useTranslation('countryStateForm');
  const bemClasses = useBEM('CountryStateForm');
  const user = useSelector(loggedInUser());
  const detectedLocale = useSelector(geoLocation());

  const validatedDetectedLocale = Country.isCountryCode(detectedLocale?.countryCode) && detectedLocale?.countryCode;
  const requiresUSState = props.country === 'us' || (!props.country && validatedDetectedLocale === 'us');
  const language = LanguageSingleton.get();

  const countryOptions = useMemo(
    () => entriesToOptions<Country.CountryTuple>(Country.localRankedCountryEntries(language)),
    [language]
  );

  const stateOptions = useMemo(
    () => entriesToOptions<UnitedStates.UnitedStatesTuple>(UnitedStates.statesEntries()),
    []
  );

  function onChangeCountry(countryCode: Country.CountryCode) {
    props.onChange({ type: 'setCountry', countryCode });
  }

  function onChangeUSState(usState: UnitedStates.StateCode) {
    props.onChange({ type: 'setState', usState });
  }

  return (
    <div className={bemClasses()}>
      <div>
        <InputLabel text={t('selectCountry')} disabled={false} id="state-picker" />
        <CountryPicker
          id="country-picker"
          options={countryOptions}
          onChange={onChangeCountry}
          placeholder={t('selectCountryPlaceholder')}
          defaultValue={user?.country || props.country || undefined}
        />
      </div>
      {requiresUSState && (
        <div>
          <InputLabel text={t('selectState')} disabled={false} id="state-picker" />
          <StatePicker
            id="state-picker"
            options={stateOptions}
            onChange={onChangeUSState}
            placeholder={t('selectStatePlaceholder')}
            defaultStateCode={user?.USState}
          />
        </div>
      )}
    </div>
  );
};
