import { getCookieValueByName, setCookie, trackAction } from '@lessonup/client-integration';
import { safeParseJSON } from '@lessonup/utils';
import { LessonUpApiService } from '../../../shared-core/client/services/api';
import { Channel, Language, LanguageSingleton, LeadTrigger } from '../../../shared-core/domain';
import { Api } from '../../../shared-core/domain/api/LessonupApi';
import { SearchAppModalManagerService } from '../SearchAppModalService';

export type ChannelAppService = ReturnType<typeof configureChannelAppService>;

export default function configureChannelAppService(
  api: LessonUpApiService,
  modalService: SearchAppModalManagerService
) {
  async function requireFollow(params: {
    userId: string | undefined;
    trigger: LeadTrigger;
    channelSlug: string;
    channelTitle: string;
  }) {
    const { userId, trigger, channelSlug, channelTitle } = params;
    const res = userId
      ? followChannelAndRequestLeadForm(channelSlug, channelTitle, trigger)
      : requireFollowFromAnonymousUser(channelTitle, channelSlug, trigger);

    return await res;
  }

  async function openChannelLeadModalAndRegister(channelTitle: string, channelSlug: string, trigger: LeadTrigger) {
    const res = await modalService.open('channelLead', {
      channelTitle,
      channelSlug,
      handleSubmit: (params) =>
        registerChannelLead({
          ...params,
          trigger,
          channel: channelSlug,
          name: params.firstName + ' ' + params.lastName,
        }),
    });
    return res;
  }

  function setChannelFollowedCookie(channelSlug: string) {
    const name = 'cookielib';
    const parsedCookieValue = safeParseJSON(getCookieValueByName(name));
    const currentValue = parsedCookieValue?.success ? parsedCookieValue : undefined;

    const cookie = {
      name,
      value: JSON.stringify({ ...currentValue, [channelSlug]: true }),
      maxAge: 60 * 60 * 24 * 31,
    };
    setCookie(cookie);
  }

  async function requireFollowFromAnonymousUser(channelTitle: string, channelSlug: string, trigger: LeadTrigger) {
    const res = await openChannelLeadModalAndRegister(channelTitle, channelSlug, trigger);

    if (res.success) {
      setChannelFollowedCookie(channelSlug);
      const { type, ...triggerParams } = trigger;
      trackAction('channel-lead', { ...triggerParams, channelSlug });
    }

    return res;
  }

  async function followChannelAndRequestLeadForm(channelSlug: string, channelTitle: string, trigger: LeadTrigger) {
    const res = await openChannelLeadModalAndRegister(channelTitle, channelSlug, trigger);

    if (res.success) {
      await followChannelWithUserId(channelSlug, trigger);
      if (!!trigger['lessonId']) {
        trackAction('follow-channel', { lessonId: trigger['lessonId'], channelSlug, trigger: trigger['trigger'] });
      } else {
        trackAction('follow-channel', { channelSlug, trigger: trigger['trigger'] });
      }
    }

    return res;
  }

  async function followChannelWithUserId(channelSlug: string, trigger?: LeadTrigger) {
    return await api.followChannelWithUserId(channelSlug, trigger);
  }

  async function registerChannelLead(params: Api.RegisterChannelLeadParams) {
    const name = `${params.firstName} ${params.lastName}`;
    return await api.registerChannelLead({ ...params, name });
  }

  async function unfollowChannel(channelSlug: string) {
    return await api.unfollowChannel(channelSlug);
  }

  async function channelDetailsByName(
    name: string,
    language: Language.Key,
    initialPage?: Channel.Page,
    selectionId?: string
  ) {
    return await api.channelDetailsByName(name, language, initialPage, selectionId);
  }

  async function channelContentPage(name: string, contentPage: Channel.ContentPageKey, language: Language.Key) {
    return await api.channelContentPage(name, contentPage, language);
  }

  async function channelFolderPage(name: string, selectionId: string) {
    return await api.channelFolderPage(name, selectionId);
  }

  async function channelCurriculaPage(name: string, selectionId: string) {
    return await api.channelCurriculaPage(name, selectionId);
  }

  async function fetchChannelOverviewChannels() {
    return await api.publicChannels(LanguageSingleton.get());
  }

  return {
    followChannelWithUserId,
    registerChannelLead,
    unfollowChannel,
    channelDetailsByName,
    channelContentPage,
    channelFolderPage,
    channelCurriculaPage,
    fetchChannelOverviewChannels,
    requireFollow,
    followChannelAndRequestLeadForm,
  };
}
