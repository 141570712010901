import { isArray } from 'lodash';
import { Organization } from '../organizations';
import { MongoUser } from '../user';

export type LanguageKey = Language.Key;
export namespace Language {
  export const validKeys = ['nl', 'en', 'fr'] as const;
  export const mainKeys = ['nl', 'en'] as const;

  export type Key = (typeof validKeys)[number];
  // FullySupported Languages;
  export type MainKey = (typeof mainKeys)[number];
  // Partially supported Languages;
  export type SecondaryKey = Exclude<Key, MainKey>;

  // Needs all the main languages and optional secondary langues
  export type Dict<T> = MainDict<T> & SecondaryDict<T>;

  type MainDict<T> = {
    [key in MainKey]: T;
  };
  type SecondaryDict<T> = {
    [key in SecondaryKey]?: T;
  };

  // If something needs to be supported by all Languages
  export type FullDict<T> = {
    [key in Key]: T;
  };

  const fallbackLanguages: SecondaryDict<MainKey> = {
    fr: 'en',
  };

  // default language can only be a fully supported language
  export const defaultLanguage: MainKey = 'en';

  export const cookieName = 'LanguageKey';

  export function isValid(string: string): string is LanguageKey {
    return validKeys.includes(string as LanguageKey);
  }

  export function isMain(string: string): string is MainKey {
    return mainKeys.includes(string as MainKey);
  }

  /** make sure we always get something */
  export function fromDictWithFallback<T>(dict: Dict<T>, language: Key): T {
    return dict[language] || dict[fallbackLanguages[language]] || dict[defaultLanguage];
  }

  export function fallbackForLanguage(language: Key): MainKey {
    return fallbackLanguages[language];
  }

  export function languageFromPath(path: string): LanguageKey | undefined {
    const splitPath = path.split('/');
    const first = splitPath[1];
    return isValid(first) ? first : undefined;
  }

  export function changePathLanguage(path: string, language: LanguageKey) {
    const splitPath = path.split('/');
    splitPath[1] = language;
    return splitPath.join('/');
  }

  export function languageOrFallback(language: LanguageKey): MainKey {
    return isMain(language) ? language : fallbackForLanguage(language);
  }

  export function defaultLanguageForCountry(country: string | string[]): LanguageKey {
    switch (Array.isArray(country) ? country[0] : country) {
      case 'nl':
      case 'be':
        return 'nl';
      case 'fr':
        return 'fr';
      default:
        return defaultLanguage;
    }
  }

  export function languageKeyFromWindowLike({ navigator: { languages, language } }: Window): LanguageKey | undefined {
    const rawLanguage = (isArray(languages) && languages[0]) || language;
    return rawLanguage ? formatBrowserLanguageToLanguageKey(rawLanguage) : undefined;
  }

  function formatBrowserLanguageToLanguageKey(windowNavigatorFormat: string): LanguageKey | undefined {
    const [languagePart] = windowNavigatorFormat.toLowerCase().split('-');
    return isValid(languagePart) ? languagePart : undefined;
  }

  export function languageFromOrganizationOrUser(organization: Organization, user: MongoUser): LanguageKey {
    return organization.language ? organization.language : user.data?.language ? user.data.language : defaultLanguage;
  }
}

/**
 * This singleton is a app global fallback language to be used in app routes
 */
export namespace LanguageSingleton {
  let language: Language.Key | undefined;

  export function set(newLanguage: string) {
    if (!Language.isValid(newLanguage)) return;
    language = newLanguage;
  }
  export function get(): Language.Key {
    if (!language) {
      console.warn('read language before set');
      return Language.defaultLanguage;
    }
    return language;
  }
  export function is(key: Language.Key): boolean {
    if (!language) {
      console.warn('read language before set');
      return Language.defaultLanguage === key;
    }
    return language === key;
  }
}
