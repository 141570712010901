import { ExtendedChannelInfo, IndexedChannelInfo, LessonDetails } from '../../../shared-core/domain';
import { AnyAction } from '../actions/actions';

export interface LessonState {
  isLoading: boolean;
  lessonDetails: LessonDetails | undefined;
  lessonId: string | undefined;
  error: false | 404 | 403 | 500;
  productError?: boolean;
  errorDetails?: ErrorDetails;
}
export interface ErrorDetails {
  forProduct?: boolean;
  channel?: ExtendedChannelInfo | IndexedChannelInfo;
}

const initialState: LessonState = {
  isLoading: false,
  lessonDetails: undefined,
  error: false,
  lessonId: undefined,
};

export function lessonReducer(state: LessonState = initialState, action: AnyAction): LessonState {
  switch (action.type) {
    case 'fetchedLesson':
      return {
        isLoading: false,
        error: false,
        lessonDetails: action.response && action.response.result,
        lessonId: action.response && action.response.result && action.response.result.lesson._id,
      };
    case 'fetchingLesson':
      return {
        isLoading: true,
        error: false,
        lessonDetails: undefined,
        lessonId: action.lessonId,
      };
    case 'fetchLessonError': {
      return {
        isLoading: false,
        error: action.code,
        productError: action.productError,
        lessonId: action.lessonId,
        lessonDetails: undefined,
      };
    }
    default:
      return state;
  }
}
