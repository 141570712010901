import { Lesson } from '@lessonup/teaching-core';
import { naiveStripHTML } from '@lessonup/utils';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Explanation, IndexedChannelInfo, LessonPlan } from '../../../shared-core/domain';
import { FacetMetaData } from '../../../shared-core/domain/search/content/FacetMetaData';
import MetaDataTagList from '../../../shared-core/ui/components/MetaDataTagList';
import ExplanationBox from '../../../shared-core/ui/components/search/explanation/ExplanationBox';
import { ImageUtils } from '../../../shared-core/utils/ImageUtils';
import { htmlDecode } from '../../utils/htmlDecode';
import { useAppServices } from '../appServices/AppServicesContext';
import ThumbBannerChannel from '../banner/ThumbBannerChannel';
import './css/PlanSummaryCard.less';

type LinkTo = (lessenId: string) => string;
export interface Props {
  channel?: IndexedChannelInfo | undefined;
  metaData?: FacetMetaData;
  lessons: Lesson[];
  plan: LessonPlan;
  explanation?: Explanation | undefined;
  linkTo?: LinkTo;
}

const NS = 'planSummaryCard';

export default function PlanSummaryCard(props: Props) {
  const { explanation, plan, lessons, channel, linkTo } = props;
  const metaData = props.metaData || FacetMetaData.fromPlan(plan);
  const { t } = useTranslation(NS);
  const services = useAppServices();
  if (!plan) return <div>no plan</div>;
  const lessonCount = LessonPlan.lessons(plan).length;
  const planId = plan._id;
  const link = linkTo ? linkTo(planId) : services.lessonPlan.gotoPlanUrl(planId);
  const sections = LessonPlan.sections(plan);
  // always add a second section
  const allContent = sections.length === 1;
  sections.push([]);
  // 3 sections should be enough
  const list = lessons ? sections.map((s, i) => Section(s, lessons, i, allContent)).slice(0, 3) : null;

  return (
    <div className="base-summary-card plan-summary-card">
      <Link to={link} className="plan-summary-inner">
        <div className="plan-top">
          <Trans t={t} i18nKey="planWithLessonCount" count={lessonCount} />
          <div className="plan-title">{htmlDecode(plan.name)}</div>
          <div className="plan-description">{naiveStripHTML(plan.description || '').trim()}</div>

          <MetaDataTagList metaData={metaData} size="small" />
        </div>

        <div className="plan-body">{list}</div>
      </Link>
      <ThumbBannerChannel
        channel={channel}
        locale={metaData.country[0]}
        channelPublic={channel && channel.channelPublic}
      />
      {explanation && <ExplanationBox explanation={explanation} id={plan._id} />}
    </div>
  );
}

function Section(section: LessonPlan.Children.AnyChild[], lessons: Lesson[], index: number, allContent: boolean) {
  const header = LessonPlan.filterList<LessonPlan.Children.Header>(section, ['header'])[0];
  const texts = LessonPlan.filterList<LessonPlan.Children.Text>(section, ['text']);
  const visuals = LessonPlan.filterList<
    LessonPlan.Children.Lesson | LessonPlan.Children.Image | LessonPlan.Children.Attachment
  >(section, ['image', 'lesson', 'attachment']);

  let url: string | undefined;
  visuals.find((vis) => {
    if (!vis) return false;
    if (vis.type === 'image') {
      url = vis.url;
      return true;
    }
    const lesson = lessons.find((l) => l._id === vis.id);
    if (lesson?.thumbnail?.url) {
      url = lesson.thumbnail.url;
      return true;
    }
    return false;
  });

  const Content = _.compact(
    allContent
      ? _.compact(
          section.map((child) => {
            if (child.type === 'text') return <Text text={child.text} key={child.id} />;
            if (child.type === 'image') return <Visual url={child.url} key={child.id} />;
            if (child.type === 'attachment') return <Visual url={child.image} key={child.id} />;
            if (child.type === 'lesson') {
              const lesson = lessons.find((l) => l._id === child.id);
              const url = lesson && lesson.thumbnail && lesson.thumbnail.url;
              return <Visual url={url} key={child.id} />;
            }
            return null;
          })
        ).slice(0, 3)
      : [<Visual key={'vis-1'} url={url} />, texts[0] && <Text text={texts[0].text} key={'vis-2'} />]
  );

  return (
    <div className="plan-section" key={`section-${index}`}>
      <div className={classNames('plancard-bullet', { 'no-connector': !section.length })}></div>
      <div className="plan-border-line"></div>
      <div className="section-header">{header && naiveStripHTML(header.text)}</div>
      {Content}
    </div>
  );
}

const Text = ({ text }: { text: string | undefined }) =>
  text ? <div className="section-text">{naiveStripHTML(text)}</div> : null;

const Visual = ({ url }: { url: string | undefined }) => {
  const [imageUrl, setImageUrl] = useState(() => ImageUtils.checkImageUrl(url, false, undefined, false));

  useEffect(() => {
    const newImageUrl = ImageUtils.checkImageUrl(url, false, undefined, true);
    if (newImageUrl !== imageUrl) {
      setImageUrl(newImageUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return url ? (
    <div
      className="section-visual background-image-fill-parent contain"
      style={{ backgroundImage: `url('${imageUrl}')` }}
    ></div>
  ) : null;
};
