import React from 'react';
import { Link } from 'react-router-dom';
import { FolderMeta } from '../../../shared-core/domain/newExplorers';
import UnstyledButton from '../../../shared-core/ui/components/UnstyledButton';
import { htmlDecode } from '../../utils/htmlDecode';
import './Breadcrumbs.less';

interface Props {
  breadcrumbs: FolderMeta[];
  rootFolderName?: string;
  getUrl?: (id: string | null) => string;
  handleClick?: (id: string | null) => void;
  currentFolderName?: string;
}

export default function Breadcrumbs({ breadcrumbs, handleClick, currentFolderName, getUrl, rootFolderName }: Props) {
  if (!breadcrumbs.length) return null;

  if (rootFolderName) {
    breadcrumbs[0].name = rootFolderName;
  }

  return (
    <div className="breadcrumb-list">
      {breadcrumbs.map((br, index) => {
        const isRootFolder = index === 0;
        const folderId = isRootFolder ? null : br._id;
        const link = getUrl && getUrl(folderId);

        return link ? (
          <Link key={br._id || br.name} to={link} className="breadcrumb-list-item">
            <UnstyledButton>{htmlDecode(br.name)}</UnstyledButton>
          </Link>
        ) : (
          <span className="breadcrumb-list-item" key={br._id || br.name}>
            <UnstyledButton onClick={(e) => handleClick && handleClick(br._id)}>{htmlDecode(br.name)}</UnstyledButton>
          </span>
        );
      })}

      {currentFolderName && <span className="breadcrumb-list-item">{htmlDecode(currentFolderName)}</span>}
    </div>
  );
}
