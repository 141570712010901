import { logout } from '@lessonup/intercom';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FacetKey } from '../../../../shared-core/domain';
import { MAX_PAGINATION_SIZE, PAGINATION_SIZE } from '../../../../shared-core/domain/search/constants/paginationSize';
import ShowMoreButton from '../../../../shared-core/ui/components/buttons/ShowMoreButton';
import SidebarLayout from '../../../components/search/sidebarLayout/SidebarLayout';
import { itemSearchForComponent, pinResults } from '../../../redux/selectors';
import SearchResultsEmptyState from '../searchResultsView/SearchResultsEmptyState/SearchResultsEmptyState';
import { useSearchContext } from '../sidebar/SearchContext';
import SearchSidebar from '../sidebar/SearchSidebarContainer';
import { ImageResult } from './ComponentResult/ImageResult';
import { VideoResult } from './ComponentResult/VideoResult';
import { PinResult } from './PinResult/PinResult';
import { PIN_SEARCH_TRANSLATION_NS } from './PinSearchPage';
import './PinSearchPage.less';

interface Props {
  showSidebar: boolean;
  toggleSelectPin: (pinId: string, event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  openPinInfo: (pinId: string) => void;
  selectedPins: string[];
  fetching: boolean;
  imageSearch: boolean;
}

const PinSearchPageView: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation(PIN_SEARCH_TRANSLATION_NS);
  const pins = useSelector(pinResults());
  const forComponent = useSelector(itemSearchForComponent());
  const { setSearchText, resetFacet, paginate } = useSearchContext();

  if (typeof window !== 'undefined') {
    logout();
  }

  function handleFacetDeselect(key: FacetKey | 'text') {
    return key === 'text' ? setSearchText('') : resetFacet(key);
  }

  const size = (pins && pins.request.size) || PAGINATION_SIZE;
  const canPaginate = !!(pins && pins.total > size && size < MAX_PAGINATION_SIZE);

  const FacetList = (
    <SearchSidebar
      facetResults={pins ? pins.facets : []}
      searchTextValue={pins ? pins.request.text : undefined}
      hideFacets={['isTest', 'type', 'hasImage']}
      hideText={true}
      hideCounts={true}
      searchType="item"
    ></SearchSidebar>
  );
  const Content =
    pins &&
    pins.items.length &&
    pins.items.map((item) => {
      const { pin, images } = item.doc;
      return props.imageSearch ? (
        <ImageResult pin={pin} images={images} />
      ) : forComponent === 'video' ? (
        <VideoResult pin={pin} />
      ) : (
        <PinResult
          pin={pin}
          selected={props.selectedPins.includes(pin._id)}
          toggleSelectPin={props.toggleSelectPin}
          openPinInfo={props.openPinInfo}
          key={pin._id}
          onClick={props.toggleSelectPin}
        />
      );
    });

  const pinRequestWithoutContentType = pins?.request;
  if (pins && pinRequestWithoutContentType) {
    pinRequestWithoutContentType.facets = _.filter(pins.request.facets, (facetType) => facetType.key !== 'type');
  }
  const emptyState = pins && pins.items.length === 0 && pinRequestWithoutContentType && (
    <div className="searchpage-result-header">
      <h3>{t('noResults')}</h3>
      <SearchResultsEmptyState searchParams={pinRequestWithoutContentType} handleFacetDeselect={handleFacetDeselect} />
    </div>
  );

  return (
    <div className="searchpage pin-search-page">
      <SidebarLayout sidebar={props.showSidebar ? FacetList : undefined} showTabs={false}>
        <main className="searchpage__searchresults" style={{ marginTop: 0 }}>
          <div className="pinsearch-container ">{Content || emptyState}</div>
          <ShowMoreButton show={canPaginate} onClick={paginate} fetching={props.fetching} />
        </main>
      </SidebarLayout>
    </div>
  );
};

export default PinSearchPageView;
