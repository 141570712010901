import React from 'react';
import { useBEM } from '../../../utils/hooks';
import './InputLabel.less';

interface InputLabelProps {
  text: string;
  disabled?: boolean;
  id: string;
}

export const InputLabel: React.FC<InputLabelProps> = ({ text, disabled, id }) => {
  const bemClasses = useBEM('InputLabel');

  return (
    <label className={bemClasses({ modifier: disabled && 'disabled' })} htmlFor={id}>
      {text}
    </label>
  );
};
