import { Component } from '@lessonup/teaching-core';
import React from 'react';
import ComponentContainer from '../componentContainer/ComponentContainer';
import { TableRenderer } from './TableReactComponent';

interface Props {
  component: Component.Table;
  onClickHandler?: Function;
  isThumb?: boolean;
}

const TableComponentView: React.FC<Props> = (props) => {
  return (
    <ComponentContainer {...props} isInteractive={false}>
      <div data-grippy="move"></div>
      <TableRenderer
        position={props.component.position}
        settings={props.component.settings}
        view={true}
      ></TableRenderer>
    </ComponentContainer>
  );
};

export default TableComponentView;
