import React from 'react';
import './DragQuestionComponentBorder.less';

export default function DragQuestionComponentBorder({ borderRadius }: { borderRadius: string | undefined }) {
  return (
    <>
      <div className="drag-question-object-outline" data-grippy="move" style={{ borderRadius }} />
      <div className="drag-question-object-grippy" data-grippy="move" />
    </>
  );
}
