import { openChatWindow } from '@lessonup/intercom';
import { EmailVerificationSent, showEmailTroubleshootModal } from '@lessonup/teacher-modern';
import React from 'react';
import { registerRoute } from '../../../../shared-core/services/app/searchRoutes';
import { PageWrapper } from '../../../components/PageWrapper/PageWrapper';
import { ClientOnlyModernWrapper } from '../../../utils/bridge/ClientOnlyModernWrapper';
import { RegistrationFormProps, RegistrationPageProps } from '../types';
import { useRegistrationContext } from '../utils/RegistrationContext';

type Props = RegistrationFormProps & RegistrationPageProps;

export const RegistrationEmailVerificationSentServiceWrapper = (props: Props) => {
  const { email } = useRegistrationContext();

  return (
    <PageWrapper>
      <ClientOnlyModernWrapper>
        <EmailVerificationSent
          emailAddress={email}
          changeEmailLink={registerRoute('credentials')}
          onTroubleshootClick={(emailAddress: string) =>
            showEmailTroubleshootModal({
              openChatWindow,
              changeEmailLink: registerRoute('credentials'),
              emailAddress,
            })
          }
        />
      </ClientOnlyModernWrapper>
    </PageWrapper>
  );
};
