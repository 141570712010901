import React from 'react';

import { AssignmentPin } from '@lessonup/teaching-core';
import { textToHtml } from '@lessonup/utils';
import { ContentTypeDefaults } from '../../../../utils/ContentTypeDefaults';
import ImageComponent, { ImageComponentPropsForPin } from '../../images/ImageComponent';
import PinContainer from '../../pin/PinContainer';
import { MapPinProps } from '../../pin/PinView';
import ViewComponents from '../../viewComponents/ViewComponents';
import Balloons from './Balloons';

interface Props extends MapPinProps {
  pin: AssignmentPin.Wordweb;
}

export default class WordwebView extends React.Component<Props> {
  render() {
    const { pin, isThumb } = this.props;
    // todo https://www.npmjs.com/package/react-html-parser
    const word = textToHtml(pin.item.custom.word);
    const imageProps = ImageComponentPropsForPin(pin, 'image', isThumb);

    return (
      <PinContainer pin={pin}>
        <ImageComponent {...imageProps} />
        <Balloons />
        <ViewComponents
          components={pin.item.components}
          isThumb={isThumb}
          expandOnlyOne={pin.item.settings?.closeOtherHotspots}
        />
        <div
          id="word"
          className={`font-size-${
            pin.item.custom.fontSize1 || ContentTypeDefaults.getDefaultFontSize(1, pin.item.type)
          }`}
          dangerouslySetInnerHTML={{ __html: word }}
        />
      </PinContainer>
    );
  }
}
