import { Component } from '@lessonup/teaching-core';
import React from 'react';
import ComponentContainer from '../componentContainer/ComponentContainer';

interface Props {
  component: Component.Formula;
  onClickHandler?: Function;
  defaultState?: string;
  isThumb?: boolean;
}

export default class FormulaComponentView extends React.PureComponent<Props> {
  render() {
    const { component } = this.props;
    const html = { __html: component.html || '' }; // TODO: Fix types properly

    return (
      // TODO: really never interactive?
      <ComponentContainer {...this.props} componentTypeClasses={``} isInteractive={false}>
        <div dangerouslySetInnerHTML={html} />
      </ComponentContainer>
    );
  }
}
