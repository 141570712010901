import { LessonPin } from '@lessonup/teaching-core';
import React from 'react';

import { PinResult } from '../../../pages/search/pins/PinResult/PinResult';
import './VerticalPinList.less';

interface Props {
  activePin: string;
  setActivePin: (pinId: string) => void;
  pins: LessonPin[];
  selectedPins: string[];
  togglePinSelectionState: (pinId: string) => void;
  label: string;
}

// TODO:
// isSelected: boolean;
// isVideoItem?: boolean;
// videoItemThumbImage?: string;
// onSelected?: (index: number) => void;

const VerticalPinList: React.FunctionComponent<Props> = (props) => {
  const { pins, selectedPins, togglePinSelectionState, setActivePin, activePin, label } = props;

  const renderPins = () => {
    return pins.map((pin) => (
      <PinResult
        pin={pin}
        selected={selectedPins.includes(pin._id)}
        toggleSelectPin={togglePinSelectionState.bind(pin._id)}
        key={pin._id}
        pinSize={250}
        onClick={setActivePin}
        highlighted={activePin === pin._id}
      />
    ));
  };

  return (
    <div className="pin-list-vertical">
      <span>{label}</span>
      <div className="pins-vertical">{renderPins()}</div>
    </div>
  );
};

export default VerticalPinList;
