const useScrollPage = () => {
  function scrollToTop() {
    if (!isBrowser) return;

    window.scrollTo(0, 0);
  }

  function isBrowser() {
    return typeof window !== 'undefined';
  }

  return { scrollToTop };
};

export default useScrollPage;
