import { useCallback, useState } from 'react';

function useToggle(defaultValue: boolean, onChange?: (val: boolean) => void): [boolean, (val?: boolean) => void] {
  const [value, setValue] = useState(defaultValue);
  const toggle = useCallback(
    (val?: boolean) => {
      const newVal = val != null ? val : !value;
      if (onChange) {
        onChange(newVal);
      }
      setValue(newVal);
    },
    [onChange, value]
  );

  return [value, toggle];
}

export default useToggle;
