import React, { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';
import { useEventListener } from '../../../utils/hooks/useEventListener';

export const useHighlightRow = (): [
  React.RefObject<HTMLTableRowElement>,
  boolean,
  Dispatch<SetStateAction<boolean>>
] => {
  const element = useRef<HTMLTableRowElement>(null);
  const [highlighted, setHighlighted] = useState<boolean>(false);

  const handleMouseEnter = useCallback(
    (event) => {
      if (element.current?.contains(event.target)) {
        setHighlighted(true);
      }
    },
    [element, highlighted]
  );

  const handleMouseLeave = useCallback(
    (event) => {
      if (element.current?.contains(event.target)) {
        setHighlighted(false);
      }
    },
    [element]
  );

  useEventListener('mouseenter', handleMouseEnter, element);
  useEventListener('mouseleave', handleMouseLeave, element);
  useEventListener('touchstart', handleMouseEnter, element);

  return [element, highlighted, setHighlighted];
};
