import classNames from 'classnames';
import React, { useCallback } from 'react';
import Checkmark from '../svgIcons/Checkmark';
import './Checkbox.less';

export type CheckBoxProps = {
  id: string;
  label?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  theme?: 'default' | 'secondary';
  height?: 'default' | 'large' | 'small';
  hitBox?: 'default';
  stopPropagation?: boolean;
  autofocus?: boolean;
  className?: string;
  dispatchEventToWindow?: boolean;
  disabled?: boolean;
};

/**
 * @example
 * const [checked, toggleChecked] = useToggle(false)
 * <Checkbox checked={checked} onChange={toggleChecked} label="Checkbox label" />
 */
const Checkbox: React.FC<CheckBoxProps> = ({
  id,
  checked = false,
  label,
  onChange,
  theme,
  height,
  stopPropagation,
  className,
  hitBox,
  dispatchEventToWindow,
  disabled,
}) => {
  const labelId = `label-${id}`;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.currentTarget.checked);
    },
    [onChange]
  );
  const handleOnClick = (event: React.MouseEvent) => {
    if (dispatchEventToWindow) window.dispatchEvent(new Event('click'));
    if (stopPropagation) event.stopPropagation();
    if (disabled) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
  };

  const themeClassName = () => `theme-${theme || 'default'}`;
  const heightClassName = () => `height-${height || 'default'}`;
  const showHitBox = !!hitBox;
  return (
    <span
      className={classNames(
        'checkbox',
        { 'checkbox--checked': checked, 'checkbox--hit-box': showHitBox, 'checkbox--disabled': disabled },
        themeClassName(),
        heightClassName(),
        className
      )}
    >
      <span className={classNames('checkbox__control')}>
        {showHitBox && <HitBox labelId={labelId} handleOnClick={handleOnClick} />}
        <Checkmark className="checkmark" role="presentation" focusable="false" aria-hidden="true" />
      </span>

      <input
        id={labelId}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        className="checkbox__hidden-input"
        onClick={handleOnClick}
        disabled={disabled}
      />
      {label && (
        <label className="checkbox__label" htmlFor={labelId}>
          {label}
        </label>
      )}
    </span>
  );
};

const HitBox = ({ labelId, handleOnClick }: { labelId: string; handleOnClick: (event: React.MouseEvent) => void }) => {
  return (
    <label className="checkbox__hit-box" htmlFor={labelId} onClick={handleOnClick}>
      <label className="checkbox__hit-box-animation" />
    </label>
  );
};

export default Checkbox;
