import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../shared-core/ui/components/Button';
import SearchIcon from '../../../../shared-core/ui/components/svgIcons/Search';
import './ChannelFilter.less';

interface ChannelFilterProps {
  onSubmit: (query: string) => void;
  onChange: (query: string) => void;
}

const handleFormSubmit = (event: FormEvent<HTMLFormElement>, query: string, callback: (query: string) => void) => {
  event.preventDefault();
  callback(query);
};

export const ChannelFilter: React.FC<ChannelFilterProps> = ({ onSubmit, onChange }) => {
  const { t } = useTranslation('channelFilter');

  const [filterQuery, setFilterQuery] = useState('');

  return (
    <form onSubmit={(event) => handleFormSubmit(event, filterQuery, onSubmit)} className="channelfilter default-form">
      <input
        className="channelfilter__input"
        placeholder={t('placeholder')}
        type="text"
        name="query"
        onChange={(event) => {
          onChange(event.target.value);
          setFilterQuery(event.target.value);
        }}
      />
      <SearchIcon className="channelfilter__icon" />
      <Button version="modern" theme="secondary" height="medium" type="submit" className="channelfilter__button">
        {t('search')}
      </Button>
    </form>
  );
};
