import React from 'react';

const PlusIcon = (props) => (
  <svg viewBox="0 0 14 14" {...props}>
    <title>Plus Icon</title>
    <path fill="#5A4D4C" d="M6 0h2v14H6z"></path>
    <path fill="#5A4D4C" d="M0 6h14v2H0z"></path>
  </svg>
);

export default PlusIcon;
