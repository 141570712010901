import { Component } from '@lessonup/teaching-core';
import _ from 'lodash';
import React from 'react';
import { secondsToPrettyTime } from '../../../../utils/TimeUtils';
import ComponentContainer from '../componentContainer/ComponentContainer';

interface Props {
  component: Component.Timer;
  onClickHandler?: Function;
  isThumb?: boolean;
}

export default class TimerComponentView extends React.PureComponent<Props> {
  type = () => {
    return this.props.component.settings.stopwatch ? 'stopwatch' : 'timer';
  };

  render() {
    const { startTime, showMiliSec, color } = this.props.component.settings;
    const time = this.type() === 'timer' ? secondsToPrettyTime(startTime) : '00:00';

    return (
      <ComponentContainer component={this.props.component}>
        <div className="timer-border" data-grippy="move">
          <TimerComponentBorder percentage={40} color={color} />
          <div className="timer-center" data-grippy="move">
            <div className="type">{this.type()}</div>
            <div className="time">
              {time}
              <span className="small">{showMiliSec && '000'}</span>
            </div>
          </div>
        </div>
      </ComponentContainer>
    );
  }
}

interface TimerComponentBorderProps {
  color: string;
  percentage: number;
}

function TimerComponentBorder(props: TimerComponentBorderProps) {
  // TODO: copied from old timerViewComponents -> needs some love

  const borderStyles = () => {
    const { color, percentage } = props;
    const deg = (360 / 100) * percentage;
    const normPercentage = percentage % 100;

    const maskmain: { [key: string]: any } = {
      transform: `rotate(${deg}deg)`,
    };
    const mask1: { [key: string]: any } = {};
    const mask2: { [key: string]: any } = {};
    const mask3: { [key: string]: any } = {};
    const mask4: { [key: string]: any } = {};

    /*----------------
    hide or show the masks based on the position on the circle
    1 and 2 are fill color
    3 and 4 are nofill color
    Masks are postioned like this:
    ---------
    | 4 | 1 |
    ---------
    | 3 | 2 |
    ---------*/

    if (normPercentage > 70) {
      mask3.display = 'none';
      mask4.display = 'none';
    } else if (normPercentage > 40) {
      mask2.display = 'none';
      mask3.display = 'none';
    } else if (normPercentage > 20) {
      mask1.display = 'none';
      mask2.display = 'none';
    } else if (normPercentage < 1 && percentage > 90) {
      mask4.display = 'none';
      mask3.backgroundColor = _.includes(color, '#') ? color : '';

      // because there is a small delay on the animation, small overflow period
      // otherwise the circle is not complete, which looks stupid
    } else {
      mask1.display = 'none';
      mask2.display = 'none';
    }

    return {
      maskmain,
      mask1,
      mask2,
      mask3,
      mask4,
    };
  };

  const mask3Class = () => {
    const { percentage, color } = props;
    return percentage % 100 < 1 && percentage > 90 ? `bg-${props.color}` : '';
  };

  // TODO: Dont forget customColor

  return (
    <div className="masks" data-grippy="move">
      <div className={`mask maskmain bg-${props.color}`} style={borderStyles().maskmain} data-grippy="move"></div>
      <div className="mask mask1 bg-{{settings.color}}" style={borderStyles().mask1} data-grippy="move"></div>
      <div className="mask mask2 bg-{{settings.color}}" style={borderStyles().mask2} data-grippy="move"></div>
      <div className={`mask mask3 ${mask3Class()}`} style={borderStyles().mask3} data-grippy="move"></div>
      <div className="mask mask4" style={borderStyles().mask4} data-grippy="move"></div>
    </div>
  );
}
