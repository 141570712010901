import { Component } from '@lessonup/teaching-core';
import React from 'react';
import ComponentContainer from '../componentContainer/ComponentContainer';
import { symbolReactCompForName } from './renderSymbol';

interface Props {
  component: Component.SymbolComp;
  onClickHandler?: Function;
  isThumb?: boolean;
}

export default class SymbolComponentView extends React.PureComponent<Props> {
  public render() {
    const pos = this.props.component.position;
    const settings = this.props.component.settings;
    const ComponentSVG = symbolReactCompForName(settings.symbol);

    return (
      <ComponentContainer {...this.props} componentTypeClasses={``} isInteractive={false}>
        <div data-grippy="move"></div>
        <ComponentSVG position={pos} settings={settings}></ComponentSVG>
      </ComponentContainer>
    );
  }
}
