import { EnvContext, stripTrailingSlash } from '@lessonup/utils';
import { childOrganizationPickerRoute, searchItemRoute, searchRoute, shareLessonRoute } from './searchRoutes';

export class EnvConfig extends EnvContext.Config {
  public studentRegistrationUrl(): string {
    return `${this.studentUrl}register`;
  }

  public studentLoginUrl(): string {
    return `${this.studentUrl}login`;
  }

  public studentHomeworkUrl(assignmentId: string) {
    return `${this.studentUrl}invite/h/${assignmentId}`;
  }

  public teacherPlanUrl(planId: string) {
    return `${this.teacherUrl}app/view-plan/${planId}`;
  }

  public teacherAccountUrl() {
    return `${this.teacherUrl}app/account/license`;
  }

  public teacherMyLessonsUrl() {
    return `${this.teacherUrl}app/explorer/lessons`;
  }

  public searchChildPickerUrl(organizationId: string) {
    return stripTrailingSlash(this.searchUrl) + childOrganizationPickerRoute(organizationId);
  }

  public channelUrl(slug: string) {
    return `${this.teacherUrl}app/channel/${slug}`;
  }

  public editorUrl(lessonId: string) {
    return `${this.teacherUrl}app/lesson/${lessonId}`;
  }

  public shareLessonUrl(lessonId: string) {
    return stripTrailingSlash(this.searchUrl) + shareLessonRoute(lessonId);
  }

  public searchLessonUrl() {
    return `${stripTrailingSlash(this.searchUrl)}${searchRoute()}`;
  }

  public searchItemUrl() {
    return `${stripTrailingSlash(this.searchUrl)}${searchItemRoute()}`;
  }

  public termsAndConditionsUrl() {
    return `${this.teacherUrl}nl/voorwaarden`;
  }

  public secureCookies() {
    return this.env !== 'local';
  }
}
