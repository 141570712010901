import { logger, useApolloClient } from '@lessonup/client-integration';
import { IntercomUserHashDocument } from '@lessonup/teacher-modern';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UserContext } from '../../../shared-core/domain';
import { isUserSignedIn, loggedInUser } from '../../redux/selectors';
import { syncUserToIntercom } from '../../utils/bridge/bridgeIntercomUserSync';
import { ClientOnlyModernWrapper } from '../../utils/bridge/ClientOnlyModernWrapper';

export const LoginStateWatcher = () => {
  return (
    <ClientOnlyModernWrapper>
      <LoginStateWatcherInner />
    </ClientOnlyModernWrapper>
  );
};

const LoginStateWatcherInner = () => {
  const client = useApolloClient();
  const isUserLoggedIn = useSelector(isUserSignedIn());
  const currentUser = useSelector(loggedInUser());

  const updateUser = useCallback(
    async (user: UserContext) => {
      try {
        if (!currentUser?._id) return;

        const result = await client.query({ query: IntercomUserHashDocument, fetchPolicy: 'cache-first' });
        const hash = result?.data?.viewer.intercomHash;
        if (!hash) {
          return;
        }

        return syncUserToIntercom(user, hash);
      } catch (error) {
        logger.error(error, { label: 'fetchIntercomHash' });
      }
    },
    [client, currentUser?._id]
  );

  useEffect(() => {
    if (currentUser && isUserLoggedIn) {
      updateUser(currentUser);
    }
  }, [currentUser, isUserLoggedIn, updateUser]);

  return null;
};
