import { Lesson, LessonPlanTrail, LessonPrivacy, UserContent } from '@lessonup/teaching-core';
import _, { compact, first, flatten, sortBy, toArray, uniq } from 'lodash';
import { UpdateFilter } from 'mongodb';
import { Location } from '../newExplorers';

export interface LessonPlan extends UserContent {
  user: string;
  location: Location;
  name: string | undefined;
  description: string | undefined;
  country: string[] | undefined;
  children: LessonPlan.Children;
  sharedExplorer?: string;
  metaData?: {
    schoolType: string[];
    levels: string[];
    years: number[];
    subjects: string[];
    country: string[];
  };
  modifiedDate?: Date | undefined;
  productsLocked?: boolean;
  trail?: LessonPlanTrail[];
  defaultOpenInView?: boolean;
}

export namespace LessonPlan {
  export const COLUMN_WIDTH = 3;
  export interface Child {
    id: string;
    order: number;
    cols?: 1 | 2 | 3;
  }

  export interface Children {
    [key: string]: Children.AnyChild;
  }

  export namespace Children {
    export type AnyChild =
      | Lesson
      | Text
      | Header
      | Link
      | Image
      | Attachment
      | Youtube
      | Vimeo
      | LessonPlanChild
      | Folder;
    export type Type = AnyChild['type'];

    export interface Lesson extends Child {
      type: 'lesson';
    }

    export interface Text extends Child {
      type: 'text';
      text: string;
      align: 'center' | 'left' | 'right' | 'justify' | undefined;
    }

    export interface Header extends Child {
      type: 'header';
      text: string;
    }

    export interface Link extends Child {
      type: 'link';
      url: string;
      image: string;
    }

    export interface Image extends Child {
      type: 'image';
      url: string;
      imageSize: 'cover' | 'contain' | undefined;
      uploadId?: string;
    }

    export interface LessonPlanChild extends Child {
      type: 'lessonPlan';
    }

    export interface Folder extends Child {
      type: 'folder';
    }

    export interface Attachment extends Child {
      type: 'attachment';
      /**@deprecated Not set for uploads after April 2023. Use the uploadId to generate a download link. */
      url?: string;
      attachmentType: string;
      image?: string;
      contentType?: string;
      name?: string;
      uploadId?: string;
    }

    export type Video = Youtube | Vimeo;

    interface GenericVideo extends Child {
      name?: string;
      image?: string;
      maxresImage?: string;
      duration?: number;
    }

    export interface Youtube extends GenericVideo {
      type: 'youtube';
      youtubeId: string;
    }

    export interface Vimeo extends GenericVideo {
      type: 'vimeo';
      vimeoId: string;
    }
  }

  export function originInfo(plan?: LessonPlan): { originId?: string; originChannel?: string } | undefined {
    if (plan === undefined) return undefined;
    if (plan.origin) {
      return {
        originId: plan.origin.id,
        originChannel: plan.origin.channel,
      };
    }
    if (plan.trail) {
      return {
        originChannel: getTrailValue(plan.trail, 'channel'),
        originId: getTrailValue(plan.trail, 'plan'),
      };
    }
    return {
      originChannel: undefined,
      originId: undefined,
    };
  }

  type Trail = LessonPlanTrail;

  function getTrailValue<T extends keyof Trail>(trail: Trail[], key: T): Trail[T] | undefined {
    return first(uniq(flatten(compact(trailParentValues(trail, key)))));
  }

  function trailParentValues<T extends keyof Trail>(trail: Trail[], key: T): Trail[T][] {
    return trail.map((t) => t[key]);
  }

  export function sortedChildren(plan: LessonPlan): Children.AnyChild[] {
    return _.sortBy(Object.values(plan.children), 'order');
  }

  export function sections(plan: LessonPlan): Children.AnyChild[][] {
    const children = sortedChildren(plan);
    const sections = children.reduce<Children.AnyChild[][]>(
      (mem, child) => {
        if (child.type === 'header' && mem[mem.length - 1].length) {
          mem.push([]);
        }
        mem[mem.length - 1].push(child);
        return mem;
      },
      [[]]
    );
    return sections;
  }

  const defaultCols = {
    header: 3,
    text: 2,
    image: 1,
    link: 1,
    lesson: 1,
    attachment: 1,
    youtube: 2,
    vimeo: 2,
    folder: 1,
    lessonPlan: 1,
  };

  export function childCols(childType: Children.Type) {
    return defaultCols[childType];
  }

  export function isLesson(child: Children.AnyChild): child is Children.Lesson {
    return child.type === 'lesson';
  }

  export function lessons(plan: LessonPlan) {
    return _.filter(plan.children, isLesson);
  }

  export function isAttachment(child: Children.AnyChild): child is Children.Attachment {
    return child.type === 'attachment';
  }

  export function attachments(plan: LessonPlan): Children.Attachment[] {
    return _.filter(plan.children, isAttachment);
  }

  export function isLink(child: Children.AnyChild): child is Children.Link {
    return child.type === 'link';
  }

  export function links(plan: LessonPlan) {
    return _.filter(plan.children, isLink);
  }

  export function isImage(child: Children.AnyChild): child is Children.Image {
    return child.type === 'image';
  }

  export function images(plan: LessonPlan) {
    return _.filter(plan.children, isImage);
  }

  export function filterList<T extends Children.AnyChild>(list: any[], filter: T['type'][]): (T | undefined)[] {
    return list.filter((p) => filter.includes(p.type));
  }

  export function lessonIds(plan: LessonPlan) {
    return lessons(plan).map((ch) => ch.id);
  }

  export function isOwner(plan: LessonPlan, userId: string): boolean {
    return plan.user == userId;
  }

  export function latestTrail(plan: LessonPlan): LessonPlanTrail[] {
    const trail = plan.trail || [];
    return plan.channel ? trail.concat({ channel: plan.channel, d: new Date(), plan: plan._id }) : trail;
  }

  export function copy(params: {
    plan: LessonPlan;
    user: string;
    newPlanId: string;
    copiedChildren: LessonPlan.Children;
    privacy: LessonPrivacy;
    location: Location;
    order?: string;
    canEditProducts?: boolean;
  }): LessonPlan {
    const { plan, user, newPlanId, copiedChildren, location, order, canEditProducts } = params;

    const copiedPlanData = _.pick(plan, [
      'name',
      'description',
      'country',
      'products',
      'defaultOpenInView',
      'metaData',
    ]);

    const channelData = (plan.channel || plan.trail) && { trail: updatedPlanTrail(plan.trail, plan.channel, plan._id) };

    const now = new Date();
    const newPlan: LessonPlan = {
      _id: newPlanId,
      user,
      ...copiedPlanData,
      ...channelData,
      productsLocked: !!plan.products?.length && !canEditProducts,
      modifiedDate: now,
      creationDate: now,
      privacy: params.privacy,
      children: copiedChildren,
      location,
      order,
    };

    return newPlan;
  }

  export function insertChildQuery(
    plan: LessonPlan,
    newChild: Omit<LessonPlan.Children.AnyChild, 'order'>,
    order?: number
  ): Pick<UpdateFilter<LessonPlan>, '$set'> {
    const childrenArray = toArray(plan.children);
    const children = sortBy(childrenArray, 'order');

    const finalOrder = !order || order > children.length ? children.length : order;

    // Typescript doesn't understand this is an AnyChild because of the Omit in the parameters
    const updatedChild = { ...newChild, order: finalOrder } as LessonPlan.Children.AnyChild;

    children.splice(finalOrder, 0, updatedChild);
    const $set: UpdateFilter<LessonPlan>['$set'] = {
      [`children.${updatedChild.id}`]: updatedChild,
    };
    children.forEach((child, index) => {
      const childIsSameAsNewChild = child.id === updatedChild.id || index === child.order;
      if (!childIsSameAsNewChild) {
        $set[`children.${child.id}.order`] = index;
      }
    });
    return { $set };
  }

  function updatedPlanTrail(currentTrail: LessonPlanTrail[] | undefined, channel: string | undefined, planId: string) {
    if (!channel) return currentTrail || [];
    return (currentTrail || []).concat({
      channel,
      d: new Date(),
      plan: planId,
    });
  }

  export function getContentPropertiesForPlan(plan: LessonPlan, lesson: Lesson) {
    return {
      privacy: plan.privacy,
      channel: plan.channel,
      productsLocked: lesson.productsLocked,
      products: lesson.productsLocked ? lesson.products : plan.products,
    };
  }
}
