import React from 'react';
import { useTranslation } from 'react-i18next';
import { useBEM } from '../../../utils/hooks';
import { ModalProps } from '../../../utils/ModalManagerService';
import Button from '../../Button';
import ModalButtonBar from '../ModalButtonBar';
import { NewModal } from '../NewModal';
import './NotificationModal.less';

export type NotificationModalParams = {
  translationKey: string;
  children?: JSX.Element;
  ariaLabel?: string;
};

export type NotificationModalResponse = undefined;

type Props = ModalProps<NotificationModalResponse> & NotificationModalParams;
export const NotificationModal: React.FC<Props> = ({ closeModal, children, translationKey, ariaLabel }) => {
  const { t } = useTranslation(translationKey);
  const bemClasses = useBEM('NotificationModal');

  function handleConfirm() {
    closeModal(undefined);
  }

  return (
    <NewModal
      isOpen
      width="medium"
      title={t('title')}
      className={bemClasses()}
      onRequestClose={handleConfirm}
      ariaLabel={ariaLabel || 'notification'}
    >
      {children ? (
        <div className={bemClasses({ element: 'content-container' })}>{children}</div>
      ) : (
        <div>
          <p>{t('body')}</p>
        </div>
      )}
      <div>
        <ModalButtonBar className={bemClasses({ element: 'actions', className: 'margin-top-4' })}>
          <Button height="medium" version="modern" onClick={handleConfirm}>
            {t('confirmButtonText')}
          </Button>
        </ModalButtonBar>
      </div>
    </NewModal>
  );
};
