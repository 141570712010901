import React, { ChangeEvent, FormEvent } from 'react';
import { Translation } from 'react-i18next';
import SearchIcon from '../../../../shared-core/ui/components/svgIcons/Search';
import './searchform.less';

export interface Props {
  onSubmit?: () => void;
  onTextChange?: (searchText: string) => void;
  textValue?: string;
  placeholder?: string;
}

export default class SearchForm extends React.Component<Props, {}> {
  public constructor(props: Props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  private handleFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault(); // Prevent page reload

    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  }

  private handleTextChange(event: ChangeEvent<HTMLInputElement>) {
    if (this.props.onTextChange) {
      this.props.onTextChange(event.target.value);
    }
  }

  public render() {
    return (
      <Translation>
        {(t) => {
          return (
            <form onSubmit={this.handleFormSubmit} className="searchform">
              <input
                className="searchform__input"
                placeholder={this.props.placeholder || t('placeholder', { ns: 'search' })}
                type="search"
                results={5}
                name="lesson-search"
                value={this.props.textValue}
                onChange={this.handleTextChange}
              />
              <button className="searchform__button" type="submit">
                <SearchIcon className="searchform__icon" />
              </button>
            </form>
          );
        }}
      </Translation>
    );
  }
}
