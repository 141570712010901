import React from 'react';
import { PartialIndexedLessonPlan, SearchParams, SearchResultItem } from '../../../shared-core/domain/search';
import { FlexBoxDummies } from '../../../shared-core/ui/utils/FlexBoxDummies';
import PlanSummaryCard from './PlanSummaryCard';

interface Props {
  plans: SearchResultItem<PartialIndexedLessonPlan>[];
  searchParams: SearchParams;
  oneLiner: boolean;
}

const PlanSummaryCards: React.FunctionComponent<Props> = (props) => {
  const { plans, oneLiner } = props;

  const Plans = plans.map((item) => {
    const lessons = item.doc.lessons.map((doc) => doc.lesson);
    return (
      <PlanSummaryCard
        lessons={lessons}
        metaData={item.doc.metaData}
        key={item._id}
        plan={item.doc.plan}
        channel={item.doc.channel}
      />
    );
  });

  const pageClass = oneLiner ? 'one-liner' : 'full-page';
  return (
    <div className={`plan-summary-cards ${pageClass}`}>
      <div className="cards">
        {Plans}
        {FlexBoxDummies('base-summary-card plan-summary-card', 5)}
      </div>
    </div>
  );
};

export default PlanSummaryCards;
