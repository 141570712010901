import { tracker } from '@lessonup/analytics';
import { logger } from '@lessonup/client-integration';
import { setUser } from '@lessonup/intercom';
import { IntercomData, Language } from '../../../shared-core/domain';
import { UserContext } from '../../../shared-core/domain/user/UserContext';

export function syncUserToIntercom(user: UserContext, userHash: string) {
  const userDataForIntercom = IntercomData.userDataForContext(user);

  if (!user || !userDataForIntercom || !tracker.cookieSettings()?.analytic) return;

  try {
    const { user_id, ...userData } = userDataForIntercom;

    setUser({
      ...userData,
      id: user_id,
      language: Language.fallbackForLanguage(user.language),
      hash: userHash,
    });
  } catch (error) {
    logger.error('Could not fetch user hash', error);
  }
}
