import { TOptions } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  translateKey: string;
  options?: TOptions | undefined;
}

export const Text = ({ translateKey, options }: Props) => {
  const { t } = useTranslation();
  // Temp fix, as Typescript will complain about the result not being a react element otherwise.
  return <>{options ? t(translateKey, options) : t(translateKey)}</>;
};
