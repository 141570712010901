import { Lesson } from '@lessonup/teaching-core';
import classNames from 'classnames';
import React from 'react';
import {
  LessonPlanAttachment,
  LessonPlanFolder,
  LessonPlanHeader,
  LessonPlanImage,
  LessonPlanLink,
  LessonPlanText,
  LessonPlanVideo,
  PlanSummaryCard,
} from '.';
import { IndexedChannelInfo, LessonPlan } from '../../../shared-core/domain';
import { FolderRenderParams } from '../../../shared-core/domain/newExplorers';
import { FacetMetaData } from '../../../shared-core/domain/search/content/FacetMetaData';
import { channelRoute } from '../../../shared-core/services/app/searchRoutes';
import LessonSummaryCard from '../lesson/lessonSummaryCard/LessonSummaryCard';
import './css/LessonPlanChild.less';

const planElements: Record<LessonPlan.Children.Type, React.FC<any>> = {
  header: LessonPlanHeader,
  text: LessonPlanText,
  image: LessonPlanImage,
  link: LessonPlanLink,
  lesson: LessonSummaryCard,
  attachment: LessonPlanAttachment,
  youtube: LessonPlanVideo,
  vimeo: LessonPlanVideo,
  lessonPlan: PlanSummaryCard,
  folder: LessonPlanFolder,
};

type LinkTo = (lessonId: string) => string;

type Props = {
  child: LessonPlan.Children.AnyChild;
  lessons: Lesson[] | undefined;
  folders?: FolderRenderParams[] | undefined;
  plans?: LessonPlan[];
  channelInfo?: IndexedChannelInfo;
  linkToChannel?: LinkTo;
  planId?: string;
};

function getFolderUrl(folderId: string, channelSlug: string | undefined) {
  if (!channelSlug) return;
  return channelRoute({ channelSlug, channelPage: 'series', selectionId: folderId });
}

function getFolderData(
  folders: FolderRenderParams[],
  channelSlug: string,
  child: LessonPlan.Children.Folder
): { folder: FolderRenderParams; url?: string } | undefined {
  const folder = folders.find((f) => f.id === child.id);

  if (folder) {
    const url = getFolderUrl(folder.id, channelSlug);
    return { folder, url };
  }

  return undefined;
}

const LessonPlanChild: React.FC<Props> = ({ child, lessons, linkToChannel, plans, folders, channelInfo, planId }) => {
  const columnClasses = ['no-columns', 'one-column', 'two-columns', 'three-columns'];
  const channelSlug = channelInfo?.name;
  const shouldNotRenderFolder = child.type === 'folder' && (!folders?.length || !channelSlug);
  const shouldNotRenderPlan = child.type === 'lessonPlan' && !plans?.length;
  const shouldNotRenderLesson = child.type === 'lesson' && !lessons?.length;
  const PlanElement = planElements[child.type];
  const numberOfColumns = child.cols ? child.cols : LessonPlan.childCols(child.type);
  const columnClass = columnClasses[numberOfColumns];

  if (shouldNotRenderFolder || shouldNotRenderPlan || shouldNotRenderLesson || !PlanElement) {
    return null;
  }

  if (child.type === 'folder' && folders && channelSlug) {
    const customData = getFolderData(folders, channelSlug, child);

    return (
      <div className={classNames('plan-child', columnClass)}>
        <PlanElement folder={customData?.folder} url={customData?.url} />
      </div>
    );
  }

  if (child.type === 'lesson' && lessons?.length) {
    const lesson = lessons.find((l) => l._id === child.id);
    if (lesson) {
      const metaData = FacetMetaData.fromLesson({ lesson });
      const lessonData = {
        metaData,
        channel: channelInfo,
        columnClass,
        lesson,
        linkTo: linkToChannel,
        userRating: undefined,
      };

      return (
        <div className={classNames('plan-child plan-lesson', columnClass)}>
          <PlanElement {...lessonData} />
        </div>
      );
    }
  }

  if (child.type === 'lessonPlan' && plans) {
    const plan = plans.find((l) => l._id === child.id);
    if (plan) {
      const metaData = FacetMetaData.fromPlan(plan);
      const planData = { metaData, channel: channelInfo, plan, linkTo: linkToChannel, child, lessons, planId };
      return (
        <div className={classNames('plan-child', columnClass)}>
          <PlanElement {...planData} />
        </div>
      );
    }
  }

  return (
    <div className={classNames('plan-child', columnClass)}>
      <PlanElement child={child} planId={planId} />
    </div>
  );
};

export default LessonPlanChild;
