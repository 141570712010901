import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../shared-core/ui/components/Button';
import TextButton from '../../../../shared-core/ui/components/buttons/TextButton';
import { PIN_SEARCH_TRANSLATION_NS } from '../../../pages/search/pins/PinSearchPage';

interface PinSearchBottomBarProps {
  selectedCount: number;
  handleSubmit: () => void;
  deselectAllPins: () => void;
  children?: React.ReactNode;
}

export const PinSearchBottomBar: React.FunctionComponent<PinSearchBottomBarProps> = ({
  selectedCount,
  handleSubmit,
  deselectAllPins,
  children,
}) => {
  const { t } = useTranslation(PIN_SEARCH_TRANSLATION_NS);

  return (
    <div className="pin-search-bar bottom-bar">
      {selectedCount ? (
        <React.Fragment>
          <ActionButton height="small" theme="secondary" onClick={handleSubmit}>
            {t('addSelectedPins', { count: selectedCount })}
          </ActionButton>
          <TextButton className="align-end deselect-pins-link" onClick={deselectAllPins}>
            {t('deselectPins')}
          </TextButton>
        </React.Fragment>
      ) : (
        <span>{t('selectPinsPlaceHolder')}</span>
      )}
      {children}
    </div>
  );
};
