import styled from '@emotion/styled';
import { font } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ErrorContent = styled.div`
  flex-grow: 0;
  padding: 1.2em;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const ErrorTitle = styled.h2`
  font-family: ${font.heading}, sans-serif;
  font-weight: bold;
  margin-bottom: 18px;
`;

const ErrorDescription = styled.p`
  white-space: break-spaces;
  font-size: 18px;
  margin-top: 18px;
  margin-bottom: 0px;
`;

const TRANSLATION_NS = 'ltiDeeplinkError';

export default function LtiDeeplinkError() {
  const { t } = useTranslation(TRANSLATION_NS);
  return (
    <ErrorContent>
      <ErrorTitle>{t('title')}</ErrorTitle>
      <ErrorDescription>{t('message')}</ErrorDescription>
    </ErrorContent>
  );
}
