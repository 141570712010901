import { includes, partition } from 'lodash';
import { LanguageKey } from '../language/Language';

export namespace Country {
  export type CountryCode = keyof typeof countryMap;
  export type CountryTier = 'nl' | 'gb' | 'eu' | 'world';
  export type CountryTuple = [CountryCode, string];

  // ISO-3166 alpha-2
  const countryMap = {
    ad: 'Andorra',
    ae: 'United Arab Emirates',
    af: 'Afghanistan',
    ag: 'Antigua and Barbuda',
    ai: 'Anguilla',
    al: 'Albania',
    am: 'Armenia',
    ao: 'Angola',
    aq: 'Antarctica',
    ar: 'Argentina',
    as: 'American Samoa',
    at: 'Austria',
    au: 'Australia',
    aw: 'Aruba',
    ax: 'Åland',
    az: 'Azerbaijan',
    ba: 'Bosnia and Herzegovina',
    bb: 'Barbados',
    bd: 'Bangladesh',
    be: 'Belgium',
    bf: 'Burkina Faso',
    bg: 'Bulgaria',
    bh: 'Bahrain',
    bi: 'Burundi',
    bj: 'Benin',
    bl: 'Saint Barthélemy',
    bm: 'Bermuda',
    bn: 'Brunei',
    bo: 'Bolivia',
    bq: 'Bonaire',
    br: 'Brazil',
    bs: 'Bahamas',
    bt: 'Bhutan',
    bv: 'Bouvet Island',
    bw: 'Botswana',
    by: 'Belarus',
    bz: 'Belize',
    ca: 'Canada',
    cc: 'Cocos [Keeling] Islands',
    cd: 'Democratic Republic of the Congo',
    cf: 'Central African Republic',
    cg: 'Republic of the Congo',
    ch: 'Switzerland',
    ci: 'Ivory Coast',
    ck: 'Cook Islands',
    cl: 'Chile',
    cm: 'Cameroon',
    cn: 'China',
    co: 'Colombia',
    cr: 'Costa Rica',
    cu: 'Cuba',
    cv: 'Cape Verde',
    cw: 'Curacao',
    cx: 'Christmas Island',
    cy: 'Cyprus',
    cz: 'Czech Republic',
    de: 'Germany',
    dj: 'Djibouti',
    dk: 'Denmark',
    dm: 'Dominica',
    do: 'Dominican Republic',
    dz: 'Algeria',
    ec: 'Ecuador',
    ee: 'Estonia',
    eg: 'Egypt',
    eh: 'Western Sahara',
    er: 'Eritrea',
    es: 'Spain',
    et: 'Ethiopia',
    fi: 'Finland',
    fj: 'Fiji',
    fk: 'Falkland Islands',
    fm: 'Micronesia',
    fo: 'Faroe Islands',
    fr: 'France',
    ga: 'Gabon',
    gb: 'United Kingdom',
    gd: 'Grenada',
    ge: 'Georgia',
    gf: 'French Guiana',
    gg: 'Guernsey',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gl: 'Greenland',
    gm: 'Gambia',
    gn: 'Guinea',
    gp: 'Guadeloupe',
    gq: 'Equatorial Guinea',
    gr: 'Greece',
    gs: 'South Georgia and the South Sandwich Islands',
    gt: 'Guatemala',
    gu: 'Guam',
    gw: 'Guinea-Bissau',
    gy: 'Guyana',
    hk: 'Hong Kong',
    hm: 'Heard Island and McDonald Islands',
    hn: 'Honduras',
    hr: 'Croatia',
    ht: 'Haiti',
    hu: 'Hungary',
    id: 'Indonesia',
    ie: 'Ireland',
    il: 'Israel',
    im: 'Isle of Man',
    in: 'India',
    io: 'British Indian Ocean Territory',
    iq: 'Iraq',
    ir: 'Iran',
    is: 'Iceland',
    it: 'Italy',
    je: 'Jersey',
    jm: 'Jamaica',
    jo: 'Jordan',
    jp: 'Japan',
    ke: 'Kenya',
    kg: 'Kyrgyzstan',
    kh: 'Cambodia',
    ki: 'Kiribati',
    km: 'Comoros',
    kn: 'Saint Kitts and Nevis',
    kp: 'North Korea',
    kr: 'South Korea',
    kw: 'Kuwait',
    ky: 'Cayman Islands',
    kz: 'Kazakhstan',
    la: 'Laos',
    lb: 'Lebanon',
    lc: 'Saint Lucia',
    li: 'Liechtenstein',
    lk: 'Sri Lanka',
    lr: 'Liberia',
    ls: 'Lesotho',
    lt: 'Lithuania',
    lu: 'Luxembourg',
    lv: 'Latvia',
    ly: 'Libya',
    ma: 'Morocco',
    mc: 'Monaco',
    md: 'Moldova',
    me: 'Montenegro',
    mf: 'Saint Martin',
    mg: 'Madagascar',
    mh: 'Marshall Islands',
    mk: 'Macedonia',
    ml: 'Mali',
    mm: 'Myanmar [Burma]',
    mn: 'Mongolia',
    mo: 'Macao',
    mp: 'Northern Mariana Islands',
    mq: 'Martinique',
    mr: 'Mauritania',
    ms: 'Montserrat',
    mt: 'Malta',
    mu: 'Mauritius',
    mv: 'Maldives',
    mw: 'Malawi',
    mx: 'Mexico',
    my: 'Malaysia',
    mz: 'Mozambique',
    na: 'Namibia',
    nc: 'New Caledonia',
    ne: 'Niger',
    nf: 'Norfolk Island',
    ng: 'Nigeria',
    ni: 'Nicaragua',
    nl: 'Netherlands',
    no: 'Norway',
    np: 'Nepal',
    nr: 'Nauru',
    nu: 'Niue',
    nz: 'New Zealand',
    om: 'Oman',
    pa: 'Panama',
    pe: 'Peru',
    pf: 'French Polynesia',
    pg: 'Papua New Guinea',
    ph: 'Philippines',
    pk: 'Pakistan',
    pl: 'Poland',
    pm: 'Saint Pierre and Miquelon',
    pn: 'Pitcairn Islands',
    pr: 'Puerto Rico',
    ps: 'Palestine',
    pt: 'Portugal',
    pw: 'Palau',
    py: 'Paraguay',
    qa: 'Qatar',
    re: 'Réunion',
    ro: 'Romania',
    rs: 'Serbia',
    ru: 'Russia',
    rw: 'Rwanda',
    sa: 'Saudi Arabia',
    sb: 'Solomon Islands',
    sc: 'Seychelles',
    sd: 'Sudan',
    se: 'Sweden',
    sg: 'Singapore',
    sh: 'Saint Helena',
    si: 'Slovenia',
    sj: 'Svalbard and Jan Mayen',
    sk: 'Slovakia',
    sl: 'Sierra Leone',
    sm: 'San Marino',
    sn: 'Senegal',
    so: 'Somalia',
    sr: 'Suriname',
    ss: 'South Sudan',
    st: 'São Tomé and Príncipe',
    sv: 'El Salvador',
    sx: 'Sint Maarten',
    sy: 'Syria',
    sz: 'Swaziland',
    tc: 'Turks and Caicos Islands',
    td: 'Chad',
    tf: 'French Southern Territories',
    tg: 'Togo',
    th: 'Thailand',
    tj: 'Tajikistan',
    tk: 'Tokelau',
    tl: 'East Timor',
    tm: 'Turkmenistan',
    tn: 'Tunisia',
    to: 'Tonga',
    tr: 'Turkey',
    tt: 'Trinidad and Tobago',
    tv: 'Tuvalu',
    tw: 'Taiwan',
    tz: 'Tanzania',
    ua: 'Ukraine',
    ug: 'Uganda',
    um: 'U.S. Minor Outlying Islands',
    us: 'United States',
    uy: 'Uruguay',
    uz: 'Uzbekistan',
    va: 'Vatican City',
    vc: 'Saint Vincent and the Grenadines',
    ve: 'Venezuela',
    vg: 'British Virgin Islands',
    vi: 'U.S. Virgin Islands',
    vn: 'Vietnam',
    vu: 'Vanuatu',
    wf: 'Wallis and Futuna',
    ws: 'Samoa',
    xk: 'Kosovo',
    ye: 'Yemen',
    yt: 'Mayotte',
    za: 'South Africa',
    zm: 'Zambia',
    zw: 'Zimbabwe',
  };

  const dutchLanguageCountryMap: Record<CountryCode, string> = {
    ...countryMap,
    nl: 'Nederland',
    be: 'België',
  };

  export function countryMapForLanguage(language: LanguageKey): Record<CountryCode, string> {
    return language === 'nl' ? dutchLanguageCountryMap : countryMap;
  }

  /**
   * Copied from utils because of failing tests
   * No idea why this is failing
   * TODO: remove this and use utils
   */
  export function objectEntriesTyped<A extends string | number | symbol, B>(record: Partial<Record<A, B>>): [A, B][] {
    return Object.entries(record) as unknown as [A, B][];
  }

  export function countryEntriesForLanguage(language: LanguageKey): CountryTuple[] {
    return objectEntriesTyped(language === 'nl' ? dutchLanguageCountryMap : countryMap);
  }

  export const abbreviations = Object.keys(countryMap);
  export const countries = Object.values(countryMap);
  export const countryEntries = Object.entries(countryMap);
  export const dutchLanguageCountryEntries = objectEntriesTyped(dutchLanguageCountryMap);
  export const defaultCountry: CountryCode = 'nl';

  export const topCountryAbbreviations: CountryCode[] = ['nl', 'at', 'gb', 'us', 'ch', 'de'];
  export const gbCountryAbbreviations: CountryCode[] = ['gb'];
  export const euCountryAbbreviations: CountryCode[] = [
    'be',
    'bg',
    'cz',
    'dk',
    'de',
    'ee',
    'ie',
    'gr',
    'es',
    'fr',
    'hr',
    'it',
    'cy',
    'lv',
    'lt',
    'lu',
    'hu',
    'mt',
    'at',
    'pl',
    'pt',
    'ro',
    'si',
    'sk',
    'fi',
    'se',
  ];

  export function getCountryTier(country: CountryCode): CountryTier {
    if (country === 'nl') return 'nl';
    if (country === 'gb') return 'gb';
    return euCountryAbbreviations.includes(country) ? 'eu' : 'world';
  }

  export function localRankedCountryEntries(language: LanguageKey): CountryTuple[] {
    const [topCountryEntries, otherCountryEntries] = partition(countryEntriesForLanguage(language), (c) =>
      includes(topCountryAbbreviations, c[0])
    );

    return topCountryEntries.concat(otherCountryEntries);
  }

  export function isCountryCode(countryCode: string | undefined): countryCode is CountryCode {
    return !!(countryCode && countryMap[countryCode]);
  }

  export function validCountryCode(countryCode: string | undefined): CountryCode | undefined {
    return isCountryCode(countryCode) ? countryCode : undefined;
  }

  export function lookupCountryByAbbreviation(abbreviation: string, localizeToDutch?: boolean): string | undefined;
  export function lookupCountryByAbbreviation(
    abbreviation: keyof typeof countryMap,
    localizeToDutch?: boolean
  ): string | undefined {
    return localizeToDutch ? dutchLanguageCountryMap[abbreviation] : countryMap[abbreviation];
  }
}
