import React from 'react';
import { useTranslation } from 'react-i18next';
import UserNamePasswordForm from '../../pages/login/UserNamePasswordForm/UserNamePasswordForm';

function PasswordLogin(props: {
  loginWithPassword: (email: string, password: string, source: 'standalone' | 'modal') => Promise<boolean>;
  handleForgotPassword: () => void;
  source: 'standalone' | 'modal';
}): JSX.Element {
  const { t } = useTranslation('login');

  return (
    <div className="login__page-option">
      <h3>{t('emailPasswordHeader')}</h3>
      <UserNamePasswordForm
        login={props.loginWithPassword}
        handleForgotPassword={props.handleForgotPassword}
        source={props.source}
      />
    </div>
  );
}

export default PasswordLogin;
