import { AssignmentPin, DragQuestion, OpenQuestion, Poll, QuizQuestion, Slide, Wordweb } from '@lessonup/teaching-core';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ImageUtils } from '../../../utils/ImageUtils';

export interface ImageComponentProps {
  imageUrl: string | undefined;
  type: string;
  opacity: number | undefined;
  imageSize: string | undefined;
  isThumb?: boolean;
  number2?: boolean;
}

const ImageComponent: React.FC<ImageComponentProps> = (props) => {
  const { imageUrl, type, opacity, imageSize, isThumb, number2 } = props;
  const opacityClass = opacity ? 'opacity-' + opacity : 'opacity-100';

  const [backgroundImage, setBackgroundImage] = useState(() => {
    return ImageUtils.urlString(ImageUtils.checkImageUrl(imageUrl, isThumb, undefined, false));
  });

  useEffect(() => {
    const updatedBackgroundImage = ImageUtils.urlString(ImageUtils.checkImageUrl(imageUrl, isThumb, undefined, true));
    setBackgroundImage(updatedBackgroundImage);
  }, [imageUrl, isThumb]);

  return (
    <div
      data-component-id={type}
      comp-remote-event="click"
      className={classNames(number2 ? 'image2' : 'image', opacityClass, {
        [`image-size-${imageSize}`]: Boolean(imageSize),
      })}
      style={{ backgroundImage }}
    />
  );
};

export default ImageComponent;

type ContentWithImages =
  | Slide.Content
  | DragQuestion.AssignmentContent
  | OpenQuestion.AssignmentContent
  | QuizQuestion.AssignmentContent
  | Wordweb.Content
  | Poll.Content;

export function ImageComponentPropsForPin(
  pin: AssignmentPin<ContentWithImages>,
  type: string,
  isThumb?: boolean
): ImageComponentProps {
  const { item } = pin;
  let imageSize = item.custom.imageSize;

  if (AssignmentPin.isSlide(pin)) {
    const slideContent = pin.item.custom;
    imageSize = type === 'image' ? slideContent.imageSize : slideContent.imageSize2;
  }

  return {
    imageUrl: item[type],
    opacity: item.custom.opacity,
    imageSize,
    type,
    isThumb,
  };
}
