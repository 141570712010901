import { getCookieValueByName, setCookie } from '@lessonup/client-integration';
import { Language } from '../../../domain/language';

export function setLanguageCookie(key: Language.Key) {
  if (!window) return;
  const cookieParams = {
    name: Language.cookieName,
    value: key,
    maxAge: 600000,
  };
  setCookie(cookieParams);
}

export function getLanguageCookie() {
  return getCookieValueByName(Language.cookieName);
}
