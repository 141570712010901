import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckmarkThin from '../../../../../../shared-core/ui/components/svgIcons/CheckmarkThin';
import CloseCrossThinSvg from '../../../../../../shared-core/ui/components/svgIcons/CloseCrossThinSvg';
import { useBEM } from '../../../../../../shared-core/ui/utils/hooks/useBEM';
import { REGISTRATION_SUGGESTIONS_TRANSLATION_NAMESPACE, ResponseStatus } from '../RegistrationSuggestionsPage';

export const RequestResultForSuggestion = ({ requestResult }: { requestResult: ResponseStatus }) => {
  const bemClasses = useBEM('RegistrationSuggestionsPage');
  const { t } = useTranslation(REGISTRATION_SUGGESTIONS_TRANSLATION_NAMESPACE);
  const requestWasSuccessful = ['isSuper', 'success'].includes(requestResult);

  return (
    <div
      className={bemClasses({
        element: 'request-response-container',
        modifier: requestResult,
      })}
    >
      {requestWasSuccessful ? <CheckmarkThin /> : <CloseCrossThinSvg />}
      <p
        className={bemClasses({
          element: 'request-response-text',
        })}
      >
        {t(requestResult)}
      </p>
    </div>
  );
};
