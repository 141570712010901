import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cookieStatementRoute } from '../../../services/app/siteRoutes';
import { ModalProps } from '../../utils/ModalManagerService';
import Button from '../Button';
import './CookiePopup.less';
import { NewModal } from './NewModal';

export interface CookiePopupProps {
  show: boolean;
  onAccept: () => void;
}

const CookiePopup: React.FC<CookiePopupProps> = (props: CookiePopupProps & ModalProps<void>) => {
  const { t } = useTranslation('cookiePopup');
  const [show, setShow] = useState(props.show);

  if (!show) {
    return null;
  }

  function close() {
    setShow(false);
  }

  function handleAccept() {
    props.onAccept();
    close();
  }

  const cookiePopup = (
    <NewModal
      className="cookie-popup-modal"
      title={t(`cookieTitle`)}
      isOpen={true}
      cantClose={true}
      width="small"
      ariaLabel={t(`cookieAriaLabel`)}
    >
      <div className="cookie-info">
        <p>
          <span>{t(`cookieText`)}</span>
          <a href={cookieStatementRoute()}>{t(`cookieStatement`)}</a>
        </p>
      </div>
      <div className="popup-button-wrapper">
        <a href={cookieStatementRoute()} className="cookie-button change-cookies">
          {t(`changeCookie`)}
        </a>
        <Button onClick={handleAccept} className="cookie-button accept-all" theme="blue">
          {t(`acceptCookie`)}
        </Button>
      </div>
    </NewModal>
  );

  return cookiePopup;
};

export default CookiePopup;
