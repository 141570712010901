import { Identifiable } from '@lessonup/utils';
import { Organization } from '../../organizations/';

export interface IndexedOrganization extends Identifiable {
  name: string;
  city: string | undefined;
  licenseType: Organization.OrganizationLicenseType | undefined;
}

export namespace IndexedOrganization {
  export function from(organization: Organization): IndexedOrganization {
    return {
      _id: organization._id,
      name: organization.name,
      city: organization.contact?.city,
      licenseType: organization.license.type,
    };
  }
}
