import { Component } from '@lessonup/teaching-core';
import { MathUtils } from './MathUtils';

export namespace ComponentLibUtils {
  type RatioBox = {
    widthRatio: number;
    heightRatio: number;
  };
  export type Ratio = 'slide' | 'none';
  const defaultRatio: RatioBox = {
    widthRatio: 16,
    heightRatio: 9,
  };
  const noRatio: RatioBox = {
    widthRatio: 1,
    heightRatio: 1,
  };

  // Find new center offset and convert to percents
  export function getComponentShift(
    rotationWithSideOffset: number,
    deltaMouse: number,
    componentSize: MathUtils.Container
  ): MathUtils.RelativePoint {
    const rotationInRad = MathUtils.degreesToRadians(rotationWithSideOffset);
    const deltaValue = deltaMouse / 2;
    const shift: MathUtils.AbsolutePoint = {
      x: Math.cos(rotationInRad) * deltaValue,
      y: Math.sin(rotationInRad) * deltaValue,
      type: 'absolute',
    };
    const relativeShift: MathUtils.RelativePoint = MathUtils.calculateRelativePosition(shift, componentSize);
    return relativeShift;
  }

  /**
   * To calculate where a box should be positioned after scaling we need the rotation to be relative to the adjusted side
   */
  export function getRotationsRelativeToSides(rotationInDeg: number): Component.Box {
    const offsetPerSide = {
      top: 90,
      right: 180,
      bottom: 270,
      left: 0,
    };
    return {
      top: offsetPerSide.top - rotationInDeg,
      right: offsetPerSide.right - rotationInDeg,
      bottom: offsetPerSide.bottom - rotationInDeg,
      left: offsetPerSide.left - rotationInDeg,
    };
  }

  export function getRotatedBoxPositions(
    box: Component.Box,
    componentRotationInDeg: number,
    returnWithOrigin: Component.BoxBottomRightOrigin = 'bottom-right',
    inContainer: MathUtils.Container | undefined = undefined,
    ratioType: Ratio = 'slide'
  ): Component.Box {
    const ratio = ratioType === 'slide' ? defaultRatio : noRatio;
    const rotationInRad = MathUtils.degreesToRadians(componentRotationInDeg);
    const boxSize = MathUtils.getRelativeBoxSize(box, inContainer);
    const relativeCenterPosition = MathUtils.calculateRelativeCenterPosition(box, boxSize);
    const centerToCornerRadius = Math.hypot(
      (boxSize.width * ratio.widthRatio) / 2,
      (boxSize.height * ratio.heightRatio) / 2
    );
    const centerToCornerAngleInRad: number = Math.acos((boxSize.width * ratio.widthRatio) / 2 / centerToCornerRadius);

    const angles: number[] = [
      centerToCornerAngleInRad,
      Math.PI - centerToCornerAngleInRad,
      Math.PI + centerToCornerAngleInRad,
      2 * Math.PI - centerToCornerAngleInRad,
    ];
    const values: { x: number[]; y: number[] } = {
      x: angles.map((angle) => (Math.cos(angle - rotationInRad) * centerToCornerRadius) / ratio.widthRatio),
      y: angles.map((angle) => -(Math.sin(angle - rotationInRad) * centerToCornerRadius) / ratio.heightRatio),
    };
    angles.forEach((angle) => {
      values.x.push((Math.cos(angle - rotationInRad) * centerToCornerRadius) / ratio.widthRatio);
      values.y.push(-(Math.sin(angle - rotationInRad) * centerToCornerRadius) / ratio.heightRatio);
    });
    values.x.sort(sortAscend);
    values.y.sort(sortAscend);
    const bottomLeftOriginBox: Component.Box = {
      left: relativeCenterPosition.x + values.x[0],
      top: relativeCenterPosition.y + values.y[0],
      right: relativeCenterPosition.x - values.x[0],
      bottom: relativeCenterPosition.y - values.y[0],
    };

    if (returnWithOrigin === 'bottom-right') {
      return {
        ...bottomLeftOriginBox,
        right: 100 - bottomLeftOriginBox.right,
        bottom: 100 - bottomLeftOriginBox.bottom,
      };
    }
    return bottomLeftOriginBox;

    function sortAscend(a, b) {
      return a - b;
    }
  }

  export function calculateBoxCornerPosition(
    box: Component.Box,
    componentRotationInDeg: number,
    cornerIndex: number
  ): MathUtils.RelativePoint {
    const rotationInRad = MathUtils.degreesToRadians(componentRotationInDeg);
    const boxSize = MathUtils.getRelativeBoxSize(box);
    const relativeCenterPosition = MathUtils.calculateRelativeCenterPosition(box, boxSize);
    const centerToCornerRadius = Math.hypot(
      (boxSize.width * defaultRatio.widthRatio) / 2,
      (boxSize.height * defaultRatio.heightRatio) / 2
    );
    const centerToCornerAngleInRad: number = Math.acos(
      (boxSize.width * defaultRatio.widthRatio) / 2 / centerToCornerRadius
    );
    const angles: number[] = [
      centerToCornerAngleInRad,
      Math.PI - centerToCornerAngleInRad,
      Math.PI + centerToCornerAngleInRad,
      2 * Math.PI - centerToCornerAngleInRad,
    ];
    const angle = angles[cornerIndex];
    const point: MathUtils.Point = {
      x: relativeCenterPosition.x + (Math.cos(angle - rotationInRad) * centerToCornerRadius) / defaultRatio.widthRatio,
      y: relativeCenterPosition.y - (Math.sin(angle - rotationInRad) * centerToCornerRadius) / defaultRatio.heightRatio,
    };
    return MathUtils.relativePoint(point);
  }

  export function getRotatedBoxTopLeftCorner(
    box: Component.Box,
    componentRotationInDeg: number
  ): MathUtils.RelativePoint {
    const position = ComponentLibUtils.calculateBoxCornerPosition(box, componentRotationInDeg, 1);
    return position;
  }

  export function round(num: number, factor = 1000): number {
    return Math.round(num * factor) / factor;
  }

  export function roundBox(box: Component.Box): Component.Box {
    return {
      left: round(box.left),
      top: round(box.top),
      right: round(box.right),
      bottom: round(box.bottom),
    };
  }
}
