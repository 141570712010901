import { Channel, ChannelTitle } from '../../channels';

export interface IndexedChannelInfo {
  _id: string;
  name: string;
  title: ChannelTitle | undefined;
  icon: string | undefined;
  channelPublic: boolean;
  requireFollow: boolean;
}

export interface ExtendedChannelInfo extends IndexedChannelInfo {
  cover: string | undefined;
  title: ChannelTitle;
  subtitle: ChannelTitle;
  coverFocus?: number;
  publicationStatus?: Channel.PublicationStatus;
  style?: Channel.Styling;
}

export namespace IndexedChannelInfo {
  type PartialProps = '_id' | 'name' | 'title' | 'meta' | 'requireFollow' | 'icon';
  type PartialChan = Pick<Channel, PartialProps>;
  export function fromChannel(channel: PartialChan): IndexedChannelInfo;
  export function fromChannel(channel: undefined): undefined;
  export function fromChannel(channel: PartialChan | undefined): IndexedChannelInfo | undefined;
  export function fromChannel(channel: PartialChan | undefined): IndexedChannelInfo | undefined {
    if (!channel) return undefined;
    return {
      _id: channel._id,
      name: channel.name,
      title: channel.title,
      icon: channel.icon,
      channelPublic:
        Boolean(channel && channel.meta && channel.meta.public) ||
        Boolean(Object.values(channel?.meta?.publicationStatus || []).some((val) => val.public)),
      requireFollow: Boolean(channel && channel.requireFollow),
    };
  }

  export type ExtendedProps = PartialProps | 'cover' | 'subtitle' | 'coverFocus' | 'styling';
  export type PartialChanExtended = Pick<Channel, ExtendedProps>;
  export function extendedInfoFromChannel(channel: PartialChanExtended): ExtendedChannelInfo;
  export function extendedInfoFromChannel(channel: undefined): undefined;
  export function extendedInfoFromChannel(channel: PartialChanExtended | undefined): ExtendedChannelInfo | undefined;
  export function extendedInfoFromChannel(channel: PartialChanExtended | undefined): ExtendedChannelInfo | undefined {
    if (!channel) return undefined;
    return {
      ...fromChannel(channel),
      cover: channel.cover,
      title: channel.title,
      subtitle: channel.subtitle,
      coverFocus: channel.coverFocus,
      publicationStatus: channel.meta?.publicationStatus,
      style: channel.styling,
    };
  }

  export function isExtendedInfo(value: IndexedChannelInfo | ExtendedChannelInfo): value is ExtendedChannelInfo {
    return 'title' in value;
  }
}
