import { stripDoubleSlash, stripTrailingSlashes, urlWithUtm } from '@lessonup/utils';
import { stringify } from 'qs';
import { Language, LanguageSingleton, SearchParams, SearchUrlParams } from '../../domain';

const withLanguage = (route: string, language: string | undefined) => {
  return `/${language || LanguageSingleton.get()}${route}`;
};

export type RegistrationStep =
  | 'select'
  | 'credentials'
  | 'password'
  | 'verification-sent'
  | 'terms'
  | 'organization'
  | 'suggestions'
  | 'demo-lesson'
  | 'pick-child'
  | 'pick-child-school-page'
  | 'registration-gift'
  | 'referral'
  | 'verification-expired'
  | ServerOnlyRegistrationStep;

export type ServerOnlyRegistrationStep = 'verify-email';

export type LoginStep = 'select' | 'form';
export const registerRouteStepKey = 'step' as const;

/** @deprecated Use registerRoute instead. */
export function depricatedRegisterRoute(
  step: RegistrationStep | undefined = undefined,
  router = false,
  language: string | undefined = undefined
): string {
  const useStep = step || (router ? `:${registerRouteStepKey}?` : '');
  return withLanguage(`/search/register/${useStep}`, language);
}

export function registerRoute(
  step: RegistrationStep | undefined = undefined,
  router = false,
  language: string | undefined = undefined,
  organizationId: string | undefined = undefined
): string {
  const useStep = step || (router ? `:${registerRouteStepKey}?` : '');
  const organization = organizationId ?? '';
  const path = stripTrailingSlashes(['register', useStep, organization].join('/'));

  return withLanguage('/' + path, language);
}

export function loginRoute(
  step: LoginStep | undefined,
  router = false,
  language: Language.MainKey | ':language' | undefined = undefined
): string {
  return withLanguage(`/login/${step || (router ? `:step?` : '')}`, language);
}

export const withQueryString = (path: string, qs?: string) => {
  return qs ? (qs[0] === '?' ? `${path}${qs}` : `${path}?${qs}`) : path;
};

export interface LanguageParams {
  language?: string;
}

export type SearchRouteParams = SearchUrlParams &
  LanguageParams & {
    qs?: string;
  };

export type MagicLinkRouteParams = LanguageParams & {
  qs?: string;
};

export const languageKey = ':language';
export const routerLangParams: LanguageParams = {
  language: languageKey,
};

export function lessonSearchRoute(params: SearchRouteParams = {}) {
  const searchUrl = SearchParams.searchUrlPostFix(params);
  return withLanguage(withQueryString(`/lessons/${searchUrl}`, params.qs), params.language);
}
// the default
export const searchRoute = lessonSearchRoute;

export function searchItemRoute(params: SearchRouteParams = {}) {
  const searchUrl = SearchParams.searchUrlPostFix(params);
  return withLanguage(withQueryString(`/search/items/${searchUrl}`, params.qs), params.language);
}

export function searchPlanRoute(params: SearchRouteParams = {}) {
  const searchUrl = SearchParams.searchUrlPostFix(params);
  return withLanguage(withQueryString(`/plans/${searchUrl}`, params.qs), params.language);
}

export function lessonRoute(lessonId: string, pinId?: string | undefined, language?: string | undefined) {
  return withLanguage(pinId ? `/lesson/${lessonId}/${pinId}` : `/lesson/${lessonId}`, language);
}

export function exportLessonRoute(lessonId: string, language?: string | undefined) {
  return withLanguage(`/export/lesson/${lessonId}`, language);
}

export function shareLessonRoute(lessonId: string, pinId?: string | undefined, language?: string | undefined): string {
  const route = lessonRoute(lessonId, pinId, language);

  return urlWithUtm(route, {
    utm_source: 'app',
    utm_campaign: 'shared-lesson-app',
    utm_content: Date.now().toString(),
    utm_medium: 'shared-link',
  });
}

export function searchApiRoute() {
  return '/search/api';
}

export function searchClusterRoute(lessonId: string, language?: string | undefined) {
  return withLanguage(`/search/cluster/${lessonId}`, language);
}

interface RecoverParams extends LanguageParams {
  token?: string;
  userId?: string;
}
export function recoverPasswordRoute(params: RecoverParams = {}) {
  const { token, userId, language } = params;
  const url = withLanguage('/reset-password', language);
  if (!token || !userId) return url;

  const query = recoverPasswordQueryString({ token, userId });
  return `${url}${query}`;
}

export function renewMagicLinkRoute(params: MagicLinkRouteParams) {
  return withLanguage(withQueryString('/magiclink', params.qs), params.language);
}

export function recoverPasswordQueryString(params: { token: string; userId: string }) {
  return stringify(params, { addQueryPrefix: true });
}

export type ChannelRouteParams = {
  channelSlug: string;
  language?: string;
  channelPage?: string;
  selectionId?: string;
  subSelectionId?: string;
} & SearchRouteParams;

// country en subSelectionId, share a spot
export function channelRoute(params: ChannelRouteParams) {
  const searchUrl = SearchParams.searchUrlPostFix(params);

  return withLanguage(
    withQueryString(
      stripTrailingSlashes(
        stripDoubleSlash(
          `/channel/${params.channelSlug}/${params.channelPage || ''}/${params.selectionId || ''}/${
            searchUrl || params.subSelectionId || ''
          }`
        )
      ),
      params.qs
    ),
    params.language
  );
}

export function voucherRoute(language?: string | undefined, redirectUrl?: string) {
  const redirectQueryParam = redirectUrl ? `?redirect-url=${encodeURIComponent(redirectUrl)}` : '';
  return withLanguage('/voucher', language) + redirectQueryParam;
}

export function forgotPasswordRoute(language?: string | undefined) {
  return withLanguage('/forgot-password', language);
}

export function lessonPlanRoute(planId: string, language?: string | undefined) {
  return withLanguage(`/plan/${planId}`, language);
}

export function channelOverviewRoute(language?: string) {
  return withLanguage('/channels', language);
}

interface EmailValidatedParams extends LanguageParams {
  token?: string;
  email?: string;
}

export function retiredEmailValidatedRoute(params: EmailValidatedParams) {
  const { language } = params;
  return withLanguage('/validate', language);
}

export function childOrganizationPickerRoute(organizationId: string, language?: string) {
  return withLanguage(`/child-organization-picker/${organizationId}`, language);
}

export function embedRoute(selectionId: string, pinId?: string | undefined, language?: string | undefined) {
  return withLanguage(pinId ? `/embed/${selectionId}/${pinId}` : `/embed/${selectionId}`, language);
}
