import { Colors, Component } from '@lessonup/teaching-core';
import _ from 'lodash';
import React from 'react';
import { ImageUtils } from '../../../../utils/ImageUtils';
import ComponentContainer from '../componentContainer/ComponentContainer';

interface Props {
  component: Component.Hotspot;
  toggle: (componentId: string, toggleMediaTrigger?: string) => void;
  defaultState?: string;
  isThumb?: boolean;
  expanded: boolean;
}

export default function HotspotComponentView(props: Props) {
  const { component } = props;
  const { mediaTrigger } = component.settings;

  const iconImageClasses = () => {
    const { settings } = component;
    const iconDark = Colors.needsDarkIcon(settings.color) ? 'icon-dark' : '';
    return `icon ${iconDark} icon-${settings.icon}`;
  };

  const iconTextClasses = () => {
    const { color } = component.settings;
    const foregroundColor = Colors.needsDarkIcon(color) ? 'black' : 'white';
    return `core-text bg-${color} fg-${foregroundColor}`;
  };

  const extraClasses = () => {
    const { settings } = component;
    const fullOpacity = settings.fullOpacity ? 'full-opacity' : undefined;
    const invertedColors = settings.invertedTextColor ? 'inverted-text-color' : undefined;
    const expandHorizontal = `expand-h-pos-${textExpansionDirectionH()}`;
    const expandVertical = `expand-v-pos-${textExpansionDirectionV()}`;
    const textExpandedClass = props.expanded ? 'show-text' : '';

    return _.compact([
      'view-component-hotspot',
      fullOpacity,
      invertedColors,
      expandHorizontal,
      expandVertical,
      textExpandedClass,
    ]).join(' ');
  };

  const textExpansionDirectionH = () => {
    const direction = component.settings.textExpandDirectionH;
    const position = component.position;

    if (direction !== 'auto') {
      return direction;
    }

    return position.left >= 50 ? 'left' : 'right';
  };

  const textExpansionDirectionV = () => {
    const direction = component.settings.textExpandDirectionV;
    const position = component.position;

    if (direction !== 'auto') {
      return direction;
    }

    return position.top >= 50 ? 'up' : 'down';
  };

  const iconContent = () => {
    const { iconText, image, imageSize, color, noIcon } = component.settings;
    if (image) {
      const imageUrl = ImageUtils.checkImageUrl(image);
      return (
        <div
          className={`core-image`}
          style={{
            backgroundImage: ImageUtils.urlString(imageUrl),
            backgroundSize: `${imageSize}`,
            backgroundColor: color || '#fff',
          }}
          data-grippy="move"
        ></div>
      );
    } else if (noIcon) {
      return (
        <div className={iconTextClasses()} data-grippy="move">
          {iconText}
        </div>
      );
    } else {
      return <div className={`core-icon ${iconImageClasses()}`} data-grippy="move"></div>;
    }
  };

  function onClick(e: React.MouseEvent) {
    props.toggle(component._id, mediaTrigger || undefined);
  }

  const { title, text, color, textWidth } = component.settings;
  const onClickProps = !props.isThumb ? { onClick } : {};

  return (
    <ComponentContainer {...props} componentTypeClasses={`${extraClasses()}`} isInteractive={!props.isThumb}>
      <div {...onClickProps}>
        {title && (
          <div className="hotspot-title" data-grippy="move">
            {title}
          </div>
        )}
        {text && (
          <div
            className={`hotspot-text ${props.expanded && 'show'}`}
            data-grippy="move"
            dangerouslySetInnerHTML={{ __html: text }}
            style={{ minWidth: `${textWidth}%` }}
          ></div>
        )}
        <div className="border" data-grippy="move"></div>
        <div className="core" style={{ backgroundColor: Colors.getHexColor(color) }} data-grippy="move">
          {iconContent()}
        </div>
      </div>
    </ComponentContainer>
  );
}
