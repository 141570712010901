import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Channel, ChannelTitle, IndexedChannelInfo, LanguageSingleton } from '../../../../../shared-core/domain';
import { ImageUtils } from '../../../../../shared-core/utils/ImageUtils';
import FollowChannelButton from '../../../../components/buttons/FollowChannelButton/FollowChannelButton';
import { htmlDecode } from '../../../../utils/htmlDecode';
import './ChannelInfo.less';

const TRANSLATION_NAMESPACE = 'channel-info';
interface LessonOverviewChannelProps {
  channel: IndexedChannelInfo | undefined;
  showFollowButton?: boolean;
  channelUrl: (slug: string) => string;
  className?: string;
  lessonId: string;
  forceNewTab?: boolean;
}

export default function ChannelInfo({
  channel,
  showFollowButton,
  channelUrl,
  className,
  lessonId,
  forceNewTab,
}: LessonOverviewChannelProps) {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  if (!channel || !channel.title || !channel.name || !channel.channelPublic) {
    return null;
  }

  const backgroundStyle = { backgroundImage: ImageUtils.urlString(ImageUtils.checkImageUrl(channel.icon)) };

  const localizedAndDecodedChannelTitle = htmlDecode(
    ChannelTitle.localizeTitle(channel.title, LanguageSingleton.get())
  );

  return (
    <div className={classNames('channel-info', className)}>
      {channel.icon && <div className="channel-info-logo" style={backgroundStyle} />}
      <p className="channel-info-link">
        {t('lesson-channel') + ' '}
        <a target={forceNewTab ? '_blank' : '_self'} href={channelUrl(channel.name)} rel="noreferrer">
          {localizedAndDecodedChannelTitle}
        </a>
      </p>

      {showFollowButton && channel?.name && (
        <FollowChannelButton
          channelSlug={channel.name}
          channelTitle={localizedAndDecodedChannelTitle}
          requireFollow={Channel.followRequired(channel)}
          followContext={{
            trigger: 'lesson-page',
            lessonId,
          }}
        />
      )}
    </div>
  );
}
