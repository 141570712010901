import { AnyAction } from '../actions/actions';

export type FollowedChannelsState = string[];

const initialState: FollowedChannelsState = [];

export function followedChannelsReducer(
  state: FollowedChannelsState = initialState,
  action: AnyAction
): FollowedChannelsState {
  switch (action.type) {
    case 'fetchFollowedChannels':
      return action.channels;
    case 'followChannel':
      return [...state, action.channelSlug];
    case 'unfollowChannel':
      return state.filter((c) => c !== action.channelSlug);
    default:
      return state;
  }
}
