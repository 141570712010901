import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { logger } from '@lessonup/client-integration';
import { SearchParams } from '../../../shared-core/domain';
import { Api } from '../../../shared-core/domain/api/LessonupApi';
import { fetchedClusterAction } from '../../redux/actions/actions';
import { StoreState } from '../../redux/store';
import { AppServices } from '../../services/AppServices';
import LessonSearchView from '../search/lessons/LessonSearchView';
import { SearchContextProvider } from '../search/sidebar/SearchContext';

// Redux bindings

function mapStateToProps(state: StoreState) {
  const { results, clusterId, forLessonId } = state.cluster;
  const { userRatings } = state.ratings;
  return { results, userRatings, clusterId, forLessonId };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    setResults: (response: Api.LessonClusterResponse) => {
      dispatch(fetchedClusterAction(response, response.clusterId, response.forLessonId));
    },
  };
}

function clusterDebugger(props: Props) {
  const docs = (props.results && props.results.items.map((i) => i.doc)) || [];
  window['cluster'] = docs;
  let source = docs[0];
  if (props.lessonId) {
    const fromUrl = docs.find((d) => d._id === props.lessonId);
    if (fromUrl) source = fromUrl;
  }
  if (!source) return;

  const list = docs.filter((d) => d._id != source._id);
  console.log('clusterItems', docs);
  const hashes = list.map((i) => i.pinHashes);
  console.log('clusterhashes', hashes);
  const intersections = hashes.map((i) => _.intersection(source.pinHashes, i));
  console.log('intersections', intersections);
}

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type Props = RouteComponentProps &
  ReduxProps & {
    lessonId: string;
    services: AppServices;
  };

class ClusterPage extends React.Component<Props> {
  public state = {};

  public constructor(props: Props) {
    super(props);
    if (!props.results || props.forLessonId !== props.lessonId) {
      console.log(props);
      this.fetchCluster(props.lessonId);
    }
  }

  public componentDidMount() {
    clusterDebugger(this.props);
  }

  public componentWillReceiveProps(newProps: Props) {
    clusterDebugger(newProps);
  }

  private fetchCluster(lessonId: string) {
    if (!window || typeof window === 'undefined') return;
    this.props.services.lesson
      .cluster(lessonId)
      .then((result) => this.props.setResults(result))
      .catch(logger.error);
  }
  async fetchCallback() {}

  public render() {
    return (
      <SearchContextProvider
        service={this.props.services.search}
        fetchCallBack={this.fetchCallback}
        indexType="lessons"
        storeSearchParams={undefined}
        routeParams={SearchParams.fromDictionary({}, 'lessons')}
        urlCreator={() => ''}
      >
        <LessonSearchView
          results={this.props.results}
          userRatings={this.props.userRatings}
          fetching={false}
          lessonUrlCreator={() => ''}
          showTabs={false}
        />
        ;
      </SearchContextProvider>
    );
  }
}

const ConnectedPage = connect(mapStateToProps, mapDispatchToProps)(ClusterPage);

export default ConnectedPage;
