import { trackAction } from '@lessonup/client-integration';
import { assertNever } from '@lessonup/utils';

type SSOSystem = 'google' | 'office365';

const handleOpenWindow = (url: string) => {
  const screenX = typeof window.screenX !== 'undefined' ? window.screenX : window.screenLeft;
  const screenY = typeof window.screenY !== 'undefined' ? window.screenY : window.screenTop;
  const outerWidth = typeof window.outerWidth !== 'undefined' ? window.outerWidth : document.body.clientWidth;
  const outerHeight = typeof window.outerHeight !== 'undefined' ? window.outerHeight : document.body.clientHeight - 22;
  // XXX what is the 22?
  const width = 650;
  const height = 700;
  // Use `outerWidth - width` and `outerHeight - height` for help in
  // positioning the popup centered relative to the current window
  const left = screenX + (outerWidth - width) / 2;
  const top = screenY + (outerHeight - height) / 2;
  const features = `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`;
  window.open(url, 'Login', features);
};

export const handleRegisterSSO = (referralToken?: string) => (system: SSOSystem) => {
  trackAction('sso-start', { provider: system === 'google' ? 'google' : 'office', origin: 'register' });
  const path = mapSystemToUrlPath(system, referralToken);
  handleOpenWindow(path);
};

function mapSystemToUrlPath(system: SSOSystem, referralToken?: string) {
  switch (system) {
    case 'google':
      return `/search/api/auth/google${referralToken ? `?referral_token=${referralToken}` : ''}`;
    case 'office365':
      return `/search/api/auth/start-office365/${referralToken ? `${referralToken}` : ''}`;
    default:
      assertNever(system, 'Unknown SSO button system');
  }
}
