import { LoggerTransport } from '@lessonup/client-integration';
import { AppError, LogDetails } from '@lessonup/utils';
import { compact } from 'lodash';
import { EnvConfig } from '../../../../services/app';

type LogLevel = 'debug' | 'info' | 'warn' | 'error';

export class ConsoleTransport implements LoggerTransport {
  private get debugMode() {
    return (this.envConfig.runsOnLocalhost || this.envConfig.isDev) && window.localStorage.getItem('debug') === 'true';
  }

  public constructor(private readonly envConfig: EnvConfig) {}

  public debug(message: string | Error | AppError, details?: LogDetails): void {
    if (!this.debugMode) return;
    logForLevel('debug', message, details);
  }

  public info(message: string | Error | AppError, details?: LogDetails): void {
    logForLevel('info', message, details);
  }

  public warn(message: string | Error | AppError, details?: LogDetails): void {
    logForLevel('warn', message, details);
  }

  public error(message: string | Error | AppError, details?: LogDetails): void {
    logForLevel('error', message, details);
  }
}

function logForLevel(level: LogLevel, message: string | Error | AppError, details?: LogDetails): void {
  if (AppError.isAppError(message)) {
    const { code, details: errorDetails } = message;
    return console[level](code, { ...errorDetails, ...details }, message);
  } else if (message instanceof Error) {
    return console[level](...compact([details, message]));
  }

  console[level](...compact([message, details]));
}
