import { LessonPin, UserContentPrivacy } from '@lessonup/teaching-core';
import { AppError, Identifiable } from '@lessonup/utils';
import _ from 'lodash';
import { LanguageKey } from '../language';

export interface ContentExplorer extends Identifiable {
  creationDate: Date | string;
}

export namespace ContentExplorer {
  export type Children = Content | ChildFolder;
  export type ChildOrRoot = Children | RootFolder;
  export type ChildType = 'lesson' | 'lessonPlan';
  export type AllTypes = ChildType | 'folder';
  export type Fields = keyof Children | keyof RootFolder;
  export interface Content<T extends ChildType = ChildType> {
    id: string;
    type: T;
    symbolic?: boolean;
  }

  interface LocationBase {
    sharedExplorer?: string;
  }

  export interface LocationRoot extends LocationBase {
    root: true;
    folderId?: undefined;
  }

  export interface LocationFolder extends LocationBase {
    root?: false;
    folderId: string;
  }

  export type Location = LocationRoot | LocationFolder;

  export interface DisplayContent {
    name: string | undefined;
    thumbPin?: LessonPin;
  }

  export interface InheritedMetaData {
    products: string[];
    private: boolean;
  }

  export interface RootFolder {
    type: 'folder';
    children: Children[];
    name?: string;
    description?: string;
    image?: string;
    showForLanguage?: 'all' | LanguageKey;
    banner?: {
      url: string;
      width: number;
      image: string;
    };
    products?: string[];
    private?: boolean;
    privacy?: UserContentPrivacy;
  }

  export interface ChildFolder extends RootFolder {
    id: string;
  }
  export type Folder = ChildFolder | RootFolder;

  export function validLocation(location: Location) {
    if (!location) return false;
    const validRoot = location.root && !location.folderId;
    const validFolder = !location.root && location.folderId;
    return validRoot || validFolder;
  }

  export function isChildFolder(child: ChildOrRoot | undefined): child is ChildFolder {
    return isFolder(child) && child.hasOwnProperty('id');
  }

  export function isFolder(child: ChildOrRoot | undefined): child is Folder {
    return Boolean(child && child.type === 'folder');
  }

  export function folderId(folder: Folder): string | undefined {
    if (isChildFolder(folder)) {
      return folder.id;
    }
    return undefined;
  }
  /**
   * folders on level
   */
  export function allFolders(tree: Folder): ChildFolder[] {
    return _.filter(tree.children, isChildFolder);
  }

  export function isContent(child: ChildOrRoot | undefined): child is Content {
    return !!child && child.type !== 'folder';
  }

  export function content(tree: Folder): Content[] {
    return _.filter(tree.children, isContent);
  }

  export function idsInFolder(folder: Folder, type: ChildType): string[] {
    const childIds = folder.children.filter((ch) => ch.type == type).map((ch) => ch.id);
    return childIds;
  }

  export function fieldListInTree(tree: Folder, field: Fields, type?: AllTypes): string[] {
    const list: string[] = [];
    function recursive(child) {
      if (!child) return;
      if (type) {
        if (child.type === type && child[field]) list.push(child[field]);
      } else {
        if (child[field]) list.push(child[field]);
      }
      if (child.children) {
        for (let i = 0; i < child.children.length; i++) {
          recursive(child.children[i]);
        }
      }
    }
    recursive(tree);
    return list;
  }

  export type Path = number[];

  export function pathForId(tree: Folder, id: string): Path | undefined {
    function recursive(child: Folder | Children): Path | undefined {
      if (!isFolder(child)) return;
      for (let i = 0; i < child.children.length; i++) {
        const ch = child.children[i];
        // we got it
        if (ch.id === id) return [i];
        const path = recursive(ch);
        if (path) {
          // id is found at path in child.children[i],
          // so prefix i to path to get the path in child.
          path.unshift(i);
          return path;
        }
      }
    }
    const path = recursive(tree);
    return path;
  }

  export interface BreadCrumb {
    name: string | undefined;
    id: string | null;
  }

  export type BreadCrumbs = BreadCrumb[];

  interface LocationInformation {
    path: Path;
    breadCrumb: BreadCrumb[];
    products: string[];
    privacy: UserContentPrivacy;
    parent: Folder | undefined;
  }

  export interface FolderInformation extends LocationInformation {
    folder: Folder;
  }

  export interface ContentInformation extends LocationInformation {
    child: ContentExplorer.Children;
  }

  export function folderOrRoot(rootFolder: Folder, location: Location): Folder {
    if (!validLocation(location)) {
      console.warn('location not valid', location);
      return rootFolder;
    }
    const findFolder = folder(rootFolder, location);
    if (!findFolder) {
      console.warn('cant find folder, going with root');
      return rootFolder;
    }
    return findFolder;
  }

  export function folder(tree: Folder, location: Location): undefined | Folder {
    const path = location.root ? [] : pathForId(tree, location.folderId);
    if (!path) return;
    const child = itemForPath(tree, path);
    if (!isFolder(child)) {
      console.warn('unexpected-data', 'cant find folder' + location.folderId);
      return;
    }
    return child;
  }

  // set folderId to null for root
  export function folderAndInfo(tree: Folder, folderId: string | undefined): undefined | FolderInformation {
    const path = folderId === undefined ? [] : pathForId(tree, folderId);
    if (!path) return;
    const folder = itemForPath(tree, path);
    if (!folder || folder.type !== 'folder') {
      return undefined;
    }
    const folders = pathFolders(tree, path);
    const parents = parentFolders(tree, path);
    const privacy = getPrivacy(tree, path);
    const products = _.uniq(_.flatten(_.compact(allFieldInPath(folders, 'products'))));
    const parent = _.last(parents);
    return {
      path,
      parent,
      folder,
      breadCrumb: breadCrumb(parents),
      products,
      privacy,
    };
  }

  // set folderId to null for root
  export function contentLocationInfo(tree: Folder, childId: string): ContentInformation | undefined {
    const path = pathForId(tree, childId);
    if (!path) return;
    const child = itemForPath(tree, path);
    if (!child || !isContent(child)) {
      return undefined;
    }
    const folders = pathFolders(tree, path);
    const parents = parentFolders(tree, path);
    const privacy = getPrivacy(tree, path);
    const products = _.uniq(_.flatten(_.compact(allFieldInPath(folders, 'products'))));
    const parent = _.last(parents);
    return {
      path,
      parent,
      child,
      breadCrumb: breadCrumb(parents),
      products,
      privacy,
    };
  }

  export function mongoSelector(tree: Folder, folderId: string, rootFolderName = 'myLessons', field?: string) {
    const path = pathForId(tree, folderId);
    if (!path) throw new AppError('unexpected-data', 'path should not be undefined');
    const mongoSelector = path.reduce(
      (mem, index) => {
        return mem.concat(index.toString(), 'children');
      },
      [rootFolderName, 'children']
    );
    if (field) mongoSelector[mongoSelector.length - 1] = field;

    return mongoSelector.join('.');
  }

  export function rootParentForIdinExplorer(tree: Folder, activeId: string) {
    let parentId;
    allFolders(tree).forEach((child) => {
      const path = pathForId(child, activeId);
      const parents = path ? parentFolders(child, path) : null;
      if (parents) parentId = isChildFolder(parents[0]) ? parents[0].id : null;
    });
    return parentId;
  }

  function parentFolders(tree: Folder, path: Path) {
    if (!path.length) return [];
    const parentPath = _.clone(path);
    parentPath.pop();
    const result: Folder[] = [tree];
    let useFolder: Folder = tree;
    for (let i = 0; i < path.length - 1; i++) {
      const index = path[i];
      const child = useFolder.children[index];
      if (!isFolder(child)) {
        throw new AppError('unexpected-data', `Path is malformed`, {
          path,
          treeId: (tree as any).id,
          treeName: tree.name,
        });
      }
      result.push(child);
      useFolder = child;
    }
    return result;
  }

  function pathFolders(tree: Folder, path: Path) {
    if (!path.length) return [tree];
    const folders = parentFolders(tree, path);
    const child = itemForPath(tree, path);
    if (child && child.type === 'folder') {
      folders.push(child);
    }
    return folders;
  }

  function breadCrumb(parents: Folder[]): BreadCrumb[] {
    return parents.map((folder, i) => {
      return isChildFolder(folder)
        ? { name: folder.name, id: folder.id }
        : {
            name: folder.name,
            id: null,
          };
    });
  }

  function allFieldInPath<T extends keyof Folder>(pathFolders: Folder[], field: T): Folder[T][] {
    const res = _.compact(
      pathFolders.map((folder) => {
        if (folder && folder[field]) return folder[field];
      })
    );
    return res;
  }

  export function getFieldValueOfFirstParent<T extends keyof Folder>(
    tree: Folder,
    path: Path,
    field: T,
    bottomUp?: boolean
  ): Folder[T] | undefined {
    if (!tree || !path) return;

    const branch = parentFolders(tree, path);
    const parent = bottomUp ? branch.reverse().find((t) => [field]) : branch.find((t) => t[field]);

    return (parent && parent[field]) || undefined;
  }

  export function itemForPath(tree: Folder, path: Path): Children | Folder | undefined {
    if (!path) return undefined;
    if (!path.length) return tree;
    let child: ChildOrRoot = tree;
    //walk path until object is returned
    for (let i = 0; i < path.length; i++) {
      const index = path[i];
      if (!isFolder(child)) {
        throw new AppError('unexpected-data', 'path is malformed' + path + tree);
      }
      child = child.children[index];
    }
    return child;
  }

  export interface FolderCounts {
    lessonCount: number;
    planCount: number;
  }

  export function countFolder(folder): FolderCounts {
    return {
      lessonCount: fieldListInTree(folder, 'id', 'lesson').length,
      planCount: fieldListInTree(folder, 'id', 'lessonPlan').length,
    };
  }

  export function truncateFolderDescription(str: string | undefined) {
    if (!str) return;
    let descriptionString = str;
    if (descriptionString.length > 100) {
      descriptionString = descriptionString.substring(0, 100) + '...';
    }
    return descriptionString;
  }

  export function isPrivate(tree: Folder, path?: Path): boolean {
    return getPrivacy(tree, path || []) === 'private';
  }

  export function getPrivacy(tree: Folder, path: Path): UserContentPrivacy {
    const folders = pathFolders(tree, path);

    return allFieldInPath(folders, 'private').some((pr) => Boolean(pr))
      ? 'private'
      : getFieldValueOfFirstParent(tree, path, 'privacy', true) || 'public';
  }
}
