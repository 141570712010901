import { Lesson } from '@lessonup/teaching-core';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import transformServingImage from '../../../../shared-core/utils/transformServingImage';
import { lessonStore } from '../../../redux/selectors';
import pageTitle from '../../../utils/pageTitle';
import ExportLessonPageView from './ExportLessonPageView';

type Props = {
  lessonId: string;
};

export const ExportLessonPage: React.FC<Props> = ({ lessonId }) => {
  const { lessonDetails, lessonId: storeLessonId } = useSelector(lessonStore());

  if (!lessonDetails || storeLessonId !== lessonId) return null;
  const { pins, lesson } = lessonDetails;

  return (
    <div>
      <LessonPageMetaTags lesson={lesson} />
      <ExportLessonPageView pins={pins || []} lesson={lesson} />
    </div>
  );
};

function LessonPageMetaTags({ lesson }: { lesson: Lesson }) {
  const THUMB_WIDTH = 580;
  const thumbHeight = (THUMB_WIDTH / 16) * 9;
  return (
    <Helmet>
      {lesson.name && <title>{pageTitle(lesson.name)}</title>}
      {lesson.thumbnail && (
        <meta
          property="og:image"
          content={transformServingImage(lesson.thumbnail.url, { width: THUMB_WIDTH, height: thumbHeight })}
        />
      )}
    </Helmet>
  );
}
