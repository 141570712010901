import { Lesson } from '@lessonup/teaching-core';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Channel,
  ChannelTitle,
  IndexedChannelInfo,
  LessonPlan,
  Product,
  ProductWithSource,
} from '../../../../shared-core/domain';
import {
  addExplorerContentType,
  Folder,
  FolderMeta,
  FolderMetaWithFolderIndex,
  folderRenderParams,
  FolderWithNavigationData,
  ItemCounts,
  LessonAndPlanCountTuple,
  Location,
  sortExplorerContentByOrder,
  tryGetFolderFromExplorer,
} from '../../../../shared-core/domain/newExplorers';
import { FacetMetaData } from '../../../../shared-core/domain/search/content/FacetMetaData';
import { channelRoute } from '../../../../shared-core/services/app/searchRoutes';
import Button from '../../../../shared-core/ui/components/Button';
import { FlexBoxDummies } from '../../../../shared-core/ui/utils/FlexBoxDummies';
import useCallbackIfParamsChanged from '../../../../shared-core/ui/utils/hooks/useCallbackIfParamsChanged';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import { ReactRouterLinkButton } from '../../../components/buttons/ReactRouterButton';
import LessonSummaryCard from '../../../components/lesson/lessonSummaryCard/LessonSummaryCard';
import { PlanSummaryCard } from '../../../components/lessonPlan/.';
import LessonPlanFolder from '../../../components/lessonPlan/LessonPlanFolder';
import { fetchedFolderPageAction, navFromPageAction } from '../../../redux/actions/actions';
import { loggedInUser, navFromSearch } from '../../../redux/selectors';
import { htmlDecode } from '../../../utils/htmlDecode';
import { useScrollToTop } from '../../../utils/useScrollToTop';
import ErrorPageView from '../../error';
import GenericErrorPage from '../../search/errors/GenericErrorPage';
import { ChannelSubPageProps } from '../ChannelPageView';
import './ChannelFolderPage.less';

type FolderUrl = (folderId: string | null) => string;
type SelectionId = string | undefined;

const ChannelFolderPage: React.FC<ChannelSubPageProps> = ({ activeTab, details, channelService }) => {
  const dispatch = useDispatch();
  const user = useSelector(loggedInUser());
  const navigationCheck = useSelector(navFromSearch());
  const { channel, folderPage, publicFolder } = details;
  const channelSlug = channel.name;
  const { channelPage } = activeTab;
  // we default to 'root' if undefined
  const selectionId = activeTab.selectionId || Channel.root;

  useScrollToTop(selectionId);

  useCallbackIfParamsChanged<SelectionId>({
    initial: folderPage && folderPage.selectionId,
    params: selectionId,
    callback: async (selectionId) => {
      const channelData = await channelService.channelFolderPage(channelSlug, selectionId || Channel.root);
      dispatch(fetchedFolderPageAction(channelData.folderPage));
    },
  });

  if (navigationCheck !== 'folder') {
    dispatch(navFromPageAction('folder'));
  }

  if (!publicFolder) {
    return null;
  }

  const folderUrl: FolderUrl = (folderId: string | null) => {
    return channelRoute({
      channelSlug,
      channelPage,
      selectionId: folderId || undefined,
    });
  };

  let folder: FolderWithNavigationData | undefined;
  let breadcrumbs: FolderMetaWithFolderIndex[] = [];
  let noFolderTitle = false;

  if (selectionId === Channel.root) {
    folder = tryGetFolderFromExplorer(publicFolder);
    noFolderTitle = true;
  } else if (selectionId) {
    const folderInfo = tryGetFolderFromExplorer(publicFolder, selectionId);
    breadcrumbs = folderInfo?.parents || [];
    noFolderTitle = breadcrumbs.length === 0;
    if (!folderInfo) {
      return (
        <ErrorPageView
          type="page"
          backUrl={channelRoute({
            channelSlug,
            channelPage,
          })}
        />
      );
    }
    folder = folderInfo;
  } else {
    return <GenericErrorPage />;
  }
  // const [folderChildren, otherChildren] = _.partition(folder.children, ContentExplorer.isChildFolder);
  const folderChildren = folder ? orderBy(folder.children, 'order') : [];
  const showSeriesButtons = channel.showSeriesButtonDepth ? breadcrumbs.length < channel.showSeriesButtonDepth : false;

  // we can start rendering the folders right away, the content will come
  const { lessons, lessonPlans, error, products, itemCounts } = folderPage || {};
  const folderId = selectionId === Channel.root ? publicFolder._id : selectionId;

  // const description = folder.description ? { __html: folder.description } : null;
  return (
    <div id="channelSeries">
      {folder && (
        <FolderPageHeader
          folder={folder}
          breadcrumbs={breadcrumbs}
          folderUrl={folderUrl}
          selectionId={selectionId}
          noFolderTitle={noFolderTitle}
          channelTitle={channel.title}
        />
      )}
      <div className="folder-container">
        {error ? (
          <ErrorPageView
            type="channel"
            code={error}
            backUrl={channelRoute({ channelSlug: channel.name, channelPage: 'series' })}
          />
        ) : (
          <>
            <FolderList
              itemCounts={itemCounts}
              channel={channel}
              folderList={folderChildren}
              folderUrl={folderUrl}
              showSeriesButtons={showSeriesButtons}
              parentProducts={folder?.products}
              pageProducts={products}
            />
            {!showSeriesButtons && (
              <FolderContent
                location={{ explorer: publicFolder._id, folder: folderId }}
                channel={channel}
                lessons={lessons || []}
                plans={lessonPlans || []}
                channelSlug={channelSlug}
                lockContent={Product.canAccess(
                  folder?.products,
                  user?.products.map((p) => p.productId)
                )}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

interface FolderPageHeaderProps {
  folder: FolderWithNavigationData;
  breadcrumbs: FolderMetaWithFolderIndex[];
  folderUrl: FolderUrl;
  selectionId: string | undefined;
  noFolderTitle: boolean;
  channelTitle?: ChannelTitle;
}
const FolderPageHeader: React.FC<FolderPageHeaderProps> = ({
  breadcrumbs,
  folder,
  selectionId,
  folderUrl,
  noFolderTitle,
  channelTitle,
}) => {
  const description = folder.description ? { __html: folder.description } : null;
  return (
    <>
      {breadcrumbs ? (
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          rootFolderName={channelTitle && ChannelTitle.localizeTitle(channelTitle)}
          getUrl={folderUrl}
          currentFolderName={folder.name}
        />
      ) : null}
      {folder.banner ? <FolderBanner folder={folder} /> : null}
      {!noFolderTitle && selectionId ? <h2 className="subfolder-title">{htmlDecode(folder.name)}</h2> : null}
      {description ? <div className="description" dangerouslySetInnerHTML={description}></div> : null}
    </>
  );
};

interface FolderListProps {
  folderList: Folder[] & FolderMeta[];
  channel: Channel;
  folderUrl: FolderUrl;
  showSeriesButtons: boolean;
  parentProducts?: string[];
  pageProducts: Product[] | undefined;
  itemCounts?: ItemCounts;
}
export type HandleClickedLocked = (folder: Pick<Folder, 'products'>) => void;

const FolderList: React.FC<FolderListProps> = ({
  folderList,
  channel,
  folderUrl,
  showSeriesButtons,
  parentProducts,
  pageProducts,
  itemCounts,
}) => {
  const user = useSelector(loggedInUser());
  const { modals } = useAppServices();
  const channelSlug = channel.name;

  const handleClickLocked: HandleClickedLocked = ({ products }) => {
    const product = pageProducts?.find((p) => products?.includes(p._id));
    const link =
      product?.url ||
      channelRoute({
        channelSlug,
      });
    modals.open('lockedFolder', {
      link,
      target: product?.url ? 'blank' : 'app',
    });
  };

  const content = folderList.map((folder) => {
    const props = folderRenderParams(folder, folderUrl);
    const childButtons = showSeriesButtons
      ? orderBy(folder.children, 'order').map((child) => (
          <SeriesButton
            folder={child}
            folderUrl={folderUrl}
            key={child._id}
            userProducts={user?.products}
            handleClickLocked={handleClickLocked}
          />
        ))
      : null;

    const count: LessonAndPlanCountTuple = Object(itemCounts).hasOwnProperty(folder._id)
      ? itemCounts![folder._id]
      : [0, 0];

    return (
      <LessonPlanFolder
        key={folder._id}
        folder={{ ...props, counts: count }}
        userProducts={user?.products}
        handleClickLocked={handleClickLocked}
        showSeriesButtons={showSeriesButtons}
        parentProducts={parentProducts}
      >
        {childButtons}
      </LessonPlanFolder>
    );
  });

  return (
    <div
      className={classNames('folder-wrapper', {
        'show-series-buttons': showSeriesButtons,
        'no-series-buttons': !showSeriesButtons,
      })}
    >
      {content}
      <div className="clearfix"></div>
    </div>
  );
};

interface ButtonProp {
  folder: Folder & FolderMeta;
  folderUrl: FolderUrl;
  userProducts?: ProductWithSource[];
  handleClickLocked: HandleClickedLocked;
}

const SeriesButton: React.FC<ButtonProp> = ({ folder, folderUrl, userProducts, handleClickLocked }) => {
  const folderAccess = Product.canAccess(
    folder?.products,
    userProducts?.map((p) => p.productId)
  );
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleLockedButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    handleClickLocked(folder);
  };
  if (!folderAccess) {
    return <Button text={folder.name || folder._id} className="icon icon-lock" onClick={handleLockedButton}></Button>;
  }

  return <ReactRouterLinkButton to={folderUrl(folder._id)} text={folder.name || folder._id} onClick={handleClick} />;
};

interface FolderContentProps {
  location: Location;
  lessons: Lesson[];
  plans: LessonPlan[];
  channelSlug: string;
  lockContent?: boolean;
  channel: Channel;
}

const FolderContent: React.FC<FolderContentProps> = ({ lessons, plans, channelSlug, channel }) => {
  const channelInfo = channel ? IndexedChannelInfo.fromChannel(channel) : undefined;

  const lessonsAndLessonPlans = [
    ...lessons.map(addExplorerContentType('lesson')),
    ...plans.map(addExplorerContentType('lessonPlan')),
  ];

  const sortedLessonsAndLessonPlans = sortExplorerContentByOrder(lessonsAndLessonPlans);

  const LessonsAndLessonPlansComponents = sortedLessonsAndLessonPlans.map((child) => {
    if (child.type === 'lesson') {
      const { content: lesson } = child;
      if (lesson.privacy && Lesson.isPrivateOrProtected(lesson.privacy)) return null;
      const metaData = FacetMetaData.fromLesson({ lesson });
      return (
        <LessonSummaryCard
          key={lesson._id}
          lesson={lesson}
          channel={channelInfo}
          metaData={metaData}
          linkTo={(id) =>
            channelRoute({
              channelSlug,
              channelPage: 'lesson',
              selectionId: id,
            })
          }
        />
      );
    }
    if (child.type === 'lessonPlan') {
      const { content: plan } = child;
      const metaData = FacetMetaData.fromPlan(plan);
      return (
        <PlanSummaryCard
          key={plan._id}
          plan={plan}
          lessons={lessons}
          metaData={metaData}
          channel={channelInfo}
          linkTo={(id) =>
            channelRoute({
              channelSlug,
              channelPage: 'plan',
              selectionId: id,
            })
          }
        />
      );
    }
  });

  const Content = LessonsAndLessonPlansComponents.concat(FlexBoxDummies('base-summary-card-dummy', 10));

  return <div className="folder-content">{Content}</div>;
};

export default ChannelFolderPage;

interface FolderBannerProps {
  folder: Folder;
}

const FolderBanner: React.FC<FolderBannerProps> = ({ folder }) => {
  const { banner } = folder;
  if (!banner) return null;
  const { url, width, image } = banner;

  const bannerStyle = { maxWidth: width };

  return (
    <a className="channel-banner" href={url} target="_blank" rel="noopener noreferrer">
      <img style={bannerStyle} src={image} alt="channel-banner" />
    </a>
  );
};
