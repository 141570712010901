import _ from 'lodash';

export interface Curriculum {
  _id: string;
  name: string;
  creationDate: string;
  settings: {
    orderByYear: boolean;
    countMainTopics: boolean;
    startCollapsed: boolean;
  };
  children: CurriculumChild[];
  description?: string;
  subjects?: string;
}

export interface CurriculumName {
  _id: string;
  name: string;
}

export interface CurriculumChild extends Curriculum {
  nr: number | string;
  year?: number;
  abv?: string;
}

export interface CurriculaGroup {
  name: string;
  children: CurriculumChild[];
}

export const getListByYear = (curricula) => {
  if (!curricula) return;

  const yearDict = {
    '4': 'Groep 1',
    '5': 'Groep 2',
    '6': 'Groep 3',
    '7': 'Groep 4',
    '8': 'Groep 5',
    '9': 'Groep 6',
    '10': 'Groep 7',
    '11': 'Groep 8',
  };
  const newList: CurriculaGroup[] = Object.values(yearDict).map((label) => ({
    name: label,
    children: [],
  }));

  // we dont look further at this, it works for what it has to do, but its ugly. pretend its not there.

  _.forEach(curricula.children, (level1, i1) => {
    _.forEach(level1.children, (level2, i2) => {
      const prettyYear = level2.year ? yearDict[level2.year] : null;
      const yearIndex = newList.findIndex((c) => c.name == prettyYear);
      if (!newList[yearIndex].children) return;
      let domeinIndex = newList[yearIndex].children.findIndex((c) => c.name == level1.name);

      if (domeinIndex == -1) {
        newList[yearIndex].children.push({
          name: level1.name,
          abv: level1.name.substr(0, 3).toUpperCase(),
          nr: level1.nr,
          _id: level1._id,
          creationDate: level1.creationDate,
          settings: level1.settings,
          children: [],
        });
        domeinIndex = newList[yearIndex].children.length - 1;
      }

      if (!newList[yearIndex].children[domeinIndex].children) return;
      newList[yearIndex].children[domeinIndex].children.push(_.clone(level2));
      const itemIndex = newList[yearIndex].children[domeinIndex].children.length - 1;
      newList[yearIndex].children[domeinIndex].children[itemIndex].nr = `${yearIndex + 1}.${
        newList[yearIndex].children[domeinIndex].abv
      }.${itemIndex + 1}`;
    });
  });

  return newList;
};
