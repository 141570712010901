import React from 'react';

import { SVGGeneratorProps } from '@lessonup/teaching-core';
import { BaseMathComponent } from './BaseMathComponent';

export const RectangleComponent: React.FC<SVGGeneratorProps> = ({ position, settings }) => {
  return <BaseMathComponent position={position} settings={settings} pathFunc={rectanglePath} />;
};

function rectanglePath(width_: number, height_: number, margin_: number): string {
  // Use width and height to draw the path while taking the margin/stroke-size into account
  const margin = margin_ / 2; // Only half of the stroke size will fall outside of the path to devide by 2
  const width = width_ - margin;
  const height = height_ - margin;

  // x and y values are for the coordinates that we need in the path
  const xValues = [margin, width];
  const yValues = [margin, height];
  const path = `M${xValues[0]} ${yValues[0]}
     L${xValues[1]} ${yValues[0]}
     L${xValues[1]} ${yValues[1]}
     L${xValues[0]} ${yValues[1]}
     Z`;
  return path;
}
