import React from 'react';
import './Fact.less';

interface Props {
  factTitle: string;
}

export default function Fact(props: Props) {
  return <div className="fact">{props.factTitle}</div>;
}
