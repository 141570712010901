/**
 * Anonymize a string by replacing all letters with asterix', making it impossible to use as personal data, but the type of data still somewhat recognizable for debugging purposes.
 *
 * @param input string to anonymize
 */
export function anonymize(input: string): string;
export function anonymize(input: undefined): undefined;
export function anonymize(input: string | undefined): string | undefined {
  if (typeof input === 'undefined') return input;
  return input.toString().replace(/[A-Za-z0-9]/g, '*');
}
