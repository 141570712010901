import classNames from 'classnames';
import React, { useRef } from 'react';
import { Language, LanguageKey } from '../../../domain';
import useOnClickOutside from '../../utils/useOnClickOutside';
import useToggle from '../../utils/useToggle';
import ChevronSvg from '../svgIcons/ChevronSvg';
import './LanguageSwitch.less';
export type LanguageSwitchProps = {
  className?: string;
  handleLanguageChange: (language: LanguageKey) => void;
  currentLanguage: LanguageKey;
  expandDirection?: 'bottom' | 'top';
  includeAllLanguages?: boolean;
};

const LanguageSwitch: React.FC<LanguageSwitchProps> = ({
  className,
  handleLanguageChange,
  currentLanguage,
  expandDirection,
  includeAllLanguages,
}) => {
  const [expanded, toggleExpanded] = useToggle(false);
  const menuElement = useRef<HTMLDivElement>(null);

  const onChange = (lang: LanguageKey) => {
    handleLanguageChange(lang);
    toggleExpanded();
  };

  useOnClickOutside(menuElement, () => {
    if (expanded) toggleExpanded();
  });

  const renderOptions = () => {
    const keysToRender = includeAllLanguages
      ? Language.validKeys
      : Language.isMain(currentLanguage)
      ? Language.mainKeys
      : [...Language.mainKeys, currentLanguage];

    return (
      <div
        className={classNames('language-switch-options', expandDirection === 'bottom' && 'expand-down')}
        ref={menuElement}
      >
        {(keysToRender as Language.Key[]).map((lang) => (
          <span
            className={classNames('language', lang === currentLanguage ? 'language-selected' : undefined)}
            onClick={() => onChange(lang)}
            key={lang}
          >
            {mapLangKeyToOriginalLang(lang)}
          </span>
        ))}
      </div>
    );
  };

  const renderSelectedOption = () => (
    <div
      className="language-switch-button icon icon-language"
      onClick={(event) => {
        event.stopPropagation();
        toggleExpanded();
      }}
    >
      <span className="selected-language">{mapLangKeyToOriginalLang(currentLanguage)}</span>
      <ChevronSvg className="picker-chevron" />
    </div>
  );

  return (
    <div className={classNames('language-switch', { expanded: expandDirection !== 'bottom' && expanded }, className)}>
      {renderSelectedOption()}
      {expanded ? renderOptions() : null}
    </div>
  );
};

function mapLangKeyToOriginalLang(key: string) {
  const languageMap: Language.FullDict<string> = {
    nl: 'Nederlands',
    en: 'English',
    fr: 'Français',
  };

  return languageMap[key];
}

export default LanguageSwitch;
