import { GetCookieValueByName, getCookieValueByName, logger, SetCookie, setCookie } from '@lessonup/client-integration';
import { addMonths, AppError, differenceInSeconds } from '@lessonup/utils';
import { toNumber } from 'lodash';
import { getOptimizeTestVariantByExperimentId } from '../../../shared-core/utils/getOptimizeTestVariantByExperimentId';

type IncentiveTestType = 'medium' | 'hard';

interface CookieValue {
  count: number;
  lastTaughtDate: Date;
  version: number;
  expirationDate: Date;
}

const REGISTRATION_INCENTIVE_EXPERIMENT_ID = '1jzgO02pQxWHBRrSQH2UkQ';

type ExperimentVariant = 'default' | 'medium' | 'hard';
const COOKIE_NAME = 'teachCount';
const HARD_LIMIT = 0;
const MEDIUM_LIMIT = 7;
export const CURRENT_VERSION = 1;

export class IncentiveCounter {
  public constructor(
    private readonly getCookieValueByName: GetCookieValueByName,
    private readonly setCookie: SetCookie
  ) {}

  public getCount() {
    const cookieValue = this.getValidParsedCookieValue();

    if (!cookieValue) {
      return 0;
    }

    return typeof cookieValue.count === 'string' ? parseInt(cookieValue.count, 10) : cookieValue.count;
  }

  public increment() {
    const currentCookieValue = this.getValidParsedCookieValue();

    if (
      !currentCookieValue ||
      this.secondsTillCookieExpiration(currentCookieValue) === 0 ||
      this.incentiveCounterExperimentVariant() === 'hard'
    ) {
      return this.setInitialCookie(1);
    }
    const newValue = {
      ...currentCookieValue,
      count: currentCookieValue.count + 1,
    };

    return this.setCookie({
      name: COOKIE_NAME,
      value: JSON.stringify(newValue),
      maxAge: this.secondsTillCookieExpiration(newValue),
    });
  }

  public hasReachedLimit(limitType: IncentiveTestType) {
    const count = this.getCount();
    const limit = limitType === 'hard' ? HARD_LIMIT : MEDIUM_LIMIT;
    return count >= limit;
  }

  public incentiveCounterExperimentVariant(): ExperimentVariant {
    const optimizeVariant = getOptimizeTestVariantByExperimentId({
      experimentId: REGISTRATION_INCENTIVE_EXPERIMENT_ID,
    });
    if (optimizeVariant === '1') {
      return 'medium';
    } else if (optimizeVariant === '2') {
      return 'hard';
    }
    return 'default';
  }

  public teachTimesLeft(variant: ExperimentVariant): number | undefined {
    if (variant === 'default') return;
    if (variant === 'hard') return 0;

    const countFromCookie = this.getCount();
    return Math.max(0, MEDIUM_LIMIT - countFromCookie);
  }

  private getValidParsedCookieValue(): CookieValue | undefined {
    const cookieValue = this.getCookieValueByName(COOKIE_NAME);

    if (!cookieValue) return undefined;

    try {
      return this.validateStringifiedCookie(cookieValue);
    } catch (error) {
      logger.error(error);
      return undefined;
    }
  }

  private validateStringifiedCookie(value: string): CookieValue {
    const parsed = JSON.parse(value);
    const version = Number(parsed.version);
    if (version !== CURRENT_VERSION) {
      throw new AppError('invalid-params', 'Version is not valid anymore');
    }

    return {
      count: toNumber(parsed.count),
      expirationDate: new Date(parsed.expirationDate),
      version: toNumber(parsed.version),
      lastTaughtDate: new Date(parsed.lastTaughtDate),
    };
  }

  private setInitialCookie(initialCount = 0) {
    const cookieValue = this.defaultValue(initialCount);
    const stringifiedCookieValue = JSON.stringify(cookieValue);

    this.setCookie({
      name: COOKIE_NAME,
      value: stringifiedCookieValue,
      maxAge: this.secondsTillCookieExpiration(cookieValue),
    });
  }

  private defaultValue(count = 0): CookieValue {
    const date = new Date();

    return {
      count,
      lastTaughtDate: date,
      version: CURRENT_VERSION,
      expirationDate: addMonths(date, 1),
    };
  }

  private secondsTillCookieExpiration(value: CookieValue): number {
    const expirationDate = value.expirationDate;

    const secondsUntilExpiration = differenceInSeconds(expirationDate, new Date());
    return Math.max(0, secondsUntilExpiration);
  }
}

export const incentiveCounter = new IncentiveCounter(getCookieValueByName, setCookie);
