import React from 'react';
import { ChevronIcon } from '../../../svgIcons';
import { DisabledBreadcrumb } from '../Breadcrumb/Breadcrumb';

export const HiddenBreadcrumbs: React.FC = () => {
  return (
    <>
      <DisabledBreadcrumb crumbName={'...'} />
      <ChevronIcon rotation={270} className="margin-left-4 margin-right-4 explorer-breadcrumbs__chevron-icon" />
    </>
  );
};
