import { AssignmentEntry, UserReference, UserReferenceAndPinEntry } from '@lessonup/teaching-core';
import { compactMap } from '@lessonup/utils';
import { MongoUser } from './MongoUser';

export class UserReferenceBuilder {
  public static fromUser(user: Partial<MongoUser>): UserReference {
    const displayName = MongoUser.displayName(user as MongoUser); // Little hacky, but this only needs the profile
    const familyName = MongoUser.familyName(user as MongoUser);

    return {
      _id: user._id!,
      displayName: displayName || 'Onbekend', // TODO How to handle lack of name
      familyName: familyName || undefined,
    };
  }

  static fromUsers(users: Partial<MongoUser>[]): UserReference[] {
    return users.map((user) => UserReferenceBuilder.fromUser(user));
  }

  static fromEntry(entry: AssignmentEntry): UserReference {
    return { _id: entry.userId, displayName: entry.displayName, familyName: entry.familyName };
  }

  static fromEntries(entries: AssignmentEntry[]): UserReference[] {
    return entries.map((entry) => UserReferenceBuilder.fromEntry(entry));
  }
}

export namespace UserReferenceAndPinEntryBuilder {
  export function fromEntries(entries: AssignmentEntry[], pinId: string): UserReferenceAndPinEntry[] {
    return compactMap(entries, (entry) => fromEntry(entry, pinId));
  }

  export function fromEntry(entry: AssignmentEntry, pinId: string): UserReferenceAndPinEntry {
    const pinEntry = AssignmentEntry.entryForPin(entry, pinId);

    return {
      user: UserReferenceBuilder.fromEntry(entry),
      pinEntry,
    };
  }
}
