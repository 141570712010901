import * as React from 'react';

export type FolderSvgColor = 'yellow' | 'red' | 'blue' | 'green';
interface Props extends React.SVGProps<SVGSVGElement> {
  color: FolderSvgColor;
  showOpen: boolean;
}
/**
 * Svg image of a folder
 * For color do not forget to include FolderSvgGradients in the DOM
 */
const FolderSvg: React.FC<Props> = ({ color: theme, showOpen, ...props }) => {
  const themeData = colorVariants[theme];
  const paths = showOpen ? openFolderPaths : closedFolderPaths;
  return (
    <svg className="folder-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 400" {...props}>
      <path d={paths[0]} fill={themeData.backFill} />
      <path d={paths[1]} fill={`url(#${themeData.id})`} />
    </svg>
  );
};

export default FolderSvg;

const closedFolderPaths = [
  'M509 64v247c0 14-11 26-25 26H27c-14 0-25-11-25-25V30c0-14 12-25 26-25h96C137 4 166 38 166 38h318c14 0 25 11 25 26z',
  'M483 374H26c-14 0-25-11-25-25V76c0-14 12-25 26-25h457c14 0 26 12 26 26v272c0 14-11 26-25 26z',
];

const openFolderPaths = [
  'M480 75v247c0 14-10 26-22 26h-400c-12 0-22-11-22-25V41c0-14 10-25 22-25h84c12 0 37 34 37 34h278c12 0 22 11 22 26z',
  'M446 388H69c-14 0-25-10-25-23L3 115c0-13 12-23 26-23h457c14 0 26 11 26 23l-39 250c0 13-11 23-25 23z',
];

/** Gradient definitions for the Folders */
export const FolderSvgGradients: React.FC<{}> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0">
    {Object.values(colorVariants).map((theme) => (
      <radialGradient
        key={theme.id}
        id={theme.id}
        cx={-147.662}
        cy={-11.417}
        r={726.731}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor={theme.gradient[0]} />
        <stop offset={0.5} stopColor={theme.gradient[1]} />
        <stop offset={0.75} stopColor={theme.gradient[2]} />
        <stop offset={1} stopColor={theme.gradient[3]} />
      </radialGradient>
    ))}
  </svg>
);

type ColorVariant = {
  id: string;
  backFill: string;
  gradient: [string, string, string, string];
};

const colorVariants: Record<FolderSvgColor, ColorVariant> = {
  yellow: {
    id: 'folder-color-yellow',
    backFill: '#dfbf55',
    gradient: ['#f1d776', '#efd574', '#eacf6c', '#dfc35c'],
  },
  red: {
    id: 'folder-color-red',
    backFill: '#CF2A1E',
    gradient: ['#DF574D', '#DF554B', '#DD4D43', '#D73025'],
  },
  blue: {
    id: 'folder-color-blue',
    backFill: '#316BB6',
    gradient: ['#5F8DCB', '#5D8BCA', '#5586C7', '#3772BD'],
  },
  green: {
    id: 'folder-color-green',
    backFill: '#289e33',
    gradient: ['#289e33', '#289e33', '#289e33', '#289e33'],
  },
};
