import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import ProPill from '../pills/ProPill';
import TooltipView, { TooltipColor, TooltipDirection } from '../tooltip/TooltipView';
import './Button.less';

type Theme =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'transparent'
  | 'light'
  | 'grey'
  | 'green'
  | 'blue'
  | 'orange'
  | 'purple'
  | 'modern';
type Height = 'small' | 'medium' | 'large';
export type Version = 'classic' | 'modern';

export type BaseButtonTheme = Theme;
export type BaseButtonHeights = Height;

export interface BaseButtonProps extends ButtonProps {
  as: 'button' | 'a';
}

export interface ButtonProps {
  theme?: Theme;
  text?: string;
  height?: Height;
  className?: string;
  disabled?: boolean;
  block?: boolean;
  style?: CSSProperties;
  type?: 'button' | 'submit' | 'reset';
  hasSecondary?: boolean;
  isSecondary?: boolean;
  'data-role'?: string;
  rightSideText?: string;
  tooltip?: string;
  tooltipColor?: TooltipColor;
  tooltipDirection?: TooltipDirection;
  version?: Version;
  children?: React.ReactNode;
}
const BaseButton: React.FC<BaseButtonProps> = (props) => {
  const {
    as: Component,
    theme,
    children,
    text,
    className,
    style,
    block,
    height,
    type = 'button',
    hasSecondary,
    isSecondary,
    rightSideText,
    tooltip,
    tooltipColor,
    tooltipDirection,
    version,
    ...buttonProps
  } = props;

  return (
    <Component
      type={type}
      className={buttonClassNames(props)}
      style={style}
      aria-disabled={buttonProps.disabled}
      {...buttonProps}
    >
      {text && <span>{text}</span>}
      {rightSideText && <div className="right">{rightSideText}</div>}
      {tooltip && (
        <TooltipView color={tooltipColor} direction={tooltipDirection}>
          {tooltip}
        </TooltipView>
      )}
      {children}
    </Component>
  );
};

export type ButtonAsButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps;
export const Button: React.FC<ButtonAsButtonProps> = (props) => <BaseButton as="button" {...props} />;

export type ButtonAsAnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & ButtonProps;
export const AnchorButton: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement> & ButtonProps> = (props) => (
  <BaseButton as="a" {...props} />
);

export function buttonClassNames({
  theme,
  className,
  block,
  height,
  hasSecondary,
  isSecondary,
  rightSideText,
  version,
  tooltip,
}: ButtonProps): string {
  return classNames(
    'action-button',
    `action-button--${height || 'large'}`,
    { 'action-button--block': block, 'has-secondary': hasSecondary, 'is-secondary': isSecondary },
    `action-button-${theme || 'primary'}`,
    `version-${version || 'classic'}`,
    { 'has-text-right': !!rightSideText },
    { tipped: !!tooltip },
    className
  );
}

interface ProPillParams {
  showProPill: boolean;
}
export const ButtonWithProPill: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps & ProPillParams
> = (props) => (
  <BaseButton as="button" {...props}>
    {props.showProPill ? <ProPill /> : null}
  </BaseButton>
);
