import { History } from 'history';
import React from 'react';
import { useLocation } from 'react-router';
import { withQueryString } from '../../../shared-core/services/app/searchRoutes';
import { useAppServices } from '../appServices/AppServicesContext';
import LoginModal from './LoginModal';

const LOGIN_REQUIRED_KEY = 'login_required';

interface Props {
  history: History;
}

export const LoginWall = ({ history }: Props) => {
  const { user } = useAppServices();
  const location = useLocation();

  if (!show()) {
    return null;
  }

  function onClose() {
    removeRequiresLoginParam();
  }

  function locationRequiresLogin() {
    const params = new URLSearchParams(location.search);
    return params.get(LOGIN_REQUIRED_KEY) === 'true';
  }

  function removeRequiresLoginParam() {
    const params = new URLSearchParams(location.search);
    params.delete(LOGIN_REQUIRED_KEY);
    history.replace(withQueryString(location.pathname, params.toString()));
  }

  function show() {
    return !user.currentUser() && locationRequiresLogin();
  }

  return <LoginModal closeModal={onClose} userService={user} initialPage="login" />;
};
