import { Colors } from '@lessonup/teaching-core';
import { capitalize } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components, DropdownIndicatorProps, OptionProps, StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ValueFacetKey } from '../../../../domain/search/Facets';
import { SelectProps } from '../../Select';
import VisuallyHidden from '../../VisuallyHidden';
import './FacetSelect.less';

export type Option = { label: string; value: string; count: number | undefined };
type MultiSelect = false;

const TRANSLATION_NAMESPACE = 'facets';
const BLUE_DARK = Colors.getHexColor('blue-dark');
const GREY_BG = Colors.getHexColor('grey-bg');

const selectStyles: Partial<StylesConfig<Option, MultiSelect>> = {
  placeholder: (provided) => {
    return { ...provided, color: Colors.getHexColor('brown-dark'), fontSize: '15px' };
  },

  control: (provided, state) => {
    return {
      ...provided,
      border: 'none',
      borderRadius: '26px',
      cursor: state.hasValue ? 'normal' : 'pointer',
      paddingLeft: '10px',
      height: '10px', // Dummy value: IE11 requires a set height, however the native min. height will actually fix the height.
      fontWeight: state.hasValue ? 700 : 'normal',
      boxShadow: state.isFocused ? `0 0 1px 0 ${BLUE_DARK}` : 'none',
      borderColor: state.isFocused ? BLUE_DARK : 'transparent',
      '&:hover': {
        borderColor: state.isFocused ? BLUE_DARK : 'transparent',
      },
    };
  },
  clearIndicator: (provided) => {
    return {
      ...provided,
      marginRight: '7px',
      height: '26px',
      width: '26px',
      padding: '10%',
      color: Colors.getHexColor('brown-dark'),
      backgroundColor: GREY_BG,
      borderRadius: '100%',
      cursor: 'pointer',
    };
  },
  container: (provided) => {
    return {
      ...provided,
      fontFamily: 'Montserrat',
      minWidth: '260px',
      marginBottom: '10px',
    };
  },
  indicatorSeparator: () => ({ display: 'none' }),
  singleValue: (provided) => {
    return {
      ...provided,
      fontWeight: 700,
      color: Colors.getHexColor('brown-dark'),
    };
  },
};

interface FacetSelectProps<T> extends SelectProps<T> {
  facetKey: ValueFacetKey;
  options: T[];
  isCreatable?: boolean;
  isDisabled?: boolean;
}

const FacetSelect: React.FC<FacetSelectProps<Option>> = ({
  facetKey,
  options,
  onChange,
  isCreatable,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  const selectProps: SelectProps<Option> = {
    id: `${facetKey}-facet-picker-select-box`,
    instanceId: `${facetKey}-facet-picker-select-box`,
    name: facetKey,
    inputId: facetKey,
    styles: selectStyles,
    className: 'facet-select',
    options: props.value ? [] : options,
    placeholder: placeholder || t('select', { facet: t(`${facetKey}`) }),
    onChange,
    isMulti: false,
    components: { Option: SelectOption, DropdownIndicator },
    isClearable: true,
    isSearchable: !props.value,
    noOptionsMessage: () => (props.value ? null : t('no-options')),
    classNamePrefix: 'react-select',
    ...props,
  };

  function formatCreateLabel(input: string): string {
    return t('searchUnlistedOption', { input });
  }

  return (
    <>
      <VisuallyHidden>
        <label htmlFor={facetKey}>{capitalize(t(`${facetKey}`))}</label>
      </VisuallyHidden>
      {isCreatable ? (
        <CreatableSelect {...selectProps} formatCreateLabel={formatCreateLabel} />
      ) : (
        <Select {...selectProps} />
      )}
    </>
  );
};

function SelectOptionCount(props) {
  if (!props.children) {
    return null;
  }

  return <div className="select-option-container__count">({props.children})</div>;
}

function SelectOption(props: OptionProps<Option, MultiSelect>) {
  return (
    <components.Option {...props}>
      <div className="select-option-container">
        <div className="select-option-container__label">{props.children}</div>
        <SelectOptionCount>{props.data.count}</SelectOptionCount>
      </div>
    </components.Option>
  );
}

function DropdownIndicator(props: DropdownIndicatorProps<Option, MultiSelect>) {
  if (props.hasValue) {
    return null;
  }

  return (
    <components.IndicatorsContainer {...props}>
      <div className="select-option-chevron">
        <components.DownChevron />
      </div>
    </components.IndicatorsContainer>
  );
}

export default FacetSelect;
