import { Lesson } from '@lessonup/teaching-core';
import { LessonPlan } from '../lessonPlan';

/**
 * Hoe we verschillende lessen van verschillende landen groeperen.
 * In de toekomst moet elke taal zijn eigen indexSettings hebben (en analysers per taal). Voor nu hanteren we NL en 'alle andere'.
 */
export type IndexLocale = 'nl' | 'en';

export namespace IndexLocale {
  export const all: IndexLocale[] = ['nl', 'en'];

  export function localeForCountry(country: string[] | undefined): IndexLocale {
    if (!country) {
      return 'nl';
    }
    if (country.includes('nl')) {
      return 'nl';
    }
    return 'en';
  }

  export function localeForLesson(lesson: Lesson): IndexLocale {
    return localeForCountry(lesson.country);
  }

  export function localeForLessonPlan(plan: LessonPlan): IndexLocale {
    return localeForCountry(plan.country);
  }
}
