import { Locales } from '../locales';
export type IndexType = IndexType.Type;

export interface ElasticIndexInfo {
  name: string;
  type: IndexType;
  locale: Locales.Key;
  timeStamp: number;
  docsCount: number;
  health: string;
  aliases: string[];
}

export namespace IndexType {
  export const types = ['lessons', 'pins', 'plans', 'organizations', 'school-suggestions'] as const;
  export type Type = (typeof types)[number];
  export function isType(string: string | undefined): string is Type {
    return !!string && types.includes(string as Type);
  }
}
