import React from 'react';
import { useBEM } from '../../../../utils/hooks';
import FolderSvg from '../../../svgIcons/FolderSvg';
import './ExplorerPrivateFolderIcon.less';

interface Props {
  className: string;
  isPublish?: boolean;
  showOpen?: boolean;
}
export const ExplorerPrivateFolderIcon: React.FC<Props> = ({ className = '', isPublish, showOpen }) => {
  const bemClasses = useBEM('ExplorerPrivateFolderIcon');
  return (
    <div className={bemClasses({ className: `${className} visual` })}>
      <FolderSvg color="blue" showOpen={!!showOpen} />
      <div
        className={bemClasses({
          element: 'visual-icon',
          className: 'visual-icon icon-lock',
          modifier: showOpen && 'is-open',
        })}
      ></div>
    </div>
  );
};
