import { Colors } from '@lessonup/teaching-core';
import _ from 'lodash';
import { StylesConfig } from 'react-select';

type isMulti = false;
const BLUE_DARK = Colors.getHexColor('blue-dark');
const BROWN_DARK = Colors.getHexColor('brown-dark');
const RED = Colors.getHexColor('pink');
export type LessonupSelectTheme = 'lessonup-round' | 'lessonup-default';

interface SelectStyleProps<T = LessonupSelectTheme, U extends any = any> {
  key: T;
  style: Partial<StylesConfig<U, isMulti>>;
}

export interface SelectThemeParams {
  hasError?: boolean;
  noMinWidth?: boolean;
}
export const getDropdownTheme = <T extends any = any>(
  theme: LessonupSelectTheme,
  params?: SelectThemeParams
): Partial<StylesConfig<T, isMulti>> => {
  const style = _.find(lessonupStyles(params), ['key', theme]);
  return style?.style || {};
};

const lessonupStyles = (params?: SelectThemeParams): SelectStyleProps[] => [
  {
    key: 'lessonup-round',
    style: {
      control: (provided, state) => {
        return {
          ...provided,
          border: 'none',
          borderRadius: '26px',
          cursor: state.hasValue ? 'normal' : 'pointer',
          paddingLeft: '10px',
          height: '10px',
          fontWeight: state.hasValue ? 700 : 'normal',
          boxShadow: state.isFocused ? `0 0 1px 0 ${BLUE_DARK}` : 'none',
          borderColor: state.isFocused ? BLUE_DARK : 'transparent',
          '&:hover': {
            borderColor: state.isFocused ? BLUE_DARK : 'transparent',
          },
        };
      },
      container: (provided) => {
        return {
          ...provided,
          fontFamily: 'Montserrat',
          minWidth: params?.noMinWidth ? 'auto' : '260px',
        };
      },
      indicatorSeparator: () => ({ display: 'none' }),
      singleValue: (provided) => {
        return {
          ...provided,
          fontWeight: 700,
          color: BROWN_DARK,
        };
      },
    },
  },
  {
    key: 'lessonup-default',
    style: {
      control: (provided, state) => {
        return {
          ...provided,
          backgroundColor: '#fff',
          borderColor: state.isFocused
            ? 'transparent'
            : params?.hasError
            ? RED
            : `${Colors.getHexColor('default-text')}24`,
          boxShadow: state.isFocused ? '0 0 0 2px #00a5c2;' : 'none',
          borderRadius: '4px',
          borderStyle: 'solid',
          borderWidth: '1px',
          cursor: state.hasValue ? 'normal' : 'pointer',
          height: '10px',
          transition: 'all 300ms;',
          transitionTimingFunction: 'ease-in-out',

          '&:hover': {
            borderWidth: '1px',
            borderColor: state.isFocused ? 'transparent' : Colors.getHexColor('default-text'),
          },

          ':placeholder': {
            color: 'pink',
          },
        };
      },
      placeholder: (provided, state) => {
        return {
          ...provided,
          color: state.isDisabled ? Colors.getHexColor('grey-light2') : Colors.getHexColor('grey-light3'),
        };
      },
      option: (provided, state) => {
        return {
          ...provided,
          cursor: 'pointer',
          backgroundColor: state.isSelected ? Colors.getHexColor('cyan') : '#fff',
          color: state.isSelected ? '#fff' : Colors.getHexColor('default-text'),

          '&:hover': {
            backgroundColor: state.isSelected ? Colors.getHexColor('cyan') : Colors.getHexColor('wild-sand'),
          },
        };
      },
      container: (provided) => {
        return {
          ...provided,
          fontFamily: 'Montserrat',
          minWidth: params?.noMinWidth ? 'auto' : '260px',
        };
      },
    },
  },
];
