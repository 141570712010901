import { Avatar } from '@lessonup/teacher-modern';
import {
  AssetLicenseStatusExpired,
  AssetLicenseStatusPro,
  AssetLicenseStatusSchool,
  Button,
  ButtonAnchor,
  Link,
  SpaceBetween,
  spacing,
  TopBar,
  TopBarItemList,
  TopBarRight,
  TopBarVisibility,
} from '@lessonup/ui-components';
import { map } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';
import { Language, LanguageSingleton, Licenses, MongoUser } from '../../../shared-core/domain';
import { EnvConfig } from '../../../shared-core/services';
import { registerRoute } from '../../../shared-core/services/app/searchRoutes';
import { productSiteRoot } from '../../../shared-core/services/app/siteRoutes';
import { teacherAccountUrl, teacherMyLessonsUrl } from '../../../shared-core/services/app/teacherRoutes';
import { currentError } from '../../redux/selectors';
import { StoreState } from '../../redux/store';
import { AppUserService } from '../../services/AppServices';
import { SearchAppModalManagerService } from '../../services/SearchAppModalService';
import { ClientOnlyModernWrapper } from '../../utils/bridge/ClientOnlyModernWrapper';
import { useAppServices } from '../appServices/AppServicesContext';
import LoginNavButton from '../login/LoginNavButton/LoginNavButton';
import RegistrationNavButton from '../login/RegistrationNavButton/RegistrationNavButton';
import Navigation from '../Navigation';
import './navBar.less';

function mapStateToProps(state: StoreState) {
  return { user: state.user.user };
}

type ReduxProps = ReturnType<typeof mapStateToProps>;
export type NavBarTheme = 'secondary' | 'default';

type Props = ReduxProps &
  RouteComponentProps & {
    userService: AppUserService;
    modalService: SearchAppModalManagerService;
    config: EnvConfig;
    navBarThemeOptions?: NavBarThemeOptions;
    navBarOptions?: { hideContent: boolean };
    toggleButtonClassName?: string;
  };

export interface NavBarThemeOptions {
  theme: NavBarTheme;
  secondaryTheme?: NavBarTheme;
  themeScrollPosition?: number;
}

const NavBar: React.FC<Props> = ({ user, navBarOptions, navBarThemeOptions, history, userService }) => {
  const { t } = useTranslation('navbar');
  const services = useAppServices();
  const userHasProducts = userService.userHasProducts();
  const error = useSelector(currentError());

  const navBarTheme = navBarThemeOptions?.theme ?? 'secondary';

  const openRegistrationFlow = (): void => {
    history.push(registerRoute());
  };

  const logoUrl = user && !error ? teacherMyLessonsUrl() : productSiteRoot();
  const adminOrganizationsForUser = map(MongoUser.adminOrganizationList(user), 'id');
  const avatarOrLogin = user ? (
    <>
      <TopBarVisibility visibility="desktop-only">
        <LicenseUpsellButton
          hideLicenseIndicator={userHasProducts}
          licenseExpires={user?.licenseExpires}
          licenseStatus={user?.licenseStatus}
        />
      </TopBarVisibility>
      <TopBarVisibility visibility="always-topbar">
        <ClientOnlyModernWrapper
          SSRFallback={
            <Avatar
              isLoggedIn={true}
              displayName={user.name}
              adminOrganizationIds={adminOrganizationsForUser}
              licenseIndicator={
                <LicenseIndicator licenseStatus={user.licenseStatus} hideLicenseIndicator={userHasProducts} />
              }
            />
          }
        >
          <Avatar
            isLoggedIn={true}
            displayName={user.name}
            adminOrganizationIds={adminOrganizationsForUser}
            onClickSignout={() => services.user.logout()}
            showLinksAsExternal={true}
            licenseIndicator={
              <LicenseIndicator licenseStatus={user.licenseStatus} hideLicenseIndicator={userHasProducts} />
            }
          />
        </ClientOnlyModernWrapper>
      </TopBarVisibility>
    </>
  ) : (
    <TopBarVisibility visibility="default">
      <SpaceBetween direction="x" spacing={spacing.size16}>
        {Language.isMain(LanguageSingleton.get()) ? (
          <LoginNavButton history={history} />
        ) : (
          <ButtonAnchor
            buttonType="secondary"
            href={services.config.appUrl(
              'search',
              `/${Language.fallbackForLanguage(LanguageSingleton.get())}/login/select`
            )}
          >
            {t('login')}
          </ButtonAnchor>
        )}
        {!navBarOptions?.hideContent && <RegistrationNavButton openRegistrationFlow={openRegistrationFlow} />}
      </SpaceBetween>
    </TopBarVisibility>
  );

  return (
    <TopBar theme={navBarTheme} logoUrl={logoUrl} logoUrlExternal={true} className="nav-bar">
      <TopBarVisibility visibility="default">
        {!navBarOptions?.hideContent && (
          <TopBarItemList>
            <Navigation />
          </TopBarItemList>
        )}
      </TopBarVisibility>
      <TopBarRight>
        <WimpersonateButton />
        {avatarOrLogin}
      </TopBarRight>
    </TopBar>
  );
};

const ConnectedNavBar = connect(mapStateToProps)(NavBar);
export default withRouter(ConnectedNavBar);

const LicenseIndicator: React.FC<{ licenseStatus?: Licenses.Status; hideLicenseIndicator?: boolean }> = ({
  hideLicenseIndicator,
  licenseStatus,
}) => {
  switch (true) {
    case licenseStatus === 'expired' && !hideLicenseIndicator:
      return <AssetLicenseStatusExpired />;
    case licenseStatus === 'pro':
      return <AssetLicenseStatusPro />;
    case licenseStatus === 'school':
      return <AssetLicenseStatusSchool />;
    default:
      return null;
  }
};

export const LicenseUpsellButton = ({
  licenseStatus,
  licenseExpires,
  hideLicenseIndicator,
}: {
  licenseStatus?: Licenses.Status;
  licenseExpires?: Licenses.ExpiryDate;
  hideLicenseIndicator: boolean;
}) => {
  const { t } = useTranslation('navbar');

  const daysFromNow = (expirationDate: Date) => {
    return daysBetween(new Date(), new Date(expirationDate));
  };

  const daysBetween = (from: Date, to: Date): number => {
    return Math.round((to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24));
  };

  if (!licenseStatus || !licenseExpires) return null;
  const displayLicenseUpsellButton: Licenses.Status[] = [Licenses.licenseEnum.free, Licenses.licenseEnum.expiring];
  if (hideLicenseIndicator || !displayLicenseUpsellButton.includes(licenseStatus)) return null;

  // In some edge cases its possible for users to have an expiration date in the past
  // We want to show this users the upsell message for Free users.
  const daysRemaining = daysFromNow(licenseExpires) || 0;
  const translationKey =
    daysRemaining >= 0 ? `licenseUpsell_${licenseStatus}` : `licenseUpsell_${Licenses.licenseEnum.free}`;
  const upsellMessage = t(translationKey, { count: daysRemaining });

  return (
    <TopBarVisibility visibility="always-topbar">
      <Link href={teacherAccountUrl()} passHref external>
        <Button buttonType="secondary">{upsellMessage}</Button>
      </Link>
    </TopBarVisibility>
  );
};

export function useIsImpersonating(): boolean {
  const [value] = useLocalStorage<string | boolean | null>('impersonator.impersonating', null);
  // The library parses the value as json which causes it to be returned as a boolean instead of a string, so we check both just to be sure
  return value === 'true' || value === true;
}

const WimpersonateButton = () => {
  const isImpersonating = useIsImpersonating();
  const { t } = useTranslation('navbar');
  if (!isImpersonating) return null;

  return (
    <Link href={teacherAccountUrl()} passHref external>
      <Button buttonType="negative" size="small">
        {t('stopWimpersonateButton')}
      </Button>
    </Link>
  );
};
