import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FacetKey,
  FacetSearchResponse,
  IndexedChannelInfo,
  PartialIndexedLessonPlan,
} from '../../../../shared-core/domain';
import { MAX_PAGINATION_SIZE } from '../../../../shared-core/domain/search/constants/paginationSize';
import ShowMoreButton from '../../../../shared-core/ui/components/buttons/ShowMoreButton';
import { PlanSummaryCards } from '../../../components/lessonPlan/.';
import SidebarLayout from '../../../components/search/sidebarLayout/SidebarLayout';
import SearchResultsEmptyState from '../searchResultsView/SearchResultsEmptyState/SearchResultsEmptyState';
import SearchResultsSubtitle from '../searchResultsView/SearchResultsSubtitle/SearchResultsSubtitle';
import SearchResultsTitle from '../searchResultsView/SearchResultsTitle/SearchResultsTitle';
import { useSearchContext } from '../sidebar/SearchContext';
import SearchSidebar from '../sidebar/SearchSidebarContainer';

interface Props {
  results: FacetSearchResponse<PartialIndexedLessonPlan> | undefined;
  fetching: boolean;
  channelInfo?: IndexedChannelInfo;
  showTabs?: boolean;
}

const PlanSearchPageView: React.FunctionComponent<Props> = (props) => {
  const { paginate, setSearchText, resetFacet, selectedFacets } = useSearchContext();
  const { results, fetching, showTabs } = props;
  const facets = results ? results.facets : [];
  const items = results ? results.items : [];
  const request = results ? results.request : undefined;
  const totalCount = results ? results.total : 0;
  const showLoadMoreButton = totalCount > items.length && items.length < MAX_PAGINATION_SIZE;
  const FacetList = (
    <SearchSidebar facetResults={facets} searchTextValue={request && request.text} searchType="plan"></SearchSidebar>
  );
  const { t } = useTranslation('planSummaryCards');

  function handleFacetDeselect(key: FacetKey | 'text') {
    return key === 'text' ? setSearchText('') : resetFacet(key);
  }

  const emptyState = (
    <SearchResultsEmptyState
      searchParams={request || { index: 'plans', text: undefined, facets: [] }}
      handleFacetDeselect={handleFacetDeselect}
    />
  );

  const showEmptyState = !fetching && !items?.length;

  const content = !showEmptyState && (
    <React.Fragment>
      {request && <PlanSummaryCards plans={items} oneLiner={false} searchParams={request} />}
      <ShowMoreButton show={showLoadMoreButton} onClick={paginate} fetching={fetching} />
    </React.Fragment>
  );

  return (
    <div className="searchpage">
      <SidebarLayout sidebar={FacetList} showTabs={showTabs}>
        <main className={classNames('searchpage__searchresults', { 'hide-tabs': !showTabs })}>
          <div className="searchpage-result-header">
            <SearchResultsTitle selectedFacets={selectedFacets} count={totalCount} type={'plan'} />
            <SearchResultsSubtitle selectedFacets={selectedFacets} channelInfo={props.channelInfo} type={'plan'} />
            {showEmptyState && emptyState}
          </div>
          {content}
        </main>
      </SidebarLayout>
    </div>
  );
};

export default PlanSearchPageView;
