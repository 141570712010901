import { ChannelDetails } from '../../../shared-core/domain';
import { AnyAction } from '../actions/actions';

export interface ChannelState {
  channelDetails: ChannelDetails | undefined;
  slug: string | undefined;
  isLoading: boolean;
  error: false | 404 | 500 | 403; // 403 is now only user for school channels
  navFrom?: string;
  showPlansTab?: boolean;
}

const initialState: ChannelState = {
  channelDetails: undefined,
  slug: undefined,
  error: false,
  isLoading: false,
  navFrom: undefined,
};

export const channelReducer = (state: ChannelState = initialState, action: AnyAction): ChannelState => {
  switch (action.type) {
    case 'fetchingChannel': {
      return {
        channelDetails: undefined,
        slug: action.slug,
        error: false,
        isLoading: true,
      };
    }
    case 'fetchedChannel':
      return {
        channelDetails: action.details,
        slug: action.details.channel.name,
        error: false,
        isLoading: false,
      };
    case 'fetchedChannelError':
      return {
        channelDetails: undefined,
        error: action.code,
        isLoading: false,
        slug: action.slug,
      };
    case 'fetchedChannelContentPage':
      if (!state.channelDetails) {
        console.warn('cant fetch content before channel');
        return state;
      }
      return {
        ...state,
        channelDetails: {
          ...state.channelDetails,
          contentPageData: action.contentPage,
        },
      };

    case 'fetchedFolderPage':
      if (!state.channelDetails) {
        console.warn('cant fetch content before channel');
        return state;
      }
      return {
        ...state,
        channelDetails: {
          ...state.channelDetails,
          folderPage: action.folderPage,
        },
      };
    case 'fetchedCurriculaPage':
      if (!state.channelDetails) {
        console.warn('cant fetch content before channel');
        return state;
      }
      return {
        ...state,
        channelDetails: {
          ...state.channelDetails,
          curriculaPage: action.curriculaPage,
        },
      };
    case 'navFromPage':
      return {
        ...state,
        navFrom: action.nav,
      };
    default:
      return state;
  }
};
