import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { logger } from '@lessonup/client-integration';
import { Channel } from '../../../../shared-core/domain';
import { channelRoute } from '../../../../shared-core/services/app/searchRoutes';
import useCallbackIfParamsChanged from '../../../../shared-core/ui/utils/hooks/useCallbackIfParamsChanged';
import { fetchedCurriculaPageAction } from '../../../redux/actions/actions';
import { useSpinnerContext } from '../../../utils/loaders/SpinnerContext';
import NotFoundErrorPageView from '../../search/errors/NotFoundErrorPageView';
import { ChannelSubPageProps } from '../ChannelPageView';
import './ChannelCurricula.less';
import ChannelCurriculaRenderer from './ChannelCurriculaRenderer';

const ChannelCurriculaPage: React.FC<ChannelSubPageProps> = ({ activeTab, details, channelService, changeTab }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('curricula');
  const { channel, curriculaPage } = details;
  const [fetching, setFetching] = useState(!curriculaPage?.curriculum);
  const spinner = useSpinnerContext();
  const channelSlug = channel.name;
  const { channelPage } = activeTab;
  const lastReduxSelectionId = curriculaPage?.selectionId;

  // In useEffect because of the 'Cannot update during an existing state transition' Error
  useEffect(() => {
    // we navigated away and returned, so fix the url
    if (lastReduxSelectionId && !activeTab.selectionId) {
      changeTab({
        channelPage: activeTab.channelPage,
        selectionId: lastReduxSelectionId,
      });
    }
    // we only need to run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // we default to 'root' if undefined to lastReduxSelectionId to prevent unneeded fetching
  const selectionId = activeTab.selectionId || lastReduxSelectionId || Channel.root;
  type SelectionId = string | undefined;
  useCallbackIfParamsChanged<SelectionId>({
    initial: curriculaPage && curriculaPage.selectionId,
    params: selectionId,
    callback: async (selectionId) => {
      try {
        setFetching(true);
        spinner.open();
        const channelData = await channelService.channelCurriculaPage(channelSlug, selectionId || Channel.root);
        dispatch(fetchedCurriculaPageAction(channelData.curriculaPage));
      } catch (error) {
        logger.error(error);
      } finally {
        setFetching(false);
        spinner.close();
      }
    },
  });

  if (!curriculaPage || !curriculaPage.curriculum) {
    return fetching ? null : (
      <NotFoundErrorPageView
        type="page"
        backUrl={channelRoute({
          channelSlug,
          channelPage,
        })}
      />
    );
  }

  const { curriculum, names, counts } = curriculaPage;
  if (!curriculum) return null;

  const curriculumId = curriculum._id;

  return (
    <div className="channel-curricula">
      <div className="curricula-sidebar">
        <div>
          <h2>{t('curricula')}</h2>
          {names
            ? names.map((curriculum, i) => {
                const pending = curriculum._id === selectionId;
                const active = curriculum._id === curriculumId;
                return (
                  <Link
                    to={channelRoute({
                      channelSlug,
                      channelPage,
                      selectionId: curriculum._id,
                    })}
                    key={curriculum._id}
                  >
                    <div className={classNames('channel-tab', { active, pending })}>{curriculum.name}</div>
                  </Link>
                );
              })
            : null}
        </div>
      </div>
      <ChannelCurriculaRenderer curriculum={curriculum} counts={counts} slug={channelSlug} />
    </div>
  );
};

export default ChannelCurriculaPage;
