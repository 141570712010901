import { randomId } from '@lessonup/utils';
import ModalManagerComponent from './ModalManagerComponent';

export class ModalManagerService {
  private component: ModalManagerComponent | undefined;
  public registerComponent(component: ModalManagerComponent) {
    if (this.component) {
      throw new Error(
        'ModalManagerService already has a component, did another component not call #unregisterComponent?'
      );
    }
    this.component = component;
  }

  public unregisterComponent() {
    this.component = undefined;
  }

  public async setModal<T>(renderFn: (closeFn: (result: T) => void) => JSX.Element): Promise<T> {
    const component = this.component;

    if (!component) {
      return Promise.reject('Tried to render modal, but no component was registered with the service');
    }

    const promise = new Promise<T>((resolve) => {
      const closeModal = (result: T) => {
        component.popModal();
        resolve(result);
      };

      component.pushModal({ renderFn, closeFn: closeModal, key: randomId() });
    });

    return promise;
  }
}

export interface ModalProps<T> {
  closeModal: (result: T) => void;
}

export abstract class AppModalManagerService extends ModalManagerService {}
