import classNames from 'classnames';
import React from 'react';
import './RocketSpinner.less';

interface Props {
  children?: React.ReactNode;
  noGlass?: boolean;
}

export default function RocketSpinner({ children, noGlass }: Props) {
  return (
    <div id="spinner" className={classNames('abs rocket-spinner', { 'no-glass': noGlass })}>
      <div id="inner-spinner" />
      <div className="spinner-content">{children}</div>
    </div>
  );
}
