import { ApolloProvider, createApolloClient, RouterProvider } from '@lessonup/client-integration';
import { NiceModal } from '@lessonup/ui-components';
import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { bridgeRouter } from './initBridge';

interface Props {
  children: React.ReactNode;
  SSRFallback?: React.ReactNode;
}

export const ClientOnlyModernWrapper: React.FC<Props> = ({ children, SSRFallback }) => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [onClient, setOnClient] = React.useState(false);
  // useEffect will never run on the server
  useEffect(() => {
    setOnClient(true);
  }, []);

  if (!onClient) {
    return <>{SSRFallback}</>;
  }
  const apolloClient = createApolloClient();

  return (
    <React.Fragment>
      <RouterProvider value={bridgeRouter(location, history, params)}>
        <NiceModal.Provider>
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        </NiceModal.Provider>
      </RouterProvider>
    </React.Fragment>
  );
};
