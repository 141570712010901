import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import { meteorRouteWithLanguageQueryParam } from '../../../utils/routeUtils/routeUtils';
import { StudentTile, TeacherTile } from '../../GrayPageLayout/appSelectionTiles';
import { LoginStepProps } from '../LoginPage';
import '../LoginPage.less';

type Props = LoginStepProps & { hideImages?: true };

const LoginSelectApplication: React.FC<Props> = (props) => {
  const { t } = useTranslation('login');
  const { config } = useAppServices();

  return (
    <div className="login-select-application stack">
      <TeacherTile next={props.next} hideImage={!!props.hideImages}>
        <div className="tile-description d-none d-xs-flex">{t('teacherLoginDescription')}</div>
      </TeacherTile>
      <StudentTile hideImage={!!props.hideImages} url={meteorRouteWithLanguageQueryParam(config.studentLoginUrl())}>
        <div className="tile-description d-none d-xs-flex">{t('studentLoginDescription')}</div>
      </StudentTile>
    </div>
  );
};

export default LoginSelectApplication;
