import React, { ComponentType } from 'react';

interface Props {
  template: string;
  [key: string]: any;
}

/**
 * Wrapper around blaze so the server and other apps can render a non blaze component
 */
export default class BlazeTemplate extends React.Component<Props> {
  private static Component: ComponentType<Props> = () => null;

  render() {
    return <BlazeTemplate.Component {...this.props} />;
  }

  public static configure(component: React.ComponentType<Props>) {
    BlazeTemplate.Component = component;
  }
}
