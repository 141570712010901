import classNames from 'classnames';
import React from 'react';
import FolderSvg, { FolderSvgColor } from '../../../svgIcons/FolderSvg';
import './ExplorerSharedExplorerIcon.less';

interface BooleanFlags {
  isPublish?: boolean;
  isTemplate?: boolean;
}
interface Props extends BooleanFlags {
  className: string;
  showOpen?: boolean;
}
export const ExplorerSharedExplorerIcon: React.FC<Props> = ({ className = '', isPublish, isTemplate, showOpen }) => (
  <div className={classNames('visual explorer-shared-explorer-icon', className, { 'is-open': showOpen })}>
    <FolderSvg color={color({ isTemplate, isPublish })} showOpen={!!showOpen} />
    <div className={`visual-icon ${icon({ isTemplate, isPublish })}`}></div>
  </div>
);

const color = ({ isPublish, isTemplate }: BooleanFlags): FolderSvgColor => {
  if (isPublish) return 'red';
  if (isTemplate) return 'green';
  return 'blue';
};

const icon = ({ isPublish, isTemplate }: BooleanFlags): string => {
  if (isPublish) return 'icon-radio-tower';
  if (isTemplate) return 'icon-template';
  return 'icon-share';
};
