import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-core/ui/components/Button';
import DefaultForm from '../../../shared-core/ui/components/inputElements/DefaultForm';

interface RenewMagicLinkFormProps {
  headerText?: string;
  description?: string;
  onSubmit: (email: string) => void;
  className?: string;
  email?: string;
}

function RenewMagicLinkForm(props: RenewMagicLinkFormProps) {
  const { t } = useTranslation('login');
  const [email, setEmail] = useState<string | undefined>(props.email);

  function handleSubmit() {
    if (email) props.onSubmit(email);
  }

  function emailChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  return (
    <div className={props.className}>
      {props.headerText && <h3 className="password-forgotten-title">{props.headerText}</h3>}
      {props.description && <p>{props.description}</p>}
      <DefaultForm className="password-forgotten-form" handleSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Email"
          autoComplete="email"
          value={email}
          onChange={emailChangeHandler}
          required
        />
        <Button theme="light" type="submit">
          {t('submit')}
        </Button>
      </DefaultForm>
    </div>
  );
}

export default RenewMagicLinkForm;
