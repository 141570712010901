import { Lesson, Pin } from '@lessonup/teaching-core';
import { isAfter, isBefore } from '@lessonup/utils';
import _ from 'lodash';
import { stringToDateUnsafe } from '../../utils';
import { Organization } from '../organizations';
import { MongoUser, MongoUserAppliedLicense, MongoUserCurrentLicense } from './MongoUser';

export namespace Licenses {
  export const allLicenses = ['free', 'start', 'expired', 'pro', 'trial', 'expiring', 'school'] as const;

  export type Status = typeof allLicenses[number];
  export type ExpiryDate = Date;

  type LicenseEnum = {
    [key in Status]: key;
  };
  export const licenseEnum: LicenseEnum = {
    free: 'free',
    start: 'start',
    expired: 'expired',
    pro: 'pro',
    trial: 'trial',
    expiring: 'expiring',
    school: 'school',
  };

  export const licenseList = <T extends Status[]>(...args: T) => args;
  export const proLicenses = licenseList('trial', 'expiring', 'pro', 'school');
  export const paidLicenses = licenseList('pro', 'school');
  export const freeLicenses = licenseList('free', 'expired');
  export const startLicenses = licenseList('start');
  export const trialLicenses = licenseList('trial', 'expiring');

  const trialLengthInDays = 30;
  export function defaultLicense(): MongoUserCurrentLicense {
    return {
      status: 'expiring',
      expires: new Date(new Date().getTime() + 1000 * 3600 * 24 * trialLengthInDays),
      since: new Date(),
    };
  }

  export type NewAppliedLicense = Omit<MongoUserAppliedLicense, 'checkDate'>;
  export function defaultAppliedLicense(): NewAppliedLicense {
    return _.pick(defaultLicense(), 'status', 'expires');
  }

  export type Pro = typeof proLicenses[number];
  export type Paid = typeof paidLicenses[number];
  export type Free = typeof freeLicenses[number];
  export type Trial = typeof trialLicenses[number];

  export function isPro(user: MongoUser | undefined): boolean {
    if (!user) return false;
    const license = MongoUser.license(user);
    return _.includes(proLicenses, license);
  }

  export function isFree(user: MongoUser | undefined): boolean {
    if (!user) return true;
    const license = MongoUser.license(user);
    return _.includes(freeLicenses, license);
  }

  export function isPaid(user: MongoUser | undefined): boolean {
    if (!user) return false;
    const license = MongoUser.license(user);
    return _.includes(paidLicenses, license);
  }

  export function isTrial(user: MongoUser | undefined): boolean {
    if (!user) return false;
    const license = MongoUser.license(user);
    return _.includes(trialLicenses, license);
  }

  export function isProLicenseStatus(license: Status | undefined): boolean {
    if (!license) return false;
    return _.includes(proLicenses, license);
  }

  export function isPaidLicense(license: Status | undefined): boolean {
    if (!license) return false;
    return _.includes(paidLicenses, license);
  }

  export function isFreeOrStartLicense(license: Status | undefined): boolean {
    if (!license) return false;
    return _.includes(freeLicenses, license) || _.includes(startLicenses, license);
  }

  export function isOrganizationLicense(user: MongoUser): boolean {
    return MongoUser.license(user) === licenseEnum.school;
  }

  export function licenseIsExpired(license: MongoUserCurrentLicense | MongoUserAppliedLicense): boolean {
    return !!license.expires && isBefore(stringToDateUnsafe(license.expires), new Date());
  }

  export function organizationLicenseIsExpired(license: Organization.License): boolean {
    return !!license.expireDate && isBefore(stringToDateUnsafe(license.expireDate), new Date());
  }

  export function isPinRestrictedForUser(user: MongoUser | undefined, pin: Pin, lesson?: Lesson): boolean {
    if (!user) return true;

    const isRestrictedForLicense = isLessonRestrictedForLicense(MongoUser.license(user), lesson);
    const pinRestricted = Pin.isRestrictedPin(pin);
    return pinRestricted && isRestrictedForLicense;
  }

  export function isLessonRestrictedForLicense(license?: Licenses.Status, lesson?: Lesson): boolean {
    return isFreeOrStartLicense(license) && !Lesson.isProductLesson(lesson);
  }

  export type CalculatedLicense = Omit<MongoUserAppliedLicense, 'checkDate'>;

  type OrganizationDate = {
    _id: string;
    date: Date | undefined;
  };
  type OrganizationWithDate = {
    _id: string;
    date: Date;
  };

  function validateAndSortOrganizationLicenseDate(organizationsWithLicenses: Organization[]) {
    const organizationDate: OrganizationDate[] = organizationsWithLicenses.map((organization) => ({
      _id: organization._id,
      // some orgs have a string date, guard against that
      date: organization.license.expireDate ? stringToDateUnsafe(organization.license.expireDate) : undefined,
    }));
    const noDate = organizationDate.filter((o) => !o.date);
    const withDate = organizationDate.filter((o): o is OrganizationWithDate => !!o.date);

    withDate.sort((a, b) => {
      if (a.date < b.date) return 1;
      if (a.date > b.date) return -1;
      return 0;
    });
    const now = new Date();

    const validLicenseOrganizations = withDate.filter((o) => isAfter(o.date, now));
    const invalidLicenseOrganizations = withDate.filter((o) => isBefore(o.date, now));
    return {
      validLicenseOrganizations,
      invalidLicenseOrganizations: noDate.concat(invalidLicenseOrganizations),
    };
  }

  export const proLicenseWillExpire = (license: MongoUserCurrentLicense): boolean =>
    isProLicenseStatus(license.status) && !!license.expires;
}
