import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelTitle, Facets, IndexedChannelInfo, SelectedFacet } from '../../../../../shared-core/domain';

type PageType = 'lesson' | 'plan';

interface SearchResultsSubtitleProps {
  selectedFacets: SelectedFacet[];
  channelInfo?: IndexedChannelInfo;
  type: PageType;
}

const SearchResultsSubtitle: React.FC<SearchResultsSubtitleProps> = ({ channelInfo, selectedFacets, type }) => {
  const { t, i18n } = useTranslation('searchResultsTitle');
  const { schoolType, schoolTypePrefix, yearAndLevel, subject } =
    Facets.obtainMetaDataInfoForSelectedFacets(selectedFacets);
  const subjectSchoolTypeYear = classNames(
    subject,
    { [t('for') + ' ']: schoolType },
    { [schoolTypePrefix + ' ']: schoolType && schoolTypePrefix && i18n.language === 'nl' }, // Workaround to ensure Dutch schoolTypes are properly prefixed.
    { [`${schoolType && schoolType.toLowerCase()}`]: schoolType },
    yearAndLevel
  );
  const description = `${t('lookingFor')}${subjectSchoolTypeYear && ' ' + subjectSchoolTypeYear}?`;

  const translation = translationKey(channelInfo, type);

  const findLessonIdeas = t(translation, {
    subject: subject || '',
    channel: channelInfo?.title && ChannelTitle.localizeTitle(channelInfo.title),
  });

  return <p className="search-results-subtitle">{`${description} ${findLessonIdeas}`}</p>;
};

function translationKey(channelInfo: IndexedChannelInfo | undefined, type: PageType) {
  if (channelInfo) {
    return type === 'lesson' ? 'findLessonIdeasChannel' : 'findPlanIdeasChannel';
  }
  return type === 'lesson' ? 'findLessonIdeas' : 'findPlanIdeas';
}

export default SearchResultsSubtitle;
