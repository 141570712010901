import { AnalyticsPageEvent, AnalyticsTrackEvent } from './types';

interface GoogleAnalyticsEvent {
  tracker?: {
    name: string;
    trackingId: string;
  };
}

interface BaseEvent<Category extends string, Action extends string> extends AnalyticsTrackEvent, GoogleAnalyticsEvent {
  readonly category: Category;
  readonly action: Action;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UserEvent<Name extends string> extends BaseEvent<'users', Name> {}

interface LessonEvent<Name extends string> extends BaseEvent<'lessons', Name> {
  readonly lesson: string;
}

interface PinEvent<Name extends string> extends BaseEvent<'pins', Name> {
  readonly lesson: string;
  readonly pin: string;
}

type ViewLesson = LessonEvent<'view-lesson'>;
type ShareLesson = LessonEvent<'share-lesson'>;
type RateLesson = LessonEvent<'rate-lesson'>;
type ViewPin = PinEvent<'view-pin'>;
type VoucherProductRedeemSuccess = UserEvent<'voucher-product-success'>;
type VoucherLicenseRedeemSuccess = UserEvent<'voucher-license-success'>;
type VoucherError = UserEvent<'voucher-redeem-error'>;
type VoucherProductValidateSuccess = UserEvent<'voucher-product-validate-success'>;
type VoucherLicenseValidateSuccess = UserEvent<'voucher-license-validate-success'>;
type VoucherValidateError = UserEvent<'voucher-validate-error'>;
type SelectRegistrationAccountType = UserEvent<'select-registration-account-type'>;
type SelectLoginAccountType = UserEvent<'select-login-account-type'>;

type RegistrationEvent =
  | UserEvent<'login-start-search'>
  | UserEvent<'register-start'>
  | UserEvent<'register-finished'>
  | UserEvent<'register-student'>;

type LoginEvent =
  | UserEvent<'login-start-search'>
  | UserEvent<'login-student-website'>
  | UserEvent<'login-student-search'>
  | UserEvent<'login-finished-website'>
  | UserEvent<'login-finished-search'>
  | UserEvent<'login-student-website'>
  | UserEvent<'login-student-search'>;

export type PageEvent = AnalyticsPageEvent & GoogleAnalyticsEvent;
export type AllEvents =
  | ViewLesson
  | ShareLesson
  | RateLesson
  | ViewPin
  | VoucherProductRedeemSuccess
  | VoucherLicenseRedeemSuccess
  | VoucherError
  | VoucherProductValidateSuccess
  | VoucherLicenseValidateSuccess
  | VoucherValidateError
  | SelectRegistrationAccountType
  | SelectLoginAccountType
  | RegistrationEvent
  | LoginEvent;

export function loginFinishedEventForSource(source?: 'standalone' | 'modal'): LoginEvent['action'] | undefined {
  if (!source) {
    return undefined;
  }

  return `login-finished-${source === 'standalone' ? 'website' : 'modal'}` as LoginEvent['action'];
}
