import { LessonPin } from '@lessonup/teaching-core';
import { Button } from '@lessonup/ui-components';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PinView from '../../../../../shared-core/ui/components/pin/PinView';
import { IframeMessaging } from '../../../../../shared-core/utils/IframeMessaging';
import { PIN_SEARCH_TRANSLATION_NS } from '../PinSearchPage';

interface VideoResultProps {
  pin: LessonPin;
}

const handleVideoResolve = (url: string | undefined) => {
  if (!url) return;
  IframeMessaging.send('video-url', url);
};

export const VideoResult: React.FunctionComponent<VideoResultProps> = ({ pin }) => {
  const { t } = useTranslation(PIN_SEARCH_TRANSLATION_NS);

  return (
    <div className={classNames('pin-result')} key={pin._id} onClick={() => handleVideoResolve(pin.item.url)}>
      <div className={`pin-size-${400}`}>
        <PinView pin={pin} isThumb={true} />
      </div>
      <div className="actions">
        <Button buttonType="primary">{t('useInLesson')}</Button>
      </div>
    </div>
  );
};
