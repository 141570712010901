import { LessonUpApiService } from '../../../shared-core/client/services/api';
import { Api } from '../../../shared-core/domain/api/LessonupApi';

export interface SchoolPickerAppService {
  getSchoolPickerSuggestions(params: Api.SchoolPickerRequest): Promise<Api.SchoolPickerResponse>;
}
export default function configureSchoolPickerAppService(api: LessonUpApiService): SchoolPickerAppService {
  function getSchoolPickerSuggestions(params: Api.SchoolPickerRequest): Promise<Api.SchoolPickerResponse> {
    return api.getSchoolSuggestions(params);
  }

  return {
    getSchoolPickerSuggestions,
  };
}
