import React from 'react';

// COPIED FROM balloons.js

export default function Balloons() {
  const balloons = calcballoonParams().map((b, i) => Balloon(b, i));

  return <div className="balloons">{balloons}</div>;
}

function Balloon(b, i) {
  return (
    <div key={`balloon-${i}`} style={{ left: b.x + '%', top: b.y + '%' }} className={`balloon ${b.class}`}>
      <svg viewBox="0 0 512 512" className="svg-icon tuutje">
        <path d="M 1 1 H 510 L 1 510 H 1" />
      </svg>
    </div>
  );
}

var cache: any[];

// this function is used to calc balloon model, runs only once
function calcballoonParams() {
  if (cache) return cache;
  const radius = 30;
  const amount = 8;
  const halfWidth = 12 / 2;
  const halfHeight = 10 / 2;

  var radianTot = Math.PI * 2;
  var res: any[] = [];
  for (var i = 0; i < amount; i++) {
    let r: _.Dictionary<any> = {},
      rad = (radianTot / amount) * i;
    r.class = rad < Math.PI || rad > radianTot ? 'arrow-left' : 'arrow-right';
    r.x = 50 - halfWidth + Math.round(0 + radius * 1.1 * Math.sin(rad) * 1000) / 1000;
    r.y = 50 - halfHeight + Math.round(0 - radius * Math.cos(rad) * 1000) / 1000;
    res.push(r);
  }
  // console.log(EJSON.stringify(res, {indent: true}));
  cache = res;
  return res;
}
