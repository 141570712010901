import classNames from 'classnames';
import React from 'react';
import Heading from '../../../shared-core/ui/components/Heading';
import './GrayPageLayout.less';

interface GrayPageLayoutProps {
  className?: string;
  children?: React.ReactNode;
}

const GrayPageLayout: React.FC<GrayPageLayoutProps> = ({ children, className }) => {
  return <div className={classNames('gray-page-layout', className)}>{children}</div>;
};

export const GrayPageLayoutTitle: React.FC<{ className?: string; children?: React.ReactNode }> = ({
  children,
  className,
}) => {
  return (
    <Heading className={classNames('gray-page-title', className)} size="h1" as="h1">
      {children}
    </Heading>
  );
};

export const GrayPageSubtitle: React.FC<{ className?: string; children?: React.ReactNode }> = ({
  children,
  className,
}) => {
  return <span className={classNames('gray-page-subtitle', className)}>{children}</span>;
};

export const GrayPageSection: React.FC<{
  position: 'top' | 'center' | 'bottom';
  className?: string;
  children?: React.ReactNode;
}> = ({ children, position, className }) => {
  return <div className={classNames('gray-page-section', `gray-page-section-${position}`, className)}>{children}</div>;
};

export default GrayPageLayout;
