import { ExtendedChannelInfo } from '../../../shared-core/domain';
import { AnyAction } from '../actions/actions';

export interface ChannelState {
  channels: ExtendedChannelInfo[];
  hasLoaded: boolean;
}

const initialState: ChannelState = {
  channels: [],
  hasLoaded: false,
};

export const channelOverviewReducer = (state: ChannelState = initialState, action: AnyAction): ChannelState => {
  switch (action.type) {
    case 'fetchedChannelOverviewChannels': {
      return {
        channels: action.channels,
        hasLoaded: true,
      };
    }
    case 'reloadChannelOverview': {
      return {
        ...state,
        hasLoaded: false,
      };
    }
    default:
      return state;
  }
};
