import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { loginRoute, registerRoute } from '../../../../shared-core/services/app/searchRoutes';
import ActionButton from '../../../../shared-core/ui/components/Button';
import { Modal } from '../../../../shared-core/ui/components/modals/Modal';
import { Text } from '../../../../shared-core/ui/components/translations/Text/Text';
import { useAppServices } from '../../appServices/AppServicesContext';
import './LoginPromptModal.less';

const TRANSLATION_NAMESPACE = 'loginPromptModal';

export interface LoginPromptModalResult {
  dismiss?: boolean;
}

export interface LoginPromptConfig {
  forceNewTab?: boolean;
  action: 'save' | 'teach';
}

type Props = RouteComponentProps &
  LoginPromptConfig & {
    closeModal: (res: LoginPromptModalResult | undefined) => void;
  };

function LoginPromptModal(props: Props) {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const { modals, user } = useAppServices();

  function handleClose(dismiss?: boolean) {
    return props.closeModal({ dismiss: !!dismiss });
  }

  async function handleLoginClick() {
    if (!props.forceNewTab) {
      const loginResult = await modals.open('login', { userService: user });
      return handleClose(loginResult.status === 'failed');
    }

    window.open(loginRoute('form'), '_blank');
    return handleClose(true);
  }

  async function handleRegisterClick() {
    if (!props.forceNewTab) {
      props.history.push(registerRoute(), '');
    } else {
      window.open(registerRoute('credentials'), '_blank');
    }
    return handleClose(true);
  }

  return (
    <Modal title={t('title')} isOpen onRequestClose={handleClose} width="medium">
      <div className="login-modal">
        <div className="loginmodal__header">
          <div className="loginmodal_headertext">
            <Text
              translateKey={props.action === 'teach' ? 'descriptionTeach' : 'descriptionSave'}
              options={{ ns: TRANSLATION_NAMESPACE }}
            />
          </div>
        </div>
        <br />
        <div className="loginmodal-buttons">
          <ActionButton theme="secondary" onClick={handleLoginClick}>
            <Text translateKey="login" options={{ ns: TRANSLATION_NAMESPACE }} />
          </ActionButton>
          <ActionButton theme="primary" onClick={() => handleRegisterClick()}>
            <Text translateKey="create-account" options={{ ns: TRANSLATION_NAMESPACE }} />
          </ActionButton>
        </div>
      </div>
    </Modal>
  );
}

export default withRouter(LoginPromptModal);
