import { Organization } from '../../../shared-core/domain';
export interface OrganisationPickerState {
  organizations: Organization[];
}

const initialState: OrganisationPickerState = {
  organizations: [],
};

// ssr only reducer, if you need it, fix it
export const organizationPickerReducer = (state: OrganisationPickerState = initialState): OrganisationPickerState =>
  state;
