import { Component } from '@lessonup/teaching-core';
import React from 'react';
import ComponentContainer from '../componentContainer/ComponentContainer';

interface Props {
  component: Component.TrafficLight;
  editSlideColor?: (color: number) => void;
  onClickHandler?: Function;
  isThumb?: boolean;
}

interface State {
  activeColor: 'red' | 'yellow' | 'green';
}

export default class TrafficLightComponentView extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }
  state = {
    activeColor: this.props.component.settings.startLight,
  };

  yellowClass = () => {
    return this.props.component.settings.startLight ? 'yellow' : 'no-yellow';
  };

  changeColor = (color: 'red' | 'yellow' | 'green'): void => {
    this.setState({
      activeColor: color,
    });
  };

  onClickHandler(e: React.MouseEvent) {
    const color = e.target['dataset']['target'];
    if (!color || !['red', 'yellow', 'green'].includes(color)) return;
    if (this.props.component.settings.changeSlideColor && this.props.editSlideColor) {
      const colorsToNumbersMap = {
        red: 3,
        yellow: 7,
        green: 8,
      };
      this.props.editSlideColor(colorsToNumbersMap[color]);
    }
    this.changeColor(color);
  }

  render() {
    return (
      <ComponentContainer {...this.props} isInteractive={!this.props.isThumb}>
        <div className={`light active-${this.state.activeColor} ${this.yellowClass()}`} data-grippy="move">
          <div className="lightbox lightbox-outer" data-grippy="move"></div>
          <div className="lightbox lightbox-inner" data-grippy="move"></div>
          <Light color="red" onClick={this.onClickHandler} isThumb={this.props.isThumb} />
          <Light color="yellow" onClick={this.onClickHandler} isThumb={this.props.isThumb} />
          <Light color="green" onClick={this.onClickHandler} isThumb={this.props.isThumb} />
        </div>
      </ComponentContainer>
    );
  }
}

interface LightProps {
  color: string;
  onClick: (e: React.MouseEvent) => void;
  isThumb?: boolean;
}

const Light = (props: LightProps) => {
  const lightProps = {
    className: `lamp lamp-${props.color}`,
    'data-role': 'change-light',
    'data-target': props.color,
  };

  if (!props.isThumb) {
    lightProps['onClick'] = props.onClick;
  }

  return <div {...lightProps}></div>;
};
