const PROVIDER = 'ga-provider';
const ORIGIN = 'ga-origin';

export const SSORegistrationLocalStorageKeys = {
  PROVIDER,
  ORIGIN,
};

export type Origin = 'register' | 'login';
export type Provider = 'google' | 'office';

export function isValidProvider(provider: any): provider is Provider {
  return ['google', 'office'].includes(provider);
}

export function isValidOrigin(origin: any): origin is Origin {
  return ['register', 'login'].includes(origin);
}
