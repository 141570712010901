import classNames from 'classnames';
import React from 'react';
import Checkbox from '../../../../../ui/components/Checkbox';
import { Cell, CellProps } from '../../../../../ui/components/Table';
import { useBEM } from '../../../../../ui/utils/hooks';
import './CheckboxCell.less';

interface Props extends CellProps {
  id: string;
  className?: string;
  onChange: any;
  isSelected: boolean;
  hideCheckbox?: boolean;
  disabled?: boolean;
  cellType?: string;
}

export const CheckboxCell: React.FC<Props> = ({
  children,
  className,
  onChange,
  isSelected,
  hideCheckbox,
  cellType,
  disabled,
  ...props
}) => {
  const bemClasses = useBEM('CheckboxCell');

  return (
    <Cell className={classNames(bemClasses({ className }), 'minimize')} {...props} ref={null}>
      <Checkbox
        id={props.id}
        className={bemClasses({
          element: 'checkbox',
          modifiers: [hideCheckbox ? '' : 'active'],
        })}
        checked={!!isSelected}
        onChange={onChange}
        stopPropagation
        hitBox={'default'}
        dispatchEventToWindow={true}
        disabled={disabled}
      ></Checkbox>
    </Cell>
  );
};
