import React from 'react';
import ensureHttps from '../../../../utils/ensureHttps';
import { useBEM } from '../../../utils/hooks';
import RocketSvg from '../../logos/RocketSvg';
import './CardIcon.less';

export interface CardProps {
  className?: string;
  iconUrl?: string;
}
export const CardIcon: React.FC<CardProps> = ({ className, iconUrl }) => {
  const bemClasses = useBEM('CardIcon');
  return (
    <div className={bemClasses({ className, modifier: !iconUrl && 'placeholder' })}>
      {iconUrl ? (
        <div
          className={bemClasses({ element: 'custom-background' })}
          style={{ backgroundImage: `url('${ensureHttps(iconUrl)}')` }}
        />
      ) : (
        <RocketSvg height="20" width="20" />
      )}
    </div>
  );
};
