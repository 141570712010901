import { RatingSummary } from '@lessonup/firebase-database';

export interface LessonStats {
  id: string;
  sharedCount: number;
  copiedCount: number;
  averageRating: number | undefined; // 0.0 - 1.0
  ratingCount: number;
  assignmentsCreatedCount: number;
  finishPresentingCount: number;
  testStartedCount: number;
}

export namespace LessonStats {
  export function from(lessonId: string, ratings: RatingSummary): LessonStats {
    // these come from ES in the past, but we are no longer using that. It should probably come from BigQuery now
    const stats: LessonStats = {
      id: lessonId,
      copiedCount: 0,
      sharedCount: 0,
      assignmentsCreatedCount: 0,
      testStartedCount: 0,
      finishPresentingCount: 0,
      averageRating: ratings.average,
      ratingCount: ratings.count,
    };

    return stats;
  }

  export function empty(id: string): LessonStats {
    return {
      id,
      copiedCount: 0,
      sharedCount: 0,
      assignmentsCreatedCount: 0,
      testStartedCount: 0,
      finishPresentingCount: 0,
      averageRating: 0,
      ratingCount: 0,
    };
  }

  export function average(stats: LessonStats[], id: string): LessonStats {
    const average: Partial<LessonStats> = {
      id,
    };
    [
      'copiedCount',
      'sharedCount',
      'assignmentsCreatedCount',
      'testStartedCount',
      'finishPresentingCount',
      'averageRating',
      'ratingCount',
    ].forEach((key) => {
      const sum = stats.reduce((mem, s) => mem + s[key], 0);
      average[key] = Math.round(sum / stats.length) || 0;
    });
    return average as LessonStats;
  }
}
