import React from 'react';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';
import ReactCreatable from 'react-select/creatable';
import { useBEM } from '../../utils/hooks';
import { getDropdownTheme, LessonupSelectTheme, SelectThemeParams } from './SelectStyles';

export type Option = { value: string; label: string };

export interface SelectProps<T> extends ReactSelectProps<T, boolean> {
  className?: string;
  dropdownTheme?: LessonupSelectTheme;
  dropdownThemeOptions?: SelectThemeParams;
  formatCreateLabel?: (input: string) => string;
  onChange: (value: T) => void;
  id?: string;
  instanceId?: string;
}

export const Select: React.FC<SelectProps<Option>> = ({
  className,
  dropdownTheme,
  dropdownThemeOptions,
  formatCreateLabel,
  ...remainingProps
}) => {
  const bemClasses = useBEM('Select');

  if (formatCreateLabel) {
    return (
      <ReactCreatable className={bemClasses({ className })} formatCreateLabel={formatCreateLabel} {...remainingProps} />
    );
  }

  return (
    <ReactSelect
      className={bemClasses({ className })}
      styles={dropdownTheme && getDropdownTheme<Option>(dropdownTheme, dropdownThemeOptions)}
      {...remainingProps}
    />
  );
};

export default Select;
