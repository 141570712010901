import React, { DependencyList, EffectCallback } from 'react';
import { UseEffectReturn } from './useDeepCompareEffect';
import useDeepCompareMemoize from './useDeepCompareMemoize';

// Taken from NPM package: https://www.npmjs.com/package/use-deep-compare-effect
// Works like useDeepCompareEffect, but should only be used for polymorphic values

function useDeepCompareEffectNoCheck(callback: EffectCallback, dependencies: DependencyList): UseEffectReturn {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useEffect(callback, useDeepCompareMemoize(dependencies));
}

export default useDeepCompareEffectNoCheck;
