import React from 'react';
import { LanguageSingleton } from '../../../domain';
import { Country } from '../../../domain/country/Country';
import { Select } from '../Select';

interface CountryOption {
  value: Country.CountryCode;
  label: string;
}

export type CountryPickerProps = {
  onChange?: (country: Country.CountryCode) => void;
  defaultValue?: Country.CountryCode;
  options: CountryOption[];
  placeholder: string;
  id?: string;
};

const CountryPicker: React.FC<CountryPickerProps> = ({ onChange, defaultValue, options, placeholder, id }) => {
  const countryMap = Country.countryMapForLanguage(LanguageSingleton.get());

  const defaultCountryValue = defaultValue && {
    value: defaultValue,
    label: countryMap[defaultValue],
  };

  const handleChange = (value: Country.CountryCode, onChange: CountryPickerProps['onChange']) => {
    const countryCode: Country.CountryCode | undefined = value in countryMap ? value : undefined;
    if (countryCode && onChange) {
      onChange(countryCode);
    }
  };

  return (
    <Select
      id={id || 'country-picker-select-box'}
      options={options}
      defaultValue={defaultCountryValue}
      onChange={({ value }: CountryOption) => handleChange(value, onChange)}
      placeholder={placeholder}
      getOptionLabel={({ value }) => countryMap[value]}
      dropdownTheme="lessonup-default"
    />
  );
};

export default CountryPicker;
