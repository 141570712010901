import { Omit } from '../../utils';

export type ProductId = string | 'free';
export interface Product {
  _id: ProductId;
  organization: string;
  name: string;
  language?: string;
  organizationName?: string;
  url?: string;
  pdfManualLink?: string;
}

export type PublicProduct = Omit<Product, 'pdfManualLink'>;
type ID = string;

export interface ProductWithSource {
  productId: string;
  channelId?: string | undefined;
}

export namespace Product {
  export function forOrganization(products: Product[], organizationId: string): Product[] {
    return products.filter((p) => p.organization == organizationId);
  }

  export function canAccess(products: ID[] | undefined, userProducts: ID[] | undefined) {
    if (!products || !products.length) return true;
    if (!userProducts) return false;
    return userProducts.some((id) => products.includes(id));
  }

  export function productIds(products: ProductWithSource[]) {
    return products.map((product) => product.productId);
  }
}
