import { LessonPin } from '@lessonup/teaching-core';
import React from 'react';

import { PinViewerStatic, TeacherPin } from '@lessonup/pin-renderer';
import PinView from '../../../shared-core/ui/components/pin/PinView';
import './lessonPreviewTile.less';

export interface LessonPreviewTilePropsv1 {
  pins: LessonPin[];
  totalPinCount: number;
}

export const LessonPreviewTileV1: React.FC<LessonPreviewTilePropsv1> = ({ pins, totalPinCount }) => {
  const firstPin = pins[0];

  if (!firstPin) {
    return null;
  }

  const totalPins = pins.length;
  const remainingPinsToShow = pins.slice(1, 4);

  return (
    <div className="lesson-preview-tile">
      <div className="lesson-preview-tile--initialPin">
        <PinView pin={firstPin} isThumb hideFact />
      </div>
      <div className="lesson-preview-tile--lowerpin-list">
        {totalPins >= 2 && (
          <>
            {remainingPinsToShow.map((pin) => (
              <div className="lesson-preview-tile--lowerpin">
                <PinView pin={pin} isThumb hideFact />
              </div>
            ))}
            <OtherPinsIndicator value={totalPinCount - 4} />
          </>
        )}
      </div>
    </div>
  );
};

export interface LessonPreviewTilePropsv2 {
  teacherPins: TeacherPin[];
  totalPinCount: number;
}

export const LessonPreviewTileV2: React.FC<LessonPreviewTilePropsv2> = ({ teacherPins, totalPinCount }) => {
  const firstPin = teacherPins[0];

  if (!firstPin) {
    return null;
  }

  const totalPins = teacherPins.length;
  const remainingPinsToShow = teacherPins.slice(1, 4);

  return (
    <div className="lesson-preview-tile">
      <div className="lesson-preview-tile--initialPin lesson-preview-tile--v2">
        <PinViewerStatic pin={firstPin} width={231} />
      </div>
      <div className="lesson-preview-tile--lowerpin-list">
        {totalPins >= 2 && (
          <>
            {remainingPinsToShow.map((pin) => (
              <div className="lesson-preview-tile--lowerpin lesson-preview-tile--v2">
                <PinViewerStatic pin={pin} width={56} />
              </div>
            ))}
            <OtherPinsIndicator value={totalPinCount - 4} />
          </>
        )}
      </div>
    </div>
  );
};

const OtherPinsIndicator: React.FC<{ value: number }> = ({ value }) => {
  if (value <= 0) {
    return null;
  }
  return (
    <div className="lesson-preview-tile__count">
      <div className="lesson-preview-tile__count-text">{value <= 99 ? `+${value}` : `99+`}</div>
    </div>
  );
};
