import { ErrorProviderWithDefaultTranslations } from '@lessonup/client-integration';
import { LinkProvider } from '@lessonup/ui-components';
import { History } from 'history';
import { i18n } from 'i18next';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { LastLocationProvider } from 'react-router-last-location';
import { ToastProvider } from '../shared-core/ui/components/toast/ToastProvider';
import { AppServicesProvider } from './components/appServices/AppServicesContext';
import { AppStore } from './redux/store';
import { AppServices } from './services/AppServices';
import { LinkBridge } from './utils/bridge/LinkBridge';
import { SpinnerProvider } from './utils/loaders/SpinnerContext';
import { TopLevelClassnameProvider } from './utils/TopLevelClassnameContext';

interface Props {
  services: AppServices;
  store: AppStore;
  i18n: i18n;
  history: History;
  children?: React.ReactNode;
}

// all context Providers for the app
export default class AppProviders extends React.PureComponent<Props> {
  public render() {
    const { store, services } = this.props;
    return (
      <Provider store={store}>
        <Suspense fallback={null}>
          <LinkProvider value={LinkBridge}>
            <AppServicesProvider services={services}>
              <ToastProvider
                manager={(manager) => {
                  services.registerToastManager(manager);
                }}
              >
                <I18nextProvider i18n={this.props.i18n}>
                  <LastLocationProvider>
                    <TopLevelClassnameProvider>
                      <ErrorProviderWithDefaultTranslations>
                        <SpinnerProvider>{this.props.children}</SpinnerProvider>
                      </ErrorProviderWithDefaultTranslations>
                    </TopLevelClassnameProvider>
                  </LastLocationProvider>
                </I18nextProvider>
              </ToastProvider>
            </AppServicesProvider>
          </LinkProvider>
        </Suspense>
      </Provider>
    );
  }
}
