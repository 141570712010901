import { addHours } from '@lessonup/utils';
import { Language } from '../language';
import { MongoUser } from '../user';

export interface DirectLoginToken extends DirectLoginTokenData {
  _id: string;
}

export interface DirectLoginTokenData {
  userId: string;
  creationDate: Date;
  lastSeenDate: Date;
  expireDate: Date;
  token: string;
  singleUse: boolean;
}

export namespace DirectLoginToken {
  export function fromUser(user: MongoUser, token: string): DirectLoginTokenData {
    const now = new Date();
    const oneHourFromNow = addHours(now, 1);

    return {
      userId: user._id,
      creationDate: now,
      expireDate: oneHourFromNow,
      lastSeenDate: now,
      token,
      // Hardcoded for backwards compatibility
      singleUse: true,
    };
  }

  export function magicLinkPath(
    loginToken: string,
    user: MongoUser,
    queryParams?: { [key: string]: string }
  ): string | undefined {
    const email = MongoUser.emailFromMongoUser(user);

    if (!email) return;

    const qs = {
      ...queryParams,
      token: loginToken,
      language: user.data?.language || Language.defaultLanguage,
    };

    const querystring = Object.entries(qs)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => {
        return encodeURI(key) + '=' + encodeURI(value);
      })
      .join('&');

    return `/app/directLogin?${querystring}`;
  }
}
