import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useBEM } from '../../utils/hooks/useBEM';
import CheckmarkThin from '../svgIcons/CheckmarkThin';
import './RadioButton.less';

export interface BaseProps {
  value: string;
  name: string;
  onClick?: () => void;
  required?: boolean;
  disabled?: boolean;
  initialValue?: boolean;
  onChange?: (event) => void;
}

interface WithText extends BaseProps {
  text: string;
}

type WithChildren<T> = T & {
  children: React.ReactNode;
};

type RadioButtonProps = WithChildren<BaseProps> | WithText;

export const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const { value, name, required, disabled, initialValue, onChange } = props;
  const bemClassName = useBEM('radio-button');
  const [id, setId] = useState<string | undefined>(undefined);

  useEffect(() => {
    setId(uniqueId('radio-'));
  }, []);

  return (
    <>
      <input
        className={bemClassName()}
        id={id}
        name={name}
        value={value}
        type="radio"
        required={required}
        disabled={disabled}
        defaultChecked={initialValue}
        onChange={onChange}
      ></input>
      {areTextProps(props) ? (
        <label htmlFor={id}>
          <div>{props.text}</div>
          <CheckmarkThin />
        </label>
      ) : (
        <label htmlFor={id}>
          <div>{props.children}</div>
          <CheckmarkThin />
        </label>
      )}
    </>
  );
};

function areTextProps(props: BaseProps): props is WithText {
  return 'text' in props;
}
