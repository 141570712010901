import { IndexedLesson } from '../../../shared-core/domain';
import { FacetSearchResponse, PartialIndexedLessonPlan } from '../../../shared-core/domain/search';
import { AnyAction } from '../actions/actions';

export interface LessonSearchState {
  lessonResults: FacetSearchResponse<IndexedLesson> | undefined;
  planResults: FacetSearchResponse<PartialIndexedLessonPlan> | undefined;
}

const initialState: LessonSearchState = {
  lessonResults: undefined,
  planResults: undefined,
};

export function searchLessonReducer(state: LessonSearchState = initialState, action: AnyAction): LessonSearchState {
  switch (action.type) {
    case 'fetchedLessonResults':
      return {
        lessonResults: action.response.result,
        planResults: state.planResults,
      };
    case 'fetchedPlansResults':
      return {
        lessonResults: state.lessonResults,
        planResults: action.response.plans,
      };
    default:
      return state;
  }
}
