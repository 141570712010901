import * as React from 'react';

function PrintSvg(props) {
  return (
    <svg viewBox="0 0 32 32" {...props} fill="currentcolor">
      <path d="M8 2h16v4H8V2zM30 8H2c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h6v8h16v-8h6c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM4 14a2 2 0 11-.001-3.999A2 2 0 014 14zm18 14H10V18h12v10z" />
    </svg>
  );
}

export default PrintSvg;
