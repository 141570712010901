import { Component, Video } from '@lessonup/teaching-core';
import React from 'react';
import VideoThumb from '../../content-types/video/VideoThumb';
import ComponentContainer from '../componentContainer/ComponentContainer';
import { InvalidVideo } from './InvalidVideoMsg';
import './VideoComponentView.less';

interface Props {
  component: Component.Video;

  /**
   * The video component is shown in a lesson thumbnail
   */
  isThumb?: boolean;

  /**
   * Show a video thumbnail instead of a playable video
   */
  useThumbnailForComponentType?: Component.Type[];
}

export default class VideoComponentView extends React.PureComponent<Props> {
  private icon = () => {
    const { source } = this.props.component.settings.videoData;
    if (!source) return 'video';
    return source === 'uploads' ? 'youtube' : source;
  };

  private image = () => {
    return this.props.component.settings.videoData.image;
  };

  private videoComponentProps = () => {
    const { settings } = this.props.component;
    const { id } = this.props.component.settings.videoData.custom;

    return {
      source: settings.videoData.source,
      id,
      start: settings.start,
      end: settings.end,
      url: settings.url,
    };
  };

  public render() {
    const { isThumb, useThumbnailForComponentType, component } = this.props;

    return (
      <ComponentContainer component={component} isInteractive={!(isThumb || useThumbnailForComponentType)}>
        {
          component.settings.videoData.invalidVideo ? (
            <InvalidVideo />
          ) : isThumb || useThumbnailForComponentType?.includes('video') ? (
            <VideoThumb image={this.image()} icon={this.icon()} />
          ) : (
            <VideoComponent {...this.videoComponentProps()} img={this.image()} />
          ) // TODO: Add actual player instead of iframe
        }
      </ComponentContainer>
    );
  }
}

export interface VideoComponentProps {
  source: string;
  id: string;
  start?: string;
  end?: string;
  autoPlay?: boolean;
  url?: string;
  img?: string;
  title?: string;
}

export function VideoComponent(props: VideoComponentProps) {
  const useIframePlayer = () => ['youtube', 'vimeo'].includes(props.source);

  const videoUrl = () => {
    const { id, source, start, end } = props;

    if (source == 'youtube') {
      let url = 'https://www.youtube-nocookie.com/embed/' + id;

      const params: string[] = [];
      if (start) params.push('start=' + start);
      if (end) params.push('end=' + end);
      if (params.length) url += '?' + params.join('&');
      return url;
    } else if (source == 'vimeo') {
      let url = Video.vimeoEmbedUrlForId(id, false);
      if (start) url += '#t=' + start + '&autoplay=0';
      return url;
    }
  };

  return (
    <div className="playerWrapper" style={{ height: '100%' }} data-role="video-load">
      {useIframePlayer() ? (
        <iframe
          src={videoUrl()}
          className={`video-iframe ${props.source === 'vimeo' ? 'vimeo' : 'yt'}-video`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title={props.title}
        ></iframe>
      ) : (
        <video controls>
          <source src={props.url} />
        </video>
      )}
    </div>
  );
}
