import { addMonths, Identifiable } from '@lessonup/utils';
import { Omit } from '../../utils';

type ID = string;

export interface Voucher extends Identifiable {
  _id: string;
  creationDate: Date;
  redeemableUntilDate?: Date;
  createdBy: ID | null;
  maxUsers: number;
  /** Number of users that redeemed this code */
  redeemUserCount: number;
  /** Is voucher soft deleted */
  deleted?: Date;
  reference: string;
}

export interface BaseProductVoucher extends Voucher {
  type: 'product';
  product: ID;
  organization: ID;
  source?: 'api' | 'user' | 'import';
  externalId?: string;
}

export interface ProductVoucherWithExpiryDate extends BaseProductVoucher {
  expiryDate: Date;
}

export interface ProductVoucherWithExtendMonths extends BaseProductVoucher {
  extendMonths: number;
}

export type ProductVoucher = ProductVoucherWithExpiryDate | ProductVoucherWithExtendMonths;

export type LicenseVoucherLicenseType = 'trial' | 'pro';

export interface LicenseVoucherWithExpiryDate extends Voucher {
  type: 'license';
  expiryDate: Date;
  licenseType: LicenseVoucherLicenseType;
}

export interface LicenseVoucherExtension extends Voucher {
  type: 'license';
  extendMonths: number;
  licenseType?: LicenseVoucherLicenseType;
}

type VoucherWithExpiryDate = ProductVoucherWithExpiryDate | LicenseVoucherWithExpiryDate;
type VoucherWithExtendMonths = ProductVoucherWithExtendMonths | LicenseVoucherExtension;

export type LicenseVoucher = LicenseVoucherWithExpiryDate | LicenseVoucherExtension;
export type AnyVoucher = LicenseVoucher | ProductVoucher;

export type VoucherType = AnyVoucher['type'];

export interface VoucherCookie {
  id: ID;
  date: Date;
}

export type ProductVoucherWithProducts =
  | ProductVoucherWithExtendMonths
  | (ProductVoucherWithExpiryDate & { products: string[] });

type ExcludedCreateParams = '_id' | 'creationDate' | 'type' | 'redeemUserCount' | 'createdBy' | 'deleted';

export type ProductVoucherCreateParamsBase = Omit<BaseProductVoucher, ExcludedCreateParams>;

export type ProductVoucherCreateParams =
  | Omit<ProductVoucherWithExpiryDate, ExcludedCreateParams>
  | Omit<ProductVoucherWithExtendMonths, ExcludedCreateParams>;

export type LicenseVoucherCreateParams =
  | Omit<LicenseVoucherWithExpiryDate, ExcludedCreateParams>
  | Omit<LicenseVoucherExtension, ExcludedCreateParams>;

export const VOUCHER_COOKIE_KEY = 'voucher';
export const productVoucherPatternMatch = /LU-([0-9]{3}-){2}[0-9]{3}/;

type ExcludeProductVoucherUpdateParams =
  | '_id'
  | 'creationDate'
  | 'type'
  | 'redeemUserCount'
  | 'createdBy'
  | 'deleted'
  | 'product'
  | 'organization';

export type ProductVoucherUpdateParams = (
  | Omit<ProductVoucherWithExpiryDate, ExcludeProductVoucherUpdateParams | 'expiryDate'>
  | Omit<ProductVoucherWithExtendMonths, ExcludeProductVoucherUpdateParams | 'extendMonths'>
) & { extendMonths: number | undefined; expiryDate: Date | undefined };

export type VoucherDurationType = 'extendMonths' | 'expiryDate';

export function voucherNotValidResponse(type: VoucherType) {
  return `not-valid-${type}` as 'not-valid-product' | 'not-valid-license';
}

export function isVoucherWithExpiryDate(voucher: AnyVoucher): voucher is VoucherWithExpiryDate {
  return 'expiryDate' in voucher && !!voucher.expiryDate;
}

export function isVoucherWithExtendMonths(voucher: AnyVoucher): voucher is VoucherWithExtendMonths {
  return 'extendMonths' in voucher && !!voucher.extendMonths;
}

export function expirationDateForVoucher(voucher: AnyVoucher): Date {
  return isVoucherWithExpiryDate(voucher) ? voucher.expiryDate : addMonths(new Date(), voucher.extendMonths);
}

export function expirationDateForVoucherFromDate(voucher: AnyVoucher, date: Date): Date {
  return isVoucherWithExpiryDate(voucher) ? voucher.expiryDate : addMonths(date, voucher.extendMonths);
}

export function isLicenseVoucher(voucher: AnyVoucher): voucher is LicenseVoucher {
  return voucher.type === 'license';
}
