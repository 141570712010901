import { logger } from '@lessonup/client-integration';
import { getEduSystem } from '@lessonup/teaching-core';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FacetMetaData } from '../../../domain/search/content/FacetMetaData';
import { Tag, TagProps, Tags } from '../Tags';

export const SUBJECTS_LIMIT = 2;

type Props = {
  metaData: FacetMetaData;
  size: 'large' | 'small';
  limit?: number;
  subjectsLimit?: number | null;
  renderOverflow?: (number) => JSX.Element;
  className?: string;
};

interface Tag {
  label: string;
  color: TagProps['color'];
}

const MetaDataTagList: React.FC<Props> = ({
  metaData,
  size,
  className,
  limit,
  subjectsLimit = SUBJECTS_LIMIT,
  renderOverflow,
}) => {
  if (limit && subjectsLimit && subjectsLimit > limit) {
    logger.error(
      `\`subjectsLimit\` should be less than or equal to \`limit\`, subjectsLimit is: ${subjectsLimit}, limit is: ${limit}`
    );
  }

  const { t } = useTranslation('common');

  const subjectTags: Tag[] = metaData.subjects.map((label) => ({ label, color: 'yellow' }));
  const limitedSubjectTags: Tag[] = subjectTags.slice(0, subjectsLimit ? subjectsLimit : undefined);
  const testTag: Tag[] = metaData.isTest ? [{ label: t('test'), color: 'red' }] : [];
  const tags: Tag[] = testTag.concat(lessonTags(metaData).map((label) => ({ label, color: 'cyan' })));
  const limitedTags: Tag[] = tags.slice(0, limit ? limit - limitedSubjectTags.length : undefined);

  const tagsTotal = subjectTags.length + tags.length;
  const renderedTags = limitedSubjectTags.length + limitedTags.length;
  const tagsOverflow = limit ? tagsTotal - renderedTags : 0;

  return (
    <>
      <Tags className={className}>
        {limitedSubjectTags.map((tag) => (
          <Tag key={tag.label} color={tag.color} size={size}>
            {tag.label}
          </Tag>
        ))}
        {subjectsLimit && subjectTags.length > subjectsLimit && (
          <SubjectsOverflowTag total={subjectTags.length} limit={subjectsLimit} size={size}></SubjectsOverflowTag>
        )}
        {limitedTags.map((tag) => (
          <Tag key={tag.label} color={tag.color} size={size}>
            {tag.label}
          </Tag>
        ))}
      </Tags>
      {renderOverflow && tagsOverflow > 0 && renderOverflow(tagsOverflow)}
    </>
  );
};

type OverflowTagProps = {
  total: number;
  limit: number;
} & Partial<TagProps>;

function SubjectsOverflowTag({ total, limit, ...props }: OverflowTagProps) {
  return <Tag color="yellow" title={`${total - limit} meer vakken`} {...props}>{`+${total - limit}`}</Tag>;
}

export function lessonTags(
  metaData: FacetMetaData | undefined,
  selectProperties = ['schoolType', 'themes', 'years']
): string[] {
  if (!metaData) return [];
  const eduSystem = getEduSystem(metaData.country);
  const tagObj = eduSystem.labelsForMetaData(metaData);
  const keys = _.flatMap([tagObj.schoolType, tagObj.levels, tagObj.years]);
  return keys;
}

export default MetaDataTagList;
