import { tracker } from '@lessonup/analytics';
import React, { Fragment } from 'react';
import {
  NotificationModal,
  NotificationModalParams,
  NotificationModalResponse,
} from '../../shared-core/ui/components/modals';
import ChannelLeadModal, {
  ChannelLeadConfig,
  ChannelLeadResponse,
} from '../../shared-core/ui/components/modals/ChannelLeadModal/ChannelLeadModal';
import { ConfirmModal, ConfirmModalParams } from '../../shared-core/ui/components/modals/ConfirmModal/ConfirmModal';
import CookiePopup from '../../shared-core/ui/components/modals/CookiePopup';
import FollowChannelModal, {
  FollowChannelModalConfig,
} from '../../shared-core/ui/components/modals/FollowChannelModal/FollowChannelModal';
import PromptStringModal, {
  PromptStringModalParams,
  PromptStringModalResponse,
} from '../../shared-core/ui/components/modals/PromptStringModal';
import SchoolSuggestion, {
  SchoolSuggestionModalProps,
  SchoolSuggestionModalResponse,
} from '../../shared-core/ui/components/modals/SchoolSuggestion';
import ProRequiredModal, {
  ProRequiredModalProps,
  ProRequiredModalResponse,
} from '../../shared-core/ui/components/modals/upsellModals/ProRequiredModal/ProRequiredModal';
import { AppModalManagerService } from '../../shared-core/ui/utils/ModalManagerService';
import { assertNever } from '../../shared-core/utils';
import LoginModal, { LoginModalConfig } from '../components/login/LoginModal';
import {
  LocationPickerModalProvider,
  LocationPickerModalProviderParams,
  LocationPickerModalProviderResponse,
} from '../components/modals';
import FlagLessonModal, { FlagLessonModalConfig } from '../components/modals/FlagLessonModal/FlagLessonModal';
import { FollowChannelModalResponse } from '../components/modals/FollowChannelModal/FollowChannelModal';
import ForgotPasswordModal from '../components/modals/ForgotPasswordModal/ForgotPasswordModal';
import { LessonsSyncedModal } from '../components/modals/LessonsSyncedModal';
import LinkModal, { LinkModalConfig } from '../components/modals/LinkModal/LinkModal';
import LockedFolderModal, { LockedFolderModalProps } from '../components/modals/LockedFolderModal/LockedFolderModal';
import LoginPromptModal, {
  LoginPromptConfig,
  LoginPromptModalResult,
} from '../components/modals/LoginPromptModal/LoginPromptModal';
import RegisterOrLoginModal, {
  RegisterOrLoginModalConfig,
} from '../components/modals/RegisterOrLoginModal/RegisterOrLoginModal';
import ShareLessonModal, { ShareLessonModalConfig } from '../components/modals/ShareLessonModal/ShareLessonModal';

type ModalsWithConfig =
  | 'login'
  | 'confirm'
  | 'shareLesson'
  | 'locationPicker'
  | 'promptString'
  | 'shareLesson'
  | 'flagLesson'
  | 'requestFollowChannel'
  | 'flagLesson'
  | 'link'
  | 'lockedFolder'
  | 'proRequired'
  | 'channelLead'
  | 'loginPrompt'
  | 'notification'
  | 'schoolSuggestions'
  | 'registerOrLogin';

type ModalsWithoutConfig = 'lessonSynced' | 'forgotPassword' | 'cookiePreferences';
type AllModals = ModalsWithConfig | ModalsWithoutConfig;

type ModalOptions<T extends AllModals> = T extends 'login'
  ? LoginModalConfig
  : T extends 'confirm'
  ? ConfirmModalParams
  : T extends 'locationPicker'
  ? LocationPickerModalProviderParams
  : T extends 'promptString'
  ? PromptStringModalParams
  : T extends 'shareLesson'
  ? ShareLessonModalConfig
  : T extends 'flagLesson'
  ? FlagLessonModalConfig
  : T extends 'requestFollowChannel'
  ? FollowChannelModalConfig
  : T extends 'link'
  ? LinkModalConfig
  : T extends 'lockedFolder'
  ? LockedFolderModalProps
  : T extends 'proRequired'
  ? ProRequiredModalProps
  : T extends 'channelLead'
  ? ChannelLeadConfig
  : T extends 'loginPrompt'
  ? LoginPromptConfig
  : T extends 'notification'
  ? NotificationModalParams
  : T extends 'schoolSuggestions'
  ? SchoolSuggestionModalProps
  : T extends 'registerOrLogin'
  ? RegisterOrLoginModalConfig
  : never;

type ModalResponse<T extends AllModals> = T extends 'locationPicker'
  ? LocationPickerModalProviderResponse
  : T extends 'promptString'
  ? PromptStringModalResponse
  : T extends 'loginPrompt'
  ? LoginPromptModalResult
  : T extends 'proRequired'
  ? ProRequiredModalResponse
  : T extends 'channelLead'
  ? ChannelLeadResponse
  : T extends 'requestFollowChannel'
  ? FollowChannelModalResponse
  : T extends 'notification'
  ? NotificationModalResponse
  : T extends 'schoolSuggestions'
  ? SchoolSuggestionModalResponse
  : any;

export class SearchAppModalManagerService extends AppModalManagerService {
  public open<T extends ModalsWithConfig, O extends ModalOptions<T>>(modal: T, options: O): Promise<ModalResponse<T>>;
  // open<T extends ModalsWithOptionalConfig, O extends ModalOptions<T>>(modal: T, options?: O): Promise<any>;
  public open<T extends ModalsWithoutConfig>(modal: T): Promise<ModalResponse<T>>;
  public open<T extends AllModals>(modalIn: T, options?: ModalOptions<T>): Promise<ModalResponse<T>> {
    // why typescript?
    const modal: AllModals = modalIn;
    type Resp = ModalResponse<T>;

    return this.setModal<Resp>((closeFn: (result) => undefined) => {
      const defaultModalProps = {
        closeModal: closeFn,
        key: modal,
      };

      switch (modal) {
        case 'login':
          return <LoginModal {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        case 'lessonSynced':
          return <LessonsSyncedModal {...defaultModalProps} />;
        case 'confirm':
          return <ConfirmModal {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        case 'locationPicker':
          return <LocationPickerModalProvider {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        case 'promptString':
          return <PromptStringModal {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        case 'shareLesson':
          return <ShareLessonModal {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        case 'flagLesson':
          return <FlagLessonModal {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        case 'requestFollowChannel':
          return <FollowChannelModal {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        case 'forgotPassword':
          return <ForgotPasswordModal {...defaultModalProps} />;
        case 'link':
          return <LinkModal {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        case 'lockedFolder':
          return <LockedFolderModal {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        case 'loginPrompt':
          return <LoginPromptModal {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        case 'proRequired':
          return <ProRequiredModal {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        case 'channelLead':
          return <ChannelLeadModal {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        case 'cookiePreferences':
          return (
            <CookiePopup
              show={tracker.requiresCookieConsent()}
              onAccept={() => tracker.setCookieSettings({ analytic: true, marketing: true })}
            />
          );
        case 'notification':
          return <NotificationModal {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        case 'schoolSuggestions':
          return <SchoolSuggestion {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        case 'registerOrLogin':
          return <RegisterOrLoginModal {...(options as ModalOptions<typeof modal>)} {...defaultModalProps} />;
        default:
          assertNever(modal, 'SearchAppModalManagerService');
      }
      return <Fragment />;
    });
  }
}

export const searchAppModalManagerService = new SearchAppModalManagerService();
