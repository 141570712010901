import { AppError } from '@lessonup/utils';
import { stringify } from 'qs';
import { wait } from '../../../utils';
import { JwtTokenService } from '../auth/JwtTokenService';

export class HttpApiService {
  protected debugging = false;
  protected delayMS = 0;

  public constructor(protected readonly host: string, protected readonly tokenService: JwtTokenService) {}

  protected async get<Params, Result>(path: string, params?: Params): Promise<Result> {
    const url = this.host === '/' ? path : `${this.host}${path}`;
    const queryString = stringify(params || ({} as any));
    const fullUrl = `${url}?${queryString}`;

    return this.request(fullUrl, 'GET');
  }

  protected async post<Params, Result>(path: string, params?: Params): Promise<Result> {
    const url = this.host === '/' ? path : `${this.host}${path}`;
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify(params);

    return this.request(url, 'POST', { headers, body });
  }

  private async request<Result>(
    url: string,
    method: 'GET' | 'POST',
    {
      headers,
      body,
    }: {
      headers?: HeadersInit;
      body?: string;
    } = {}
  ): Promise<Result> {
    if (this.debugging) {
      console.info(`api call get ${url}, delayMS: ${this.delayMS}`, { body });
      console.time(`api ${url}`);
    }

    const response = await fetch(url, {
      method,
      headers,
      body,
    });

    if (this.delayMS) await wait(this.delayMS);

    let decoded;
    try {
      decoded = await response.json();
    } catch (error) {}

    if (!response.ok) {
      if (decoded?.isAppError) {
        throw new AppError(decoded.code, decoded.message, { response, url, method, headers, body });
      }

      throw new AppError('unexpected-data', 'Request returned a not-OK response', {
        response,
        url,
        method,
        headers,
        body,
      });
    }

    if (!decoded) throw new AppError('unexpected-data', 'Unable to read JSON returned from server', { response });

    if (this.debugging) {
      console.info(`api call ${url} result`, decoded);
      console.timeEnd(`api ${url}`);
    }

    return decoded;
  }
}
