import { PinItem, Slide } from '@lessonup/teaching-core';
import React from 'react';
import { sanitizeContent } from '../../../../utils';
import { ContentTypeDefaults } from '../../../../utils/ContentTypeDefaults';

interface Props {
  type: 'text1' | 'text2';
  item: PinItem<Slide.Content>;
}

export default class Text extends React.Component<Props> {
  private processContent = (content: string): string => {
    // Normalize newlines and other formatting
    const sanitizedContent = sanitizeContent(content);
    return sanitizedContent.replace(/\r\n|\r|\n/g, '<br />');
  };

  private getClasses = (): string => {
    const { align1, align2, font1, font2 } = this.props.item.custom;
    const { type } = this.props;
    const align = type === 'text1' ? align1 : align2;
    const fontSize = `font-size-${this.getFontSize(type)}`;
    const font = type === 'text1' ? font1 : font2;

    return `text text-comp ${this.renderText1AsText2() ? 'text2' : type} text-align-${align} ${fontSize} font-${font}`;
  };

  private getFontSize = (type: string): string => {
    const pinType = this.props.item.type;
    let fontSize: string | number | undefined;

    if (type === 'text1') {
      fontSize = this.props.item.custom.fontSize1 || ContentTypeDefaults.getDefaultFontSize(1, pinType);
    } else {
      fontSize = this.props.item.custom.fontSize2 || ContentTypeDefaults.getDefaultFontSize(2, pinType);
    }

    return fontSize ? fontSize.toString() : '';
  };

  private renderText1AsText2 = (): boolean => {
    const layout = this.props.item.custom.layout;
    return layout !== undefined && [7, 8].includes(layout);
  };

  public render() {
    const { type, item } = this.props;
    const rawHtml = item.custom[type];

    if (!rawHtml) {
      return null;
    }

    const processedHtml = this.processContent(rawHtml);

    return (
      <div id={type} className={this.getClasses()}>
        <div className="text-inner" dangerouslySetInnerHTML={{ __html: processedHtml }}></div>
      </div>
    );
  }
}
