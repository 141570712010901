import { tracker } from '@lessonup/analytics';
import { ID } from '@lessonup/teaching-core';
import { AppError } from '@lessonup/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { logger } from '@lessonup/client-integration';
import { Api } from '../../../../../shared-core/domain/api/LessonupApi';
import { schoolExplanationArticle } from '../../../../../shared-core/services/app/siteRoutes';
import Button from '../../../../../shared-core/ui/components/Button';
import { Card } from '../../../../../shared-core/ui/components/Cards/Card';
import LinkButton from '../../../../../shared-core/ui/components/links/LinkButton';
import { useBEM } from '../../../../../shared-core/ui/utils/hooks';
import { assertNever } from '../../../../../shared-core/utils';
import { useAppServices } from '../../../../components/appServices/AppServicesContext';
import { loggedInUser } from '../../../../redux/selectors';
import { RegistrationFormProps } from '../../types';
import { useRegistrationContext } from '../../utils/RegistrationContext';
import { CardContentForSuggestion } from './CardContentForSuggestion/CardContentForSuggestion';
import './RegistrationSuggestionsPage.less';

type Props = RegistrationFormProps;
export type ResponseStatus = 'error' | 'success' | 'isSuper';

interface SuggestionRequestResponse {
  organizationId: string;
  resultStatus: ResponseStatus;
}

export const REGISTRATION_SUGGESTIONS_TRANSLATION_NAMESPACE = 'registrationSuggestionsPage';

export const RegistrationSuggestionsPage: React.FC<Props> = (props) => {
  const bemClasses = useBEM('RegistrationSuggestionsPage');
  const { t } = useTranslation(REGISTRATION_SUGGESTIONS_TRANSLATION_NAMESPACE);
  const { domainRuleSuggestions } = useRegistrationContext();
  const services = useAppServices();
  const user = useSelector(loggedInUser());
  const { setPickChildSource } = useRegistrationContext();

  const organizationMatchSuggestedTracked = useRef<boolean>(false);

  useEffect(() => {
    if (organizationMatchSuggestedTracked.current) {
      return;
    }
    const suggestionsIds = domainRuleSuggestions.map((suggestion) => suggestion.organizationId);
    tracker.events.organizationMatchSuggested({
      organizationIdList: suggestionsIds,
      organizationMatchMethod: 'email_domain',
    });
    organizationMatchSuggestedTracked.current = true;
  }, [domainRuleSuggestions]);

  const [responseForSuggestions, setResponseForSuggestions] = useState<SuggestionRequestResponse[]>([]);

  const cardButtonTheme = domainRuleSuggestions.length === 1 ? 'secondary' : 'quaternary';

  const requestToJoin = async (organizationId: ID) => {
    try {
      const { success, reason } = await services.user.sendOrganizationJoinRequest(organizationId);

      if (success) {
        handleRequestResponse({ organizationId, resultStatus: 'success' });
        tracker.events.teacherRequestToJoinOrganizationSent({
          organizationId,
          requestToJoinMethod: 'email_domain_suggestion',
        });
      }

      handleUnsuccessfulJoin(organizationId, reason);
    } catch (error) {
      logger.error(error);
      handleRequestResponse({ organizationId, resultStatus: 'error' });
    }
  };

  function handleUnsuccessfulJoin(organizationId: string, reason?: Api.SendJoinRequestResponse['reason']) {
    if (!reason) return;

    switch (reason) {
      case 'isSuper':
        handleRequestResponse({ organizationId, resultStatus: 'isSuper' });
        break;
      case 'notFound':
        throw new AppError('not-found', 'Organization not found');
      case 'emailAddressInvalid':
        throw new AppError('invalid-params', 'Not allowed to join organization with this email address');
      default:
        assertNever(reason, 'Did not handle reason from RegistrationSuggestionsPage');
    }
  }

  const onCreateAccount = () => {
    // We assume domains aren't shared between supers.
    // If they are, we're fine with only adding the user to the first domain.
    const redirectToChildPicker = responseForSuggestions.find(
      (response) => response.resultStatus === 'isSuper'
    )?.organizationId;
    if (redirectToChildPicker) {
      setPickChildSource('email_domain_suggestion');
      return props.goToStep('pick-child', redirectToChildPicker);
    }

    props.next();
  };

  const handleRequestResponse = ({
    organizationId,
    resultStatus,
  }: {
    organizationId: string;
    resultStatus: ResponseStatus;
  }) => {
    const requestResponse: SuggestionRequestResponse = {
      organizationId,
      resultStatus,
    };
    setResponseForSuggestions([requestResponse, ...responseForSuggestions]);
  };

  const requestResultStatusByOrganizationId = (organizationId: string): ResponseStatus | undefined => {
    if (user?.organizations.find((organization) => organization.id === organizationId)) {
      return 'success';
    }

    return responseForSuggestions.find((response) => response.organizationId === organizationId)?.resultStatus;
  };

  const isLastItemInSuggestions = (index: number) => {
    return index === domainRuleSuggestions.length - 1;
  };

  return (
    <div className={bemClasses({})}>
      <h1 className={bemClasses({ element: 'title' })}>{t('teamUpWithColleagues')}</h1>
      <div className={bemClasses({ element: 'description' })}>
        <p>{t('registrationSuggestionsDescription')}</p>
        <Link to={schoolExplanationArticle()} target="_blank">
          {t('registrationSuggestionsDescriptionLink')}
        </Link>
      </div>
      <div className={bemClasses({ element: 'cards-container' })}>
        <Card>
          {domainRuleSuggestions.map((suggestion, index) => {
            const requestResult = requestResultStatusByOrganizationId(suggestion.organizationId);
            return (
              <React.Fragment key={suggestion.organizationId}>
                <CardContentForSuggestion
                  suggestion={suggestion}
                  requestResult={requestResult}
                  handleRequestToJoin={requestToJoin}
                  cardButtonTheme={cardButtonTheme}
                />
                {!isLastItemInSuggestions(index) && <div className={bemClasses({ element: 'divider' })} />}
              </React.Fragment>
            );
          })}
        </Card>
      </div>

      <Button
        height="medium"
        theme="secondary"
        version="modern"
        disabled={!responseForSuggestions.length && !user?.organizations.length}
        className={bemClasses({ element: 'create-account-button' })}
        onClick={onCreateAccount}
      >
        {t('createAccount')}
      </Button>
      <LinkButton
        bgColor="transparent"
        className={bemClasses({ element: 'skip-button' })}
        onClick={() => props.goToStep('organization')}
        text={t('skipThisStep')}
        underline
        big={false}
        height="small"
      />
      <p className={bemClasses({ element: 'subtext' })}>{t('subtextSuggestionsPage')}</p>
    </div>
  );
};
