import { AssignmentPin, LessonPin } from '@lessonup/teaching-core';
import React from 'react';

import { Poll } from '@lessonup/teaching-core';
import RealtimePollEmojis from '../PollEmojis/RealtimePollEmojis';
import RealtimePollMultipleChoice from '../PollMultipleChoice/RealtimePollMultipleChoice';
import RealtimePollScale from '../PollScale/RealtimePollScale';
import './PollContent.less';

interface Props {
  pin: LessonPin<Poll.Content> | AssignmentPin.Poll;
  isThumb?: boolean;
  handleSubmitAnswer?: (answer: number) => Promise<void>;
  pollAnswer?: number;
  answerError?: string;
}

const PollContent: React.FC<Props> = (props) => {
  const { custom } = props.pin.item;
  const { pollAnswer } = props;

  function contentComponent() {
    switch (custom.answerType) {
      case 'emoji':
        return (
          <RealtimePollEmojis pollItem={custom} pollAnswer={pollAnswer} handleSubmitAnswer={props.handleSubmitAnswer} />
        );
      case 'multipleChoice':
        return (
          <RealtimePollMultipleChoice
            pinContent={custom}
            pollAnswer={pollAnswer}
            handleSubmitAnswer={props.handleSubmitAnswer}
            teacherViewClass={true}
          />
        );
      case 'scale':
        return (
          <RealtimePollScale
            isThumb={props.isThumb}
            pinContent={custom}
            pollAnswer={pollAnswer}
            handleSubmitAnswer={props.handleSubmitAnswer}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className="poll-content">
      {props.answerError ? <div className="input-error">{props.answerError}</div> : null}
      {contentComponent()}
    </div>
  );
};

export default PollContent;
