import {
  AnswerBook,
  AnyPinEntry,
  Assignment,
  AssignmentEntry,
  AssignmentGradeModelSettings,
  AssignmentMeta,
  AssignmentPin,
  AssignmentPinContent,
  AssignmentType,
  AssignmentUtils,
  Component,
  DragQuestion,
  MongoGroup,
  OpenQuestion,
  Pin,
  PinEntry,
  PinType,
  Poll,
  PollResults,
  QuizQuestion,
  Slide,
  UserReference,
  UserReferenceAndPinEntry,
  WordSummary,
  Wordweb,
  WordwebResults,
} from '@lessonup/teaching-core';
import { AppError } from '@lessonup/utils';
import _ from 'lodash';
import { I18n } from '../../utils';
import { UserReferenceBuilder } from '../user';

declare const Meteor: any, i18n: I18n;

export namespace GenerateAssignmentReport {
  //what goes in
  export interface Params {
    assignment: Assignment;
    meta: AssignmentMeta;
    entries: AssignmentEntry[];
    showHiddenUsers?: boolean;
    group?: MongoGroup;
    gradingSystem?: string; // TODO enum;
    sortNameKey: 'displayName' | 'familyName';
  }

  //does come out
  export interface Response {
    assignment: Assignment;
    meta: AssignmentMeta;
    group?: MongoGroup;
    pins: AnyPinResult[];
    pinDict: PinResults;
    userDict: Users;
    users: User[];
    hasHiddenUsers: boolean;
    hiddenCount: {
      notInGroup: number;
      kicked: number;
    };
    totalNeedReviseCount?: number;
    totalReviseCount?: number;
  }

  export interface User extends UserReference {
    fullName: string;
    entry: AssignmentEntry;
    notInGroup: boolean;
    totalTime: number;
    seen: number;
    doneCount: number;
    totalQuiz: number;
    calcGrade: number;
    finalGrade: number | string | undefined;
    totalPoints: number;
    loginsDuringTest: number;
    correctNum: number;
    incorrectNum: number;
    otherNum: number;
    emptyNum: number;
    incorrect: string[];
    correct: string[];
    other: string[];
    empty: string[];
    done: string[];
    partialCorrect: string[];
  }

  export interface Users {
    [key: string]: User;
  }

  export type UserListsKeys = 'seen' | 'done' | 'empty' | 'correct' | 'incorrect' | 'partialCorrect';
  export const userListKeys: UserListsKeys[] = ['seen', 'done', 'empty', 'correct', 'incorrect', 'partialCorrect'];

  export interface PinResult {
    _id: string;
    originalPin: Pin<AssignmentPinContent>;
    num: number;
    type: PinType;
    components: Component.All[];
    name?: string;
    // for VideoThumbs
    image?: string;
    images?: string[];
    source?: string;
    hasAnswer: boolean;
    // custom: AssignmentPinContent;
    // uploadId?: number;
    url?: string;
    // shuffleMap?: any;
    videoItems?: any[] | undefined;
    points: number;
    reviseCount: number;
    needReviseCount: number;
    diff?: string;
    seen: string[];
    done: string[];
    empty: string[];
    correct: string[];
    incorrect: string[];
    partialCorrect: string[];
    avrTime: number;
    videoQuestion?: string;
    colorClass: string | undefined;
    // item: {
    //   videoItems?: any[] | undefined;
    //   image?: string;
    //   images?: string[];
    // }
  }
  export interface QuizQuestionPinResult extends PinResult {
    type: 'question';
    custom: QuizQuestion.AssignmentContent;
    correctAnswer: QuizQuestion.CorrectAnswer | undefined;
    correctAnswerLetter: string[] | undefined;
    distribution: {
      A: number;
      B: number;
      C: number;
      D: number;
    };
    distributionNames: {
      A: string[];
      B: string[];
      C: string[];
      D: string[];
    };
  }

  export interface OpenQuestionPinResult extends PinResult {
    type: 'openQuestion';
    custom: OpenQuestion.AssignmentContent;
    answer: OpenQuestion.CorrectAnswer | undefined;
    correctAnswer: OpenQuestion.CorrectAnswer | undefined;
    hasImages: boolean;
  }

  export interface ReportWordwebSummary extends WordSummary {
    stripped: string;
  }

  export interface WordwebPinResult extends PinResult {
    type: 'wordweb';
    custom: Wordweb.Content;
    answers: ReportWordwebSummary[];
  }

  export interface PollPinResult extends PinResult {
    type: 'poll';
    custom: Poll.Content;
    answers: Poll.Answer.StudentAnswer[];
    distribution: any[];
    users: UserReference[];
  }

  export interface SlidePinResult extends PinResult {
    type: 'slide';
    custom: Slide.Content;
  }

  interface DragQuestionUserAnswer {
    userId: string;
    answer: DragQuestion.Answer;
    result: DragQuestion.AnswerResult | undefined;
    components?: Component[]; //TODO remove from model, now only filled if we pass addViewData
  }

  export interface DragQuestionPinResult extends PinResult {
    type: 'dragQuestion';
    custom: DragQuestion.AssignmentContent;
    dragComponentsWithAnswer: number;
    correctAnswer: DragQuestion.CorrectAnswer | undefined;
  }

  type LeftOverPinTypes = 'map' | 'link' | 'video';

  export interface GenericPinResult extends PinResult {
    type: LeftOverPinTypes;
    custom: AssignmentPinContent;
  }

  interface PinResults {
    [key: string]: AnyPinResult;
  }

  export type AnyPinResult =
    | OpenQuestionPinResult
    | QuizQuestionPinResult
    | WordwebPinResult
    | DragQuestionPinResult
    | PollPinResult
    | SlidePinResult
    | GenericPinResult;

  interface GetPinResultsData {
    assignment: Assignment;
    meta: AssignmentMeta;
    answerBook: AnswerBook;
    users: Users;
    entries: AssignmentEntry[];
    mode: AssignmentType;
  }

  const REPORT_LOGGING = false;
  /*
    Generate is the main function of GenerateAssignmentReport, here all the functions are called to generate a report
  */
  export function generate({
    assignment,
    meta,
    entries,
    group,
    showHiddenUsers,
    gradingSystem,
    sortNameKey = 'displayName',
  }: Params): Response {
    if (!assignment || !entries || !meta) {
      throw new AppError('invalid-params');
    }

    if (REPORT_LOGGING) console.log('REPORT_LOGGING arguments', arguments);

    let sortedEntries = _.sortBy(entries, (entry) => entry[sortNameKey]?.toLowerCase());

    const mode = assignment.settings.type;
    // get a fillable list of all users
    const { users, kicked, notInGroup } = createUserDict(sortedEntries, group, showHiddenUsers);

    const hasHiddenUsers = Boolean(notInGroup + kicked);
    const hiddenCount = {
      kicked,
      notInGroup,
    };
    // filter hidden users from entries
    sortedEntries = sortedEntries.filter((e) => users[e.userId]);
    if (REPORT_LOGGING) console.log('REPORT_LOGGING entries', entries);

    const answerBook = new AnswerBook({ assignment, meta });
    const intermediateData: GetPinResultsData = {
      assignment,
      meta,
      answerBook,
      users,
      entries: sortedEntries,
      mode,
    };
    if (REPORT_LOGGING) console.log('REPORT_LOGGING answerBook', answerBook);

    const pinResults: PinResults = {};
    // Main function to generate rapport, this also mutates user
    AssignmentUtils.pinsByAssignmentType(assignment).forEach((pin, index) => {
      pinResults[pin._id] = getPinResult(pin, index, intermediateData);
    });

    if (Assignment.isTest(assignment)) {
      const gradingParams = assignment.settings.gradeModel?.params;
      setCurrentGradeOnUser(users, gradingSystem, gradingParams, assignment.testPoints || 0);
    }

    let pinList = _.map(pinResults, (pin) => pin);

    if (Assignment.isRealtime(assignment)) {
      pinList = pinList.filter((pin) => pin.seen.length > 0);
    }

    pinList.sort((a, b) => {
      if (a.num < b.num) return -1;
      if (a.num > b.num) return 1;
      return 0;
    });

    const userList = _.map(users, (user) => user);
    const response: Response = {
      assignment,
      meta,
      group,
      pins: pinList,
      pinDict: pinResults,
      userDict: users,
      users: userList,
      hasHiddenUsers,
      hiddenCount,
    };

    if (Assignment.isTest(assignment)) {
      const counts = getReviseCounts(pinList);
      response.totalNeedReviseCount = counts.need;
      response.totalReviseCount = counts.done;
    }
    if (REPORT_LOGGING) console.log('REPORT_LOGGING', response);
    return response;
  }

  function createUserDict(
    entries: AssignmentEntry[],
    group?: MongoGroup,
    showHiddenUsers = false
  ): { users: Users; kicked: number; notInGroup: number } {
    const users: Users = {};
    let kicked = 0;
    let notInGroup = 0;
    entries.forEach((entry) => {
      const student = UserReferenceBuilder.fromEntry(entry);
      const user: User = {
        ...student,
        entry,
        fullName: student.displayName || i18n.__('unknown'), // backwards compatible TODO translations
        totalTime: 0,
        seen: 0,
        calcGrade: 1,
        finalGrade: AssignmentEntry.getGrade(entry),
        totalPoints: 0,
        // probely not used:
        doneCount: 0,
        totalQuiz: 0,
        correctNum: 0,
        incorrectNum: 0,
        otherNum: 0,
        emptyNum: 0,
        // end probely not used
        incorrect: [],
        correct: [],
        partialCorrect: [],
        other: [],
        empty: [],
        done: [],
        notInGroup: false,
        loginsDuringTest: AssignmentEntry.loginCountDuringTest(entry),
      };

      let addUser = true;
      if (group && group.members && !group.members.includes(student._id)) {
        user.notInGroup = true;
        addUser = false;
        notInGroup += 1;
      }
      if (AssignmentEntry.isKicked(entry)) {
        addUser = false;
        kicked += 1;
      }

      if (addUser || showHiddenUsers) {
        users[student._id] = user;
      }
    });

    return {
      users,
      kicked,
      notInGroup,
    };
  }

  function getPinResult(
    pin: AssignmentPin<AssignmentPinContent>,
    index: number,
    data: GetPinResultsData
  ): AnyPinResult {
    const type = pin.item.type;
    const base: PinResult = getBasicPinResult(pin, index, data);
    let pr: AnyPinResult;

    if (REPORT_LOGGING) console.log('REPORT_LOGGING PR base', base);

    if (type == 'question') {
      pr = getQuizQuestionPinResult(pin as AssignmentPin<QuizQuestion.AssignmentContent>, base, data);
    } else if (type === 'openQuestion') {
      pr = getOpenQuestionPinResult(pin as AssignmentPin<OpenQuestion.AssignmentContent>, base, data);
    } else if (type === 'wordweb') {
      pr = getWordWebPinResult(pin as AssignmentPin<Wordweb.Content>, base, data);
    } else if (type === 'poll') {
      pr = getPollPinResult(pin as AssignmentPin<Poll.Content>, base, data);
    } else if (type === 'dragQuestion') {
      pr = getDragQuestionPinResult(pin as AssignmentPin<DragQuestion.AssignmentContent>, base, data);
    } else {
      pr = getGenericPinResult(pin, base, data);
    }
    return pr;
  }

  /*
    Shared helper functions for getpinresults
  */
  function getBasicPinResult(pin: AssignmentPin<AssignmentPinContent>, index, data: GetPinResultsData) {
    const colorClass =
      pin.diff &&
      Meteor.Lib.DiffShared &&
      Meteor.Lib.DiffShared.getColorClassByLabel({ diff: data.assignment.diff }, pin.diff);

    const type = pin.item.type;

    const pr: PinResult = {
      _id: pin._id,
      type,
      num: index + 1,
      source: pin.item.source,
      url: pin.item.url,
      image: pin.item.image,
      hasAnswer: false,
      // images: pin.item.images,
      components: pin.item.components || [],
      points: pin.item.testPoints || 0,
      reviseCount: 0,
      needReviseCount: 0,
      diff: pin.diff,
      seen: [],
      done: [],
      empty: [],
      correct: [],
      partialCorrect: [],
      incorrect: [],
      avrTime: 0,
      videoQuestion: pin.videoQuestion,
      // item: {
      //   videoItems: pin.item.videoItems,
      //   image: pin.item.image,
      //   // images: pin.item.images,
      // },
      colorClass,
      originalPin: pin,
    };

    return pr;
  }

  function getUser(data: GetPinResultsData, userId: string): User | undefined {
    return data.users[userId];
  }

  function addBasicMetrics(pinEntry: AnyPinEntry, pr: PinResult, user: User) {
    const secondsWatched = PinEntry.timeSpent(pinEntry);
    user.seen++;
    const prevTotal = pr.avrTime ? pr.avrTime * pr.seen.length : 0;
    pr.seen.push(user._id);
    user.totalTime += secondsWatched;
    pr.avrTime = (prevTotal + secondsWatched) / pr.seen.length;
  }

  type ListNames = 'done' | 'empty' | 'correct' | 'incorrect' | 'partialCorrect';

  function updateUserAndPR(key: ListNames, user: User, pr: AnyPinResult) {
    user[key].push(pr._id);
    pr[key].push(user._id);
  }

  function updateReviseCount(
    pin: AssignmentPin<AssignmentPinContent>,
    pr: AnyPinResult,
    pinEntry: AnyPinEntry,
    user: User
  ) {
    if (pin.item.testPoints) {
      pr.needReviseCount += 1;
      if (pinEntry.points != undefined) {
        pr.reviseCount += 1;
        user.totalPoints += pinEntry.points;
      }
    }
  }

  /*
    Test Specific
  */
  function getReviseCounts(pinResults: AnyPinResult[]) {
    const counts = pinResults.reduce(
      (mem, pr) => {
        if (pr.needReviseCount) {
          mem.need += pr.needReviseCount;
          mem.done += pr.reviseCount;
        }
        return mem;
      },
      { need: 0, done: 0 }
    );
    return counts;
  }

  function setCurrentGradeOnUser(
    users: Users,
    gradingSystem: string | undefined,
    gradingParams: AssignmentGradeModelSettings['params'] | undefined,
    totalPoint: number
  ) {
    const models = Meteor.Lib.Test.gradeModels;

    const model = gradingSystem && models[gradingSystem] ? models[gradingSystem] : models.default;

    _.each(users, (u) => {
      const grade = model.calc({
        total: totalPoint,
        points: u.totalPoints,
        extra: gradingParams,
      });
      // eslint-disable-next-line no-param-reassign
      u.calcGrade = grade;
    });
  }

  function handleAlreadyGradedQuestion(
    pr: AnyPinResult,
    pin: AssignmentPin,
    user: User,
    pinEntry: AnyPinEntry
  ): boolean {
    // if we have test points, just show the teacher result
    if (pinEntry.points !== undefined && pin.item.testPoints) {
      if (pinEntry.points == 0) {
        updateUserAndPR('incorrect', user, pr);
      } else if (pinEntry.points == pin.item.testPoints) {
        updateUserAndPR('correct', user, pr);
      } else {
        updateUserAndPR('partialCorrect', user, pr);
      }
      updateReviseCount(pin, pr, pinEntry, user);
      return true;
    }
    return false;
  }

  /*
   Content type specific
  */
  function getQuizQuestionPinResult(
    pin: AssignmentPin<QuizQuestion.AssignmentContent>,
    base: PinResult,
    data: GetPinResultsData
  ): QuizQuestionPinResult {
    const custom = pin.item.custom;
    const correctAnswer = data.answerBook.correctAnswerForPin(pin._id) as QuizQuestion.CorrectAnswer;
    const correctAnswerLetter = correctAnswer
      ? correctAnswer.map((l) => QuizQuestion.Content.getAnswerLetterByNumber(l))
      : undefined;

    const pr: QuizQuestionPinResult = {
      ...base,
      type: 'question',
      custom,
      correctAnswer,
      hasAnswer: Boolean(correctAnswer),
      correctAnswerLetter,
      distribution: {
        A: 0,
        B: 0,
        C: 0,
        D: 0,
      },
      distributionNames: {
        A: [],
        B: [],
        C: [],
        D: [],
      },
    };

    parseQuizQuestionPinResult(pin, pr, data);
    return pr;
  }

  function parseQuizQuestionPinResult(
    pin: AssignmentPin<QuizQuestion.AssignmentContent>,
    pr: QuizQuestionPinResult,
    data: GetPinResultsData
  ) {
    if (REPORT_LOGGING) console.log('REPORT_LOGGING parseQuizQuestionPinResult arguments', arguments);
    data.entries.forEach((entry) => {
      const user = getUser(data, entry.userId);
      if (!user) return;
      const pinEntry = AssignmentEntry.entryForPin(entry, pin._id) as PinEntry.QuizQuestion | undefined;
      if (!pinEntry) {
        pr.empty.push(user._id);
        user.empty.push(pr._id);
        return;
      }
      addBasicMetrics(pinEntry, pr, user);
      const result = data.answerBook.checkAnswer(pin._id, pinEntry.answer) as QuizQuestion.AnswerResult;
      pinEntry.result = result;
      if (result.type == 'noAnswer') {
        updateUserAndPR('empty', user, pr);
        pinEntry.points = 0;
        updateReviseCount(pin, pr, pinEntry, user);
        return;
      }

      const answerLetter = QuizQuestion.Content.getAnswerLetterByNumber(result.usedAnswer);

      pr.distribution[answerLetter]++;
      pr.distributionNames[answerLetter].push(user._id);
      user.totalQuiz++;
      if (result.type == 'opinion') {
        updateUserAndPR('done', user, pr);
        updateReviseCount(pin, pr, pinEntry, user);
        return;
      }
      if (result.type == 'correct') {
        updateUserAndPR('correct', user, pr);
        pinEntry.points = pr.points || 0;
      } else if (result.type == 'incorrect') {
        updateUserAndPR('incorrect', user, pr);
        pinEntry.points = 0;
      }
      updateReviseCount(pin, pr, pinEntry, user);
    });
  }

  function getOpenQuestionPinResult(
    pin: AssignmentPin<OpenQuestion.AssignmentContent>,
    base: PinResult,
    data: GetPinResultsData
  ): OpenQuestionPinResult {
    const custom = pin.item.custom;
    const answer = OpenQuestion.Answer.getAnswers(data.meta, pin._id);

    const hasAnswer = OpenQuestion.Answer.correctAnswerProvided(custom);
    const correctAnswer = hasAnswer
      ? (data.answerBook.correctAnswerForPin(pin._id) as OpenQuestion.CorrectAnswer)
      : undefined;

    const pr: OpenQuestionPinResult = {
      ...base,
      type: 'openQuestion',
      custom,
      hasAnswer,
      answer,
      correctAnswer,
      hasImages: custom.maximumNumberOfUploads > 0,
    };
    parseOpenQuestionPinResult(pin, pr, data);
    return pr;
  }

  function parseOpenQuestionPinResult(
    pin: AssignmentPin<OpenQuestion.AssignmentContent>,
    pr: OpenQuestionPinResult,
    data: GetPinResultsData
  ) {
    if (REPORT_LOGGING) console.log('REPORT_LOGGING parseOpenQuestionPinResult arguments', arguments);

    data.entries.forEach((entry) => {
      const user = getUser(data, entry.userId);
      if (!user) return;
      const pinEntry = AssignmentEntry.entryForPin(entry, pin._id) as PinEntry.OpenQuestion | undefined;
      const answer = OpenQuestion.Answer.getAnswerifHasTextsOrAttachment(pinEntry);

      if (!pinEntry || !answer) {
        updateUserAndPR('empty', user, pr);
        return;
      }

      addBasicMetrics(pinEntry, pr, user);
      const attachments = OpenQuestion.Answer.attachments(pinEntry.answer);
      const explanation = pin.item.custom.explanation;

      const handled = handleAlreadyGradedQuestion(pr, pin, user, pinEntry);
      if (handled) return;

      const hasAnswer = OpenQuestion.Answer.correctAnswerProvided(pin.item.custom);
      if (hasAnswer) {
        const result = data.answerBook.checkAnswer(pin._id, pinEntry.answer) as OpenQuestion.AnswerResult;
        pinEntry.result = result;
        if (result) {
          if (result.type == 'correct') {
            updateUserAndPR('correct', user, pr);
            pinEntry.points = pinEntry.points || pr.points;
          } else if (result.type == 'incorrect') {
            updateUserAndPR('incorrect', user, pr);
          } else {
            // answerbook gives back no answer wich is not true
            // TODO fix
            updateUserAndPR('incorrect', user, pr);
          }
        } else {
          console.warn('should not happen, check answer and no result', pinEntry.answer);
          updateUserAndPR('done', user, pr);
        }
      } else {
        updateUserAndPR('done', user, pr);
      }
      updateReviseCount(pin, pr, pinEntry, user);
    });
  }

  function getWordWebPinResult(
    pin: AssignmentPin<Wordweb.Content>,
    base: PinResult,
    data: GetPinResultsData
  ): WordwebPinResult {
    const pr: WordwebPinResult = {
      ...base,
      type: 'wordweb',
      custom: pin.item.custom,
      answers: [],
    };

    // TODO: map with sideeffects is dirty, improve
    const usersAndEntries: UserReferenceAndPinEntry<PinEntry.Wordweb>[] = _.compact(
      data.entries.map((entry) => {
        const user = getUser(data, entry.userId);
        if (!user) return;
        const pinEntry = AssignmentEntry.entryForPin(entry, pin._id) as PinEntry.Wordweb | undefined;
        if (!pinEntry || Wordweb.Answer.numberOfWords(pinEntry.answer) == 0) {
          updateUserAndPR('empty', user, pr);
          return;
        }

        updateUserAndPR('done', user, pr);
        addBasicMetrics(pinEntry, pr, user);

        return {
          user: UserReferenceBuilder.fromEntry(entry),
          pinEntry,
        };
      })
    );
    const res = WordwebResults.fromUserReferenceAndAnswer(usersAndEntries);
    pr.answers = res.words.map((w) => {
      return {
        ...w,
        stripped: w.word,
      };
    });

    return pr;
  }

  function getPollPinResult(pin: AssignmentPin<Poll.Content>, base: PinResult, data: GetPinResultsData): PollPinResult {
    const pr: PollPinResult = {
      ...base,
      type: 'poll',
      custom: pin.item.custom,
      answers: [],
      distribution: [],
      users: [],
    };
    const userWithEntries: UserReferenceAndPinEntry<PinEntry.Poll>[] = _.compact(
      data.entries.map((entry) => {
        const user = getUser(data, entry.userId);
        if (!user) return;

        const entryForPin = AssignmentEntry.entryForPin<PinEntry.Poll>(entry, pin._id);
        if (!entryForPin || !entryForPin.answer) {
          updateUserAndPR('empty', user, pr);
          return;
        }

        const pinEntry = {
          ...entryForPin,
          pinId: pin._id,
          type: 'poll' as const,
        };
        updateUserAndPR('done', user, pr);
        addBasicMetrics(pinEntry, pr, user);
        return {
          user,
          pinEntry,
        };
      })
    );
    const results = PollResults.fromUserReferenceAndEntries(userWithEntries, pin as AssignmentPin.Poll);

    return {
      ...pr,
      distribution: results.type === 'scale' ? [] : results.distribution,
      answers: results as any,
      users: results.users,
    };
  }

  function getDragQuestionPinResult(
    pin: AssignmentPin<DragQuestion.AssignmentContent>,
    base: PinResult,
    data: GetPinResultsData
  ): DragQuestionPinResult {
    const correctAnswer = data.answerBook.correctAnswerForPin<DragQuestion.CorrectAnswer>(pin._id);
    const dragComponentsWithAnswer = DragQuestion.Answer.totalNumberOfCorrect(correctAnswer);

    const pr: DragQuestionPinResult = {
      ...base,
      type: 'dragQuestion',
      custom: pin.item.custom,
      dragComponentsWithAnswer,
      hasAnswer: dragComponentsWithAnswer > 0,
      correctAnswer,
    };
    parseDragQuestionPinResult(pin, pr, data);
    return pr;
  }

  function parseDragQuestionPinResult(
    pin: AssignmentPin<DragQuestion.AssignmentContent>,
    pr: DragQuestionPinResult,
    data: GetPinResultsData
  ) {
    data.entries.forEach((entry) => {
      const user = getUser(data, entry.userId);
      if (!user) return;
      const pinEntry = AssignmentEntry.entryForPin(entry, pin._id) as PinEntry.DragQuestion | undefined;
      if (!pinEntry || !pinEntry.answer || _.isEmpty(pinEntry.answer.components)) {
        updateUserAndPR('empty', user, pr);
        return;
      }

      addBasicMetrics(pinEntry, pr, user);

      const result = data.answerBook.checkAnswer(pin._id, pinEntry.answer) as DragQuestion.AnswerResult | undefined;
      pinEntry.result = result;

      const handled = handleAlreadyGradedQuestion(pr, pin, user, pinEntry);
      if (handled) return;

      if (!DragQuestion.Answer.hasCorrectAnswer(pin.item.custom)) {
        updateUserAndPR('done', user, pr);
        return;
      }

      if (!result) return; // for typescript

      if (result.type == 'correct') {
        updateUserAndPR('correct', user, pr);
        pinEntry.points = pinEntry.points || pr.points;
        // something with points
      } else if (result.type == 'incorrect') {
        updateUserAndPR('incorrect', user, pr);
      } else if (result.type == 'partialCorrect') {
        updateUserAndPR('partialCorrect', user, pr);
      }
      updateReviseCount(pin, pr, pinEntry, user);
    });
  }

  function getGenericPinResult(
    pin: AssignmentPin<AssignmentPinContent>,
    base: PinResult,
    data: GetPinResultsData
  ): GenericPinResult {
    const type = pin.item.type as LeftOverPinTypes;
    const pr: GenericPinResult = {
      ...base,
      type,
      custom: pin.item.custom,
    };
    data.entries.forEach((entry) => {
      const user = getUser(data, entry.userId);
      if (!user) return;
      const pinEntry = AssignmentEntry.entryForPin(entry, pin._id) as AnyPinEntry | undefined;

      if (!pinEntry || !pinEntry.seen) {
        updateUserAndPR('empty', user, pr);
        return;
      }
      updateUserAndPR('done', user, pr);
      addBasicMetrics(pinEntry, pr, user);

      return pr;
    });
    return pr;
  }
}
