import React from 'react';

const PlayIcon = (props) => (
  <svg {...props} width="20px" height="20px" viewBox="0 0 32 32">
    <title>{'play3'}</title>
    <path d="M6 4l20 12L6 28z" />
  </svg>
);

export default PlayIcon;
