import { tracker } from '@lessonup/analytics';
import { RegistrationVerificationExpired } from '@lessonup/teacher-modern';
import React from 'react';
import { useOnMountEffect } from '../../../../shared-core/ui/utils/hooks/useOnMountEffect';
import { PageWrapper } from '../../../components/PageWrapper/PageWrapper';
import { RegistrationFormProps } from '../types';

type RegistrationVerificationLinkExpiredServiceWrapperProps = Pick<RegistrationFormProps, 'next'>;

export const RegistrationVerificationLinkExpiredServiceWrapper = (
  props: RegistrationVerificationLinkExpiredServiceWrapperProps
) => {
  const onRequestNewVerificationLink = () => {
    props.next();
  };

  useOnMountEffect(() => {
    tracker.events.registrationVerificationLinkExpired();
  });

  return (
    <PageWrapper>
      <RegistrationVerificationExpired onCTAClick={onRequestNewVerificationLink} />
    </PageWrapper>
  );
};
