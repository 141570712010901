import classNames from 'classnames';
import { TFunction } from 'i18next';
import { isEmpty, orderBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ChannelDetails, LanguageSingleton } from '../../../shared-core/domain';
import { Channel, ChannelTab, ChannelTitle, isDefaultChannelTab } from '../../../shared-core/domain/channels/Channel';
import { PublishContentExplorer, tryGetFolderFromExplorer } from '../../../shared-core/domain/newExplorers';
import { channelRoute } from '../../../shared-core/services/app/searchRoutes';
import { AnchorButton } from '../../../shared-core/ui/components/Button';
import capitalize from '../../../shared-core/utils/capitalize';
import { ImageUtils } from '../../../shared-core/utils/ImageUtils';
import transformServingImage from '../../../shared-core/utils/transformServingImage';
import FollowChannelButton from '../../components/buttons/FollowChannelButton/FollowChannelButton';
import { LessonPlanState } from '../../redux/reducers/lessonPlanReducer';
import { LessonState } from '../../redux/reducers/lessonReducer';
import { lessonPlanDetails, lessonStore, shouldShowPlansTab } from '../../redux/selectors';
import { ChannelAppService } from '../../services/appServices/ChannelAppService';
import { htmlDecode } from '../../utils/htmlDecode';
import { showTabsForLanguage } from '../../utils/tabs';
import ErrorPageView from '../error';
import './ChannelPage.less';
import ChannelPageMeta from './ChannelPageMeta/ChannelPageMeta';
import ChannelTabsList, { ChannelTabItem } from './ChannelTabsList';
import ChannelContentPage from './subpage/ChannelContentPage';
import ChannelCurriculaPage from './subpage/ChannelCurriculaPage';
import ChannelFolderPage from './subpage/ChannelFolderPage';
import ChannelLessonPage from './subpage/ChannelLessonPage';
import ChannelPlanPage from './subpage/ChannelPlanPage';
import ChannelSearchPage from './subpage/ChannelSearchPage';

const TRANSLATION_KEY = 'channel';

export interface ActiveChannelTab {
  channelPage: string;
  selectionId: string | undefined;
  subSelectionId?: string | undefined;
}

type ChangeTab = (newTab: ActiveChannelTab, replace?: boolean) => void;

export type ChannelTabWithActive = ChannelTab & {
  isActive: boolean;
};

interface Props {
  details: ChannelDetails;
  channelService: ChannelAppService;
  userIsLoggedIn: boolean;
  activeTab: ActiveChannelTab;
  changeTab: ChangeTab;
}

const ChannelPageView: React.FC<Props> = ({ details, userIsLoggedIn, activeTab, channelService, changeTab }) => {
  const { t } = useTranslation(TRANSLATION_KEY);
  const showPlansTab = useSelector(shouldShowPlansTab());
  const plans = useSelector(lessonPlanDetails());
  const lessons = useSelector(lessonStore());

  const { channel, publicFolder } = details;

  const channelTabs = useMemo(() => {
    const tabs = getChannelTabs({ channel, publicFolder, translator: t });

    return tabs.map<ChannelTabWithActive>((tab) => ({
      ...tab,
      isActive: isChannelTabActive({ tab, activeTab, channel, publicFolder, plans, lessons }),
    }));
  }, [activeTab, channel, publicFolder, plans, lessons]);

  return (
    <div className="channel-container">
      <ChannelHeader details={details} userIsLoggedIn={userIsLoggedIn} channelTabs={channelTabs} />
      <SubPage
        activeTab={activeTab}
        details={details}
        channelService={channelService}
        changeTab={changeTab}
        showTabs={showPlansTab && showTabsForLanguage()}
      />
      <ChannelPageMeta
        channelTitle={details.channel.title}
        channelSubtitle={details.channel.subtitle}
        page={activeTab.channelPage}
        channelIcon={details.channel.icon}
        subPage={activeTab.channelPage || 'start'}
        channelTabs={channelTabs}
      />
    </div>
  );
};

export interface ChannelSubPageProps {
  details: ChannelDetails;
  activeTab: ActiveChannelTab;
  channelService: ChannelAppService;
  changeTab: ChangeTab;
  showTabs?: boolean;
}

const SubPage: React.FC<ChannelSubPageProps> = (props) => {
  const { selectionId, channelPage } = props.activeTab;
  const { details } = props;

  const tab = channelPage || 'start';

  if (tab === 'lesson' && selectionId) {
    return <ChannelLessonPage {...props} />;
  } else if (tab === 'plan' && selectionId) {
    return <ChannelPlanPage {...props} />;
  }

  if (!details.channel.showSeries) {
    return <ChannelSearchPage {...props} />;
  }

  if (['start', 'about'].includes(tab)) {
    return <ChannelContentPage {...props} />;
  } else if (['series'].includes(tab)) {
    return <ChannelFolderPage {...props} />;
  } else if (tab === 'curricula') {
    return <ChannelCurriculaPage {...props} />;
  } else if (tab === 'search') {
    return <ChannelSearchPage {...props} />;
  }
  return (
    <ErrorPageView
      type="page"
      backUrl={channelRoute({
        channelSlug: props.details.channel.name,
      })}
    />
  );
};

export default ChannelPageView;

interface ChannelHeaderProps {
  details: ChannelDetails;
  userIsLoggedIn: boolean;
  coverImageOverride?: string;
  channelTabs: ChannelTabWithActive[];
}

const ChannelHeader: React.FC<ChannelHeaderProps> = ({ details, coverImageOverride, userIsLoggedIn, channelTabs }) => {
  const { channel, publicFolder } = details;
  const iconStyle = {
    backgroundImage: channel.icon ? ImageUtils.urlString(transformServingImage(channel.icon, { size: 150 })) : 'none',
  };

  return (
    <div className="chan-header">
      <div
        className={classNames('visual', { 'folder-visual': !!coverImageOverride })}
        style={channelBannerStyle(channel, coverImageOverride)}
      ></div>
      <div className="glasspane"></div>
      <div className="channel-icon-and-name">
        <div className="header-icon" style={iconStyle}></div>
        <ChannelNameDescription channel={channel} userIsLoggedIn={userIsLoggedIn} />
      </div>
      <ChannelTabs tabs={channelTabs} channel={channel} />
    </div>
  );
};

function channelBannerStyle(channel: Channel, coverImageOverride?: string): React.CSSProperties {
  const backgroundImage = coverImageOverride
    ? ImageUtils.urlString(transformServingImage(coverImageOverride, { size: 1280 }))
    : channel.cover
    ? ImageUtils.urlString(transformServingImage(channel.cover, { size: 1280 }))
    : null;

  return {
    backgroundImage: backgroundImage ? backgroundImage : 'none',
    backgroundPosition: `center ${channel.coverFocus || 50}%`,
  };
}

const ChannelNameDescription: React.FC<{
  channel: Channel;
  userIsLoggedIn: boolean;
}> = ({ channel, userIsLoggedIn }) => {
  const { t } = useTranslation(TRANSLATION_KEY);

  const localizedAndDecodedChannelTitle = htmlDecode(
    ChannelTitle.localizeTitle(channel.title, LanguageSingleton.get())
  );
  const localizedAndDecodedChannelSubTitle = htmlDecode(
    ChannelTitle.localizeTitle(channel.subtitle, LanguageSingleton.get())
  );

  return (
    <div className="channel-name-description">
      <div className="channel-name">{localizedAndDecodedChannelTitle}</div>
      {channel.subtitle && <div className="subtitle">{localizedAndDecodedChannelSubTitle}</div>}
      <div className="buttonbar">
        {userIsLoggedIn && (
          <FollowChannelButton
            channelTitle={localizedAndDecodedChannelTitle}
            requireFollow={Channel.followRequired(channel)}
            channelSlug={channel.name}
            followContext={{ trigger: 'channel-page' }}
            className="button-margin-right"
          />
        )}
        {channel.website && (
          <AnchorButton
            href={channel.website}
            target="_blank"
            className="icon icon-dark icon-external"
            theme="secondary"
            height="small"
          >
            {capitalize(t('visitWebsite'))}
          </AnchorButton>
        )}
      </div>
    </div>
  );
};

type ChannelTabsProps = {
  tabs: ChannelTabWithActive[];
  channel: Channel;
};

const ChannelTabs: React.FC<ChannelTabsProps> = ({ tabs, channel }) => {
  if (!tabs.length) {
    return null;
  }

  return (
    <ChannelTabsList channel={channel}>
      {tabs.map((tab) => (
        <ChannelTabItem tab={tab} active={tab.isActive} key={tab.id} channelName={channel.name} />
      ))}
    </ChannelTabsList>
  );
};

type GetChannelTabsArgs = {
  translator: TFunction;
  channel: Channel;
  publicFolder: PublishContentExplorer | undefined;
};

function getChannelTabs({ translator, channel, publicFolder }: GetChannelTabsArgs): ChannelTab[] {
  if (!channel.showSeries) {
    return [];
  }

  // Note: order of defaultTabs is relevant.
  const defaultTabs: ChannelTab[] = [
    {
      id: 'start',
      name: translator('start'),
      path: '/start',
    },
    {
      id: 'search',
      name: translator('search'),
      path: '/search',
    },
    {
      id: 'about',
      name: translator('about'),
      path: '/about',
    },
    {
      id: 'curricula',
      name: translator('curricula'),
      path: '/curricula',
    },
  ].filter((tab) => channelHasTab({ tabId: tab.id, channel }));

  const rootTabs =
    publicFolder && channel.useRootAsTabs
      ? getFolderTabs(publicFolder)
      : [{ id: 'series', name: translator('series'), path: '/series' }];

  if (defaultTabs[0].id === 'search') {
    return [...rootTabs, defaultTabs[0], ...defaultTabs.slice(1)];
  }

  return [defaultTabs[0], ...rootTabs, ...defaultTabs.slice(1)];
}

function getFolderTabs(publicFolder: PublishContentExplorer): ChannelTab[] {
  const folders = orderBy(publicFolder.children, 'order');
  const lang = LanguageSingleton.get();
  const filtered = folders.filter(
    (f) => !f.showForLanguage || f.showForLanguage === 'all' || f.showForLanguage === lang
  );
  return filtered.map((folder) => ({
    name: folder.name || folder._id,
    id: folder._id,
    path: `/series/${folder._id}`,
  }));
}

type ChannelHasTabArgs = {
  tabId: string;
  channel: Channel;
};

function channelHasTab({ tabId, channel }: ChannelHasTabArgs): boolean {
  switch (tabId) {
    case 'start':
      return Boolean(channel.start && Object.values(channel.start).some((val) => val && !isEmpty(val)));
    case 'about':
      return Boolean(channel.about && Object.values(channel.about).some((val) => val && !isEmpty(val)));
    case 'search':
      return true;
    case 'curricula':
      return Boolean(channel.curricula?.length);
    case 'series':
      return !channel.useRootAsTabs;
    default:
      return false;
  }
}

type IsChannelTabActiveArgs = {
  tab: ChannelTab;
  activeTab: ActiveChannelTab;
  channel: Channel;
  publicFolder: PublishContentExplorer | undefined;
  plans: LessonPlanState;
  lessons: LessonState;
};

function isChannelTabActive({
  tab,
  activeTab,
  channel,
  publicFolder,
  plans,
  lessons,
}: IsChannelTabActiveArgs): boolean {
  const { channelPage } = activeTab;

  const isSeriesLessonOrPlan = channelPage && ['series', 'lesson', 'plan'].includes(channelPage);

  if (isSeriesLessonOrPlan && channel.useRootAsTabs && publicFolder && activeTab.selectionId) {
    if (channelPage === 'lesson') {
      const location = lessons.lessonDetails?.lesson.location;
      if (location?.explorer !== publicFolder._id) return false;

      const info = tryGetFolderFromExplorer(publicFolder, location.folder);
      if (!info) return false;
      const showParentAsActive = tab.id && info.parents.some((folder) => folder._id === tab.id);
      return !!showParentAsActive;
    }

    if (channelPage === 'plan') {
      const location = plans.planDetails?.plan.location;
      if (location?.explorer !== publicFolder._id) return false;

      const info = tryGetFolderFromExplorer(publicFolder, location?.folder);
      if (!info) return false;
      const showParentAsActive = tab.id && info.parents.some((folder) => folder._id === tab.id);
      return !!showParentAsActive;
    }

    const info = tryGetFolderFromExplorer(publicFolder, activeTab.selectionId);
    if (info) {
      const showParentAsActive = tab.id && info.parents.some((folder) => folder._id === tab.id);
      if (showParentAsActive) return true;
    }
  }

  if (channelPage && ['lesson', 'plan'].includes(channelPage)) {
    return tab.id === 'series';
  }

  if (isDefaultChannelTab(tab)) {
    return tab.id === channelPage;
  }

  return tab.id === activeTab.selectionId;
}
