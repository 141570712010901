/* eslint-disable no-param-reassign */
import { Colors, TableComponent } from '@lessonup/teaching-core';
import classNames from 'classnames';
import React, { CSSProperties, ReactNode, useCallback } from 'react';
import { FontsSingleton } from '../../../../services/fonts/FontsSingleton';

export const TableRendererBase: React.FC<TableComponent.TableGeneratorProps> = ({
  settings,
  position,
  view,
  pinId,
  componentId,
  ContentComponent,
}) => {
  const rows = settings.rows;
  const columns = settings.columns;
  const tableData = TableComponent.tableDataToArray(settings.tableData);
  const bodyColor = settings.contentLayout.color;
  const style: CSSProperties = { fontFamily: settings.fontFamily, color: bodyColor };
  const borderOverrideClasses = {
    'border-black': settings.borderColorOverride === 'black',
    'border-white': settings.borderColorOverride === 'white',
  };
  const viewClass = view ? 'table-view' : '';
  const colPositions = columns.slice(0, columns.length - 1).reduce<number[]>((positions, col, i) => {
    const previousWidth = i > 0 ? positions[i - 1] : 0;
    positions.push(col.width + previousWidth);
    return positions;
  }, []);

  const fontsForComponent = useCallback(() => {
    FontsSingleton.add([settings.fontFamily]);
  }, [settings.fontFamily]);

  fontsForComponent();

  return (
    <div className={classNames('table-react-component', viewClass, borderOverrideClasses)} style={style}>
      {rows.map((row, rowIndex) => (
        <div className="ct-row" key={rowIndex}>
          {columns.map((colum, colIndex) => {
            const cell = TableComponent.getCell(tableData, rowIndex, colIndex);
            if (!cell) return null;
            return ContentComponent ? (
              <ContentComponent
                row={rowIndex}
                col={colIndex}
                cell={cell}
                settings={settings}
                position={position}
                pinId={pinId}
                componentId={componentId}
                view={false}
                key={colIndex}
              />
            ) : (
              <TableCellWrapper row={rowIndex} col={colIndex} settings={settings} key={colIndex}>
                <div
                  dangerouslySetInnerHTML={{ __html: cell.content }}
                  className={classNames(
                    TableComponent.cellClassName,
                    'text-' + settings.columns[colIndex].alignment,
                    'font-size-' + settings.fontSize
                  )}
                />
              </TableCellWrapper>
            );
          })}
          {!view && (
            <div
              data-grippy="resize-row"
              data-row-grippy={rowIndex}
              className={classNames('grippy', 'move', 'row-grippy')}
              key={`row-${rowIndex}`}
            ></div>
          )}
        </div>
      ))}
      {!view &&
        colPositions.map((colPosition, colIndex) => (
          <div
            data-grippy="resize-col"
            data-col-grippy={colIndex}
            className={classNames('grippy', 'move', 'edge-left', 'col-grippy')}
            style={{ left: colPositions[colIndex] + '%' }}
            key={colIndex}
          ></div>
        ))}
    </div>
  );
};

export const TableRenderer = React.memo(TableRendererBase);

interface CellWrapperProps {
  row: number;
  col: number;
  settings: TableComponent.Settings;
  cell?: TableComponent.Cell;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onTouchStart?: (event: React.TouchEvent<HTMLDivElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: ReactNode;
}

// format b(border)-direction-[-h(header)]
type BorderClassNames = 'b-t' | 'b-t-h' | 'b-r' | 'b-r-h' | 'b-b' | 'b-b-h' | 'b-l' | 'b-l-h';

/**
 * Return a list of all css border classes, this is called once per cell
 */
function borderClasses({ row, col, settings }: Pick<CellWrapperProps, 'col' | 'row' | 'settings'>): BorderClassNames[] {
  const headerPositions = TableComponent.getHeaders(settings);
  const borders: BorderClassNames[] = [];
  if (settings.borders === 'no-border') return [];

  // TOP
  // the border between cells is always the bottom one, so only first row needs a top
  if (row === 0 && settings.borders !== 'no-outer-border') {
    borders.push('b-t');
  }

  // LEFT
  // the border between cells is always the right one, so only first column needs a left
  if (col === 0 && settings.borders !== 'no-outer-border') {
    borders.push('b-l');
  }

  // BOTTOM
  const lastRow = settings.rows.length - 1;
  const secondLastRow = settings.rows.length - 2;
  if (row === lastRow) {
    if (settings.borders !== 'no-outer-border') borders.push('b-b');
  }
  // the top header row gets a thick bottom border
  else if (row === 0 && headerPositions.top) {
    borders.push('b-b-h');
  }
  // the bottom header row gets a thick upper border
  // but without collapse this is the bottom border of bottom - 1;
  else if (row === secondLastRow && headerPositions.bottom) {
    borders.push('b-b-h');
  }
  // all other cell have a bottom border
  else {
    borders.push('b-b');
  }

  // RIGHT
  const lastColumn = settings.columns.length - 1;
  const secondLastColumn = settings.columns.length - 2;
  if (col === lastColumn) {
    if (settings.borders !== 'no-outer-border') borders.push('b-r');
  }
  // the left header row gets a thick right border
  else if (col === 0 && headerPositions.left) {
    borders.push('b-r-h');
  }
  // the right header row gets a thick left border
  // but without collapse this is the right border of bottom - 1;
  else if (col === secondLastColumn && headerPositions.right) {
    borders.push('b-r-h');
  } else {
    borders.push('b-r');
  }
  return borders;
}

export const TableCellWrapper: React.FC<CellWrapperProps> = (props) => {
  const { row, col, settings, children } = props;
  const header: boolean = settings.columns[col].header || settings.rows[row].header;
  const style: CSSProperties = {
    width: settings.columns[col].width + '%',
    minHeight: TableComponent.heightPercentToEm(settings.rows[row].height),
    backgroundColor: Colors.getHexColor(header ? settings.headerLayout.fillColor : settings.contentLayout.fillColor),
  };
  // border color should inherit, so text color one deeper
  const innerStyle = {
    color: Colors.getHexColor(header ? settings.headerLayout.color : settings.contentLayout.color),
  };

  return (
    <div
      className={classNames('cell-wrapper', borderClasses({ row, col, settings }))}
      style={style}
      data-col={col}
      data-row={row}
      onMouseDown={props.onMouseDown ? props.onMouseDown : undefined}
      onTouchStart={props.onTouchStart ? props.onTouchStart : undefined}
      onClick={props.onClick ? props.onClick : undefined}
    >
      <div style={innerStyle}>{children}</div>
    </div>
  );
};
