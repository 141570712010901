import { Button } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppServices } from '../../../services/AppServices';
import { AppServicesConsumer } from '../../appServices/AppServicesContext';

interface Props {
  openRegistrationFlow: () => void;
}

type RegistrationNavButton = Props;

export default function RegistrationNavButton(props: RegistrationNavButton) {
  const { t } = useTranslation('navbar');

  async function handleOpenClick(services: AppServices) {
    props.openRegistrationFlow();
  }

  return (
    <AppServicesConsumer
      render={(services) => {
        return (
          <Button buttonType="primary" onClick={handleOpenClick.bind(null, services)}>
            {t('create-account')}
          </Button>
        );
      }}
    />
  );
}
