import { combineReducers, createStore } from 'redux';
import { channelOverviewReducer } from './reducers/channelOverviewReducer';
import { channelReducer } from './reducers/channelReducer';
import { clusterReducer } from './reducers/clusterReducer';
import { errorReducer } from './reducers/errorReducer';
import { featureFlagReducer } from './reducers/featureflagReducer';
import { followedChannelsReducer } from './reducers/followedChannelsReducer';
import { geoLocationReducer } from './reducers/geoLocationReducer';
import { lessonPlanReducer } from './reducers/lessonPlanReducer';
import { lessonReducer } from './reducers/lessonReducer';
import { organizationPickerReducer } from './reducers/organisationPickerReducer';
import { pinStateReducer } from './reducers/pinsReducer';
import { ratingsReducer } from './reducers/ratingsReducer';
import { savedLessonReducer } from './reducers/savedLessonReducer';
import { searchLessonReducer } from './reducers/searchLessonReducer';
import { searchPinsReducer } from './reducers/searchPinsReducer';
import { selectPinReducer } from './reducers/selectPinReducer';
import { tooltipReducer } from './reducers/tooltipReducer';
import { userReducer } from './reducers/userReducer';

const reducers = combineReducers({
  search: searchLessonReducer,
  user: userReducer,
  lesson: lessonReducer,
  plan: lessonPlanReducer,
  searchPins: searchPinsReducer,
  cluster: clusterReducer,
  ratings: ratingsReducer,
  tooltips: tooltipReducer,
  selectPins: selectPinReducer,
  followedChannels: followedChannelsReducer,
  savedLessons: savedLessonReducer,
  pins: pinStateReducer,
  channel: channelReducer,
  channelOverview: channelOverviewReducer,
  featureFlags: featureFlagReducer,
  geoLocation: geoLocationReducer,
  organizationPicker: organizationPickerReducer,
  error: errorReducer,
});

export function configureStore(initialState: StoreState | undefined, isLive: boolean) {
  const devTools =
    !isLive && typeof window !== 'undefined'
      ? window['__REDUX_DEVTOOLS_EXTENSION__'] && window['__REDUX_DEVTOOLS_EXTENSION__']()
      : undefined;

  const searchStore = createStore(reducers, initialState, devTools);

  return searchStore;
}

export type StoreState = ReturnType<typeof reducers>;
export type SearchStore = ReturnType<typeof configureStore>;
export type AppStore = ReturnType<typeof configureStore>;
