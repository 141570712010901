import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Facets, IndexedChannelInfo, SearchParams } from '../../../../shared-core/domain';
import useScrollPage from '../../../../shared-core/ui/utils/useScrollPage';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import SearchHiddenUrls from '../../../components/search/SearchHiddenUrls/SearchHiddenUrls';
import SearchPageMeta from '../../../components/search/SearchPageTitle/SearchPageMeta';
import { fetchedLessonsAction } from '../../../redux/actions/actions';
import { lessonResults, userRatings } from '../../../redux/selectors';
import { useSpinnerContext } from '../../../utils/loaders/SpinnerContext';
import { showTabsForLanguage } from '../../../utils/tabs';
import { SearchContextProvider } from '../sidebar/SearchContext';
import LessonSearchView from './LessonSearchView';

type Props = {
  searchParams: SearchParams;
  urlCreator: (params: SearchParams) => string;
  lessonUrlCreator?: (lessonId: string) => string;
  channelInfo?: IndexedChannelInfo;
  facetSubType?: Facets.FacetSubTypes[];
  showTabs?: boolean;
};

const LessonSearch: React.FunctionComponent<Props> = ({
  searchParams,
  urlCreator,
  lessonUrlCreator,
  channelInfo,
  facetSubType,
  showTabs,
}) => {
  const services = useAppServices();
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const lessons = useSelector(lessonResults());
  const ratings = useSelector(userRatings());
  const storeSearchParams = lessons && lessons.request;
  const spinner = useSpinnerContext();
  const { scrollToTop } = useScrollPage();

  const fetch = useCallback(
    async (params: SearchParams, isPagination?: boolean) => {
      setFetching(true);
      const overrideExistingResults = !!lessons;
      if (!isPagination) {
        // Size is changed through the showMore button, which comes with its own loading state. So we omit this one.
        spinner.open(overrideExistingResults);
      }

      const res = await services.search.searchLessons(params, facetSubType);
      dispatch(fetchedLessonsAction(res));

      if (!isPagination) {
        scrollToTop();
      }

      spinner.close();
      setFetching(false);
    },
    [dispatch, facetSubType, lessons, scrollToTop, services.search, spinner]
  );

  return (
    <SearchContextProvider
      service={services.search}
      fetchCallBack={fetch}
      indexType="lessons"
      storeSearchParams={storeSearchParams}
      routeParams={searchParams}
      urlCreator={urlCreator}
      facetSubType={facetSubType}
    >
      <SearchPageMeta count={lessons && lessons.total} type="lessons" channelInfo={channelInfo} />
      <LessonSearchView
        results={lessons}
        userRatings={ratings}
        fetching={fetching}
        lessonUrlCreator={lessonUrlCreator}
        channelInfo={channelInfo}
        showTabs={showTabs && showTabsForLanguage()}
      />
      <SearchHiddenUrls results={lessons} urlCreator={urlCreator} />
    </SearchContextProvider>
  );
};

export default LessonSearch;
