import { logger } from '@lessonup/client-integration';
import { AppError } from '@lessonup/utils';
import { debounce } from 'lodash';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { components, ControlProps, InputActionMeta, MenuProps, mergeStyles } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Country } from '../../../../domain/country/Country';
import { IndexedSchoolSuggestion } from '../../../../domain/schoolSuggestions/IndexedSchoolSuggestion';

import { Colors } from '@lessonup/teaching-core';
import { useBEM } from '../../../utils/hooks';
import { InputLabel } from '../../Input/InputLabel/InputLabel';
import { InputSubtext } from '../../Input/InputSubtext/InputSubtext';
import { getDropdownTheme } from '../../Select/SelectStyles';
import PlusIcon from '../../svgIcons/PlusIcon';
import SearchIcon from '../../svgIcons/Search';
import './SchoolSelect.less';
import SchoolSelectOption from './SchoolSelectOption/SchoolSelectOption';

const HTML_ID = 'school-select';
const searchMinimumChars = 3;

export interface SchoolSelectProps {
  onSubmit: (school: IndexedSchoolSuggestion | undefined) => void;
  countryCode?: Country.CountryCode;
  getSuggestions: (params: { query: string; countryCode: Country.CountryCode }) => Promise<IndexedSchoolSuggestion[]>;
  onSetManualEntry: (val: string) => void;
  hasError: boolean;
  invalidateError: () => void;
  onChange?: (school: IndexedSchoolSuggestion | undefined) => void;
}

const SchoolSelect: React.FC<SchoolSelectProps> = ({
  onSubmit,
  countryCode,
  getSuggestions,
  onSetManualEntry,
  hasError,
  invalidateError,
  onChange: onChangeProp,
}) => {
  const { t } = useTranslation('schoolPickForm');
  const [schoolSelectValue, setSchoolSelectValue] = useState<string>('');
  const [chosenSchool, setChosenSchool] = useState<IndexedSchoolSuggestion | undefined>(undefined);
  const [optionCache, setOptionCache] = useState<IndexedSchoolSuggestion[] | undefined>(undefined);
  const bemClasses = useBEM('SchoolSelect');

  // Using callback instead of Promise as otherwise the list of options doesn't get updated due to AsyncSelect.
  const searchSchools = (searchQuery: string, callback: (schools: IndexedSchoolSuggestion[]) => void): void => {
    if (!countryCode) return;

    if (searchQuery.length < searchMinimumChars) {
      callback([]);
      setOptionCache([]);
      return;
    }

    getSuggestions({ countryCode, query: searchQuery })
      .then((suggestions) => {
        setOptionCache(suggestions);
        return callback(suggestions);
      })
      .catch((error) => {
        logger.error(error);
        setOptionCache([]);
        callback([]);
      });
  };

  // Debounced invoke to prevent multiple API calls
  const debouncedSearchSchools = useRef(
    debounce(searchSchools, 400, { leading: true })
  ).current;

  const onChange = (value: IndexedSchoolSuggestion) => {
    if (onChangeProp) onChangeProp(value);

    if (!countryCode) throw new AppError('invalid-params');

    if (value?.['label']) {
      return onSetManualEntry(value['label']);
    }

    if (!value) setOptionCache(undefined);
    setChosenSchool(value);
    onSubmit(value);
  };

  const handleInputChange = (inputValue: string, action: InputActionMeta) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setSchoolSelectValue(inputValue);
      invalidateError();
    }
  };

  const styles = getDropdownTheme<IndexedSchoolSuggestion>('lessonup-default', { hasError });

  return (
    <div className={bemClasses()}>
      <InputLabel id={HTML_ID} text={t('selectSchoolLabel')} disabled={!countryCode} />

      <AsyncCreatableSelect
        id={HTML_ID}
        className={bemClasses({ element: 'dropdown-selection' })}
        defaultOptions={optionCache?.map((option) => ({ ...option, value: option._id }))}
        loadOptions={debouncedSearchSchools}
        onChange={onChange}
        cacheOptions={optionCache?.map((option) => ({ ...option, value: option._id }))}
        onInputChange={handleInputChange.bind(this)}
        openMenuOnClick={!!chosenSchool?._id}
        placeholder={t('searchPlaceholder')}
        loadingMessage={() => t('loading')}
        noOptionsMessage={() => t('noOptions')}
        isClearable={true}
        styles={mergeStyles(styles, {
          menu: (provided) => ({ ...provided, position: 'unset' }),
          option: (provided, state) => {
            const isCreateOption = !state.data._id;
            return {
              ...provided,
              cursor: 'pointer',
              backgroundColor:
                chosenSchool && state.data._id === chosenSchool?._id ? Colors.getHexColor('cyan') : '#fff',
              color: Colors.getHexColor('default-text'),

              '&:hover': {
                backgroundColor: Colors.getHexColor('wild-sand'),
              },
              '&:not(:only-child)': {
                '&:before': isCreateOption && {
                  content: '""',
                  width: 'calc(100% - 1rem)',
                  left: '.5rem',
                  position: 'absolute',
                  margin: '-.5rem auto',
                  height: '1px;',
                  backgroundColor: Colors.getHexColor('outline'),
                },
              },
            };
          },
        })}
        formatCreateLabel={(value: string) => t('createSchool', { value })}
        formatOptionLabel={(option, { context }) => {
          if (!option._id) {
            const isSelectedOption = option['label'] === option['value'];

            // React-select does not allow us to properly type the option.
            return (
              <div className={bemClasses({ element: 'create-option' })}>
                {!isSelectedOption && <PlusIcon height="16" width="16" />}
                <span className={bemClasses({ element: 'create-element' })}>{option['label']}</span>
              </div>
            );
          }

          return (
            <SchoolSelectOption school={option} searchQuery={schoolSelectValue} isSelected={context === 'value'} />
          );
        }}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          Control,
          Menu: (props: MenuProps<IndexedSchoolSuggestion, false>): React.ReactElement<any, any> | null => {
            return schoolSelectValue.length >= searchMinimumChars ? <Menu {...props} /> : null;
          },
        }}
        maxMenuHeight={300}
        isDisabled={!countryCode}
      ></AsyncCreatableSelect>
      {hasError && <InputSubtext subtext={t('schoolRequired')} subtextVariant="error" />}
    </div>
  );
};

const Control: React.FC<ControlProps<IndexedSchoolSuggestion, false>> = ({ children, ...props }) => (
  <components.Control {...props} className="school-select__control">
    <SearchIcon className="input-icon" />
    {children}
  </components.Control>
);

const Menu: React.FC<MenuProps<IndexedSchoolSuggestion, false>> = ({ children, ...props }) => (
  <components.Menu {...props} menuShouldScrollIntoView={true}>
    {children}
  </components.Menu>
);

export default SchoolSelect;
