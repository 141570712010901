export type Payment = BunqPayment | BankTransferPayment | IdealPayment;

export interface PaymentBase {
  _id: string;

  //TODO: Type properly
  metadata: any;
}

//TODO: Type properly
export interface BunqPayment extends PaymentBase {
  method: 'bunq';
}

//TODO: Type properly
export interface BankTransferPayment extends PaymentBase {
  method: 'banktransfer';

  //TODO: Type properly
  details: any;
}

//TODO: Type properly
export interface IdealPayment extends PaymentBase {
  method: 'ideal';
}

export namespace Payment {
  export function isBankTransferPayment(payment: any): payment is BankTransferPayment {
    return payment.method === 'banktransfer';
  }
}
