import { AssignmentPin } from '@lessonup/teaching-core';
import React from 'react';
import PinContainer from '../../pin/PinContainer';
import { MapPinProps } from '../../pin/PinView';
import MapThumb from './MapThumb';

interface Props extends MapPinProps {
  pin: AssignmentPin.Map;
}

export default class MapView extends React.Component<Props> {
  public render() {
    return (
      <PinContainer pin={this.props.pin}>
        <MapThumb pin={this.props.pin} />
      </PinContainer>
    );
  }
}
