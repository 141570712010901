import React from 'react';

import { AssignmentPin } from '@lessonup/teaching-core';
import PinContainer from '../../pin/PinContainer';
import { MapPinProps } from '../../pin/PinView';
import VideoIndicatorSvg from '../../svgIcons/VideoIndicatorSvg';
import { VideoComponent } from '../../viewComponents/video/VideoComponentView';
import VideoThumb from './VideoThumb';

interface Props extends MapPinProps {
  pin: AssignmentPin.Video;
}

export default class VideoView extends React.Component<Props> {
  private thumbIcon = () => {
    const { source } = this.props.pin.item;
    if (!source) return 'video';
    return source === 'uploads' ? 'youtube' : source;
  };

  private thumbImage = () => {
    return this.props.pin.item.image || '';
  };

  private videoComponentProps = () => {
    const { item } = this.props.pin;
    return {
      source: item.source!,
      id: item.custom.id,
      autoplay: item.custom.autoplay,
      img: item.image,
      title: item.name,
    };
  };

  public render() {
    const { isThumb, useThumbnailForComponentType, pin } = this.props;

    if (isThumb || useThumbnailForComponentType?.includes('video')) {
      return (
        <VideoThumb icon={this.thumbIcon()} image={this.thumbImage()}>
          {pin.item.videoItems && pin.item.videoItems.length && (
            <div className="tipped">
              <VideoIndicatorSvg
                pinId={pin._id}
                number={pin.item.videoItems.length}
                style={{ position: 'absolute', bottom: 0 }}
              />
            </div>
          )}
        </VideoThumb>
      );
    } else {
      return (
        <PinContainer pin={pin}>
          <VideoComponent {...this.videoComponentProps()} />
        </PinContainer>
      ); // TODO: Add actual player instead of iframe
    }
  }
}
