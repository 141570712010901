import React from 'react';

export const RocketPath = (props) => (
  <g fillRule="evenodd" {...props} preserveAspectRatio="xMidYMid meet">
    <path
      d="M8.10791 25.6014C8.33859 24.7836 8.18588 24.3339 7.91093 24.0892C7.66614 23.8141 7.21641 23.6614 6.39859 23.8921C3.80876 24.6263 3.50293 27.9212 3.46838 28.5316C4.07886 28.497 7.37377 28.1912 8.10791 25.6014"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M8.03871 13.3709C8.47292 12.5253 8.92879 11.7061 9.40109 10.9199C7.51798 10.7412 4.01009 11.1499 0 15.2499L1.5208 16.7375C1.5208 16.7375 4.63417 15.6975 6.72659 16.1621C7.13255 15.2182 7.57142 14.281 8.03871 13.3709Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M18.6291 23.9612C17.7188 24.4287 16.7815 24.8676 15.8378 25.2733C16.3024 27.3657 15.2623 30.4791 15.2623 30.4791L16.7501 32C20.8502 27.9898 21.2588 24.4819 21.0799 22.5989C20.2939 23.0711 19.4747 23.5269 18.6291 23.9612Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.1536 9.7563C22.0063 9.90357 21.8134 9.97721 21.6204 9.97721C21.4273 9.97721 21.2344 9.90357 21.0871 9.7563C20.7926 9.46174 20.7926 8.98436 21.0871 8.68981C21.2344 8.54253 21.4273 8.46889 21.6204 8.46889C21.8134 8.46889 22.0063 8.54253 22.1536 8.68981C22.4482 8.98436 22.4482 9.46174 22.1536 9.7563ZM23.4181 8.58657C22.9216 8.09024 22.2711 7.84189 21.6204 7.84189C20.9698 7.84189 20.3192 8.09024 19.8226 8.58657C18.8298 9.57942 18.8298 11.1892 19.8226 12.182C20.3192 12.6783 20.9698 12.9265 21.6204 12.9265C22.2711 12.9265 22.9216 12.6783 23.4181 12.182C24.4109 11.1892 24.4109 9.57942 23.4181 8.58657Z"
      fill={props.fill || 'currentColor'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.422 13.1896C23.6729 13.9388 22.6769 14.3516 21.6174 14.3516C20.5579 14.3516 19.5618 13.9388 18.8126 13.1896C17.2662 11.6427 17.2662 9.12583 18.8128 7.5789C19.5618 6.8297 20.5579 6.41693 21.6174 6.41693C22.6769 6.41693 23.6729 6.8297 24.4221 7.57908C25.9686 9.12583 25.9686 11.6427 24.422 13.1896ZM30.9834 1.01628C30.3114 0.344152 29.1914 0.000688939 27.7839 0.000688939C24.2013 0.000688939 18.7534 2.22408 14.0692 6.90929C13.0907 7.88788 12.3126 9.04967 11.545 10.2006C10.7261 11.4288 9.98479 12.7086 9.31066 14.0218C8.78207 15.0514 8.29436 16.1023 7.8493 17.1703C7.68605 17.5616 7.52879 17.9549 7.3781 18.351C7.23452 18.7291 7.09657 19.1094 6.96863 19.4929C6.98083 19.5401 6.99366 19.5873 7.00685 19.6341C7.0807 19.8887 7.16874 20.1392 7.26813 20.3852C7.36653 20.6285 7.47572 20.8675 7.59541 21.1009C7.95687 21.8076 8.41497 22.4662 8.96286 23.0414C9.53789 23.5894 10.1964 24.0476 10.9029 24.4092C11.1364 24.5288 11.3752 24.638 11.6185 24.7364C11.8643 24.8358 12.1149 24.9239 12.3694 24.9979C12.4162 25.011 12.4633 25.0239 12.5106 25.0361C12.894 24.908 13.2744 24.7701 13.6523 24.6264C14.0482 24.4757 14.4415 24.3185 14.8327 24.1552C15.9005 23.7099 16.9513 23.2223 17.9806 22.6936C19.2935 22.0194 20.573 21.2777 21.801 20.4587C22.9517 19.6909 24.1132 18.9126 25.0916 17.934C31.6159 11.4084 33.366 3.3994 30.9834 1.01628"
      fill={props.fill || 'currentColor'}
    />
  </g>
);

const RocketSvg = (props) => (
  <svg
    width={props.width || 100}
    height={props.height || 100}
    viewBox="0 0 32 32"
    fillRule="evenodd"
    {...props}
    preserveAspectRatio="xMidYMid meet"
    fill="currentColor"
  >
    <RocketPath />
  </svg>
);

export default RocketSvg;
