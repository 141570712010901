import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ActionButton from '../../../../shared-core/ui/components/Button';
import RocketFlightSvg from '../../../../shared-core/ui/components/svgIcons/RocketFlightSvg';
import './ErrorPage.less';
interface NotFoundErrorProps {
  type: 'lesson' | 'page' | 'channel';
  backUrl: string;
}

const NotFoundErrorPageView: React.FunctionComponent<NotFoundErrorProps> = (props) => {
  const { t } = useTranslation('errors');
  return (
    <div className="error-page-container">
      <div className="error-page error-not-found">
        <h1 className="error-title">{t('not-found-error')}</h1>
        <RocketFlightSvg />
        <p className="error-description">{t('not-found-error-description', { type: t(props.type) })}</p>
        <Link to={props.backUrl}>
          <ActionButton className="error-page-back">{t('error-page-back')}</ActionButton>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundErrorPageView;
