import { allPinTypes, PinType } from '@lessonup/teaching-core';
import { isNumber, isString } from 'lodash';

export interface SearchItemParams {
  lessonId?: string;
  afterPinId?: string;
  phase?: number | string;
  type?: PinType;
  schoolType?: string;
  subjects?: string;
  levels?: string;
  years?: string;
  country?: string;
  hasImage?: boolean;
  forComponent: 'video';
}

export function paramsAreValidSearchItemParams(object: Record<string, any>): object is SearchItemParams {
  const optionalStringPropsAreStrings = [
    'afterPinId',
    'schoolType',
    'subjects',
    'levels',
    'years',
    'country',
    'lessonId',
  ].every((property) => !object[property] || isString(object[property]));

  const optionalNumberPropsAreNumbers = !object['phase'] || isString(object['phase']) || isNumber(object['phase']);
  const otherPropertiesAreCorrectType = !object['type'] || allPinTypes.includes(object['type']);
  return optionalStringPropsAreStrings && optionalNumberPropsAreNumbers && otherPropertiesAreCorrectType;
}

export type IframeMsgLabel = 'pinAddedFromSearch';
