import { LessonPlanDetails } from '../../../shared-core/domain';
import { AnyAction } from '../actions/actions';

export interface LessonPlanState {
  isLoading: boolean;
  planDetails: LessonPlanDetails | undefined;
  planId: string | undefined;
  error: false | 404 | 403 | 500;
}

const initialState: LessonPlanState = {
  isLoading: false,
  planDetails: undefined,
  error: false,
  planId: undefined,
};

export function lessonPlanReducer(state: LessonPlanState = initialState, action: AnyAction): LessonPlanState {
  switch (action.type) {
    case 'fetchedPlan':
      return {
        isLoading: false,
        error: false,
        planDetails: action.response && action.response.result,
        planId: action.response && action.response.result && action.response.result.plan._id,
      };
    case 'fetchingPlan':
      return {
        isLoading: true,
        error: false,
        planDetails: undefined,
        planId: action.planId,
      };
    case 'fetchPlanError': {
      return {
        isLoading: false,
        error: action.code,
        planId: action.planId,
        planDetails: undefined,
      };
    }
    default:
      return state;
  }
}
