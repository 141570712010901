// This is the .ts-version of src/client/stylesheets/sharedLib/_mediaqueries.less Make sure to update the less file as well.

export interface Breakpoints {
  mqXsMin: number;
  mqXsMax: number;
  mqSmMin: number;
  mqSmMax: number;
  mqMdMin: number;
  mqMdMax: number;
  mqLgMin: number;
}

export type Breakpoint = keyof Breakpoints;

export const BREAKPOINTS: Breakpoints = {
  mqXsMin: 480,
  mqXsMax: 767,
  mqSmMin: 768,
  mqSmMax: 959,
  mqMdMin: 960,
  mqMdMax: 1199,
  mqLgMin: 1200,
};
