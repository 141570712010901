import React from 'react';
import './VisuallyHidden.less';

type Props = {
  children: React.ReactNode;
};

function VisuallyHidden({ children }: Props) {
  return <div className="visually-hidden">{children}</div>;
}

export default VisuallyHidden;
