import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from '../../../utils/ModalManagerService';
import FollowChannelForm, { FormParams } from '../FollowChannelModal/FollowChannelForm';
import { Modal } from '../Modal';

export interface ChannelLeadConfig {
  channelTitle: string;
  handleSubmit: (params: FormParams) => Promise<{ success: boolean }>;
  channelSlug: string;
}

export interface ChannelLeadResponse {
  success: boolean;
}

type Props = ModalProps<ChannelLeadResponse | undefined> & ChannelLeadConfig;

const TRANSLATION_NAMESPACE = 'followChannelModal';

const ChannelLeadModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  function handleClose() {
    props.closeModal({ success: false });
  }

  async function handleFollowByEmail(params: FormParams) {
    const result = await props.handleSubmit(params);
    props.closeModal(result);
  }

  return (
    <Modal title={props.channelTitle || t('title')} isOpen onRequestClose={handleClose} width="medium">
      <FollowChannelForm channelTitle={props.channelTitle} submitHandler={handleFollowByEmail} />
    </Modal>
  );
};

export default ChannelLeadModal;
