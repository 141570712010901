import { Country } from '../country/Country';
import { LanguageKey } from '../language';
import { CurrentPaymentMethods } from '../subscription/paymentMethod';
import { StripeSubscriptionStatus } from '../subscription/StripeSubscription';
import { Licenses } from './Licenses';
import { MongoUser, MongoUserAccount } from './MongoUser';
import { getAmbassadorSince, isUsercontext, UserContext } from './UserContext';

/**Client side intercom and server side intercom expect the same data but a different format */
export namespace IntercomData {
  interface BaseData {
    user_id: string;
    name: string | undefined;
    email: string | undefined;
  }

  export interface ExtraData {
    organizationIds?: string;
    schoolTypes?: string;
    subjects?: string;
    licenseStatus?: Licenses.Status;
    license_expires?: Licenses.ExpiryDate;
    userType: 'student' | 'teacher';
    language?: LanguageKey;
    language_override?: LanguageKey;
    country?: Country.CountryCode;
    stripeSubscription_cancelAtPeriodEnd?: boolean;
    stripeSubscription_status?: string;
    ambassadorSince?: MongoUserAccount['ambassadorSince'];
  }

  interface ClientSignupDate {
    created_at?: number;
  }

  interface ServerSignupDate {
    signed_up_at?: number;
  }
  export interface ProgressData {
    progress_lesson_created?: boolean;
    progress_share_lesson?: boolean;
    progress_teach_lesson?: boolean;
    progress_save_lesson?: boolean;
  }

  export type UserData = BaseData & ExtraData & ProgressData & ClientSignupDate;

  export interface ApiData extends BaseData {
    custom_attributes: ExtraData & ServerSignupDate;
  }

  function arrayToString(strings: string[] | undefined): string {
    // we should return an empty string to make sure intercom updates correctly
    // undefined is not parts of the intercom spec
    // https://www.intercom.com/help/en/articles/179-send-custom-user-attributes-to-intercom
    return strings?.join(', ') || '';
  }

  function baseData(user: UserContext | MongoUser): BaseData {
    return {
      user_id: user._id,
      email: MongoUser.emailFromMongoUser(user),
      name: user.profile?.name,
    };
  }

  function extraData(user: UserContext | MongoUser): ExtraData {
    const organizationIds: string[] | undefined =
      user && 'organizations' in user
        ? user.organizations.map((org) => org.id)
        : user?.account?.organizations?.map((o) => o.id);

    const ambassadorSince = isUsercontext(user) ? getAmbassadorSince(user) : MongoUser.ambassadorSince(user);

    return {
      organizationIds: arrayToString(organizationIds),
      licenseStatus: isUsercontext(user) ? user.licenseStatus : MongoUser.license(user),
      license_expires: isUsercontext(user) ? user.licenseExpires : MongoUser.licenseExpires(user),
      language: isUsercontext(user) ? user.language : MongoUser.language(user),
      language_override: isUsercontext(user) ? user.language : MongoUser.language(user),
      country: isUsercontext(user) ? user.country : MongoUser.country(user),
      subjects: arrayToString(user.profile?.subjects),
      schoolTypes: arrayToString(user.profile?.schoolTypes),
      userType: (user as UserContext).type === 'student' || MongoUser.isStudent(user) ? 'student' : 'teacher',
      ambassadorSince: ambassadorSince ?? undefined,
    };
  }

  function clientSignupDate(user: UserContext | MongoUser): ClientSignupDate {
    return { created_at: user.createdAt ? new Date(user.createdAt).getTime() / 1000 : undefined };
  }

  function serverSignupDate(user: UserContext | MongoUser): ServerSignupDate {
    return { signed_up_at: user.createdAt ? new Date(user.createdAt).getTime() / 1000 : undefined };
  }

  function userProgressData(user: UserContext | MongoUser): ProgressData {
    if (!('data' in user) || !user.data) return {};

    return {
      progress_lesson_created: !!user.data.progress?.['create-new-lesson'],
      progress_share_lesson: !!user.data.progress?.['share-lesson'],
      progress_teach_lesson: !!user.data.progress?.['finish-presenting'],
      progress_save_lesson: !!user.data.progress?.['save-lesson'],
    };
  }

  /**format for client */
  export function userDataForContext(user?: UserContext | MongoUser): UserData | undefined {
    if (!user) return undefined;
    return {
      ...baseData(user),
      ...extraData(user),
      ...clientSignupDate(user),
      ...userProgressData(user),
    };
  }

  export interface ServerCustomAttributes {
    publisherAdmin: string[];
    publisherMember: string[];
    schoolBasicMemberIds: string[];
    schoolAdminIds: string[];
    schoolMemberIds: string[];
    cancelAtPeriodEnd?: boolean;
    redeemedVoucherIds?: string[];
    status?: StripeSubscriptionStatus;
  }

  interface IntercomSafeServerAttributes {
    publisher_admin: string;
    publisher_member: string;
    school_admin: string;
    school_member: string;
    school_basic: string;
    used_voucher: boolean;
    license_voucher_ids?: string;
    stripeSubscription_cancelAtPeriodEnd?: boolean;
    stripeSubscription_status?: StripeSubscriptionStatus;
    stripeSubscription_paymentMethod?: CurrentPaymentMethods;
  }

  function intercomSafeServerAttributes(params: ServerCustomAttributes): IntercomSafeServerAttributes {
    return {
      publisher_admin: arrayToString(params.publisherAdmin),
      publisher_member: arrayToString(params.publisherMember),
      school_admin: arrayToString(params.schoolAdminIds),
      school_member: arrayToString(params.schoolMemberIds),
      school_basic: arrayToString(params.schoolBasicMemberIds),
      used_voucher: Boolean(params.redeemedVoucherIds?.length),
      license_voucher_ids: arrayToString(params.redeemedVoucherIds),
      stripeSubscription_cancelAtPeriodEnd: params.cancelAtPeriodEnd,
      stripeSubscription_status: params.status,
    };
  }

  /** @description format for server, all extra properties need to be in custom_attributes */
  export function apiDataForUser(user: MongoUser, attributes: ServerCustomAttributes): ApiData {
    return {
      ...baseData(user),
      custom_attributes: {
        ...extraData(user),
        ...serverSignupDate(user),
        ...intercomSafeServerAttributes(attributes),
      },
    };
  }
}
