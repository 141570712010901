import React from 'react';
import { Toast, ToastButtonParams } from './ToastProvider';

interface Props {
  toast: Toast;
}

interface State {
  show: boolean;
}

export class ToastController extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  public componentDidMount() {
    const { delayMs, actionButton } = this.props.toast;
    const delay = delayMs || actionButton ? 4000 : 2000;
    setTimeout(() => {
      this.hide();
    }, delay);
  }

  public hide = () => {
    const { hide, id } = this.props.toast;
    this.setState({
      show: false,
    });
    setTimeout(() => {
      hide(id);
    }, 400);
  };

  public render() {
    const { actionButton, msg, undo } = this.props.toast;
    const buttons: JSX.Element[] = [];
    if (actionButton) {
      buttons.push(ToastButton({ button: actionButton }));
    }
    if (undo) {
      const undoButton: ToastButtonParams = {
        label: 'ongedaan makken',
        iconName: 'undo',
        action: () => undo,
      };
      buttons.push(ToastButton({ button: undoButton }));
    }
    const Buttons = buttons.length ? <div className="actions">{...buttons}</div> : null;

    const show = this.state.show ? '' : 'hide';

    return (
      <div className={`toast ${show}`}>
        <div className="msg" aria-live="polite">
          {msg}
        </div>
        {Buttons}
        <div className="close-toast icon icon-close2" onClick={() => this.hide()}></div>
      </div>
    );
  }
}

function ToastButton({ button }: { button: ToastButtonParams }) {
  const { iconName, action, label } = button;
  const icon = iconName || 'circle-right';
  return (
    <div
      className={`content-item-button bg-yellow fg-black icon icon-dark icon-${icon}`}
      onClick={() => action()}
      key={label}
    >
      {label}
    </div>
  );
}
