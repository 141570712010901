import React from 'react';
import { ImageUtils } from '../../../../utils/ImageUtils';
import './VideoThumb.less';

interface Props {
  image: string;
  icon: string;
  children?: any;
}

export default class VideoThumb extends React.PureComponent<Props> {
  style = () => {
    const url = ImageUtils.checkImageUrl(this.props.image, true);
    return { backgroundImage: ImageUtils.urlString(url) };
  };

  render() {
    return (
      <div className="video-thumb playerWrapper" style={this.style()}>
        <div className={`icon icon-color icon-${this.props.icon}`}></div>
        {this.props.children}
      </div>
    );
  }
}
