import { useEffect, useRef } from 'react';
import { Language, LanguageSingleton } from '../../../domain/language';

const useLanguageChange = (callback: () => void) => {
  const language = useRef<Language.Key | null>(LanguageSingleton.get());

  useEffect(() => {
    if (language.current !== LanguageSingleton.get()) {
      callback();
      language.current = LanguageSingleton.get();
    }
  }, [callback]);
};

export default useLanguageChange;
