import { compactMap } from '@lessonup/utils';
import _, { intersection } from 'lodash';
import qs from 'qs';
import {
  ArraySelectedFacet,
  DefaultUrlFacets,
  Facet,
  FacetKey,
  Facets,
  isArrayFacet,
  isValueFacet,
  SelectedFacet,
  SingleValueSelectedFacet,
} from './Facets';
import { IndexType } from './IndexType';

export interface SearchParams {
  index: IndexType;
  text: string | undefined;
  facets: SelectedFacet[];
  fixedFacets?: {
    organization?: string[];
  };
  size?: number;
  from?: number;
}

export type SearchUrlParams = {
  [key in DefaultUrlFacets]?: string;
};

export interface SearchRouteParams extends SearchUrlParams {
  language?: string;
  qs?: string;
}

function parseNumberParam(param?: string): number | undefined {
  if (!param || param.length < 1) return undefined;
  return parseInt(param, 10);
}

export namespace SearchParams {
  export function facetsFromParams(
    obj: _.Dictionary<string | string[] | boolean>,
    possibleFacets: Facet[]
  ): SelectedFacet[] {
    return compactMap(possibleFacets, (f): SelectedFacet | undefined => {
      const value = obj[f.key];

      if ((_.isString(value) && _.isEmpty(value)) || value === SearchParams.placeHolder) return undefined;
      if (_.isNil(value)) return undefined;
      if (isValueFacet(f.key) && (_.isString(value) || _.isBoolean(value))) {
        const facet: SingleValueSelectedFacet = {
          key: f.key,
          value: value.toString(),
        };
        if (f.fixed !== undefined) facet.fixed = f.fixed;
        return facet;
      }
      if (isArrayFacet(f.key) && _.isArray(value)) {
        // we don't want to create a facet for an empty array, or it will return an empty result
        if (value.length === 0) return undefined;
        const facet: ArraySelectedFacet = {
          key: f.key,
          value,
        };
        if (f.fixed !== undefined) facet.fixed = f.fixed;
        return facet;
      }
    });
  }

  export function setFacet(
    searchParams: SearchParams,
    key: FacetKey,
    value: string,
    possibleFacets: Facet[]
  ): SearchParams {
    const toAdd = facetsFromParams(
      {
        [key]: value,
      },
      possibleFacets
    );
    const newFacets = searchParams.facets.filter((f) => f.key !== key).concat(toAdd);
    return {
      ...searchParams,
      facets: newFacets,
    };
  }

  export function toDictionary(params: SearchParams): _.Dictionary<string> {
    const pairs = params.facets.map((f) => [f.key, f.value]);
    const dict = _.fromPairs(pairs);
    dict['text'] = params.text;
    dict['size'] = params.size;
    dict['from'] = params.from;

    return _.omitBy(dict, (v) => _.isNil(v) || (_.isString(v) && _.isEmpty(v)));
  }

  export const urlOrder: DefaultUrlFacets[] = ['country', 'subjects', 'schoolType', 'levels', 'years'];
  const urlOrderReversed = urlOrder.slice().reverse();

  type DictOrParams = Record<string, string> | SearchUrlParams;
  export const placeHolder = 'any' as const;
  export function searchUrlPostFix(obj: DictOrParams = {}): string {
    const lastIndex = urlOrderReversed.findIndex((k) => !!obj[k]);
    if (lastIndex == -1) return '';
    const deep = urlOrderReversed.length - lastIndex;
    const keysToInclude = urlOrder.slice(0, deep);
    return keysToInclude.map((k) => obj[k] || placeHolder).join('/');
  }

  export function defaultDictFromParams(params: SearchParams) {
    const pairs = params.facets.map((f) => [f.key, f.value]);
    return defaultDictFromDictionary(_.fromPairs(pairs));
  }

  export function defaultDictFromDictionary(obj: Record<string, string>) {
    return _.pick(obj, urlOrder) as SearchUrlParams;
  }

  export function searchParamsToUrlComponents(params: SearchParams | undefined) {
    if (!params) {
      return {
        queryString: '',
        urlDict: {},
      };
    }
    const obj = toDictionary(params);
    const dict = _.pick(obj, urlOrder) as SearchUrlParams;
    const urlDict = _.mapValues(dict, (v) => v && encodeURIComponent(v));
    const queryDict = _.omitBy(obj, (value, key) => urlDict[key] !== undefined);

    const queryString = qs.stringify(queryDict);
    return {
      queryString,
      urlDict,
    };
  }

  interface FromUrlParams {
    urlParams: SearchUrlParams | undefined;
    queryString: string;
    indexType: IndexType;
    subType?: Facets.FacetSubTypes[];
  }
  export function fromUrlComponents(params: FromUrlParams) {
    const { urlParams, queryString, indexType, subType } = params;
    const qsDict = qs.parse(queryString, {
      ignoreQueryPrefix: true,
    }) as Record<string, string>;
    const decoded = _.mapValues(urlParams, (v) => v && decodeURIComponent(v));
    return fromDictionary(Object.assign({}, decoded, qsDict), indexType, subType);
  }

  export function removeChannelFromFacets(searchParams: SearchParams): SearchParams {
    return {
      ...searchParams,
      facets: searchParams.facets.filter((facet) => facet.key !== 'channel'),
    };
  }

  export function fromDictionary(
    obj: Record<string, string>,
    indexType: IndexType,
    subType?: Facets.FacetSubTypes[]
  ): SearchParams {
    const possibleFacets = Facets.facetsForType(indexType, subType);

    const facets = facetsFromParams(obj, possibleFacets);

    return {
      index: indexType,
      text: obj.text || '',
      facets,
      size: parseNumberParam(obj.size),
      from: parseNumberParam(obj.from),
    };
  }

  export function paramsFromQueryString(queryString: string, indexType: IndexType): SearchParams {
    const obj = qs.parse(queryString, {
      ignoreQueryPrefix: true,
    }) as Record<string, string>;
    return fromDictionary(obj, indexType);
  }

  export function queryStringFromSearchParams(params: SearchParams | undefined) {
    return params ? qs.stringify(toDictionary(params)) : '';
  }

  export function isEmpty(params: SearchParams): boolean {
    return params.facets.length === 0 && _.isEmpty(params.text);
  }

  export function hasFacet(params: SearchParams | undefined, string: string): boolean {
    if (!params) return false;
    return params.facets.some((facet) => facet.key === string);
  }

  export function facetIs(params: SearchParams | undefined, key: string, value: string | string[]): boolean {
    if (!params) return false;
    const facet = params.facets.find((facet) => facet.key === key);
    const facetValue = facet?.value;
    const facetIsArray = Array.isArray(facetValue);
    const valueIsArray = Array.isArray(value);
    if (valueIsArray) {
      return facetIsArray
        ? !!intersection(value, facet!.value).length
        : !!(facetValue && value.includes(facetValue as string));
    }
    return facetIsArray ? facet!.value.includes(value as string) : facet?.value === value;
  }

  export function replaceHistoryState(oldParams: SearchParams, newParams: SearchParams): boolean {
    if (!_.isEqual(_.omit(oldParams, ['size', 'from']), _.omit(newParams, ['size', 'from']))) {
      return false;
    }

    return Boolean(oldParams.size !== newParams.size);
  }
}
