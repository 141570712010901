import { empty, fromEvent, Observable } from 'rxjs';
import { filter, first, map, share, tap } from 'rxjs/operators';

export namespace IframeMessaging {
  type ActionTypes = 'app-started' | 'logged-in' | 'pin-added' | 'image-url' | 'video-url';

  interface IframeMessage {
    action: ActionTypes;
    msg: string;
    api: number;
  }

  let msgObs: Observable<IframeMessage>;

  if (typeof window !== 'undefined') {
    msgObs = fromEvent<MessageEvent>(window, 'message').pipe(
      filter((msg) => {
        return msg.type == 'message' && typeof msg.data == 'string' && msg.data.substr(2, 8) === 'lessonup';
      }),
      map((msg) => {
        return JSON.parse(msg.data)[1] as IframeMessage;
      }),
      share()
    );
  }

  const version = 1;

  function getOpenerWindow() {
    let w = window;
    if (window.opener && window.opener != w) w = window.opener;
    if (window.parent && window.parent != w) w = window.parent as any;
    return w;
  }

  export function send(action: ActionTypes, msg?: string, payload?: any) {
    const w = getOpenerWindow();
    if (!w || !w.postMessage) return false;
    const data = [
      'lessonup',
      {
        action,
        msg: msg || '',
        api: version,
      },
    ];
    const str = JSON.stringify(data);
    w.postMessage(str, '*');
  }

  export function getStream(actionFilter?: ActionTypes) {
    if (!msgObs) {
      return empty();
    }

    if (actionFilter) {
      return msgObs.pipe(filter((msg) => msg.action == actionFilter));
    }
    return msgObs;
  }

  export function promise(actionFilter?: ActionTypes) {
    return getStream(actionFilter)
      .pipe(
        tap((msg) => {
          console.log('hallo', msg);
        }),
        first()
      )
      .toPromise();
  }
}
