import React from 'react';

import { SVGGeneratorProps } from '@lessonup/teaching-core';
import { BaseMathComponent } from './BaseMathComponent';

export const StarComponent: React.FC<SVGGeneratorProps> = ({ position, settings }) => {
  return <BaseMathComponent position={position} settings={settings} pathFunc={starPath} />;
};

export function starPath(width_: number, height_: number, margin_: number): string {
  // Use width and height to draw the path while taking the margin/stroke-size into account
  const margin = margin_ / 2; // Only half of the stroke size will fall outside of the path to devide by 2
  const width = width_ - margin * 2;
  const height = height_ - margin * 2;

  // The decimal numbers are percentages of the points we use to draw the star
  // I calculated these by hand now, if we add the extra grippy functionality we have to calculate this in the code based on a parameter.
  // But for now these values work for a normal pentagram.

  // x and y values are for the coordinates that we need in the path
  const xValues = [
    0 + margin,
    0.191 * width + margin,
    0.309 * width + margin,
    0.382 * width + margin,
    0.5 * width_,
    0.618 * width + margin,
    0.691 * width + margin,
    0.808 * width + margin,
    width + margin,
  ];
  const yValues = [
    0 + margin,
    0.382 * height + margin,
    0.618 * height + margin,
    0.764 * height + margin,
    height + margin,
  ];
  const path = `M${xValues[0]} ${yValues[1]}
     L${xValues[3]} ${yValues[1]}
     L${xValues[4]} ${yValues[0]}
     L${xValues[5]} ${yValues[1]}
     L${xValues[8]} ${yValues[1]}
     L${xValues[6]} ${yValues[2]}
     L${xValues[7]} ${yValues[4]}
     L${xValues[4]} ${yValues[3]}
     L${xValues[1]} ${yValues[4]}
     L${xValues[2]} ${yValues[2]}
     Z`;
  return path;
}
