import { tracker } from '@lessonup/analytics';
import { logger, trackAction } from '@lessonup/client-integration';
import { RegistrationPasswordStep } from '@lessonup/teacher-modern';
import { AppError } from '@lessonup/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { showDefaultErrorNotification } from '../../../../shared-core/client/utils/modals';
import { Auth, LanguageSingleton } from '../../../../shared-core/domain';
import { useOnMountEffect } from '../../../../shared-core/ui/utils/hooks/useOnMountEffect';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import { PageWrapper } from '../../../components/PageWrapper/PageWrapper';
import { loggedInUser } from '../../../redux/selectors';
import { useQuery } from '../../../utils/hooks/queryParamsHook';
import { RegistrationFormProps } from '../types';

type RegistrationPasswordServiceWrapperProps = Pick<RegistrationFormProps, 'next' | 'goToStep'>;

export const RegistrationPasswordServiceWrapper = ({ next, goToStep }: RegistrationPasswordServiceWrapperProps) => {
  const services = useAppServices();
  const emailVerificationToken = useQuery().get('token');
  const user = useSelector(loggedInUser());

  useOnMountEffect(() => {
    if (user) {
      services.user.logout();
    }

    tracker.events.registrationVerificationSuccessful();
  });

  if (!emailVerificationToken) {
    goToStep('verification-expired');
    return null;
  }

  const onSubmit = async ({ password }: { password: string }): Promise<void> => {
    try {
      const success = await services.user.registerWithEmailVerificationTokenPassword({
        emailVerificationToken,
        password,
        language: LanguageSingleton.get(),
      });
      if (!success) throw new AppError('unexpected-data', 'Registration unsuccessful');
      trackAction('register-credentials-finished', { provider: 'password' });
      tracker.events.registrationPasswordCreated();
    } catch (error) {
      logger.error(error);
      tracker.trackError(error);
      tracker.events.registrationError({ errorDescription: error.toString() });
      showDefaultErrorNotification(error);
    }

    next();
  };

  return (
    <PageWrapper>
      <RegistrationPasswordStep
        onValidatePassword={Auth.validateRegistrationPasswordRequirements}
        onSubmit={onSubmit}
        loading={false}
      />
    </PageWrapper>
  );
};
