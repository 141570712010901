import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import { SearchParams, SearchRouteParams } from '../../../../shared-core/domain';
import { lessonSearchRoute } from '../../../../shared-core/services/app/searchRoutes';
import { showTabsForLanguage } from '../../../utils/tabs';
import LessonSearch from './LessonSearch';

type Props = {} & RouteComponentProps<SearchRouteParams>;

const LessonSearchPage: React.FunctionComponent<Props> = (props) => {
  const { history, match } = props;
  const searchParams = SearchParams.fromUrlComponents({
    urlParams: match.params,
    queryString: history.location.search,
    indexType: 'lessons',
  });

  const urlCreator = useCallback((params: SearchParams) => {
    const { queryString, urlDict } = SearchParams.searchParamsToUrlComponents(params);
    return lessonSearchRoute({
      ...urlDict,
      qs: queryString,
    });
  }, []);

  return <LessonSearch showTabs={showTabsForLanguage()} searchParams={searchParams} urlCreator={urlCreator} />;
};

export default LessonSearchPage;
