import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ChannelTitle } from '../../../../shared-core/domain';
import capitalize from '../../../../shared-core/utils/capitalize';
import transformServingImage from '../../../../shared-core/utils/transformServingImage';
import { ChannelTabWithActive } from '../ChannelPageView';

const TRANSLATION_KEY = 'channelPageMeta';
const tabsToExcludeForTitle = ['search', 'plan'];

interface Props {
  channelTitle: ChannelTitle;
  channelSubtitle: ChannelTitle;
  channelIcon: string | undefined;
  page: string;
  subPage: string;
  channelTabs: ChannelTabWithActive[];
}

const ChannelPageMeta: React.FC<Props> = ({
  channelTitle,
  channelSubtitle,
  page,
  subPage,
  channelIcon,
  channelTabs,
}) => {
  const { t } = useTranslation(TRANSLATION_KEY);
  const localizedTitle = useMemo(() => ChannelTitle.localizeTitle(channelTitle), [channelTitle]);
  const localizedSubtitle = useMemo(() => ChannelTitle.localizeTitle(channelSubtitle), [channelSubtitle]);
  const image = imageForPage({ subPage, channelIcon });
  const description = localizedTitle && t('description', { channelTitle: localizedTitle });

  const seoTitle = useMemo(() => {
    if (tabsToExcludeForTitle.includes(page)) {
      return null;
    }

    const activeTab = channelTabs.find((tab) => tab.isActive);
    const activeTabName = activeTab?.name || '';
    const hasChannelTitleAndSubtitle = localizedTitle && localizedSubtitle;
    const hasNoActiveTabOrIsStartTabSelected = !activeTab || page === 'start';

    if (hasChannelTitleAndSubtitle && hasNoActiveTabOrIsStartTabSelected) {
      return composeSeoTitle({ prefix: localizedTitle, suffix: localizedSubtitle });
    }

    if (hasChannelTitleAndSubtitle) {
      return composeSeoTitle({ prefix: localizedTitle, suffix: activeTabName });
    }

    if (localizedTitle) {
      const suffix = hasNoActiveTabOrIsStartTabSelected ? '' : activeTabName;
      return composeSeoTitle({ prefix: localizedTitle, suffix });
    }

    return null;
  }, [page, localizedTitle, localizedSubtitle, channelTabs]);

  return (
    <Helmet>
      {seoTitle && <title>{seoTitle}</title>}
      <meta name="robots" content="index" />
      {description && <meta name="description" content={description} />}
      {image && <meta name="og:image" content={image} />}
    </Helmet>
  );
};

export default ChannelPageMeta;

type ImageForPageArgs = {
  subPage: string;
  channelIcon: string | undefined;
};

function imageForPage({ subPage, channelIcon }: ImageForPageArgs) {
  const subPagesToExclude = ['lesson', 'plan'];
  if (subPagesToExclude.includes(subPage) || !channelIcon) {
    return null;
  }

  return transformServingImage(channelIcon, {});
}

type ComposeSeoTitleArgs = {
  prefix: string;
  suffix: string;
};

function composeSeoTitle({ prefix, suffix }: ComposeSeoTitleArgs): string {
  if (suffix === '') {
    return capitalize(prefix);
  }

  return capitalize(`${prefix} - ${capitalize(suffix)}`);
}
