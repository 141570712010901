import { AssignmentPin } from '@lessonup/teaching-core';
import React from 'react';
import GoogleMapsSvg from '../../svgIcons/GoogleMaps';
import './mapView.less';

interface Props {
  pin: AssignmentPin.Map;
}

export default class MapThumb extends React.Component<Props> {
  public render() {
    return (
      <div style={{ height: 'inherit' }}>
        <div className="thumb map-thumb">
          <GoogleMapsSvg className="limit-to-parentsize" />
        </div>
      </div>
    );
  }
}
