import React from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from '../../../../utils/capitalize';
import TextButton from '../../../components/buttons/TextButton';
import { ModalProps } from '../../../utils/ModalManagerService';
import ActionButton from '../../Button';
import { Modal } from '../Modal';
import ModalButtonBar from '../ModalButtonBar';

export interface FollowChannelModalConfig {
  channelSlug: string;
  channelTitle?: string;
  handleSubmit: () => Promise<FollowChannelModalResponse>;
}

export interface FollowChannelModalResponse {
  success: boolean;
}

type Props = ModalProps<FollowChannelModalResponse> & FollowChannelModalConfig;

const TRANSLATION_NAMESPACE = 'followChannelModal';

const FollowChannelModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  function handleClose() {
    return props.closeModal({ success: false });
  }

  async function handleSubmit() {
    const result = await props.handleSubmit();
    props.closeModal(result);
  }

  return (
    <Modal
      title={capitalize(t('follow')) + ' ' + props.channelTitle || t('channelTitleFallback')}
      isOpen
      onRequestClose={handleClose}
      width="medium"
    >
      <p className="modal-content">{t('requireFollowDescription')}</p>
      <ModalButtonBar>
        <ActionButton theme="primary" onClick={handleSubmit}>
          {t('followChannel')}
        </ActionButton>
        <TextButton onClick={handleClose}>{t('dismiss')}</TextButton>
      </ModalButtonBar>
    </Modal>
  );
};

export default FollowChannelModal;
