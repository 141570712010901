import { isEqual } from 'lodash';
import { useCallback, useMemo } from 'react';
import {
  ExplorerContent,
  ExplorerContentSplit,
  ExplorerFolderContent,
  ExplorerSharedExplorerContent,
  ExplorerUserContent,
} from '../../../../domain/newExplorers';
import { ORDER, SortMeta, SortOnFunction, useSorting } from '../../Table';

const INITIAL_SORT_META: SortMeta<ExplorerContent> = {
  property: ['content', 'order'],
  order: ORDER.ascending,
};

const INITIAL_SORT_NAME: SortMeta<ExplorerContent> = {
  property: ['content', 'name'],
  order: ORDER.ascending,
};

export const useExplorerSorting = (
  contents: ExplorerContentSplit
): {
  sortMeta: SortMeta<ExplorerContent> | undefined;
  sortOnProperty: SortOnFunction<ExplorerContent>;
  sorted: ExplorerContent[];
  isSorted: boolean;
} => {
  const [sortedLessonsAndLessonPlans, sortMeta, sortLessonsAndLessonPlansOnProperty] = useSorting<ExplorerUserContent>(
    [...contents.lessons, ...contents.lessonPlans, ...contents.links],
    INITIAL_SORT_META,
    true
  );

  const [sortedFolders, _sortMetaFolders, sortFoldersOnProperty] = useSorting<ExplorerFolderContent>(
    contents.folders,
    INITIAL_SORT_META,
    true
  );
  const [sortedShared, _sortMetaShared, sortSharedOnProperty] = useSorting<ExplorerSharedExplorerContent>(
    contents.sharedExplorers,
    INITIAL_SORT_NAME,
    true
  );

  const sorted = useMemo(
    () => [...sortedShared, ...sortedFolders, ...sortedLessonsAndLessonPlans],
    [sortedShared, sortedFolders, sortedLessonsAndLessonPlans]
  );

  const sortOnProperty: SortOnFunction<ExplorerContent> = useCallback(
    (property, options) => {
      sortFoldersOnProperty(property, options);
      sortLessonsAndLessonPlansOnProperty(property, options);
      sortSharedOnProperty(property, options);
    },
    [sortFoldersOnProperty, sortLessonsAndLessonPlansOnProperty, sortSharedOnProperty]
  );

  const isSorted = !isEqual(INITIAL_SORT_META, sortMeta);

  return { sortMeta, sortOnProperty, sorted, isSorted };
};
