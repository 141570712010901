import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Facets, SelectedFacet } from '../../../../../shared-core/domain';
import Heading from '../../../../../shared-core/ui/components/Heading';

interface SearchResultsTitleProps {
  selectedFacets: SelectedFacet[];
  count: number;
  type: 'lesson' | 'plan';
}

const SearchResultsTitle: React.FC<SearchResultsTitleProps> = ({ selectedFacets, count, type }) => {
  const { t } = useTranslation('searchResultsTitle');
  const { subject, schoolType, yearAndLevel } = Facets.obtainMetaDataInfoForSelectedFacets(selectedFacets);

  return (
    <Heading size="h1" as="h1" className="searchpage-result-header--title">
      {t(type, { count, formattedCount: count.toLocaleString() }) +
        ' ' +
        classNames(subject, schoolType && schoolType.toLowerCase(), yearAndLevel)}
    </Heading>
  );
};

export default SearchResultsTitle;
