import { logger } from '@lessonup/client-integration';
import { asyncScheduler, Observable, ReplaySubject, SchedulerLike } from 'rxjs';
import { distinctUntilChangedIsEqual, firstAsPromise } from '../../../utils';

export class MeteorUserIdService {
  private readonly meteorUserId: ReplaySubject<string | undefined>;

  public constructor(scheduler: SchedulerLike = asyncScheduler) {
    this.meteorUserId = new ReplaySubject(1, undefined, scheduler);
  }

  public idAsObservable(): Observable<string | undefined> {
    return this.meteorUserId.asObservable().pipe(distinctUntilChangedIsEqual());
  }

  public firstAsPromise(): Promise<string | undefined> {
    return firstAsPromise(this.meteorUserId.asObservable());
  }

  public setMeteorUserId(id: string | undefined | null): void {
    console.info('MeteorUserId', id);
    logger.trace('auth-meteor', `${id}`);
    this.meteorUserId.next(id || undefined);
  }
}
