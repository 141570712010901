import { logger } from '@lessonup/client-integration';
import { Country } from '../../../../../../shared-core/domain/country/Country';
import { UnitedStates } from '../../../../../../shared-core/domain/country/UnitedStates';
import { IndexedSchoolSuggestion } from '../../../../../../shared-core/domain/schoolSuggestions/IndexedSchoolSuggestion';

export type FormStateSelectedSchool =
  | {
      type: 'manualEntry';
      name: string;
      city: string;
    }
  | {
      type: 'selectedSuggestion';
      selectedSuggestion: IndexedSchoolSuggestion;
    };

export interface FormState {
  countryCode: Country.CountryCode | undefined;
  state: UnitedStates.StateCode | undefined;
  worksAtSchool: boolean;
  selectedSchool: FormStateSelectedSchool | undefined;
  message: { type: 'error' | 'success'; message: string } | undefined;
  reasonMessage: string | undefined;
  showManualEntry?: boolean;
}

export interface SetCountryAction {
  type: 'setCountry';
  countryCode: Country.CountryCode | undefined;
}

export interface SetUSStateAction {
  type: 'setState';
  usState: UnitedStates.StateCode | undefined;
}

interface SetConfirmationMessageAction {
  type: 'setConfirmationMessage';
  message: string | undefined;
}

interface SetErrorMessageAction {
  type: 'setErrorMessage';
  message: string | undefined;
}

interface ClearMessageAction {
  type: 'clearMessage';
}

interface SetReasonAction {
  type: 'setReason';
  reason: string | undefined;
}

interface SetSchoolTypeToNoneAction {
  type: 'setSchoolTypeToNone';
  schoolTypeIsNone: boolean;
}

interface SetSchoolAction {
  type: 'setSchool';
  selectedSchool: FormStateSelectedSchool | undefined;
}

interface SetManualEntrySchoolNameAction {
  type: 'setManualEntrySchoolName';
  name: string;
}

interface SetManualEntrySchoolCityAction {
  type: 'setManualEntrySchoolCity';
  city: string;
}

export type FormStateAction =
  | SetCountryAction
  | SetUSStateAction
  | SetConfirmationMessageAction
  | SetErrorMessageAction
  | ClearMessageAction
  | SetReasonAction
  | SetSchoolTypeToNoneAction
  | SetSchoolAction
  | SetManualEntrySchoolNameAction
  | SetManualEntrySchoolCityAction;

export function schoolPickerPageReducer(state: FormState, action: FormStateAction): FormState {
  switch (action.type) {
    case 'setCountry':
      return {
        ...state,
        countryCode: action.countryCode,
        message: undefined,
        selectedSchool: undefined,
      };
    case 'setState':
      return { ...state, state: action.usState };
    case 'setConfirmationMessage':
      return { ...state, message: { message: action.message!, type: 'success' } };
    case 'setErrorMessage':
      return { ...state, message: { message: action.message!, type: 'error' } };
    case 'clearMessage':
      return { ...state, message: undefined };
    case 'setReason':
      return { ...state, reasonMessage: action.reason };
    case 'setSchoolTypeToNone':
      return action.schoolTypeIsNone
        ? {
            ...state,
            worksAtSchool: false,
            message: undefined,
            selectedSchool: undefined,
          }
        : {
            ...state,
            worksAtSchool: true,
            message: undefined,
          };
    case 'setSchool':
      return {
        ...state,
        selectedSchool: action.selectedSchool,
        worksAtSchool: true,
        reasonMessage: undefined,
      };
    case 'setManualEntrySchoolName':
      if (state.selectedSchool?.type !== 'manualEntry') {
        return { ...state, selectedSchool: { type: 'manualEntry', name: action.name, city: '' } };
      }

      return {
        ...state,
        selectedSchool: {
          ...state.selectedSchool,
          name: action.name,
        },
        message: undefined,
      };
    case 'setManualEntrySchoolCity':
      if (state.selectedSchool?.type !== 'manualEntry') {
        return { ...state, selectedSchool: { type: 'manualEntry', city: action.city, name: '' } };
      }

      return { ...state, selectedSchool: { ...state.selectedSchool, city: action.city } };
    default:
      logger.error('Reducer action not handled', { action });
      return state;
  }
}
