import _ from 'lodash';
import { RenderingOnClient } from './clientOrServer';
import ensureHttps from './ensureHttps';
// COPIED FROM _globalUtils.js

export namespace ImageUtils {
  /**
   * Fix the url for images that are possible scalable
   */
  export function checkImageUrl(
    url: string | undefined | null,
    isThumb?: boolean,
    forceWidth?: number,
    useDevicePixelRatio = true
  ): string | undefined {
    if (_.isNil(url)) return '';
    if (isRelative(url)) return url;

    const isGoogleImg = (url + '').indexOf('googleusercontent.') > -1;
    if (isGoogleImg) {
      return addOptionalImageSizing(url, isThumb, forceWidth, useDevicePixelRatio);
    }
    return ensureHttpsOrProxy(url);
  }

  /** Do not return url(undefined) to the dom, it will fetch the page */
  export function urlString(url: string | undefined) {
    return url ? `url(${url})` : undefined;
  }

  function isRelative(url) {
    if (!url) return false;
    if (/^\/.*$/i.test(url)) return true;
    return false;
  }

  function prefixImageProxy(url) {
    if (!url || isRelative(url)) return url;
    return 'https://lessonup-proxy.appspot.com/imageproxy?url=' + encodeURIComponent(url);
  }

  function addOptionalImageSizing(
    url: string | undefined,
    isThumb?: boolean,
    forceWidth?: number,
    useDevicePixelRatio?: boolean
  ) {
    if (!url || isRelative(url)) return url;
    if ((url + '').indexOf('googleusercontent.') > -1) {
      let s = 1280;

      if (forceWidth) {
        s = forceWidth;
      } else if (isThumb) {
        s = 250;
      }

      if (useDevicePixelRatio && RenderingOnClient && window.devicePixelRatio) {
        s = Math.floor(s * window.devicePixelRatio);
      }

      url += '=s' + s;
    } else if ((url + '').indexOf('.appspot.com') == -1) {
      // route ALL external images via our image https proxy!
      url = 'https://lessonup-proxy.appspot.com/imageproxy?url=' + encodeURIComponent(url);
    }
    return ensureHttps(url);
  }

  function ensureHttpsOrProxy(url: string | undefined): string | undefined {
    if (!url || isRelative(url)) return url;
    if (url.toLowerCase().indexOf('https://') == 0) return url;
    return prefixImageProxy(url);
  }
}
