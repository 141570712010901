import classNames from 'classnames';
import React, { ChangeEvent } from 'react';

import { Poll } from '@lessonup/teaching-core';
import './PollScaleInput.less';

interface Props {
  answerData: { range: Poll.Answer.Range; answer: number } | undefined;
  className?: string;
  onChange?: (val: number) => void;
  style?: React.CSSProperties;
}

export const PollScaleInput: React.FC<Props> = (props) => {
  const range = props.answerData?.range || { min: 0, max: 100 };

  return (
    <input
      disabled={!props.onChange}
      className={classNames('poll-scale', props.className)}
      name="student-scale"
      style={props.style}
      type="range"
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        if (!props.onChange) return;
        const rangeValue = parseInt(event.target.value, 10);
        props.onChange(rangeValue);
      }}
      min={range.min}
      max={range.max}
      step={1}
      value={Math.round(props.answerData?.answer ?? Math.round((range.max - range.min) / 2) + range.min)}
    ></input>
  );
};
