import { FractionalIndex, ID, Lesson } from '@lessonup/teaching-core';
import { Identifiable, Location } from '@lessonup/utils';
import { groupBy } from 'lodash';
import { LessonPlan } from '../lessonPlan';

export interface SymbolicLink<T extends SymbolicLink.Type = SymbolicLink.Type> extends Identifiable {
  type: T;
  location: Location;
  order: FractionalIndex.OrderKey;
  creationDate: Date;
  contentId: string;
  user?: ID;
}

export namespace SymbolicLink {
  export type Type = 'lesson' | 'lessonPlan';

  export interface WithLesson extends SymbolicLink {
    type: 'lesson';
    content: Lesson | undefined;
  }
  export interface WithPlan extends SymbolicLink {
    type: 'lessonPlan';
    content: LessonPlan | undefined;
  }
  export type WithContent = WithLesson | WithPlan;

  export interface WithLessonComplete extends WithLesson {
    content: Lesson;
  }

  export interface WithPlanComplete extends WithPlan {
    content: LessonPlan;
  }

  export interface WithContentForType {
    lesson: WithLesson;
    lessonPlan: WithPlan;
  }

  export function isLessonContentComplete(contentItem: WithContent): contentItem is WithLessonComplete {
    return contentItem.type === 'lesson' && !!contentItem.content;
  }

  export function isPlanContentComplete(contentItem: WithContent): contentItem is WithPlanComplete {
    return contentItem.type === 'lessonPlan' && !!contentItem.content;
  }

  export function contentWithSymlinkOrder(symLink: WithPlanComplete): LessonPlan;
  export function contentWithSymlinkOrder(symLink: WithLessonComplete): Lesson;
  export function contentWithSymlinkOrder(symLink: WithContent): WithContent['content'] | undefined {
    if (!symLink.content) return undefined;
    const order = symLink.order;
    return {
      ...symLink.content,
      order,
    };
  }

  export function groupByType(links: SymbolicLink[]): Partial<Record<Type, SymbolicLink<Type>[]>> {
    return groupBy(links, 'type') as Partial<Record<Type, SymbolicLink<Type>[]>>;
  }

  export function groupWithContent(links: WithContent[]): Partial<Record<Type, WithContentForType[Type][]>> {
    return groupBy(links, 'type') as Partial<Record<Type, WithContentForType[Type][]>>;
  }
}
