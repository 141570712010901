import React from 'react';
//https://github.com/smooth-code/svgr

const GoogleMapsSvg = (props) => (
  <svg viewBox="0 0 48 48" {...props}>
    <path d="M42 39V9a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3z" fill="#1c9957" />
    <path d="M9 42h30c1.656 0-15-16-15-16S7.344 42 9 42z" fill="#3e7bf1" />
    <path d="M42 39V9c0-1.656-16 15-16 15s16 16.656 16 15z" fill="#cbccc9" />
    <path d="M39 42a3 3 0 0 0 3-3v-.246L26.246 23 23 26.246 38.754 42z" fill="#efefef" />
    <path d="M42 9a3 3 0 0 0-3-3h-.246L6 38.754V39a3 3 0 0 0 3 3h.246L42 9.246z" fill="#ffd73d" />
    <path
      d="M36 2c-5.523 0-10 4.477-10 10 0 6.813 7.664 9.297 9.332 19.852.11.68.117 1.148.668 1.148.55 0 .559-.469.668-1.148C38.336 21.297 46 18.812 46 12c0-5.523-4.477-10-10-10z"
      fill="#d73f35"
    />
    <path d="M39.5 12a3.5 3.5 0 1 1-7 0 3.5 3.5 0 1 1 7 0z" fill="#752622" />
    <path
      d="M14.492 12.531v2.102h2.996c-.394 1.273-1.457 2.183-2.996 2.183a3.316 3.316 0 0 1-3.316-3.316 3.317 3.317 0 0 1 3.316-3.32c.824 0 1.578.304 2.156.8l1.547-1.546A5.5 5.5 0 1 0 14.492 19c4.813 0 5.637-4.316 5.184-6.46z"
      fill="#fff"
    />
  </svg>
);

export default GoogleMapsSvg;
