import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Logo, { LogoProps } from '../../../shared-core/ui/components/logos/rocketLogo';
import { clearGlobalErrorAction } from '../../redux/actions/actions';
import { currentError } from '../../redux/selectors';

type Props = Omit<LogoProps, 'onClick'>;
export function SearchAppLogo(props: Props) {
  // for the error page, we want to clear the error when the user clicks the logo
  // otherwise they can't get back to the home page
  const dispatch = useDispatch();
  const error = useSelector(currentError());

  const handleOnClick = () => {
    if (error) {
      dispatch(clearGlobalErrorAction());
    }
  };

  return <Logo onClick={handleOnClick} {...props} />;
}
