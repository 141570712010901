import React, { SVGProps } from 'react';

const InfoSvg = (props: SVGProps<any>) => (
  <svg width={7} height={14} {...props}>
    <g fill="#5A4D4C" fillRule="nonzero">
      <path d="M6.549 12.102c-.508 1.958-5.656 2.103-5.294-.507.218-1.74.58-3.408.87-5.148.146-1.087-.58-.725-1.16-.942-.434-.218-.434-.725.073-.798.653-.145 2.9-.072 3.697-.072.435 0 .653.217.653.58.072.363 0 .725-.072 1.016-.29 1.667-.725 3.407-.87 5.003-.072.363 0 1.015.507 1.015.507-.072.725-.29 1.16-.435.29-.145.435.073.435.29v-.002zM4.01.355c1.016 0 1.74.798 1.74 1.74 0 1.015-.797 1.813-1.74 1.74-.941 0-1.74-.798-1.74-1.74S3.07.355 4.01.355z" />
    </g>
  </svg>
);

export default InfoSvg;
