import ensureHttps from './ensureHttps';

export type TransformOptions = { size?: number; width?: number; height?: number; format?: keyof typeof imageFormats };

const imageFormats = {
  jpg: 'rj',
  png: 'rp',
  webp: 'rw',
  gif: 'rg',
};

/**
 * Transform images generated by [`get_serving_url`](https://cloud.google.com/appengine/docs/standard/python/images/#get-serving-url)
 * List of all URI options: https://stackoverflow.com/a/25438197
 */
export default function transformServingImage(_imageUrl: string, options: TransformOptions) {
  const imageUrl = ensureHttps(_imageUrl);
  const params = getUriParamsFromOptions(options);
  return params.length > 0 ? imageUrl + '=' + params : imageUrl;
}

/**
 * URI params for appengine get_serving_url
 * List of all URI options: https://stackoverflow.com/a/25438197
 */
function getUriParamsFromOptions({ size, width, height, format }: TransformOptions): string {
  const result: string[] = [];

  if (size && size >= 0) {
    result.push(`s${size}`);
  }

  if (width && width >= 0) {
    result.push(`w${Math.round(width)}`);
  }
  if (height && height >= 0) {
    result.push(`h${Math.round(height)}`);
  }

  if (width && height) {
    result.push('c');
  }

  if (format && imageFormats[format]) {
    result.push(imageFormats[format]);
  }

  return result.join('-');
}
