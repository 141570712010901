import _ from 'lodash';
import { IndexedChannelInfo } from '.';
import { FacetMetaData } from './FacetMetaData';

export interface IndexedContent {
  important: string[];
  normal: string[];
}

export namespace IndexedContent {
  export function flatten(items: IndexedContent[]): IndexedContent {
    const empty: IndexedContent = { important: [], normal: [] };

    return items.reduce(
      (acc, item) => ({
        important: acc.important.concat(item.important),
        normal: acc.normal.concat(item.normal),
      }),
      empty
    );
  }

  export function addContentForMultiMatch(
    current: IndexedContent,
    documentName: string | undefined,
    metaData: FacetMetaData,
    channel: IndexedChannelInfo | undefined
  ): IndexedContent {
    const uniqChannelNames = channel
      ? _.uniq([channel.name, ..._.flatMap(channel.title)].map((str) => str?.toLowerCase()))
      : [];

    const important: string[] = _.compact([
      ...current.important,
      ...uniqChannelNames,
      documentName,
      ...metaData.subjects,
    ]);
    return {
      important,
      normal: current.normal,
    };
  }
}
