import React from 'react';
import { useTranslation } from 'react-i18next';
import './pill.less';

const TestPill = (props) => {
  const { t } = useTranslation('common');
  return (
    <div {...props} className={'test-pill'}>
      {t('test')}
    </div>
  );
};

export default TestPill;
