import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import ButtonLoader from './ButtonLoader';
import './ShowMoreButton.less';

interface Props {
  show: boolean;
  onClick: () => void;
  fetching?: boolean;
}

export default function ShowMoreButton({ show, onClick, fetching }: Props) {
  const { t } = useTranslation('common');

  return show ? (
    <Button onClick={onClick} className="show-more-button disable-disabled-styles" theme="grey" disabled={fetching}>
      <span style={{ visibility: fetching ? 'hidden' : 'visible' }}>{t('showMore')}</span>
      {fetching && <ButtonLoader />}
    </Button>
  ) : null;
}
