import { compact, isEqual, ListIterator, Many, NotVoid, orderBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export const ORDER = {
  descending: 'desc',
  ascending: 'asc',
} as const;
export type Order = typeof ORDER['descending'] | typeof ORDER['ascending'];
const DEFAULT_ORDER = ORDER.ascending;

export type CompareFunction<T> = (a: T, b: T) => number;
export type SortOnOptions<T> = { order?: Order; compareFunction?: CompareFunction<T> };
export type SortableProperty<T> = [Extract<keyof T, string>, string?];
export type SortOnFunction<T> = (property: SortableProperty<T>, options?: SortOnOptions<T>) => void;

const keysToIteratorFunction = <T>(keys: SortableProperty<T>): Many<ListIterator<T, NotVoid>> => {
  return (toBeSorted) => {
    let valueToSortOn: any = toBeSorted;

    for (const key of keys) {
      if (!key) return;

      const levelDeeper = valueToSortOn[key];
      valueToSortOn = levelDeeper;
    }

    return valueToSortOn;
  };
};

export interface SortMeta<T> {
  order: Order;
  property: string | string[];
  compareFunction?: CompareFunction<T>;
}

interface InnerSortMeta<T> extends SortMeta<T> {
  initialOrder?: Order;
}

export const oppositeOrder = (order: Order): Order => (order === ORDER.ascending ? ORDER.descending : ORDER.ascending);

export const useSorting = <T>(
  items: T[],
  initialMeta: SortMeta<T>,
  revertToInitialAfterSecondSort?: boolean
): [T[], SortMeta<T> | undefined, SortOnFunction<T>] => {
  const [sortMeta, setSortMeta] = useState<InnerSortMeta<T>>(initialMeta);

  const sortOn: SortOnFunction<T> = useCallback(
    (property, { order, compareFunction } = {}) => {
      setSortMeta((currentMeta) => {
        const isSameAsPrevious = isEqual(property, currentMeta.property);
        const initialOrderForProperty = (isSameAsPrevious ? currentMeta?.initialOrder : order) || DEFAULT_ORDER;

        if (revertToInitialAfterSecondSort && initialMeta) {
          const isSecondSort = isSameAsPrevious && currentMeta.order !== initialOrderForProperty;
          if (isSecondSort) {
            return initialMeta;
          }
        }

        const updatedOrder =
          order || isSameAsPrevious ? oppositeOrder(currentMeta.order) : currentMeta.order || DEFAULT_ORDER;
        const compactChain = compact(property);

        return {
          order: updatedOrder,
          property: compactChain,
          compareFunction,
          initialOrder: initialOrderForProperty,
        };
      });
    },
    [initialMeta, revertToInitialAfterSecondSort]
  );

  const sorted = useMemo(() => {
    if (sortMeta?.compareFunction) {
      const customSorted = [...items].sort(sortMeta?.compareFunction);
      return sortMeta.order === ORDER.ascending ? customSorted : customSorted.reverse();
    }

    const propertyChain = Array.isArray(sortMeta?.property) ? sortMeta?.property : [sortMeta?.property];
    const compactChain = compact(propertyChain);
    const iterator =
      compactChain.length === 1 ? compactChain : keysToIteratorFunction<T>(compactChain as SortableProperty<T>);

    return orderBy(items, iterator, sortMeta?.order);
  }, [items, sortMeta]);

  return [sorted, sortMeta, sortOn];
};

export const headCopyWithOrder = (order: Order, text: string): string =>
  order === ORDER.descending ? `${text} ↓` : `${text} ↑`;
