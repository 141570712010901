import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';
import Button from '../Button';
import { ButtonAsButtonProps } from '../Button/Button';
import ButtonLoader from './ButtonLoader';
import './ShowMoreButton.less';

interface Props {
  onClick?: () => void;
  loading?: boolean;
}

const ButtonWithLoader: React.FC<Props & ButtonHTMLAttributes<HTMLButtonElement> & ButtonAsButtonProps> = ({
  onClick,
  loading,
  children,
  className,
  ...props
}) => {
  return (
    <Button
      {...props}
      onClick={onClick}
      className={classNames('show-more-button', className)}
      theme={props.theme || 'light'}
      disabled={loading || props.disabled}
    >
      <span style={{ visibility: loading ? 'hidden' : 'visible' }}>{children}</span>
      {loading && <ButtonLoader />}
    </Button>
  );
};

export default ButtonWithLoader;
