import React from 'react';
import './LogoRedRocketCircle.less';
import RocketSvg from './RocketSvg';

const LogoRedRocketCircle = (props) => (
  <div className="logo-red-rocket-circle" {...props}>
    <div className="logo-circle"></div>
    <RocketSvg className="rocket-svg" />
  </div>
);

export default LogoRedRocketCircle;
