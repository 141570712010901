import { captureMessage, withScope } from '@sentry/browser';
import { InitOptions } from 'i18next';

export const missingKeyHandler: InitOptions['missingKeyHandler'] = (lang, namespace, key, fallbackValue) => {
  const message = `Missing key ${key} in namespace ${namespace} for language ${lang}. Using ${fallbackValue}`;
  console.warn(message);
  withScope((scope) => {
    scope.setLevel('warning');
    // Set tags so that we can easily search for this warning in Sentry
    scope.setTag('category', 'i18n');
    scope.setTag('key', 'missing-translation');
    captureMessage(message);
  });
};
