import { UserContext } from '../../../shared-core/domain';
import { AnyAction } from '../actions/actions';

export interface UserState {
  user: UserContext | undefined; // TODO: probably change this type
}

const initialState: UserState = { user: undefined };

export function userReducer(state: UserState = initialState, action: AnyAction): UserState {
  switch (action.type) {
    case 'fetchedUser':
      return {
        user: action.user,
      };
    case 'signOutUser':
      return {
        user: undefined,
      };
    default:
      return state;
  }
}
