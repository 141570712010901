import React from 'react';
import { useTranslation } from 'react-i18next';
import { LessonAndPlanCountTuple } from '../../../shared-core/domain/newExplorers';

interface Props {
  count: LessonAndPlanCountTuple;
}
const FolderCounts: React.FC<Props> = ({ count }) => {
  const { t } = useTranslation('common');
  const [lessonCount, planCount] = count;

  const lesson = () => {
    if (!lessonCount && planCount) return null;
    return <p>{t('lessonCount', { count: lessonCount })}</p>;
  };
  const plan = () => {
    if (!planCount) return null;
    return <p>{t('lessonPlanCount', { count: planCount })}</p>;
  };

  return (
    <div className="lesson-count">
      {lesson()}
      {plan()}
    </div>
  );
};
export default FolderCounts;
