import _ from 'lodash';
import React from 'react';
import { ChannelTitle, IndexedChannelInfo } from '../../../shared-core/domain';
import { channelRoute } from '../../../shared-core/services/app/searchRoutes';
import ThumbBanner from './ThumbBanner';

interface Props {
  channel: IndexedChannelInfo | undefined;
  locale: string;
  channelPublic?: boolean;
}

const ThumbBannerChannel: React.FC<Props> = (props) => {
  const { channel, locale, channelPublic } = props;

  if (!_.isUndefined(channelPublic) && !channelPublic) return null;
  if (!locale || !channel?.title || !channel.name || !channel.icon) return null;
  const { name, icon, title } = channel;

  const label = ChannelTitle.localizeTitle(title, locale as any);
  return <ThumbBanner content={label} bannerLink={channelRoute({ channelSlug: name })} icon={icon} />;
};

export default ThumbBannerChannel;
