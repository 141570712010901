import { Component } from '@lessonup/teaching-core';
import React from 'react';
import FakeSpinnerSvg from '../../svgIcons/FakeSpinnerSvg';
import ComponentContainer from '../componentContainer/ComponentContainer';

interface Props {
  component: Component.Spinner;
  onClickHandler?: Function;
  isThumb?: boolean;
}

export default class SpinnerComponentView extends React.PureComponent<Props> {
  render() {
    return (
      <ComponentContainer component={this.props.component}>
        <FakeSpinnerSvg />
      </ComponentContainer>
    );
  }
}
