import { Lesson, LessonContent, LessonPin, Video } from '@lessonup/teaching-core';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BREAKPOINTS } from '../../../../domain/ui/constants/breakpoints';
import PinView from '../../../../ui/components/pin/PinView';
import ArrowLeft from '../../../../ui/components/svgIcons/ArrowLeft';
import ArrowRight from '../../../../ui/components/svgIcons/ArrowRight';
import { useFontSizeForRef } from '../../../utils/hooks/fontResizeHooks';
import UnstyledButton from '../../UnstyledButton';
import './lessonPreview.less';

const TRANSLATION_NS = 'lessonDetails';

export interface Props {
  lesson: Lesson;
  pins: LessonPin[];
  preselectedPin?: string;
  onPinSelected?: (pinId: string) => void;
  children: React.ReactNode;
}

export interface State {
  selectedPinIndex: number;
}

export const LessonPreview: React.FC<Props> = ({ pins, preselectedPin, children, onPinSelected }) => {
  const [selectedPinIndex, setSelectedPinIndex] = useState(getInitialPinIndex());
  const { t } = useTranslation(TRANSLATION_NS);

  function getInitialPinIndex(): number {
    if (!preselectedPin) {
      return 0;
    }

    const pinIndex = pins.findIndex((pin) => pin._id === preselectedPin);

    if (pinIndex === -1) {
      console.warn('lessonPreview got a preselectedPin prop that does not exist!');
      return 0;
    }

    return pinIndex;
  }

  function moveActiveSlide(change: number) {
    const newIndex = selectedPinIndex + change;

    if (newIndex < 0 || newIndex > pins.length - 1) {
      return;
    }

    setActiveSlide(newIndex);
  }

  function setActiveSlide(slideIndex: number) {
    setSelectedPinIndex(slideIndex);

    if (onPinSelected) {
      onPinSelected(pins[slideIndex]._id);
    }
  }

  function handleRightClick(e: React.MouseEvent) {
    moveActiveSlide(1);
  }

  function handleLeftClick(e: React.MouseEvent) {
    moveActiveSlide(-1);
  }

  function handleKeyInput(event: React.KeyboardEvent) {
    // Left arrow
    if (event.which === 37) {
      moveActiveSlide(-1);
    }

    // Right arrow
    if (event.which === 39) {
      moveActiveSlide(1);
    }
  }

  const selectedPin = pins[selectedPinIndex];

  if (!selectedPin) {
    throw new Error('No selectedpin!');
  }

  return (
    <div className="lesson-preview" tabIndex={0} onKeyDown={handleKeyInput}>
      <div className="lesson-preview__player-container">
        <div className="relative">
          <SelectedPinView pin={selectedPin}>
            <div className="lesson-preview__player-nav">
              <div
                className={classNames('player-nav-button', 'player-nav-button-left', {
                  clickable: selectedPinIndex > 0,
                })}
                onClick={handleLeftClick}
              >
                <UnstyledButton className="lesson-preview__player-navbutton">
                  <ArrowLeft className="arrow-left" width={10} height={12} />
                </UnstyledButton>
                {selectedPinIndex > 0 && <span className="navigation-text-button">{t('previous')}</span>}
              </div>
              <div className="lesson-preview__player-nav-count">
                <span>
                  {selectedPinIndex + 1} / {pins.length}
                </span>
              </div>
              <div
                className={classNames('player-nav-button', 'player-nav-button-right', {
                  clickable: selectedPinIndex < pins.length - 1,
                })}
                onClick={handleRightClick}
              >
                {selectedPinIndex < pins.length - 1 && <span className="navigation-text-button">{t('next')}</span>}
                <UnstyledButton className="lesson-preview__player-navbutton">
                  <ArrowRight className="arrow-right" width={10} height={12} />
                </UnstyledButton>
              </div>
            </div>
          </SelectedPinView>
          <PinSlideInfo pin={selectedPin} pinIndex={selectedPinIndex} />
        </div>
      </div>
      <div className="lesson-preview__content">{children}</div>
    </div>
  );
};

const SelectedPinView: React.FunctionComponent<{ pin: LessonPin<LessonContent>; children: React.ReactNode }> = ({
  pin,
  children,
}) => {
  const { setRef, fontSize, windowWidth } = useFontSizeForRef();
  // we only scale on small screens
  const font = windowWidth && windowWidth <= BREAKPOINTS.mqMdMax ? fontSize : undefined;

  return (
    <div className="lesson-preview__player" ref={setRef}>
      <PinView pin={pin} fontSize={font} useThumbnailForComponentType={['video']} />
      {children}
    </div>
  );
};

interface PinSlideInfoProps {
  pin: LessonPin;
  pinIndex: number;
}

function PinSlideInfo({ pin, pinIndex }: PinSlideInfoProps) {
  const { t } = useTranslation([TRANSLATION_NS, 'slides']);
  const { type } = pin.item;

  let translatedString: string | undefined;

  if (Video.isInteractiveVideo(pin)) {
    translatedString = t('interactive-video', { count: pin.item.videoItems ? pin.item.videoItems.length : 0 });
  } else if (!!pin.videoQuestion) {
    translatedString = t('video-item', { type: t(`slides:${type}`) });
  } else {
    translatedString = t(`slides:${type}`);
  }

  return (
    <div className="lesson-preview__slide-info">
      <span>
        Slide {pinIndex + 1}: <strong>{capitalize(translatedString)}</strong>
      </span>
    </div>
  );
}
