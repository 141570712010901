import { Lesson } from '@lessonup/teaching-core';
import _ from 'lodash';
import { Curriculum } from '../../objectives';

export interface CurriculumData {
  list: CurriculumData.CurriculumInfo[];
  objectives: CurriculumData.ObjectiveInfo[];
}

type Ref = Lesson.CurriculaRef;

export namespace CurriculumData {
  export interface CurriculumInfo {
    id: string;
    label: string;
  }

  export interface ObjectiveInfo {
    id: string;
    label: string;
    curriculum: string;
  }

  export function fromLessonCurriculaRef(
    refs: Ref[] | undefined,
    curriculaDict: Curriculum.ReferenceDict
  ): CurriculumData | undefined {
    if (!refs || !refs.length) {
      return {
        list: [],
        objectives: [],
      };
    }
    const allObjectives = _.flatMap(refs, (ref) => ref.objectives);
    const list: CurriculumInfo[] = _.compact(
      refs.map((ref) => {
        if (!curriculaDict.curricula[ref.id]) return;
        const curriculum = curriculaDict.curricula[ref.id];
        return {
          id: curriculum._id,
          label: curriculum.name,
        };
      })
    );
    const objectives: ObjectiveInfo[] = _.compact(
      allObjectives.map((objectiveId) => {
        if (!curriculaDict.objectives[objectiveId]) return;
        const objective = curriculaDict.objectives[objectiveId];
        return {
          id: objective._id,
          label: objective.name,
          curriculum: objective.curriculum,
        };
      })
    );
    return {
      list,
      objectives,
    };
  }
}
