import _ from 'lodash';
import { IndexedLesson, IndexedLessonPlan, IndexedPin, IndexType } from '.';
import { Bucket, TermAggregation } from './ElasticResponseBody';
import { Facet, FacetResult } from './Facets';
import { SearchParams } from './SearchParams';
import { SearchResponse, SearchResultItem } from './SearchResponse';

export interface FacetSearchResponse<T> {
  items: SearchResultItem<T>[];
  facets: FacetResult[];
  // request used for the response
  request: SearchParams;
  total: number;
}

export type FacetSearchResponseType<T extends IndexType> = T extends 'lessons'
  ? FacetSearchResponse<IndexedLesson>
  : T extends 'pins'
  ? FacetSearchResponse<IndexedPin>
  : FacetSearchResponse<IndexedLessonPlan>;

export namespace FacetSearchResponse {
  export function nestedBucketToLabeledBucket(facet: Facet, aggregation: TermAggregation): Bucket[] {
    const { nested } = facet;
    if (!nested) return [];
    return aggregation[nested.field].buckets.map((bucket) => {
      const labelAgg: TermAggregation | undefined = nested.labelField && bucket[nested.labelField];
      if (labelAgg && nested.labelField) {
        const label = labelAgg.buckets[0].key;
        return {
          ...bucket,
          [nested.labelField]: null,
          label,
        };
      }
      return bucket;
    });
  }

  export function fromResponse<T>(
    response: SearchResponse<T>,
    request: SearchParams,
    facets: Facet[]
  ): FacetSearchResponse<T> {
    const facetResults: FacetResult[] = facets.map((facet) => {
      const aggregation = _.get(response.aggregations, facet.key);
      let items = aggregation ? aggregation.buckets : [];
      if (facet.nested && aggregation) {
        items = nestedBucketToLabeledBucket(facet, aggregation);
      }
      return {
        key: facet.key,
        selected: _.find(request.facets, (f) => f.key === facet.key),
        items: items.filter((item) => !_.isEmpty(item.key) || _.isNumber(item.key)),
        hideIfEmpty: facet.hideIfEmpty,
      };
    });

    return {
      items: response.items,
      facets: facetResults,
      request,
      total: response.total,
    };
  }
}
