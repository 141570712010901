import { Button } from '@lessonup/ui-components';
import { AppErrorStatusCodes } from '@lessonup/utils';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ChannelTitle,
  ExtendedChannelInfo,
  IndexedChannelInfo,
  Language,
  LanguageSingleton,
} from '../../../shared-core/domain';
import { languageKey, loginRoute, voucherRoute } from '../../../shared-core/services/app/searchRoutes';
import { schoolSettingsRoute } from '../../../shared-core/services/app/teacherRoutes';
import AnchorOrLink from '../../../shared-core/ui/components/anchorOrLink/AnchorOrLink';
import ActionButton from '../../../shared-core/ui/components/Button';
import RocketSvg from '../../../shared-core/ui/components/logos/RocketSvg';
import ensureHttps from '../../../shared-core/utils/ensureHttps';
import { ImageUtils } from '../../../shared-core/utils/ImageUtils';
import { ServerErrorDetails } from '../../redux/reducers/errorReducer';
import { ErrorDetails } from '../../redux/reducers/lessonReducer';
import './ErrorPage.less';

type PageType = 'page' | 'channel' | 'lesson' | 'lessonPlan' | 'embedLesson';

interface ErrorMessageProps {
  type: PageType;
  backUrl: string;
  backUrlIsSearchExternal?: boolean;
  currentUser?: boolean;
  lesson?: boolean;
  code?: AppErrorStatusCodes;
  voucher?: boolean;
  backToLesson?: string;
  errorDetails?: ErrorDetails;
  serverError?: ServerErrorDetails;
  onBackButtonClick?: () => void;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  currentUser,
  type,
  lesson,
  backUrl,
  code,
  voucher,
  backToLesson,
  errorDetails,
  onBackButtonClick,
  serverError,
  backUrlIsSearchExternal,
}) => {
  const { t } = useTranslation('errors');
  const showSchoolChannelError = code === 403 && type === 'channel';
  const translationKey = errorMessageTitleKey(code, showSchoolChannelError);
  const msg =
    translationKey &&
    _.upperFirst(
      t(translationKey, {
        typeWithPronoun: t(type),
      })
    );

  const channel = errorDetails?.channel;

  function isExtended(channel: ExtendedChannelInfo | IndexedChannelInfo | undefined): channel is ExtendedChannelInfo {
    return false;
  }

  let bannerStyles;
  if (isExtended(channel)) {
    const backgroundCover = channel?.cover ? `url(${ensureHttps(channel?.cover)})` : 'none';
    const coverFocus = channel?.coverFocus ? channel.coverFocus : 50;
    bannerStyles = { backgroundImage: backgroundCover, backgroundPosition: `center ${coverFocus}%` };
  }

  return (
    <>
      <div className="error-header" style={bannerStyles}>
        {isExtended(channel) && channel?.cover ? <div className="glass-pane"></div> : null}
        {errorDetails && errorDetails?.channel?.icon ? (
          <>
            <img
              className={'channel-icon'}
              src={ImageUtils.checkImageUrl(errorDetails.channel.icon)}
              alt="Channel Icon"
            ></img>
            <div className="channel-banner">
              <h1 className={'channel-banner-title'}>
                {errorDetails.channel.title ? ChannelTitle.localizeTitle(errorDetails.channel.title) : ''}
              </h1>
              {'subtitle' in errorDetails.channel ? (
                <p className={'channel-banner-subtitle'}>
                  {ChannelTitle.localizeTitle(errorDetails.channel?.subtitle)}
                </p>
              ) : null}
            </div>
          </>
        ) : (
          ((isExtended(channel) && !channel?.cover) || code === 404) && <RocketSvg className={'error-header-logo'} />
        )}
      </div>

      <div className="error-page-content">
        <h2 className="error-title">{msg}</h2>
        <p className="error-description">{t(errorMessageDescriptionKey(code, showSchoolChannelError))}</p>
        <ul className="error-resolution-suggestions">
          {((!showSchoolChannelError && code === 403) || (showSchoolChannelError && !currentUser) || code === 401) && (
            <li>{t('not-allowed-error-suggestion-login')}</li>
          )}
          {voucher ? lesson && code === 403 && <li>{t('not-allowed-error-voucher')}</li> : null}
        </ul>
        <div className="error-actions">
          {(() => {
            if (voucher) {
              return (
                <>
                  <Link
                    className="error-link"
                    to={voucherRoute(undefined, backToLesson)}
                    onClick={onBackButtonClick}
                    target={type === 'embedLesson' ? '_blank' : undefined}
                  >
                    <ActionButton
                      className="error-page-voucher"
                      version={'modern'}
                      theme={'secondary'}
                      height={'medium'}
                    >
                      {t('error-page-voucher')}
                    </ActionButton>
                  </Link>
                  {!currentUser && (
                    <Link
                      target={type === 'embedLesson' ? '_blank' : undefined}
                      className="error-link"
                      to={loginRoute(undefined, false, Language.fallbackForLanguage(LanguageSingleton.get()))}
                      onClick={onBackButtonClick}
                    >
                      <span className={'error-page-login'}>{t('login')}</span>
                    </Link>
                  )}
                </>
              );
            }
            if (code === 403 && type === 'channel' && currentUser) {
              return (
                <a href={schoolSettingsRoute()} className="error-link">
                  <Button type="button" buttonType="secondary">
                    {t('error-page-school-signup')}
                  </Button>
                </a>
              );
            }
            if (code === 401 || (code === 403 && !currentUser)) {
              return (
                <Link
                  className="error-link"
                  to={loginRoute(undefined, false, languageKey)}
                  onClick={onBackButtonClick}
                  target={type === 'embedLesson' ? '_blank' : undefined}
                >
                  <span className={'error-page-login'}>{t('login')}</span>
                </Link>
              );
            }
            return (
              <AnchorOrLink
                className="error-link"
                url={backUrl}
                onClick={onBackButtonClick}
                isAnchor={!!backUrlIsSearchExternal}
              >
                <ActionButton className="error-page-back" version={'modern'} height={'medium'}>
                  {t('error-page-back')}
                </ActionButton>
              </AnchorOrLink>
            );
          })()}
        </div>
      </div>
    </>
  );
};

const errorMessageTitleKey = (code: AppErrorStatusCodes | undefined, showSchoolChannelError?: boolean): string => {
  if (showSchoolChannelError) {
    return 'not-allowed-error-school-channel';
  }
  if (code === 401 || code === 403) {
    return 'not-allowed-error-with-pronoun';
  }
  if (code === 404) {
    return 'not-found-error-with-pronoun';
  }
  return 'default';
};

const errorMessageDescriptionKey = (code: AppErrorStatusCodes | undefined, showSchoolChannelError: boolean): string => {
  if (showSchoolChannelError) {
    return 'not-allowed-error-school-channel-description';
  }
  if (code === 401 || code === 403) {
    return 'not-allowed-error-description';
  }
  return 'default-description';
};

export default ErrorMessage;
