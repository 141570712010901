import { RefObject, useEffect } from 'react';

export const useEventListener = <T extends HTMLElement>(
  eventName: keyof WindowEventMap,
  handler: (event: Event) => void,
  element?: RefObject<T>
): void => {
  useEffect(() => {
    const targetElement: T | Window = element?.current || window;

    targetElement.addEventListener(eventName, handler);

    return () => {
      targetElement.removeEventListener(eventName, handler);
    };
  }, [eventName, element, handler]);
};
