import React from 'react';
import { Translation } from 'react-i18next';
import LogoRedRocketCircle from '../../../shared-core/ui/components/logos/LogoRedRocketCircle';
import { Modal } from '../../../shared-core/ui/components/modals/Modal';
import { AppServicesConsumer } from '../appServices/AppServicesContext';
import './LessonsSyncedModal.less';

export interface SavedLesson {
  lessonName?: string;
  lessonId: string;
}

export interface LessonSyncedModalResult {
  status: 'success' | 'failed';
  data: {
    lesson?: SavedLesson[];
  };
}

const TRANSLATION_NAMESPACE = 'savemodal';

type Props = {
  closeModal: (result: LessonSyncedModalResult | undefined) => void;
};

export class LessonsSyncedModal extends React.Component<Props> {
  public constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  public handleClose() {
    this.props.closeModal(undefined);
  }

  public render() {
    return (
      <AppServicesConsumer
        render={(services) => {
          return (
            <Translation>
              {(t) => {
                return (
                  <Modal title={t('title', { ns: TRANSLATION_NAMESPACE })} isOpen onRequestClose={this.handleClose}>
                    <div className="savemodal__header">
                      <div className="savemodal_headertext">{t('did-save-lessons', { ns: TRANSLATION_NAMESPACE })}</div>
                      <LogoRedRocketCircle className="savemodal__logo logo-red-rocket-circle" />
                    </div>

                    <div>{t('find-in-lessonup', { ns: TRANSLATION_NAMESPACE })}</div>
                  </Modal>
                );
              }}
            </Translation>
          );
        }}
      />
    );
  }
}
