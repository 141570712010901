import { AppErrorCode, AppErrorStatusCodes } from '@lessonup/utils';
import { AnyAction } from '../actions/actions';

export type ServerErrorDetails = {
  isAppError: boolean;
  code: AppErrorCode | 'internal-server-error';
  statusCode: AppErrorStatusCodes;
  msg: string;
  stack: string | null | undefined;
};

export interface ErrorState {
  currentError: ServerErrorDetails | null;
}

const initialState: ErrorState = {
  currentError: null,
};

export function errorReducer(state: ErrorState = initialState, action: AnyAction): ErrorState {
  switch (action.type) {
    case 'clearGlobalError':
      return { currentError: null };
    default:
      return state;
  }
}
