import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { buttonClassNames, ButtonProps } from '../../../shared-core/ui/components/Button/Button';

export const ReactRouterLinkButton: React.FC<LinkProps & ButtonProps> = (props) => {
  const { style, text, children, to, onClick } = props;
  return (
    <Link to={to} onClick={onClick} className={buttonClassNames(props)} style={style}>
      {text && <span>{text}</span>}
      {children}
    </Link>
  );
};
