import { throttle } from 'lodash';
import { useEffect, useRef } from 'react';

const isBrowser = typeof window !== `undefined`;

export function useScrollPosition(
  scrollingCheck: (params: { previousScrollTop: number; currentScrollTop: number }) => void
) {
  const scrollPos = useRef<number>(0);
  const initial = useRef<boolean>(true);
  const previousScrollTop = 0;

  function handleDocumentScroll() {
    const { scrollTop: currentScrollTop } = document.documentElement || document.body;
    const previousScrollTop = scrollPos.current;
    scrollPos.current = currentScrollTop;
    scrollingCheck({ previousScrollTop, currentScrollTop });
  }

  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250);

  useEffect(() => {
    if (!isBrowser) return;
    //initial check for refresh in middle of page
    if (initial.current) {
      initial.current = false;
      handleDocumentScrollThrottled();
    }
    window.addEventListener('scroll', handleDocumentScrollThrottled, { passive: true });
    return () => window.removeEventListener('scroll', handleDocumentScrollThrottled);
  }, [handleDocumentScrollThrottled, previousScrollTop]);
}
