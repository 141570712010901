import { MergeErrorLabel } from '@lessonup/teaching-core';
import { AppError } from '@lessonup/utils';
import { cloneDeep, each, uniqBy } from 'lodash';
import { flow } from 'lodash/fp';
import { mongoUserAccountOrganizationRoles } from '..';
import { ValidateStructure } from '../../utils/TypescriptUtils';
import { Licenses, MongoUser, MongoUserCurrentLicense, MongoUserServiceName } from '../user';
import { TeacherUser } from '../user/User';

type ID = string;

export namespace Merge {
  export interface State {
    source: UserWithMergeInformation;
    target: UserWithMergeInformation;
    sourceResult: Source;
    targetResult: UserWithMergeInformation;
    errors: Error[];
    choices: Choices;
    dryRun: boolean;
  }

  export interface UserWithMergeInformation extends TeacherUser {
    counts: UserCounts;
    hasActiveSubscription: boolean;
    hasPersonalExplorer: boolean;
  }

  export interface Source {
    _id: ID;
    mergedAccount: TeacherUser & {
      mergedTo: ID;
      mergeDate: Date;
    };
  }

  export interface Error {
    code: number;
    label: MergeErrorLabel;
    reason: string;
    data?: any;
  }

  export interface UserCounts {
    pins: number;
    lessons: number;
    uploads: number;
    assignments: UserStats;
    favorites: number;
    groups: number;
    groupMember: number;
    invoices: number;
    payments: number;
  }

  export interface UserStats {
    userId: ID;
    assignmentCount: number;
  }

  export type Option = 'target' | 'source';

  export interface Choices {
    account: ID;
    services?: {
      google?: Option;
      somtoday?: Option;
      office365?: Option;
    };
  }

  export interface UsersAndChoices {
    target: UserWithMergeInformation;
    source: UserWithMergeInformation;
    choices: Choices;
  }
}

export const hasSameServiceSignOn = (
  userA: Merge.UserWithMergeInformation,
  userB: Merge.UserWithMergeInformation
): MongoUserServiceName[] => {
  if (!userA.services || !userB.services) return [];
  const keysOfSourceServices = (Object.keys(userA.services) as Array<MongoUserServiceName>).filter((key) =>
    MongoUser.isSSOService(key)
  );

  return keysOfSourceServices.filter((service) => !!userB.services?.[service]);
};

export const mergeServices = (usersAndChoices: Merge.UsersAndChoices): Merge.UsersAndChoices => {
  const { target, source, choices } = usersAndChoices;
  if (!source.services) return { target, source, choices };
  const clonedTarget = cloneDeep(target);

  const servicesToCheck = hasSameServiceSignOn(target, source);

  if (servicesToCheck.length && !choices.services) {
    throw new AppError('not-allowed', 'Users have same service but no choice was given');
  }

  if (servicesToCheck.length > 1) {
    throw new AppError('not-allowed', 'Users have more then one of the same service');
  }

  each(source.services, (service, serviceName) => {
    if (serviceName === 'resume' && service.loginTokens) {
      // special case
      if (!clonedTarget.services) clonedTarget.services = {};
      if (!clonedTarget.services?.resume) clonedTarget.services.resume = { loginTokens: [] };
      clonedTarget.services.resume.loginTokens = [
        ...(clonedTarget.services.resume.loginTokens || []),
        ...(service.loginTokens || []),
      ];
      return;
    }

    if (serviceName === 'password') return;

    if (choices.services?.[serviceName] && !clonedTarget.services?.[serviceName]) {
      throw new AppError('unexpected-data', 'Something went wrong, choices found for non duplicate service');
    }

    if (choices.services?.[serviceName] === 'target') {
      return;
    }

    clonedTarget.services = { ...clonedTarget.services, [serviceName]: service };
  });

  return { target: clonedTarget, source, choices };
};

export const mergePicture = ({ target, source, ...rest }: Merge.UsersAndChoices): Merge.UsersAndChoices => {
  if (!source.profile?.picture) return { target, source, ...rest };

  const targetResult = cloneDeep(target);
  targetResult.profile = { ...targetResult.profile, picture: source.profile.picture };

  return { target: targetResult, source, ...rest };
};

export const mergeEmails = ({ target, source, ...rest }: Merge.UsersAndChoices): Merge.UsersAndChoices => {
  if (!source.emails?.length) return { target, source, ...rest };

  const combinedEmails = [...(target.emails || []), ...(source.emails || [])];
  const emailResult = uniqBy(combinedEmails, 'address');

  return { target: { ...target, emails: emailResult }, source, ...rest };
};

export const mergeChannels = ({ target, source, ...rest }: Merge.UsersAndChoices): Merge.UsersAndChoices => {
  const following = source.data && source.data.following;
  if (!following) return { target, source, ...rest };
  const targetResult = cloneDeep(target);

  targetResult.data = { ...targetResult.data, following: { ...targetResult.data?.following, ...following } };

  return { target: targetResult, source, ...rest };
};

export const mergePermissions = ({ target, source, ...rest }: Merge.UsersAndChoices): Merge.UsersAndChoices => {
  if (!source.account) return { target, source, ...rest };
  const targetResult = cloneDeep(target);

  const accountFields = [
    'loginTypes',
    'channels',
    'schools',
    'explorerOwner',
    'explorerMember',
    'objectiveOwner',
    'objectiveMember',
  ];
  each(accountFields, (af) => {
    const combined = [...(targetResult.account[af] || []), ...(source.account[af] || [])];
    targetResult.account![af] = combined;
  });

  return { target: targetResult, source, ...rest };
};

const hasPayments = (user: Merge.UserWithMergeInformation): boolean =>
  user.hasActiveSubscription || user.counts.invoices + user.counts.payments > 0;

export const unableToMergeLicenses = (
  userA: Merge.UserWithMergeInformation,
  userB: Merge.UserWithMergeInformation
): boolean => hasPayments(userA) && hasPayments(userB);

export const shouldSelectSource = (
  target: Merge.UserWithMergeInformation,
  source: Merge.UserWithMergeInformation
): boolean => hasPayments(source) && !hasPayments(target);

export const shouldForceUserSelection = (
  userA: Merge.UserWithMergeInformation,
  userB: Merge.UserWithMergeInformation
): boolean => shouldSelectSource(userA, userB) || shouldSelectSource(userB, userA);

export const canUseSchoolInsteadOfProLicense = (
  target: Merge.UserWithMergeInformation,
  source: Merge.UserWithMergeInformation
): boolean => !hasPayments(source) && MongoUser.license(target) === 'pro' && MongoUser.license(source) === 'school';

export const shouldAutomaticallyStopActiveLicense = (
  target: Merge.UserWithMergeInformation,
  source: Merge.UserWithMergeInformation
): boolean => canUseSchoolInsteadOfProLicense(target, source) && target.hasActiveSubscription;

const doesTargetLicenseExpireAfterSourceLicense = (
  targetLicense: MongoUserCurrentLicense,
  sourceLicense: MongoUserCurrentLicense
): boolean =>
  !Licenses.proLicenseWillExpire(targetLicense) ||
  (Licenses.proLicenseWillExpire(sourceLicense) && targetLicense?.expires! >= sourceLicense?.expires!);

const hasFreeOrNoLicense = (license?: MongoUserCurrentLicense): boolean =>
  !license || !Licenses.isPaidLicense(license.status);

export const mergeLicenses = ({ target, source, ...rest }: Merge.UsersAndChoices): Merge.UsersAndChoices => {
  if (unableToMergeLicenses(target, source)) {
    throw new AppError(
      'not-allowed',
      'Unable to merge because both accounts have invoices, payments and/or an active subscription'
    );
  }
  if (shouldSelectSource(target, source)) throw new AppError('not-allowed', 'Select other account as target');

  const {
    account: { currentLicense: targetLicense },
  } = target;
  const {
    account: { currentLicense: sourceLicense },
  } = source;

  const targetWithSourceLicense = {
    ...target,
    account: {
      ...target.account,
      currentLicense: source.account.currentLicense,
    },
  };

  if (hasFreeOrNoLicense(sourceLicense)) return { target, source, ...rest };

  if (hasFreeOrNoLicense(targetLicense)) {
    return {
      target: targetWithSourceLicense,
      source,
      ...rest,
    };
  }

  const targetLicenseExpiresAfterSourceLicense = doesTargetLicenseExpireAfterSourceLicense(
    targetLicense!,
    sourceLicense!
  );

  if (targetLicenseExpiresAfterSourceLicense) {
    return {
      target,
      source,
      ...rest,
    };
  }

  return {
    target: targetWithSourceLicense,
    source,
    ...rest,
  };
};

export const mergeOrganizations = ({ target, source, ...rest }: Merge.UsersAndChoices): Merge.UsersAndChoices => {
  if (!source.account.organizations?.length) return { target, source, ...rest };
  const targetResult = cloneDeep(target);

  if (!targetResult.account.organizations) {
    return {
      target: { ...targetResult, account: { ...targetResult.account, organizations: source.account.organizations } },
      source,
      ...rest,
    };
  }

  const sourceOrganizations = source.account.organizations;
  const targetOrganizations = targetResult.account.organizations;

  sourceOrganizations.forEach((sourceOrganization) => {
    const sameOrganizationInTarget = targetOrganizations.find((to) => to.id === sourceOrganization.id);
    if (!sameOrganizationInTarget) {
      targetOrganizations.push(sourceOrganization);
    } else {
      // roles are in order of importance, so first find
      const role = mongoUserAccountOrganizationRoles.find((r) =>
        [sameOrganizationInTarget.role, sourceOrganization.role].includes(r)
      );

      if (role) sameOrganizationInTarget.role = role;

      if (sourceOrganization.isAdmin || sameOrganizationInTarget.isAdmin) {
        sameOrganizationInTarget.isAdmin = true;
      }
      sameOrganizationInTarget.locations = [
        ...(sameOrganizationInTarget.locations || []),
        ...(sourceOrganization.locations || []),
      ];
    }
  });

  return { target: targetResult, source, ...rest };
};

/**
 * Function to make sure the teacher user object does not have any extra properties
 * https://fettblog.eu/typescript-match-the-exact-object-shape/
 *
 * @param possibly - Teacher user with maybe extra properties
 * @returns Teacher user with no extra properties for sure
 */
const typeCheckTeacherUserExclusively = <T>(possibly: ValidateStructure<T, TeacherUser>): TeacherUser => {
  return possibly;
};

export const getValidatedTeacherUser = <T>(userWithMergeInformation: Merge.UserWithMergeInformation): TeacherUser => {
  const { counts: _, hasActiveSubscription: __, hasPersonalExplorer: ___, ...teacherUser } = userWithMergeInformation;
  return typeCheckTeacherUserExclusively(teacherUser);
};

export const generateSourceResult = (
  target: Merge.UserWithMergeInformation,
  source: Merge.UserWithMergeInformation
): Merge.Source => {
  return {
    _id: source._id,
    mergedAccount: {
      ...getValidatedTeacherUser(source),
      mergedTo: target._id,
      mergeDate: new Date(),
    },
  };
};

export const mergeUserObjects = ({
  target,
  source,
  choices,
}: Merge.UsersAndChoices): {
  target: Merge.UserWithMergeInformation;
  source: Merge.Source;
  choices?: Merge.Choices;
} => {
  return {
    target: flow([
      mergeServices,
      mergePicture,
      mergeLicenses,
      mergeEmails,
      mergeChannels,
      mergePermissions,
      mergeOrganizations,
    ])({
      target,
      source,
      choices,
    }).target,
    source: generateSourceResult(target, source),
  };
};
