import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { BackGroundColor, BorderColor, ForeGroundColor } from '../../definitions/colors';
import { IconName } from '../../definitions/Icons';
import './LinkButton.less';

type Height = 'small' | 'medium' | 'large';

interface LinkButtonProps {
  text: string;
  href?: string;
  big?: boolean;
  round?: boolean;
  height?: Height;
  bgColor?: BackGroundColor;
  fgColor?: ForeGroundColor;
  borderColor?: BorderColor;
  target?: '_blank' | undefined;
  iconName?: IconName;
  svgIcon?: ReactNode;
  onClick?: (event?: React.MouseEvent) => void;
  style?: React.StyleHTMLAttributes<HTMLAnchorElement>;
  tooltip?: string;
  underline?: boolean;
  children?: ReactNode;
}

export type LinkButtonAsLinkButtonProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & LinkButtonProps;

export default class LinkButton extends React.PureComponent<LinkButtonAsLinkButtonProps> {
  public constructor(props: LinkButtonProps) {
    super(props);
  }

  private handleClick(event: React.MouseEvent, props: LinkButtonProps) {
    if (props.onClick) {
      props.onClick(event);
      event.preventDefault();
    }
  }

  public render() {
    const { href, svgIcon, target, text, className } = this.props;
    const classList = getClassList(this.props);

    const tooltipProps = this.props.tooltip
      ? {
          'data-tip': true,
          'data-for': this.props.tooltip,
        }
      : {};
    return (
      <a
        href={href}
        target={target}
        onClick={(event: React.MouseEvent) => this.handleClick(event, this.props)}
        className={classNames(classList, className)}
        style={this.props.style || {}}
        {...tooltipProps}
      >
        {svgIcon}
        {text}
        {this.props.children}
      </a>
    );
  }
}

function getClassList(props: LinkButtonProps) {
  const { big, round, bgColor, fgColor, borderColor, iconName, svgIcon, height, underline } = props;

  const classes: string[] = ['btn-new', bgColor ? `bg-${bgColor}` : 'bg-yellow'];

  classes.push(`link-button--${height || 'large'}`);

  if (underline) classes.push('btn-underline');
  if (big) classes.push('btn-big');
  if (round) classes.push('btn-round');
  if (fgColor) classes.push(`fg-${fgColor}`);
  if (borderColor) classes.push(`border-color bc-${borderColor}`);
  if (iconName || svgIcon) classes.push('icon');
  if (iconName) classes.push(`icon-${iconName}`);
  return classes.join(' ');
}
