import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import ActionButton from '../../../../shared-core/ui/components/Button';
import { Modal } from '../../../../shared-core/ui/components/modals/Modal';
import ModalButtonBar from '../../../../shared-core/ui/components/modals/ModalButtonBar';
import { Text } from '../../../../shared-core/ui/components/translations/Text/Text';
import { useAppServices } from '../../appServices/AppServicesContext';
import './ShareLessonModal.less';

export interface ShareLessonModalConfig {
  url: string;
  isSignedIn: boolean;
  hasProFeatures: boolean;
  shareWithStudents: () => void;
  canShareWithStudents?: boolean;
}

type Props = ShareLessonModalConfig &
  RouteComponentProps & {
    closeModal: (res: undefined) => void;
  };

interface ShareLessonModalControlsProps {
  inputFieldRef: React.RefObject<HTMLInputElement> | null;
  url: string;
  copyLink: () => void;
  selectLink: () => void;
}

function ShareLessonModal(props: Props) {
  const { t } = useTranslation('shareLessonModal');
  const inputFieldRef = useRef<HTMLInputElement | null>(null);
  const [page, setPage] = useState<'selectShareMethod' | 'copyLink' | 'confirm'>(
    props.isSignedIn && props.canShareWithStudents ? 'selectShareMethod' : 'copyLink'
  );

  function handleClose() {
    return props.closeModal(undefined);
  }

  function selectLink() {
    if (!inputFieldRef || !inputFieldRef.current) {
      return;
    }

    inputFieldRef.current.focus();
    inputFieldRef.current.setSelectionRange(0, 99999);
  }

  function copyLink() {
    if (!inputFieldRef || !inputFieldRef.current) {
      return;
    }

    selectLink();

    document.execCommand('copy');
    nextPage();
  }

  function nextPage() {
    if (page === 'confirm') {
      props.closeModal(undefined);
      return;
    }

    const next = page === 'selectShareMethod' ? 'copyLink' : 'confirm';
    setPage(next);
  }

  const pageContent =
    page === 'selectShareMethod' ? (
      <ShareMethodSelect
        next={nextPage}
        shareWithStudents={props.shareWithStudents}
        hasProFeatures={props.hasProFeatures}
        canShareWithStudents={props.canShareWithStudents}
      />
    ) : page === 'copyLink' ? (
      <ShareLessonModalControls
        url={props.url}
        copyLink={copyLink}
        selectLink={selectLink}
        inputFieldRef={inputFieldRef}
      />
    ) : (
      <>
        <Text translateKey="confirm" options={{ ns: 'shareLessonModal' }} />
        <ActionButton theme="light" onClick={nextPage} style={{ display: 'block', margin: '1em auto 0' }}>
          <Text translateKey="close" options={{ ns: 'shareLessonModal' }} />
        </ActionButton>
      </>
    );

  return (
    <Modal title={t('title')} isOpen onRequestClose={handleClose} width="medium">
      {pageContent}
    </Modal>
  );
}

function ShareMethodSelect({ next, shareWithStudents, hasProFeatures, canShareWithStudents }) {
  const { t } = useTranslation('shareLessonModal');
  const { config } = useAppServices();

  return (
    <>
      <p>{t('share-select-option')}</p>
      <ModalButtonBar>
        <ActionButton theme="secondary" onClick={next}>
          <Text translateKey="copy-link" options={{ ns: 'shareLessonModal' }} />
        </ActionButton>

        {canShareWithStudents ? (
          <ActionButton theme="tertiary" onClick={shareWithStudents} disabled={!hasProFeatures}>
            <Text translateKey="share-with-students" options={{ ns: 'shareLessonModal' }} />
            {!hasProFeatures ? '*' : null}
          </ActionButton>
        ) : null}
      </ModalButtonBar>

      {!hasProFeatures && (
        <p className="modal-bottom-text">
          *
          <Trans
            t={t}
            i18nKey="shareModalLocked"
            components={[
              <a href={config.teacherAccountUrl()} target="_blank" rel="noopener noreferrer">
                {/* "Content" is a placeholder text which is filled in by react-118next. It's essentially just used to keep react from complaining. */}
                content
              </a>,
            ]}
            values={{ link: config.teacherAccountUrl() }}
          />
        </p>
      )}
    </>
  );
}

function ShareLessonModalControls(props: ShareLessonModalControlsProps) {
  return (
    <>
      <div className="sharelesson-modal__headertext">
        <Text translateKey="text" options={{ ns: 'shareLessonModal' }} />
      </div>
      <br />
      <div className="share-lessonmodal-controls">
        <input
          className="copy-field"
          type="text"
          ref={props.inputFieldRef}
          value={props.url}
          onFocus={() => props.selectLink()}
          readOnly
        />
        <ActionButton height="medium" theme="tertiary" onClick={props.copyLink}>
          <Text translateKey="copy-link" options={{ ns: 'shareLessonModal' }} />
        </ActionButton>
      </div>
    </>
  );
}

export default withRouter(ShareLessonModal);
