import { tracker } from '@lessonup/analytics';
import { compact, intersection } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { logger, trackLessonActionWithOrigin } from '@lessonup/client-integration';
import { UserRatings } from '@lessonup/firebase-database';
import { EmbedErrorPage, EmbedErrorPageProps } from '@lessonup/teacher-modern';
import { EduSystem, formatSchoolType, Lesson } from '@lessonup/teaching-core';
import { AppError, naiveStripHTML } from '@lessonup/utils';
import { ChannelTitle, IndexedChannelInfo, IndexedLesson, SearchResultItem } from '../../../shared-core/domain';
import { Api } from '../../../shared-core/domain/api/LessonupApi';
import { channelRoute, lessonRoute } from '../../../shared-core/services/app/searchRoutes';
import { editorUrl } from '../../../shared-core/services/app/teacherRoutes';
import { useToasts } from '../../../shared-core/ui/components/toast/ToastProvider';
import useCallbackIfParamsChanged from '../../../shared-core/ui/utils/hooks/useCallbackIfParamsChanged';
import transformServingImage from '../../../shared-core/utils/transformServingImage';
import { useAppServices } from '../../components/appServices/AppServicesContext';
import LessonSummaryCards from '../../components/lesson/lessonSummaryCard/LessonSummaryCards';
import {
  changeRatingAction,
  fetchedLessonAction,
  fetchingLessonAction,
  fetchLessonErrorAction,
  followChannelAction,
  saveLessonAction,
} from '../../redux/actions/actions';
import { isChannelFollowed, lessonStore, loggedInUser, userRatings } from '../../redux/selectors';
import { useSpinnerContext } from '../../utils/loaders/SpinnerContext';
import pageTitle from '../../utils/pageTitle';
import { useTopLevelClassnameContext } from '../../utils/TopLevelClassnameContext';
import { useScrollToTop } from '../../utils/useScrollToTop';
import ErrorPageView from '../error';
import FlagLessonButton from './flagLessonButton/FlagLessonButton';
import LessonPageView from './lessonPage/LessonPageView';

type Props = {
  lessonId: string;
  preselectedPin?: string;
  handlePinSelected: (pinId: string) => void;
  backUrl: string;
  backNavigation?: (lesson: Lesson) => JSX.Element | undefined;
  teachOverride?: (newTab?: boolean) => void;
  withoutHasMore?: boolean;
  embedLesson?: boolean;
};

const LessonPage: React.FC<Props> = ({
  lessonId,
  preselectedPin,
  handlePinSelected,
  backNavigation,
  backUrl,
  teachOverride,
  withoutHasMore,
  embedLesson,
}) => {
  const { lessonDetails, error, lessonId: storeLessonId, errorDetails } = useSelector(lessonStore());
  const channel = lessonDetails && lessonDetails.channel;
  const ratings = useSelector(userRatings());
  const user = useSelector(loggedInUser());
  const services = useAppServices();
  const { user: userService, lesson: lessonService } = services;
  const channelFollowed = useSelector(isChannelFollowed(channel && channel.name));
  const [lessonSaved, setLessonSaved] = useState(false);
  const spinner = useSpinnerContext();
  const dispatch = useDispatch();
  const { t } = useTranslation('lessonDetails');
  const toastManager = useToasts();
  const { setClassName } = useTopLevelClassnameContext();
  useScrollToTop();

  useEffect(() => {
    setClassName('lesson-page-rendered');
    return () => {
      setClassName('');
    };
  });

  useEffect(() => {
    setLessonSaved(lessonService.isLessonSaved(lessonId));
  }, [lessonService, lessonId]);

  useCallbackIfParamsChanged({
    initial: storeLessonId,
    params: lessonId,
    async callback(lessonToFetch) {
      if (!lessonToFetch) return;
      spinner.open();
      dispatch(fetchingLessonAction(lessonToFetch));
      try {
        const result = await lessonService.lessonById(lessonToFetch, withoutHasMore);
        if (result) dispatch(fetchedLessonAction(result));
      } catch (error) {
        if (AppError.isError(error, 'not-allowed')) {
          dispatch(fetchLessonErrorAction(403, lessonToFetch));
        } else if (AppError.isError(error, 'not-allowed/no-product')) {
          dispatch(fetchLessonErrorAction(403, lessonToFetch, true));
        } else if (AppError.isError(error, 'not-found')) {
          dispatch(fetchLessonErrorAction(404, lessonToFetch));
        } else {
          dispatch(fetchLessonErrorAction(500, lessonToFetch));
        }
      } finally {
        spinner.close();
      }
    },
  });

  const handleLessonSave = async (lesson: Lesson, cancelOnLoginDismiss?: boolean) => {
    try {
      if (!userService.currentUser()) {
        const loginResponse = await services.modals.open('loginPrompt', { forceNewTab: !!embedLesson, action: 'save' });
        if (cancelOnLoginDismiss && loginResponse.dismiss) return;
      }

      const res = await lessonService.saveLesson(lesson._id);
      if (!res) return;

      tracker.events.lessonSaveForLater({ lessonId: lesson._id });

      trackLessonActionWithOrigin(
        'save-lesson',
        {
          lessonId: lesson._id,
          label: res.currentUser ? 'user' : 'non-user',
          channel: lesson.channel,
        },
        lesson
      );

      if (!res.currentUser) {
        setLessonSaved(true);
        return;
      }

      dispatch(saveLessonAction(lesson._id));

      services.modals.open('link', {
        title: t('lessonSavedTitle') || '',
        description: t('lessonSavedDescription'),
        link: editorUrl(res.lessonId),
        linkText: t('openSavedLesson'),
      });
    } catch (error) {
      logger.error(error);
    }
  };

  const navigateToLesson = (newTab?: boolean) => {
    if (teachOverride) {
      return teachOverride(newTab);
    }

    lessonService.teach({
      lessonId,
      newTab,
      pinId: preselectedPin,
      source: embedLesson ? 'searchembed' : 'search',
    });
  };

  const shareWithStudents = async () => {
    if (lesson.channel && channel?.requireFollow) {
      const res = await services.channel.requireFollow({
        trigger: { trigger: 'handout-lesson', type: 'lesson', lessonId },
        channelSlug: lesson.channel,
        channelTitle: channel.title ? ChannelTitle.localizeTitle(channel.title) : undefined,
        userId: user?._id,
      });
      dispatch(followChannelAction(lesson.channel));
      if (!res.success) return;
    }

    try {
      const res = await lessonService.saveLessonToRoot(lessonId);
      if (res) lessonService.shareWithStudents(res.lessonId);
    } catch (error) {
      logger.error(error);
    }
  };

  const shareLesson = () => {
    tracker.events.lessonShare({ lessonId });
    trackLessonActionWithOrigin('share-lesson', { lessonId, channel: lesson.channel }, lesson);

    const userHasAccessToProduct =
      intersection(
        user?.products.map((product) => product.productId),
        lesson.products
      ).length > 0;

    services.modals.open('shareLesson', {
      url: services.config.shareLessonUrl(lessonId),
      isSignedIn: Boolean(userService.currentUser()),
      hasProFeatures: userHasAccessToProduct || services.user.hasProFeatures(),
      canShareWithStudents: lessonDetails?.accessTypes.shareWithStudents && lessonDetails?.accessTypes.copySearch,
      shareWithStudents,
    });
  };

  const isLessonSaved = () => {
    return userService.currentUser() ? lessonService.isLessonSaved(lessonId) : lessonSaved || false;
  };

  const handleRatingSelected = async (lesson: Lesson, rating: number) => {
    trackLessonActionWithOrigin('rate-lesson', { lessonId }, lesson);

    try {
      if (!userService.currentUser()) {
        toastManager.add({
          msg: t('ratingLoginRequired', { ns: 'toast' }),
        });
        return;
      }
      // optimistic ui
      dispatch(changeRatingAction(lesson._id, rating));
      await lessonService.setRating(lesson._id, rating);
      toastManager.add({
        msg: t('thanksForRating', { ns: 'toast' }),
      });
    } catch (error) {
      logger.error(error);
    }
  };

  if (error && errorDetails?.forProduct) {
    if (error === 403 && embedLesson) {
      const channelTitle = lessonDetails?.channel?.title
        ? ChannelTitle.localizeTitle(lessonDetails?.channel?.title)
        : undefined;
      const channel: EmbedErrorPageProps['channel'] = lessonDetails?.channel &&
        channelTitle && {
          title: channelTitle,
          slug: lessonDetails.channel.name,
        };
      return <EmbedErrorPage channel={channel} style={{ height: '100%' }} />;
    }

    return <ErrorPageView type={'lesson'} backUrl={backUrl} code={error} voucher={true} errorDetails={errorDetails} />;
  }
  if (error) {
    return (
      <ErrorPageView
        type="lesson"
        backUrl={backUrl}
        code={error}
        backToLesson={lessonRoute(lessonId)}
        errorDetails={errorDetails}
      />
    );
  }

  if (!lessonDetails || storeLessonId !== lessonId) return null;
  const { pins, moreLikeThis, lesson, indexedLesson } = lessonDetails;

  const flagLessonButton = <FlagLessonButton lessonId={lesson._id} />;

  async function followChannel(
    params: Api.RegisterChannelLeadParams | { channel: string }
  ): Promise<Api.RegisterChannelLeadResponse | Api.FollowChannelWithUserIdResponse> {
    const currentUser = userService.currentUser();

    const result = currentUser
      ? await services.channel.followChannelWithUserId(params.channel)
      : services.channel.registerChannelLead(params as Api.RegisterChannelLeadParams);

    dispatch(followChannelAction(params.channel));

    return result;
  }

  // only show the button if we have a active lesson
  const activeLesson = user?.activeLesson;
  const activeDocument = user?.activeDocument;
  const addPin = activeLesson
    ? (pinId: string) => {
        return lessonService.addPinToActiveLesson(pinId);
      }
    : undefined;

  return (
    <div>
      <LessonPageMetaTags lesson={lesson} indexedLesson={indexedLesson} />
      <LessonPageView
        className="lesson-page-view"
        pins={pins || []}
        lesson={lesson}
        channel={channel}
        accessTypes={lessonDetails.accessTypes}
        onLessonSave={handleLessonSave}
        onPinSelected={handlePinSelected}
        preselectedPin={preselectedPin}
        navigateToLesson={navigateToLesson}
        shareLesson={shareLesson}
        isLessonSaved={isLessonSaved()}
        backNavigation={backNavigation && backNavigation(lesson)}
        flagLessonButton={flagLessonButton}
        channelUrl={(channelSlug: string) => channelRoute({ channelSlug })}
        channelFollowed={channelFollowed}
        userRatings={ratings}
        onRatingSelected={handleRatingSelected}
        userIsLoggedIn={!!user}
        addPin={addPin}
        activeLesson={activeLesson}
        activeDocument={activeDocument}
        embedLesson={embedLesson}
        currentLicense={user?.licenseStatus}
        eligibleToTeachWithoutCredits={lessonDetails.eligibleToTeachWithoutCredits}
      />

      <MoreLikeThis items={moreLikeThis} userRatings={ratings} />
      <LessonAnalytics lesson={lesson} channel={channel}></LessonAnalytics>
    </div>
  );
};

function LessonPageMetaTags({ lesson, indexedLesson }: { lesson: Lesson; indexedLesson: boolean }) {
  const THUMB_WIDTH = 580;
  const thumbHeight = (THUMB_WIDTH / 16) * 9;

  return (
    <Helmet>
      {lesson.name && <title>{pageTitle(lesson.name)}</title>}
      <meta name="robots" content={indexedLesson ? 'index' : 'noindex'} />
      <meta property="twitter:card" content="summary" />
      <meta property="og:title" content={lesson.name} />
      <meta
        property="og:description"
        content={lesson.description ? naiveStripHTML(lesson.description) : generateDutchLessonDescription(lesson)}
      />
      {lesson.thumbnail && (
        <meta
          property="og:image"
          content={transformServingImage(lesson.thumbnail.url, { width: THUMB_WIDTH, height: thumbHeight })}
        />
      )}
    </Helmet>
  );
}

function generateDutchLessonDescription(lesson: Lesson) {
  const eduSystem = EduSystem.get('nl');
  const subjects = lesson.subjects?.slice(0, 2) || [];
  const duration = lesson.durationInMin;
  const schoolTypes = lesson.schoolType ? lesson.schoolType.slice(0, 2).map((t) => formatSchoolType(eduSystem, t)) : [];
  const elements = [
    'Les',
    subjects.join(' en '),
    duration && `van ${duration} minuten`,
    schoolTypes.length > 0 && `voor ${schoolTypes.join(' en ')}`,
  ];

  return compact(elements).join(' ');
}

const MoreLikeThis = ({
  items,
  userRatings,
}: {
  items: SearchResultItem<IndexedLesson>[];
  userRatings: UserRatings;
}) => {
  const { t } = useTranslation('search');
  if (items.length === 0) return null;

  return (
    <div className="lesson-page__more-like-this">
      <div className="lesson-page-section page-center">
        <h2 className="lesson-page__header">{t('more-lessons-like-this')}</h2>
        <LessonSummaryCards userRatings={userRatings} items={items} />
      </div>
    </div>
  );
};

export default LessonPage;

function LessonAnalytics({ lesson, channel }: { lesson: Lesson; channel?: IndexedChannelInfo }) {
  useEffect(() => {
    // new tracker is using the channel.id
    tracker.events.lessonView({ lessonId: lesson._id, channelId: channel?._id });
    // the old tracked used the channel.name
    trackLessonActionWithOrigin('view-lesson', { lessonId: lesson._id, channel: channel?.name }, lesson);
  }, [channel, lesson]);

  return null;
}
