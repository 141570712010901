import { Colors, Component, Fonts } from '@lessonup/teaching-core';
import classNames from 'classnames';
import React, { useCallback } from 'react';

import { FontsSingleton } from '../../../../services/fonts/FontsSingleton';
import { ImageUtils } from '../../../../utils/ImageUtils';
import ComponentContainer from '../componentContainer/ComponentContainer';
import './DropzoneComponentView.less';

interface Props {
  component: Component.Dropzone;
  isThumb?: boolean;
}

export default function DropzoneComponentView(props: Props) {
  const fontsForComponent = useCallback(() => {
    const fontFamily = props.component.settings.fontFamily as Fonts.Key;
    if (fontFamily) FontsSingleton.add([fontFamily]);
  }, [props.component.settings.fontFamily]);

  fontsForComponent();

  function style() {
    const { settings } = props.component;
    const imageUrl = ImageUtils.checkImageUrl(settings.imageUrl);
    const backgroundColor = Colors.getHexColor(settings.backgroundColor);

    return {
      backgroundImage: ImageUtils.urlString(imageUrl),
      backgroundSize: settings.imageSize,
      borderRadius: settings.borderRadius,
      backgroundColor,
    };
  }

  function stylingClasses(): string {
    const { settings } = props.component;
    const opacityClass = `opacity-${settings.transparency || '100'}`;
    const shadow = settings.shadow ? 'shadow' : '';
    return ['comp-dropzone', opacityClass, shadow].join(' ');
  }

  function textStyleClasses(): string {
    const { settings } = props.component;
    const font = `font-${settings.fontFamily} font-size-${settings.fontSize}`;
    const padding = `text-padding-${settings.padding}`;
    const alignment = `text-${settings.textAlign} ${settings.verticalAlign}`;
    return ['text-value', font, padding, alignment].join(' ');
  }

  return (
    <ComponentContainer component={props.component} isThumb={props.isThumb}>
      <div className={stylingClasses()} data-grippy="move" style={style()}>
        <DropzoneText className={textStyleClasses()} text={props.component.settings.text} data-grippy="move" />
      </div>
      <DropzoneBorder
        showDropStyle={Boolean(props.component.settings.showDropStyle)}
        borderRadius={props.component.settings.borderRadius}
      />
    </ComponentContainer>
  );
}

function DropzoneBorder({ showDropStyle, borderRadius }: { showDropStyle: boolean; borderRadius: string | undefined }) {
  if (!showDropStyle) {
    return null;
  }

  return (
    <>
      <div className={classNames('dropzone-object-outline')} data-grippy="move" style={{ borderRadius }} />
      <div className="dropzone-object-grippy" data-grippy="move" />
    </>
  );
}

function DropzoneText(props: { className: string; text: string | undefined }) {
  if (!props.text) {
    return null;
  }

  return <div className={props.className} dangerouslySetInnerHTML={{ __html: props.text }} />;
}
