import { LicenseStatus } from '@lessonup/teacher-modern';
import { assertNever } from '@lessonup/utils';
import { Licenses } from '../../../../shared-core/domain';

/**
 * Copy from the teacher since we do not have a shared spot where we have shared core and teacher modern
 */
export const domainLicenseToLicenseStatus = (license: Licenses.Status | undefined): LicenseStatus | undefined => {
  if (!license) return undefined;
  switch (license) {
    case 'free':
      return 'FREE';
    case 'start':
      return 'START';
    case 'trial':
      return 'TRIAL';
    case 'expired':
      return 'EXPIRED';
    case 'expiring':
      return 'EXPIRING';
    case 'pro':
      return 'PRO';
    case 'school':
      return 'SCHOOL';
    default:
      assertNever(license, 'Unknown license');
  }
};
