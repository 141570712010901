import React, { Fragment, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Explorer,
  FolderMetaWithFolderIndex,
  FolderWithNavigationData,
  getFolderFromExplorer,
  Location,
} from '../../../../../domain/newExplorers';
import { useBEM } from '../../../../utils/hooks';
import { ChevronIcon, HomeIcon } from '../../../svgIcons';
import { ExplorerSharedExplorerIcon } from '../../icons';
import { CurrentFolderBreadcumb } from '../Breadcrumb/Breadcrumb';
import { BreadcrumbWithChevron } from './BreadcrumbWithChevron';
import './ExplorerBreadcrumbs.less';
import { HiddenBreadcrumbs } from './HiddenBreadcrumbs';

interface BaseProps {
  onLocationChange: (newLocation: Location) => void;
  rootExplorer: Explorer;
}

interface Props extends BaseProps {
  currentLocation: Location;
  currentExplorer: Explorer;
  className?: string;
  maxBreadcrumbLength?: number;
  hideRootExplorerButton?: boolean;
}

export const ExplorerBreadcrumbs: React.FC<Props> = ({
  rootExplorer,
  currentExplorer,
  currentLocation,
  onLocationChange,
  className = 'padding-top-2 padding-bottom-2',
  maxBreadcrumbLength = 3,
  hideRootExplorerButton = false,
}) => {
  const { t } = useTranslation('explorerBreadcrumbs');

  const bemClasses = useBEM('ExplorerBreadcrumbs');

  const currentFolder: FolderWithNavigationData = useMemo(
    () => getFolderFromExplorer(currentExplorer, currentLocation.folder),
    [currentExplorer, currentLocation]
  );
  const breadcrumbs: FolderMetaWithFolderIndex[] = [...currentFolder.parents, currentFolder];

  const isInSharedFolder = currentLocation.explorer !== rootExplorer._id;

  const currentCrumbName = breadcrumbs.length === 1 && !isInSharedFolder ? t(currentExplorer.type) : currentFolder.name;

  const breadcrumbsExceedMaxLength = () => {
    return maxBreadcrumbLength && breadcrumbs.length > maxBreadcrumbLength;
  };

  const defaultProps = {
    className: bemClasses({ element: 'icon' }),
  };

  const rootLocationCrumb = (key: string): ReactNode => {
    if (breadcrumbs.length === 1) {
      return (
        <Fragment key={key}>
          <ExplorerBreadCrumbHomeIcon type={'div'} />
          <CurrentFolderBreadcumb crumbName={t(currentExplorer.type)} singleItem={true} />
        </Fragment>
      );
    }
    return (
      <BreadcrumbWithChevron
        crumbName={t(currentExplorer.type)}
        handleClick={() => onLocationChange({ ...currentLocation, folder: breadcrumbs[0]._id })}
        key={key}
      />
    );
  };

  const sharedFolderCrumb = (key: string): ReactNode => {
    return (
      <Fragment key={key}>
        {!hideRootExplorerButton && (
          <ExplorerBreadcrumbsHomeButton onLocationChange={onLocationChange} rootExplorer={rootExplorer} />
        )}
        <ExplorerSharedExplorerIcon
          isPublish={currentExplorer.isPublish}
          isTemplate={currentExplorer.templates}
          {...defaultProps}
          className={bemClasses({ element: 'shared-explorer-icon' })}
        />
        {breadcrumbs.length > 1 ? (
          <BreadcrumbWithChevron
            crumbName={breadcrumbs[0].name}
            handleClick={() => onLocationChange({ ...currentLocation, folder: breadcrumbs[0]._id })}
          />
        ) : (
          currentCrumb(key)
        )}
      </Fragment>
    );
  };

  const currentCrumb = (key: string) => {
    return <CurrentFolderBreadcumb crumbName={currentCrumbName} key={key} />;
  };

  return (
    <div className={bemClasses({ className })}>
      {breadcrumbs.map((breadcrumb, index) => {
        const isSharedRootCrumb = index === 0 && isInSharedFolder;
        if (isSharedRootCrumb) return sharedFolderCrumb(breadcrumb._id);

        if (breadcrumbsExceedMaxLength()) {
          const isFirstCrumb = index === 1;
          if (isFirstCrumb) {
            return [<HiddenBreadcrumbs key="hidden-breadcrumbs" />];
          }
          const isNotRootAndNotLastTwoCrumbs = index !== 0 && index < breadcrumbs.length - 2;
          if (isNotRootAndNotLastTwoCrumbs) {
            return null;
          }
        }

        const isNormalRootCrumbAndCurrent = index === 0 && breadcrumbs.length === 1;
        if (isNormalRootCrumbAndCurrent) return rootLocationCrumb(breadcrumb._id);

        const isNormalRootCrumb = index === 0;
        if (isNormalRootCrumb) {
          return (
            <ExplorerBreadcrumbsHomeButton
              onLocationChange={onLocationChange}
              rootExplorer={rootExplorer}
              key={breadcrumb._id}
            />
          );
        }

        const isCurrentLocation = breadcrumb._id === currentLocation.folder;
        if (isCurrentLocation) return <CurrentFolderBreadcumb key={breadcrumb._id} crumbName={currentCrumbName} />;

        return (
          <BreadcrumbWithChevron
            key={breadcrumb._id}
            crumbName={breadcrumb.name}
            handleClick={() => onLocationChange({ ...currentLocation, folder: breadcrumb._id })}
          />
        );
      })}
    </div>
  );
};

interface ExplorerBreadcrumbsHomeButtonProps extends BaseProps {}

const ExplorerBreadcrumbsHomeButton: React.FC<ExplorerBreadcrumbsHomeButtonProps> = ({
  onLocationChange,
  rootExplorer,
}) => {
  const bemClasses = useBEM('ExplorerBreadcrumbsHomeButton');
  return (
    <>
      <ExplorerBreadCrumbHomeIcon
        type={'button'}
        key={'home-button'}
        onClick={() => onLocationChange({ explorer: rootExplorer._id, folder: rootExplorer._id })}
      />

      <ChevronIcon
        rotation={270}
        className={`margin-left-4 margin-right-4 ${bemClasses({ element: 'chevron-icon' })}`}
      />
    </>
  );
};

interface HomeIconProps {
  type: 'button' | 'div';
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const ExplorerBreadCrumbHomeIcon: React.FC<HomeIconProps> = ({ type: WrapperComponent, onClick }) => {
  const bemClasses = useBEM('explorer-breadcrumb-wrapper');

  return (
    <WrapperComponent onClick={onClick} className={bemClasses()}>
      <HomeIcon className={bemClasses({ element: 'icon' })} />
    </WrapperComponent>
  );
};
