import { EduSystem } from '@lessonup/teaching-core';
import { compactMap } from '@lessonup/utils';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FacetKey,
  FacetResult,
  Facets,
  isValueFacet,
  LanguageSingleton,
  SingleValueSelectedFacet,
} from '../../../../domain';
import {
  createLabelFormatter,
  createOption,
  formatFacetPlaceholder,
  getOptions,
  translatedFacetPlaceholder,
} from '../../../../utils/FacetUtils';
import FacetSelect, { Option } from './FacetSelect';

const CREATABLE_FACETS: FacetKey[] = ['subjects'];

interface FacetSelectWithOptionsProps {
  facetResult: FacetResult;
  selectedSchoolType: string | undefined;
  selectedFacets: SingleValueSelectedFacet[];
  eduSystem: EduSystem.System;
  country: string;
  handleFacetChange: (value: Option) => void;
  hideCounts?: boolean;
}

const FacetSelectWithOptions: React.FC<FacetSelectWithOptionsProps> = ({
  facetResult,
  selectedSchoolType,
  selectedFacets,
  eduSystem,
  country,
  handleFacetChange,
  hideCounts,
}) => {
  const { t } = useTranslation(['facets', 'slides', 'sources']);
  const labelFormatter = createLabelFormatter(
    facetResult.key,
    selectedSchoolType,
    eduSystem,
    country,
    LanguageSingleton.get()
  );

  const options = getOptions(
    facetResult.key,
    selectedSchoolType,
    eduSystem,
    compactMap(facetResult.items, (item) => createOption(item, labelFormatter)),
    t,
    hideCounts
  );
  if (!isValueFacet(facetResult.key)) return null;

  function labelForFacetWithFallbackIfNoResults(facet: SingleValueSelectedFacet): Option | undefined {
    const option = options.find((option) => option.value === facet.value);
    if (option) return option;
    const fallbackLabel = Facets.facetLabel(facet, country, selectedSchoolType);
    if (fallbackLabel) return { value: facet.value, label: fallbackLabel, count: 0 };
  }

  const selectedFacet = selectedFacets.find((f) => f.key === facetResult.key);
  const selectedOption = selectedFacet ? labelForFacetWithFallbackIfNoResults(selectedFacet) : null;
  const isDisabled =
    facetResult.isDisabled ||
    Facets.facetsHasUnmetDependency(facetResult.key, selectedFacets) ||
    (!selectedFacet && options.length === 0);

  const placeholder = formatFacetPlaceholder(facetResult.key, eduSystem, selectedSchoolType, t);
  const placeholderOption =
    !isDisabled && selectedFacet
      ? {
          ...selectedFacet,
          label: translatedFacetPlaceholder(selectedFacet, t),
          count: undefined,
        }
      : null; // this placeholder option allows a user to clear a selected option if no options are available.

  return (
    <FacetSelect
      key={facetResult.key}
      facetKey={facetResult.key}
      placeholder={placeholder}
      options={options}
      onChange={handleFacetChange}
      isDisabled={isDisabled}
      value={selectedOption || placeholderOption}
      isCreatable={_.includes(CREATABLE_FACETS, facetResult.key)}
    ></FacetSelect>
  );
};

export default FacetSelectWithOptions;
