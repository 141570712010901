import React from 'react';

interface Props {
  pinId: string;
  isghost?: boolean;
  number: number;
  style: object;
}

export default function VideoIndicatorSvg(props: Props) {
  return (
    <svg viewBox="0 0 58 67" className={`video-pin-indicator ${props.isghost ? 'ghost' : ''}`} style={props.style}>
      <defs>
        <path
          d="M29 58c12.667-12.338 19-22.004 19-29 0-10.493-8.507-19-19-19s-19 8.507-19 19c0 6.996 6.333 16.662 19 29z"
          id="path-1"
        />
        <filter x="-39.5%" y="-31.3%" width="178.9%" height="162.5%" filterUnits="objectBoundingBox" id="filter-2">
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.213485054 0" in="shadowBlurOuter1" />
        </filter>
      </defs>
      <g className="core" data-pin={props.pinId} data-role="goto-pin">
        <use filter="url(#filter-2)" xlinkHref="#path-1" />
        <path
          stroke="#FFF"
          strokeWidth={3}
          d="M29 55.898C40.694 44.319 46.5 35.278 46.5 29c0-9.665-7.835-17.5-17.5-17.5S11.5 19.335 11.5 29c0 6.278 5.806 15.32 17.5 26.898z"
          strokeLinejoin="miter"
          className="center"
          fillRule="evenodd"
        />
        <text x={29} y={37} className="text" textAnchor="middle">
          {props.number}
        </text>
      </g>
    </svg>
  );
}
