import React from 'react';
import { RouteComponentProps } from 'react-router';
import LessonPlanPage from './LessonPlanPage';

type Props = RouteComponentProps & {
  planId: string;
};

const LessonPlanRootPage: React.FC<Props> = ({ history, planId }) => {
  return <LessonPlanPage planId={planId} />;
};

export default LessonPlanRootPage;
