import classNames from 'classnames';
import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { ForeGroundColor } from '../../definitions/colors';
import './TextButton.less';

export interface TextButtonProps
  extends Partial<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> {
  color?: ForeGroundColor;
  text?: string;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

const TextButton: React.FC<TextButtonProps> = (props) => {
  const { className, color, children, text, type, ...otherProps } = props;
  const fgColor = `fg-${color ? color : 'text'}`;
  const disabledClass = props.disabled ? 'disabled' : '';

  return (
    <button
      type={type || 'button'}
      {...otherProps}
      className={classNames('text-button-comp', fgColor, disabledClass, className)}
    >
      {children}
      {text}
    </button>
  );
};

export default TextButton;
