import classNames from 'classnames';
import React from 'react';
import UnstyledButton from '../../../UnstyledButton';
import './Breadcrumb.less';

interface BaseProps {
  crumbName: string;
}

interface BaseBreadcrumbProps extends BaseProps {
  handleClick?: () => void;
  currentFolder?: boolean;
  disabled?: boolean;
  bigger?: boolean;
}

const BaseBreadcrumb: React.FC<BaseBreadcrumbProps> = ({ crumbName, handleClick, currentFolder, disabled, bigger }) => {
  return (
    <span className="breadcrumb">
      <UnstyledButton
        onClick={handleClick}
        className={classNames('breadcrumb__button', {
          'breadcrumb__button--current': currentFolder,
          'breadcrumb__button--disabled': disabled,
          'breadcrumb__button--bigger': bigger,
        })}
      >
        {crumbName}
      </UnstyledButton>
    </span>
  );
};

export interface BreadcrumbProps extends BaseProps {
  handleClick: () => void;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  return <BaseBreadcrumb {...props} />;
};

interface CurrentFolderBreadcumbProps extends BaseProps {
  singleItem?: boolean;
}

export const CurrentFolderBreadcumb: React.FC<CurrentFolderBreadcumbProps> = (props) => {
  return <BaseBreadcrumb {...props} currentFolder={true} bigger={props.singleItem} />;
};

interface DisabledBreadcrumbProps extends BaseProps {}

export const DisabledBreadcrumb: React.FC<DisabledBreadcrumbProps> = (props) => {
  return <BaseBreadcrumb {...props} disabled={true} />;
};
