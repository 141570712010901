import { Colors, Component } from '@lessonup/teaching-core';
import _ from 'lodash';
import React from 'react';
import DragQuestionComponentBorder from '../viewComponentHelpers/DragQuestionComponentBorder';

interface ComponentContainerProps {
  component: Component.All;
  onClickHandler?: Function;
  children?: any;
  componentTypeClasses?: string;
  componentTypeStyles?: React.CSSProperties;
  isInteractive?: boolean;
  isThumb?: boolean;
}

export default class ComponentContainer extends React.Component<ComponentContainerProps> {
  public constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  private classes = () => {
    const { component, componentTypeClasses, isInteractive, isThumb } = this.props;
    const shadow =
      // These components control their own shadow
      !(['text', 'image', 'dropzone'] as Component.Type[]).includes(component.type) &&
      Component.getSetting(component, 'shadow')
        ? 'shadow'
        : '';
    const extraClasses = componentTypeClasses || '';
    const interactiveClass = isInteractive ? 'interactive' : '';
    const isThumbClass = isThumb ? 'is-thumb' : '';
    return `component component-container component-${component.type} ${shadow} ${extraClasses} ${interactiveClass} ${isThumbClass}`;
  };

  private positionStyle = () => {
    const { position } = this.props.component;
    const positionsPercentages = _.mapValues(position, (v) => v + '%');

    const inset = Component.isBox(position)
      ? ['top', 'right', 'bottom', 'left'].map((position) => positionsPercentages[position]).join(' ')
      : undefined;

    return {
      ...positionsPercentages,
      inset,
    };
  };

  private scalePropsStyle = () => {
    const { scaleProps } = this.props.component;
    if (!scaleProps) return;

    const styleObject = {};
    _.each(scaleProps, (v, k) => (styleObject[_.camelCase(k)] = v + '%'));
    return styleObject;
  };

  private onClickHandler(e: React.MouseEvent | React.TouchEvent) {
    if (this.props.onClickHandler) {
      this.props.onClickHandler(e);
    }
  }

  public render() {
    const { component } = this.props;
    const { settings, type, _id, rotation } = component;
    const fgColor = Component.getSetting(component, 'color');
    const bgColor = Component.getSetting(component, 'backgroundColor');
    const color = fgColor ? Colors.getHexColor(fgColor) : undefined;
    const backgroundColor =
      // These component control their own bg color
      !(['dropzone', 'text'] as Component.Type[]).includes(component.type) && bgColor
        ? Colors.getHexColor(bgColor)
        : undefined;

    const style = {
      ...this.positionStyle(),
      ...this.scalePropsStyle(),
      color,
      backgroundColor,
      ...this.props.componentTypeStyles,
    };

    if (rotation) style['transform'] = `rotate(${rotation}deg)`;
    return (
      <div
        data-component-id={_id}
        data-component={type}
        data-grippy="move"
        className={this.classes()}
        style={style}
        onClick={this.onClickHandler}
      >
        {this.props.children}
        {settings && settings.isDraggable && (
          <DragQuestionComponentBorder borderRadius={component.settings.borderRadius} />
        )}
      </div>
    );
  }
}
