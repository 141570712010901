import _ from 'lodash';
import { TooltipName } from '../utils/tooltips/TooltipContext';
import { StoreState } from './store';

export const isUserSignedIn = () => (store: StoreState) => {
  return !!store.user.user;
};

export const loggedInUser = () => (store: StoreState) => {
  return store.user.user;
};

export const schoolForUser = () => (store: StoreState) => {
  return store.user.user && store.user.user.school;
};

export const activeLessonForUser = () => (store: StoreState) => {
  return store.user.user && store.user.user.activeLesson;
};

export const activeDocumentForUser = () => (store: StoreState) => {
  return store.user.user && store.user.user.activeDocument;
};

export const isTooltipShown = (id: TooltipName) => (store: StoreState) => {
  return store.tooltips.includes(id);
};

export const shouldShowPlansTab = () => (store: StoreState) => {
  return store.channel.showPlansTab;
};

export const lessonResults = () => (store: StoreState) => {
  return store.search.lessonResults;
};

export const pinResults = () => (store: StoreState) => {
  return store.searchPins.results;
};

export const itemSearchForComponent = () => (store: StoreState) => {
  return store.searchPins.forComponent;
};

export const averageRatingForLesson = (lessonId: string) => (store: StoreState) => {
  return store.ratings.userRatings[lessonId];
};

export const selectedPins = () => (store: StoreState) => store.selectPins;

export const isChannelFollowed = (channelSlug: string | undefined) => (store: StoreState) => {
  return _.includes(store.followedChannels, channelSlug);
};

export const planResults = () => (store: StoreState) => {
  return store.search.planResults;
};

export const lessonStore = () => (store: StoreState) => {
  return store.lesson;
};

export const lessonDetails = () => (store: StoreState) => {
  // TODO: More specific name?
  return store.lesson.lessonDetails;
};

export const userRatings = () => (store: StoreState) => {
  return store.ratings.userRatings;
};

export const selectedLessonFacets = () => (store: StoreState) => {
  return (store.search.lessonResults && store.search.lessonResults.request.facets) || [];
};

export const selectedPinFacets = () => (store: StoreState) => {
  return (store.searchPins.results && store.searchPins.results.request.facets) || [];
};

export const lessonSearchQuery = () => (store: StoreState) => {
  return store.search.lessonResults && store.search.lessonResults.request;
};

export const savedLessons = () => (store: StoreState) => {
  return store.savedLessons;
};

export const thumbPins = () => (store: StoreState) => {
  return store.pins;
};

export const channelStore = () => (store: StoreState) => {
  return store.channel;
};

export const channelDetails = () => (store: StoreState) => {
  return store.channel.channelDetails;
};

export const channelState = () => (store: StoreState) => {
  return store.channel.channelDetails && store.channel.channelDetails.channel;
};

export const channelContentPage = () => (store: StoreState) => {
  return store.channel.channelDetails && store.channel.channelDetails.contentPageData;
};

export const channelFolderPage = () => (store: StoreState) => {
  return store.channel.channelDetails && store.channel.channelDetails.folderPage;
};

export const lessonPlanDetails = () => (store: StoreState) => {
  return store.plan;
};

export const navFromSearch = () => (store: StoreState) => {
  return store.channel.navFrom;
};

export const channelsForOverview = () => (store: StoreState) => {
  return store.channelOverview;
};

export const featureFlagsForUser = () => (store: StoreState) => {
  return store.featureFlags;
};

export const geoLocation = () => (store: StoreState) => {
  return store.geoLocation;
};

export const organizationsForPicker = () => (store: StoreState) => {
  return store.organizationPicker.organizations;
};

export const currentError = () => (store: StoreState) => {
  return store.error.currentError;
};
