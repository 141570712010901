import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ExplorerContentSplit,
  ExplorerLessonContent,
  ExplorerUserContent,
  isLessonContent,
  Location,
} from '../../../../domain/newExplorers';
import { useBEM } from '../../../utils/hooks';
import { ModalProps } from '../../../utils/ModalManagerService';
import ActionButton from '../../Button';
import {
  SelectionContextProvider,
  splitContentAndItemsToExplorerContent,
  useContents,
  useExplorerSelectionContext,
} from '../../newExplorer';
import { CondensedExplorer } from '../../newExplorer/CondensedExplorer/CondensedExplorer';
import { ExplorerPickerBaseProps } from '../explorerPickers';
import { Modal } from '../Modal';
import ModalButtonBar from '../ModalButtonBar';
import './ExplorerContentPickerModal.less';
import SelectedContentConfirmation from './SelectedContentConfirmation';

export type ExplorerContentPickerModalResponse = ExplorerUserContent[];
export interface ExplorerContentPickerModalParams extends ExplorerPickerBaseProps {
  initialLocation?: Location;
  selectedLessons?: ExplorerLessonContent[];
  isMultiselect?: boolean;
  hideRootExplorerButton?: boolean;
  hideTests?: boolean;
  startAtLastOpenedLesson?: boolean;
}
type Props = ModalProps<ExplorerContentPickerModalResponse> & ExplorerContentPickerModalParams;

export const ExplorerContentPickerModal: React.FC<Props> = (props) => {
  return (
    <SelectionContextProvider>
      <ExplorerContentPickerModalInner {...props} />
    </SelectionContextProvider>
  );
};

export const ExplorerContentPickerModalInner: React.FC<Props> = ({
  rootExplorer,
  initialExplorer,
  sharedExplorers,
  fetchLessonsAndPlansForLocation,
  closeModal,
  translationKey,
  isMultiselect = false,
  initialLocation,
  selectedLessons = [],
  hideRootExplorerButton,
  enabledTypes,
  hideTests,
}) => {
  const { t } = useTranslation(translationKey || 'explorerContentPickerModal');
  const bemClasses = useBEM('ExplorerContentPickerModal');
  const startingLocation: Location = useMemo(
    () => initialLocation || { explorer: initialExplorer._id, folder: initialExplorer._id },
    [initialLocation, initialExplorer]
  );
  const [location, setLocation] = useState<Location>(startingLocation);
  const [selectedContent, setSelectedContent] = useState<ExplorerLessonContent[]>(selectedLessons);
  const { contents, explorer: currentExplorer } = useContents(
    rootExplorer,
    initialExplorer,
    location,
    sharedExplorers,
    fetchLessonsAndPlansForLocation
  );
  const { selectedRows } = useExplorerSelectionContext();

  const contentsWithoutTests = (): ExplorerContentSplit => {
    const filteredLessons = contents.lessons.filter((lesson) => !lesson.content.isTest);
    const filteredContents = { ...contents, lessons: filteredLessons };
    return filteredContents;
  };

  const changeLocation = (location: Location) => {
    setLocation(location);
  };

  const onContentSelect = (content: ExplorerUserContent[]) => {
    if (isMultiselect) return;
    const selectedContentIsNotLesson = !content.filter(isLessonContent).length;
    if (selectedContentIsNotLesson) return closeModal(content);
    setSelectedContent(content.filter(isLessonContent));
  };

  const pickButtonClick = () => {
    const selectedContents = selectedRows.map((item) => splitContentAndItemsToExplorerContent(contents, item));
    closeModal(selectedContents);
  };

  if (selectedContent.length) {
    return (
      <Modal className={bemClasses()} title={t('title')} isOpen onRequestClose={() => closeModal([])} width="large">
        <div className={bemClasses({ element: 'wrapper' })}>
          <SelectedContentConfirmation
            content={selectedContent}
            onConfirm={closeModal}
            onCancel={() => setSelectedContent([])}
          />
        </div>
      </Modal>
    );
  }

  return (
    <Modal className={bemClasses()} title={t('title')} isOpen onRequestClose={() => closeModal([])} width="large">
      <CondensedExplorer
        className={bemClasses({ element: 'explorer', className: 'padding-4' })}
        contents={hideTests ? contentsWithoutTests() : contents}
        rootExplorer={rootExplorer}
        currentExplorer={currentExplorer}
        location={location}
        onLocationChange={changeLocation}
        contentSelection={{ onContentSelect, isMultiselect }}
        hideRootExplorerButton={hideRootExplorerButton}
        enabledTypes={enabledTypes}
      />
      <ModalButtonBar className={bemClasses({ element: 'actions', className: 'margin-top-4' })}>
        {isMultiselect && (
          <ActionButton
            className={bemClasses({ element: 'pick-button' })}
            onClick={pickButtonClick}
            height="small"
            theme="secondary"
            version="modern"
          >
            {t('pickFolderButton')}
          </ActionButton>
        )}
      </ModalButtonBar>
    </Modal>
  );
};
