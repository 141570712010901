import { Language, LanguageSingleton } from '../../domain';

export function productSiteRoot(language: Language.Key = LanguageSingleton.get()) {
  return language === 'nl' ? '/site/nl' : '/site/en';
}

export function sitePricingRoute(language: Language.MainKey) {
  if (language === 'nl') return '/site/nl/tarieven';
  return '/site/en/pricing-of-lessonup';
}

export function schoolLicenseBasicInfo(language: Language.MainKey) {
  if (language === 'nl') return '/site/nl/schoollicentie-basis';

  return '/site/en/pricing-of-lessonup';
}

export function schoolLicenseContactForm(language: Language.MainKey) {
  if (language === 'nl') return '/site/nl/offerte-schoollicentie-basis';

  // We do not offer a basic license in English at this point, so we will redirect to the general page instead.
  return '/site/en/schools';
}

export function aboutLessonUpRoute(language: Language.Key = LanguageSingleton.get()) {
  return language === 'nl' ? '/site/nl/docenten' : '/site/en/teachers';
}

export function channelInfoRoute(language: Language.Key = LanguageSingleton.get()) {
  return language === 'nl' ? '/site/nl/uitgevers' : '/site/en/publishers';
}

export function cookieStatementRoute(language: Language.Key = LanguageSingleton.get()) {
  return `/site/${language}/cookie-statement`;
}

export function privacyRoute(language: Language.Key = LanguageSingleton.get()) {
  return language === 'nl' ? '/site/nl/privacy' : '/site/en/privacy-en';
}

export function termsRoute(language: Language.Key = LanguageSingleton.get()) {
  return language === 'nl' ? '/site/nl/voorwaarden' : '/site/en/terms';
}

export function schoolExplanationArticle(language: Language.Key = LanguageSingleton.get()) {
  return language === 'nl' ? '/site/nl/scholen' : '/site/en/schools';
}

export function webinarRoute(language: Language.Key = LanguageSingleton.get()) {
  return language === 'nl' ? '/site/nl/webinars' : '/site/en/webinars';
}

export function contactRequestQuote(language: Language.Key = LanguageSingleton.get()) {
  return language === 'nl' ? '/site/nl/offerte-aanvragen' : '/site/en/request-quote';
}
