import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useBEM } from '../../utils/hooks';
import './Input.less';
import { InputLabel } from './InputLabel/InputLabel';
import { InputSubtext } from './InputSubtext/InputSubtext';

export type InputType = 'text' | 'email' | 'password' | 'number' | 'date';

export interface InputProps {
  labelText?: string;
  placeholderText?: string;
  disabled?: boolean;
  type?: InputType;
  id?: string;
  error?: string;
  onChange?: (e: any) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  autoComplete?: React.InputHTMLAttributes<HTMLInputElement>['autoComplete'];
  initial?: string;
  autoFocus?: boolean;
  className?: string;
  ariaLabel?: string;
  iconElement?: (className: string) => React.ReactElement;
}

export const Input = ({
  labelText,
  disabled,
  placeholderText,
  type,
  id,
  error,
  onChange,
  onBlur,
  onFocus,
  autoComplete,
  initial,
  autoFocus,
  className,
  ariaLabel,
  iconElement,
}: InputProps): JSX.Element => {
  const [labelId, setLabelId] = useState<string | undefined>(undefined);
  const bemClasses = useBEM('input');

  useEffect(() => {
    setLabelId(uniqueId('inputId-'));
  }, []);

  return (
    <div className={bemClasses({ className, modifiers: [iconElement ? 'has-icon' : false] })}>
      {labelText && <InputLabel text={labelText} id={id || labelId || ''} disabled={!!disabled} />}
      {iconElement ? iconElement(bemClasses({ element: 'icon' })) : null}
      <input
        aria-label={ariaLabel || ''}
        id={id || labelId}
        className={bemClasses({
          element: 'field',
          modifiers: [error ? 'error' : false, disabled ? 'disabled' : false, iconElement ? 'has-icon' : false],
        })}
        placeholder={placeholderText}
        disabled={disabled}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        autoComplete={autoComplete || 'off'}
        defaultValue={initial}
        autoFocus={autoFocus}
      />
      <InputSubtext subtext={error || ''} subtextVariant={error ? 'error' : 'neutral'} />
    </div>
  );
};
