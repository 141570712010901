import { logger, Router } from '@lessonup/client-integration';
import { History, Location } from 'history';
import { isTeacherUrl } from './isTeacherUrl/isTeacherUrl';

export function bridgeRouter(location: Location, history: History<unknown>, params: Record<string, string>): Router {
  const { pathname, search } = location;
  const query = Object.fromEntries(new URLSearchParams(search));
  return {
    route: {
      name: pathname,
      params: {
        hash: location.hash,
        query,
        ...params,
      },
    },
    go: (url: string) => {
      if (isTeacherUrl(url)) {
        window.location.href = url;
        return;
      }
      history.push(url);
    },
    replace: (url: string) => history.replace(url),
  };
}

export function setBridgeLogger(): void {
  logger.registerTransport({
    info: console.info,
    error: console.error,
    warn: console.warn,
    debug: console.debug,
  });
}
