import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnchorButton } from '../../../../shared-core/ui/components/Button';
import { Modal } from '../../../../shared-core/ui/components/modals/Modal';
import ModalButtonBar from '../../../../shared-core/ui/components/modals/ModalButtonBar';
import { ReactRouterLinkButton } from '../../buttons/ReactRouterButton';

export interface LockedFolderModalProps {
  link: string;
  target: 'app' | 'blank';
}

type Props = LockedFolderModalProps & { closeModal: (res: void) => void };

const LockedFolderModal: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation('lessonFolder');

  function handleClose() {
    props.closeModal();
  }

  const MoreButton =
    props.target === 'app' ? (
      <ReactRouterLinkButton theme="primary" to={props.link} onClick={handleClose}>
        {t('modalInfo')}
      </ReactRouterLinkButton>
    ) : (
      <AnchorButton theme="primary" href={props.link} target="_blank">
        {t('modalInfo')}
      </AnchorButton>
    );

  return (
    <Modal title={t('modalTitle')} isOpen onRequestClose={handleClose} width="medium">
      <p className="modal-description">{t('modalMessage')}</p>
      <ModalButtonBar>
        {MoreButton}
        <AnchorButton className="spacing-left" onClick={handleClose} theme="secondary">
          {t('modalClose')}
        </AnchorButton>
      </ModalButtonBar>
    </Modal>
  );
};

export default LockedFolderModal;
