interface Props {
  experimentId: string;
}

export const getOptimizeTestVariantByExperimentId = ({ experimentId }: Props): string | undefined => {
  if (typeof window === 'undefined') return;

  if (window['google_optimize'] !== undefined) {
    return window['google_optimize'].get(experimentId);
  }
};

export const isPartOfOptimizeTestGroup = ({
  experimentId,
  variant,
}: {
  experimentId: string;
  variant: string;
}): boolean => {
  const variantIdForExperiment = getOptimizeTestVariantByExperimentId({ experimentId });
  return variantIdForExperiment === variant;
};
