import { Component, LessonPin } from '@lessonup/teaching-core';
import _ from 'lodash';

export interface ContentImage {
  url: string;
}

export interface ContentImageData {
  main: ContentImage | undefined;
  list: ContentImage[];
  hasImage: boolean;
}

export namespace ContentImage {
  export function fromPin(pin: LessonPin): ContentImageData {
    const { item } = pin;
    const { image, image2 } = item;
    const imageComponents = (item.components || []).filter(Component.isImage);
    const list: ContentImage[] = _.compact([image, image2, ...imageComponents.map((comp) => comp.settings.url)]).map(
      (url) => ({
        url,
      })
    );
    return {
      main: list[0],
      hasImage: !!list[0],
      list,
    };
  }
}
