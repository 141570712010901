import React from 'react';

import { AssignmentPin } from '@lessonup/teaching-core';
import ImageComponent, { ImageComponentPropsForPin } from '../../images/ImageComponent';
import PinContainer from '../../pin/PinContainer';
import { MapPinProps } from '../../pin/PinView';
import ViewComponents from '../../viewComponents/ViewComponents';

interface Props extends MapPinProps {
  pin: AssignmentPin.DragQuestion;
}

export default class DragQuestionView extends React.Component<Props> {
  public render() {
    const { item } = this.props.pin;
    const { pin, isThumb } = this.props;
    const imageProps = ImageComponentPropsForPin(pin, 'image', isThumb);
    return (
      <PinContainer pin={this.props.pin}>
        {item.image && <ImageComponent {...imageProps} />}
        <ViewComponents
          components={item.components}
          isThumb={isThumb}
          expandOnlyOne={item.settings?.closeOtherHotspots}
        />
      </PinContainer>
    );
  }
}
