import { LessonPin } from '@lessonup/teaching-core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Channel, IndexedChannelInfo, Language, LanguageSingleton, LessonPlan } from '../../../../shared-core/domain';
import { channelRoute } from '../../../../shared-core/services/app/searchRoutes';
import useCallbackIfParamsChanged from '../../../../shared-core/ui/utils/hooks/useCallbackIfParamsChanged';
import LessonPlanChild from '../../../components/lessonPlan/LessonPlanChild';
import { fetchedChannelContentPageAction, navFromPageAction } from '../../../redux/actions/actions';
import { navFromSearch } from '../../../redux/selectors';
import { ChannelSubPageProps } from '../ChannelPageView';

interface FetchParams {
  slug: string | undefined;
  page: string | undefined;
}

const ChannelContentPage: React.FC<ChannelSubPageProps> = ({ activeTab, details, channelService, ...props }) => {
  const { channel, contentPageData, publicFolder } = details;
  const navigationCheck = useSelector(navFromSearch());
  const dispatch = useDispatch();
  const channelSlug = channel.name;
  const page = activeTab.channelPage;

  const language = LanguageSingleton.get();
  const contentPage = Channel.contentPage(channel, page as Channel.ContentPageKey, language);

  type ChannelContentParams = { page: string; language: Language.Key };
  useCallbackIfParamsChanged<ChannelContentParams | undefined>({
    initial: contentPageData && { page: contentPageData.page, language: contentPageData.language },
    params: { page, language: LanguageSingleton.get() },
    callback: async (props) => {
      if (!props) return null;
      if (!Channel.isContentPageKey(props.page)) return null;
      const channelData = await channelService.channelContentPage(channelSlug, props.page, props.language);
      dispatch(fetchedChannelContentPageAction(channelData.contentPage));
    },
  });

  useEffect(() => {
    if (navigationCheck !== 'content') {
      dispatch(navFromPageAction('content'));
    }
  }, [navigationCheck, dispatch]);

  if (!contentPage || !publicFolder) {
    return null;
  }

  const { lessons, lessonPlans, folders } = contentPageData || {};
  const sortedContent = Object.values(contentPage).sort(LessonPin.sortByOrder);

  return (
    <div className="channel-about selectable">
      <div className="content-page plan-styles view">
        <div className="plan-children">
          {Object.values(sortedContent).map((child: LessonPlan.Children.AnyChild) => (
            <div key={child.id}>
              <LessonPlanChild
                child={child}
                lessons={lessons}
                plans={lessonPlans}
                folders={folders}
                channelInfo={channel ? IndexedChannelInfo.fromChannel(channel) : undefined}
                linkToChannel={(id) =>
                  channelRoute({
                    channelSlug,
                    channelPage: child.type === 'lessonPlan' ? 'plan' : 'lesson',
                    selectionId: id,
                  })
                }
              />
            </div>
          ))}
          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  );
};

export default ChannelContentPage;
