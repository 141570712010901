import { RegistrationStep } from '../../../../shared-core/services/app/searchRoutes';
import { assertNever } from '../../../../shared-core/utils';
import { Context } from './RegistrationContext';

const DEFAULT_STEP = 'select';

export function nextRegistrationStep(
  currentStep: RegistrationStep | undefined,
  registrationContext: Pick<Context, 'domainRuleSuggestions' | 'demoLesson'>
): RegistrationStep | undefined {
  if (!currentStep) return DEFAULT_STEP;

  const hasSuggestions = !!registrationContext.domainRuleSuggestions.length;
  const hasDemoLesson = !!registrationContext.demoLesson;

  switch (currentStep) {
    case 'select':
      return 'credentials';
    case 'referral':
      return 'credentials';
    case 'credentials':
      return 'verification-sent';
    case 'password':
      return 'terms';
    case 'terms':
      return afterTerms(hasSuggestions);
    case 'suggestions':
      return afterSuggestionsOrOrganization(hasDemoLesson);
    case 'organization':
      // TODO: Skip organization step when user got org from domain rules automatically
      return afterSuggestionsOrOrganization(hasDemoLesson);
    case 'pick-child':
      return afterSuggestionsOrOrganization(hasDemoLesson);
    case 'demo-lesson':
      return undefined;
    case 'pick-child-school-page':
      return undefined;
    case 'registration-gift':
      return undefined;
    case 'verification-expired':
      return 'credentials';
    case 'verification-sent':
      return undefined;
    case 'verify-email':
      return 'password';
    default:
      assertNever(currentStep, 'Unknown step in registration flow');
  }
}

function afterTerms(hasSuggestions: boolean) {
  return hasSuggestions ? 'suggestions' : 'organization';
}

function afterSuggestionsOrOrganization(hasDemoLesson: boolean) {
  return hasDemoLesson ? 'demo-lesson' : undefined;
}
