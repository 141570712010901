import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { productVoucherPatternMatch } from '../../../../domain/vouchers';
import { teacherAccountUrl } from '../../../../services/app/teacherRoutes';
import useToggle from '../../../utils/useToggle';
import ButtonWithLoader from '../../buttons/ButtonWithLoader';
import VoucherInputField from '../../inputElements/VoucherInputField/VoucherInputField';
import './RedeemVoucherForm.less';

const MASK = 'LU-000-000-000';

interface RedeemVoucherPageProps {
  handleInputChange: (s: string) => void;
  handleSubmit: () => void;
  error: string | undefined;
  isFeatureFlagEnabled: (flag: string) => boolean;
}

const RedeemVoucherForm: React.FC<RedeemVoucherPageProps> = ({
  handleInputChange,
  error,
  handleSubmit,
  isFeatureFlagEnabled,
}) => {
  const { t } = useTranslation('redeemVoucher');
  const [complete, toggleComplete] = useToggle(false);
  const [loading, setLoading] = useState(false);

  async function onSubmit(e: React.FormEvent): Promise<void> {
    setLoading(true);
    e.preventDefault();
    await handleSubmit();
    setLoading(false);
  }

  function onComplete(val: string) {
    if (val) {
      toggleComplete(true);
    }
  }

  function onChange(value: string) {
    if (complete && !productVoucherPatternMatch.test(value)) {
      toggleComplete(false);
    }

    handleInputChange(value);
  }

  return (
    <div className="redeem-voucher-form-wrapper">
      <p className="redeem-voucher-description">{t('description')}</p>
      <form className="default-form voucher-form" onSubmit={onSubmit}>
        <VoucherInputField
          onChange={onChange}
          onComplete={onComplete}
          mask={MASK}
          className="voucher-input-field"
          hasError={!!error}
        />
        {isFeatureFlagEnabled('redeemedVoucherOverview') && <p>{t('foldersWillShowUp')}</p>}
        <Error error={error && t(error, { licenseLink: teacherAccountUrl() })} />
        <div className="voucher-input-access">
          <ButtonWithLoader type="submit" disabled={!complete} loading={loading} version="modern" theme="secondary">
            {t('confirmVoucherCode')}
          </ButtonWithLoader>
        </div>
      </form>
    </div>
  );
};

function Error({ error }: { error: string | undefined }) {
  return error ? <div dangerouslySetInnerHTML={{ __html: error }} className="error-message"></div> : null;
}

export default RedeemVoucherForm;
