import { Component } from '@lessonup/teaching-core';
import classNames from 'classnames';
import React from 'react';
import { ImageUtils } from '../../../../utils/ImageUtils';
import ComponentContainer from '../componentContainer/ComponentContainer';

interface Props {
  component: Component.Image;
  onClickHandler?: Function;
  isThumb?: boolean;
}

export default class ImageComponentView extends React.PureComponent<Props> {
  private style = () => {
    const { settings } = this.props.component;
    const imageUrl = ImageUtils.checkImageUrl(settings.url);

    return {
      backgroundImage: ImageUtils.urlString(imageUrl),
      backgroundSize: settings.imageSize,
      borderRadius: settings.borderRadius,
    };
  };

  private classes = () => {
    const { settings } = this.props.component;

    const allowZoomClass = `allowzoom-${settings.allowZoom ? 'true' : 'false'}`;
    const opacityClass = `opacity-${settings.transparency}`;

    return `${allowZoomClass} ${opacityClass}`;
  };

  public render() {
    const { shadow } = this.props.component.settings;
    return (
      <ComponentContainer {...this.props} componentTypeClasses={this.classes()}>
        <div
          className={classNames('comp-image', { shadow: Boolean(shadow) })}
          data-grippy="move"
          style={this.style()}
        ></div>
      </ComponentContainer>
    );
  }
}
