import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from '../../utils/ModalManagerService';
import ActionButton from '../Button';
import TextButton from '../buttons/TextButton';
import { Modal } from './Modal';
import ModalButtonBar from './ModalButtonBar';
import './SchoolSuggestion.less';

const TRANSLATION_NS = 'schoolSuggestionModal';

export type SuggestedOrganization = {
  _id: string;
  city?: string;
  name: string;
};

export type SchoolSuggestionModalResponse =
  | {
      organization: SuggestedOrganization;
    }
  | undefined;

export type SchoolSuggestionModalProps = {
  organizations: SuggestedOrganization[];
};

type Props = ModalProps<SchoolSuggestionModalResponse> & SchoolSuggestionModalProps;

const SchoolSuggestion: React.FC<Props> = ({ closeModal, organizations }) => {
  const { t } = useTranslation(TRANSLATION_NS);
  const [schoolSuggestion, setSchoolSuggestion] = useState<SuggestedOrganization | undefined>();
  const title = t('title', { count: organizations.length });

  useEffect(() => {
    if (organizations.length === 1) {
      setSchoolSuggestion(organizations[0]);
    }
  }, [organizations]);

  function handleClose() {
    closeModal(undefined);
  }

  function handleSetSchool() {
    if (!schoolSuggestion) return;

    closeModal({ organization: schoolSuggestion });
  }

  function handleChange(selection: string) {
    const organization = organizations.find((organization) => organization._id === selection);
    setSchoolSuggestion(organization);
  }

  return (
    <Modal
      width="medium"
      title={title}
      isOpen
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      className="suggestion_modal"
    >
      <div>
        <p>{t('description', { count: organizations.length })}</p>
        <div className="suggestion_container">
          {organizations.map((organization) => {
            return (
              <div
                className={classNames('school_suggestion', { selected: organization._id === schoolSuggestion?._id })}
                key={organization._id}
                role="button"
                onClick={() => handleChange(organization._id)}
              >
                {organization.name}
                <span className="school_suggestion_city">{organization.city || ''}</span>
              </div>
            );
          })}
        </div>

        <ModalButtonBar className="spread_modal_buttons">
          <TextButton color="red" onClick={handleClose}>
            {t('noSuggestion', { count: organizations.length })}
          </TextButton>
          <ActionButton theme="secondary" disabled={!schoolSuggestion} onClick={handleSetSchool}>
            {t('suggestion', { count: organizations.length })}
          </ActionButton>
        </ModalButtonBar>
      </div>
    </Modal>
  );
};

export default SchoolSuggestion;
