import { AssignmentPin } from '@lessonup/teaching-core';
import React from 'react';

import { PinEntry } from '@lessonup/teaching-core';
import BasePollView, { BasePollProps } from './BasePoll';
import './Poll.less';
import PollContent from './PollContent/PollContent';

export interface PollViewProps extends BasePollProps {
  pin: AssignmentPin.Poll;
  correctAnswers: undefined;
  isPlayer?: boolean;
  isThumb?: boolean;
  handleSubmitAnswer?: (answer: number) => Promise<void>;
  pollAnswer?: number;
  answerError?: string;
  pinEntries?: PinEntry.Poll[];
  renderComponents?: (pin: AssignmentPin.Poll, isPlayer?: boolean, isThumb?: boolean) => JSX.Element;
}

const PollView: React.FC<PollViewProps> = (props) => {
  return (
    <BasePollView
      pin={props.pin}
      correctAnswers={props.correctAnswers}
      isPlayer={props.isPlayer}
      isThumb={props.isThumb}
      renderComponents={props.renderComponents}
    >
      <PollContent
        pin={props.pin}
        isThumb={props.isThumb}
        handleSubmitAnswer={props.handleSubmitAnswer}
        pollAnswer={props.pollAnswer}
        answerError={props.answerError}
      />
      {props.children || null}
    </BasePollView>
  );
};

export default PollView;
