import { Fonts } from '@lessonup/teaching-core';

import { EnvConfig } from '../app';

function loadWebFontInBrowser() {
  if (typeof window === 'undefined') return;
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const WebFont = require('webfontloader');
  return WebFont;
}

export function loadPrimaryFonts(config: EnvConfig) {
  const options = {
    ...Fonts.primaryFonts,
    fontinactive(e) {
      if (config.runsOnLocalhost) console.warn('fontinactive', e, config);
    },
  };

  const WebFont = loadWebFontInBrowser();
  WebFont && WebFont.load(options);
}

export function loadConditionalFont(font: Fonts.ConditionalFont) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const WebFont = loadWebFontInBrowser();
  WebFont &&
    WebFont.load(
      'url' in font
        ? { custom: { families: font?.families, urls: [font?.url] } }
        : 'google' in font
        ? { google: { families: font.families } }
        : undefined
    );
}
