import React from 'react';

interface TableBodyProps {
  className?: string;
  children?: React.ReactNode;
}

export const TableBody = React.forwardRef<HTMLTableSectionElement, TableBodyProps>(({ children, className }, ref) => (
  <tbody className={className} ref={ref}>
    {children}
  </tbody>
));

export default TableBody;
