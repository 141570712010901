import React from 'react';

export default function ArrowDownRight(props) {
  return (
    <svg className="arrow" viewBox="0 0 17 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeWidth="2">
        <polyline id="Path-3" points="11 6 15 10 11 14"></polyline>
        <polyline id="Path-4" points="1 1 1 10 15 10"></polyline>
      </g>
    </svg>
  );
}
