import classNames from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { SearchParams } from '../../../../shared-core/domain';
import { channelRoute, lessonSearchRoute, searchPlanRoute } from '../../../../shared-core/services/app/searchRoutes';
import { GrayTab, GrayTabs } from '../../../../shared-core/ui/components/tabs/GrayTabs';
import PassiveTooltip from '../../../../shared-core/ui/components/tooltip/PassiveTooltip';
import capitalize from '../../../../shared-core/utils/capitalize';
import { areSearchParamsEqual, useSearchContext } from '../../../pages/search/sidebar/SearchContext';
import { lessonResults, planResults } from '../../../redux/selectors';
import './SearchResultTabs.less';
export interface Tab {
  key: string;
  label: string;
  path: string;
  children?: React.ReactNode;
}

const SearchResultTabs: React.FunctionComponent = () => {
  const { t } = useTranslation(['searchResultTabs', 'common']);
  const lessons = useSelector(lessonResults());
  const plans = useSelector(planResults());
  const currentParams = useSearchContext().currentParams();
  const match = useRouteMatch();
  let lessonParams = currentParams;
  let planParams = currentParams;
  const channelSlug = match.params['channelSlug'];
  const onChannelRoute = !!channelSlug;

  // always make sure we have sane urls in the tabs
  if (lessons && areSearchParamsEqual(currentParams, lessons.request, true)) {
    lessonParams = lessons.request;
  } else {
    lessonParams.size = undefined;
  }
  lessonParams.from = undefined;

  if (plans && areSearchParamsEqual(currentParams, plans.request, true)) {
    planParams = plans.request;
  } else {
    planParams.size = undefined;
  }
  planParams.from = undefined;

  const planUrlData = SearchParams.searchParamsToUrlComponents(planParams);
  const lessonUrlData = SearchParams.searchParamsToUrlComponents(lessonParams);

  const lessonPath = onChannelRoute
    ? channelRoute({
        ...lessonUrlData.urlDict,
        channelSlug,
        channelPage: 'search',
        selectionId: 'lessons',
        qs: lessonUrlData.queryString,
      })
    : lessonSearchRoute({
        ...lessonUrlData.urlDict,
        qs: lessonUrlData.queryString,
      });

  const plansPath = onChannelRoute
    ? channelRoute({
        ...lessonUrlData.urlDict,
        channelSlug,
        channelPage: 'search',
        selectionId: 'plans',
        qs: lessonUrlData.queryString,
      })
    : searchPlanRoute({
        ...planUrlData.urlDict,
        qs: planUrlData.queryString,
      });

  const tabs: Tab[] = [
    {
      key: 'lessons',
      label: t('common:lessons'),
      path: lessonPath,
    },
    {
      key: 'plans',
      label: t('common:lessonPlans'),
      path: plansPath,
      children: (
        <ExplanationTag
          labelTextSmall="?"
          labelTextLarge={t('lessonPlanTipLabel')}
          explanation={<Trans t={t} i18nKey="lessonPlanExplanation" />}
        />
      ),
    },
  ];

  const active = currentParams.index;
  function renderTabs() {
    return tabs.map((tab) => {
      return (
        <Link className="search-tab-wrapper" to={tab.path} key={tab.key}>
          <GrayTab active={active} name={tab.key}>
            <span>{capitalize(tab.label)}</span>
            {tab.children || null}
          </GrayTab>
        </Link>
      );
    });
  }

  return (
    <div className="search-result-tabs">
      <GrayTabs>{renderTabs()}</GrayTabs>
    </div>
  );
};

interface ExplanationTagProps {
  labelTextSmall: string;
  labelTextLarge: string;
  explanation: string | JSX.Element;
  Action?: JSX.Element | React.ReactElement;
}

const ExplanationTag: React.FunctionComponent<ExplanationTagProps> = (props) => {
  const color = 'yellow';

  return (
    <span className={classNames('explanation-tag', `bg-${color}`, 'tipped', 'rel')}>
      <span className="d-block d-sm-none">{props.labelTextSmall}</span>
      <span className="d-none d-sm-block">{props.labelTextLarge}</span>
      <PassiveTooltip direction="left" customClassNames="explanation-tag-tooltip">
        {props.explanation}
      </PassiveTooltip>
    </span>
  );
};

export default SearchResultTabs;
