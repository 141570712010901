import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IndexedChannelInfo, SearchParams } from '../../../../shared-core/domain';
import useScrollPage from '../../../../shared-core/ui/utils/useScrollPage';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import SearchHiddenUrls from '../../../components/search/SearchHiddenUrls/SearchHiddenUrls';
import SearchPageMeta from '../../../components/search/SearchPageTitle/SearchPageMeta';
import { fetchedPlansAction } from '../../../redux/actions/actions';
import { planResults } from '../../../redux/selectors';
import { useSpinnerContext } from '../../../utils/loaders/SpinnerContext';
import { SearchContextProvider } from '../sidebar/SearchContext';
import PlanSearchPageView from './PlanSearchPageView';

type Props = {
  searchParams: SearchParams;
  urlCreator: (params: SearchParams) => string;
  channelInfo?: IndexedChannelInfo;
  showTabs?: boolean;
};

const PlanSearch: React.FunctionComponent<Props> = ({ searchParams, urlCreator, channelInfo, showTabs }) => {
  const results = useSelector(planResults());
  const dispatch = useDispatch();
  const storeSearchParams = results && results.request;
  const [fetching, setFetching] = useState<boolean>(false);
  const spinner = useSpinnerContext();
  const { scrollToTop } = useScrollPage();
  const services = useAppServices();

  const fetch = useCallback(
    async (params: SearchParams, isPagination?: boolean) => {
      setFetching(true);
      const overrideExistingResults = !!results;

      if (!isPagination) {
        // Size is changed through the showMore button, which comes with its own loading state. So we omit this one.
        spinner.open(overrideExistingResults);
      }
      const res = await services.search.searchPlans(params);
      dispatch(fetchedPlansAction(res));

      if (!isPagination) {
        scrollToTop();
      }

      spinner.close();
      setFetching(false);
    },
    [dispatch, results, scrollToTop, services.search, spinner]
  );

  return (
    <SearchContextProvider
      service={services.search}
      fetchCallBack={fetch}
      indexType="plans"
      storeSearchParams={storeSearchParams}
      routeParams={searchParams}
      urlCreator={urlCreator}
    >
      <SearchPageMeta count={results && results.total} type="plans" channelInfo={channelInfo} />
      <PlanSearchPageView results={results} fetching={fetching} channelInfo={channelInfo} showTabs={showTabs} />
      <SearchHiddenUrls results={results} urlCreator={urlCreator} />
    </SearchContextProvider>
  );
};

export default PlanSearch;
