import { SVGGeneratorProps } from '@lessonup/teaching-core';
import React from 'react';
import { BaseMathComponent } from './BaseMathComponent';

export const ArrowComponent: React.FC<SVGGeneratorProps> = ({ position, settings }) => {
  return <BaseMathComponent position={position} settings={settings} pathFunc={arrowPath} />;
};

function arrowPath(width_: number, height_: number, margin_: number): string {
  // Use width and height to draw the path while taking the margin/stroke-size into account
  const margin = margin_ / 2; // Only half of the stroke size will fall outside of the path to devide by 2
  const width = width_ - margin;
  const height = height_ - margin;

  // lineHeight is a percentage of the height. It stands for the size of the rectangle in the arrow
  const lineHeight = 40;
  // arrowsize is the width for the arrowpoint so that it's in a fixed aspect ratio with the height
  const arrowSize = width - Math.sqrt(Math.pow(height, 2) - Math.pow(height / 2, 2));
  // If arrowsize is bigger than half of the width then it gets locket at half of the width
  const arrowWidth = arrowSize > width_ / 2 ? arrowSize : width_ / 2;

  // x and y values are for the coordinates that we need in the path
  const xValues = [margin, arrowWidth, width];
  const yValues = [
    margin,
    ((100 - lineHeight) / 2 / 100) * (height - margin) + margin,
    height_ / 2,
    ((100 - (100 - lineHeight) / 2) / 100) * (height - margin) + margin,
    height,
  ];
  const path = `M${xValues[0]} ${yValues[1]}
     L${xValues[1]} ${yValues[1]}
     L${xValues[1]} ${yValues[0]}
     L${xValues[2]} ${yValues[2]}
     L${xValues[1]} ${yValues[4]}
     L${xValues[1]} ${yValues[3]}
     L${xValues[0]} ${yValues[3]}
     Z`;
  return path;
}
