import React from 'react';
import { createPortal } from 'react-dom';
import { IconName } from '../../definitions/Icons';
import { useContextGenerator } from '../../utils/react';
import { ToastController } from './ToastController';
import './ToastProvider.less';

export interface ToastButtonParams {
  label: string;
  action: () => void;
  iconName?: IconName;
}

export interface ToastParams {
  msg: string;
  delayMs?: number;
  actionButton?: ToastButtonParams;
  undo?: () => void;
}

export interface Toast extends ToastParams {
  id: number;
  hide: (id: number) => void;
}

interface State {
  toasts: Toast[];
}

interface Context {
  withContext: boolean;
  add: (toast: ToastParams) => void;
  remove: (toastId: number) => void;
}

const ToastContext = React.createContext<Context | undefined>(undefined);
const { Consumer, Provider } = ToastContext;

const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export interface ToastManager {
  add: (toast: ToastParams) => void;
}

export interface ToastComponentProps {
  toastManager: ToastManager;
}

interface Props {
  manager?: (manager: ToastManager) => void;
  children: React.ReactNode;
}

export class ToastProvider<T> extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.state = {
      toasts: [],
    };
  }

  public componentDidMount() {
    if (this.props.manager) {
      this.props.manager({
        add: (toast: ToastParams) => this.add(toast),
      });
    }
  }

  public add(toast: ToastParams) {
    const newToast: Toast = {
      ...toast,
      id: Date.now(),
      hide: (id: number) => this.remove(id),
    };
    this.setState({
      toasts: this.state.toasts.concat(newToast),
    });
  }

  public remove(toastId: number) {
    this.setState({
      toasts: this.state.toasts.filter((t) => t.id != toastId),
    });
  }

  public render() {
    const { add, remove } = this;
    const { toasts } = this.state;
    const { children } = this.props;
    const withContext = true;
    return (
      <Provider value={{ add, remove, withContext }}>
        {children}
        {canUseDOM ? (
          createPortal(
            <div className="toast-provider">
              {toasts.map((toast) => (
                <ToastController key={toast.id} toast={toast} />
              ))}
            </div>,
            document.body
          )
        ) : (
          <div className="toast-provider" /> // keep ReactDOM.hydrate happy
        )}
      </Provider>
    );
  }
}

export const useToasts = useContextGenerator(ToastContext, 'ToastContext');
