import React, { useState } from 'react';

import { AssignmentPin } from '@lessonup/teaching-core';
import ImageComponent, { ImageComponentProps, ImageComponentPropsForPin } from '../../images/ImageComponent';
import PinContainer from '../../pin/PinContainer';
import { MapPinProps } from '../../pin/PinView';
import ViewComponents from '../../viewComponents/ViewComponents';
import Text from './Text';

interface Props extends MapPinProps {
  pin: AssignmentPin.Slide;
  isThumb?: boolean;
}

const SlideView: React.FC<Props> = ({ pin, isThumb }) => {
  const [slideColor, setSlideColor] = useState<number | undefined>(pin.item.custom.color);
  const [colorOverride, setColorOverride] = useState<boolean>(false);

  const imageProps = (type: string): ImageComponentProps => {
    return ImageComponentPropsForPin(pin, type, isThumb);
  };

  const editSlideColor = (color: number): void => {
    setSlideColor(color);
    setColorOverride(true);
  };

  const { item } = pin;

  return (
    <PinContainer pin={pin} pinColorOverride={colorOverride ? slideColor : undefined}>
      <ImageComponent {...imageProps('image')} />
      <ImageComponent {...imageProps('image2')} number2={true} />
      <Text type={'text1'} item={item} />
      <Text type={'text2'} item={item} />
      <ViewComponents
        components={item.components}
        isThumb={isThumb}
        editSlideColor={editSlideColor}
        expandOnlyOne={item.settings?.closeOtherHotspots}
      />
    </PinContainer>
  );
};

export default SlideView;
