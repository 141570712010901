import { LessonPin } from '@lessonup/teaching-core';
import { Button } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContentImageData } from '../../../../../shared-core/domain/search/content/ContentImage';
import { IframeMessaging } from '../../../../../shared-core/utils/IframeMessaging';
import { PIN_SEARCH_TRANSLATION_NS } from '../PinSearchPage';

interface ImageResultProps {
  pin: LessonPin;
  images: ContentImageData | undefined;
}

const handleImageResolve = (url: string | undefined) => {
  if (!url) return;
  IframeMessaging.send('image-url', url);
};

export const ImageResult: React.FunctionComponent<ImageResultProps> = ({ pin, images }) => {
  const { t } = useTranslation(PIN_SEARCH_TRANSLATION_NS);
  if (!images || !images.main) return null;
  return (
    <div className="image-result" key={pin._id} onClick={() => handleImageResolve(images.main && images.main.url)}>
      <div className={`pin-size-${400}`}>
        <div className="image" style={{ backgroundImage: `url(${images.main.url}` }}></div>
      </div>
      <div className="actions">
        <Button buttonType="primary">{t('useInLesson')}</Button>
      </div>
    </div>
  );
};
