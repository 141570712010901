import { UserContentPrivacy } from '@lessonup/teaching-core';
import { flatten, map } from 'lodash';
import { LanguageKey } from '..';
import { DbFolder } from '../../server/services/mongo/domainDatabase/ExplorerMongoDatabase';
import { ProductId } from '../products';
import { Explorer } from './Explorer';
import { LessonAndPlanCountTuple } from './Item';
import { findFolderInExplorer } from './utils/findFolderInExplorer';
export type FolderId = string;

export interface BaseFolder {
  _id: string;
  name: string;
  children: Folder[];
  privacy?: UserContentPrivacy;
  products?: ProductId[];
}

export interface Folder extends BaseFolder {
  order: string;
  description?: string;
  showForLanguage?: 'all' | LanguageKey;
  image?: string;
  banner?: {
    url: string;
    width: number;
    image: string;
  };
}

export type AnyFolder = Folder | Explorer;

export function isExplorer(anyFolder: AnyFolder): anyFolder is Explorer {
  return 'type' in anyFolder;
}

export function isFolder(anyFolder: AnyFolder): anyFolder is Folder {
  return 'order' in anyFolder;
}

export interface FolderCounts {
  lessonCount: number;
  planCount: number;
}

export interface NavigationData {
  parents: FolderMetaWithFolderIndex[];
  children: FolderMeta[];
}

interface FixedDetails {
  privacy: UserContentPrivacy;
  products: ProductId[];
}

interface PublicationFolderDetails {
  description: string;
  image: string;
}

export type ChannelInfo = { channel?: string | undefined };
export type FolderMeta = Pick<Folder, '_id' | 'name' | 'order' | 'products'> & ChannelInfo;
export type WithFolderIndex = { index: number };
export type FolderMetaWithFolderIndex = FolderMeta & WithFolderIndex & FolderRights;
export type FolderRights = Pick<Folder, '_id' | 'privacy' | 'products'>;
export type FolderDetails = FolderMeta &
  FolderRights &
  NavigationData &
  FixedDetails &
  ChannelInfo &
  Partial<PublicationFolderDetails>;
export type FolderWithNavigationData = Folder & NavigationData & WithFolderIndex & ChannelInfo;

export type FolderRenderParams = Pick<Folder, 'name' | 'description' | 'image'> & {
  id: string;
  counts: LessonAndPlanCountTuple;
  url?: string | undefined;
  products?: string[];
};

export const convertToQueryPath = (path: FolderMetaWithFolderIndex[], field: keyof DbFolder): string => {
  const isRoot = path.length === 1;

  if (isRoot) {
    return field;
  }

  const pathWithoutRoot = path.slice(1);

  return pathWithoutRoot.reduce((queryPath, folder, pathIndex) => {
    const isLastFolder = pathIndex === pathWithoutRoot.length - 1;
    return isLastFolder ? `${queryPath}.${folder.index}.${field}` : `${queryPath}.${folder.index}.children`;
  }, 'children');
};

export function folderRenderParams(
  folder: Folder & FolderMeta,
  urlBuilder?: (folderId: string) => string
): FolderRenderParams {
  return {
    name: folder.name,
    image: folder.image,
    description: folder.description,
    products: folder.products,
    counts: [0, 0],
    id: folder._id,
    url: urlBuilder && urlBuilder(folder._id),
  };
}

export function getChildrenIdsForFolder(node: BaseFolder): FolderId[] {
  return getChildrenPropertyForFolder(node, '_id');
}

export function getChildrenPropertyForFolder<K extends keyof Folder>(node: BaseFolder, property: K): Folder[K][] {
  return map(getChildFoldersForFolder(node), property);
}

export function getChildFoldersForFolder(node: BaseFolder, filter?: (folder: Folder) => boolean): Folder[] {
  const allNestedChildrenForFolder = (nodes: Folder[]): Folder[] => {
    if (nodes.length === 0) {
      return [];
    }
    const filteredNodes = filter ? nodes.filter(filter) : nodes;

    const children = filteredNodes.map((node) => allNestedChildrenForFolder(node.children));
    return [...filteredNodes, ...flatten(children)];
  };

  return allNestedChildrenForFolder(node.children);
}

export function getMetaForFolder(folder: FolderWithNavigationData): FolderMeta {
  return {
    _id: folder._id,
    name: folder.name,
    order: folder.order,
    products: folder.products,
    channel: folder.channel,
  };
}

export function getFolderNameOrRootName(explorer: Explorer, folderId: string, rootName: string): string {
  if (explorer._id === folderId) return rootName;
  const folder = findFolderInExplorer(explorer, folderId);
  return folder.name;
}

export function getFolderIdsInPathIncludingFolder(details: FolderDetails): string[] {
  return details.parents.map((parent) => parent._id).concat(details._id);
}

export function isPrivateFolder(folder: Folder): boolean {
  return folder.privacy === 'private';
}
