import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from '../../utils/ModalManagerService';
import ActionButton from '../Button';
import DefaultForm from '../inputElements/DefaultForm';
import { Modal } from './Modal';
import ModalButtonBar from './ModalButtonBar';

export type PromptStringModalParams = {
  current?: string;
  nameType?: string;
  title?: string;
};
const TRANSLATION_NS = 'promptStringModal';

export type PromptStringModalResponse = undefined | string;

type Props = ModalProps<PromptStringModalResponse> & PromptStringModalParams;

export default function PromptStringModal(props: Props) {
  const { t } = useTranslation(TRANSLATION_NS);
  const [string, updateString] = useState<string>(props.current || '');

  function handleClose() {
    props.closeModal(undefined);
  }

  function handleSubmit() {
    if (string) props.closeModal(string);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    updateString(event.currentTarget.value);
  }

  const title = props.nameType ? t('title', { ns: props.nameType }) : props.title || '';
  const placeholder = props.nameType ? t('placeholder', { ns: props.nameType }) : '';

  return (
    <Modal title={title} isOpen onRequestClose={handleClose} className="prompt-strings-modal">
      <div>
        <DefaultForm handleSubmit={handleSubmit}>
          <input
            type="text"
            autoFocus={true}
            placeholder={placeholder}
            onChange={(event) => handleChange(event)}
            value={string}
            style={{ marginBottom: '20px' }}
          />
          <ModalButtonBar>
            <ActionButton theme="primary" type="submit" disabled={!string.length}>
              {t('ok')}
            </ActionButton>
          </ModalButtonBar>
        </DefaultForm>
      </div>
    </Modal>
  );
}
