import React from 'react';
import { useTranslation } from 'react-i18next';
import { DomainRuleSuggestion } from '../../../../../../shared-core/domain/authorization/DomainRules';
import { Button } from '../../../../../../shared-core/ui/components/Button/Button';
import { CardIcon } from '../../../../../../shared-core/ui/components/Cards/CardIcon/CardIcon';
import { useBEM } from '../../../../../../shared-core/ui/utils/hooks/useBEM';
import ensureHttps from '../../../../../../shared-core/utils/ensureHttps';
import { REGISTRATION_SUGGESTIONS_TRANSLATION_NAMESPACE, ResponseStatus } from '../RegistrationSuggestionsPage';
import { RequestResultForSuggestion } from '../RequestResultForSuggestion/RequestResultForSuggestion';

type CardButtonTheme = 'secondary' | 'quaternary';

interface Props {
  suggestion: DomainRuleSuggestion;
  requestResult?: ResponseStatus;
  handleRequestToJoin: (organizationId: string) => void;
  cardButtonTheme: CardButtonTheme;
}

export const CardContentForSuggestion: React.FC<Props> = ({
  suggestion,
  requestResult,
  handleRequestToJoin,
  cardButtonTheme,
}) => {
  const bemClasses = useBEM('RegistrationSuggestionsPage');
  const { t } = useTranslation(REGISTRATION_SUGGESTIONS_TRANSLATION_NAMESPACE);

  return (
    <div className={bemClasses({ element: 'card-content-span' })}>
      <CardIcon iconUrl={suggestion.channelThumbnail && ensureHttps(suggestion.channelThumbnail)} />
      <div className={bemClasses({ element: 'card-content-wrapper' })}>
        <div className={bemClasses({ element: 'card-content' })}>
          <h2>{suggestion.organizationName}</h2>
          <p>{t('member', { count: suggestion.memberCount })}</p>
        </div>
        <div className={bemClasses({ element: 'card-button' })}>
          {requestResult ? (
            <RequestResultForSuggestion requestResult={requestResult} />
          ) : (
            <Button
              theme={cardButtonTheme}
              version="modern"
              height="medium"
              onClick={() => handleRequestToJoin(suggestion.organizationId)}
            >
              {t('requestToJoin')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
