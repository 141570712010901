import classNames from 'classnames';
import React from 'react';
import './ButtonLoader.less';

export default function Loader({ className }: { className?: string }) {
  // Each empty div is styled to be a dot for the loading state.
  return (
    <div className={classNames(className, 'button-loader')}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
