import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnchorButton } from '../../../../shared-core/ui/components/Button';
import TextButton from '../../../../shared-core/ui/components/buttons/TextButton';
import { Modal } from '../../../../shared-core/ui/components/modals/Modal';
import ModalButtonBar from '../../../../shared-core/ui/components/modals/ModalButtonBar';

export interface LinkModalConfig {
  description: string;
  link: string;
  title: string;
  linkText: string;
}

type Props = LinkModalConfig & { closeModal: (res: void) => void };

const LinkModal: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation('common');

  function handleClose() {
    props.closeModal();
  }

  return (
    <Modal title={props.title} isOpen onRequestClose={handleClose} width="medium">
      <p className="link-modal-description">{props.description}</p>
      <ModalButtonBar>
        <AnchorButton theme="primary" href={props.link}>
          {props.linkText}
        </AnchorButton>
        <TextButton className="spacing-left" onClick={handleClose}>
          {t('modalDismiss')}
        </TextButton>
      </ModalButtonBar>
    </Modal>
  );
};

export default LinkModal;
