import { UserRatings } from '@lessonup/firebase-database';
import classNames from 'classnames';
import React from 'react';
import { IndexedLesson, UserContext } from '../../../../shared-core/domain';
import { FacetKey, FacetSearchResponse, IndexedChannelInfo } from '../../../../shared-core/domain/search';
import SidebarLayout from '../../../components/search/sidebarLayout/SidebarLayout';
import SearchResultView from '../searchResultsView/SearchResultsView';
import { useSearchContext } from '../sidebar/SearchContext';
import SearchSidebar from '../sidebar/SearchSidebarContainer';
import './lessonSearchView.less';

interface Props {
  results: FacetSearchResponse<IndexedLesson> | undefined;
  userRatings: UserRatings;
  fetching: boolean;
  lessonUrlCreator?: (lessonId: string) => string;
  channelInfo?: IndexedChannelInfo;
  showTabs?: boolean;
  user?: UserContext;
}

const LessonSearchView: React.FunctionComponent<Props> = ({
  userRatings,
  results,
  fetching,
  lessonUrlCreator,
  channelInfo,
  showTabs,
}) => {
  const { resetFacet, setSearchText } = useSearchContext();

  function handleFacetDeselect(key: FacetKey | 'text') {
    return key === 'text' ? setSearchText('') : resetFacet(key);
  }

  const items = results ? results.items : [];
  const facets = results ? results.facets : [];
  const request = results ? results.request : undefined;

  const Content = (
    <SearchResultView
      userRatings={userRatings}
      totalCount={results ? results.total : 0}
      items={items}
      searchParams={{ index: 'lessons', facets: request ? request.facets : [], text: request && request.text }}
      handleFacetDeselect={handleFacetDeselect}
      fetching={fetching}
      lessonUrlCreator={lessonUrlCreator}
      channelInfo={channelInfo}
    />
  );

  const FacetList = (
    <SearchSidebar facetResults={facets} searchTextValue={request && request.text} searchType="lesson"></SearchSidebar>
  );

  return (
    <div className="searchpage">
      <SidebarLayout sidebar={FacetList} showTabs={showTabs}>
        <main
          className={classNames('searchpage__searchresults', {
            'hide-tabs': !showTabs,
          })}
        >
          {Content}
        </main>
      </SidebarLayout>
    </div>
  );
};

export default LessonSearchView;
