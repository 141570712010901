import { AppError } from '@lessonup/utils';
import { errorToDefaultNotificationTranslationKey, SharedModalsProvider } from '../../shared-core/client/utils/modals';

import { searchAppModalManagerService } from './SearchAppModalService';

const showDefaultErrorNotification = (error?: Error | AppError): void => {
  const translationKey = errorToDefaultNotificationTranslationKey(error);

  searchAppModalManagerService.open('notification', { translationKey });
};

export const sharedModalsProvider: SharedModalsProvider = { showDefaultErrorNotification };
