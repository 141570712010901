import { Fonts } from '@lessonup/teaching-core';
import _ from 'lodash';
import { loadConditionalFont } from './WebFont';

function isBrowser() {
  return typeof window !== 'undefined';
}

export namespace FontsSingleton {
  const fontNames = new Set<Fonts.Key>();

  export function add(fontKeys: Fonts.Key[]) {
    const fontNamesToLoad = newFontKeys(fontKeys);
    fontNamesToLoad.forEach((font) => fontNames.add(font));

    if (isBrowser()) {
      _.compact(fontNamesToLoad.map(Fonts.conditionalFontByKey)).forEach((font) => loadConditionalFont(font));
    }
  }

  function newFontKeys(fonts: Fonts.Key[]): Fonts.Key[] {
    return fonts.filter((font) => !fontNames.has(font) && !Fonts.primaryFontKeys.includes(font));
  }

  // For SSR purposes
  export function fontsHead(assetUrlPrefix: string) {
    const conditionalFontDict = _.keyBy(Fonts.conditionalFonts, 'name');
    const fonts: Fonts.ConditionalFont[] = [];

    fontNames.forEach((fontName) => conditionalFontDict[fontName] && fonts.push(conditionalFontDict[fontName]));

    const [googleFonts, customFonts] = _.partition(fonts, (font) => font && 'google' in font);

    const googleHead =
      !!googleFonts.length &&
      Fonts.generateGoogleFontsHead(_.flatten(googleFonts.map((font: Fonts.GoogleFont) => font.families)));

    const customFontHead =
      !!customFonts.length &&
      Fonts.generateCustomFontHead(
        assetUrlPrefix,
        customFonts.map((font: Fonts.CustomFont) => font.url)
      );

    return `${googleHead || ''}${customFontHead || ''}`;
  }

  export function clear() {
    return fontNames.clear();
  }

  export function getFonts() {
    return fontNames;
  }
}
