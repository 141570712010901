import { LoggerTransport } from '@lessonup/client-integration';
import { AppError, LogDetails } from '@lessonup/utils';

type TrackFunction = (error: any) => void;

/**
 * Transport to track errors going through the logger
 */
export class TrackErrorTransport implements LoggerTransport {
  public constructor(private readonly track: TrackFunction) {}

  public debug(message: string | Error | AppError, details?: LogDetails): void {}

  public info(message: string | Error | AppError, details?: LogDetails): void {}

  public warn(message: string | Error | AppError, details?: LogDetails): void {}

  public error(message: string | Error | AppError, details?: LogDetails): void {
    this.track(message);
  }
}
