import classNames from 'classnames';
import React from 'react';

import { Poll } from '@lessonup/teaching-core';
import './RealtimePollEmojis.less';

interface Props {
  pollItem: Poll.Emoji;
  pollAnswer?: number;
  handleSubmitAnswer?: (answer: number) => Promise<void>;
}

const RealtimePollEmojis: React.FC<Props> = (props) => {
  const { handleSubmitAnswer, pollAnswer, pollItem } = props;

  return (
    <div className="emoji-wrapper">
      {pollItem.options.map((emoji, i) => (
        <PollEmojiInput
          key={i}
          emoji={emoji}
          answer={i}
          handleSubmitAnswer={handleSubmitAnswer}
          isActive={!handleSubmitAnswer && !pollAnswer ? true : i === pollAnswer}
        />
      ))}
    </div>
  );
};

export default RealtimePollEmojis;

type PollEmojiProps = {
  isActive: boolean;
  emoji: string;
  handleSubmitAnswer?: (answer: number) => Promise<void>;
  answer: number;
};

const PollEmojiInput: React.FC<PollEmojiProps> = ({ isActive, emoji, handleSubmitAnswer, answer }) => {
  const handleClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    handleSubmitAnswer && handleSubmitAnswer(answer);
  };

  return (
    <span onClick={handleClick} className={classNames('emoji', isActive && 'active')}>
      {emoji}
    </span>
  );
};
