import { PinType } from '@lessonup/teaching-core';

export namespace ContentTypeDefaults {
  export const defaultFontSizes = {
    slide: {
      1: 100,
      2: 60,
    },
    question: {
      1: 80,
      2: 60,
    },
    openQuestion: {
      1: 80,
    },
    wordweb: {
      1: 80,
    },
    dragQuestion: {
      1: 80,
    },
    poll: {
      1: 80,
    },
  };

  export const defaultColors = {
    slide: 1,
    question: 1,
    wordweb: 1,
    openQuestion: 1,
    dragQuestion: 1,
  };

  const defaultImageSizesPerSlideLayout = {
    1: 'cover',
    2: 'cover',
    3: 'contain',
    4: 'contain',
    5: 'contain',
    6: 'contain',
    7: 'contain',
    8: 'contain',
  };

  const defaultImageSizesPerQuestionLayout = {
    1: 'cover',
    2: 'contain',
    3: 'contain',
    4: 'contain',
  };

  export function getImageSizeForLayout(layoutNr, type) {
    if (type == 'question') {
      return defaultImageSizesPerQuestionLayout[layoutNr];
    }
    return defaultImageSizesPerSlideLayout[layoutNr];
  }

  export function getDefaultFontSize(textNr = 1, type: PinType): number | undefined {
    if (!type) return undefined;
    const source = defaultFontSizes[type];
    if (source) return source[textNr];
    return textNr === 1 ? 80 : 60;
  }

  export function getDefaultColor(type) {
    return defaultColors[type];
  }
}
