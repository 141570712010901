import { ReferralRouteParams } from '@lessonup/teacher-modern';
import { AtLeast } from '@lessonup/utils';
import { RegistrationStep } from '../../../shared-core/services/app/searchRoutes';

export interface RegistrationPageProps {
  step?: RegistrationStep;
  organizationId?: string;
  referralToken?: string;
  nextPage?: (params: AtLeast<ReferralRouteParams, 'step'> & Pick<ReferralRouteParams, 'organizationId'>) => string;
  emailVerificationToken?: string;
}

export interface RegistrationFormProps {
  next: () => void;
  goToStep: (step: RegistrationStep, organizationId?: string) => void;
}

export const PRE_REGISTRATION_STEPS: RegistrationStep[] = [
  'select',
  'credentials',
  'password',
  'referral',
  'verification-expired',
  'verification-sent',
  'verify-email',
];
