import { format } from '@lessonup/utils';
import React from 'react';
import { Cell, CellProps } from '../Cell/Cell';

interface DateCellProps extends CellProps {
  date: Date;
}

export const DateCell: React.FC<DateCellProps> = ({ date, ...props }) => {
  const localizedDateAndTimeSetting = 'Pp';
  return (
    <Cell {...props} ref={null}>
      {format(date, localizedDateAndTimeSetting)}
    </Cell>
  );
};
