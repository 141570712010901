import React from 'react';
import { useBEM } from '../../../../utils/hooks';
import './Row.less';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> {
  isHighlighted?: boolean;
}

export const Row = React.forwardRef<HTMLTableRowElement, Props>(
  ({ children, className, isHighlighted = false, id, onContextMenu, ...props }, ref) => {
    const bemClasses = useBEM('Row');

    return (
      <tr
        id={id}
        onContextMenu={onContextMenu}
        className={bemClasses({
          modifier: isHighlighted && 'highlighted',
          className,
        })}
        {...props}
        ref={ref}
      >
        {children}
      </tr>
    );
  }
);
