import classNames from 'classnames';
import React from 'react';
import './UnstyledButton.less';

function UnstyledButton({
  className,
  ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) {
  return <button className={classNames('button-unstyled', className)} {...props} />;
}

export default UnstyledButton;
