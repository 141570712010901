import { UserRatings } from '@lessonup/firebase-database';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IndexedLesson } from '../../../../shared-core/domain';
import { IndexedChannelInfo, SearchParams, SearchResultItem } from '../../../../shared-core/domain/search';
import { MAX_PAGINATION_SIZE } from '../../../../shared-core/domain/search/constants/paginationSize';
import ShowMoreButton from '../../../../shared-core/ui/components/buttons/ShowMoreButton';
import LessonSummaryCards from '../../../components/lesson/lessonSummaryCard/LessonSummaryCards';
import { geoLocation, loggedInUser } from '../../../redux/selectors';
import { useSearchContext } from '../sidebar/SearchContext';
import { CountryNotification } from './CountryNotification/CountryNotification';
import SearchResultsEmptyState from './SearchResultsEmptyState/SearchResultsEmptyState';
import SearchResultsSubtitle from './SearchResultsSubtitle/SearchResultsSubtitle';
import SearchResultsTitle from './SearchResultsTitle/SearchResultsTitle';
import './SearchResultsView.less';

export interface SearchResultViewProps {
  totalCount: number;
  items: SearchResultItem<IndexedLesson>[];
  userRatings: UserRatings;
  handleFacetDeselect: (key: string) => void;
  searchParams: SearchParams;
  fetching: boolean;
  lessonUrlCreator?: (lessonId: string) => string;
  channelInfo?: IndexedChannelInfo;
}

const SearchResultView: React.FunctionComponent<SearchResultViewProps> = ({
  items,
  userRatings,
  searchParams,
  totalCount,
  handleFacetDeselect,
  fetching,
  lessonUrlCreator,
  channelInfo,
}) => {
  const { paginate, selectedFacets, setFacet } = useSearchContext();
  const [count, setCount] = useState(totalCount);
  const user = useSelector(loggedInUser());
  const showLoadMoreButton = totalCount > items.length && items.length < MAX_PAGINATION_SIZE;
  const location = useSelector(geoLocation());
  const country = user?.country || location?.countryCode || undefined;
  const isChannelResults = channelInfo;

  useEffect(() => {
    setCount(totalCount);
  }, [searchParams.text, totalCount]);

  return (
    <>
      <div className="searchpage-result-header">
        {!isChannelResults && country && totalCount > 1 && (
          <CountryNotification
            selectedFacets={selectedFacets}
            country={country}
            onClick={() => setFacet('country', country)}
          />
        )}
        <SearchResultsTitle selectedFacets={selectedFacets} count={count} type={'lesson'} />
        <SearchResultsSubtitle selectedFacets={selectedFacets} channelInfo={channelInfo} type={'lesson'} />
        {!fetching && !items.length ? (
          <SearchResultsEmptyState searchParams={searchParams} handleFacetDeselect={handleFacetDeselect} />
        ) : null}
      </div>
      <LessonSummaryCards items={items} userRatings={userRatings} lessonUrlCreator={lessonUrlCreator} />
      <ShowMoreButton show={showLoadMoreButton} onClick={paginate} fetching={fetching} />
    </>
  );
};

export default SearchResultView;
