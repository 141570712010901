import {
  enGBLocale,
  format,
  isBefore,
  isDate as dateFnsIsDate,
  isToday,
  isValid,
  nlLocale,
  parseISO,
  setMonth,
  startOfMonth,
  subYears,
} from '@lessonup/utils';
import { LanguageKey, Locales } from '../domain';
import { assertNever } from '../utils';

export function startOfSchoolYear(now: Date = new Date()): Date {
  const augustThisYear = startOfMonth(setMonth(now, 7));
  const augustLastYear = subYears(augustThisYear, 1);
  const isBeforeAugustThisYear = isBefore(now, augustThisYear);

  return isBeforeAugustThisYear ? augustLastYear : augustThisYear;
}

export function startOfLastSchoolYear(): Date {
  const start = startOfSchoolYear();
  return subYears(start, 1);
}

export function timestampToDate(date: Date | number | undefined): Date | undefined {
  return typeof date === 'number' ? new Date(date) : date;
}

export function dateOrTimeIfToday(date: Date): string {
  return isToday(date) ? format(date, 'HH:mm') : format(date, 'yyyy-MM-dd HH:mm');
}

/** data-fns does not support strings, but we have those in our database.
 * Cast strings to date without validating
 */
export function stringToDateUnsafe(date: Date | string): Date {
  return typeof date === 'string' ? new Date(date) : date;
}

export function isDate(date: any): date is Date {
  return dateFnsIsDate(date);
}

export function dateValueIsValidDateOrISoString(date: Date | string | null | undefined): date is Date | string {
  if (!date) return false;
  if (!isDate(date)) {
    return isValid(parseISO(date));
  }
  return isValid(date);
}

const minute = 60 * 1000;
export function ceilWholeMinute(time = Date.now()): Date {
  return new Date(Math.ceil(time / minute) * minute);
}

export function getDateForOneYearAgo(): Date {
  const date = new Date();
  date.setMonth(date.getMonth() - 12);
  return date;
}

export function getDateYearsAgoFromNow(years: number): Date {
  const currentDate = new Date();
  return new Date(currentDate.setFullYear(currentDate.getFullYear() - years));
}

export function getDateFnsLocale(locale: Locales.IsoKey): Locale {
  switch (locale) {
    case 'nl-NL':
      return nlLocale;
    case 'en-GB':
      return enGBLocale;
    default:
      assertNever(locale, 'DateUtils');
  }
}

export function getFormattedDateForLanguage(
  date: Date,
  language: LanguageKey,
  options: { format: string } = { format: 'P' }
): string {
  const locale = getDateFnsLocale(Locales.guesstimateLocaleFromLanguage(language));
  return format(new Date(date), options.format, { locale });
}
