import React from 'react';
import { useTranslation } from 'react-i18next';
import { LockedFeature } from '../../../../../domain/lockedFeatures/LockedFeatures';
import { teacherAccountUrl } from '../../../../../services/app/teacherRoutes';
import { ModalProps } from '../../../../utils/ModalManagerService';
import { AnchorButton } from '../../../Button';
import CheckListItem from '../../../CheckList/CheckListItem';
import { Modal } from '../../Modal';
import '../upsellModal.less';

const TRANSLATION_NS = 'proRequiredModal';

export type ProRequiredModalResponse = { success: boolean };

export interface ProRequiredModalProps {
  feature?: LockedFeature;
}

type Props = ModalProps<ProRequiredModalResponse> & ProRequiredModalProps;

const ProRequiredModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(TRANSLATION_NS);

  function handleClose() {
    props.closeModal({ success: false });
  }

  function handleButtonClick() {
    props.closeModal({ success: true });
  }

  return (
    <Modal
      title={t('title')}
      subtitle={t('subtitle')}
      isOpen
      onRequestClose={handleClose}
      className="upsell-modal"
      width="large"
      theme="red"
    >
      <img src="/svg/teacher-pro.svg" alt="Teacher Pro Icon" className="teacher-image" />
      <div className="upsell-content">
        <div className="description">
          {t('description', { feature: props.feature ? t(`lockedFeatures:${props.feature}`) : t('thisFeature') })}
        </div>
        <div className="subcontent">
          <ul className="upsell-checklist">
            <CheckListItem size="small" text={t('createGroups')} />
            <CheckListItem size="small" text={t('differentiate')} />
            <CheckListItem size="small" text={t('test')} />
          </ul>
          <AnchorButton theme="secondary" href={teacherAccountUrl()} onClick={handleButtonClick}>
            {t('cta')}
          </AnchorButton>
        </div>
      </div>
    </Modal>
  );
};

export default ProRequiredModal;
