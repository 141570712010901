import React, { SVGProps } from 'react';

function Checkmark(props: SVGProps<any>) {
  return (
    <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill={props.fill || '#fff'}
        d="M8.555 1L10 2.483 3.545 9 0 5.41l1.445-1.483 2.1 2.147z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Checkmark;
