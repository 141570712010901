export function wait(ms: number, msg = ''): Promise<string> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(msg), ms);
  });
}

export function secondsToPrettyTime(s: number | undefined): string {
  function n2(n: number) {
    return n < 10 ? '0' + n : n;
  }

  if (!s) return '0:00';

  const totalSeconds = Math.round(s * 1);

  const hours = Math.trunc(totalSeconds / 3600);
  const minutes = Math.trunc((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  if (hours) {
    return hours + ':' + n2(minutes) + ':' + n2(seconds);
  }
  if (minutes) {
    return minutes + ':' + n2(seconds);
  }
  return '0:' + n2(seconds);
}
