import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckListItem from '../../../../shared-core/ui/components/CheckList/CheckListItem';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import { meteorRouteWithLanguageQueryParam } from '../../../utils/routeUtils/routeUtils';
import { StudentTile, TeacherTile } from '../../GrayPageLayout/appSelectionTiles';
import { GrayPageLayoutTitle, GrayPageSection, GrayPageSubtitle } from '../../GrayPageLayout/GrayPageLayout';
import { RegistrationFormProps } from '../types';
import { useRedirectForLoggedInUsers } from '../utils/useRedirectForLoggedInUsers';
import './RegistrationSelectApplication.less';

const RegistrationSelectApplication: React.FC<RegistrationFormProps> = (props) => {
  const { t } = useTranslation('register');
  const { config } = useAppServices();

  useRedirectForLoggedInUsers();

  return (
    <>
      <GrayPageSection position="center" className="grow full-width select-application-wrapper">
        <div className="registration-title">
          <GrayPageLayoutTitle>{t('start-title')}</GrayPageLayoutTitle>
          <GrayPageSubtitle>{t('registerNowSubtitle')}</GrayPageSubtitle>
        </div>
        <div className="registration-container registration-select-application stack">
          <TeacherTile next={props.next} imageClass="registration-image">
            <div className="check-list-container d-none d-xs-flex">
              <ul className="check-list">
                <CheckListItem text={t('createLessons')} />
                <CheckListItem text={t('searchLessons')} />
                <CheckListItem text={t('teachLessons')} />
                <CheckListItem text={t('handoutLessons')} />
                <CheckListItem text={t('shareLessons')} />
              </ul>
            </div>
          </TeacherTile>
          <StudentTile
            imageClass="registration-image"
            url={meteorRouteWithLanguageQueryParam(config.studentRegistrationUrl())}
          >
            <div className="check-list-container d-none d-xs-flex">
              <ul className="check-list">
                <CheckListItem text={t('joinLessons')} />
                <CheckListItem text={t('enterClassCodes')} />
                <CheckListItem text={t('completeHomework')} />
              </ul>
            </div>
          </StudentTile>
        </div>
      </GrayPageSection>
      <GrayPageSection position="bottom"></GrayPageSection>
    </>
  );
};

export default RegistrationSelectApplication;
