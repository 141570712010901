import { LessonPin } from '@lessonup/teaching-core';
import _ from 'lodash';
import { Sanitizer } from '../../../utils/Sanitizer';
import { IndexedContent } from './IndexedContent';

export namespace IndexedPinContent {
  export function fromPins(pinOrPins: LessonPin[] | LessonPin): IndexedContent {
    const pins = _.isArray(pinOrPins) ? pinOrPins : [pinOrPins];

    const importantProps = [
      'item.custom.text1',
      'item.custom.text2',
      'item.custom.question',
      'item.components.settings.text',
      'item.components.settings.title',
    ];

    const normalProps = [
      'item.name',
      'item.custom.answer',
      'item.custom.answer1',
      'item.custom.answer2',
      'item.custom.answer3',
      'item.custom.answer4',
      'item.notes.text',
    ];

    const componentProps = {
      important: ['settings.text', 'settings.title'],
      normal: ['katex'],
    };

    const importantItems: string[] = [];
    const normalItems: string[] = [];

    function addKeysToArray(arr: string[], props: string[], obj: Object) {
      props.forEach((prop) => {
        const value = _.get(obj, prop);
        if (_.isString(value) && !_.isEmpty(value)) {
          arr.push(Sanitizer.sanitize(value));
        }
      });
    }

    // Loop through the pins and add all valid values to the arrays
    pins.forEach((pin) => {
      addKeysToArray(importantItems, importantProps, pin);
      addKeysToArray(normalItems, normalProps, pin);

      if (!pin.item.components) return;
      pin.item.components.forEach((c) => {
        addKeysToArray(importantItems, componentProps.important, c);
        addKeysToArray(normalItems, componentProps.normal, c);
      });
    });

    return {
      important: importantItems,
      normal: normalItems,
    };
  }
}
