import { Lesson } from '@lessonup/teaching-core';
import { incentiveCounter } from '../../services/IncentiveCounter/IncentiveCounterService';

export function userIsInIncentiveCounterTest(
  isEmbeddedVersion: boolean,
  lesson: Lesson,
  hasUser: boolean
): 'medium' | 'hard' | 'default' {
  const lessonIsExcludedFromTest = isEmbeddedVersion || lesson.channel || hasUser;
  if (lessonIsExcludedFromTest) return 'default';

  return incentiveCounter.incentiveCounterExperimentVariant();
}

export function incrementTeachCountIfApplicable(isEmbeddedVersion: boolean, lesson: Lesson, hasUser: boolean) {
  const incentiveCounterVersion = userIsInIncentiveCounterTest(isEmbeddedVersion, lesson, hasUser);

  if (incentiveCounterVersion === 'default') return;

  incentiveCounter.increment();
}

export function userHasReachedIncentiveCount(isEmbeddedVersion: boolean, lesson: Lesson, hasUser: boolean) {
  const testVariant = userIsInIncentiveCounterTest(isEmbeddedVersion, lesson, hasUser);
  if (testVariant === 'default') return false;

  return incentiveCounter.hasReachedLimit(testVariant);
}

export function incentiveCounterTeachesLeft(
  isEmbeddedVersion: boolean,
  lesson: Lesson,
  hasUser: boolean
): number | undefined {
  const testVariant = userIsInIncentiveCounterTest(isEmbeddedVersion, lesson, hasUser);
  if (testVariant === 'default') return;

  return incentiveCounter.teachTimesLeft(testVariant);
}
