import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FlagSvg from '../../../../shared-core/ui/components/svgIcons/FlagSvg';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import { loggedInUser } from '../../../redux/selectors';
import './FlagLessonButton.less';
interface Props {
  lessonId: string;
}

const TRANSLATION_NAMESPACE = 'flagModal';

const FlagLessonButton: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const { modals } = useAppServices();
  const user = useSelector(loggedInUser());
  if (!user) return null;

  return (
    <div className="flag-lesson-button" onClick={() => modals.open('flagLesson', { lessonId: props.lessonId })}>
      <FlagSvg className="icon flag-icon" />
      <span className="flag-lesson-button__inner">{t('title')}</span>
    </div>
  );
};

export default FlagLessonButton;
