import React from 'react';
import { useTranslation } from 'react-i18next';
import MultiLocaleLink from '../../../../ui/components/links/MultiLocaleLink';

const InvalidVideoMsg: React.FC = () => {
  const { t } = useTranslation('invalidVideo');
  return (
    <div className="invalid-video-msg">
      <div className="msg-inner">
        <div>{t('msg')}</div>
        <span className="explain">
          <MultiLocaleLink
            label={t('explain')}
            target="_blank"
            urls={{
              nl: 'https://help.lessonup.com/nl/articles/9031458-deze-youtube-video-is-niet-meer-beschikbaar',
              en: 'https://help.lessonup.com/en/articles/9031458-this-youtube-video-is-not-available-anymore',
            }}
          />
        </span>
      </div>
    </div>
  );
};

export default InvalidVideoMsg;

export const InvalidVideo: React.FC = () => {
  return (
    <div className="invalid-video">
      <InvalidVideoMsg />
    </div>
  );
};
