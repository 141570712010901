import { trackIntercomEvent } from '@lessonup/intercom';
import { AppError } from '@lessonup/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MongoUser } from '../../../../shared-core/domain';
import { forgotPasswordRoute } from '../../../../shared-core/services/app/searchRoutes';
import Button from '../../../../shared-core/ui/components/Button';
import '../../../../shared-core/ui/components/inputElements/FormStyles.less';
import { useFormInput } from '../../../../shared-core/ui/components/inputElements/useFormInput';
import './UserNamePasswordForm.less';

interface Props {
  login: (username: string, password: string, source: 'standalone' | 'modal') => Promise<boolean>;
  onLogin?: (res: boolean) => Promise<boolean>;
  handleForgotPassword?: () => void;
  source: 'standalone' | 'modal';
}

export default function UserNamePasswordForm(props: Props) {
  const { t } = useTranslation('login');

  const [email, emailInput, validateEmail] = useFormInput({
    autoComplete: 'username',
    type: 'text',
    placeholder: t('email'),
    requiredMsg: t('email-error'),
    id: 'user-name-password-form__name-input',
    validator: (email) => Boolean(email && MongoUser.validateEmail(email)),
  });
  const [password, passwordInput, validatePassword] = useFormInput({
    autoComplete: 'current-password',
    type: 'password',
    placeholder: t('password'),
    requiredMsg: t('password-error'),
    id: 'user-name-password-form__password-input',
    validator: (password) => Boolean(password && MongoUser.validateLoginPassword(password)),
  });

  const [error, setError] = useState<string>();
  const [, setErrorCount] = useState<number>(0);

  function handleError(errorTranslationString: string): void {
    setError(errorTranslationString);

    setErrorCount((currentCount: number) => {
      const newCount = currentCount + 1;
      trackIntercomEvent('login-password-error', { count: newCount });

      return newCount;
    });
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const validateAllInputs = [validateEmail(), validatePassword()].every((val) => val);
    if (!validateAllInputs || !email || !password) return;
    try {
      const res = await props.login(email, password, props.source);
      if (!res) {
        handleError('loginIncorrect');
        return;
      }
      if (props.onLogin) {
        props.onLogin(res);
      }
    } catch (error) {
      if (AppError.isError(error, 'not-authenticated')) {
        handleError('loginIncorrect');
      } else {
        handleError('genericLoginFailedError');
      }
    }
  }

  return (
    <form className="default-form login-username-password" onSubmit={handleSubmit}>
      {emailInput}
      {passwordInput}
      {error && <div className="error">{t(error)}</div>}
      <Link to={forgotPasswordRoute()} className="password-forgotten-link" onClick={props.handleForgotPassword}>
        {t('forgot-password')}
      </Link>
      <Button theme="secondary" type="submit" version="modern">
        {t('login')}
      </Button>
    </form>
  );
}
