import classnames from 'classnames';
import React from 'react';
import './ExplorerLessonIcon.less';
interface Props {
  className: string;
  isTest?: boolean;
}

export const ExplorerLessonIcon: React.FC<Props> = ({ className = '', isTest }) => {
  const classNames = classnames('explorer-lesson-icon', { [className]: className });
  return <div className={classNames}></div>;
};
