import React from 'react';
import { ExplorerUserContent, Item } from '../../../../domain/newExplorers';
import { useBEM } from '../../../utils/hooks';
import { ExplorerBreadcrumbs } from '../Breadcrumbs';
import { BaseExplorerProps } from '../models';
import './CondensedExplorer.less';
import { CondensedExplorerTable } from './CondensedExplorerTable/CondensedExplorerTable';

interface Props extends BaseExplorerProps {
  className?: string;
  contentSelection?: {
    onContentSelect: (content: ExplorerUserContent[]) => void;
    isMultiselect?: boolean;
  };
  disabledRows?: Item[];
  hideRootExplorerButton?: boolean;
  disableModernLessons?: boolean;
}

export const CondensedExplorer: React.FC<Props> = ({
  rootExplorer,
  currentExplorer,
  location,
  onLocationChange,
  contentSelection,
  contents,
  className,
  disabledRows,
  enabledTypes,
  hideRootExplorerButton,
  disableModernLessons,
}) => {
  const bemClasses = useBEM('CondensedExplorer');

  const isInPublicationFolder = currentExplorer.isPublish;

  return (
    <div className={bemClasses({ className })}>
      <ExplorerBreadcrumbs
        currentExplorer={currentExplorer}
        currentLocation={location}
        rootExplorer={rootExplorer}
        onLocationChange={onLocationChange}
        className={bemClasses({ element: 'breadcrumbs', className: 'margin-bottom-4' })}
        maxBreadcrumbLength={3}
        hideRootExplorerButton={hideRootExplorerButton}
      />
      <CondensedExplorerTable
        contents={contents}
        onLocationChange={(newLocation) => onLocationChange({ ...location, ...newLocation })}
        contentSelection={contentSelection}
        disabledRows={disabledRows}
        enabledTypes={enabledTypes}
        isInPublicationFolder={isInPublicationFolder}
        disableModernLessons={disableModernLessons}
      />
    </div>
  );
};
