import { UserRatings } from '@lessonup/firebase-database';
import {
  shouldShowTeacherCreditsIndicator,
  TeachingCreditsIndicator,
  TeachingCreditsIndicatorFeature,
  useTeachingCredits,
} from '@lessonup/teacher-modern';
import { Lesson, LessonAttachment, LessonPin, pinInteractiveTypes } from '@lessonup/teaching-core';
import { Banner, Button as ModernButton, IconDownload, IconLesson, IconShare, Link } from '@lessonup/ui-components';
import _, { capitalize } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  ChannelTitle,
  IndexedChannelInfo,
  Language,
  LanguageSingleton,
  LessonDetailsAccessTypes,
  LessonTrigger,
  Licenses,
  UserContext,
} from '../../../../shared-core/domain';
import { FacetMetaData } from '../../../../shared-core/domain/search/content/FacetMetaData';
import { helpdeskArticle } from '../../../../shared-core/services/app/helpdeskRoutes';
import { lessonRoute } from '../../../../shared-core/services/app/searchRoutes';
import Button from '../../../../shared-core/ui/components/Button';
import TextButton from '../../../../shared-core/ui/components/buttons/TextButton';
import ContextMenu from '../../../../shared-core/ui/components/ContextMenu/ContextMenu';
import Heading from '../../../../shared-core/ui/components/Heading';
import FeedbackStars from '../../../../shared-core/ui/components/inputElements/FeedbackStars';
import { LessonPreview } from '../../../../shared-core/ui/components/lesson/lessonPreview/LessonPreview';
import MetaDataTagList from '../../../../shared-core/ui/components/MetaDataTagList';
import { SUBJECTS_LIMIT } from '../../../../shared-core/ui/components/MetaDataTagList/MetaDataTagList';
import PinView from '../../../../shared-core/ui/components/pin/PinView';
import PrintSvg from '../../../../shared-core/ui/components/svgIcons/PrintSvg';
import UnstyledButton from '../../../../shared-core/ui/components/UnstyledButton';
import { FlexBoxDummies } from '../../../../shared-core/ui/utils/FlexBoxDummies';
import useToggle from '../../../../shared-core/ui/utils/useToggle';
import listFormat from '../../../../shared-core/utils/listFormat';
import { PinViewUtils } from '../../../../shared-core/utils/PinViewUtils';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import { followChannelAction } from '../../../redux/actions/actions';
import { ClientOnlyModernWrapper } from '../../../utils/bridge/ClientOnlyModernWrapper';
import { htmlDecode } from '../../../utils/htmlDecode';
import { domainLicenseToLicenseStatus } from '../helpers/domainLicenseToLicenseStatus';
import {
  incentiveCounterTeachesLeft,
  incrementTeachCountIfApplicable,
  userHasReachedIncentiveCount,
  userIsInIncentiveCounterTest,
} from '../utils';
import ChannelInfo from './ChannelInfo/ChannelInfo';
import { DownloadAttachment } from './components/DownloadAttachment';
import './LessonOverview.less';

const TRANSLATION_NS = 'lessonDetails';

export interface AnonymousFollowProps {
  channel: string;
  email: string;
  firstName: string;
  lastName: string;
  organization: string;
  city: string;
  level: string;
}

export type AddPinToActiveLesson = (pinId: string) => Promise<boolean>;

export interface Props {
  lesson: Lesson;
  channel: IndexedChannelInfo | undefined;
  pins: LessonPin[];
  navigateToLesson: (newTab?: boolean) => void;
  onLessonSave?: (lesson: Lesson, cancelOnLoginDismiss?: boolean) => void;
  onPinSelected?: (pinId: string) => void;
  shareLesson: () => void;
  accessTypes: LessonDetailsAccessTypes;
  preselectedPin?: string;
  isLessonSaved: boolean;
  flagLessonButton?: JSX.Element | undefined;
  channelUrl: (slug: string) => string;
  channelFollowed: boolean;
  userIsLoggedIn: boolean;
  userRatings: UserRatings;
  onRatingSelected?: (lesson: Lesson, rating: number) => void;
  addPin?: AddPinToActiveLesson;
  activeLesson?: UserContext['activeLesson'];
  activeDocument?: UserContext['activeDocument'];
  embedLesson?: boolean;
  currentLicense: Licenses.Status | undefined;
  eligibleToTeachWithoutCredits: boolean;
}

function LessonOverview({
  lesson,
  preselectedPin,
  channel,
  pins,
  isLessonSaved,
  onLessonSave,
  shareLesson,
  accessTypes,
  onPinSelected,
  flagLessonButton,
  channelUrl,
  navigateToLesson,
  channelFollowed,
  userRatings,
  onRatingSelected,
  userIsLoggedIn,
  addPin,
  activeLesson,
  activeDocument,
  embedLesson,
  currentLicense,
  eligibleToTeachWithoutCredits,
}: Props) {
  const { t } = useTranslation(TRANSLATION_NS);
  const [tagsExpanded, toggleTagsExpanded] = useToggle(false);
  const services = useAppServices();
  const dispatch = useDispatch();
  const { isTeachingAllowed, handleNotAllowedToTeach } = useTeachingCredits();

  function handleRatingSelected(rating: number) {
    if (!onRatingSelected) return;
    onRatingSelected(lesson, rating);
  }

  async function handleLessonSave() {
    if (userIsLoggedIn && !(await requestFollowIfRequired('save-lesson'))) {
      return;
    }

    const requireFollow = channel && channel.name && channel.requireFollow;
    if (!onLessonSave) return;
    onLessonSave(lesson, Boolean(requireFollow));
  }

  async function handleTeachClick(newTab?: boolean) {
    if (!eligibleToTeachWithoutCredits && !services.user.currentUser()) {
      const loginResponse = await services.modals.open('loginPrompt', { forceNewTab: !!embedLesson, action: 'teach' });
      if (loginResponse.dismiss) return;
    }
    if (!isTeachingAllowed) {
      handleNotAllowedToTeach();
      return;
    }
    const requestChannelFollow = await requestFollowIfRequired('play-lesson');
    if (!requestChannelFollow) {
      return;
    }
    const showLoginOrRegisterModal = userHasReachedIncentiveCount(!!embedLesson, lesson, userIsLoggedIn);

    if (showLoginOrRegisterModal) {
      const promptType = userIsInIncentiveCounterTest(!!embedLesson, lesson, userIsLoggedIn);

      services.modals.open('registerOrLogin', {
        redirectUrl: lessonRoute(lesson._id, preselectedPin),
        titleOverride: promptType === 'hard' ? t('hardLimitReachedTitle') : t('mediumLimitReachedTitle'),
        descriptionOverride: t('limitReachedDescription'),
      });

      return;
    }

    incrementTeachCountIfApplicable(!!embedLesson, lesson, userIsLoggedIn);

    return navigateToLesson(Boolean(newTab));
  }

  function teachButtonText() {
    const count = incentiveCounterTeachesLeft(!!embedLesson, lesson, userIsLoggedIn);
    if (typeof count === 'undefined') return t('teach');

    return count === 0 ? t('teachLockedForIncentiveCounter') : t('teachLimited', { count });
  }

  async function requestFollowIfRequired(trigger: LessonTrigger['trigger']): Promise<boolean> {
    if (channelFollowed) {
      return true;
    }

    if (channel && channel.name && channel.requireFollow) {
      const products = await services.user.currentUser()?.products;

      if (products) {
        const hasVoucherForChannel = products?.some((product) => product.channelId === channel._id);
        if (hasVoucherForChannel) return true;
      }

      const { success } = await services.channel.requireFollow({
        channelSlug: channel.name,
        channelTitle: channel.title && ChannelTitle.localizeTitle(channel.title),
        trigger: {
          lessonId: lesson._id,
          trigger,
          type: 'lesson',
        },
        userId: services.user.currentUser()?._id,
      });
      if (success) {
        dispatch(followChannelAction(channel.name));
      }

      return success;
    }

    return true;
  }

  const generatedDescription = useMemo(() => {
    const slideCounts = pins.reduce(
      (acc, pin) => {
        const incr = (key: keyof typeof acc) => ({ ...acc, [key]: acc[key] + 1 });

        const pinType = pin.item.type;

        switch (true) {
          case pinInteractiveTypes.some((t) => pinType === t):
            return incr('interactive');

          case pinType === 'video':
            return incr('video');

          case pinType === 'slide':
            return incr('text');

          default:
            return acc;
        }
      },
      { interactive: 0, text: 0, video: 0 }
    );

    const translatedValues = Object.entries(slideCounts)
      .filter(([_type, count]) => count > 0)
      .map(([type, count]) => {
        return `<strong>` + (type === 'video' ? `${count} ` : '') + t(`${type}-slide`, { count }) + `</strong>`;
      });

    return (
      <p>
        <Trans t={t} i18nKey="generated-description" count={pins.length} />
        {translatedValues.length > 0 && (
          <>
            , {t('common:with')}{' '}
            <span dangerouslySetInnerHTML={{ __html: listFormat(t, translatedValues) || '' }}></span>
          </>
        )}
        .
      </p>
    );
  }, [pins, t]);

  function pinOverviewInfo(pin: LessonPin, pinIndex: number): React.ReactElement {
    return (
      <Heading className="lesson-overview-pin-title" as="h4" size="h3">
        Slide {pinIndex + 1} -{' '}
        {capitalize(t('slides:' + PinViewUtils.titleForPinType(pin.item.type, !!pin.videoQuestion)))}
      </Heading>
    );
  }

  const attachments = useMemo(() => {
    const init: { instructions: LessonAttachment[]; worksheets: LessonAttachment[] } = {
      instructions: [],
      worksheets: [],
    };

    if (!lesson.attachments) return init;

    return lesson.attachments.reduce((acc, attachment) => {
      if (attachment.type === 'worksheet') acc.worksheets.push(attachment);
      else acc.instructions.push(attachment);

      return acc;
    }, init);
  }, [lesson.attachments]);

  const anyPinHasNotes = pins.some((pin) => Boolean(pin.item.notes && pin.item.notes.text));

  const userRating = userRatings[lesson._id];
  const rating = !_.isNil(userRating) ? userRating : 0;
  const isRatedByUser = !_.isNil(userRating);

  const contextMenuProps: ContextMenuBaseProps = {
    addPin,
    activeLesson,
  };
  const showContextMenu = lesson.privacy !== 'protected' && activeDocument?.type !== 'lessonV2';

  const showTeacherCreditsIndicator = shouldShowTeacherCreditsIndicator(domainLicenseToLicenseStatus(currentLicense));
  const showSaveButton = Language.isMain(LanguageSingleton.get()) && accessTypes.copySearch && !embedLesson;
  return (
    <div className="lesson-overview">
      {!embedLesson || (embedLesson && !channel) ? (
        <Heading as="h1" size="h0" className="lesson-overview__title">
          {htmlDecode(lesson.name)}
        </Heading>
      ) : null}

      <LessonPreview lesson={lesson} pins={pins} preselectedPin={preselectedPin} onPinSelected={onPinSelected}>
        <div className="lesson-overview-meta">
          <div>
            <MetaDataTagList
              className="lesson-overview-meta-section"
              metaData={FacetMetaData.fromLesson({ lesson })}
              size="large"
              limit={tagsExpanded ? undefined : 8}
              subjectsLimit={tagsExpanded ? null : SUBJECTS_LIMIT}
              renderOverflow={(overflowCount) => {
                return (
                  <UnstyledButton className="lesson-overview-meta-tags-show-more" onClick={() => toggleTagsExpanded()}>
                    {t('showMoreTags', { count: overflowCount })}
                  </UnstyledButton>
                );
              }}
            />

            {channel && (
              <ChannelInfo
                className="lesson-overview-meta-section"
                channel={channel}
                showFollowButton={Boolean(userIsLoggedIn)}
                channelUrl={channelUrl}
                lessonId={lesson._id}
                forceNewTab={!!embedLesson}
              />
            )}

            <div className="lesson-overview-meta-section">
              <div className="lesson-overview-meta__description ">{generatedDescription}</div>
              <LessonDuration durationInMin={lesson.durationInMin} />
            </div>
          </div>

          <div className="lesson-overview-meta-action-section">
            {!embedLesson ? (
              <FeedbackStars
                className="lesson-overview-meta-section"
                strokeOnlyOnEmpty={isRatedByUser}
                onChangeValue={handleRatingSelected}
                value={rating}
                maxValue={1.0}
                stars={5}
                style={{ fontSize: '2em' }}
              />
            ) : null}
            {showTeacherCreditsIndicator && eligibleToTeachWithoutCredits && (
              <Banner
                paragraph={t('teachingCreditsChannelBanner')}
                actionElement={
                  <Link
                    external
                    href={
                      LanguageSingleton.is('nl') ? helpdeskArticle('nl', '6737128') : helpdeskArticle('en', '6737128')
                    }
                  >
                    <a target="_blank">{t('teachingCreditsChannelBannerLink')}</a>
                  </Link>
                }
              />
            )}
            <div className="lesson-overview-meta__buttons lesson-overview-meta-section">
              <div>
                <ModernButton
                  buttonType="primary"
                  iconStart={<IconLesson />}
                  onClick={(e) => handleTeachClick(false)}
                  onAuxClick={(e) => handleTeachClick(true)}
                >
                  {teachButtonText()}
                </ModernButton>
              </div>
              {showTeacherCreditsIndicator && !eligibleToTeachWithoutCredits && (
                <ClientOnlyModernWrapper SSRFallback={<TeachingCreditsIndicator loading={true} credits={-1} />}>
                  <TeachingCreditsIndicatorFeature />
                </ClientOnlyModernWrapper>
              )}
              {showSaveButton ? <SaveButton isLessonSaved={isLessonSaved} handleLessonSave={handleLessonSave} /> : null}
              <ModernButton buttonType="neutral" noFill showStroke onClick={shareLesson} iconStart={<IconShare />}>
                {t('share')}
              </ModernButton>
            </div>
            <div className="lesson-overview-meta__sub-buttons lesson-overview-meta-section">
              <TextButton onClick={() => window.print()} className="print-button">
                <PrintSvg className="icon" />
                {t('print')}
              </TextButton>
              {flagLessonButton}
            </div>
          </div>
        </div>
      </LessonPreview>

      <div className="lesson-overview-introduction">
        <div className="lesson-overview-introduction-text">
          {lesson.description && (
            <div>
              <Heading>{t('introduction')}</Heading>
              <div dangerouslySetInnerHTML={{ __html: lesson.description }}></div>
            </div>
          )}

          {lesson.information && (
            <div>
              <Heading>{t('instructions')}</Heading>
              <div dangerouslySetInnerHTML={{ __html: lesson.information }}></div>
            </div>
          )}
        </div>

        {lesson.attachments && lesson.attachments.length > 0 && (
          <div className="lesson-overview-introduction-downloads">
            {attachments.instructions.length > 0 && (
              <div>
                <Heading as="h3" size="h2" style={{ marginTop: 0 }}>
                  {t('instructions')}
                </Heading>
                {attachments.instructions.map((attachment) => (
                  <DownloadAttachment lessonId={lesson._id} attachment={attachment} key={attachment.uploadId} />
                ))}
              </div>
            )}

            {attachments.worksheets.length > 0 && (
              <div>
                <Heading as="h3" size="h2">
                  {t('worksheets')}
                </Heading>
                {attachments.worksheets.map((attachment) => (
                  <DownloadAttachment lessonId={lesson._id} attachment={attachment} key={attachment.uploadId} />
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <div>
        <Heading>{t('lesson-parts')}</Heading>
        {!anyPinHasNotes ? (
          <PinGrid
            pins={pins}
            contextMenuProps={contextMenuProps}
            pinInfo={pinOverviewInfo}
            showContextMenu={showContextMenu}
          />
        ) : (
          <PinList pins={pins} contextMenuProps={contextMenuProps} pinInfo={pinOverviewInfo} />
        )}
      </div>
    </div>
  );
}

function LessonDuration({ durationInMin }: { durationInMin: number | undefined }) {
  const { t } = useTranslation(TRANSLATION_NS);
  if (!durationInMin) return null;
  return (
    <span className="lesson-overview-meta__duration">
      <span className="lesson-overview-meta__icon">
        <img src="/search/public/img/icons/black/clock.png" alt="time-icon" />
      </span>
      {t('lesson-lasts') + ': '}
      <strong>
        {durationInMin} {t('minutes-short')}
      </strong>
    </span>
  );
}

interface SaveButtonProps {
  isLessonSaved: boolean;
  handleLessonSave: () => void;
}

function SaveButton({ isLessonSaved, handleLessonSave }: SaveButtonProps) {
  const { t } = useTranslation(TRANSLATION_NS);

  const buttonProps = isLessonSaved
    ? {
        disabled: true,
        className: 'lesson-saved',
      }
    : {
        onClick: handleLessonSave,
      };

  return (
    <ModernButton buttonType="secondary" iconStart={<IconDownload className="icon" />} {...buttonProps}>
      {isLessonSaved ? (
        <span>{t('saved')}</span>
      ) : (
        <>
          <span>{t('save-for-later')}</span>
        </>
      )}
    </ModernButton>
  );
}

type PinGridProps = {
  pins: LessonPin[];
  showContextMenu: boolean;
  contextMenuProps: ContextMenuBaseProps;
  pinInfo: (pin: LessonPin, index: number) => React.ReactElement;
};

function PinGrid({ pins, contextMenuProps, showContextMenu, pinInfo }: PinGridProps) {
  return (
    <div className="lesson-overview-pins lesson-overview-pin-grid">
      <div className="pin-flow-40">
        {pins
          .map((pin, index) => {
            const videoPin = pin.videoQuestion
              ? pins.find((videoPin) => videoPin._id === pin.videoQuestion)
              : undefined;
            return (
              <div className="pin-wrapper" key={pin._id}>
                <PinView
                  className="lesson-overview-pin"
                  pin={pin}
                  isThumb
                  hideFact={true}
                  videoPin={videoPin}
                  contextMenu={() =>
                    showContextMenu ? <PinContextMenu pinId={pin._id} {...contextMenuProps} /> : <></>
                  }
                />
                {pinInfo(pin, index)}
              </div>
            );
          })
          .concat(FlexBoxDummies('pin-wrapper', 4))}
      </div>
    </div>
  );
}

type PinListProps = {
  pins: LessonPin[];
  contextMenuProps: ContextMenuBaseProps;
  pinInfo: (pin: LessonPin, index: number) => React.ReactElement;
};

function PinList({ pins, contextMenuProps, pinInfo }: PinListProps) {
  const { t } = useTranslation(TRANSLATION_NS);
  return (
    <div className="lesson-overview-pins lesson-overview-pin-list">
      {pins.map((pin, index) => {
        const notes = pin.item.notes && pin.item.notes.text;
        const videoPin = pin.videoQuestion ? pins.find((videoPin) => videoPin._id === pin.videoQuestion) : undefined;

        return (
          <div key={pin._id} className="lesson-overview-pin-list-item">
            <div className="rel">
              <div className="lesson-overview-pin-container">
                <PinView
                  className="relative lesson-overview-pin"
                  pin={pin}
                  isThumb
                  hideFact={true}
                  contextMenu={() => <PinContextMenu pinId={pin._id} {...contextMenuProps} />}
                  videoPin={videoPin}
                />
              </div>
            </div>
            <div className="lesson-overview-pin-list-item-notes">
              {pinInfo(pin, index)}
              {!!notes ? (
                <div dangerouslySetInnerHTML={{ __html: notes }}></div>
              ) : (
                <p className="lesson-overview-pin-notes-empty">{t('slide-no-notes')}</p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

interface ContextMenuBaseProps {
  addPin?: AddPinToActiveLesson;
  activeLesson?: UserContext['activeLesson'];
}

interface ContextMenuProps extends ContextMenuBaseProps {
  pinId: string;
}

type AddPinState = 'idle' | 'fetching' | 'done' | 'failed';
function PinContextMenu({ pinId, addPin, activeLesson }: ContextMenuProps) {
  const [pinState, setPinState] = useState<AddPinState>('idle');
  const { t } = useTranslation(TRANSLATION_NS);
  const [pickerOpen, togglePickerOpen] = useToggle(false);

  const add = async () => {
    if (!addPin) return;
    setPinState('fetching');
    const res = await addPin(pinId);
    setPinState(res ? 'done' : 'failed');
  };

  const handlePickerOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    togglePickerOpen(true);
  };

  const handleOnClose = () => {
    togglePickerOpen(false);
  };

  const addButtonDisabledStated: AddPinState[] = ['failed', 'done'];

  // because add to lesson is the only action, for now hide the menu if
  if (!activeLesson) return null;
  return (
    <ContextMenu onClose={handleOnClose}>
      <div style={{ display: pickerOpen ? 'none' : 'block' }}>
        <Button theme={'grey'} onClick={handlePickerOpen} height={'small'}>
          {t('useInMyLesson')}
        </Button>
      </div>
      <div style={{ display: pickerOpen ? 'block' : 'none' }}>
        <Heading size="h3">{htmlDecode(activeLesson.name)}</Heading>
        {activeLesson.firstPin ? <PinView pin={activeLesson.firstPin}></PinView> : <div>{t('lessonHasNoPins')}</div>}
        <div className="flex-align-horizontal">
          <Button onClick={() => add()} disabled={addButtonDisabledStated.includes(pinState)} height={'small'}>
            {pinState === 'done' ? t('useInMyLessonSuccess') : t('useInMyLessonConfirm')}
          </Button>
        </div>
      </div>
    </ContextMenu>
  );
}

export default LessonOverview;
