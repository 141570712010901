import React from 'react';

const FolderUpSvg = (props) => (
  <svg width={55} height={36} {...props} viewBox="0 0 55 36">
    <defs>
      <path
        d="M54.003 6.794c.422.634.667 1.395.667 2.212v22.99a4 4 0 01-3.998 4.006H3.998A4.006 4.006 0 010 31.997V3.996A4 4 0 014.01 0h9.649a4.01 4.01 0 013.473 2h32.866a4.005 4.005 0 014.005 4v.794z"
        id="prefix__a"
      />
      <mask
        id="prefix__b"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x={0}
        y={0}
        width={54.67}
        height={36.002}
        fill="#fff"
      >
        <use xlinkHref="#prefix__a" />
      </mask>
    </defs>
    <g fill="none" fillRule="evenodd">
      <use stroke="#E0E0E0" mask="url(#prefix__b)" strokeWidth={4} strokeDasharray="3,3" xlinkHref="#prefix__a" />
      <path fill="#E0E0E0" d="M21 20h15v2H21zM34 13h2v7h-2zM15 21.657L20.657 16v11.314z" />
    </g>
  </svg>
);

export default FolderUpSvg;
