import { AppError } from '@lessonup/utils';
import React, { ComponentType, useContext } from 'react';

export type TypeOfProps<C> = C extends ComponentType<infer P>
  ? P
  : C extends React.Component<infer P>
  ? P
  : C extends React.PureComponent<infer P>
  ? P
  : never;

export function useContextGenerator<T>(context: React.Context<T | undefined>, name: string): () => T {
  return () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const usedContext = useContext(context);
    if (!usedContext) {
      throw new AppError('invalid-params', `Cannot use context consumer outside of provider`, { context, name });
    }
    return usedContext;
  };
}
