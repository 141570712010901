import { TooltipName } from '../../utils/tooltips/TooltipContext';
import { AnyAction } from '../actions/actions';

export interface Tooltip {
  id: TooltipName;
}

type TooltipState = TooltipName[];

const initialState: TooltipState = [];

export function tooltipReducer(state: TooltipState = initialState, action: AnyAction): TooltipState {
  switch (action.type) {
    case 'showTooltip':
      return state.concat([action.tooltipId]);
    case 'hideTooltip':
      return state.filter((t) => t !== action.tooltipId);
    default:
      return state;
  }
}
