import { AppError } from '@lessonup/utils';

export interface AppRouter {
  go: (routeName: string, params?: object, options?: object) => void;
  path: (routeName: string, params?: object) => string;
}

export namespace AppRouter {
  let _router: AppRouter;

  export function initialize(router: AppRouter) {
    _router = router;
  }

  export function go(routeName: string, params?: object) {
    if (!_router) {
      throw new AppError('unexpected-data', 'router.ts not initialized');
    }
    return _router.go(routeName, params);
  }

  export function path(routeName: string, params?: Object) {
    if (!_router) return 'no Router initialized';
    return _router.path(routeName, params);
  }
}
