import { logger } from '@lessonup/client-integration';
import { Lesson } from '@lessonup/teaching-core';
import _ from 'lodash';
import { LessonPlan, MongoUser, UserContentAuth } from '../../../domain';
import { Explorer, getExplorerIdFromDocument } from '../../../domain/newExplorers';

type ID = string;

export class ClientContentAuthService {
  private debug = false;
  private auth: UserContentAuth.AuthChecker;

  public constructor(
    private explorerById: (id: string) => Explorer | undefined,
    private planById: (id: string) => LessonPlan | undefined,
    private currentProducts: () => string[],
    private currentUser: () => MongoUser | undefined,
    private publisherProducts: () => string[]
  ) {
    this.auth = new UserContentAuth.AuthChecker(logger);
  }

  public isLessonActionAllowed(
    lesson: Lesson | undefined,
    action: UserContentAuth.LessonAction,
    plan: LessonPlan | undefined
  ): boolean {
    if (!lesson) return false;
    let contentRightsDoc = lesson.plan ? plan : lesson;
    if (!contentRightsDoc) {
      this.noPlanPassed(lesson);
      contentRightsDoc = lesson.plan ? this.planById(lesson.plan) : undefined;
      if (!contentRightsDoc) return false;
    }
    const explorerId = getExplorerIdFromDocument(contentRightsDoc);
    const explorer = explorerId ? this.explorerById(explorerId) : undefined;

    const { allowed } = this.isLessonActionAllowedWithMeta({ lesson, action, plan, explorer });
    if (this.debug) {
      console.log('cache', lesson.origin?.rights);
      console.log(`Lesson ${action} is ${allowed ? 'allowed' : 'disallowed'} for ${lesson._id}`, lesson);
    }
    return allowed;
  }

  private noPlanPassed(lesson: Lesson) {
    logger.error('Lesson has a plan but no plan was passed', { userId: lesson.user, lessonId: lesson._id });
  }

  public isLessonActionAllowedWithMeta({
    lesson,
    action,
    plan,
    explorer,
  }: {
    lesson: Lesson | undefined;
    action: UserContentAuth.LessonAction;
    plan?: LessonPlan;
    explorer?: Explorer;
  }): UserContentAuth.Response {
    if (!lesson) return { allowed: false };
    const user = this.currentUser();
    const [userProducts, userPublisherProducts] = this.fetchProductsIfNeeded(lesson, [action]);

    const response = this.auth.isLessonActionAllowed({
      document: lesson,
      user,
      action,
      userProducts,
      userPublisherProducts,
      channelRights: lesson.origin?.rights,
      plan,
      explorer,
    });
    if (this.debug) {
      console.log(`Lesson isLessonActionAllowedWithMeta`, response);
    }
    return response;
  }

  public areLessonActionsAllowed<A extends UserContentAuth.LessonAction>(
    lesson: Lesson | undefined,
    actions: A[],
    plan?: LessonPlan
  ): UserContentAuth.LessonActionCheckResponse<A> {
    const res = _.fromPairs(actions.map((a) => [a, false])) as UserContentAuth.LessonActionCheckResponse<A>;
    if (!lesson) return res;
    let contentRightsDoc = lesson.plan ? plan : lesson;
    if (!contentRightsDoc) {
      this.noPlanPassed(lesson);
      contentRightsDoc = lesson.plan ? this.planById(lesson.plan) : undefined;
      return res;
    }
    const explorerId = getExplorerIdFromDocument(contentRightsDoc);
    const explorer = explorerId ? this.explorerById(explorerId) : undefined;
    const user = this.currentUser();
    const [userProducts, userPublisherProducts] = this.fetchProductsIfNeeded(lesson, actions);
    actions.forEach(
      (action) =>
        (res[action] = this.auth.isLessonActionAllowed({
          document: lesson,
          user,
          action,
          userProducts,
          userPublisherProducts,
          channelRights: lesson.origin?.rights,
          plan,
          explorer,
        }).allowed)
    );
    if (this.debug) {
      console.log(`Lesson ${lesson._id} actions: ${JSON.stringify(res)}`, lesson);
    }
    return res;
  }

  public arePlanActionsAllowed<A extends UserContentAuth.PlanAction>(
    plan: LessonPlan | undefined,
    actions: A[]
  ): UserContentAuth.PlanActionCheckResponse<A> {
    const res = _.fromPairs(actions.map((a) => [a, false])) as UserContentAuth.PlanActionCheckResponse<A>;
    if (!plan) return res;
    const user = this.currentUser();
    const [userProducts, userPublisherProducts] = this.fetchProductsIfNeeded(plan, actions);
    const explorerId = getExplorerIdFromDocument(plan);
    const explorer = explorerId ? this.explorerById(explorerId) : undefined;

    actions.forEach(
      (action) =>
        (res[action] = this.auth.isPlanActionAllowed({
          document: plan,
          user,
          action,
          userProducts,
          userPublisherProducts,
          explorer,
        }).allowed)
    );
    if (this.debug) {
      console.log(`Lesson ${plan._id} actions: ${JSON.stringify(res)}`, plan);
    }
    return res;
  }

  public isPlanActionAllowed(
    lessonPlan: LessonPlan | undefined,
    action: UserContentAuth.PlanAction
  ): UserContentAuth.Response {
    if (!lessonPlan) return { allowed: false };
    const user = this.currentUser();
    const [userProducts, userPublisherProducts] = this.fetchProductsIfNeeded(lessonPlan, [action]);
    const explorerId = getExplorerIdFromDocument(lessonPlan);
    const explorer = explorerId ? this.explorerById(explorerId) : undefined;
    const allowed = this.auth.isPlanActionAllowed({
      document: lessonPlan,
      user,
      action,
      userProducts,
      userPublisherProducts,
      explorer,
    });
    if (this.debug) {
      console.log(`LessonPlan ${action} is ${allowed ? 'allowed' : 'disallowed'} for ${lessonPlan._id}`, lessonPlan);
    }
    return allowed;
  }

  public hasAccessToDocumentProducts(document: UserContentAuth.Document) {
    if (!document?.products || !document?.products?.length) {
      return true;
    }
    return this.hasAccessToProducts(document?.products);
  }

  public hasAccessToProducts(products: ID[] | undefined) {
    if (!products) return true;
    const currentProducts = this.currentProducts();
    return products.some((id) => currentProducts.includes(id));
  }

  public hasProducts(): boolean {
    return !!this.currentProducts().length;
  }

  /*
    Small optimization, if no products are involved we don't need te fetch them
    If the action does not require publisher access, we don't need to fetch those
  */
  private fetchProductsIfNeeded(
    document: UserContentAuth.Document,
    actions: UserContentAuth.AnyAction[]
  ): [string[], string[]] {
    if (!UserContentAuth.hasProducts(document)) return [[], []];
    const products = this.currentProducts();
    const publisherProducts = actions.some((action) => this.auth.isPublisherAction(action))
      ? this.publisherProducts()
      : [];
    return [products, publisherProducts];
  }
}
