import { trackAction } from '@lessonup/client-integration';
import { useEffect } from 'react';

export default function ChannelAnalytics({ channelSlug, channelId }: { channelSlug: string; channelId?: string }) {
  useEffect(() => {
    trackAction('view-channel', { channelSlug, channelId });
  }, [channelSlug, channelId]);

  return null;
}
