import * as React from 'react';
import { ModalManagerService } from './ModalManagerService';

export interface Props {
  service: ModalManagerService;
}

interface ModalComponent {
  renderFn: (closeFn) => any;
  closeFn: (result) => any;
  key: string;
}

export interface State {
  modals: ModalComponent[] | undefined;
}

export default class ModalManagerComponent extends React.Component<Props, State> {
  public componentDidMount() {
    this.props.service.registerComponent(this);

    this.setState({
      modals: [],
    });
  }

  public componentWillUnmount() {
    this.props.service.unregisterComponent();
  }

  public pushModal(modal: ModalComponent) {
    this.setState({
      modals: (this.state.modals || []).concat(modal),
    });
  }

  public popModal() {
    this.setState({
      modals: (this.state.modals || []).slice(0, -1),
    });
  }

  public render() {
    if (!this.state || !this.state.modals || !this.state.modals.length) {
      return false;
    }

    return this.state.modals.map((m) => m.renderFn(m.closeFn));
  }
}
