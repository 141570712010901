import React from 'react';

interface Props {
  rotation?: number;
  className?: string;
}

export const HomeIcon: React.FC<Props> = ({ ...props }) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 426.7 426.7"
    {...props}
  >
    <g>
      <g>
        <path
          d="M209.5,35.4L11,214.1c-3.9,3.5-1.4,9.9,3.8,9.9H64v167.8c0,1.6,1.3,2.8,2.8,2.8h101c1.6,0,2.8-1.3,2.8-2.8V266.7H256
			v125.2c0,1.6,1.3,2.8,2.8,2.8h101c1.6,0,2.8-1.3,2.8-2.8V224h49.2c5.2,0,7.7-6.4,3.8-9.9L217.1,35.4
			C215,33.5,211.7,33.5,209.5,35.4z"
        />
      </g>
    </g>
  </svg>
);
