import { Component, SVGGeneratorProps, SymbolComponent, Symbols } from '@lessonup/teaching-core';
import { ArrowComponent } from './symbolPaths/ArrowSymbol';
import { CircleComponent } from './symbolPaths/CircleSymbol';
import { HexagonComponent } from './symbolPaths/HexagonSymbol';
import { RectangleComponent } from './symbolPaths/RectangleSymbol';
import { RocketComponent } from './symbolPaths/RocketSymbol';
import { StarComponent } from './symbolPaths/StarSymbol';
import { TriangleComponent } from './symbolPaths/TriangleSymbol';

export function renderSymbol(component: SymbolComponent, position: Component.Box) {
  const comp = symbolReactCompForName(component.settings.symbol);
  return comp({ position, settings: component.settings });
}

export function symbolReactCompForName(name: Symbols): React.FC<SVGGeneratorProps> {
  if (name === 'arrow') return ArrowComponent;
  if (name === 'star') return StarComponent;
  if (name === 'rectangle') return RectangleComponent;
  if (name === 'triangle') return TriangleComponent;
  if (name === 'circle') return CircleComponent;
  if (name === 'hexagon') return HexagonComponent;
  if (name === 'rocket') return RocketComponent;
  throw Error('missing def');
}
