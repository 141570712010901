import { Colors, Component, SVGGeneratorProps, SymbolComponent } from '@lessonup/teaching-core';
import React from 'react';

export interface BaseMathComponentProps extends SVGGeneratorProps {
  pathFunc: (width: number, height: number, margin: number) => string;
}

export const BaseMathComponent: React.FC<BaseMathComponentProps> = ({ position, settings, pathFunc }) => {
  const { width, height } = getSize(position);
  const { strokeWidth } = settings;
  const path = pathFunc(width, height, parseInt(strokeWidth));
  const _styles = styles(settings);
  const hasPattern = !!settings.fillPattern;
  const firstStyles = !hasPattern ? _styles : {};
  return (
    <svg viewBox={`0 0 ${width} ${height}`}>
      <path d={path} fill={getColor(settings.color)} {...firstStyles} />
      {hasPattern && <path d={path} {...styles} fill={settings.fillPattern && `url(#${settings.fillPattern})`} />}
    </svg>
  );
};

export function styles({
  color,
  strokeColor,
  strokeDash,
  strokeWidth,
}: SymbolComponent.Settings): React.SVGProps<SVGPathElement> {
  return {
    fill: getColor(color),
    strokeWidth,
    stroke: getColor(strokeColor),
    strokeDasharray: getStrokeArray(strokeDash, parseInt(strokeWidth)),
    ...roundLineCapStyles(),
  };
}

export function roundLineCapStyles(): React.SVGProps<SVGPathElement> {
  return {
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  };
}

export function getSize(pos: Component.Box): { width: number; height: number } {
  // Base values have to be in the same aspect ratio as the slide.
  const baseWidth = 480;
  const baseHeight = 270;
  let width = 100 - pos.right - pos.left;
  let height = 100 - pos.top - pos.bottom;
  // These width and height values basically set the new canvas size for the svg path.
  // A bigger canvas means strokes will show smaller.
  width = baseWidth * (width / 100);
  height = baseHeight * (height / 100);
  return { width, height };
}

export function getStrokeArray(dashSettings: string, strokeWidth: number) {
  const dashArray: string[] = [];
  const settings = dashSettings.split(' ');
  settings.forEach((f) => {
    const value: number = parseFloat(f) * strokeWidth;
    dashArray.push(value.toString());
  });
  return dashArray.join();
}

export function getColor(color) {
  if (color && color.substr(0, 1) == '#') {
    return color;
  }
  return Colors.getHexColor(color);
}
