import React from 'react';
import './Star.less';

const FILLED_COLOR = '#f8c82d'; // @yellow
const EMPTY_COLOR = '#dcdbdc';

const STROKE_COLOR = '#e0e0e0';

export interface StarProps {
  filled: boolean;
  outlineOnly: boolean;
  value: number;
  onHover?: (value: number) => void;
  onClick?: (value: number) => void;
}

export default class FeedbackStar extends React.PureComponent<StarProps> {
  constructor(props: Readonly<StarProps>) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onHover = this.onHover.bind(this);
  }

  onClick() {
    this.props.onClick && this.props.onClick(this.props.value);
  }

  onHover() {
    this.props.onHover && this.props.onHover(this.props.value);
  }
  render() {
    let fill = 'none';

    if (!this.props.outlineOnly) {
      fill = this.props.filled ? FILLED_COLOR : EMPTY_COLOR;
    }

    const stroke = this.props.outlineOnly ? STROKE_COLOR : 'none';

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 222 210"
        className="feedback_bar__star feedback_bar__star--sizing-to-font feedback_bar__star--animations"
        onClick={this.onClick}
        onMouseEnter={this.onHover}
        fill={fill}
      >
        <path
          d="M179.46 209.73l-68.28-41.51-68.107 41.79 18.381-77.77L.661 80.38 80.3 73.835 110.84-.009l30.838 73.717 79.665 6.222-60.58 52.11z"
          stroke={stroke}
          strokeWidth="10"
          fill={fill}
          className="svg_star_path"
        />
      </svg>
    );
  }
}

// import React from 'react'

// const SvgComponent = props => (
//   <svg {...props} viewBox="0 0 222 210"
//   >
//     <path

//       fill="#DCDBDC"
//       fillRule="nonzero"
//     />
//   </svg>
// )

// export default SvgComponent
