import React from 'react';
import { LessonPlan } from '../../../shared-core/domain';
import { sanitizeContent } from '../../../shared-core/utils';
import './css/LessonPlanText.less';

type Props = {
  child: LessonPlan.Children.Text;
};

const LessonPlanText: React.FC<Props> = ({ child }) => {
  const alignText = child.align ? `text-align-${child.align}` : 'text-align-left';
  const childText = sanitizeContent(child.text);
  return (
    <div
      className={`paragraph ${alignText} html auto-height no-border lesson-plan-text`}
      dangerouslySetInnerHTML={{ __html: childText }}
    ></div>
  );
};

export default LessonPlanText;
