import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { registerAnalyticsLogger, tracker } from '@lessonup/analytics';
import {
  AuthStatus,
  Debugger,
  getUseFormalLanguageOnLoadFromCookies,
  globalTracker,
  initI18n,
  initializeOptimizeCallbacks,
  logger,
  setAuth,
  setI18nInstance,
  setIntegrationConfig,
  trackAction,
} from '@lessonup/client-integration';
import { initIntercom, logoutAndReinit } from '@lessonup/intercom';
import { graphQLFragmentMatches } from '@lessonup/teacher-modern';
import { SymbolComponent } from '@lessonup/teaching-core';
import { injectStyle } from '@lessonup/ui-components';
import { AppError } from '@lessonup/utils';
import { createBrowserHistory } from 'history';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import ReactModal from 'react-modal';
import { LessonUpApiService } from '../shared-core/client/services/api';
import { JwtTokenService } from '../shared-core/client/services/auth';
import { getLanguageCookie, setLanguageCookie } from '../shared-core/client/services/language/setLanguageCookie';
import { ConsoleTransport, SentryTransport, TrackErrorTransport } from '../shared-core/client/services/logging';
import {
  isValidOrigin,
  isValidProvider,
  SSORegistrationLocalStorageKeys,
} from '../shared-core/client/services/tracking/SsoRegistrationParams';
import { GA4ActionTransport } from '../shared-core/client/services/tracking/transports/GA4ActionTransport';
import { HttpActionTransport } from '../shared-core/client/services/tracking/transports/HttpActionTransport';
import { registerModalProvider } from '../shared-core/client/utils/modals';
import { LanguageKey, LanguageSingleton, MongoUser, UserContext, VOUCHER_COOKIE_KEY } from '../shared-core/domain';
import { EnvConfig } from '../shared-core/services';
import { missingKeyHandler } from '../shared-core/ui/dependencies';
import { Translations } from '../shared-core/ui/dependencies/translations';
import App from './App';
import { loginFinishedEventForSource } from './modules/analytics/events';
import { PublicAppSettings } from './PublicAppSettings';
import { configureStore } from './redux/store';
import { configureServices } from './services/AppServices';
import configureVoucherAppService from './services/appServices/VoucherAppService';
import { searchAppModalManagerService } from './services/SearchAppModalService';
import { sharedModalsProvider } from './services/sharedModalsProvider';
import { UserService } from './services/UserService';
import { translationResources } from './utils/translations';

ReactModal.setAppElement('#app');

const settings: PublicAppSettings = window['__SETTINGS'];
if (!settings) {
  throw new AppError('missing-config', 'No settings found in window-object');
}
const initialState = window['__INITIAL_STATE'];
const ssrLanguage: LanguageKey = window['__LANGUAGE'];
LanguageSingleton.set(ssrLanguage);

const config = new EnvConfig(settings);

const sentry = settings.sentry;
if (!sentry || (!sentry.disabled && !sentry.dsn)) {
  throw new AppError('missing-config', 'Missing Sentry DSN in window.__SETTINGS');
}
logger.registerTransport(new SentryTransport(config, sentry));
logger.registerTransport(new ConsoleTransport(config));
logger.registerTransport(new TrackErrorTransport((error) => tracker.trackError(error)));

if (!config.isLive) new Debugger(config.versionTagName);

registerModalProvider(sharedModalsProvider);

// for easy reference when debugging/checking/releasing on live/test/staging setups
window['VERSION_TAGNAME'] = config.versionTagName;

const tokenService = new JwtTokenService(`${settings.apiHost}`);

const api: LessonUpApiService = new LessonUpApiService(settings.apiHost, tokenService);

const history = createBrowserHistory();

const store = configureStore(initialState, config.isLive);

const voucherService = configureVoucherAppService(api);

function syncDimensions(user: UserContext | undefined) {
  if (!user) return;
  const organizations = user.organizations.map((o) => o.id);
  const mainOrganization = organizations.length ? organizations[0] : undefined;
  globalTracker.setCustomDimensions({
    appliedLicense: user.licenseStatus,
    mainOrganization,
    subjects: MongoUser.subjects(user)?.join(', '),
    schoolTypes: MongoUser.schoolTypes(user)?.join(', '),
  });
}

initIntercom(settings.intercom.id, { language_override: LanguageSingleton.get() });

if (typeof document !== 'undefined') {
  injectStyle();
}

const userService = new UserService(tokenService, api, store, {
  // this function only seems to trigger when
  // - the user logs in
  // - the user is logged in and refreshes
  // - the user logs not
  // this won't get triggered when nobody is logged in and the page refreshes
  onLoginStatusChange: async (isLoggedIn, user, loginSource) => {
    if (isLoggedIn && user) {
      tracker.setUserId(user._id);
      globalTracker.setUserId(user._id);
      const loginEvent = loginFinishedEventForSource(loginSource);
      trackLoginOrRegisterEvent();
      if (loginEvent) trackAction(loginEvent);
      syncDimensions(user);
      setAuth({ type: AuthStatus.LOGGED_IN, userId: user._id });
    } else {
      tracker.logout();
      globalTracker.setUserId(undefined);
      logoutAndReinit({ appId: settings.intercom.id, language: LanguageSingleton.get() });
      setAuth({ type: AuthStatus.LOGGED_OUT });
    }
    const voucherId = localStorage.getItem(VOUCHER_COOKIE_KEY);

    if (voucherId) {
      await voucherService.redeem(voucherId);
      localStorage.removeItem(VOUCHER_COOKIE_KEY);
    }
  },
});

userService.init();

globalTracker.addTransport(new GA4ActionTransport(settings.ga.analyticsV4, settings.ga.awId));
globalTracker.addTransport(
  new HttpActionTransport((params, action, userIds) => api.trackAction(params, action, userIds))
);

globalTracker.setConsent(tracker.cookieSettings());

registerAnalyticsLogger(logger);
tracker.init({
  env: config.env,
  snowplowCollectorUrl: settings.snowplow.collectorEndpoint,
  applicationName: settings.snowplow.applicationName,
  applicationVersion: config.version,
  enableMicrosoftDestination: true,
  onCookieConsentChange: (consent) => globalTracker.setConsent(consent),
});

if (localStorage.getItem('impersonator.impersonating') === 'true') {
  tracker.pause(true);
}

tracker.startTrackingErrors();
tracker.startTrackingPageViews();

initializeOptimizeCallbacks();

const i18nInstance = initI18n({
  resources: translationResources,
  userLanguage: ssrLanguage,
  locize: Debugger.isTranslationModeEnabled() ? settings.locize : undefined,
  useFormalLanguageOnLoad: getUseFormalLanguageOnLoadFromCookies(),
  missingKeyHandler,
});
Translations.initialize(i18nInstance.t);
setI18nInstance(i18nInstance);

const services = configureServices(
  api,
  userService,
  config,
  searchAppModalManagerService,
  store,
  i18nInstance,
  history
);

if (config.isLocal || config.isDev || config.isTest) {
  window['services'] = services;
}

if (getLanguageCookie() !== ssrLanguage) {
  setLanguageCookie(ssrLanguage);
}

setIntegrationConfig({
  apiHost: settings.graphql.baseUrl,
  possibleTypes: graphQLFragmentMatches.possibleTypes,
});

SymbolComponent.injectDefsIfNeeded();
const cache = createCache({ key: 'css' });
const rootElement = document.getElementById('app');

if (rootElement) {
  hydrateRoot(
    rootElement,
    <HelmetProvider>
      <CacheProvider value={cache}>
        <App history={history} services={services} store={store} isClient={true} i18n={i18nInstance} />
      </CacheProvider>
    </HelmetProvider>
  );
} else {
  console.error('Root element not found');
}

function trackLoginOrRegisterEvent() {
  if (typeof localStorage === 'undefined') return;
  const { PROVIDER, ORIGIN } = SSORegistrationLocalStorageKeys;

  const origin = localStorage.getItem(ORIGIN);
  const provider = localStorage.getItem(PROVIDER);
  const action = provider && origin && ['google', 'office'].includes(provider) ? 'sso-finished' : undefined;
  if (!action || !origin || !provider || !isValidOrigin(origin) || !isValidProvider(provider)) return;

  localStorage.removeItem(ORIGIN);
  localStorage.removeItem(PROVIDER);

  if (origin === 'register') {
    trackAction('register-credentials-finished', { provider });
  }

  trackAction(action, { origin, provider });
}
