import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { trackAction } from '@lessonup/client-integration';
import { EduSystem } from '@lessonup/teaching-core';
import { FacetKey, FacetResult, isSelectedValueFacet } from '../../../../shared-core/domain/search';
import { Option } from '../../../../shared-core/ui/components/search/facets/FacetSelect';
import { SearchSidebarViewProps } from '../../../../shared-core/ui/components/search/SearchSidebar';
import SearchSidebarView, {
  SearchType,
} from '../../../../shared-core/ui/components/search/SearchSidebar/SearchSidebarView';
import { useSearchContext } from './SearchContext';
import './SearchSidebar.less';

const FALLBACK_COUNTRY = 'nl';

interface Props {
  searchTextValue?: string;
  facetResults: FacetResult[];
  hideFacets?: FacetKey[];
  hideText?: boolean;
  hideCounts?: boolean;
  searchType: SearchType;
}

export default function SearchSidebar({
  facetResults,
  searchTextValue,
  hideFacets,
  hideText,
  hideCounts,
  searchType,
}: Props) {
  const { selectedFacets, resetFacet, setFacet, setSearchText, selectedText } = useSearchContext();
  const onlyValueFacets = selectedFacets.filter(isSelectedValueFacet);

  const selectedCountry = get(
    onlyValueFacets.find((facet) => facet.key === 'country'),
    'value',
    FALLBACK_COUNTRY
  );

  const selectedSchoolType = get(
    onlyValueFacets.find((facet) => facet.key === 'schoolType'),
    'value'
  );

  const eduSystem = useMemo(() => EduSystem.get(selectedCountry), [selectedCountry]);

  const [searchTextState, setSearchTextState] = useState<string | undefined>(searchTextValue || selectedText);

  useEffect(() => {
    if (!selectedText) {
      setSearchTextState('');
    }
  }, [selectedText]);

  const handleSearchSubmit = useCallback(() => {
    setSearchText(searchTextState);
    const action = ('search-' + searchType) as any; // TODO: will work better in TS 4.1
    trackAction(action, { text: searchTextState });
  }, [searchTextState, searchType, setSearchText]);

  const handleSearchChange = useCallback(
    (s: string) => {
      setSearchTextState(s);

      if (s === '') {
        setSearchText(undefined);
      }
    },
    [setSearchText]
  );

  const handleFacetChange = useCallback(
    (facetKey: FacetKey) => {
      return (value?: Option) => {
        if (!value) {
          resetFacet(facetKey);
          return;
        }
        setFacet(facetKey, value.value);
      };
    },
    [resetFacet, setFacet]
  );

  const handleCheckboxFacetChange = useCallback(
    (facetKey: FacetKey) => (checked: boolean) => checked ? setFacet(facetKey, 'true') : resetFacet(facetKey),
    [resetFacet, setFacet]
  );

  const facetCheckboxIsChecked = useCallback(
    (facetKey: FacetKey) => selectedFacets.some((f) => f.key === facetKey && f.value === 'true'),
    [selectedFacets]
  );

  const viewModel: SearchSidebarViewProps = {
    facetResults,
    textSearch: {
      searchText: searchTextState,
      handleSearchTextChange: handleSearchChange,
      handleSearchTextSubmit: handleSearchSubmit,
    },
    facetSelect: {
      selectedSchoolType,
      selectedFacets: onlyValueFacets,
      eduSystem,
      selectedCountry,
      handleFacetChange,
    },
    checkbox: {
      facetCheckboxIsChecked,
      handleCheckboxFacetChange,
    },
    hideFacets,
    hideText,
    hideCounts,
    searchType,
  };

  return <SearchSidebarView {...viewModel} />;
}
