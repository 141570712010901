import { EduSystem } from '@lessonup/teaching-core';
import classNames from 'classnames';
import _, { capitalize, partition } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FacetKey, FacetResult, SelectedFacet, SingleValueSelectedFacet } from '../../../../domain';
import useToggle from '../../../utils/useToggle';
import Checkbox from '../../Checkbox';
import FormField from '../../FormField';
import Heading from '../../Heading';
import Equalizer from '../../svgIcons/Equalizer';
import { Option } from '../facets/FacetSelect';
import FacetSelectWithOptions from '../facets/FacetSelectWithOptions';
import SearchForm from './SearchForm';
import './SearchSidebar.less';

const HIDDEN_ON_MOBILE = 'searchpage-hidden-on-small-displays';

const TRANSLATION_NAMESPACE_FACETS = 'facets';
const TRANSLATION_NAMESPACE_SEARCH_SIDEBAR = 'searchSidebar';

export type SearchType = 'lesson' | 'internet' | 'plan' | 'item';

export interface SearchSidebarViewProps {
  facetResults: FacetResult[];
  textSearch: {
    searchText?: string;
    initialText?: string;
    handleSearchTextChange: (s: string) => void;
    handleSearchTextSubmit: () => void;
  };
  checkbox: {
    facetCheckboxIsChecked: (facet: FacetKey) => boolean;
    handleCheckboxFacetChange: (facet: FacetKey) => (checked: boolean) => void;
  };
  facetSelect: {
    selectedSchoolType: string | undefined;
    selectedFacets: SingleValueSelectedFacet[];
    eduSystem: EduSystem.System;
    selectedCountry: string;
    handleFacetChange: (key: FacetKey) => (value: Option | undefined) => void;
  };
  hideFacets?: FacetKey[];
  hideText?: boolean;
  hideCounts?: boolean;
  searchType: SearchType;
  autoFocusSearchField?: boolean;
  sidebarClassName?: string | undefined;
}

const CHECKBOX_FACETS: FacetKey[] = ['isTest', 'isChannelLesson'];
// some facets make no sense if others are set
const HIDE_KEY_IF_VALUES_SELECTED_FACETS: Partial<Record<FacetKey, FacetKey>> = {
  isChannelLesson: 'channel',
};

function hideFacet(facet: FacetResult, selected: SelectedFacet[], hideFacets?: FacetKey[]) {
  const { key, hideIfEmpty, items } = facet;
  if (hideIfEmpty && items.length < 1) return true;
  if (HIDE_KEY_IF_VALUES_SELECTED_FACETS[key]) {
    return selected.some((k) => k.key === HIDE_KEY_IF_VALUES_SELECTED_FACETS[key]);
  }
  if (hideFacets && _.includes(hideFacets, key)) return true;
  return false;
}

export default function SearchSidebarView(props: SearchSidebarViewProps) {
  const { t } = useTranslation([TRANSLATION_NAMESPACE_FACETS, TRANSLATION_NAMESPACE_SEARCH_SIDEBAR]);
  const [showFiltersOnMobile, toggleShowFiltersOnMobile] = useToggle(false);

  const showFacets = useMemo(
    () => props.facetResults.filter((facet) => !hideFacet(facet, props.facetSelect.selectedFacets, props.hideFacets)),
    [props.facetResults, props.facetSelect.selectedFacets, props.hideFacets]
  );

  const [selectableFacets, checkboxFacets] = useMemo(
    () => partition(showFacets, (facet) => !CHECKBOX_FACETS.some((checkFacet) => facet.key === checkFacet)),
    [showFacets]
  );

  const { selectedSchoolType, selectedFacets, eduSystem, selectedCountry, handleFacetChange } = props.facetSelect;

  const renderFacetSelect = useCallback(
    (facetResult: FacetResult) => {
      return (
        <FacetSelectWithOptions
          facetResult={facetResult}
          selectedSchoolType={selectedSchoolType}
          selectedFacets={selectedFacets}
          eduSystem={eduSystem}
          country={selectedCountry}
          handleFacetChange={handleFacetChange(facetResult.key)}
          hideCounts={props.hideCounts}
          key={facetResult.key}
        />
      );
    },
    [eduSystem, handleFacetChange, props.hideCounts, selectedFacets, selectedSchoolType]
  );

  const renderTextSearchForm = () => {
    const { searchText, initialText, handleSearchTextChange, handleSearchTextSubmit } = props.textSearch;

    return props.hideText ? null : (
      <>
        <Heading as="h3" size="h2" className="search-sidebar__title">
          {t(`sidebar-title`, { ns: TRANSLATION_NAMESPACE_SEARCH_SIDEBAR })}
        </Heading>

        <SearchForm
          textValue={searchText}
          initialText={initialText}
          onSubmit={handleSearchTextSubmit}
          onTextChange={handleSearchTextChange}
          searchType={props.searchType}
          autoFocus={props.autoFocusSearchField}
        ></SearchForm>
      </>
    );
  };

  const renderCheckboxFacets = () => {
    if (!checkboxFacets || !checkboxFacets.length) return null;
    const { facetCheckboxIsChecked, handleCheckboxFacetChange } = props.checkbox;
    return (
      <div>
        <h3 className="search-sidebar__subtitle">{t('properties', { ns: TRANSLATION_NAMESPACE_SEARCH_SIDEBAR })}</h3>
        {checkboxFacets.map((facetResult) => (
          <FormField key={facetResult.key}>
            <Checkbox
              id={`facet-checkbox-${facetResult.key}`}
              checked={facetCheckboxIsChecked(facetResult.key)}
              onChange={handleCheckboxFacetChange(facetResult.key)}
              label={capitalize(t(`${facetResult.key}`))}
            ></Checkbox>
          </FormField>
        ))}
      </div>
    );
  };

  return (
    <div className={classNames('search-sidebar', props.sidebarClassName)}>
      {renderTextSearchForm()}

      <button
        className={classNames('search-sidebar__toggle-button', 'd-md-none')}
        onClick={() => toggleShowFiltersOnMobile()}
      >
        <span className="search-sidebar__toggle-button-icon-container">
          <Equalizer />
        </span>
        <span className="search-sidebar__toggle-button-text">{t(`refine`)}</span>
      </button>

      <form data-testid="facets-form" className="facets-form">
        <div className={classNames({ [HIDDEN_ON_MOBILE]: !showFiltersOnMobile })}>
          <h3 className={classNames('search-sidebar__subtitle', HIDDEN_ON_MOBILE)}>{t('refine')}</h3>

          <div>{selectableFacets.map(renderFacetSelect)}</div>

          {renderCheckboxFacets()}
        </div>
      </form>
    </div>
  );
}
