import classNames from 'classnames';
import React from 'react';
import './CheckListItem.less';

export default function CheckListItem({ text, size }: { text: string; size?: 'small' | 'default' }) {
  return (
    <li className="check-list-item">
      <div
        className={classNames('list-icon icon icon-checkmark-round-green', { 'icon-small': size === 'small' })}
      ></div>
      <span>{text}</span>
    </li>
  );
}
