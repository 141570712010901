import { useCallback, useEffect } from 'react';

const isBrowser = typeof window !== 'undefined';

export function useVisibilityChange(onChange: (visibilityState: DocumentVisibilityState) => void) {
  const handleVisibilityChange = useCallback(() => {
    onChange(document.visibilityState);
  }, []);

  useEffect(() => {
    if (!isBrowser) return;
    window.addEventListener('visibilitychange', handleVisibilityChange, { passive: true });
    return () => window.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [handleVisibilityChange]);
}
