import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { logger } from '@lessonup/client-integration';
import ActionButton from '../../../../shared-core/ui/components/Button';
import DefaultForm from '../../../../shared-core/ui/components/inputElements/DefaultForm';
import { Modal } from '../../../../shared-core/ui/components/modals/Modal';
import ModalButtonBar from '../../../../shared-core/ui/components/modals/ModalButtonBar';
import { Text } from '../../../../shared-core/ui/components/translations/Text/Text';
import { ModalProps } from '../../../../shared-core/ui/utils/ModalManagerService';
import { useAppServices } from '../../appServices/AppServicesContext';
import './FlagLessonModal.less';

export interface FlagLessonModalConfig {
  lessonId: string;
}

const TRANSLATION_NAMESPACE = 'flagModal';

function FlagLessonModal(props: ModalProps<undefined> & FlagLessonModalConfig) {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const { lesson } = useAppServices();
  const [comment, setComment] = useState<string | undefined>(undefined);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  function handleClose() {
    return props.closeModal(undefined);
  }

  async function onSubmit() {
    if (!comment) {
      return;
    }
    try {
      await lesson.flagLesson(props.lessonId, comment);
      setFormSubmitted(true);
    } catch (error) {
      logger.error(error);
    }
  }

  function modalContent() {
    return formSubmitted ? (
      <ConfirmMessage text={t('confirmation')} closeModal={handleClose} />
    ) : (
      <FlagLessonForm comment={comment} setComment={setComment} onSubmit={onSubmit} />
    );
  }

  return (
    <Modal title={t(formSubmitted ? 'confirmationTitle' : 'title')} isOpen onRequestClose={handleClose} width="medium">
      {modalContent()}
    </Modal>
  );
}

interface FlagLessonFormProps {
  comment: string | undefined;
  setComment: (c: string) => void;
  onSubmit: () => void;
}

const FlagLessonForm: React.FunctionComponent<FlagLessonFormProps> = ({ comment, setComment, onSubmit }) => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  return (
    <>
      <div className="flagmodal__header">
        <div className="flagmodal_headertext">
          <Text translateKey="description" options={{ ns: TRANSLATION_NAMESPACE }} />
        </div>
      </div>
      <br />
      <DefaultForm className="flagmodal-form" handleSubmit={onSubmit}>
        <textarea
          className="capsule-textarea-field capsule-sizing-default capsule-color-default"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={t('your-comment')}
          required
        />
        <ModalButtonBar layout="center">
          <ActionButton theme="primary" type="submit" role="submit">
            <Text translateKey="send" options={{ ns: TRANSLATION_NAMESPACE }} />
          </ActionButton>
        </ModalButtonBar>
      </DefaultForm>
    </>
  );
};

const ConfirmMessage: React.FunctionComponent<{ text: string; closeModal: (res: undefined) => void }> = (props) => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  function clickHandler(e: React.MouseEvent | React.TouchEvent) {
    props.closeModal(undefined);
  }

  return (
    <div>
      <div className="flagmodal__header">
        <div className="flagmodal_headertext">{props.text}</div>
      </div>
      <ModalButtonBar layout="center">
        <ActionButton theme="light" onClick={clickHandler}>
          {t('ok')}
        </ActionButton>
      </ModalButtonBar>
    </div>
  );
};

export default FlagLessonModal;
