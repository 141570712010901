import { trackAction } from '@lessonup/client-integration';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { teacherHome } from '../../../shared-core/services/app/teacherRoutes';
import PromoButton from '../../../shared-core/ui/components/buttons/PromoButton';
import { RedirectService } from '../../services/RedirectService';

import './LoginButtons.less';

const handleOpenWindow = (url: string) => {
  const screenX = typeof window.screenX !== 'undefined' ? window.screenX : window.screenLeft;
  const screenY = typeof window.screenY !== 'undefined' ? window.screenY : window.screenTop;
  const outerWidth = typeof window.outerWidth !== 'undefined' ? window.outerWidth : document.body.clientWidth;
  const outerHeight = typeof window.outerHeight !== 'undefined' ? window.outerHeight : document.body.clientHeight - 22;
  // XXX what is the 22?
  const width = 650;
  const height = 700;
  // Use `outerWidth - width` and `outerHeight - height` for help in
  // positioning the popup centered relative to the current window
  const left = screenX + (outerWidth - width) / 2;
  const top = screenY + (outerHeight - height) / 2;
  const features = `width=${width},height=${height}` + `,left=${left},top=${top},scrollbars=yes`;
  window.open(url, 'Login', features);
};

interface Props {
  action: 'login' | 'register';
  source?: 'page' | 'modal';
  referralToken?: string;
}

const GoogleButton = (props: PropsWithChildren<Props>) => {
  return (
    <PromoButton
      className="login google"
      imageUrl="/search/public/img/google.png"
      onClick={() => {
        // We misuse the redirect URL here to ensure users are directed to the teacher home page after logging in.
        if (!RedirectService.getRedirectUrl() && props.action === 'login' && props.source === 'page') {
          RedirectService.setRedirectUrl(teacherHome());
        }

        trackAction('sso-start', { provider: 'google', origin: props.action });
        handleOpenWindow(`/search/api/auth/google?referral_token=${props.referralToken}`);
      }}
    >
      {props.children}
    </PromoButton>
  );
};

const OfficeButton = (props: PropsWithChildren<Props>) => {
  return (
    <PromoButton
      className="login office"
      imageUrl="/search/public/img/office365.png"
      onClick={() => {
        // We misuse the redirect URL here to ensure users are directed to the teacher home page after logging in.
        if (!RedirectService.getRedirectUrl() && props.action === 'login' && props.source === 'page') {
          RedirectService.setRedirectUrl(teacherHome());
        }
        trackAction('sso-start', { provider: 'office', origin: props.action });
        handleOpenWindow(`/search/api/auth/start-office365${props.referralToken ? `/${props.referralToken}` : ''}`);
      }}
    >
      {props.children}
    </PromoButton>
  );
};

export function SingleSignOnButtons(props: Props) {
  const { t } = useTranslation('sso');

  return (
    <div className="sso-buttons">
      <GoogleButton action={props.action} referralToken={props.referralToken} source={props.source}>
        {t(props.action === 'login' ? 'loginGoogle' : 'registerGoogle')}
      </GoogleButton>
      <OfficeButton action={props.action} referralToken={props.referralToken} source={props.source}>
        {t(props.action === 'login' ? 'loginOffice' : 'registerOffice')}
      </OfficeButton>
    </div>
  );
}
