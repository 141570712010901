import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExplorerLessonContent } from '../../../../domain/newExplorers';
import { useBEM } from '../../../utils/hooks';
import ActionButton from '../../Button';
import PreviewThumbnail from '../../lesson/previewThumbnail/PreviewThumbnail';
import BackArrowSvg from '../../svgIcons/BackArrowSvg';
import ModalButtonBar from '../ModalButtonBar';
import { ExplorerContentPickerModalResponse } from './ExplorerContentPickerModal';
import './SelectedContentConfirmation.less';

type Props = {
  content: ExplorerLessonContent[];
  onConfirm: (result: ExplorerContentPickerModalResponse) => void;
  onCancel: () => void;
};

const SelectedContentConfirmation = (props: Props) => {
  const { t } = useTranslation('selectedContentConfirmation');
  const bemClasses = useBEM('selectedContentConfirmation');
  const lesson = props.content[0].content;

  return (
    <div className={bemClasses()}>
      <div className={bemClasses({ element: 'header' })} onClick={props.onCancel}>
        <BackArrowSvg />
        {t('title', { lesson: lesson.name })}
      </div>
      <div className={bemClasses({ element: 'body' })}>
        {lesson.thumbnail?.url && <PreviewThumbnail lesson={lesson} extraElement={false} />}

        <ModalButtonBar className={bemClasses({ element: 'actions' })}>
          <ActionButton
            height="small"
            theme="secondary"
            version="modern"
            onClick={() => props.onConfirm(props.content)}
          >
            {t('button')}
          </ActionButton>
        </ModalButtonBar>
      </div>
    </div>
  );
};

export default SelectedContentConfirmation;
