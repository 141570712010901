import React from 'react';

const FlagSvg = (props) => (
  <svg width={13} height={14} {...props}>
    <g fill="#EC484D" fillRule="nonzero">
      <path d="M0 .668h1.617v12.936H0zM2.425 2.285V9.56c3.234-3.234 7.276 3.234 10.51 0V2.285c-3.233 3.234-7.276-3.234-10.51 0z" />
    </g>
  </svg>
);

export default FlagSvg;
