import { LessonPin } from '@lessonup/teaching-core';
import { compactMap, Identifiable } from '@lessonup/utils';
import _ from 'lodash';
import { ContentImage, ContentImageData } from './ContentImage';
import { FacetMetaData } from './FacetMetaData';
import { IndexedContent } from './IndexedContent';
import { IndexedPinContent } from './IndexedPinContent';

export interface IndexedPin extends Identifiable {
  docType: 'pin';
  pin: LessonPin;
  hash: string;
  indexedContent: IndexedContent;
  metaData?: FacetMetaData;
  images?: ContentImageData;
}

export namespace IndexedPin {
  export function from(
    pin: LessonPin,
    hashFunc: (value: string) => string,
    lessonFacetMetaData?: FacetMetaData
  ): IndexedPin | undefined {
    // there are some pins that have a pin.item string instead of an object
    if (!_.isObject(pin.item)) {
      return undefined;
    }

    const indexed: IndexedPin = {
      _id: pin._id,
      docType: 'pin',
      pin,
      hash: hashFunc(hashableContent(pin)),
      indexedContent: IndexedPinContent.fromPins(pin),
      images: ContentImage.fromPin(pin),
    };

    if (lessonFacetMetaData) indexed.metaData = lessonFacetMetaData;

    return indexed;
  }

  /** returns content that should be hashed */
  export function hashableContent(pin: LessonPin): string {
    const noneArrayProps = [
      'item.image',
      'item.url',
      'item.name',
      'item.custom.text1',
      'item.custom.text2',
      'item.custom.question',
      'item.custom.answer1',
      'item.custom.answer2',
      'item.custom.answer3',
      'item.custom.answer4',
      'item.custom.word',
    ];

    const nonArrayValues = compactMap(noneArrayProps, (p) => {
      const value = _.get(pin, p);
      return _.isString(value) ? value : undefined;
    });

    const componentProps = ['type', 'settings.text', 'settings.title', 'settings.url', 'katex'];

    const componentValues = _.flatMap(pin.item.components, (c) => {
      return compactMap(componentProps, (p) => {
        const value = _.get(c, p);
        return _.isString(value) ? value : undefined;
      });
    });

    return nonArrayValues.concat(componentValues).join('|');
  }
}
