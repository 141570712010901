import { MathUtils } from './MathUtils';

export namespace TableComponentUtils {
  export function resizeColPositions(
    dpx: number,
    tableWidth: number,
    colPositions: number[],
    colIndex: number
  ): number[] {
    const minDistance = 4 * (100 / tableWidth);

    const neighborCols: { left: number; right: number } = {
      left: colPositions[colIndex - 1] || 0,
      right: colPositions[colIndex + 1] || 100,
    };
    const targetPosition: number = colPositions[colIndex] + dpx;
    // This makes the grippy stay between surrounding grippies
    const updatedPosition = keepPositionBetweenNeighbors(targetPosition, neighborCols, minDistance);
    const newColPositions = [...colPositions];
    newColPositions[colIndex] = updatedPosition;
    return newColPositions.map((num) => MathUtils.simpleRound(num, 3));
  }

  export function getColWidthValues(colPositions: number[]): number[] {
    // Convert new col positions to col widths in percentages
    const newColWidthData: number[] = [];
    let offset = 0;
    colPositions.forEach((colSize) => {
      newColWidthData.push(colSize - offset);
      offset = colSize;
    });
    newColWidthData.push(100 - offset);
    return newColWidthData;
  }

  function keepPositionBetweenNeighbors(
    targetPosition: number,
    neighborCols: { left: number; right: number },
    minDistance: number
  ): number {
    let updatedPosition = targetPosition;
    if (targetPosition < neighborCols.left + minDistance) updatedPosition = neighborCols.left + minDistance;
    if (targetPosition > neighborCols.right - minDistance) updatedPosition = neighborCols.right - minDistance;
    return updatedPosition;
  }
}
