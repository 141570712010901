import { Colors, QuizQuestion } from '@lessonup/teaching-core';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import { ContentTypeDefaults } from '../../../../utils/ContentTypeDefaults';
import { ImageUtils } from '../../../../utils/ImageUtils';
import './QuizAnswer.less';

export type Style = 'correct' | 'incorrect' | 'opinion' | 'default' | 'iscorrect';

interface QuizAnswerProps {
  answer: QuizQuestion.AnyAssignmentAnswerType;
  style?: Style;
  fontSize?: number;
  isLoading?: boolean;
  onClickHandler?: (key: QuizQuestion.AnswerValue) => void;
}

export default class QuizAnswer extends React.Component<QuizAnswerProps> {
  private renderAnswer() {
    const { answer, style, isLoading, onClickHandler } = this.props;
    const fontSize = this.props.fontSize || ContentTypeDefaults.defaultFontSizes['question'][2];

    return (
      <div
        className={classNames('answer', `answer${answer.value}`, style || 'default', {
          'answer-clickable': !!onClickHandler,
        })}
        data-role="select-answer"
        data-nr={answer.value}
        onClick={() => onClickHandler && onClickHandler(answer.value)}
      >
        <div className="inner">
          <div translate="no" className={'nr ' + (isLoading ? 'spinner-container' : '')}>
            {QuizQuestion.Content.getAnswerLetterByNumber(answer.value)}
          </div>
          {AnswerContent(answer, fontSize)}
        </div>
      </div>
    );
  }

  public render() {
    return this.renderAnswer();
  }
}

function AnswerContent(answer: QuizQuestion.AnyAssignmentAnswerType, fontSize: number | undefined) {
  switch (answer.type) {
    case 'audio':
      return AudioAnswer(answer as QuizQuestion.AnswerTypeAudio);
    case 'formula':
      return FormulaAnswer(answer as QuizQuestion.AnswerTypeFormula, fontSize);
    case 'text':
      return TextAnswer(answer as QuizQuestion.AnswerTypeText, fontSize);
    case 'image':
      return ImageAnswer(answer as QuizQuestion.AnswerTypeImage);
    default:
      return;
  }
}

function TextAnswer(answer: QuizQuestion.AnswerTypeText, fontSize: number | undefined) {
  return <div className={`answer-text font-size-${fontSize}`}>{answer.text}</div>;
}

function FormulaAnswer(answer: QuizQuestion.AnswerTypeFormula, fontSize: number | undefined) {
  return (
    <div
      data-role="formula"
      className={`answer-formula answer-guppy font-size-${fontSize}`}
      dangerouslySetInnerHTML={{ __html: answer.html }}
    ></div>
  );
}

function ImageAnswer(answer: QuizQuestion.AnswerTypeImage) {
  const zoomAlign = answer.value % 2 === 0 ? 'zoom-icon-right' : 'zoom-icon-left';
  const contentAlign = answer.value % 2 === 0 ? 'align-content-left' : 'align-content-right';

  return (
    <div className="background-image-fill-parent no-transform-on-fullscreen-image answer-image" data-role="image">
      <div
        className={`background-image-fill-parent contain no-margin-on-fullscreen-image non-comp-image comp-image component-image limit-image-width ${contentAlign}`}
        style={{ backgroundImage: ImageUtils.urlString(ImageUtils.checkImageUrl(answer.url)) }}
      >
        <img
          src="/search/public/img/icons/black/zoom-in.png"
          className={`answer-zoom-icon limit-to-parentsize hide-in-fullscreen hide-in-thumb" data-role="make-img-fullscreen ${zoomAlign}`}
        />
      </div>
    </div>
  );
}

function AudioAnswer(answer: QuizQuestion.AnswerTypeAudio) {
  const url = ImageUtils.checkImageUrl(answer.url);
  const audioStyle = _.includes(answer.color, '#') ? { backgroundColor: answer.color } : {};
  const darkClass = answer.color && Colors.needsDarkIcon(answer.color) ? 'icon-dark' : '';
  const alignmentClass = answer.value % 2 === 0 ? 'answer-audio-align-left' : 'answer-audio-align-right';

  return (
    <div
      data-component="audio"
      className={`answer-audio ${alignmentClass}`}
      data-role="play-pause-audio"
      data-audiourl={url}
    >
      <div className="border"></div>
      <div className={`core bg-${answer.color || ''}`} style={audioStyle}>
        <div className={`core-icon icon-playing-state answer-audio-image ${darkClass}`}></div>
      </div>
    </div>
  );
}
