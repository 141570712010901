import { Lesson } from '@lessonup/teaching-core';
import { AppError } from '@lessonup/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LessonPlan } from '../../../../domain';
import {
  Explorer,
  ExplorerContentSplit,
  ExplorerFolderContent,
  ExplorerId,
  ExplorerLessonContent,
  ExplorerLessonPlanContent,
  explorersToSharedExplorerContents,
  ExplorerSymbolicLinkContent,
  ExplorerUserContent,
  FolderWithNavigationData,
  getFolderContentFromExplorer,
  getFolderFromExplorer,
  Item,
  Location,
  splitExplorerContentsByType,
} from '../../../../domain/newExplorers';
import { assertNever } from '../../../../utils/TypescriptUtils';

export type ContentLoadingState = 'none' | 'inline' | 'blocking';

type FindContentResponse =
  | ExplorerLessonContent
  | ExplorerLessonPlanContent
  | ExplorerSymbolicLinkContent
  | FolderWithNavigationData
  | undefined;

export type FindContentFunction = (item: Item) => FindContentResponse;

const findInExplorersById = (explorers: Explorer[], id: ExplorerId) => explorers.find(({ _id }) => _id === id);

export const useContents = (
  rootExplorer: Explorer,
  initialExplorer: Explorer,
  location: Location,
  sharedExplorers: Explorer[],
  fetchLessonsAndPlansForLocation: (location: Location) => Promise<ExplorerUserContent[]>
): {
  contents: ExplorerContentSplit;
  explorer: Explorer;
  changeLessonPlanInContents;
  changeLessonInContents;
  isLoading: ContentLoadingState;
  addContentToExplorer: (content: ExplorerUserContent) => void;
  refetchContents: () => Promise<void>;
  findContentItemForItem: FindContentFunction;
} => {
  const [lessons, setLessons] = useState<ExplorerLessonContent[]>([]);
  const [lessonPlans, setLessonPlans] = useState<ExplorerLessonPlanContent[]>([]);
  const [links, setLinks] = useState<ExplorerSymbolicLinkContent[]>([]);
  const [folders, setFolders] = useState<ExplorerFolderContent[]>([]);

  const explorer = useMemo(() => {
    const initialExplorerIfCurrent = location.explorer === initialExplorer._id && initialExplorer;
    const rootExplorerIfCurrent = location.explorer === rootExplorer._id && rootExplorer;
    const currentExplorer =
      initialExplorerIfCurrent || rootExplorerIfCurrent || findInExplorersById(sharedExplorers, location.explorer);
    if (!currentExplorer) {
      throw new AppError('not-found', `Unable to find explorer set as current location`, {
        explorerId: location.explorer,
      });
    }
    setFolders(getFolderContentFromExplorer(currentExplorer, location.folder));
    return currentExplorer;
  }, [initialExplorer, sharedExplorers, location, rootExplorer]);

  const [isLoading, setIsLoading] = useState<ContentLoadingState>('inline');

  const updateLessonsAndPlansForLocation = useCallback(
    async (location) => {
      const {
        lessons: newLessons,
        lessonPlans: newLessonPlans,
        links: newLinks,
      } = splitExplorerContentsByType(await fetchLessonsAndPlansForLocation(location));
      setLinks(newLinks);
      setLessons(newLessons);
      setLessonPlans(newLessonPlans);
      setIsLoading('none');
    },
    [fetchLessonsAndPlansForLocation]
  );

  const refetchContents = useCallback(async () => {
    setIsLoading('blocking');
    await updateLessonsAndPlansForLocation(location);
  }, [location, updateLessonsAndPlansForLocation]);

  const changeLessonInContents = (lesson: Lesson): void => {
    setLessons((lessons) =>
      lessons.map((fromState) => (fromState.content._id === lesson._id ? { ...fromState, content: lesson } : fromState))
    );
  };

  const changeLessonPlanInContents = (plan: LessonPlan): void => {
    setLessonPlans((lessonPlans) =>
      lessonPlans.map((fromState) => (fromState.content._id === plan._id ? { ...fromState, content: plan } : fromState))
    );
  };

  const addContentToExplorer = useCallback(
    (content: ExplorerUserContent): void => {
      if (content.type === 'lesson') {
        setLessons((lessons) => [...lessons, content]);
      } else if (content.type === 'lessonPlan') {
        setLessonPlans((plans) => [...plans, content]);
      } else {
        setLinks((links) => [...links, content]);
      }
    },
    [setLinks, setLessons, setLessonPlans]
  );

  const findContentItemForItem = useCallback(
    (item: Item): FindContentResponse => {
      if (!item) return;
      switch (item.type) {
        case 'lesson':
          return lessons.find((lesson) => lesson.content._id === item.id);
        case 'symbolicLink':
          return links.find((link) => link.content._id === item.id);
        case 'lessonPlan':
          return lessonPlans.find((plan) => plan.content._id === item.id);
        case 'folder':
          try {
            return getFolderFromExplorer(explorer, item.id);
          } catch (error) {
            return undefined;
          }
        case 'sharedExplorer':
          return;
        default:
          assertNever(item, item);
      }
    },
    [lessons, links, lessonPlans, explorer]
  );

  const isRootFolder = location.folder === rootExplorer._id;
  const sharedExplorerContent = isRootFolder ? explorersToSharedExplorerContents(sharedExplorers) : [];

  const resetContent = () => {
    setLessons([]);
    setLessonPlans([]);
  };

  useEffect(() => {
    resetContent();
    setIsLoading('inline');
    updateLessonsAndPlansForLocation(location);
  }, [updateLessonsAndPlansForLocation, location]);

  const contents: ExplorerContentSplit = {
    folders,
    lessons,
    lessonPlans,
    sharedExplorers: sharedExplorerContent,
    links,
  };

  return {
    contents,
    explorer,
    changeLessonInContents,
    changeLessonPlanInContents,
    isLoading,
    addContentToExplorer,
    refetchContents,
    findContentItemForItem,
  };
};
