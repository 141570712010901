import { tracker } from '@lessonup/analytics';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { teacherTeachUrl } from '../../../../../shared-core/services/app/teacherRoutes';
import Button from '../../../../../shared-core/ui/components/Button';
import Heading from '../../../../../shared-core/ui/components/Heading';
import RocketSvg from '../../../../../shared-core/ui/components/logos/RocketSvg';
import { useBEM } from '../../../../../shared-core/ui/utils/hooks';
import { ImageUtils } from '../../../../../shared-core/utils/ImageUtils';
import { RegistrationFormProps } from '../../types';
import { useRegistrationContext } from '../../utils/RegistrationContext';
import './RegistrationDemoLessonPage.less';

type Props = RegistrationFormProps;

export const TRANSLATION_NAMESPACE = 'registrationDemoLessonPage';

export const RegistrationDemoLessonPage: React.FC<Props> = (props) => {
  const bemClasses = useBEM('RegistrationDemoLessonPage');
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const { demoLesson } = useRegistrationContext();

  useEffect(() => {
    tracker.events.onboardingLessonDemoPrompted();
  }, []);

  const imageUrl = ImageUtils.checkImageUrl(demoLesson?.thumbnail?.url);

  return (
    <div className={bemClasses()}>
      <div className={bemClasses({ element: 'col' })}>
        <div className={bemClasses({ element: 'description' })}>
          <Heading as="h1" className={bemClasses({ element: 'title' })}>
            {t('title')}
          </Heading>
          <p>{t('description')}</p>
        </div>
        <div className={bemClasses({ element: 'button-container' })}>
          <Button
            theme="secondary"
            height="medium"
            version="modern"
            onClick={
              demoLesson?.id
                ? () => window.open(teacherTeachUrl({ lessonId: demoLesson.id, source: 'onboarding-demo' }), '_self')
                : props.next
            }
          >
            {t('startLesson')}
          </Button>
          <Button theme="transparent" height="medium" version="modern" onClick={() => props.next()}>
            {t('skip')}
          </Button>
        </div>
        <div className={bemClasses({ element: 'extra-text' })}>
          <span>
            <Trans t={t} i18nKey="extraText" />
          </span>
        </div>
      </div>
      <div className={bemClasses({ element: 'col' })}>
        {imageUrl ? (
          <div className={bemClasses({ element: 'img-container' })} style={{ backgroundImage: `url(${imageUrl})` }} />
        ) : (
          <div className={bemClasses({ element: 'img-container', modifier: 'placeholder' })}>
            <RocketSvg className={bemClasses({ element: 'placeholder-svg' })} />
          </div>
        )}
      </div>
    </div>
  );
};
