import { Lesson, LessonContent, LessonPin } from '@lessonup/teaching-core';
import classNames from 'classnames';
import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import PinView from '../../../../shared-core/ui/components/pin/PinView';
import PlayIcon from '../../../../shared-core/ui/components/svgIcons/PlayIcon';
import './ExportLessonPageView.less';

export interface ExportLessonPageViewProps {
  lesson: Lesson | undefined;
  pins: LessonPin<LessonContent>[];
}

export const ExportLessonPageView: React.FC<ExportLessonPageViewProps> = (props: ExportLessonPageViewProps) => {
  const { lesson, pins } = props;

  // TODO: set notes from queryparams using from ssr.ts
  const [showNotes, setShowNotes] = useState(false);

  if (!lesson) return null;

  return (
    <div className={classNames('lesson-page-section', 'print-lesson-page-view')}>
      {pins.map((pin) => {
        const videoPin = pin.videoQuestion ? pin : undefined;
        const { type, url } = pin.item;

        const videoUrl =
          type === 'video' && url ? (
            <div className="slide-content video-url">
              <PlayIcon className="play-icon" />
              {url}
            </div>
          ) : null;

        return (
          <div className="slide-page" key={pin._id}>
            <div className="slide-wrapper" key={pin._id}>
              <PinView className="lesson-print-pin slide" pin={pin} hideFact={true} videoPin={videoPin} />
              {videoUrl}
            </div>
          </div>
        );
      })}
      {showNotes && <SlideNotes pins={pins} />}
    </div>
  );
};

interface SlideNotesProps {
  pins: LessonPin<LessonContent>[];
}

const SlideNotes: React.FC<SlideNotesProps> = ({ pins }: ExportLessonPageViewProps) => {
  const { t } = useTranslation('exportToPdf');
  const slidesCount = pins.length;

  const slidesWithNotes = pins
    .filter((pin) => {
      const text = pin.item.notes?.text;
      if (!text || text.length <= 0) return false;
      return true;
    })
    .map((pin) => pin.item.notes?.text);

  if (slidesWithNotes.length <= 0) return null;

  return (
    <div className="slide-notes">
      <h2>{t('notes')}</h2>
      {slidesWithNotes.map((note, index) => {
        const currentSlide = index + 1;

        return (
          <div className="slide-note" key={`slide-note-${index}`}>
            <h3>{`Note slide ${currentSlide}/${slidesCount}`}</h3>
            <div>{ReactHtmlParser(note)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ExportLessonPageView;
