import _, { isString, toNumber } from 'lodash';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { logger } from '@lessonup/client-integration';
import { allPinTypes, PinUtils } from '@lessonup/teaching-core';
import { AppError } from '@lessonup/utils';
import { showDefaultErrorNotification } from '../../../../shared-core/client/utils/modals';
import {
  Facets,
  isSelectedValueFacet,
  LanguageSingleton,
  paramsAreValidSearchItemParams,
  SearchParams,
} from '../../../../shared-core/domain';
import { searchItemRoute } from '../../../../shared-core/services/app/searchRoutes';
import { Option as DropdownOption, Select } from '../../../../shared-core/ui/components/Select';
import useScrollPage from '../../../../shared-core/ui/utils/useScrollPage';
import useToggle from '../../../../shared-core/ui/utils/useToggle';
import { IframeMessaging } from '../../../../shared-core/utils/IframeMessaging';
import { PinViewUtils } from '../../../../shared-core/utils/PinViewUtils';
import { PinSearchBottomBar } from '../../../components/navbars/pinSearchBars/PinSearchBottomBar';
import PinSearchTopBar from '../../../components/navbars/pinSearchBars/PinSearchTopBar';
import {
  deselectAllPinsAction,
  deselectPinsAction,
  fetchedPinResultsAction,
  selectPinsAction,
} from '../../../redux/actions/actions';
import { itemSearchForComponent, pinResults, selectedPins } from '../../../redux/selectors';
import { AppServices } from '../../../services/AppServices';
import { useSpinnerContext } from '../../../utils/loaders/SpinnerContext';
import { SearchContextProvider, useSearchContext } from '../sidebar/SearchContext';
import SearchForm from '../sidebar/SearchForm';
import { PinSearchLessonPageView } from './PinSearchLessonPage/PinSearchLessonPageView';
import PinSearchPageView from './PinSearchPageView';

type Props = {
  services: AppServices;
} & RouteComponentProps;

type PinSearchProps = {
  services: AppServices;
  fetching: boolean;
  lessonId?: string;
  afterPinId?: string;
  phase?: number;
};

export const PIN_SEARCH_TRANSLATION_NS = 'pinSearch';

const PinSearchWrapper: React.FunctionComponent<Props> = (props) => {
  const { services, location } = props;
  const dispatch = useDispatch();
  const pins = useSelector(pinResults());
  const [fetching, setFetching] = useState(false);
  const spinner = useSpinnerContext();
  const { scrollToTop } = useScrollPage();

  const searchItemParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  if (!paramsAreValidSearchItemParams(searchItemParams)) {
    console.log(searchItemParams);
    const error = new AppError('unexpected-data', 'Invalid params');
    showDefaultErrorNotification(error);
    throw error;
  }

  const [lessonId] = useState<string | undefined>(
    isString(searchItemParams.lessonId) ? searchItemParams.lessonId : undefined
  );
  const [afterPinId] = useState<string | undefined>(
    isString(searchItemParams.afterPinId) ? searchItemParams.afterPinId : undefined
  );
  const [phase] = useState<number | undefined>(toNumber(searchItemParams.phase));

  const searchParams = SearchParams.fromUrlComponents({
    urlParams: props.match.params,
    queryString: location.search,
    indexType: 'pins',
  });

  const fetch = useCallback(
    async (params: SearchParams, isPagination?: boolean) => {
      setFetching(true);
      if (!isPagination) {
        spinner.open(!!pins);
      }

      const res = await services.search.searchPins(params);

      dispatch(fetchedPinResultsAction(res));

      if (!isPagination) scrollToTop();
      spinner.close();
      setFetching(false);
    },
    [dispatch, services.search, pins, spinner]
  );

  const urlCreator = useCallback((params: SearchParams) => {
    const { queryString, urlDict } = SearchParams.searchParamsToUrlComponents(params);
    return searchItemRoute({
      ...urlDict,
      qs: queryString,
    });
  }, []);

  return (
    <SearchContextProvider
      service={services.search}
      fetchCallBack={fetch}
      indexType="pins"
      storeSearchParams={pins?.request}
      routeParams={searchParams}
      urlCreator={urlCreator}
    >
      <PinSearchPage
        fetching={fetching}
        services={services}
        lessonId={lessonId}
        afterPinId={afterPinId}
        phase={phase}
      />
    </SearchContextProvider>
  );
};

const PinSearchPage: React.FunctionComponent<PinSearchProps> = (props) => {
  const [page, setPage] = useState<'pins' | 'lesson'>('pins');
  const [textState, setTextState] = useState<string | undefined>(undefined);
  const [showSidebar, toggleSidebar] = useToggle(true);
  const [activeLesson, setActiveLesson] = useState<string | undefined>(undefined);
  const [activePin, setActivePin] = useState<string | undefined>(undefined);

  const { setFacet, resetFacet, setSearchText, selectedFacets: AllSelectedFacets, selectedText } = useSearchContext();
  const selectedFacets = AllSelectedFacets.filter(isSelectedValueFacet);

  const dispatch = useDispatch();
  const pins = useSelector(pinResults());
  const forComponent = useSelector(itemSearchForComponent());
  const selectedPinList = useSelector(selectedPins());

  const { t } = useTranslation(PIN_SEARCH_TRANSLATION_NS);

  const selectedLanguage = LanguageSingleton.get();

  useEffect(() => {
    if (!selectedText) setTextState('');
  }, [selectedText]);

  const handleSearchSubmit = useCallback(() => {
    setSearchText(textState);
  }, [setSearchText, textState]);

  const handleSearchChange = useCallback((s: string) => {
    setTextState(s);
    if (s === '') {
      setTextState(undefined);
    }
  }, []);

  const handleContenttypeDropdownChange = useCallback(
    (value?: DropdownOption) => {
      if (!value) return resetFacet('type');
      setFacet('type', value.value);
    },
    [resetFacet, setFacet]
  );

  const toggleSelectPin = async (pinId: string) => {
    if (selectedPinList.includes(pinId)) {
      dispatch(deselectPinsAction([pinId]));
    } else {
      dispatch(selectPinsAction([pinId]));
    }
  };

  const deselectAllPins = () => {
    dispatch(deselectAllPinsAction());
  };

  const getNewPinIds = async (): Promise<string[]> => {
    try {
      if (!props.lessonId) {
        return [];
      }

      const { newPinIds } = await props.services.lesson.addPins(props.lessonId, {
        afterPinId: props.afterPinId,
        phase: props.phase,
        pinIds: selectedPinList,
      });

      return newPinIds;
    } catch (error) {
      if ((error as AppError).code === 'not-allowed') {
        showDefaultErrorNotification(error);
        return [];
      }
      throw error;
    }
  };

  const handleAddPins = async () => {
    const newPinIds = await getNewPinIds();

    if (newPinIds?.length) {
      IframeMessaging.send('pin-added', _.last(newPinIds));
    }
  };

  const openPinInfo = (pinId: string) => {
    const pin = pins && pins.items.find((p) => p._id === pinId);
    if (!pin) {
      const error = new AppError('not-found', 'Tried to open a missing pin', { pinId });
      logger.error(error);
    }

    setActivePin(pinId);
    setActiveLesson(pin && pin.doc.pin.lesson);
    setPage('lesson');
  };

  const closePinInfo = () => {
    setActivePin(undefined);
    setActiveLesson(undefined);
    setPage('pins');
  };

  const typeFacet = Facets.find(pins, 'type');
  const imageFacet = Facets.find(pins, 'hasImage');
  const imageSearchMode = !!(imageFacet && imageFacet.selected);
  const defaultPinTypeOption: DropdownOption = { value: '', label: t('searchAllPinTypes') };

  const getPinTypeListForDropdown = (): DropdownOption[] => {
    const pinOptionList: DropdownOption[] = _.map(allPinTypes, (pinType) => {
      return {
        value: pinType,
        label: PinViewUtils.labelForPinType(pinType, selectedLanguage),
      };
    });

    return [...pinOptionList, defaultPinTypeOption];
  };

  const getSelectedPinTypeForDropdown = () => {
    const selectedPinType = selectedFacets.find((facet) => facet.key === 'type')?.value;

    if (selectedPinType && PinUtils.isPinType(selectedPinType)) {
      return {
        value: selectedPinType,
        label: PinViewUtils.labelForPinType(selectedPinType, selectedLanguage),
      };
    }
    return defaultPinTypeOption;
  };

  const TypeField = () => {
    if (imageSearchMode) {
      return (
        <div>
          <b>{t('common:image')}</b>
        </div>
      );
    }

    return typeFacet && forComponent ? (
      <div>
        <b>{getSelectedPinTypeForDropdown().label}</b>
      </div>
    ) : (
      typeFacet && (
        <Select
          id="pin-search-select-box"
          onChange={handleContenttypeDropdownChange}
          options={getPinTypeListForDropdown()}
          placeholder={t('searchAllPinTypes')}
          dropdownTheme={'lessonup-round'}
          defaultValue={getSelectedPinTypeForDropdown()}
        />
      )
    );
  };

  return (
    <div>
      <PinSearchTopBar>
        <div className="topbar-facets">
          <span className="topbar-facets-text">{t('searchIntro')}</span>
          {TypeField()}
          <span className="topbar-facets-text">{t('searchAbout')}</span>
          <SearchForm
            onSubmit={handleSearchSubmit}
            onTextChange={handleSearchChange}
            placeholder={t('subjectPlaceholder')}
          />
        </div>
      </PinSearchTopBar>
      {page === 'pins' ? (
        <PinSearchPageView
          showSidebar={showSidebar}
          toggleSelectPin={toggleSelectPin}
          selectedPins={selectedPinList}
          openPinInfo={openPinInfo}
          fetching={props.fetching}
          imageSearch={imageSearchMode}
        />
      ) : (
        <PinSearchLessonPageView
          pinId={activePin}
          lesson={activeLesson}
          closePinInfo={closePinInfo}
          togglePinSelectionState={toggleSelectPin}
          selectedPins={selectedPinList}
        />
      )}
      {!imageSearchMode && (
        // FIXME: Don't show button when user is not allowed to add pins?
        <PinSearchBottomBar
          selectedCount={selectedPinList.length}
          handleSubmit={handleAddPins}
          deselectAllPins={deselectAllPins}
        />
      )}
    </div>
  );
};

export default PinSearchWrapper;
