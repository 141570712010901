import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MongoUser, UserSharedExplorer } from '../../../../domain';
import {
  Explorer,
  ExplorerContentType,
  ExplorerUserContent,
  Item,
  Location,
  SharedExplorer,
  sharedExplorerToItem,
} from '../../../../domain/newExplorers';
import { useBEM } from '../../../utils/hooks';
import { ModalProps } from '../../../utils/ModalManagerService';
import ActionButton from '../../Button';
import LinkButton from '../../links/LinkButton';
import { CondensedExplorer, SelectionContextProvider, useContents } from '../../newExplorer';
import { ExplorerPickerBaseProps } from '../explorerPickers';
import { Modal } from '../Modal';
import ModalButtonBar from '../ModalButtonBar';
import './LocationPickerModal.less';

export type LocationPickerModalResponse = Location | undefined;
export interface LocationPickerModalParams extends ExplorerPickerBaseProps {
  rootExplorer: Explorer;
  initialExplorer: Explorer;
  sharedExplorers: SharedExplorer[];
  fetchLessonsAndPlansForLocation: (location: Location) => Promise<ExplorerUserContent[]>;
  handleCreateFolder: (location: Location) => void;
  user: MongoUser;
  startLocation?: Location;
  translationKey?: string;
  titleItemCount?: number;
  disabledRows?: Item[];
  enabledTypes?: ExplorerContentType[];
  hideRootExplorerButton?: boolean;
  disableReadOnlyExplorers?: boolean;
  setActiveExplorer?: (explorerId: string) => void;
}
type Props = ModalProps<LocationPickerModalResponse> & LocationPickerModalParams;

export const LocationPickerModal: React.FC<Props> = (props) => {
  return (
    <SelectionContextProvider>
      <LocationPickerModalInner {...props} />
    </SelectionContextProvider>
  );
};

const isAReadonlySharedExplorer = UserSharedExplorer.isAReadonlySharedExplorer;

export const LocationPickerModalInner: React.FC<Props> = ({
  rootExplorer,
  initialExplorer,
  startLocation,
  sharedExplorers,
  fetchLessonsAndPlansForLocation,
  handleCreateFolder,
  closeModal,
  user,
  translationKey,
  titleItemCount,
  disabledRows,
  enabledTypes,
  hideRootExplorerButton,
  disableReadOnlyExplorers,
  setActiveExplorer,
}) => {
  const { t } = useTranslation(translationKey || 'locationPickerModal');
  const bemClasses = useBEM('LocationPickerModal');

  const [location, setLocation] = useState<Location>(
    startLocation || { explorer: initialExplorer._id, folder: initialExplorer._id }
  );

  const { contents, explorer: currentExplorer } = useContents(
    rootExplorer,
    initialExplorer,
    location,
    sharedExplorers,
    fetchLessonsAndPlansForLocation
  );

  const changeLocation = (location: Location) => {
    setLocation(location);
    setActiveExplorer?.(location.explorer);
  };

  const onSubmit = () => {
    closeModal(location);
  };

  const onCreateFolder = async () => {
    await handleCreateFolder(location);
  };

  const enabledTypesIncludingReadOnlyExplorers =
    disableReadOnlyExplorers && isAReadonlySharedExplorer(currentExplorer, user) ? [] : enabledTypes;

  const sharedExplorersWithoutWriteAccess = sharedExplorers.filter(
    (sharedExplorer) => !UserSharedExplorer.canUserAccessSharedExplorer(user, sharedExplorer, 'write')
  );
  const sharedExplorerItemsWithoutWriteAccess = sharedExplorersWithoutWriteAccess.map(sharedExplorerToItem);

  const disabledRowsIncludingReadOnlyExplorers = disableReadOnlyExplorers
    ? [...(disabledRows || []), ...sharedExplorerItemsWithoutWriteAccess]
    : disabledRows;

  const title = t('title', { count: titleItemCount });

  return (
    <Modal className={bemClasses()} title={title} isOpen onRequestClose={() => closeModal(undefined)} width="large">
      <CondensedExplorer
        className={bemClasses({ element: 'explorer' })}
        contents={contents}
        rootExplorer={rootExplorer}
        currentExplorer={currentExplorer}
        location={location}
        onLocationChange={changeLocation}
        disabledRows={disabledRowsIncludingReadOnlyExplorers}
        enabledTypes={enabledTypesIncludingReadOnlyExplorers}
        hideRootExplorerButton={hideRootExplorerButton}
      />
      <ModalButtonBar className={bemClasses({ element: 'actions', className: 'margin-top-4' })}>
        <LinkButton
          className={bemClasses({ element: 'create-folder-button' })}
          onClick={onCreateFolder}
          text={t('createFolderButton')}
          bgColor="transparent"
        ></LinkButton>
        <ActionButton
          className={bemClasses({ element: 'pick-folder-button' })}
          onClick={onSubmit}
          height="small"
          theme="secondary"
          version="modern"
        >
          {t('pickFolderButton')}
        </ActionButton>
      </ModalButtonBar>
    </Modal>
  );
};
