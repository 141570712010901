import { schooltypeHasLevels } from '@lessonup/teaching-core';
import React from 'react';
import { DefaultUrlFacets, FacetSearchResponse, LanguageSingleton, SearchParams } from '../../../../shared-core/domain';
import * as FacetUtils from '../../../../shared-core/utils/FacetUtils';

interface Props {
  results: FacetSearchResponse<any> | undefined;
  urlCreator: (params: SearchParams) => string;
}

/**
 * This component generates URLs for google or other crawlers to follow
 * SEO crawlers cant deal with the dropdown facets in the sidebar, they do read links
 * This component renders a list of urls off screen to guide the crawler
 */
const SearchHiddenUrls: React.FunctionComponent<Props> = ({ results, urlCreator }) => {
  if (!results) return null;
  const order = SearchParams.urlOrder;
  const dict = SearchParams.toDictionary(results.request);
  const { country, schoolType } = dict;

  // we want google to walk the line, we get strange results if we start in the middle
  const nextMissing = order.findIndex((k) => !dict[k]);
  const afterNext = order.slice(nextMissing + 1).findIndex((k) => dict[k]);
  if (nextMissing === -1 || afterNext > -1) return null;
  const nextMissingKey = order[nextMissing];

  // skip levels if there are none
  const nextKey: DefaultUrlFacets | undefined =
    nextMissingKey === 'levels' && !schooltypeHasLevels(country, schoolType) ? 'years' : nextMissingKey;
  const nextResult = results.facets.find((f) => f.key === nextKey);
  // if we the last item, we want to stop, otherwise we keep going forever
  if (!nextKey || !nextResult || dict[order[order.length - 1]]) return null;

  const options = FacetUtils.validOptionsForFacetResult({
    country,
    schoolType,
    result: nextResult,
    language: LanguageSingleton.get(),
  });

  if (!options || !options.length) return null;
  const getUrl = (o: FacetUtils.Option) => {
    const url = urlCreator({
      ...results.request,
      facets: [...results.request.facets, { key: nextKey, value: o.value.toString() }],
    });
    const path = url.split('?')[0];
    return path;
  };

  return (
    <div
      /** off screen */
      style={{
        position: 'absolute',
        left: '-9999px',
      }}
    >
      {options.map((o) => (
        <a key={o.value} href={getUrl(o)}>
          {o.label}
        </a>
      ))}
    </div>
  );
};

export default React.memo(SearchHiddenUrls);
