import classNames from 'classnames';
import React from 'react';
import TooltipView, { TooltipViewProps } from './TooltipView';

type Props = TooltipViewProps & { hasInteractiveContent?: boolean };

const PassiveTooltip: React.FunctionComponent<Props> = (props) => {
  const { children, customClassNames, hasInteractiveContent, ...tooltipProps } = props;

  return (
    <div className={classNames('passive-tip-wrapper', hasInteractiveContent && 'interactive-content')}>
      <TooltipView {...tooltipProps} customClassNames={classNames(['tooltip--hover', customClassNames])}>
        {props.children}
      </TooltipView>
    </div>
  );
};

export default PassiveTooltip;
