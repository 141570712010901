import React from 'react';

const ArrowRight = (props) => (
  <svg viewBox="0 0 9 12" {...props}>
    <path
      d="M1.514.909l7.057 4.234a1 1 0 010 1.714l-7.057 4.234A1 1 0 010 10.234V1.766A1 1 0 011.514.91z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowRight;
