import { LessonPin } from '@lessonup/teaching-core';
import { RegistrationPurposeType } from '@lessonup/utils';
import { Country } from '../country/Country';
import { UnitedStates } from '../country/UnitedStates';
import { LanguageKey } from '../language';
import { ProductWithSource } from '../products';
import { Licenses } from './Licenses';
import { MongoUser, MongoUserAccountOrganization, MongoUserActiveDocument, MongoUserProfile } from './MongoUser';

// TODO TimM, change this
export interface AccessDefinition {
  id: string;
  role: 'Admin' | 'member';
}

export interface UserContext {
  _id: string;
  name: string;
  products: ProductWithSource[];
  licenseStatus: Licenses.Status;
  licenseExpires: Licenses.ExpiryDate | undefined;
  acceptedTerms: boolean;
  organizations: MongoUserAccountOrganization[];
  sharedFolders: {
    owner: string[];
    member: string[];
  };
  profile: MongoUserProfile | undefined;
  followedChannels?: string[];
  activeDocument?: MongoUserActiveDocument;
  activeLesson:
    | {
        _id: string;
        name: string;
        firstPin: LessonPin | undefined;
      }
    | undefined;
  school?: {
    _id: string;
    slug: string;
  };
  purpose?: RegistrationPurposeType;
  language: LanguageKey;
  country?: Country.CountryCode;
  USState?: UnitedStates.StateCode;
  /** @deprecated */
  'skipConfirm-pressedTeach'?: 0 | 1;
  featureFlags?: string[];
  roles?: ('admin' | 'labs')[];
  ambassadorSince?: Date;
  createdAt?: Date;
  type: 'student' | 'teacher';
}

export function isUsercontext(user: MongoUser | UserContext | undefined): user is UserContext {
  return !!user && 'licenseStatus' in user;
}

export function getAmbassadorSince(user: UserContext): Date | undefined {
  return user.ambassadorSince;
}
