import React from 'react';
import { useDispatch } from 'react-redux';
import { productSiteRoot } from '../../../shared-core/services/app/siteRoutes';
import { clearGlobalErrorAction } from '../../redux/actions/actions';
import { ServerErrorDetails } from '../../redux/reducers/errorReducer';
import ErrorMessage from './ErrorMessage';
import './ErrorPage.less';

interface ErrorPageViewProps {
  error: ServerErrorDetails;
}

export const ServerErrorPage: React.FC<ErrorPageViewProps> = ({ error }) => {
  const dispatch = useDispatch();

  const handleClicked = () => {
    dispatch(clearGlobalErrorAction());
  };

  return (
    <div className="error-container">
      <ErrorMessage
        type={'page'}
        code={error.statusCode}
        backUrl={productSiteRoot()}
        backUrlIsSearchExternal
        onBackButtonClick={handleClicked}
        serverError={error}
      />
    </div>
  );
};
