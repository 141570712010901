import { Video } from '@lessonup/teaching-core';
import React from 'react';
import { LessonPlan } from '../../../shared-core/domain';
import './css/LessonPlanVideo.less';

type Props = {
  child: LessonPlan.Children.Video;
};

const LessonPlanVideo: React.FC<Props> = ({ child }) => {
  const renderYoutubeVideo = (child: LessonPlan.Children.Youtube) => {
    const youtubeLink = `https://www.youtube-nocookie.com/embed/${child.youtubeId}?rel=0`;

    return (
      <div className="youtube-wrapper">
        <div className="youtube-thumb" style={{ backgroundImage: `url(${child.image})` }}>
          <iframe
            title={youtubeLink}
            className="youtube-embed"
            src={youtubeLink}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    );
  };

  const renderVimeoVideo = (child: LessonPlan.Children.Vimeo) => {
    const vimeoUrl = Video.vimeoEmbedUrlForId(child.vimeoId, false) + '?color=ffffff';
    return (
      <>
        <div className="vimeo-wrapper" style={{ backgroundImage: `url(${child.image})` }}>
          <iframe
            src={vimeoUrl}
            className="vimeo-embed"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </>
    );
  };

  return (
    <div className="plan-video lesson-plan-video">
      {child.type === 'youtube' ? renderYoutubeVideo(child) : renderVimeoVideo(child)}
    </div>
  );
};

export default LessonPlanVideo;
