import React from 'react';
import { useTranslation } from 'react-i18next';
import { LessonPlan } from '../../../shared-core/domain';
import { ImageUtils } from '../../../shared-core/utils/ImageUtils';
import './css/LessonPlanLink.less';

type Props = {
  child: LessonPlan.Children.Link;
};

const LessonPlanLink: React.FC<Props> = ({ child }) => {
  const { t } = useTranslation('lessonPlanLink');

  return (
    <div className="lesson-plan-link">
      <div className="plan-link">
        <a href={child.url} className="browser" target="_blank" rel="noopener noreferrer" data-role="open-url">
          <div className="browserbar">
            <div className="browser-buttons">
              <div className="browser-button c1"></div>
              <div className="browser-button c2"></div>
              <div className="browser-button c3"></div>
            </div>
            <div className="url">{child.url}</div>
          </div>
          <div
            className="webpage"
            style={{ backgroundImage: ImageUtils.urlString(ImageUtils.checkImageUrl(child.image)) }}
          >
            <div className="glass"></div>
            <div className="plan-button open-url">
              <span className="icon icon-external"></span>
              {t('openNewTab')}
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default LessonPlanLink;
