import { Country } from '../country/Country';
import { Locales } from '../locales';

export namespace Currency {
  export const isoCurrenciesLowerCased = ['eur', 'usd', 'gbp'] as const;
  export type ISOCurrencyLowerCased = typeof isoCurrenciesLowerCased[number];

  export const defaultCurrency: ISOCurrencyLowerCased = 'eur';

  export function centsCurrencyUnits(cents: number) {
    return cents / 100;
  }

  export function currencyUnitsToCents(amount: number) {
    return Math.round(amount * 100);
  }

  export function isValidCurrency(currency: string | undefined | null): currency is ISOCurrencyLowerCased {
    return !!currency && ['eur', 'usd', 'gbp'].includes(currency);
  }

  export function currencyForCountry(country: Country.CountryCode | undefined) {
    if (!country) return;
    const currency = ['nl', 'eu'].includes(Country.getCountryTier(country))
      ? 'eur'
      : ['gb'].includes(Country.getCountryTier(country))
      ? 'gbp'
      : 'usd';
    return currency;
  }

  /**
   * Format amount of money including currency symbol
   */
  export function amountAndCurrencySymbol(
    amount: number,
    currency: ISOCurrencyLowerCased,
    locale: Locales.IsoKey,
    formatOptions: Intl.NumberFormatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  ): string {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency.toLocaleUpperCase(),
      ...formatOptions,
    });
    return formatter.format(amount);
  }
}
