import _ from 'lodash';
import { AnyAction } from '../actions/actions';

export type SelectPinState = string[];

const initialState: SelectPinState = [];

export function selectPinReducer(state: SelectPinState = initialState, action: AnyAction): SelectPinState {
  switch (action.type) {
    case 'selectPins':
      return state.concat(action.pinIds);
    case 'deselectPins':
      return state.filter((p) => !_.includes(action.pinIds, p));
    case 'deselectAllPins':
      return [];
    default:
      return state;
  }
}
