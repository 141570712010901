import { TeacherPin } from '@lessonup/pin-renderer';
import { LessonPin } from '@lessonup/teaching-core';

export interface PinsState {
  pins: LessonPin[];
  pinCount: number;
  teacherPins: TeacherPin[];
  isV2Lesson: boolean;
}

const initialState: PinsState = { pins: [], pinCount: 0, teacherPins: [], isV2Lesson: false };
// ssr only reducer, if you need it, fix it
export const pinStateReducer = (state: PinsState = initialState): PinsState => state;
