import { IndexedLesson } from '../../../shared-core/domain';
import { FacetSearchResponse } from '../../../shared-core/domain/search';
import { AnyAction } from '../actions/actions';

export interface LessonSearchState {
  results: FacetSearchResponse<IndexedLesson> | undefined;
  clusterId: string | undefined;
  forLessonId: string | undefined;
}

const initialState: LessonSearchState = {
  results: undefined,
  clusterId: undefined,
  forLessonId: undefined,
};

export function clusterReducer(state: LessonSearchState = initialState, action: AnyAction): LessonSearchState {
  switch (action.type) {
    case 'fetchedClusterResult':
      const { clusterId, response, forLessonId } = action;
      return {
        results: response.result,
        clusterId,
        forLessonId,
      };
    default:
      return state;
  }
}
