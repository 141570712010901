import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';

export const useRowSelection = <T>(
  initialSelection: T[] = []
): {
  selectedRows: T[];
  toggleRow: (row: T) => void;
  setSelectedRows: React.Dispatch<React.SetStateAction<T[]>>;
  overRideIfNotPartOfSelection: (row: T) => void;
} => {
  const [selectedRows, setSelectedRows] = useState<T[]>(initialSelection);

  const toggleRow = (row: T) => {
    if (selectedRows.find((selectedRow) => isEqual(selectedRow, row))) {
      removeRow(row);
    } else {
      addRow(row);
    }
  };

  const addRow = (row: T) => {
    setSelectedRows([row, ...selectedRows]);
  };

  const removeRow = (row: T) => {
    const selectionWithoutRow = selectedRows.filter((selectedRow) => !isEqual(selectedRow, row));
    setSelectedRows(selectionWithoutRow);
  };

  const overRideIfNotPartOfSelection = useCallback(
    (row: T) => {
      setSelectedRows((pref) => {
        if (!pref.some((r) => isEqual(r, row))) {
          return [row];
        }
        return pref;
      });
    },
    [setSelectedRows]
  );

  return { selectedRows, toggleRow, setSelectedRows, overRideIfNotPartOfSelection };
};
