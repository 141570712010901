import React from 'react';
import { Country } from '../../../../../../../shared-core/domain/country/Country';
import { IndexedSchoolSuggestion } from '../../../../../../../shared-core/domain/schoolSuggestions/IndexedSchoolSuggestion';
import SchoolSelect from '../../../../../../../shared-core/ui/components/pickers/SchoolSelect/SchoolSelect';
import { useAppServices } from '../../../../../../components/appServices/AppServicesContext';
import useFeatureFlags from '../../../../../../utils/featureFlags/useFeatureFlags';

interface SchoolSuggestorProps {
  countryCode?: Country.CountryCode;
  onSelect: (school: IndexedSchoolSuggestion) => void;
  onSetManualEntry: (val: string) => void;
  hasError: boolean;
  invalidateError: () => void;
  onChange?: (school: IndexedSchoolSuggestion | undefined) => void;
}

export const SchoolSuggestor: React.FC<SchoolSuggestorProps> = (props) => {
  const services = useAppServices();
  const { isFeatureFlagEnabled } = useFeatureFlags();

  function getSuggestions(params: { countryCode: Country.CountryCode; query: string }) {
    return services.schoolPicker.getSchoolPickerSuggestions({
      ...params,
    });
  }

  return (
    <SchoolSelect
      countryCode={props.countryCode}
      onSubmit={props.onSelect}
      getSuggestions={getSuggestions}
      onSetManualEntry={props.onSetManualEntry}
      hasError={props.hasError}
      invalidateError={props.invalidateError}
      onChange={props.onChange}
    />
  );
};
