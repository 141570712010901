import * as React from 'react';

interface Props {
  color?: string;
  width?: number;
  height?: number;
}

const CloseCrossIcon: React.FC<Props> = ({ color, width, height }) => (
  <svg viewBox={`0 0 ${width} ${height}`} width={`${width}px`} height={`${height}px`} fill="none">
    <path
      d="M14 1.41 12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7 14 1.41Z"
      fill={color || '#5A4D4C'}
    />
  </svg>
);

export default CloseCrossIcon;
