import React from 'react';
import { useSelector } from 'react-redux';
import { SearchParams } from '../../../shared-core/domain';
import { lessonSearchRoute } from '../../../shared-core/services/app/searchRoutes';
import { ErrorDetails } from '../../redux/reducers/lessonReducer';
import { lessonSearchQuery, loggedInUser } from '../../redux/selectors';
import ErrorMessage from './ErrorMessage';
import './ErrorPage.less';

interface ErrorPageViewProps {
  type: 'page' | 'channel' | 'lesson' | 'lessonPlan' | 'embedLesson';
  backUrl?: string;
  code?: 404 | 403 | 500;
  voucher?: boolean;
  backToLesson?: string;
  errorDetails?: ErrorDetails;
}

const ErrorPageView: React.FC<ErrorPageViewProps> = ({ type, backUrl, code, voucher, backToLesson, errorDetails }) => {
  const currentUser = useSelector(loggedInUser());
  const searchQuery = useSelector(lessonSearchQuery());
  const { queryString, urlDict } = SearchParams.searchParamsToUrlComponents(searchQuery);
  const backLink = lessonSearchRoute({
    ...urlDict,
    qs: queryString,
  });

  return (
    <div className="error-container">
      <ErrorMessage
        currentUser={!!currentUser}
        type={type}
        backUrl={backUrl || backLink}
        lesson={['lesson', 'embedLesson'].includes(type)}
        code={code}
        voucher={voucher}
        backToLesson={backToLesson}
        errorDetails={errorDetails}
      />
    </div>
  );
};

export default ErrorPageView;
