import React from 'react';
// https://github.com/smooth-code/svgr

const BackArrowSvg = (props) => (
  <svg width={14} height={14} viewBox="0 0 12 9" {...props}>
    <g stroke="currentColor" fill="currentColor">
      <path d="M13 5H3M6 1L2 5l4 4" />
    </g>
  </svg>
);

export default BackArrowSvg;
