import { Language } from '../language';

export namespace Locales {
  const keys = ['nl', 'en'] as const;

  /**
   * @deprecated - Locales are comprised of both a language and a country, this key does not allow to distinguish between British and American date formatting for instance.
   */
  export type Key = typeof keys[number];

  // Format: ISO-639-1 (language) - ISO-3166-1 alpha-2 (country)
  const isoKeys = ['nl-NL', 'en-GB'] as const;
  export type IsoKey = typeof isoKeys[number];
  const isoKeyForKeyDict: { [key in Key]: IsoKey } = {
    nl: 'nl-NL',
    // Using en-GB (instead of en-US) because we are switching business focus there since start of 2022
    en: 'en-GB',
  };

  export const defaultLocale: IsoKey = guesstimateLocaleFromLanguage(Language.defaultLanguage);

  type LanguageDictionary<T> = {
    [K in Key]: T;
  };

  // get a typed version of what you want, and fill for all languages
  export function forLocale<T>(dict: LanguageDictionary<T>, key: Key): T {
    return dict[key];
  }

  export function guesstimateLocaleFromLanguage(language: Language.Key): IsoKey {
    if (language === 'nl') return 'nl-NL';
    return 'en-GB';
  }

  export function keyToIsoKey(key: Key): IsoKey {
    return isoKeyForKeyDict[key];
  }
}
