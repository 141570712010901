import { Lesson } from '@lessonup/teaching-core';
import _ from 'lodash';
import { LessonPlan } from '../lessonPlan/LessonPlan';

export namespace Auth {
  export interface AccessRulable {
    usersWithAccess: string[];
  }

  export function validateLoginPasswordRequirements(password: string): boolean {
    // This checks for login passwords, since we changed the password requirements later,
    // users will still have passwords with the old requirements
    return _.isString(password) && password.length >= 6;
  }

  export function validateRegistrationPasswordRequirements(password: string): boolean {
    // This regex requires at least 1 uppercase and 1 lowercase letter
    const hasAtLeastOneUpperCaseAndLowerCaseLetter = /^(?=.*[a-z])(?=.*[A-Z]).*$/;
    return _.isString(password) && password.length >= 8 && hasAtLeastOneUpperCaseAndLowerCaseLetter.test(password);
  }
}

// follow meteor standards
export type ClientHashedPassword = {
  algorithm: 'sha-256';
  digest: string;
};

export type StampedLoginToken = {
  token: string;
  when: Date;
};

export type HashedLoginToken = {
  hashedToken: string;
  when: Date;
};

export type ClientSimplePasswordCredentials = {
  email: string;
  password: string;
  loginSource?: 'standalone' | 'modal';
};

export type MeteorPassword = string | ClientHashedPassword;

export namespace Auth {
  export function isAccessibleForUser(ar: Auth.AccessRulable, userId: string): boolean {
    return _.some(ar.usersWithAccess, (id) => id == userId);
  }

  export function isLessonAccessibleForUser(
    lesson: Lesson,
    userId: string | undefined,
    userSharedExplorers: string[] | undefined,
    userHasProducts: boolean,
    plan?: LessonPlan
  ): boolean {
    if (!userId && lesson.privacy === 'private') {
      return false;
    }
    if (userId === lesson.user) {
      return true;
    }

    if (lesson.products && lesson.products.length && !_.includes(lesson.products, 'free')) {
      return userHasProducts;
    } else if (lesson.privacy !== 'private') {
      return true;
    } else if (lesson.location) {
      return _.includes(userSharedExplorers, lesson.location?.explorer);
    } else if (plan?.location) {
      return _.includes(userSharedExplorers, plan.location.explorer);
    } else if (lesson.sharedExplorer) {
      return _.includes(userSharedExplorers, lesson.sharedExplorer);
    }

    return false;
  }
}
