import React from 'react';
import { useTranslation } from 'react-i18next';
import { MongoUser } from '../../../../domain';
import ActionButton from '../../Button';
import { useFormInput } from '../../inputElements/useFormInput';

const TRANSLATION_NAMESPACE = 'followForm';

export interface FormParams {
  name?: string;
  email: string;
  firstName: string;
  lastName: string;
  organization: string;
  city: string;
  visitorType: string;
}

interface Props {
  channelTitle?: string;
  submitHandler: (params: FormParams) => Promise<void>;
}

export default function FollowChannelForm(props: Props) {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  const [firstName, firstNameInput, validateFirstName] = useFormInput({
    autoComplete: 'firstName',
    type: 'text',
    placeholder: t('firstName'),
    requiredMsg: t('firstNameError'),
  });

  const [lastName, lastNameInput, validateLastName] = useFormInput({
    autoComplete: 'lastName',
    type: 'text',
    placeholder: t('lastName'),
    requiredMsg: t('lastNameError'),
  });

  const [email, emailInput, validateEmail] = useFormInput({
    autoComplete: 'email',
    type: 'email',
    placeholder: t('email'),
    requiredMsg: t('emailError'),
    validator: (email) => Boolean(email && MongoUser.validateEmail(email)),
  });

  const [organization, organizationInput, validateOrganization] = useFormInput({
    autoComplete: 'organization',
    type: 'text',
    placeholder: t('organization'),
    requiredMsg: t('organizationError'),
  });

  const [city, cityInput, validateCity] = useFormInput({
    autoComplete: 'city',
    type: 'text',
    placeholder: t('city'),
    requiredMsg: t('cityError'),
  });

  const [visitorType, visitorTypeInput, validateVisitorType] = useFormInput({
    autoComplete: 'visitorType',
    type: 'text',
    placeholder: t('visitorType'),
    requiredMsg: t('visitorTypeError'),
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const valid = [
      validateFirstName(),
      validateLastName(),
      validateEmail(),
      validateOrganization(),
      validateCity(),
      validateVisitorType(),
    ].every((v) => v);

    if (!valid || !email || !firstName || !lastName || !organization || !city || !visitorType) return;

    props.submitHandler({
      email,
      firstName,
      lastName,
      organization,
      city,
      visitorType,
    });
  };

  return (
    <form autoComplete="on" className="default-form default-form--full" onSubmit={handleSubmit}>
      <p className="modal-title">{t('require-follow-text')}</p>
      {emailInput}
      {firstNameInput}
      {lastNameInput}
      {organizationInput}
      {cityInput}
      {visitorTypeInput}
      <p className="modal-bottom-text">
        {props.channelTitle ? t('formPolicyWithName', { channelName: props.channelTitle }) : t('formPolicy')}
      </p>
      <br />
      <ActionButton theme="light" type="submit">
        {t('confirm')}
      </ActionButton>
    </form>
  );
}
