import React from 'react';
import { useTranslation } from 'react-i18next';
import { forgotPasswordRoute } from '../../../../shared-core/services/app/searchRoutes';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import { SingleSignOnButtons } from '../../../components/login/LoginButtons';
import PasswordLogin from '../../../components/login/PasswordLogin';

const LoginForm: React.FC<{}> = (props) => {
  const { user } = useAppServices();
  const { t } = useTranslation('login');
  function handleForgotPassword() {
    window.open(forgotPasswordRoute(), '_parent');
  }

  return (
    <div className="login-options">
      <PasswordLogin
        loginWithPassword={user.loginWithPassword}
        handleForgotPassword={handleForgotPassword}
        source="standalone"
      />
      <div className="login__page-divider" />
      <div>
        <h3>{t('ssoHeader')}</h3>
        <SingleSignOnButtons action="login" source="page" />
      </div>
    </div>
  );
};

export default LoginForm;
