import { Lesson } from '@lessonup/teaching-core';
import { AppError, IsomorphicLogger } from '@lessonup/utils';
import _ from 'lodash';
import { assertNever } from '../../utils';
import { Channel } from '../channels';
import { LessonPlan } from '../lessonPlan';
import { canUserAccessExplorer, Explorer, folderOrParentsHaveProduct } from '../newExplorers';
import { Licenses, MongoUser } from '../user';
import { UserContentUtils } from './UserContent';

export namespace UserContentAuth {
  export type Document = Lesson | LessonPlan;

  function isLessonPlan(document: Document): document is LessonPlan {
    return 'children' in document;
  }

  /**
   * @fetchComplete fetch the whole document from the backend
   * @fetchCoverData fetch enough data from the backend to draw a preview
   * @edit edit the document
   * @copyLocal Copy locally (in a explorer)
   * @copySearch Copy from search or channel
   * @editProducts Edit the Products on a document
   */
  export const defaultAction = [
    'fetchComplete',
    'edit',
    'fetchCoverData',
    'copyLocal',
    'copySearch',
    'delete',
  ] as const;
  export const publisherAction = ['editProducts'] as const;
  const lessonSpecificAction = ['teach', 'shareWithStudents', 'test', 'report', 'differentiate'] as const;

  export type DefaultAction = typeof defaultAction[number];
  type PublisherAction = typeof publisherAction[number];
  type LessonSpecificAction = typeof lessonSpecificAction[number];

  export const lessonActions = [...defaultAction, ...publisherAction, ...lessonSpecificAction] as const;
  export type LessonAction = typeof lessonActions[number];

  export const planActions = [...defaultAction, ...publisherAction] as const;
  export type PlanAction = typeof planActions[number];

  export type AnyAction = LessonAction | PlanAction;

  export type LessonActionCheckResponse<A extends LessonAction> = { [key in A]: boolean };
  export type PlanActionCheckResponse<A extends LessonAction> = { [key in A]: boolean };

  export type Response = {
    allowed: boolean;
    noAccessToProduct?: boolean;
    updateCache?: boolean;
    proFromChannel?: boolean;
  };

  const notAllowed: Response = { allowed: false };
  const notAllowedProduct: Response = { allowed: false, noAccessToProduct: true };
  const allowed: Response = { allowed: true };

  interface BaseActionCheckerParams {
    user: MongoUser | undefined;
    explorer?: Explorer;
    userProducts?: string[];
    userPublisherProducts?: string[];
  }

  export interface LessonActionCheckerParams extends BaseActionCheckerParams {
    document: Lesson;
    action: LessonAction;
    channelRights?: Channel.Rights;
    plan: LessonPlan | undefined;
  }

  interface LessonPlanActionCheckerParams extends BaseActionCheckerParams {
    document: LessonPlan;
    action: PlanAction;
  }

  type LessonActionChecker = (params: LessonActionCheckerParams) => Response;
  type LessonPlanActionChecker = (params: LessonPlanActionCheckerParams) => Response;

  export type DocumentUserRights = {
    fromProducts: ProductRights;
    documentUserAccess: DocumentUserAccess;
    hasProductPublishRights: boolean;
    missingExtendedRights: boolean;
  };

  export const documentUserAccessOptions = ['owner', 'noAccess', 'public'] as const;
  export type DocumentUserAccess = typeof documentUserAccessOptions[number];

  function isOwner(documentUserAccess: DocumentUserAccess): boolean {
    return documentUserAccess === 'owner';
  }

  function privateForUser(documentUserAccess: DocumentUserAccess): boolean {
    return documentUserAccess === 'noAccess';
  }

  export const productRightsOptions = ['noAccess', 'access', 'noProduct'] as const;
  export type ProductRights = typeof productRightsOptions[number];

  function userCannotAccessProduct(productRights: ProductRights): boolean {
    return productRights === 'noAccess';
  }

  function userHasAccessToProductLesson(productRights: ProductRights): boolean {
    return productRights === 'access';
  }

  export const userRightsOptions = ['isPro', 'isPaid', 'none'] as const;
  export type UserRights = typeof userRightsOptions[number];

  function isPro(userRights: UserRights): boolean {
    return userRights === 'isPro' || userRights === 'isPaid';
  }

  function isPaid(userRights: UserRights): boolean {
    return userRights === 'isPaid';
  }

  export class AuthChecker {
    public constructor(
      private readonly logger: IsomorphicLogger,
      private documentCacheOutdatedCallBack?: (type: 'lesson' | 'lessonPlan', id: string, right: Channel.Rights) => void
    ) {}

    public isLessonActionAllowed: LessonActionChecker = ({
      document: lesson,
      user,
      action,
      userProducts = [],
      userPublisherProducts = [],
      channelRights = 'none',
      plan,
      explorer,
    }) => {
      this.validateDocumentProductsAreAccurate(lesson, plan, explorer);
      const userRights = getUserRights(user);
      const documentUserRights = getDocumentUserRights(
        plan || lesson,
        user,
        userProducts,
        userPublisherProducts,
        explorer
      );

      const copySearchIfProtected = action === 'copySearch' && lesson.privacy === 'protected';
      if (copySearchIfProtected) return { allowed: false };

      const channelGivesProFeatures = () => this.channelGivesProFeatures(lesson, channelRights);
      const hasProFeatures = () => this.hasProFeatures(userRights, documentUserRights, channelGivesProFeatures);

      return responseIsLessonActionAllowed({
        action,
        userRights,
        documentUserRights,
        channelGivesProFeatures,
        hasProFeatures,
      });
    };

    public isPlanActionAllowed: LessonPlanActionChecker = ({
      document: contentDoc,
      user,
      action,
      userProducts = [],
      userPublisherProducts = [],
      explorer,
    }) => {
      this.validateDocumentProductsAreAccurate(contentDoc, undefined, explorer);
      const documentUserRights = getDocumentUserRights(contentDoc, user, userProducts, userPublisherProducts, explorer);

      const copySearchIfProtected = action === 'copySearch' && contentDoc.privacy === 'protected';
      if (copySearchIfProtected) return { allowed: false };

      return responseIsPlanActionAllowed({ action, documentUserRights });
    };

    private hasProFeatures(
      userRights: UserRights,
      { fromProducts }: DocumentUserRights,
      channelGivesProFeatures: () => Response
    ) {
      if (userHasAccessToProductLesson(fromProducts) || isPro(userRights)) return { allowed: true };
      return channelGivesProFeatures();
    }

    public isPublisherAction(action: AnyAction) {
      return _.includes(publisherAction, action);
    }

    public channelGivesProFeatures(document: Document, right: Channel.Rights): Response {
      const documentCache = document.origin?.rights;
      let updateCache = false;
      if ((Channel.givesRights(documentCache) || Channel.givesRights(right)) && documentCache !== right) {
        updateCache = true;
        this.documentCacheOutdatedCallBack?.(isLessonPlan(document) ? 'lessonPlan' : 'lesson', document._id, right);
      }
      if (right !== 'givesProFeatures') return { allowed: false, updateCache };
      // channel lesson or document unchanged
      const channelRights = UserContentUtils.documentGivesChannelRights(document);
      const allowed = Boolean(document.channel || channelRights);
      return { allowed, proFromChannel: allowed };
    }

    private documentProductsAreAccurate(
      document: Document,
      plan: LessonPlan | undefined,
      explorer: Explorer | undefined
    ): boolean {
      const planIsForDocument = plan && (document as Lesson).plan === plan._id;
      const planHasValidProducts =
        plan && planIsForDocument && hasProducts(plan) && this.documentProductsAreAccurate(plan, undefined, explorer);
      const locationOrParentsHaveProducts =
        document.location && explorer && folderOrParentsHaveProduct(explorer, document.location.folder);

      return (
        !hasProducts(document) || document.productsLocked || planHasValidProducts || !!locationOrParentsHaveProducts
      );
    }

    private validateDocumentProductsAreAccurate(
      document: Document,
      plan: LessonPlan | undefined,
      explorer: Explorer | undefined
    ): void {
      if (!this.documentProductsAreAccurate(document, plan, explorer)) {
        // TODO: This situation should never happen, so we should throw here, but at the moment we don't know how many issues there are already, so there is a log now to not bother the users too much for now.
        this.logger.error(
          new AppError('unexpected-data', 'Document has invalid products', {
            lessonId: document._id,
            planId: plan?._id,
            explorerId: explorer?._id,
          })
        );
      }
    }
  }

  export const responseIsPlanActionAllowed = ({
    action,
    documentUserRights,
  }: {
    action: PlanAction;
    documentUserRights: DocumentUserRights;
  }) => {
    switch (action) {
      case 'edit':
      case 'fetchCoverData':
      case 'fetchComplete':
      case 'copyLocal':
      case 'copySearch':
      case 'delete':
        return isDefaultActionAllowed(documentUserRights, action);
      case 'editProducts':
        return isPublisherActionAllowed(documentUserRights, action);
      default:
        assertNever(action, 'unknown action isPlanActionAllowed');
    }
  };

  export const responseIsLessonActionAllowed = ({
    action,
    userRights,
    documentUserRights,
    channelGivesProFeatures,
    hasProFeatures,
  }: {
    action: LessonAction;
    userRights: UserRights;
    documentUserRights: DocumentUserRights;
    channelGivesProFeatures: () => Response;
    hasProFeatures: () => Response;
  }) => {
    switch (action) {
      case 'edit':
      case 'fetchCoverData':
      case 'fetchComplete':
      case 'copyLocal':
      case 'copySearch':
      case 'delete':
        return isDefaultActionAllowed(documentUserRights, action);
      case 'editProducts':
        return isPublisherActionAllowed(documentUserRights, action);
      case 'report':
        return hasProFeatures();
      case 'shareWithStudents':
        return canShareWithStudents(documentUserRights, hasProFeatures);
      case 'teach':
        return canTeach(documentUserRights);
      case 'test':
        return canTest(userRights, documentUserRights, channelGivesProFeatures);
      case 'differentiate':
        return hasProFeatures();
      default:
        assertNever(action, 'unknown action isLessonActionAllowed');
    }
  };

  export const isDefaultActionAllowed = (
    { fromProducts, documentUserAccess, missingExtendedRights }: DocumentUserRights,
    action: DefaultAction
  ): Response => {
    switch (action) {
      case 'edit':
        if (userCannotAccessProduct(fromProducts)) return notAllowedProduct;
        return { allowed: isOwner(documentUserAccess) };
      case 'delete':
        return { allowed: isOwner(documentUserAccess) };
      case 'fetchCoverData':
        if (userCannotAccessProduct(fromProducts)) {
          // we allow partial fetch for lessons that the user copies once and now does not have access to anymore
          return { allowed: isOwner(documentUserAccess) };
        }
        return { allowed: !privateForUser(documentUserAccess) };
      case 'fetchComplete':
      case 'copyLocal':
      case 'copySearch':
        if (userCannotAccessProduct(fromProducts) || missingExtendedRights) {
          return notAllowedProduct;
        }
        return { allowed: !privateForUser(documentUserAccess) };
      default:
        assertNever(action, 'unknown action isActionAllowed');
    }
  };

  const isPublisherActionAllowed = (
    { documentUserAccess, hasProductPublishRights }: DocumentUserRights,
    action: PublisherAction
  ): Response => {
    switch (action) {
      case 'editProducts':
        if (!hasProductPublishRights) return notAllowedProduct;
        return { allowed: isOwner(documentUserAccess) };
      default:
        assertNever(action, 'unknown action isActionAllowed');
    }
  };

  const canShareWithStudents = (
    { fromProducts, documentUserAccess }: DocumentUserRights,
    hasProFeatures: () => Response
  ) => {
    if (privateForUser(documentUserAccess)) return notAllowed;
    if (userCannotAccessProduct(fromProducts)) return notAllowedProduct;
    return hasProFeatures();
  };

  const canTeach = ({ fromProducts, documentUserAccess }: DocumentUserRights) => {
    if (privateForUser(documentUserAccess)) return notAllowed;
    if (userCannotAccessProduct(fromProducts)) return notAllowedProduct;
    return allowed;
  };

  const canTest = (
    userRights: UserRights,
    { fromProducts, documentUserAccess }: DocumentUserRights,
    channelGivesProFeatures: () => Response
  ) => {
    if (privateForUser(documentUserAccess)) return notAllowed;
    if (userCannotAccessProduct(fromProducts)) return notAllowedProduct;
    if (
      userHasAccessToProductLesson(fromProducts) ||
      (isOwner(documentUserAccess) && isPro(userRights)) ||
      isPaid(userRights)
    ) {
      return allowed;
    }
    return channelGivesProFeatures();
  };

  /**
   * Any rights the user has itself, unrelated to documents or products
   */
  const getUserRights = (user: MongoUser | undefined): UserRights => {
    return Licenses.isPaid(user) ? 'isPaid' : Licenses.isPro(user) ? 'isPro' : 'none';
  };

  /**
   * Check the accessability of the document for the user
   */
  export const getDocumentUserRights = (
    document: Document,
    user: MongoUser | undefined,
    userProducts: string[],
    userPublisherProducts: string[],
    explorer: Explorer | undefined
  ): DocumentUserRights => {
    const documentHasProduct = hasProducts(document);
    const userCannotAccessProduct = !!documentHasProduct && !userCanAccessProduct(document, userProducts);
    const userHasAccessToProductLesson = !!documentHasProduct && !userCannotAccessProduct;
    const isOwner = isUserOwner(document, explorer, user);
    const privateForUser = isPrivateForUser(document, user, explorer);
    const needsExtendedRights = needsExtendedRightsForDoc(document, user, isOwner);
    const missingExtendedRights = needsExtendedRights && !(userHasAccessToProductLesson || Licenses.isPaid(user));
    logMissingParams(explorer, isOwner);

    const fromProducts: ProductRights = documentHasProduct
      ? userCannotAccessProduct
        ? 'noAccess'
        : 'access'
      : 'noProduct';

    const hasProductPublishRights = documentHasProduct ? userCanAccessProduct(document, userPublisherProducts) : true;

    const documentUserAccess: DocumentUserAccess = isOwner ? 'owner' : privateForUser ? 'noAccess' : 'public';

    return {
      fromProducts,
      documentUserAccess,
      hasProductPublishRights,
      missingExtendedRights,
    };
  };

  export const hasProducts = (document: Document): boolean => {
    return !!document.products && document.products.length > 0;
  };

  export const userCanAccessProduct = (document: Document, products: string[]): boolean => {
    return !!document.products && document.products.some((pId) => products.includes(pId));
  };

  const isUserOwner = (document: Document, explorer?: Explorer, user?: MongoUser): boolean => {
    if (!user) return false;
    if (document.user === user._id) {
      return true;
    }
    if (explorer) {
      return canUserAccessExplorer(user, explorer, 'write');
    }
    return false;
  };

  const isPrivateForUser = (document: Document, user?: MongoUser, explorer?: Explorer): boolean => {
    if (document.privacy !== 'private') return false;
    if (!user) return true;

    if (explorer) {
      return !canUserAccessExplorer(user, explorer, 'read');
    }

    if (document.user === user._id) return false;
    return true;
  };

  /**
    Tests need a paid access check to even fetch the document
  */
  const needsExtendedRightsForDoc = (document: Document, user: MongoUser | undefined, isOwner: boolean): boolean => {
    if ('isTest' in document && document.isTest) {
      // is we are not free and we own the lesson, than allow everything
      if (!user || Licenses.isFree(user)) return true;
      return !isOwner;
    }
    return false;
  };

  const logMissingParams = (explorer: Explorer | undefined, isOwner: boolean) => {
    if (!explorer) {
      console.warn('MISSING EXPLORER, CANT DO VALID AUTH', { isOwner });
    }
  };
}
