import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Heading from '../../../shared-core/ui/components/Heading';
import { useAppServices } from '../../components/appServices/AppServicesContext';
import ForgotPasswordEmailForm from '../../components/ForgotPasswordForm/ForgotPasswordForm';
import GrayPageLayout, {
  GrayPageLayoutTitle,
  GrayPageSection,
  GrayPageSubtitle,
} from '../GrayPageLayout/GrayPageLayout';
import './ForgotPasswordPage.less';

const TRANSLATION_NS = 'forgotPassword';

type RecoverPasswordMethod = 'magicLink' | 'resetPassword';
interface Props {}

const ForgotPasswordPage: React.FC<Props> = (props) => {
  const { language } = useParams<{ language: string }>();
  const [completed, setCompleted] = useState(false);
  const { t } = useTranslation(TRANSLATION_NS);
  const { user } = useAppServices();

  function handleSubmit(email: string, type: RecoverPasswordMethod) {
    return type === 'magicLink'
      ? user.requestMagicLink(email, language, 'genericLoginLink').then(() => setCompleted(true))
      : user.requestPasswordReset(email).then(() => setCompleted(true));
  }

  return (
    <GrayPageLayout className="forgot-password-page">
      <GrayPageSection position="top">
        <div>
          <GrayPageLayoutTitle>{capitalize(t('forgotPasswordTitle'))}</GrayPageLayoutTitle>
          <GrayPageSubtitle>{t('forgotPasswordSubtitle')}</GrayPageSubtitle>
        </div>
      </GrayPageSection>
      <GrayPageSection position="center">
        {completed ? <CompletedForm /> : <ForgotPasswordForms handleSubmit={handleSubmit} />}
      </GrayPageSection>
      <GrayPageSection position="bottom"></GrayPageSection>
    </GrayPageLayout>
  );
};

interface ForgotPasswordFormsPropsf {
  handleSubmit: (email: string, type: RecoverPasswordMethod) => void;
}

const ForgotPasswordForms: React.FC<ForgotPasswordFormsPropsf> = ({ handleSubmit }) => {
  const { t } = useTranslation(TRANSLATION_NS);
  return (
    <div className="login-options forgot-password">
      <ForgotPasswordEmailForm
        headerText={t('magicLinkTitle')}
        description={t('magicLinkDescription')}
        onSubmit={(email: string) => handleSubmit(email, 'magicLink')}
        className="registration-container-half--first spread-content"
      />
      <div className="login__page-divider" />
      <ForgotPasswordEmailForm
        headerText={t('requestPasswordResetTitle')}
        description={t('requestPasswordResetDescription')}
        onSubmit={(email: string) => handleSubmit(email, 'resetPassword')}
        className="registration-container-half--last spread-content"
      />
    </div>
  );
};

const CompletedForm: React.FC = () => {
  const { t } = useTranslation(TRANSLATION_NS);
  return (
    <div className="completed-message">
      <Heading size="h2" as="h2">
        {t('email-sent-title')}
      </Heading>
      <p>{t('email-sent-description')}</p>
    </div>
  );
};
export default ForgotPasswordPage;
