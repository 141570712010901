import { AppError } from '@lessonup/utils';

// see https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OmitByType<T, K> = Pick<T, Exclude<keyof T, keyof K>>;
export type Nullable<T> = { [P in keyof T]: T[P] | null };
export type Diff<T, U> = T extends U ? never : T;
export type UnionSubSet<T, U extends T> = U;

// All keys of union type
export type KeysOfUnion<T> = T extends any ? keyof T : never;

export type ValidateStructure<T, Struct> = T extends Struct
  ? Exclude<keyof T, keyof Struct> extends never
    ? T
    : never
  : never;

export type NonOptionalKeys<T> = { [k in keyof T]-?: undefined extends T[k] ? never : k }[keyof T];
export type OptionalKeys<T> = Exclude<keyof T, NonOptionalKeys<T>>;

export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

// helper function to force switchmaps to be complete
export function assertNever(x: never, label: string): never {
  throw new AppError('unexpected-data', `Missing configuration label from type`, { label, type: x });
}

export function valideNotNil<T>(value: T): asserts value is NonNullable<T> {
  if (!value) throw new Error('Invalid data');
}

// map to property name or never
type MapKeys<A, B> = {
  [K in keyof A & keyof B]: A[K] extends B[K] ? K : never;
};
// remove all the nevers
type ValidKeys<A, B> = MapKeys<A, B>[keyof A & keyof B];
// map to the original types
export type Overlap<A, B> = {
  [K in ValidKeys<A, B>]: A[K];
};

export type ValueOfConst<T> = T[keyof T];

export function stringIsOfUnionType<T extends string>(string: string | undefined, list: T[]): string is T {
  return !!string && list.includes(string as T);
}

export function stringIsOfUnionTypeOrUndefined<T extends string>(
  string: string | undefined,
  list: T[]
): string is T | undefined {
  if (typeof string === 'undefined') return true;
  return stringIsOfUnionType(string, list);
}

export function isPrimitive(val: unknown): boolean {
  return val == null || /^[sbn]/.test(typeof val);
}

export function mutable<T extends string>(list: readonly T[]) {
  // convert the readonly array to a mutable copy.
  return [...list];
}

/**
 * Returns Object entries with typed keys
 * Only use if you are sure you have no unknown keys, could lead to bugs
 * */
export function objectEntriesTyped<A extends string | number | symbol, B>(record: Partial<Record<A, B>>): [A, B][] {
  return Object.entries(record) as unknown as [A, B][];
}

/**
 * We dont want JQuery in the Shared core.
 * This is a base Event that has the most impotant intersection
 * Feel free to add types when needed,
 * but check in a meteor application for build errors
 */
export type JQuerySaveBaseEvent = Pick<
  Event,
  'stopPropagation' | 'preventDefault' | 'target' | 'currentTarget' | 'type'
>;
