import { trackAction } from '@lessonup/client-integration';
import { Button } from '@lessonup/ui-components';
import { History } from 'history';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { loginRoute } from '../../../../shared-core/services/app/searchRoutes';
import capitalize from '../../../../shared-core/utils/capitalize';
import { AppServices } from '../../../services/AppServices';
import { routeIsAccountPage } from '../../../utils/routeUtils/routeUtils';
import { AppServicesConsumer } from '../../appServices/AppServicesContext';

interface Props {
  history: History;
}

export default function LoginNavButton(props: Props) {
  const { t } = useTranslation('navbar');

  async function handleOpenClick(services: AppServices) {
    trackAction('login-start-search');
    if (routeIsAccountPage(props.history.location.pathname)) {
      props.history.push(loginRoute(undefined));
      return;
    }

    services.modals.open('login', {
      userService: services.user,
    });
  }

  return (
    <AppServicesConsumer
      render={(services) => {
        return (
          <Button buttonType="secondary" onClick={handleOpenClick.bind(null, services)}>
            {capitalize(t('login'))}
          </Button>
        );
      }}
    />
  );
}
