import { AppError } from '@lessonup/utils';

export interface SharedModalsProvider {
  showDefaultErrorNotification(error?: Error | AppError): void;
}

let modalProvider: SharedModalsProvider | undefined;
export const registerModalProvider = (service: SharedModalsProvider): void => {
  modalProvider = service;
};

export const showDefaultErrorNotification = (error?: Error | AppError): void => {
  if (!modalProvider) throw new AppError('missing-config', 'SharedModalsProvider is not registered', error);
  modalProvider?.showDefaultErrorNotification(error);
};
