import {
  Explorer,
  ExplorerId,
  ExplorerPermission,
  ExplorerUserSettings,
  isSharedExplorer,
  isSharedTrashExplorer,
  permissionIsLowerOrEqualTo,
  SharedExplorer,
} from '../newExplorers/Explorer';
import { MongoUser, MongoUserInOrganization } from './MongoUser';

export namespace UserSharedExplorer {
  export type Role = 'member' | 'owner';

  export function isOwner(sharedExplorerId: string, user: MongoUser | undefined): boolean {
    return MongoUser.sharedFolders(user).owner.includes(sharedExplorerId);
  }

  export function isMember(sharedExplorerId: string, user: MongoUser | undefined): boolean {
    return MongoUser.sharedFolders(user).member.includes(sharedExplorerId);
  }

  export function hasAccess(sharedExplorerId: string, user: MongoUser | undefined): boolean {
    const { owner, member } = MongoUser.sharedFolders(user);
    return owner.concat(member).includes(sharedExplorerId);
  }

  export const canUserAccessSharedExplorer = (
    user: MongoUser,
    explorer: SharedExplorer,
    permission: ExplorerPermission
  ): boolean => {
    if (UserSharedExplorer.canUserAccessOrganizationSharedExplorer(user, explorer, permission)) return true;
    if (permission === 'write') {
      return isOwner(explorer.mainExplorer, user);
    }
    return hasAccess(explorer.mainExplorer, user);
  };

  export function hasAdminAccess(user: MongoUser, explorer: Explorer): boolean {
    if (MongoUser.isAdmin(user)) return true;

    const isOrganizationAdmin = (explorer.organizations || []).some((org) =>
      MongoUser.isAdminOfOrganization(user, org.id)
    );

    return isOrganizationAdmin;
  }

  export function canUserAccessOrganizationSharedExplorer(
    user: MongoUser,
    sharedExplorer: SharedExplorer,
    permission: ExplorerPermission
  ): boolean {
    const organizationList = sharedExplorerOrganizationList(user);
    return sharedExplorer.organizations.some((organization) => {
      if (!organizationList.includes(organization.id) || !organization.permission) {
        return false;
      }
      return permissionIsLowerOrEqualTo(permission, organization.permission);
    });
  }

  export function sharedExplorerOrganizationList(user: MongoUser): string[] {
    const organizations = MongoUser.memberOfOrganizationList(user);
    return organizations.map((o) => o.id);
  }

  export const isAReadonlySharedExplorer = (explorer: Explorer, user: MongoUser): boolean =>
    (isSharedExplorer(explorer) || isSharedTrashExplorer(explorer)) &&
    !canUserAccessSharedExplorer(user, explorer, 'write');

  export const permissionsToRoles = (permissions: (ExplorerPermission | undefined)[]): (Role | undefined)[] =>
    permissions.map((permission) => (permission === 'write' ? 'owner' : permission === 'read' ? 'member' : undefined));

  export const explorerPermissionForUser = (user: MongoUser, explorerId: ExplorerId): ExplorerPermission | undefined =>
    user.account?.explorerOwner?.includes(explorerId)
      ? 'write'
      : user.account?.explorerMember?.includes(explorerId)
      ? 'read'
      : undefined;

  export const getMemberSettings = (
    members: MongoUserInOrganization[],
    sharedExplorerId: ExplorerId
  ): ExplorerUserSettings[] => {
    return members.map((member) => ({
      id: member._id,
      permission: explorerPermissionForUser(member, sharedExplorerId),
    }));
  };
}
