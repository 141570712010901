import React from 'react';
import { ExplorerIconType } from '../../../../domain/newExplorers/Explorer';
import { useBEM } from '../../../utils/hooks';
import TestPill from '../../pills/TestPill';
import {
  ExplorerFolderIcon,
  ExplorerLessonIcon,
  ExplorerLessonPlanIcon,
  ExplorerPrivateFolderIcon,
  ExplorerSharedExplorerIcon,
} from '../icons';
import './ExplorerThumbnail.less';

interface Props {
  type: ExplorerIconType;
  imageSource?: string;
  isPublish?: boolean;
  isTemplate?: boolean;
  isTest?: boolean;
  showOpen?: boolean;
  folderIsPrivate?: boolean;
  isInPublicationFolder?: boolean;
  children?: React.ReactNode;
}

export const ExplorerThumbnail: React.FC<Props> = ({
  type,
  imageSource,
  isInPublicationFolder = false,
  children,
  isPublish,
  isTest,
  showOpen,
  isTemplate,
}) => {
  const bemClasses = useBEM('explorer-thumbnail');

  const thumbnailElementName = 'thumbnail';
  const hasContentThumbnail = imageSource && (type === 'lesson' || type === 'symbolicLink');
  const defaultProps = {
    className: bemClasses({ element: thumbnailElementName, modifier: hasContentThumbnail && 'content-thumbnail' }),
  };

  const defaultImage: Record<ExplorerIconType, React.ReactNode> = {
    folder: <ExplorerFolderIcon {...defaultProps} isInPublicationFolder={isInPublicationFolder} showOpen={showOpen} />,
    lesson: <ExplorerLessonIcon {...defaultProps} isTest={isTest} />,
    lessonPlan: <ExplorerLessonPlanIcon {...defaultProps} />,
    sharedExplorer: (
      <ExplorerSharedExplorerIcon isPublish={isPublish} isTemplate={isTemplate} {...defaultProps} showOpen={showOpen} />
    ),
    privateFolder: <ExplorerPrivateFolderIcon {...defaultProps} showOpen={showOpen} />,
    symbolicLink: null,
  };

  return (
    <div className={'thumbnail-wrapper'}>
      {imageSource ? <img alt="" src={imageSource} {...defaultProps} /> : defaultImage[type]}
      {isTest && <TestPill />}
      {children}
    </div>
  );
};
