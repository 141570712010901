import { trackAction } from '@lessonup/client-integration';
import { LessonUpApiService } from '../../../shared-core/client/services/api';
import { UserContext, VOUCHER_COOKIE_KEY } from '../../../shared-core/domain';
import { Api } from '../../../shared-core/domain/api/LessonupApi';

export interface VoucherAppService {
  redeem: (voucherId: string) => Promise<Api.RedeemVoucherResponse>;
  validate: (voucherId: string) => Promise<Api.RedeemVoucherResponse>;
  redeemOrValidateVoucher: (voucherId: string, user: UserContext | undefined) => Promise<Api.RedeemVoucherResponse>;
}

export default function configureVoucherAppService(api: LessonUpApiService): VoucherAppService {
  async function redeem(voucherId: string): Promise<Api.RedeemVoucherResponse> {
    const res = await api.redeemVoucher(voucherId);

    if (!res.success) {
      trackAction('voucher-redeem-error', { voucherId, errorCode: res.errorCode! });
      return res;
    }

    if (res.type === 'product') {
      trackAction('voucher-product-success', { voucherId, productId: res.productId! });
    }

    if (res.type === 'license') {
      trackAction('voucher-license-success', { voucherId });
    }
    trackAction('redeem-voucher-finished', { type: 'redeem' });

    return res;
  }

  async function validate(voucherId: string) {
    const res = await api.validateVoucher(voucherId);

    if (res.success) {
      localStorage.setItem(VOUCHER_COOKIE_KEY, voucherId);
    }

    if (!res.success) {
      trackAction('voucher-validate-error', { voucherId, errorCode: res.errorCode! });
      return res;
    }

    if (res.type === 'product') {
      trackAction('voucher-product-validate-success', { voucherId });
    }

    if (res.type === 'license') {
      trackAction('voucher-license-validate-success', { voucherId });
    }

    trackAction('redeem-voucher-finished', { type: 'validate' });
    return res;
  }

  function redeemOrValidateVoucher(
    voucherId: string,
    user: UserContext | undefined
  ): Promise<Api.RedeemVoucherResponse | Api.ValidateVoucherResponse> {
    return user ? redeem(voucherId) : validate(voucherId);
  }

  return {
    redeem,
    validate,
    redeemOrValidateVoucher,
  };
}
