import { logger } from '@lessonup/client-integration';
import { AppError } from '@lessonup/utils';
import { isString } from 'lodash';
import { Api } from '../../../domain/api/LessonupApi';
import { MeteorLocalStorage } from '../../../services/authentication';
import { anonymize } from '../../../utils';
import { fetchWithTimeout } from '../../utils/fetchUtils';

const { USERID, LOGINTOKEN } = MeteorLocalStorage;

/**
 * @deprecated this service should only be used by the search which uses these deprecated JWT tokens.
 * The new auth code can be found in the `@lessonup/client-integration`
 */
export class JwtTokenService {
  public constructor(protected readonly host: string) {}

  public async fetchToken(): Promise<Api.AuthJwtTokenResponse | undefined> {
    const localCredentials = this.localCredentials();

    if (!localCredentials) {
      return undefined;
    }

    try {
      const url = `${this.host}search/api/auth/jwtToken`;

      const result = await fetch(url, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          meteorUserId: localCredentials.userId,
          meteorUserToken: localCredentials.token,
        }),
      });

      // The search throws a 403 when tokens are expired
      // probably not how it should be
      // but we are afraid to make changes here :)
      if ([401, 403].includes(result.status)) {
        throw new AppError('not-authenticated', 'Unable to fetch JWT token', {
          url,
          result: await result?.json().catch(() => result),
        });
      }

      if (!result.ok) {
        throw new AppError('unexpected-data', 'Request returned a not-OK response', {
          url,
          result: await result?.json().catch(() => result),
          userId: localCredentials.userId,
          token: anonymize(localCredentials.token),
        });
      }

      return (await result.json()) as Api.AuthJwtTokenResponse;
    } catch (error) {
      logger.error(error);
    }
  }

  public async validateToken(userId: string): Promise<boolean> {
    const url = `${this.host}search/api/auth/validate-jwt-token`;

    const response = await fetch(url, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 401) {
      throw new AppError('not-authenticated', response.statusText, { url, response });
    }

    if (!response.ok) {
      throw new AppError('unexpected-data', 'Request returned a not-OK response', { url, response });
    }

    const result: Api.AuthValidateJwtTokenResponse = await response.json();

    return result.userId === userId;
  }

  public async unsetTokens() {
    try {
      const result = await fetchWithTimeout(`${this.host}search/api/auth/logout`, {
        timeout: 3000,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return await result.json();
    } catch (error) {
      logger.error(error);
    }
  }

  public hasCredentials() {
    return Boolean(this.localCredentials());
  }

  public saveCredentials(userId: string, loginToken: string) {
    localStorage.setItem(USERID, userId);
    localStorage.setItem(LOGINTOKEN, loginToken);
  }

  public purgeCredentials() {
    localStorage.removeItem(USERID);
    localStorage.removeItem(LOGINTOKEN);

    // clear these just to be sure
    localStorage.removeItem('auth.accessToken');
    localStorage.removeItem('auth.userId');
  }

  public localStorageScript(userId: string, token: string) {
    return `
    localStorage.setItem('${USERID}','${userId}');
    localStorage.setItem('${LOGINTOKEN}','${token}');
    `;
  }

  public localCredentials(): { userId: string; token: string } | undefined {
    const userId = localStorage.getItem(USERID);
    const token = localStorage.getItem(LOGINTOKEN);
    if (!userId || !token || !isString(userId) || !isString(token)) return undefined;
    return { userId, token };
  }
}
