import { AppError } from '@lessonup/utils';
import { isNil, omitBy } from 'lodash';
import {
  AnyFolder,
  Explorer,
  FolderId,
  FolderMetaWithFolderIndex,
  FolderWithNavigationData,
  isFolder,
  isPublishExplorer,
} from '..';
import { ProductId } from '../../products';

export function findFolderInExplorer(explorer: Explorer, folderId: FolderId): FolderWithNavigationData {
  const folder = findFolderDataRecursivelyInTree(explorer, 0, ({ _id }) => _id === folderId);
  if (!folder) {
    throw new AppError('not-found', `Folder does not exist in explorer`, { folderId, explorerId: explorer._id });
  }
  if (isPublishExplorer(explorer) && explorer.channel) {
    folder.channel = explorer.channel;
  }
  return folder;
}

function findFoldersDataRecursivelyInTree(
  currentFolder: AnyFolder,
  currentFolderIndex: number,
  predicate: (current: AnyFolder) => boolean,
  parents: FolderMetaWithFolderIndex[] = []
): FolderWithNavigationData[] {
  const found: FolderWithNavigationData[] = [];
  const newPredicate = (current: AnyFolder) => {
    const currentNotAlreadyFound = () => !found.find(({ _id }) => _id === current._id);
    return predicate(current) && currentNotAlreadyFound();
  };

  // TODO: Improve code so its more efficient and less sketchy
  let newlyFound = findFolderDataRecursivelyInTree(currentFolder, currentFolderIndex, newPredicate, parents);

  while (newlyFound) {
    found.push(newlyFound);
    newlyFound = findFolderDataRecursivelyInTree(currentFolder, currentFolderIndex, newPredicate, parents);
  }

  return found;
}

function findFolderDataRecursivelyInTree(
  currentFolder: AnyFolder,
  currentFolderIndex: number,
  predicate: (current: AnyFolder) => boolean,
  parents: FolderMetaWithFolderIndex[] = []
): FolderWithNavigationData | undefined {
  const { _id, children, name, privacy, products } = currentFolder;

  let order = '';
  let image, description;
  if (isFolder(currentFolder)) {
    order = currentFolder.order ?? '';
    image = currentFolder.image;
    description = currentFolder.description;
  }

  if (predicate(currentFolder)) {
    return {
      _id,
      index: currentFolderIndex,
      children,
      name,
      privacy,
      products,
      parents,
      order,
      image,
      description,
    };
  }
  const currentFolderMeta = stripDefaults({ _id, name, index: currentFolderIndex, order, products, privacy });
  const parentsForChild = [...parents, currentFolderMeta];

  for (let i = 0; i < children.length; i++) {
    const childFolder = children[i];
    const folder = findFolderDataRecursivelyInTree(childFolder, i, predicate, parentsForChild);

    if (folder) {
      return folder;
    }
  }
}

export function folderOrParentsHaveProduct(explorer: Explorer, folderId: FolderId): boolean {
  const folderWithParentData = findFolderDataRecursivelyInTree(explorer, 0, ({ _id }) => folderId === _id);
  if (folderWithParentData?.products?.length) {
    return true;
  }
  const folderParents = folderWithParentData?.parents;
  if (!folderParents?.length) {
    return false;
  }
  return !!folderParents.find(({ products }) => products?.length);
}

function stripDefaults(parent: FolderMetaWithFolderIndex): FolderMetaWithFolderIndex {
  return omitBy(parent, isNil) as FolderMetaWithFolderIndex;
}

export function findFoldersFromProductInExplorer(explorer: Explorer, productId: ProductId): FolderWithNavigationData[] {
  const folders = findFoldersDataRecursivelyInTree(explorer, 0, ({ products }) => !!products?.includes(productId));

  return folders.map((folder) => {
    if (isPublishExplorer(explorer) && explorer.channel) {
      return { ...folder, channel: explorer.channel };
    }
    return folder;
  });
}
