import { ID } from '@lessonup/teaching-core';
import { AppError } from '@lessonup/utils';
import { FolderId } from '.';
import { SymbolicLink } from '../explorers';
import { Explorer, ExplorerContent, ExplorerId, ExplorerUserContent } from './Explorer';

export interface LessonItem {
  id: string;
  type: 'lesson';
}

export interface LessonItemWithOrder extends LessonItem {
  order: string;
}

export interface LessonPlanItem {
  id: string;
  type: 'lessonPlan';
}

export interface LessonPlanItemWithOrder extends LessonPlanItem {
  order: string;
}

export interface FolderItem {
  id: FolderId;
  type: 'folder';
}

export interface FolderItemItemWithOrder extends FolderItem {
  order: string;
}

export interface SharedExplorerItem {
  id: ExplorerId;
  type: 'sharedExplorer';
}

export interface SharedExplorerItemWithOrder extends FolderItem {
  order: string;
}

export interface SymbolicLinkItem {
  id: ExplorerId;
  type: 'symbolicLink';
  contentId: string;
  contentType: SymbolicLink.Type;
}

export interface SymbolicLinkLessonItem extends SymbolicLinkItem {
  contentType: 'lesson';
}

export interface SymbolicLinkLessonPlanItem extends SymbolicLinkItem {
  contentType: 'lessonPlan';
}

export interface SymbolicLinkItemWithOrder extends SymbolicLinkItem {
  order: string;
}

export type Item = LessonItem | LessonPlanItem | FolderItem | SharedExplorerItem | SymbolicLinkItem;
export type ItemWithOrder =
  | LessonItemWithOrder
  | LessonPlanItemWithOrder
  | FolderItemItemWithOrder
  | SharedExplorerItemWithOrder
  | SymbolicLinkItemWithOrder;

export type UserContentItem = LessonItem | LessonPlanItem | SymbolicLinkItem;
export type UserContentItemWithOrder = LessonItemWithOrder | LessonPlanItemWithOrder | SymbolicLinkItemWithOrder;

export type LessonAndPlanCountTuple = [number, number];

export type ItemCounts = {
  [folderId: string]: LessonAndPlanCountTuple;
};

export function isLessonItem(item: Item): item is LessonItem {
  return item.type === 'lesson';
}

export function isLessonPlanItem(item: Item): item is LessonPlanItem {
  return item.type === 'lessonPlan';
}

export function isFolderItem(item: Item): item is FolderItem {
  return item.type === 'folder';
}

export function isSharedExplorerItem(item: Item): item is SharedExplorerItem {
  return item.type === 'sharedExplorer';
}

export function isSymbolicLinkItem(item: Item): item is SymbolicLinkItem {
  return item.type === 'symbolicLink';
}

export function isSymbolicLinkLessonItem(item: Item): item is SymbolicLinkLessonItem {
  return isSymbolicLinkItem(item) && item.contentType === 'lesson';
}

export function isSymbolicLinkLessonPlanItem(item: Item): item is SymbolicLinkLessonPlanItem {
  return isSymbolicLinkItem(item) && item.contentType === 'lessonPlan';
}

export function isUserContentItem(item: Item): item is UserContentItem {
  return isLessonItem(item) || isLessonPlanItem(item) || isSymbolicLinkItem(item);
}

export function userContentItems(items: Item[]): UserContentItem[] {
  return items.filter(isUserContentItem);
}

export const idToFolderItem = (id: FolderId): FolderItem => ({ type: 'folder', id });
export const idToLessonItem = (id: ID): LessonItem => ({ type: 'lesson', id });
export const idToPlanItem = (id: ID): LessonPlanItem => ({ type: 'lessonPlan', id });
export const idToSharedExplorerItem = (id: ID): SharedExplorerItem => ({ type: 'sharedExplorer', id });

type SplitResponse = {
  folders: FolderItem[];
  lessons: LessonItem[];
  lessonPlans: LessonPlanItem[];
  sharedExplorers: SharedExplorerItem[];
  symbolicLinks: SymbolicLinkItem[];
};

type SplitResponseWithOrder = {
  folders: FolderItemItemWithOrder[];
  lessons: LessonItemWithOrder[];
  lessonPlans: LessonPlanItemWithOrder[];
  sharedExplorers: SharedExplorerItemWithOrder[];
  symbolicLinks: SymbolicLinkItemWithOrder[];
};

export function splitExplorerItemsByType(items: Item[]): SplitResponse {
  return {
    folders: items.filter(isFolderItem),
    lessonPlans: items.filter(isLessonPlanItem),
    lessons: items.filter(isLessonItem),
    sharedExplorers: items.filter(isSharedExplorerItem),
    symbolicLinks: items.filter(isSymbolicLinkItem),
  };
}

export function splitExplorerItemsWithOrderByType(items: ItemWithOrder[]): SplitResponseWithOrder {
  return splitExplorerItemsByType(items) as unknown as SplitResponseWithOrder;
}

export function explorerContentToItem(content: ExplorerUserContent): UserContentItem;
export function explorerContentToItem(content: ExplorerContent): Item;
export function explorerContentToItem(content: ExplorerContent): Item {
  if (content.type === 'symbolicLink') {
    return {
      type: content.type,
      id: content.content._id,
      contentId: content.content.contentId,
      contentType: content.content.type,
    };
  }
  return {
    type: content.type,
    id: content.content._id,
  };
}

export function sharedExplorerToItem(sharedExplorer: Explorer): SharedExplorerItem {
  if (sharedExplorer.ownership !== 'shared') {
    throw new AppError('unexpected-data', `Expected explorer to be shared`, { explorerId: sharedExplorer._id });
  }

  return {
    type: 'sharedExplorer',
    id: sharedExplorer._id,
  };
}
