import { AnyAction } from '../actions/actions';

export type SavedLessonState = string[];
const initialState: SavedLessonState = [];

export function savedLessonReducer(state: SavedLessonState = initialState, action: AnyAction): SavedLessonState {
  switch (action.type) {
    case 'saveLesson':
      return state.concat(action.lessonId);
    case 'removeSavedLesson':
      return state.filter((l) => l !== action.lessonId);
    default:
      return state;
  }
}
