import classNames from 'classnames';
import React from 'react';
import SearchResultsTabs from '../SearchResultTabs/SearchResultTabs';
import './sidebarLayout.less';
export interface Props {
  sidebar?: JSX.Element;
  showTabs?: boolean;
  children?: React.ReactNode;
}

const SidebarLayout: React.FC<Props> = (props) => {
  return (
    <div className={classNames('sidebar-layout', { 'sidebar-hidden': !Boolean(props.sidebar) })}>
      <div className={classNames('sidebar-layout__sidebar', { hide: !Boolean(props.sidebar) })}>{props.sidebar}</div>
      <div className="sidebar-container">
        {(props.showTabs && <SearchResultsTabs />) || null}
        <div className="sidebar-layout__main-content">{props.children}</div>
      </div>
    </div>
  );
};

export default SidebarLayout;
