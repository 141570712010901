import { compact, kebabCase } from 'lodash';
import { useCallback, useMemo } from 'react';

interface BemProps {
  element?: string;
  modifier?: string | false;
  modifiers?: (string | false)[];
  className?: string;
}

export type BemClasses = (Props?: BemProps) => string;

export const useBEM = (componentName: string): BemClasses => {
  const block = useMemo(() => kebabCase(componentName), [componentName]);

  const bemClasses: BemClasses = useCallback(
    ({ element, modifier, modifiers, className } = {}) => {
      const getElement = (element: string): string => `${block}__${kebabCase(element)}`;

      const getModifier = (modifier: string, element?: string) =>
        element
          ? `${getElement(element)} ${getElement(element)}--${kebabCase(modifier)}`
          : `${block} ${block}--${kebabCase(modifier)}`;

      const getBEMClass = ({ element, modifier }: { element?: string; modifier?: string | false }): string =>
        modifier ? getModifier(modifier, element) : element ? getElement(element) : block;

      const modifierClass = getBEMClass({
        element,
        modifier,
      });
      const modifiersClasses = compact(modifiers).map((modifier) => getModifier(modifier, element));
      return compact([modifierClass, className]).concat(modifiersClasses).join(' ');
    },
    [block]
  );

  return bemClasses;
};
