import { capitalize, compact, escapeRegExp } from 'lodash';
import React from 'react';
import reactStringReplace from 'react-string-replace';
import { IndexedSchoolSuggestion } from '../../../../../domain/schoolSuggestions/IndexedSchoolSuggestion';

import { useBEM } from '../../../../utils/hooks';
import './SchoolSelectOption.less';

interface Props {
  school: IndexedSchoolSuggestion;
  searchQuery: string;
  isSelected: boolean;
}

const SchoolSelectOption: React.FC<Props> = ({ school, searchQuery, isSelected }) => {
  const bemClasses = useBEM('SchoolSelectOption');

  if (isSelected) {
    return <span>{compact([school.name, school.address, school.city]).join(', ')}</span>;
  }

  function searchQueryAsBold(text: string | undefined): React.ReactNode | undefined {
    if (!text) return;
    const query = escapeRegExp(searchQuery);

    // Query needs to be in a capturing group as that's required by react-string-replace.
    // https://www.npmjs.com/package/react-string-replace
    const regex = new RegExp(`(${query})`, 'gi');

    return (
      <span>
        {reactStringReplace(text, regex, (match, i) => (
          <strong key={`${match}-${i}`}>{match}</strong>
        ))}
      </span>
    );
  }

  return (
    <div className={bemClasses()}>
      <div className={bemClasses({ element: 'option' })}>
        <p className={bemClasses({ element: 'title' })}>{searchQueryAsBold(school.name)}</p>
        <span className={bemClasses({ element: 'address' })}>
          {searchQueryAsBold(school.address)}, {searchQueryAsBold(capitalize(school.city))}
        </span>
      </div>
    </div>
  );
};

export default SchoolSelectOption;
