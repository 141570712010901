import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FacetKey,
  Facets,
  isSelectedValueFacet,
  SearchParams,
  SelectedFacet,
  ValueFacetKey,
} from '../../../../../shared-core/domain';
import TagList from '../../../../../shared-core/ui/components/TagList';

interface SearchResultEmptyStateProps {
  searchParams: SearchParams;
  handleFacetDeselect: Function;
}

const TRANSLATION_NAMESPACE = 'search';

const canFilterFacet = (facet: SelectedFacet): boolean => {
  if (facet.fixed) return false;
  return !nonfilterableFacets[facet.key];
};

type NonfilterableFacets = Partial<{
  [key in FacetKey]: boolean;
}>;
const nonfilterableFacets: NonfilterableFacets = {
  hasImage: true,
};

type WithLabel = {
  key: ValueFacetKey;
  label: string;
};
export default function SearchResultsEmptyState(props: SearchResultEmptyStateProps) {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  const tags = props.searchParams.facets
    .filter(canFilterFacet)
    .filter(isSelectedValueFacet)
    .map((facet) => {
      const label = Facets.facetLabel(facet, 'nl', 'vo');
      return { key: facet.key, label: label && _.includes(['test', 'channelLesson'], label) ? t(label) : label };
    })
    .filter<WithLabel>((facet): facet is WithLabel => !!facet.label);

  const removeParam = (facetKey: FacetKey) => {
    props.handleFacetDeselect(facetKey);
  };

  return (
    <>
      {props.searchParams.text && <p>{t('no-result-text', { ns: TRANSLATION_NAMESPACE })}</p>}
      {props.searchParams.text && (
        <TagList
          className="search-page-tags removal-tags"
          tags={[{ label: `"${props.searchParams.text}"`, key: 'text' }]}
          onClick={removeParam}
          passiveTooltip={t('remove', { ns: TRANSLATION_NAMESPACE })}
        />
      )}

      {tags.length ? <p>{t('no-result-facets', { ns: TRANSLATION_NAMESPACE })}</p> : null}
      <TagList
        className="search-page-tags removal-tags"
        tags={tags}
        onClick={removeParam}
        passiveTooltip={t('remove', { ns: TRANSLATION_NAMESPACE })}
      />
    </>
  );
}
