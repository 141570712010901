import React from 'react';
import { ChannelTitle, LessonPlan } from '../../../../shared-core/domain';
import { getFolderFromExplorer } from '../../../../shared-core/domain/newExplorers';
import { channelRoute } from '../../../../shared-core/services/app/searchRoutes';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import LessonPlanPage from '../../lessonPlan/LessonPlanPage';
import { ChannelSubPageProps } from '../ChannelPageView';

const ChannelPlanPage: React.FC<ChannelSubPageProps> = ({ activeTab, changeTab, details }) => {
  const { selectionId, subSelectionId, channelPage } = activeTab;
  const { publicFolder } = details;

  if (!selectionId) {
    return null;
  }

  const info = publicFolder && getFolderFromExplorer(publicFolder);

  const backNavigation = (LessonPlan: LessonPlan) => {
    if (!info || !info.parents) return;
    return (
      <Breadcrumbs
        breadcrumbs={info.parents}
        rootFolderName={ChannelTitle.localizeTitle(details.channel.title)}
        getUrl={(folderId) =>
          channelRoute({
            channelSlug: details.channel.name,
            channelPage: 'series',
            selectionId: folderId || undefined,
          })
        }
        currentFolderName={LessonPlan.name}
      />
    );
  };

  return <LessonPlanPage planId={selectionId} backNavigation={backNavigation} inChannelContext={true} />;
};

export default ChannelPlanPage;
