type FetchRequestWithTimeOut = RequestInit & { timeout?: number };
const defaultTimeOut = 5000;

export async function fetchWithTimeout(input: RequestInfo, options: FetchRequestWithTimeOut = {}): Promise<Response> {
  const { timeout = defaultTimeOut } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(input, {
    ...options,
    signal: controller.signal,
  });

  clearTimeout(id);
  return response;
}
