import { UserRatings } from '@lessonup/firebase-database';
import _ from 'lodash';
import { AnyAction } from '../actions/actions';

export interface RatingsState {
  userRatings: UserRatings;
}

const initialState: RatingsState = { userRatings: {} };

export function ratingsReducer(state: RatingsState = initialState, action: AnyAction): RatingsState {
  switch (action.type) {
    case 'fetchedLesson':
    case 'fetchedLessonResults':
      return {
        userRatings: Object.assign({}, state.userRatings, action.response.userRatings),
      };
    case 'changeRating':
      const ratings = _.clone(state.userRatings);
      ratings[action.lessonId] = action.rating;
      return { userRatings: ratings };
    default:
      return state;
  }
}
