import { logger } from '@lessonup/client-integration';
import { AppError, sanitizeNameString } from '@lessonup/utils';
import _ from 'lodash';
import { LessonUpApiService } from '../../shared-core/client/services/api';
import { JwtTokenService } from '../../shared-core/client/services/auth';
import {
  ClientSimplePasswordCredentials,
  Language,
  LanguageSingleton,
  Licenses,
  MongoUserProfile,
  UserContext,
} from '../../shared-core/domain';
import { Api } from '../../shared-core/domain/api/LessonupApi';
import { MeteorLocalStorage } from '../../shared-core/services';
import { teacherHome } from '../../shared-core/services/app/teacherRoutes';
import { setUserAction, signOutUserAction } from '../redux/actions/actions';
import { SearchStore } from '../redux/store';
import { RedirectService } from './RedirectService';

export type Status = 'loggingIn' | 'notLoggedIn' | 'required';

export type Callbacks = {
  onLoginStatusChange: (isLoggedIn: boolean, user?: UserContext, source?: 'standalone' | 'modal') => void;
};

export class UserService {
  private callbacks: Callbacks;

  public constructor(
    private tokenService: JwtTokenService,
    private api: LessonUpApiService,
    private store: SearchStore,
    callbacks: Partial<Callbacks> = {}
  ) {
    this.callbacks = {
      onLoginStatusChange: () => {},
      ...callbacks,
    };
  }

  private startLocalStorageListener() {
    window.addEventListener('storage', (event) => {
      if (event.storageArea !== localStorage || event.key !== 'Meteor.loginToken') return;

      this.setLocalStorageCredentialsIfChanged(event);

      const user = this.currentUser();
      const token = event.newValue;

      if (token && !user) {
        if (this.tokenService.hasCredentials()) {
          this.loginWithToken();
        }
      } else if (!token && user) {
        this.logout();
      } else if (token && user) {
        // Invalidate the JWT token if the localStorage user differs from the current user.
        const localCredentials = this.tokenService.localCredentials();

        if (localCredentials && localCredentials.userId === user._id) return;

        this.tokenService.fetchToken().then((response) => {
          if (!response || !response.user) {
            this.logout();
            return;
          }

          if (response.user._id !== user._id) {
            this.tokenService.unsetTokens();
            this.loginWithToken();
          }
        });
      }
    });
  }

  private setLocalStorageCredentialsIfChanged(event: StorageEvent) {
    const { USERID, LOGINTOKEN } = MeteorLocalStorage;

    if (event.key && [LOGINTOKEN, USERID].includes(event.key) && event.newValue) {
      localStorage.setItem(event.key, event.newValue);
    }
  }

  public init(): void {
    this.startLocalStorageListener();
    const user = this.currentUser();
    if (user) {
      this.onLogin(user);
      return;
    }

    // already logged in in meteor
    if (this.tokenService.hasCredentials()) {
      this.loginWithToken();
    }
  }

  public currentUser(): UserContext | undefined {
    return this.store.getState().user.user;
  }

  public userHasProducts(): boolean {
    return !!this.currentUser()?.products.length;
  }

  public async logout(): Promise<void> {
    await this.api.logoutServerSide();
    this.tokenService.purgeCredentials();
    logger.setUserId(undefined);
    this.store.dispatch(signOutUserAction());
    this.callbacks.onLoginStatusChange(false);
  }

  private onLogin(user: UserContext, loginSource?: 'standalone' | 'modal'): void {
    this.updateLoginStatus(user, loginSource);
    const redirectUrl = RedirectService.getRedirectUrl();

    if (redirectUrl) {
      window.open(redirectUrl, '_self');
      return;
    }

    if (loginSource === 'standalone') {
      window.open(teacherHome(), '_self');
    }
  }

  private async loginWithToken(): Promise<boolean> {
    try {
      const resp = await this.tokenService.fetchToken();
      if (!resp || !resp.success) return false;
      if (resp.user) this.onLogin(resp.user);

      return true;
    } catch (e) {
      throw new AppError('invalid-params', 'invalid login credentials');
    }
  }

  public async loginWithEmailPassword(params: ClientSimplePasswordCredentials): Promise<boolean> {
    const resp = await this.api.loginWithEmailPassword({
      email: params.email,
      password: params.password,
    });

    if (resp) {
      this.tokenService.saveCredentials(resp.user._id, resp.loginToken);
      this.onLogin(resp.user, params.loginSource);
      return true;
    }
    return false;
  }

  public async registerWithEmailVerificationTokenPassword({
    emailVerificationToken,
    password,
  }: Omit<Api.AuthMeteorEmailVerifiedRegistrationParams, 'addMultipleFirstLessons'>): Promise<boolean> {
    const resp = await this.api.registerWithEmailVerificationTokenPassword({
      emailVerificationToken,
      password,
      language: LanguageSingleton.get(),
      addMultipleFirstLessons: false,
    });

    if (resp && resp.type === 'success') {
      this.tokenService.saveCredentials(resp.user._id, resp.loginToken);
      this.updateLoginStatus(resp.user);
      return true;
    }

    return false;
  }

  private updateLoginStatus(user: UserContext, loginSource?: 'standalone' | 'modal') {
    this.store.dispatch(setUserAction(user));
    logger.setUserId(user._id);
    this.callbacks.onLoginStatusChange(true, user, loginSource);
  }

  private updateUser(user: UserContext) {
    this.store.dispatch(setUserAction(user));
  }

  public async onAcceptTerms(): Promise<boolean> {
    const res = await this.api.handleUserAcceptTerms();
    if (res.type === 'success') this.updateUser(res.user);

    return res.type === 'success';
  }

  public async setData(dataKey: string, value: string | boolean | {}): Promise<boolean> {
    const res = await this.api.setUserData(dataKey, value);
    if (res.type === 'success') this.updateUser(res.user);
    return true;
  }

  public async requestMagicLink(params: Api.RequestMagicLinkParams): Promise<boolean> {
    const response = await this.api.requestMagicLink(
      params.email,
      params.language || Language.defaultLanguage,
      params.emailVariant
    );
    if (response && response.type === 'success') {
      return true;
    }
    return false;
  }

  public async requestPasswordReset(params: Api.RequestPasswordResetParams): Promise<boolean> {
    const response = await this.api.requestPasswordReset(params.email);

    if (response && response.type === 'success') {
      return true;
    }

    return false;
  }

  public async resetPassword({
    userId,
    password,
    token,
  }: {
    userId: string;
    token: string;
    password: string;
  }): Promise<boolean> {
    const response = await this.api.resetPassword({ userId, token, password });

    if (response.type === 'success') {
      this.tokenService.saveCredentials(userId, response.token);
      this.loginWithToken();
      return true;
    }
    return false;
  }

  public hasProFeatures(): boolean {
    const user = this.store.getState().user.user;
    if (!user) {
      return false;
    }

    return _.includes(Licenses.proLicenses, user.licenseStatus);
  }

  public hasTestAccess(): boolean {
    const user = this.store.getState().user.user;
    if (!user) {
      return false;
    }

    return Licenses.isPaidLicense(user.licenseStatus);
  }

  public sendOrganizationJoinRequest(organizationId: string): Promise<Api.SendJoinRequestResponse> {
    return this.api.sendOrganizationJoinRequest(organizationId);
  }

  public setSchoolType(schoolTypes: MongoUserProfile['schoolTypes']): Promise<void> {
    return this.api.setSchoolType(schoolTypes);
  }

  public setName(params: { givenName: string; familyName: string }): Promise<void> {
    return this.api.setName({
      givenName: sanitizeNameString(params.givenName),
      familyName: sanitizeNameString(params.familyName),
    });
  }

  public setReasonForRegistration(params: Api.SaveReasonParams): Promise<void> {
    return this.api.setReasonOnRegistration(params);
  }

  public saveOrganizationRegistration(
    params: Api.SaveOrganizationRegistrationParams
  ): Promise<Api.SaveOrganizationRegistrationResponse> {
    return this.api.saveOrganizationRegistration(params);
  }

  public userIsLoggedInInMeteor(): boolean {
    return this.tokenService.hasCredentials();
  }
}
