import moment from 'moment';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { logger } from '@lessonup/client-integration';
import { UserContext } from '../../../shared-core/domain';
import { registerRoute } from '../../../shared-core/services/app/searchRoutes';
import { channelTeacherUrl } from '../../../shared-core/services/app/teacherRoutes';
import ActionButton, { AnchorButton } from '../../../shared-core/ui/components/Button';
import RedeemVoucherConfirmationBox from '../../../shared-core/ui/components/forms/RedeemVoucherConfirmationBox/RedeemVoucherConfirmationBox';
import RedeemVoucherForm from '../../../shared-core/ui/components/forms/RedeemVoucherForm/RedeemVoucherForm';
import capitalize from '../../../shared-core/utils/capitalize';
import { useAppServices } from '../../components/appServices/AppServicesContext';
import { ReactRouterLinkButton } from '../../components/buttons/ReactRouterButton';
import { loggedInUser } from '../../redux/selectors';
import { RedirectService } from '../../services/RedirectService';
import useFeatureFlags from '../../utils/featureFlags/useFeatureFlags';
import { GrayPageLayoutTitle, GrayPageSection } from '../GrayPageLayout/GrayPageLayout';
import './VoucherPage.less';

const TRANSLATION_KEY = 'redeemVoucher';

const VoucherPageView: React.FC<{}> = (props) => {
  const { t } = useTranslation(TRANSLATION_KEY);
  const user = useSelector(loggedInUser());

  const { voucher } = useAppServices();

  const [page, setPage] = useState<'redeemVoucher' | 'confirm'>('redeemVoucher');
  const [voucherId, setVoucherId] = useState<string>('');

  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [voucherCallbackUrl, setVoucherCallbackUrl] = useState<string | undefined>(undefined);

  async function handleSubmit(): Promise<void> {
    try {
      setErrorMessage(undefined);
      const res = await voucher.redeemOrValidateVoucher(voucherId, user);
      if (!res || !res.success) {
        setErrorMessage(res.errorCode);
        return;
      }

      if (res.type === 'product') {
        setSuccessMessage(
          capitalize(t('productReceivedUntil', { organization: res.organization, product: res.productName }))
        );
        setVoucherCallbackUrl(res.channel ? channelTeacherUrl(res.channel.name) : undefined);
      } else if (res.expiryDate) {
        setSuccessMessage(
          capitalize(t('licenseExtendedUntil', { expiryDate: moment(res.expiryDate).format('D MMMM YYYY') }))
        );
      } else {
        setSuccessMessage(capitalize(t('licenseValidForMonths', { count: res.extendMonths })));
      }

      setPage('confirm');
    } catch (error) {
      logger.error(error);
      setErrorMessage('genericErrorMessage');
    }
  }

  function handleInputChange(value: string) {
    setVoucherId(value);
  }

  return (
    <div className="redeem-voucher-inner-page">
      {page === 'redeemVoucher' && (
        <RedeemVoucherContent
          user={user}
          errorMessage={errorMessage}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
        />
      )}
      {page === 'confirm' && (
        <RedeemVoucherConfirmationPage
          message={successMessage}
          callToAction={
            user ? (
              <UserCallToActions callbackUrl={voucherCallbackUrl} />
            ) : (
              <AnonymousCallToActions callbackUrl={voucherCallbackUrl} />
            )
          }
          userIsLoggedIn={!!user}
        />
      )}
    </div>
  );
};

interface RedeemVoucherContentProps {
  user: UserContext | undefined;
  errorMessage?: string;
  handleSubmit: () => void;
  handleInputChange: (val: string) => void;
}

const RedeemVoucherContent: React.FC<RedeemVoucherContentProps> = ({
  user,
  errorMessage,
  handleSubmit,
  handleInputChange,
}) => {
  const { t } = useTranslation(TRANSLATION_KEY);
  const { isFeatureFlagEnabled } = useFeatureFlags();

  return (
    <React.Fragment>
      <GrayPageSection position="top">
        <GrayPageLayoutTitle>
          {user ? t('redeemVoucherForUserTitle') : t('redeemVoucherAnonymousTitle')}
        </GrayPageLayoutTitle>
        <p className="redeem-voucher-subtitle">
          {user ? (
            t('redeemVoucherForUserSubtitle')
          ) : (
            <Trans
              t={t}
              i18nKey="redeemVoucherAnonymousSubtitle"
              components={[
                <a href={'/'} target="_blank" rel="noopener noreferrer">
                  content {/** Content will be replaced by i18next. */}
                </a>,
              ]}
            ></Trans>
          )}
        </p>
      </GrayPageSection>
      <GrayPageSection position="center">
        <RedeemVoucherForm
          error={errorMessage}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          isFeatureFlagEnabled={isFeatureFlagEnabled}
        />
      </GrayPageSection>
      <GrayPageSection position="bottom"></GrayPageSection>
    </React.Fragment>
  );
};

export interface RedeemVoucherConfirmationProps {
  message: string | undefined;
  callToAction: JSX.Element;
  userIsLoggedIn: boolean;
}

const RedeemVoucherConfirmationPage: React.FC<RedeemVoucherConfirmationProps> = (props) => {
  const { t } = useTranslation(TRANSLATION_KEY);

  return (
    <React.Fragment>
      <GrayPageSection position="top">
        <GrayPageLayoutTitle>
          {props.userIsLoggedIn ? t('congratulations') : t('congratulationsAnonymous')}
        </GrayPageLayoutTitle>
        <p className="redeem-voucher-subtitle">{t('congratulationsSubtitle')}</p>
      </GrayPageSection>
      <GrayPageSection position="center">
        <RedeemVoucherConfirmationBox>
          <span>{t('confirmationIntro')}</span>
          <h3 className="confirmation-message">{props.message}</h3>
          {!props.userIsLoggedIn && <span>{t('registerPrompt')}</span>}
          {props.callToAction}
        </RedeemVoucherConfirmationBox>
      </GrayPageSection>
      <GrayPageSection position="bottom"></GrayPageSection>
    </React.Fragment>
  );
};

interface CallToActionProps {
  callbackUrl?: string;
}
const AnonymousCallToActions: React.FC<CallToActionProps> = ({ callbackUrl }) => {
  const { t } = useTranslation(TRANSLATION_KEY);
  const { modals, user } = useAppServices();

  function onRegistrationClick() {
    if (callbackUrl) RedirectService.setRedirectUrlFromQueryString(callbackUrl);
  }

  return (
    <div className="call-to-action stack">
      <ActionButton
        onClick={() => {
          modals.open('login', { userService: user, initialPage: 'login' });
        }}
        theme="secondary"
      >
        {t('login')}
      </ActionButton>

      <ReactRouterLinkButton onClick={onRegistrationClick} to={registerRoute('credentials')} theme="primary">
        {t('register')}
      </ReactRouterLinkButton>
    </div>
  );
};

const UserCallToActions: React.FC<CallToActionProps> = ({ callbackUrl }) => {
  const { config } = useAppServices();
  const { t } = useTranslation(TRANSLATION_KEY);

  return (
    <div className="call-to-action">
      <AnchorButton href={callbackUrl || config.teacherMyLessonsUrl()} theme="primary">
        {t('getStarted')}
      </AnchorButton>
    </div>
  );
};
export default VoucherPageView;
