import { Pin, PinType } from '@lessonup/teaching-core';
import { isEmptyHtmlString } from '@lessonup/utils';
import _ from 'lodash';
import { Language, LanguageSingleton } from '../domain';

export namespace PinViewUtils {
  export function allowImageZoomByLayout(layout: number, pinType: PinType): boolean {
    const zoomAllowedMap = {
      slide: [1, 2, 8],
      quiz: [1],
      openQuestion: [1],
      question: [1],
    };
    return zoomAllowedMap[pinType] && zoomAllowedMap[pinType].includes(layout);
  }

  export function allowImageZoom(pin: Pin): boolean {
    const pinItem = pin.item;
    if (!allowImageZoomByLayout(pinItem.custom.layout, pinItem.type)) {
      return false;
    }

    if (['question', 'openQuestion'].includes(pinItem.type)) {
      return isEmptyHtmlString(pinItem.custom.question);
    }
    return true;
  }

  const pinRatio = (10 / 16) * 9;
  export function calcFontSize(innerWidth: number | undefined): string | undefined {
    if (typeof window === 'undefined' || !innerWidth) {
      return;
    }
    const windowWidth = window.innerWidth;
    const scale = innerWidth / windowWidth;
    const fontSize = pinRatio * scale;
    return fontSize + 'vw';
  }

  export function calcFontSizeForWindowWidth(
    innerWidth: number | undefined,
    windowWidth: number | undefined
  ): string | undefined {
    if (!innerWidth || !windowWidth) {
      return;
    }
    const scale = innerWidth / windowWidth;
    const fontSize = pinRatio * scale;
    return fontSize + 'vw';
  }

  export function labelForPinType(type: PinType, language = LanguageSingleton.get()): string {
    // TODO: Add translations
    const map: Language.FullDict<{ [key in PinType]: string }> = {
      nl: {
        dragQuestion: 'Sleepvraag',
        link: 'Link',
        map: 'Kaart',
        openQuestion: 'Open vraag',
        question: 'Quizvraag',
        slide: 'Slide',
        video: 'Video',
        wordweb: 'Woordweb',
        poll: 'Poll',
      },
      en: {
        dragQuestion: 'Drag question',
        link: 'Link',
        map: 'Map',
        openQuestion: 'Open question',
        question: 'Quiz question',
        slide: 'Slide',
        video: 'Video',
        wordweb: 'Mind map',
        poll: 'Poll',
      },
      fr: {
        dragQuestion: 'Question drag',
        link: 'Lien',
        map: 'Dossier',
        openQuestion: 'Question ouverte',
        question: 'Question quiz',
        slide: 'Diapositive',
        video: 'Video',
        poll: 'Sondage',
        wordweb: 'Carte mentale',
      },
    };
    return map[language][type];
  }

  export function titleForPinType(type: PinType, isInteractive?: boolean) {
    if (type === 'video') {
      return isInteractive ? 'interactive-video' : 'video';
    }
    return type;
  }

  type VideoSource = 'youtube' | 'vimeo' | 'schooltv' | 'npo';

  const embeddedVideoSources: VideoSource[] = ['youtube', 'vimeo'];
  const externalVideoSources: VideoSource[] = ['schooltv', 'npo'];
  export const videoSources: VideoSource[] = embeddedVideoSources.concat(externalVideoSources);

  export function pinTypeOrVideoSource(pin: Pin, isThumb = false, useThumbnailForVideo = false): PinType {
    if (pin.item.type !== 'video') {
      return pin.item.type;
    }

    if (isThumb || useThumbnailForVideo) return 'video';

    return _.includes(externalVideoSources, pin.item.source) ? 'link' : pin.item.type;
  }
}
