import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { teacherHome } from '../../../../shared-core/services/app/teacherRoutes';
import { loggedInUser } from '../../../redux/selectors';
import { RedirectService } from '../../../services/RedirectService';

export const useRedirectForLoggedInUsers = () => {
  const user = useSelector(loggedInUser());

  useEffect(() => {
    if (user) {
      window.open(RedirectService.getRedirectUrl() || teacherHome(), '_self');
    }
  }, [user]);
};
