import React from 'react';
import { useSelector } from 'react-redux';
import { thumbPins } from '../../redux/selectors';
import { LessonPreviewTileV1, LessonPreviewTileV2 } from './LessonPreviewTile';
import './LessonThumbPage.less';

const LessonThumbPage: React.FunctionComponent = () => {
  const { pins, pinCount, isV2Lesson, teacherPins } = useSelector(thumbPins());

  if (!pinCount) {
    return <div>no lesson</div>;
  }

  return (
    <div className="lesson-thumb-page" id="lesson-thumbnail">
      <div className="lesson-summary-card__preview">
        {isV2Lesson ? (
          <LessonPreviewTileV2 teacherPins={teacherPins} totalPinCount={pinCount} />
        ) : (
          <LessonPreviewTileV1 pins={pins} totalPinCount={pinCount} />
        )}
      </div>
    </div>
  );
};

export default LessonThumbPage;
