import { logger } from '@lessonup/client-integration';
import { ReferralRegistrationWelcomeFeature } from '@lessonup/teacher-modern';
import { useErrorContext } from '@lessonup/ui-components';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { registerRoute } from '../../../../shared-core/services/app/searchRoutes';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import { loggedInUser } from '../../../redux/selectors';
import { RegistrationFormProps } from '../types';

type ReferralWelcomeProps = RegistrationFormProps & {
  referralToken: string;
};

export default function ReferralWelcome(props: ReferralWelcomeProps) {
  const user = useSelector(loggedInUser());
  const services = useAppServices();
  const [referrerName, setReferrerName] = useState<string | undefined>(undefined);
  const [isInvalidLink, setIsInvalidLink] = useState<boolean>(false);
  const { setError } = useErrorContext();

  useEffect(() => {
    if (user) {
      props.next();
    }
  }, [props, user]);

  useEffect(() => {
    if (!props.referralToken || props.referralToken === 'undefined') {
      setIsInvalidLink(true);
    } else {
      const getReferrerName = async () => {
        try {
          const { referrerName: fromApi } = await services.referral.referralByReferralToken(props.referralToken);
          setReferrerName(fromApi);
        } catch (error) {
          logger.error(error);
          setError(error);
        }
        getReferrerName();
      };
    }
  }, [props.referralToken, services, setError]);

  return (
    <ReferralRegistrationWelcomeFeature
      isInvalidLink={isInvalidLink}
      referrerName={referrerName}
      {...props}
      next={isInvalidLink ? () => window.open(registerRoute(), '_self') : props.next}
    />
  );
}
