import { Country } from '../country/Country';

export namespace GeoLocation {
  export interface GeoValues {
    countryCode?: string;
    countryName?: string;
    regionCode?: string;
    timezone?: string;
    cityName?: string;
    lat?: number;
    long?: number;
  }

  export const GeoHeaders: Record<GeoProperty, GeoDataHeaderKeys> = {
    countryCode: 'x-country-code',
    countryName: 'x-country-name',
    regionCode: 'x-region-code',
    timezone: 'x-timezone',
    cityName: 'x-city-name',
    lat: 'x-lat',
    long: 'x-long',
    latLong: 'x-lat-long',
  };

  export type GeoDataHeaderKeys =
    | 'x-lat'
    | 'x-long'
    | 'x-city-name'
    | 'x-timezone'
    | 'x-region-code'
    | 'x-country-name'
    | 'x-country-code'
    | 'x-lat-long';

  export type GeoProperty =
    | 'lat'
    | 'long'
    | 'latLong'
    | 'cityName'
    | 'timezone'
    | 'regionCode'
    | 'countryName'
    | 'countryCode';

  export type GeoLocationObject = Record<GeoProperty, string>;

  export type HttpHeaderList = { [header: string]: string | string[] | undefined };

  export function getHeaderName(type: GeoProperty): GeoDataHeaderKeys {
    return GeoHeaders[type];
  }

  export function getAsString(headers: HttpHeaderList, header: string): string | undefined {
    const res = headers[header];
    if (res instanceof Array) return res.join(',');
    return res;
  }

  export function parseGeoHeaders(headers: HttpHeaderList): GeoValues {
    if (!headers) return {};
    const geo: GeoValues = {};
    for (const geoProperty of Object.keys(GeoHeaders)) {
      const s = getAsString(headers, getHeaderName(geoProperty as GeoProperty));
      if (s && (geoProperty === 'lat' || geoProperty === 'long')) {
        // we store lat/long as float and avoid 0
        const n = Number.parseFloat(s);
        if (isNaN(n) || n !== 0) geo[geoProperty] = n;
      } else if (s && geoProperty === 'latLong') {
        const [lat, long] = s.split(',');
        geo['lat'] = Number(lat.trim());
        geo['long'] = Number(long.trim());
      } else if (s && s !== '') {
        geo[geoProperty] = s;
      }
    }
    return geo;
  }
  export function countryForGeoHeaders(headers: HttpHeaderList): Country.CountryCode | undefined {
    const geoValues = parseGeoHeaders(headers);
    return Country.validCountryCode(geoValues.countryCode?.toLowerCase());
  }
}
