import { AppError } from '@lessonup/utils';

const DEFAULT_NOTIFICATION_TRANSLATION_KEY = 'defaultErrorNotification';

export const errorToDefaultNotificationTranslationKey = (error?: Error | AppError): string =>
  AppError.isAppError(error)
    ? appErrorToDefaultNotificationTranslationKey(error)
    : DEFAULT_NOTIFICATION_TRANSLATION_KEY;

const appErrorToDefaultNotificationTranslationKey = (appError: AppError): string => {
  switch (appError.code) {
    case 'vimeo:video-not-embeddable':
      return 'vimeo:video-not-embeddable-notification';
    case 'vimeo:video-not-found':
      return 'vimeo:video-not-found-notification';
    default:
      return DEFAULT_NOTIFICATION_TRANSLATION_KEY;
  }
};
