import _ from 'lodash';
import React from 'react';

import { AssignmentPin } from '@lessonup/teaching-core';
import { ContentTypeDefaults } from '../../../../utils/ContentTypeDefaults';
import ImageComponent, { ImageComponentPropsForPin } from '../../images/ImageComponent';
import PinContainer from '../../pin/PinContainer';
import { MapPinProps } from '../../pin/PinView';
import TextNewLineToBr from '../../text/TextNewLineToBr';
import ViewComponents from '../../viewComponents/ViewComponents';
import './OpenQuestionView.less';

interface Props extends MapPinProps {
  pin: AssignmentPin.OpenQuestion;
}

export default class OpenQuestionView extends React.Component<Props> {
  private questionClasses = () => {
    const pinContent = this.props.pin.item.custom;
    const textAlignClass = `text-align-${pinContent.align}`;
    const fontSize = pinContent.fontSize1
      ? pinContent.fontSize1
      : ContentTypeDefaults.getDefaultFontSize(1, this.props.pin.item.type);
    const fontSizeClass = `font-size-${fontSize}`;

    return [textAlignClass, fontSizeClass].join(' ');
  };

  private isPhotoQuestion = () => {
    const { maximumNumberOfUploads } = this.props.pin.item.custom;
    return !_.isUndefined(maximumNumberOfUploads) && maximumNumberOfUploads > 0;
  };

  public render() {
    const { pin, isThumb } = this.props;
    const imageProps = ImageComponentPropsForPin(pin, 'image', isThumb);
    const { item } = this.props.pin;

    return (
      <PinContainer pin={this.props.pin}>
        {item.image && <ImageComponent {...imageProps} />}
        <div id="title" className={'question ' + this.questionClasses()}>
          <TextNewLineToBr string={item.custom.question} />
        </div>
        <div className="component-area">
          <div className="scale-up">
            <ViewComponents
              components={item.components}
              isThumb={isThumb}
              expandOnlyOne={item.settings?.closeOtherHotspots}
            />
          </div>
        </div>
        <div className="placeholder-answers" />
        {this.isPhotoQuestion() ? <div className="open-question-with-image-camera-overlay" /> : null}
      </PinContainer>
    );
  }
}
