import classNames from 'classnames';
import React, { useState } from 'react';
import MaskedField from './MaskedField';
import './VoucherInputField.less';

interface InputProps {
  onChange: (s: string) => void;
  onComplete?: (value?: string) => void;
  mask: string;
  voucher: string;
  hasError?: boolean;
  className?: string;
}

interface Props {
  onChange: InputProps['onChange'] | undefined;
  onComplete: InputProps['onComplete'];
  mask: InputProps['mask'];
  className: InputProps['className'];
  hasError: InputProps['hasError'];
}

const MASK_FORMAT = { A: /[a-zA-Z]/, 0: /[0-9]/, '*': /./, _: /\s/ };

const VoucherInputField: React.FC<Props> = ({ onChange, onComplete, mask, className, hasError }) => {
  const [voucher, setVoucher] = useState<string>('');

  function handleChange(value: string) {
    setVoucher(value);
    onChange && onChange(value);
  }

  return (
    <div>
      <Input
        onChange={handleChange}
        onComplete={onComplete}
        voucher={voucher}
        mask={mask}
        className={classNames(className, { 'has-error': !!hasError })}
      />
    </div>
  );
};

const Input: React.FC<InputProps> = ({ onChange, onComplete, voucher, mask, className }) => {
  const maskPrefix = mask.substring(0, 3);
  const maskSuffix = mask.substring(3);
  const voucherSuffix = voucher.substring(3);

  return (
    <div className="placeholder input">
      <div className={classNames('voucher-input', `input-length-${maskSuffix.length}`, className)}>
        <div className="voucher-prefix">{maskPrefix}</div>
        <MaskedField
          value={voucherSuffix}
          mask={maskSuffix}
          placeholder={'XXX-XXX-XXX'}
          onChange={(event) => onChange(`${maskPrefix}${event.target.value}`)}
          type="text"
          translations={MASK_FORMAT}
          onComplete={onComplete}
          autoFocus
        ></MaskedField>
      </div>
    </div>
  );
};

export default VoucherInputField;
