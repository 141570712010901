import React from 'react';
import { ExplorerIconType } from '../../../../../domain/newExplorers';
import { useBEM } from '../../../../utils/hooks';
import { Cell, CellProps } from '../../../Table';
import { ExplorerThumbnail } from '../../ExplorerThumbnail/ExplorerThumbnail';
import './ImageCell.less';

interface Props extends CellProps {
  type: ExplorerIconType;
  imageSource?: string;
  className?: string;
  isPublish?: boolean;
  isTest?: boolean;
  isInPublicationFolder?: boolean;
  children?: React.ReactNode;
}

export const ImageCell: React.FC<Props> = ({
  children,
  type,
  className,
  imageSource,
  isPublish,
  isTest,
  isInPublicationFolder = false,
  ...props
}) => {
  const bemClasses = useBEM('ImageCell');
  return (
    <Cell className={bemClasses({ className })} {...props} ref={null}>
      <ExplorerThumbnail
        type={type}
        imageSource={imageSource}
        isInPublicationFolder={isInPublicationFolder}
        isPublish={isPublish}
        isTest={isTest}
      >
        {children}
      </ExplorerThumbnail>
    </Cell>
  );
};
