import { FacetSearchResponse, IndexedPin } from '../../../shared-core/domain/search';
import { AnyAction } from '../actions/actions';

export interface PinSearchState {
  results: FacetSearchResponse<IndexedPin> | undefined;
  forComponent: 'video' | undefined;
}

const initialState: PinSearchState = {
  results: undefined,
  forComponent: undefined,
};

export function searchPinsReducer(state: PinSearchState = initialState, action: AnyAction): PinSearchState {
  switch (action.type) {
    case 'fetchedPinResults':
      return {
        ...state,
        results: action.response.result,
      };
    default:
      return state;
  }
}
