import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../shared-core/ui/components/Button/Button';
import Heading from '../../../../shared-core/ui/components/Heading';
import { useBEM } from '../../../../shared-core/ui/utils/hooks/useBEM';
import { CHILD_PICKER_PAGE_TRANSLATION_NAMESPACE, ResponseStatus } from '../ChildPickerPage';
import { RequestResultForOrganization } from '../RequestResultForOrganization/RequestResultForOrganization';
import './CardContentForOrganization.less';
type CardButtonTheme = 'secondary' | 'quaternary';
interface Props {
  id: string;
  name: string;
  onRequestToJoin: (organizationId) => void;
  cardButtonTheme: CardButtonTheme;
  requestResult: ResponseStatus | undefined;
}

export const CardContentForOrganization: React.FC<Props> = ({
  id,
  name,
  requestResult,
  onRequestToJoin: handleRequestToJoin,
  cardButtonTheme,
}) => {
  const bemClasses = useBEM('CardContentForOrganization');
  const { t } = useTranslation(CHILD_PICKER_PAGE_TRANSLATION_NAMESPACE);

  return (
    <div className={bemClasses()}>
      <div className={bemClasses({ element: 'card-content' })}>
        <Heading size="h2" className={bemClasses({ element: 'card-title' })}>
          {name}
        </Heading>
      </div>
      <div className={bemClasses({ element: 'card-button' })}>
        {requestResult ? (
          <RequestResultForOrganization requestResult={requestResult} />
        ) : (
          <Button theme={cardButtonTheme} version="modern" height="small" onClick={() => handleRequestToJoin(id)}>
            {t('requestToJoin')}
          </Button>
        )}
      </div>
    </div>
  );
};
