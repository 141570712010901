import { Product } from '../products';
import { Licenses, UserContext } from '../user';

export interface UserSearchContext {
  /** product that the user has access to */
  allowedProducts: string[];
  licenseStatus: Licenses.Status;
}

export interface UserSearchCacheCheck {
  /** product that the user has access to */
  withTests: boolean;
  withProducts: boolean;
}

export namespace UserSearchContext {
  export function from(userContext: UserContext | undefined): UserSearchContext | undefined {
    if (!userContext) {
      return undefined;
    }

    return {
      allowedProducts: Product.productIds(userContext.products) || [],
      licenseStatus: userContext.licenseStatus,
    };
  }

  export function cacheCheck(context: UserSearchContext | undefined): UserSearchCacheCheck {
    return {
      withTests: context ? Licenses.isPaidLicense(context.licenseStatus) : false,
      withProducts: !!context?.allowedProducts.length,
    };
  }
}
