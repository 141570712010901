import React from 'react';
import { ChevronIcon } from '../../../svgIcons';
import { Breadcrumb, BreadcrumbProps } from '../Breadcrumb/Breadcrumb';

interface Props extends BreadcrumbProps {}

export const BreadcrumbWithChevron: React.FC<Props> = (props) => {
  return (
    <>
      <Breadcrumb {...props} />
      <ChevronIcon className="margin-left-4 margin-right-4 explorer-breadcrumbs__chevron-icon" />
    </>
  );
};
