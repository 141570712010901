import { SVGGeneratorProps } from '@lessonup/teaching-core';
import React from 'react';
import { BaseMathComponent } from './BaseMathComponent';

export const CircleComponent: React.FC<SVGGeneratorProps> = ({ position, settings }) => {
  return <BaseMathComponent position={position} settings={settings} pathFunc={circlePath} />;
};

export function circlePath(width_: number, height_: number, margin_: number): string {
  // Use width to draw the path while taking the margin/stroke-size into account
  const margin = margin_ / 2; // Only half of the stroke size will fall outside of the path to devide by 2
  const width = width_ - margin;

  const centerX = width_ / 2;
  const centerY = height_ / 2;
  // This is a way to draw a circle while having the option to tweak stuff like a pac-man/pizza effect..
  // I still have to find out which variables we need for this though but we'll only use it once we add the extra grippy
  const path = `M ${width} ${centerY} A ${centerX - margin} ${centerY - margin} 0 1 1 ${width} ${centerY - 0.01}`;
  return path;
}
