import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { Facets, IndexedChannelInfo, IndexType, SearchParams } from '../../../../shared-core/domain';
import { channelRoute, ChannelRouteParams } from '../../../../shared-core/services/app/searchRoutes';
import useDeepCompareMemoize from '../../../../shared-core/ui/utils/hooks/useDeepCompareMemoize';
import { useScrollPosition } from '../../../../shared-core/ui/utils/hooks/useScrollPosition';
import { navFromPageAction } from '../../../redux/actions/actions';
import { navFromSearch } from '../../../redux/selectors';
import { showTabsForLanguage } from '../../../utils/tabs';
import LessonSearch from '../../search/lessons/LessonSearch';
import PlanSearch from '../../search/plans/PlanSearch';
import { ChannelSubPageProps } from '../ChannelPageView';
import './ChannelSearchPage.less';

const ChannelSearchPage: React.FC<ChannelSubPageProps> = ({ activeTab, changeTab, details, showTabs }) => {
  const [isSticky, setSticky] = useState(false);
  const location = useLocation();
  const match = useRouteMatch<ChannelRouteParams>();
  const channelSlug = details.channel.name;
  const indexType = IndexType.isType(activeTab.selectionId) ? activeTab.selectionId : 'lessons';
  const channelInfo = IndexedChannelInfo.fromChannel(details.channel);
  const dispatch = useDispatch();
  const navigationCheck = useSelector(navFromSearch());
  const facetsSubType: Facets.FacetSubTypes[] = details.channel.showSearch === 'organization' ? [] : ['channelFacets'];

  const searchParams = SearchParams.fromUrlComponents({
    urlParams: match.params,
    queryString: location.search,
    indexType,
    subType: facetsSubType,
  });
  if (details.channel.showSearch) {
    searchParams.facets.push({ key: 'organizations', value: details.channel.organizations, fixed: true });
  }

  if (navigationCheck !== 'search') {
    dispatch(navFromPageAction('search'));
  }

  useScrollPosition(({ currentScrollTop }) => {
    const isMinimumScrolled = currentScrollTop > 300;
    if (isSticky !== isMinimumScrolled) setSticky(isMinimumScrolled);
  });

  const possibleFacets = Facets.facetsForType(indexType, ['channelFacets']);

  const paramsWithChannel = useDeepCompareMemoize(
    SearchParams.setFacet(searchParams, 'channel', channelSlug, possibleFacets)
  );

  const urlCreator = useCallback(
    (params: SearchParams) => {
      const { queryString, urlDict } = SearchParams.searchParamsToUrlComponents(params);

      return channelRoute({
        channelSlug,
        channelPage: 'search',
        selectionId: indexType,
        ...urlDict,
        qs: queryString,
      });
    },
    [channelSlug, indexType]
  );

  const lessonUrlCreator = useCallback(
    (lessonId: string) => {
      return channelRoute({
        channelSlug,
        channelPage: 'lesson',
        selectionId: lessonId,
      });
    },
    [channelSlug]
  );

  const shouldDisplayTabs = showTabs && showTabsForLanguage();

  return indexType === 'lessons' ? (
    <div className={classNames('channel-search', isSticky ? ' sticky' : undefined)}>
      <LessonSearch
        searchParams={paramsWithChannel}
        urlCreator={urlCreator}
        lessonUrlCreator={lessonUrlCreator}
        channelInfo={channelInfo}
        facetSubType={facetsSubType}
        showTabs={shouldDisplayTabs}
      />
    </div>
  ) : (
    <div className={classNames('channel-search', isSticky ? ' sticky' : undefined)}>
      <PlanSearch
        searchParams={paramsWithChannel}
        urlCreator={urlCreator}
        channelInfo={channelInfo}
        showTabs={shouldDisplayTabs}
      />
    </div>
  );
};

export default ChannelSearchPage;
