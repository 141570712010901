import { MathUtils } from './MathUtils';
export namespace SvgUtils {
  export function getFullCirclePath(center: MathUtils.Point, radius: number) {
    const doubleRadius = radius * 2;
    const d = [
      'M',
      center.x,
      center.y,
      'm',
      -radius,
      0,
      'a',
      radius,
      radius,
      0,
      1,
      0,
      doubleRadius,
      0,
      'a',
      radius,
      radius,
      0,
      1,
      0,
      -doubleRadius,
      0,
    ].join(' ');
    return d;
  }

  export function describeArcPath(center: MathUtils.Point, radius: number, startAngle: number, endAngle: number) {
    const start = MathUtils.polarCoordinateToPoint(center, radius, endAngle);
    const end = MathUtils.polarCoordinateToPoint(center, radius, startAngle);
    const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';
    const d = [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      arcSweep,
      0,
      end.x,
      end.y,
      'L',
      center.x,
      center.y,
      'L',
      start.x,
      start.y,
    ].join(' ');
    return d;
  }
}
