import React from 'react';
import './pill.less';

const ProPill: React.FC = () => {
  return (
    <div className="base-pill pro-pill">
      <div className="label">PRO</div>
    </div>
  );
};
export default ProPill;
