import React from 'react';

const ArrowLeft = (props) => (
  <svg viewBox="0 0 9 12" {...props}>
    <path
      d="M7.486.909L.429 5.143a1 1 0 000 1.714l7.057 4.234A1 1 0 009 10.234V1.766A1 1 0 007.486.91z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowLeft;
