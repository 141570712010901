import React, { useState } from 'react';

import { Colors, Lesson } from '@lessonup/teaching-core';
import {
  LESSON_THUMBNAIL_HEIGHT,
  LESSON_THUMBNAIL_RATIO,
  LESSON_THUMBNAIL_WIDTH,
} from '../../../../domain/ui/constants/lessonThumbnail';
import { useBEM } from '../../../utils/hooks';
import AspectRatioBox from '../../aspectRatioBox/AspectRatioBox';
import './PreviewThumbnail.less';
import { generateLessonThumbnailSrcSet, resizeLessonThumbnail } from './utils';

interface PreviewProps {
  lesson: Lesson;
  extraElement: JSX.Element | false | undefined;
}

const LessonPreviewThumbnail: React.FunctionComponent<PreviewProps> = ({ lesson, extraElement }) => {
  const [imageLoadingFailed, setImageLoadingFailed] = useState(false);
  const thumbnailUrl = lesson.thumbnail && lesson.thumbnail.url;
  const bemClasses = useBEM('preview-thumbnail');
  return (
    <div className={bemClasses()}>
      <AspectRatioBox aspectRatio={LESSON_THUMBNAIL_RATIO}>
        {thumbnailUrl && !imageLoadingFailed ? (
          <picture>
            <source type="image/webp" srcSet={generateLessonThumbnailSrcSet(thumbnailUrl, { format: 'webp' })} />
            <source srcSet={generateLessonThumbnailSrcSet(thumbnailUrl)} />
            <img
              className={bemClasses({ element: 'image' })}
              src={resizeLessonThumbnail(thumbnailUrl, 1)}
              width={LESSON_THUMBNAIL_WIDTH}
              height={LESSON_THUMBNAIL_HEIGHT}
              onError={() => setImageLoadingFailed(true)}
              alt=""
            />
          </picture>
        ) : (
          <LessonThumbnailFallback
            className="preview-thumbnail-image"
            width={LESSON_THUMBNAIL_WIDTH}
            height={LESSON_THUMBNAIL_HEIGHT}
          />
        )}
        {extraElement}
      </AspectRatioBox>
    </div>
  );
};

const LessonThumbnailFallback = (props) => (
  <svg viewBox="0 0 660 483" {...props}>
    <path
      d="M0 0h660v372H0zm0 391h160v92H0zm180 0h160v92H180zm180 0h160v92H360zm180 0h120v92H540z"
      fill={Colors.getHexColor('grey-bg')}
      fillRule="evenodd"
    />
  </svg>
);

export default LessonPreviewThumbnail;
