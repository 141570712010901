import _ from 'lodash';
import { ElasticResponseBody, TermAggregation } from './ElasticResponseBody';
import { Explanation, Hit } from './Hit';

export interface SearchResponse<T> {
  items: SearchResultItem<T>[];
  aggregations?: _.Dictionary<TermAggregation>;
  total: number;
}

export interface SearchResultItem<T> {
  _id: string;
  _index: string;
  doc: T;
  score: number;
  explanation?: Explanation;
  collapseCount: number | undefined;
}

export namespace SearchResponse {
  export function fromBody<T>(body: ElasticResponseBody<T>): SearchResponse<T> {
    const items = _.map(body.hits.hits, (hit) => SearchResultItem.fromHit<T>(hit));
    return {
      items,
      aggregations: body.aggregations,
      total: (body.hits.total && body.hits.total.value) || 0,
    };
  }

  export function docs<T>(result: SearchResponse<T>): T[] {
    return result.items.map((i) => i.doc);
  }
}

export namespace SearchResultItem {
  export function fromHit<T>(hit: Hit<T>): SearchResultItem<T> {
    return {
      _id: hit._id,
      _index: hit._index,
      doc: Hit.toDocument(hit),
      score: hit._score,
      explanation: hit._explanation,
      collapseCount: Hit.collapseCount(hit),
    };
  }
}
